import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirmation-call',
  templateUrl: './confirmation-call.component.html',
  styleUrls: ['./confirmation-call.component.less']
})
export class ConfirmationCallComponent {
 
  @Output() callConfirmation: EventEmitter<any> = new EventEmitter()

  userChoice(choice:string){
    if (choice === 'confirm'){
      this.callConfirmation.emit(true)
    }else {
      this.callConfirmation.emit(false)
    }

  }
  
}
