import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GET_CIRCUIT_BOX } from 'src/app/graphql/circuit-box.graphql';
import { COMMAND_BULK_REQUESTS, SEND_QUERY_COMMAND, SEND_QUERY_SCHEDULE_COMMAND, SEND_SET_PARAMETER_COMMAND, SEND_SWITCH_OFF_COMMAND, SEND_SWITCH_ON_COMMAND, SET_SCHEDULE } from 'src/app/graphql/commands.graphql';
import { FILTER_GATEWAYS, GET_GATEWAY_FOR_FILTER } from 'src/app/graphql/gateways.graphql';
import { FILTER_INSTALLATIONS, GET_INSTALLATION_SERIAL } from 'src/app/graphql/installations.graphql';

@Injectable({
  providedIn: 'root'
})
export class CommandsServiceService {

  constructor(private apollo: Apollo) {
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
  }

  /** Armazena ID da empresa selecionada **/
  public company: string | null;

  // Filtra instalação por empresa
  getInstallationsByCompanyId(company: string | null, equipmentTypes: Array<string | null>, gateway: string | null, referenceContains: string | null) {
    return this.apollo.query<any>({
      query: GET_INSTALLATION_SERIAL,
      variables: {
        company: company,
        equipmentTypes: equipmentTypes,
        gateway: gateway,
        referenceContains: referenceContains,
        first: 50,
      }
    })
  }

  filterInstallationByGateway() {
    return this.apollo.watchQuery({
      query: GET_GATEWAY_FOR_FILTER,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
      }
    })
  }

  // Filtra instalação por empresa
  getCircuitBoxesByCompanyId(company: string | null, referenceContains: string | null, sgIpcMac: string | null) {
    return this.apollo.query<any>({
      query: GET_CIRCUIT_BOX,
      variables: {
        company: company,
        sgIpcMacAddresses: sgIpcMac,
        referenceContains: referenceContains,
        first: 15,
      }
    })
  }
  // Envia comando
  sendQueryCommand(devices: Array<string | null>, dateTime: string, PowerQualityType: string) {
    return this.apollo.mutate<any>({
      mutation: SEND_QUERY_COMMAND,
      variables: {
        devices: devices,
        requestDt: dateTime,
        type: PowerQualityType
      }
    })
  }

  // Filtra comandos
  filterCommands(company: string, commandType: string | null, sort_field: string | null, sort_dir: string | null, cursor: string | null) {
    return this.apollo.watchQuery<any>({
      query: COMMAND_BULK_REQUESTS,
      fetchPolicy: 'no-cache',
      variables: {
        company,
        commandType,
        first: 10,
        customAfter: cursor,
        sort_field,
        sort_dir,
        total: false
      }
    })
  }

  //Filtro dos comandos anteriores 
  handlePreviousPage(company: string, commandType: string | null, sort_field: string | null, sort_dir: string | null, cursor: string | null) {
    return this.apollo.watchQuery<any>({
      query: COMMAND_BULK_REQUESTS,
      fetchPolicy: 'no-cache',
      variables: {
        company,
        commandType,
        last: 10,
        customBefore: cursor,
        sort_dir,
        sort_field,
        total: false
      }
    })
  }

  /** Retorna a requisição que realiza o filtro de todas as propriedades **/
  // filterPropertiesBySourceName(sourceName: string | null, cursor: string | null) {
  //   return this.apollo.watchQuery({
  //     query: FILTER_PROPERTIES,
  //     fetchPolicy: 'no-cache',
  //     variables: {
  //       cursor,
  //       first: 10,
  //       sourceName: sourceName,
  //       sort_dir: "ASC",
  //       sort_field: "NAME"
  //     }
  //   })
  // }

  // Filtra agendamentos
  sendScheduleCommand(devices: Array<string | null>, requestDt: string, scheduleSlot: number) {
    return this.apollo.mutate<any>({
      mutation: SEND_QUERY_SCHEDULE_COMMAND,
      variables: {
        devices: devices,
        requestDt: requestDt,
        scheduleSlot: scheduleSlot
      }
    })
  }

  // Liga equipamentos
  sendSwitchOnCommand(devices: Array<string | null>, requestDt: string) {
    return this.apollo.mutate<any>({
      mutation: SEND_SWITCH_ON_COMMAND,
      variables: {
        devices: devices,
        requestDt: requestDt
      }
    })
  }

  // Desliga equipamentos
  sendSwitchOffCommand(devices: Array<string | null>, requestDt: string) {
    return this.apollo.mutate<any>({
      mutation: SEND_SWITCH_OFF_COMMAND,
      variables: {
        devices: devices,
        requestDt: requestDt
      }
    })
  }

  //Consulta comandos
  SendSetParameterCommand(devices: Array<string | null>, parameter: string, requestDt: string, value: number | null | boolean) {
    return this.apollo.mutate<any>({
      mutation: SEND_SET_PARAMETER_COMMAND,
      variables: {
        devices: devices,
        parameter: parameter,
        requestDt: requestDt,
        value: value
      }
    })
  }

  // Faz agendamentos
  setSchedule(devices: Array<string | null>, scheduleSlot: number | null, dimming: number, switchOnTime: string,
    switchOffTime: string, requestDt: string) {
    return this.apollo.mutate<any>({
      mutation: SET_SCHEDULE,
      variables: {
        devices: devices,
        scheduleSlot: scheduleSlot,
        dimming: dimming,
        switchOnTime: switchOnTime,
        switchOffTime: switchOffTime,
        requestDt: requestDt
      }
    })
  }

  /** Método que lê o arquivo CSV e retorna uma lista de objetos **/
  public importDataFromCSV(csvText: string): Array<any> {
    // Remover caracteres de retorno de carro
    csvText = csvText.replace(/\r/g, '');

    // Pega a primeira linha do arquivo e define como o cabeçalho do arquivo, realizando a quebra de linha
    const propertyNames = csvText.slice(0, csvText.indexOf('\n')).split(',');

    // Constante que armazena as linhas com os dados
    const dataRows = csvText.slice(csvText.indexOf('\n') + 1).split('\n');

    // Array para armazenar a lista de objetos
    let dataArray: any[] = [];

    // Para cada linha do arquivo
    dataRows.forEach((row: any) => {
      // Instancia um novo objeto abstrato
      let obj: any = new Object();

      // Pega o valor da linha
      let val: any = row

      // Seta nulo para itens vazios
      if (val === '') {
        val = null;
      }

      // Seta no objeto uma propriedade passando o valor do índice compatível
      obj[propertyNames[0]] = val;

      // Adiciona o objeto referente a linha de dados
      dataArray.push(obj);
    });

    // Retorna uma lista de objetos referente aos dados do CSV
    return dataArray;
  }

  getInstallationByReference(referenceContains: string) {
    return this.apollo.watchQuery({
      query: GET_INSTALLATION_SERIAL,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        referenceContains,
      }
    })
  }
}