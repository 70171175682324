/** Variaveis de Componentes **/
export class Components {
  constructor(
    public id: string,
    public serialNumber: string | null,
    public installation: Installation,
    public componentType: ComponentType
  ) {}
}

/** Variáveis de tipos de Componentes **/
export class ComponentType {
  constructor(public id: string, public reference: string | null) {}
}
/** Variáveis de Instalações **/
export class Installation {
  constructor(public id: string, public reference: string | null) {}
}

//Variaveis de paginação
export class PaginationInfo {
  constructor(
    public count: number,
    public total: number,
    public hasNextPage: boolean,
    public hasPreviousPage: boolean,
    public startCursor: string | null,
    public endCursor: string | null
  ) {}
}
