import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-installations-modal',
  templateUrl: './installations-modal.component.html',
  styleUrls: ['./installations-modal.component.less']
})
export class InstallationsModalComponent {
  constructor(public dialogRef: MatDialogRef<InstallationsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  removeInstallation(i: number): void {
    this.data.installationList.splice(i, 1);
  }

  clearList(): void {
    /** Remove todos os itens da lista **/
    this.data.installationList.splice(0, this.data.installationList.length);
  }
}
