<div class="form-fields-box filter" [ngClass]="!filter ? 'closed' : ''">
  <h2 (click)="Filter()">{{ 'logs.logs-title-label' | translate }}</h2>
  <hr [ngClass]="!filter ? 'hr-open' : ''" />

  <div class="form-step">
    <div class="circle-number circle-number-ready">1</div>
    <mat-form-field class="parameter" appearance="outline">
      <mat-label>{{ 'logs.logs-form-filter' | translate }}</mat-label>
      <mat-select [(ngModel)]="typesFilter" (selectionChange)="Router([value.value])" #value>
        <mat-option class="form-control" value="/logs">{{ 'logs.form-filter-null' | translate }}</mat-option>
        <mat-option class="form-control" value="/logs/installation">{{ 'logs.form-filter-installation' | translate
          }}</mat-option>
        <mat-option class="form-control" value="/logs/installation_site">{{ 'logs.form-filter-installation-site' |
          translate }}</mat-option>
        <mat-option class="form-control" value="/logs/equipment">{{ 'logs.form-filter-equipment' | translate
          }}</mat-option>

        <!-- Redirecionamento para tela de logs de usuários desativada 
        <mat-option class="form-control" value="/logs/user">{{ 'logs.form-filter-user' | translate }}</mat-option> -->
      </mat-select>
    </mat-form-field>
  </div>