export class Installation {
  constructor(
    public id: string | null,
    public reference: string | null,
    public macAddress: string | null,
    public equipmentType: string | null,
  ) { }
}
export class Gateway {
  constructor(
    public id: string | null,
    public reference: string | null,
  ) { }
}