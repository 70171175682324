<div class="component-main-container">
  <!-- Cabeçalho -->
  <div class="header">
    <app-page-header parentTitle="{{'gateways.parentTitle' | translate}}"
      pageTitle="{{'gateways.pageTitle' | translate}}"></app-page-header>
  </div>
  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-12">
      <div class="component-forms-box">
        <!-- Box do formulário de registros -->
        <app-register-form></app-register-form>

        <!-- Box de filtro de concentradores -->
        <div class="form-fields-box" [formGroup]="filterForm" [ngClass]="!filterBox ? 'closed' : ''">
          <h2 (click)="filterBoxOpen()">{{'gateways.filter-form' | translate}} </h2>
          <hr [ngClass]="!filterBox ? 'hr-open' : ''">

          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">

            <mat-tab label="{{'gateways.mat-tab-reference' | translate}}">
              <br>
              <!-- Campo do formulário de referência -->
              <div class="form-step">
                <div class="circle-number"> 1 </div>
                <mat-form-field appearance="outline">
                  <mat-label> {{'gateways.filter-form-reference' | translate}} </mat-label>
                  <input matInput formControlName="reference" #referenceFilter>
                </mat-form-field>
              </div>
              <!-- Botão do filtro -->
              <div class="d-flex m-2">
                <button *ngIf="!filterLoading" class="btn btn-success m-1 w-100" (click)="filterGateways(null)">
                  {{'gateways.button-filter' | translate}}
                </button>
                <mat-spinner *ngIf="filterLoading" [diameter]="30"></mat-spinner>
              </div>
            </mat-tab>

            <!-- Campo do formulário (filtro de equipamento) -->
            <mat-tab label="{{'gateways.mat-tab-equipmentType' | translate}}">
              <br>
              <!-- Campo para realizar a busca dos equipamentos -->
              <div class="form-step">
                <div class="circle-number"> 2 </div>
                <mat-form-field appearance="outline">
                  <mat-label> {{'gateways.search-equipmentType-filter-form' | translate}} </mat-label>
                  <input matInput formControlName="equipment" #equipmentSerialNumberFilter>
                  <mat-icon class="component-search-icon"
                    (click)="searchEquipmentsFilter(equipmentSerialNumberFilter.value)" svgIcon="bx-search"></mat-icon>
                </mat-form-field>
              </div>

              <!-- Loading -->
              <mat-progress-bar class="mb-2" *ngIf="equipmentLoadingFilter" mode="indeterminate"></mat-progress-bar>

              <!-- Lista dos equipamentos filtrados -->
              <div class="form-step" *ngIf="equipmentListFilter.length > 0 ">
                <div class="circle-number-ready"> 2.1 </div>
                <mat-form-field appearance="outline">
                  <mat-label> {{'gateways.select-equipmentType-filter-form' | translate}} </mat-label>
                  <mat-select formControlName="equipmentSelected" #value>
                    <mat-option appereance="outline" *ngFor="let equipment of equipmentListFilter"
                      [value]="equipment.id">
                      <strong> SN: </strong>
                      {{equipment.serialNumber}}
                      <strong>
                        ({{equipment.reference}}
                        {{equipment.major}}.{{equipment.minor}}.{{equipment.revision}})
                      </strong>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- Botão de filtro -->
              <div class="d-flex m-2">
                <button *ngIf="!filterLoading" class="btn btn-success m-1 w-50" (click)="filterGateways(null)">
                  {{'gateways.button-filter' | translate}}
                </button>

                <!-- Botão para limpar os campos do formulário -->
                <button *ngIf="!filterLoading" (click)="clearFilterForm()" class="btn btn-secondary m-1 w-50">
                  {{'gateways.button-clean-filter' | translate}}
                </button>
                <mat-spinner *ngIf="filterLoading" [diameter]="30"></mat-spinner>
              </div>
            </mat-tab>

            <!-- Campos do formulário de local de instalação -->
            <mat-tab label="{{'gateways.mat-tab-installation-site' | translate}}">
              <br>
              <!-- Campo de busca de um local de instalação -->
              <div class="form-step">
                <div class="circle-number"> 3 </div>
                <mat-form-field appearance="outline">
                  <mat-label> {{'gateways.filter-form-adress' | translate}} </mat-label>
                  <input matInput matInput [formControl]="fullAddress" [placeholder]="filterPlaceholder" #filter>
                  <mat-icon class="component-search-icon" svgIcon="bx-search"></mat-icon>
                </mat-form-field>
              </div>
              <!-- Campos que serão automaticamente preenchidos com as informações do local de instalação
                                que foram filtradas -->
              <div class="form-step">
                <mat-form-field appearance="outline">
                  <mat-label> CEP </mat-label>
                  <input matInput mask="00000-000" [(ngModel)]="filterPostalCode" disabled=""
                    [ngModelOptions]="{standalone: true}">
                </mat-form-field>
              </div>

              <div class="form-step">
                <mat-form-field appearance="outline">
                  <mat-label> {{'gateways.filter-form-public-place' | translate}} </mat-label>
                  <input matInput [(ngModel)]="filterPublicPlace" disabled="" #street
                    [ngModelOptions]="{standalone: true}">
                </mat-form-field>
              </div>

              <div class="form-step">
                <mat-form-field appearance="outline">
                  <mat-label> {{'gateways.filter-form-city' | translate}} </mat-label>
                  <input matInput [(ngModel)]="filterCity" disabled="" #city [ngModelOptions]="{standalone: true}">
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label> {{'gateways.filter-form-district' | translate}} </mat-label>
                  <input matInput [(ngModel)]="filterDistrict" disabled="" #district
                    [ngModelOptions]="{standalone: true}">
                </mat-form-field>
              </div>

              <div class="form-step">
                <mat-form-field appearance="outline">
                  <mat-label> {{'gateways.filter-form-state' | translate}} </mat-label>
                  <input matInput [(ngModel)]="filterState" disabled="" #state [ngModelOptions]="{standalone: true}">
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label> {{'gateways.filter-form-country' | translate}} </mat-label>
                  <input matInput [(ngModel)]="filterCountry" disabled="" #country
                    [ngModelOptions]="{standalone: true}">
                </mat-form-field>
              </div>

              <!-- Botão do filtro  -->
              <div class="d-flex m-2">
                <button *ngIf="!filterLoading" class="btn btn-success m-1 w-50" (click)="filterGateways(null)">
                  {{'gateways.button-filter' | translate}}
                </button>
                <!-- Botão para limpar os campos do formulário -->
                <button *ngIf="!filterLoading" (click)="clearFilterForm()" class="btn btn-secondary m-1 w-50">
                  {{'gateways.button-clean-filter' | translate}}
                </button>
                <mat-spinner *ngIf="filterLoading" [diameter]="30"></mat-spinner>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <!-- Box de registro de concentradores -->
        <div class="form-fields-box" [ngClass]="!registerBox ? 'closed' : ''">
          <h2 (click)="registerBoxOpen()"> {{'gateways.register-form' | translate}} </h2>
          <hr [ngClass]="!registerBox ? 'hr-open' : ''">
          <!-- Campo do formulário (referência) -->
          <div class="form-step">
            <div class="circle-number"> 1 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{'gateways.register-form-reference' | translate}} </mat-label>
              <input matInput [formControl]="reference">
            </mat-form-field>
          </div>

          <!-- Campo para realizar a busca dos equipamentos -->
          <div class="form-step" *ngIf="validateStep1Create()">
            <div class="circle-number"> 2 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{'gateways.search-equipment-register-form' | translate}} </mat-label>
              <input matInput [formControl]="equipment" #serialNumberEquipmentCreate>
              <mat-icon class="component-search-icon"
                (click)="searchEquipmentsCreate(serialNumberEquipmentCreate.value)" svgIcon="bx-search"></mat-icon>
            </mat-form-field>
          </div>

          <!-- Loading -->
          <mat-progress-bar class="mb-2" *ngIf="equipmentLoadingCreate" mode="indeterminate"></mat-progress-bar>

          <!-- Lista dos equipamentos filtrados -->
          <div class="form-step" *ngIf="equipmentListCreate.length > 0">
            <div class="circle-number-ready"> 2.1 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{'gateways.select-equipment-register-form' | translate}} </mat-label>
              <mat-select [formControl]="equipmentSelected">
                <mat-option appereance="outline" *ngFor="let equipment of equipmentListCreate" [value]="equipment.id">
                  <strong> SN: </strong>
                  {{equipment.serialNumber}}
                  <strong>
                    ({{equipment.reference}}
                    {{equipment.major}}.{{equipment.minor}}.{{equipment.revision}})
                  </strong>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Campo para realizar a busca do local de instalação -->
          <div class="form-step" *ngIf="validateStep2Create()">
            <div class="circle-number"> 3 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{'gateways.search-installation-site-register-form' | translate}} </mat-label>
              <input matInput [formControl]="installationSite" #referenceSiteCreate>
              <mat-icon class="component-search-icon" (click)="searchInstallationSiteCreate(referenceSiteCreate.value)"
                svgIcon="bx-search"></mat-icon>
            </mat-form-field>
          </div>
          <!-- Loading -->
          <mat-progress-bar class="mb-2" *ngIf="installationSiteLoading" mode="indeterminate"></mat-progress-bar>

          <!-- Campo com o local de instalação filtrado  -->
          <div class="form-step" *ngIf="installationSiteListCreate.length > 0 ">
            <div class="circle-number-ready"> 3.1 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{'gateways.select-installation-site-register-form' | translate}}</mat-label>
              <mat-select [formControl]="installationSiteSelected">
                <mat-option *ngFor="let site of installationSiteListCreate" [value]="site.id">
                  <strong> {{site.reference}} </strong>
                  <span *ngIf="site.street && site.number && site.city && site.state!= null">({{site.street}}</span>
                  <span *ngIf="site.street && site.number && site.city && site.state!= null">,</span>
                  {{site.number}}
                  <span *ngIf="site.street && site.number && site.city && site.state!= null">-</span>
                  {{site.city}}
                  <span *ngIf="site.street && site.number && site.city && site.state!= null">/{{site.state}})</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Botão para realizar a criação do gateway -->
          <button *ngIf="!createLoading" class="btn btn-success" (click)="createGateway()"
            [disabled]="reference.invalid || equipmentSelected.invalid || installationSiteSelected.invalid">
            {{'gateways.button-save' | translate}}
          </button>
          <!-- Loading -->
          <mat-spinner *ngIf="createLoading" [diameter]="30"></mat-spinner>
        </div>
      </div>
    </div>

    <!-- Tabela de gateways -->
    <div class="col-lg-7 col-xl-8 col-sm-12" *ngIf="paginationProperties.total">
      <app-table-header *ngIf="length > 0" [itensNumber]="paginationProperties.count"
        [totalNumber]="paginationProperties.total" [hasCSV]="true" [hasPDF]="true" [loadingData$]="fileLoading"
        (downloadCSV)="generateFileData(null, 'CSV')" (downloadPDF)="generateFileData(null, 'PDF')">
      </app-table-header>

      <div class="table-div mt-2 mt-lg-3 mt-xl-3" *ngIf="ELEMENT_DATA.length">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <!-- Coluna de referência -->
          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef> {{'gateways.column-table-reference' | translate}} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">{{element.reference}}</td>
          </ng-container>

          <!-- Coluna de local de instalação -->
          <ng-container matColumnDef="installationSite">
            <th mat-header-cell *matHeaderCellDef> {{'gateways.column-table-installation-site' | translate}}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <strong>{{element.site.street}}</strong><span
                *ngIf="element.site.street && element.site.district && element.site.city && element.site.number != null">,</span>
              {{element.site.district}}<span
                *ngIf="element.site.street && element.site.district && element.site.city && element.site.number != null">,</span>
              {{element.site.city}}
              <span
                *ngIf="element.site.street && element.site.district && element.site.city && element.site.number != null">
                -
              </span>

              {{element.site.number}}
            </td>
          </ng-container>

          <!-- Coluna de tipo de equipamento -->
          <ng-container matColumnDef="equipmentType">
            <th mat-header-cell *matHeaderCellDef> {{'gateways.column-table-equipment-type' | translate}}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">

              <strong> {{element.device.reference}} </strong>
              <span *ngIf="element.device.reference && element.device.major != null">
                ({{element.device.major}}<span *ngIf="element.device.reference && element.device.major != null">.</span>
                {{element.device.minor}}<span *ngIf="element.device.reference && element.device.major != null">.</span>
                {{element.device.revision}})
              </span>
            </td>
          </ng-container>

          <!-- Coluna de numero de série -->
          <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef> {{'gateways.column-table-serial-number' | translate}}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">{{element.device.serialNumber}}</td>
          </ng-container>

          <!-- Coluna de status -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> {{'gateways.column-table-status' | translate}} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <span *ngIf="element.isActive === true ">{{'Ativo'}}</span>
              <span *ngIf="element.isActive === false">{{'Inativo'}}</span>
            </td>
          </ng-container>

          <!-- Coluna com botões de edição/visualização -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> {{'gateways.column-table-actions' | translate}} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <!-- Botão de edição -->
              <button (click)="openSidebarUpdate(element, false)" [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success view">
                <mat-icon class="component-svg-icon" svgIcon="bx-edit"></mat-icon>
              </button>
              <!-- Botão de visualização -->
              <button (click)="openSidebarUpdate(element, true)" [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success view">
                <mat-icon class="component-svg-icon" svgIcon="bx-show-white"></mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <!-- Paginator -->
        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex"
          (page)="pageChanged($event)" aria-label="Selecione as páginas de concentradores" class="mat-paginator-sticky">
        </mat-paginator>
      </div>
    </div>
  </div>

  <!-- Sidebar de edição/visualização -->
  <div class="sidebar-edit" *ngIf="editorController" [formGroup]="updateForm">
    <mat-icon class="component-close-icon" (click)="closeSidebarUpdate()"> clear </mat-icon>
    <h2 *ngIf="!viewController"> {{'gateways.sidebar-edit-title' | translate}} </h2>
    <h2 *ngIf="viewController"> {{'gateways.sidebar-view-title' | translate}} </h2>
    <hr>
    <mat-form-field appearance="outline">
      <mat-label> {{'gateways.edit-form-reference' | translate}} </mat-label>
      <input matInput formControlName="reference">
    </mat-form-field>

    <!-- Campo para realizar a busca do(s) equipamento(s) -->
    <mat-form-field appearance="outline">
      <mat-label> {{'gateways.search-equipment-edit-form' | translate}} </mat-label>
      <input matInput formControlName="equipment" #equipmentSerialNumber>
      <mat-icon class="component-search-icon" (click)="searchEquipmentsUpdate(equipmentSerialNumber.value)"
        svgIcon="bx-search"></mat-icon>
    </mat-form-field>
    <!-- Loading -->
    <mat-progress-bar class="mb-2" *ngIf="equipmentLoadingUpdate" mode="indeterminate"></mat-progress-bar>

    <!-- Campo com o(s) equipamento(s) filtrado(s) -->
    <mat-form-field appearance="outline" *ngIf="equipmentListUpdate.length > 0">
      <mat-label> {{'gateways.select-equipment-edit-form' | translate}} </mat-label>
      <mat-select formControlName="equipmentSelected">
        <mat-option appereance="outline" *ngFor="let equipment of equipmentListUpdate" [value]="equipment.id">
          {{equipment.serialNumber}} ({{equipment.reference}}
          {{equipment.major}}.{{equipment.minor}}.{{equipment.revision}})
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Campo para realizar a busca do local de instalação -->
    <mat-form-field appearance="outline">
      <mat-label> {{'gateways.search-installation-site-edit-form' | translate}} </mat-label>
      <input matInput formControlName="installationSite" #installationSiteReference>
      <mat-icon class="component-search-icon" (click)="searchInstallationSiteUpdate(installationSiteReference.value)"
        svgIcon="bx-search"></mat-icon>
    </mat-form-field>

    <!-- Loading -->
    <mat-progress-bar class="mb-2" *ngIf="installationSiteLoadingUpdate" mode="indeterminate"></mat-progress-bar>

    <!-- Campo com o local de instalação filtrado -->
    <mat-form-field appearance="outline" *ngIf="installationSiteListUpdate.length > 0">
      <mat-label> {{'gateways.select-installation-site-edit-form' | translate}} </mat-label>
      <mat-select formControlName="installationSiteSelected">
        <mat-option appereance="outline" *ngFor="let site of installationSiteListUpdate" [value]="site.id">
          <strong> {{site.reference}} </strong>
          <span *ngIf="site.street && site.number && site.city && site.state!= null">({{site.street}}</span>
          <span *ngIf="site.street && site.number && site.city && site.state!= null">,</span>
          {{site.number}}
          <span *ngIf="site.street && site.number && site.city && site.state!= null">-</span>
          {{site.city}}
          <span *ngIf="site.street && site.number && site.city && site.state!= null">/{{site.state}})</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Botão para salvar o gateway editado -->
    <div class="d-flex m-2" id="buttons-edit" *ngIf="!viewController">
      <button *ngIf="!viewController && updateForm.value.isActive && !updateLoading" class="btn btn-success m-1 w-50"
        (click)="updateGateway()" [disabled]="updateForm.invalid">
        {{'gateways.button-edit' | translate}}
      </button>
      <!-- Botão para alterar o status do gateway inativado -->
      <button *ngIf="updateForm.value.isActive && !viewController && !updateLoading" class="btn btn-secondary m-1 w-50"
        (click)="updateStatusGateway(false)">
        {{'gateways.button-inactivate' | translate}}
      </button>
      <mat-spinner *ngIf="updateLoading" [diameter]="30"></mat-spinner>
    </div>
    <!-- Botão para alterar o status do gateway ativado -->
    <button *ngIf="!updateForm.value.isActive && !viewController" class="btn btn-success"
      (click)="updateStatusGateway(true)" [disabled]="updateForm.invalid">
      {{'gateways.button-activate' | translate}}
    </button>
  </div>
</div>