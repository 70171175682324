import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PropertiesService } from 'src/shared/services/properties.service';
import { MeasurementUnit, PaginationInfo, Properties } from './models/properties.models';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import jsPDF from 'jspdf';
import { Subscription } from 'rxjs';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.less']
})
export class PropertiesComponent extends Unsubscriber implements OnInit, OnDestroy {

  constructor(
    private propertieService: PropertiesService,
    private formBuilder: FormBuilder,
    private errorLibService: ErrorLibService
  ) {
    super();
  }

  ngOnInit(): void {
    /** Traz todas as unidades de medidas **/
    this.getMeasurementUnit();
  }


  /** Variáveis para controlar o sideBar de edição/Visualização */
  public editorController: boolean = false;

  public viewController: boolean = false;

  /** Varáveis responsáveis pela manipulação dos loadings  **/
  public createLoading: boolean = false;
  public updateLoading: boolean = false;
  public filterLoading: boolean = false;
  public fileLoading: boolean = false;

  /** Variáveis utilizadas para manipulação do Paginator **/
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  /** Variável utilizada para armazenar o filtro feito **/
  public nameFilter: string;

  /** Variáveis utilizadas para geração e manipulação dos arquivos **/
  public isReady = false;
  public FILE_DATA: Properties[] = [];

  /** Variáveis para controlar o box de filtro e registro de propriedades */
  public registerPropertiesBox: boolean = false;

  public filterPropertiesBox: boolean = false;

  /** Variável que armazena uma lista de unidades de medidas **/
  public measurementUnitList: MeasurementUnit[] = [];

  /** Variável que armazena os elementos ques serão exibidos na tabela **/
  public ELEMENT_DATA: Properties[] = [];

  /** Renderiza os comandos da lista ELEMENT_DATA para a tabela **/
  public dataSource = new MatTableDataSource<Properties>(this.ELEMENT_DATA);

  /** Define todas as colunas da tabela **/
  public displayedColumns: string[] = ['name', 'sourceName', 'shortName', 'service', 'measurementUnit', 'actions'];

  /** Manipula o paginator **/
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null)

  /** Métodos e variáveis responsáveis por configurar a paginação da tabela **/
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /** Função que controla os botões de anterior e pŕoximo no paginator **/
  public pageChanged(event: any) {
    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.filterProperties(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterProperties(this.paginationProperties.endCursor)
    }
  }

  /** Configurações do paginator **/
  setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  /** Realiza a manipulação do box de registro de propriedades **/
  registerPropertiesOpen(): void {
    this.registerPropertiesBox = !this.registerPropertiesBox;
    this.filterPropertiesBox = false;
  }

  /** Realiza a manipulação do box de filtro de propriedades **/
  filterPropertiesOpen(): void {
    this.filterPropertiesBox = !this.filterPropertiesBox;
    this.registerPropertiesBox = false;
  }

  /** Forms de filtro de propriedades **/
  filterPropertiesForm: FormGroup = this.formBuilder.group({
    nameContains: [null, Validators.required]
  })

  /** Forms de registro de propriedades **/
  createPropertiesForm: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
    sourcename: [null, Validators.required],
    shortname: [null, Validators.required],
    service: [null, Validators.required],
    measurementUnitSelected: [null]
  })

  /** Forms de update de propriedades **/
  updatePropertiesForm: FormGroup = this.formBuilder.group({
    id: [null],
    name: [null, Validators.required],
    sourcename: [null, Validators.required],
    shortname: [null, Validators.required],
    service: [null, Validators.required],
    measurementUnitSelected: [null]
  })

  /** Método responsável por realizar o filtro de todas as unidades de medidas (utilizado no select) **/
  getMeasurementUnit() {
    this.subscriptions = this.propertieService.getMeasurementUnit().valueChanges.subscribe({
      next: ((response: any) => {
        response.data.measurementUnit.edges.forEach((node: any) => {
          this.measurementUnitList.push(
            new MeasurementUnit(
              node.node.id,
              node.node.name,
              node.node.symbol
            )
          )
        })
      }),
      /** Caso ocorra algum erro exibe um alerta na tela **/
      error: ((error: any) => {
        this.errorLibService.errorAlert(error);
      })
    })
  }

  /** Método responsável por realizar a criação de propriedades  **/
  createProperties(): void {
    /** Caso todos os campos sejam devidamente preenchidos **/
    if (this.createPropertiesForm.valid) {

      /** Ativa o loading **/
      this.createLoading = true;

      /** Realiza a criação de uma nova propriedade  */
      this.subscriptions = this.propertieService.createProperties(
        this.createPropertiesForm.get('name')?.value,
        this.createPropertiesForm.get('sourcename')?.value,
        this.createPropertiesForm.get('shortname')?.value,
        this.createPropertiesForm.get('service')?.value,
        this.createPropertiesForm.value?.measurementUnitSelected
      ).subscribe({
        next: (() => {
          /** Atribui o valor do "nameContains" para o que foi cadastrado no name  **/
          this.filterPropertiesForm.patchValue({
            nameContains: this.createPropertiesForm.get('name')?.value
          });

          /** Realiza o filtro **/
          this.filterProperties(null)

          /** Limpa o valor do input nameContains **/
          this.filterPropertiesForm.patchValue({
            nameContains: null
          });

          /** Exibe alerta de sucesso **/
          Swal.fire({
            title: 'Propriedade criada',
            text: 'Propriedade criada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          this.createPropertiesForm.reset();

          /** Desativa o loading **/
          this.createLoading = false;

        }),
        error: (error: any) => {
          this.errorLibService.errorAlert(error);
          console.log("error On Mutation Create", error)
        }
      })
    }
    else {
      /** Caso algum campo não tenha sido preenchido, emite alerta ao usuário **/
      Swal.fire({
        title: 'Formulário Incompleto',
        text: 'Preencha todos os campos',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
  }

  /** Método que realiza o filtro de propriedades **/
  filterProperties(cursor: string | null): any {
    this.filterLoading = true;

    this.nameFilter = this.filterPropertiesForm.get("nameContains")?.value;

    if (cursor === null) {
      this.pageIndex = 0;
      this.isReady = true;
    }

    /** Realiza o filtro */
    this.subscriptions = this.propertieService.filterProperties(
      this.nameFilter,
      cursor,
      this.pageSize
    ).valueChanges.subscribe({
      /** Realiza o tratamento da resposta de sucesso **/
      next: ((response: any) => {
        /** Atualiza os elementos de paginação **/
        this.paginationProperties = new PaginationInfo(
          response.data.property.count,
          response.data.property.total,
          response.data.property.pageInfo.hasNextPage,
          response.data.property.pageInfo.hasPreviousPage,
          response.data.property.pageInfo.startCursor,
          response.data.property.pageInfo.endCursor
        )

        this.ELEMENT_DATA = [];
        this.length = this.paginationProperties.total;
        this.isReady = false;

        /** Realiza a iteração de cada elemento de Properties/MeasurementUnit a lista criada (propertiesList) **/
        response.data.property.edges.forEach((node: any) => {
          this.ELEMENT_DATA.push(
            new Properties(
              node.node.id,
              node.node.name,
              node.node.sourceName,
              node.node.shortName,
              node.node.service,
              new MeasurementUnit(
                node.node.measurementUnit?.id,
                node.node.measurementUnit?.name,
                node.node.measurementUnit?.symbol
              )
            )
          )
        });

        /** Atualiza o paginator **/
        this.setDataSourceAttributes();

        /** Desativa o loading de filtro **/
        this.filterLoading = false;

        /** Caso ocorra algum erro **/
      }), error: ((error: any) => {
        this.errorLibService.errorAlert(error);
        console.log(error);
      })
    })
  }

  /** Método utilizado para a realizar a manipulação de fechar o SideBar de edição **/
  closeEditionSideBar() {
    this.editorController = false;
    this.viewController = false;
  }

  /** Método que atribui os campos para edição ou visualização **/
  openSidebarUpdate(element: any, isViewing: boolean) {
    /** Definindo variável manipuladora de visualização para o parametro de viewing **/
    this.viewController = isViewing;

    /** Se o parâmetro for view (true) o formulário é desabilitado **/
    if (isViewing) {
      this.updatePropertiesForm.disable();
    } else {
      /** Caso contrário o formulário é ativado para edição normalmente **/
      this.updatePropertiesForm.enable();
    }

    /** Atribuindo os campos do formulário de edição aos elementos existentes de propriedades **/
    this.updatePropertiesForm.get("id")?.setValue(element?.id),
      this.updatePropertiesForm.get('name')?.setValue(element.name),
      this.updatePropertiesForm.get('sourcename')?.setValue(element.sourceName),
      this.updatePropertiesForm.get('shortname')?.setValue(element.shortName),
      this.updatePropertiesForm.get('service')?.setValue(element.service),
      this.updatePropertiesForm.get('measurementUnitSelected')?.setValue(element.measurementUnit.id)

    /** Abre o campo de edição **/
    this.editorController = true;
  }

  /** Para realizar a edição de propriedades **/
  updateProperties(): void {
    /** Se todos os campos do formulário de edição foram preenchidos **/
    if (this.updatePropertiesForm.valid) {
      /** Realiza a edição **/
      this.subscriptions = this.propertieService.updateProperties(
        this.updatePropertiesForm.get('id')?.value,
        this.updatePropertiesForm.get('name')?.value,
        this.updatePropertiesForm.get('sourcename')?.value,
        this.updatePropertiesForm.get('shortname')?.value,
        this.updatePropertiesForm.get('service')?.value,
        this.updatePropertiesForm.get('measurementUnitSelected')?.value
      ).subscribe({
        /** Trata a resposta de sucesso **/
        next: ((response: any) => {

          /** Exibe alerta de sucesso **/
          Swal.fire({
            title: 'Propriedade atualizada',
            text: 'Propriedade atualizada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });
          /** Exibe a resposta no console **/
          console.log(response);

          /** Atualiza a tabela com os elementos de edição **/
          this.ELEMENT_DATA = [
            new Properties(
              response.data.propertyUpdate.property?.id,
              response.data.propertyUpdate.property?.name,
              response.data.propertyUpdate.property?.sourceName,
              response.data.propertyUpdate.property?.shortName,
              response.data.propertyUpdate.property?.service,
              new MeasurementUnit(
                response.data.propertyUpdate.property?.measurementUnit?.id,
                response.data.propertyUpdate.property?.measurementUnit?.name,
                response.data.propertyUpdate.property?.measurementUnit?.symbol
              )
            )
          ];

          /** Atualiza o paginator **/
          this.setDataSourceAttributes();

          /** SideBar de edição é fechado **/
          this.editorController = false;
        }),
        /** Caso de algum erro **/
        error: (error: any) => {
          /** Exibe no console o error **/
          console.log("updateUnits", error)
          /** Exibe um alerta **/
          this.errorLibService.errorAlert(error);
        }
      })
    }
    /** Caso algum campo não esteja preenchido corretamente exibe um alerta ao usuário **/
    else {
      Swal.fire({
        text: 'Verifique os campos e tente novamente!',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }

  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscriptions = this.propertieService.filterProperties(
        this.nameFilter,
        cursor,
        100
      ).valueChanges.subscribe({
        next: ((response: any) => {

          response.data.properties.edges.forEach((node: any) => {

            this.FILE_DATA.push(new Properties(
              node.node.id,
              node.node.name,
              node.node.sourceName,
              node.node.shortName,
              node.node.service,
              new MeasurementUnit(
                node.node.measurementUnit?.id,
                node.node.measurementUnit?.name,
                node.node.measurementUnit?.symbol
              )
            ));
          });

          /** Caso seja a última página a lista está completa **/
          if (!response.data?.properties?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.properties?.pageInfo?.endCursor, fileType);
        }),
        error: ((error: any) => {
          this.errorLibService.errorAlert(error);
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  /** Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe **/
  private convertToCSV(arr: any) {
    /** Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas **/
    const content = [Object.keys(arr[0])].concat(arr);

    /** Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um **/
    return content.map((item) => {
      return Object.values(item).toString().replace(',', ';');
    }).join('\n');
  }

  /** Método que realiza o download do CSV **/
  public downloadCSV() {
    let content: any = [];

    /** Adiciona cada elemento como um objeto na lista de conteúdo **/
    this.FILE_DATA.forEach((element) => {
      content.push({
        'Nome': element.name,
        'Fonte': element.sourceName,
        'Abreviatura': element.shortName,
        'Serviço': element.service,
        'Unidade de Medida': element.measurementUnit.name
      });
    });

    /** Adiciona o conteúdo convertido dentro de uma nova variável. **/
    let data = this.convertToCSV(content);
    /**  Transforma o conteúdo para o formato blob para geração do arquivo. **/
    let file = new Blob([data], { type: 'text/csv' });
    /** Cria um endereçamento para o arquivo. **/
    let url = window.URL.createObjectURL(file);
    /** Cria um elemento para a linkagem do arquivo. **/
    let link = document.createElement('a');
    /** Adiciona a url no elemento de linkagem. **/
    link.href = url;
    /**  Nomeia o arquivo que será feito o download. **/
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-properties.csv`;
    /** Adiciona o link no documento. **/
    document.body.appendChild(link);
    /** Atica o evento de click para realizar o download. **/
    link.click();
    /** Remove o link do documento. **/
    document.body.removeChild(link);
    /**  Remove o endereço do download. **/
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  /** Método que realiza o download do PDF. **/
  public downloadPDF() {
    /** Define o cabeçalho do documento. **/
    const header = [
      'Nome',
      'Fonte',
      'Abreviatura',
      'Serviço',
      'Unidade de medida'
    ];

    /** Lista que irá conter o conteúdo retornado pela API. **/
    let content: any = [];
    /** Inserção do conteúdo na lista. **/
    this.FILE_DATA.forEach((element) => {
      console.log(element)
      content.push([
        element.name,
        element.sourceName,
        element.shortName,
        element.service,
        element.measurementUnit.name,
      ]);
    });
    /** Define o formato do documento. **/
    let file: any = new jsPDF('l', 'mm', 'a4');
    /** Cria o PDF. **/
    file.autoTable({
      theme: 'grid',
      margin: { top: 20 },
      head: [header],
      body: content
    });
    /** Gera o download do documento. **/
    file.save(`${new Date().toLocaleDateString()}-${new Date().getHours()}:${new Date().getMinutes()}-properties.pdf`);

    this.FILE_DATA = [];
    this.isReady = false;
  }
}
