<div class="row">
  <div class="col-md-12">
    <div class="card mb-3 shadow">
      <div class="card-body">
        <form class="row form-card">
          <div class="col-md-6 icon-div">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
              class="bi bi-person-fill mt-3" viewBox="0 0 16 16">
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>
          </div>
          <div class="user-profile">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="inputNome" class="form-label"> {{"user-profile-edit.form-name" | translate}} </label>
                <input type="text" class="form-control" id="inputNome" [formControl]="nameFormEdit"
                  placeholder="Nome" />
              </div>
              <div class="col-md-6">
                <label for="inputEmail" class="form-label"> {{"user-profile-edit.form-email" | translate}} </label>
                <input type="text" class="form-control" id="inputEmail" [formControl]="emailFormEdit"
                  placeholder="email@email.com" />
              </div>
              <div class="col-md-6">
                <label for="inputContato" class="form-label"> {{"user-profile-edit.form-user" | translate}} </label>
                <input type="text" class="form-control" [formControl]="userFormEdit" id="inputUsuario"
                  placeholder="nome.nome" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="col-12 div-btn ">
  <button (click)="cancelEdit()" type="button" class="btn btn-danger btn me-0 shadow">
    {{"user-profile-edit.button-cancel" | translate}}
  </button>
  <button type="button" class="btn btn-success btn me-0 shadow" (click)="saveEdit()">
    {{"user-profile-edit.button-save" | translate}}
  </button>
</div>