export class auditDownload {
    constructor(
        public idIctiReport: string | null,
        public idIcviReport: string | null,
        public idSdotReport: string | null,
        public idMprevReport: string | null,
        public reference: string | null,
        public requestedBy: string | null,
        public generationDate: string | null,
        public preSignedUrlIcti: string | null,
        public preSignedUrlIcvi: string | null,
        public preSignedUrlSdot: string | null,
        public preSignedUrlMprev: string | null,
        public preSdft: string | null,
        public ready: boolean | null
    ) { }
}