export class Gateway {
    constructor(
        public id: string,
        public reference: string | null
    ){}
}

export class EquipmentType {
    constructor(
        public id: string,
        public reference: string | null,
        public checkbox: boolean = false
        ){}
}