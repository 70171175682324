export class InstallationSite {
    constructor(
        public id: string | null,
        public reference: string | null,
        public country: string | null,
        public state: string | null,
        public city: string | null,
        public district: string | null,
        public street: string | null,
        public number: string | null,
        public details: string | null,
        public locationCode: string | null,
        public lat: string | null,
        public long: string | null,
        public isActive: boolean = true
    ) { }
}

export class PaginationInfo {
    constructor(
        public count: number,
        public total: number,
        public hasNextPage: boolean,
        public hasPreviousPage: boolean,
        public startCursor: string | null,
        public endCursor: string | null
    ) { }
}
