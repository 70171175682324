export class PaginationInfo {
  constructor(
      public count: number,
      public total: number,
      public hasNextPage: boolean,
      public hasPreviousPage: boolean,
      public startCursor: string | null,
      public endCursor: string | null
  ) { }
}

export class CircuitBox {
  constructor(
      public id: string,
      public reference: string | null,
      public sgIpcSerialNumber: string,
      public sgIpcID: string,
      public sgGatewayReference: string,
      public sgGatewayID: string,
      public sgConsumptionSerialNumber: string,
      public sgConsumptionID: string
  ) { }
}

export class EquipmentTypeCircuitBox {
  constructor(
      public id: string,
      public reference: string | null,
      public serialNumber: string,
      public major: string,
      public minor: string,
      public revision: string
  ) { }
}

export class GatewayCircuitBox {
  constructor(
      public id: string,
      public reference: string | null,
  ) { }
}
