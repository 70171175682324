import { Injectable, EventEmitter } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GET_USER_GROUP_PERMISSIONS, USER_PROFILE_UPDATE } from 'src/app/graphql/users.graphql';

@Injectable({
  providedIn: 'root'
})
export class UserprofileService {

  cancelEdit = new EventEmitter<boolean>();

  constructor(private apollo: Apollo) { }

  editProfile(email: string | null, username: string | null, firstName: string | null) {
    return this.apollo.mutate({
      mutation: USER_PROFILE_UPDATE,
      variables: {
        username: username,
        email: email,
        firstname: firstName
      }
    })
  }

  getUserInfo(username: any) {
    //Query for USER_INFO_QUERY
    return this.apollo.watchQuery<any>({
      query: GET_USER_GROUP_PERMISSIONS,
      variables: {
        username: username
      }
    })
  }
}
