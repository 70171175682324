import { Injectable } from '@angular/core';
import {  CanActivate, Router } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  // Serviço de authGuard injetado no construtor
  constructor(private authServiceInstance: AuthGuardService, private router: Router) { }

  // Método que verifica se o usuário está logado, através do método isLoggedIn do serviço de authGuard
  canActivate(): boolean {
    if(this.authServiceInstance.isLoggedIn()){
      return true;
    }
    else{
      this.router.navigate(['/login']);
      return false;
    }
  }
}

