import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthGuardService } from './auth-guard.service';

@Injectable({
  providedIn: 'root'
})
export class AuditGuard implements CanActivate {

  constructor(private authServiceInstance: AuthGuardService, private router: Router){

  }

  canActivate(){
    if(this.authServiceInstance.canAudit()){
      return true;
    }
    else{
      this.router.navigate(['/**']);
      return false;
    }
  }
  
}
