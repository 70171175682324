import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoginService } from './login.service';
// import ErrorLibMap from '../../assets/errorlib/errorLib';

@Injectable({
  providedIn: 'root',
})
export class ErrorLibService {
  constructor(private loginService: LoginService, private router: Router) { }

  errorLib(error: string): string {
    //create a swith case to return the error message
    switch (error) {
      case "'NoneType' object has no attribute 'get_username'":
        return 'Login e/ou senha incorretos';
      case "'Invalid token for this user'":
        return 'Seu token expirou!';
      case 'nenhum dado encontrado':
        return 'Nenhum registro encontrado';
      case 'Service is down or unconfigured.':
        return 'Alguns de nossos serviços estão fora do ar';
      case 'UNIQUE constraint failed.':
        return 'Dados já existentes';
      case 'installation with no macaddress':
        return 'A instalação selecionada não possui macAdress associado. Favor escolher outra instalação ou entrar em contato com o suporte.';
      case 'Requested data format is invalid.':
        return 'Formato incorreto dos dados';
      case 'Variable "$applications" got invalid value "".Expected type "ApplicationTags", found "".':
        return 'Campo de "aplicação" é obrigatório';
      case 'Invalid input data format!':
        return 'Por favor, verifique todos os campos e tente novamente'
      case "{'non_field_errors': ['Os campos major, minor, revision, name devem criar um set \u00fanico.']}":
        return 'Informações já existentes no banco de dados';
      case 'Impossible to update this alert with the given status!':
        return 'Não é possível atualizar o status da alerta com o status selecionado, favor entrar em contato com o suporte ou tente novamente mais tarde.';
      case 'Connection refused. It is possible that the AMQP connection or some database had problems.':
        return 'Conexão recusada. É possível que a conexão AMQP ou a algum banco de dados tenha tido problemas.';
      case 'Invalid serial. Only numbers are accepted':
        return 'Serial inválido. São aceitos apenas números.';
      case "{'mac_address': [ErrorDetail(string='O endere\u00e7o MAC deve ser composto por 16 digitos.', code='invalid')]}":
        return 'O endereço MAC deve ser composto por 16 digitos.'
      case "The installation site to be deleted is linked to one or more installations.":
        return 'Não é possível concluir a exclusão. O local de instalação selecionado está associado a uma ou mais instalações.'
      case 'Duplicated Entry: The alert rule name cannot be repeated.':
        return 'A referência da regra de alerta não pode ser repetida.'
      case error.includes('Http failure response for') ? error : '':
        // A diretiva Same Origin (mesma origem) não permite a leitura do recurso remoto.
        return 'Não foi possível filtrar alguns dados da requisição';
      case error.includes('Já existe um relatório pendente para o mesmo tipo.') ? error : '':
        return 'Já existe um relatório pendente para o mesmo tipo.';
      default:
        return 'Erro desconhecido';
    }
  }

  expiredToken(error: any): void {
    if (
      error.message === 'Invalid token for this user' ||
      error.message === 'Token signature has expired' ||
      error.message === 'Signature verification failed'
    ) {
      this.loginService.showSideBar.emit(false);
      this.loginService.showSideBarUpdateUser.emit(false);
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Seu login expirou',
        showConfirmButton: false,
        timer: 2500,
      }).then(() => {
        this.router.navigate(['/login']);
      });
    }
  }

  errorAlert(error: any): void {
    if (error.extensions?.code) {
      console.log(error.extensions?.code, error.message);
      alert(
        `Error code: ${error.extensions?.code}, Error message: ${error.message}`
      );
    }
    if (
      error.message &&
      (
        error.message === 'Invalid token for this user' ||
        error.message === 'Token signature has expired' ||
        error.message === 'Signature verification failed'
      )
    ) {
      this.expiredToken(error);
      return;
    } else if (error.message) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: this.errorLib(error.message),
      });
    } else if (this.errorLib(error)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: this.errorLib(error),
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${error}`,
      });
    }
  }
}
