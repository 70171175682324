import { gql } from "apollo-angular";

const CREATE_ALERT_RULE = gql`
mutation AlertRuleCreate(
			$company: ID!,
			$name: String!,
			$alerttimewindow: Int!,
			$alerteventmaxcount: Int!,
			$startdate: Date,
			$enddate: Date,
			$dailystarttime: Time,
			$dailystoptime: Time,
			$weekdays: Int!,
			$installations: [ID]!,
			$users: [ID],
			$fullCoverage: Boolean,
			$monitoredProperties:  [MonitoredPropertyInput]!) {
				alertRuleCreate	(
					input: {
						company: $company,
						name: $name,
						alertTimeWindow: $alerttimewindow,
						alertEventMaxCount: $alerteventmaxcount,
						startDate: $startdate,
						endDate: $enddate,
						dailyStartTime: $dailystarttime,
						dailyStopTime: $dailystoptime,
						weekDayBitmask: $weekdays,
						installations: $installations,
						fullCoverage: $fullCoverage,
						users: $users,
						monitoredProperties: $monitoredProperties,
					}) {
				alertRule {
					id
				}
			}
		}`

const GET_ALL_ALERT_RULES = gql`
query AlertRule(
		$company: ID!,
		$reference: String,
		) {
		alertRules(
			company: $company,
			name_Icontains: $reference,
			) {
			edges {
				node {
					id
					name
					emails
					fullCoverage
					startDate
					endDate
					dailyStartTime
					dailyStopTime
					weekDayBitmask
					isActive
					alertTimeWindow
					alertEventMaxCount
					installations{
						edges{
							node{
								id
								reference
						}
					}
					}
					propertyRules{
						comparisonRule
						comparisonValue
						monitoredProperty{
							id
							name
							measurementUnit {
								symbol
							}
						}
					}
				}
				cursor
			}
			total
			count
		}
	}`

const GET_ALERT_RULE_BY_ID = gql`
query node($id: ID!) {
			node(id: $id) {
				... on AlertRule {
					id
					name
					emails
					fullCoverage
					startDate
					endDate
					dailyStartTime
					dailyStopTime
					weekDayBitmask
					alertTimeWindow
					alertEventMaxCount
					isActive
					installations {
						edges {
							node {
								id
								reference
							}
						}
					}
					propertyRules{
						comparisonRule
						comparisonValue
						monitoredProperty{
							id
							sourceName
							name
							measurementUnit {
								symbol
							}
						}
					}
				}
			}
		}`

export const GET_ALERT_RULES = gql`
query AlertRule(
			$company: ID!,
			$first: Int,
			$cursor: String,
			$sort_dir: RatatoskrDirection!,
			$sort_field: AlertRuleOrderFields!
			) {
			alertRules(
				company: $company,
				first: $first,
				after: $cursor,
				orderBy:{
					direction: $sort_dir,
					field: $sort_field
					}
				) {
				edges {
					node {
						id
						name
						#propertyRules{
						#	comparisonRule
						#	comparisonValue
						#	monitoredProperty{
						#		name
						#		measurementUnit {
						#			symbol
						#		}
						#	}
						#}
					}
					cursor
				}
				total
				count
				pageInfo {
					hasNextPage
					hasPreviousPage
					startCursor
					endCursor
				}
			}
		}`


const UPDATE_ALERT_RULE = gql`
mutation AlertRuleUpdate(
			$id: ID!
			$name: String!
			$installations: [ID]!
			) {
				alertRuleUpdate	(
					input: {
						id: $id,
						name: $name,
						installations: $installations,
					}) {
				alertRule {
					id
				}
			}
		}`

const ACTIVATE_DEACTIVATE_ALERT_RULE = gql`
mutation AlertRuleUpdate(
			$id: ID!
			$name: String!
			$isActive: Boolean
			$installations: [ID]!
			) {
				alertRuleUpdate	(
					input: {
						id: $id,
						name: $name,
						installations: $installations,
						isActive: $isActive
					}) {
				alertRule {
					id
				}
			}
		}`


export {
	// Queries
	GET_ALL_ALERT_RULES,
	GET_ALERT_RULE_BY_ID,
	// Mutations
	CREATE_ALERT_RULE,
	UPDATE_ALERT_RULE,
	ACTIVATE_DEACTIVATE_ALERT_RULE,
}

