import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SidebarService } from 'src/shared/services/sidebar.service';
import { Company } from '../models/companies.model';
import Swal from 'sweetalert2';
import { LoginService } from 'src/shared/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { PageHeaderService } from 'src/shared/services/page-header.service';
import { Unsubscriber } from '../../unsubscriber/unsubscriber.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})

export class SidebarComponent extends Unsubscriber implements OnInit {

  constructor(private sidebarService: SidebarService,
    private translate: TranslateService,
    private router: Router,
    private loginService: LoginService,
    private dialog: MatDialog,
    private pageHeaderService: PageHeaderService) {
    super();
  }


  ngOnInit() {

    //Inicia a função de checkImagePath
    this.checkImagePath()

    this.subscriptions = this.sidebarService.getUserCompanies(`${localStorage.getItem('username')}`)
      .valueChanges
      .subscribe(
        {
          next: (result: any) => {
            result.data.user.edges[0].node.companies.edges.forEach((company: any) => {
              this.companies.push(
                new Company(company.node.id, company.node.name)
              )
            })
            this.companyFormControl.setValue(this.checkLastCompany());
            if (this.companyFormControl.value === 'Q29tcGFueToyMA==') {
              this.isIpMinas = true;
            }
          },
          error: (error: any) => {
            if (error.message === "Invalid token for this user" || error.message === "Token signature has expired") {
              this.loginService.showSideBar.emit(false);
              this.loginService.showSideBarUpdateUser.emit(false);
              Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Seu login expirou',
                showConfirmButton: false,
                timer: 2500
              }).then(() => {
                this.router.navigate(['/login']);
              });
            }
          }
        });

    this.filteredCompanies = this.companies;

    this.subscriptions = this.pageHeaderService.openSideBar.subscribe({
      next: ((open: boolean) => {
        this.isClosed = !open;
      })
    });

    this.subscriptions = this.pageHeaderService.openSubMenu.subscribe({
      next: ((submenu: string) => {
        switch (submenu) {
          case "Relatórios":
            this.openCloseReportSubMenu();
            break;
          case "Usuários":
            this.openCloseUserSubMenu();
        }
      })
    })

    if (this.lastSelectedLanguage !== null) {
      this.selectedLanguage = this.lastSelectedLanguage;
    } else {
      this.selectedLanguage = 'pt';
    }

    /** Chama o método que converte a data atual do navegador que será exibida no modal de fuso horário **/
    this.formatDate(this.currentSystemDate);
  }

  /** Utilizado para acessar o elemento do modal de seleção de timezones **/
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;

  /** Propriedade que contém uma lista com todos os fusos horários suportados no JS **/
  public allTimeZones: string[] = [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg'
  ]

  /** Propriedade que contém o timezone devidamente formatado **/
  public formattedTimeZone: any[] = [];

  /** Propriedade que armazena a lista dos fusos horários utilizado para filtrar no autocomplete **/
  public filteredTimezones: string[] = [];

  /** Propriedade que armazena o fuso horário que foi selecionado pelo usuário ***/
  public selectedTimeZone: string | any;

  /** Método utilizado para armazenar a data atual (utilizado para exibir o fuso horário atual do navegador no modal de fuso horário) **/
  public currentSystemDate: any;

  //Flag que controla o estado do menu, se está aberto ou fechado.
  public isClosed: boolean = true;
  public isReportMenuOpen: boolean = false;
  public isUserMenuOpen: boolean = false;
  public isAlertsMenuOpen: boolean = false;
  public companies: Company[] = [];

  public filteredCompanies: Company[] = [];

  //Variáveis de form control
  public companyFormControl = new FormControl({ value: '', disabled: false });
  public selectedLanguage = '';
  public lastSelectedLanguage = localStorage.getItem('language');
  public fullName = localStorage.getItem('username')!.split(/\./);

  //flag que checka se a empresa selecionada é a IPMINAS
  public isIpMinas: boolean = false;

  //Logo da empresa selecionada
  public logoImg: string | null = ''

  //Checa se a logo da empresa selecionada existe
  async checkImagePath() {
    const imagePath = `../../../../assets/imgs/logos_companies/${localStorage.getItem('lastCompanySelected')}.png`;
    //Utiliza a função que verifica se a imagem existe
    const exists = await this.checkImageExistence(imagePath);

    if (exists) {
      this.logoImg = imagePath
    } else {
      this.logoImg = null
    }
  }

  /**
   * Recebe o resultado da requisição da lista de empresas do usuário, e atribui o resultado à variável companies.
   * Caso haja erro de invalid token ou token expirado, redireciona para a página de login.
   */

  /**
   * @description Alterna a variável isClosed para true ou false, controlando o estado do menu entre aberto e fechado, respectivamente.
   * Também alterna a classe do botão de menu, para que o ícone mude de acordo com o estado do menu.
   *
   */
  public toggleSidebar(): void {
    if (!this.isClosed) {
      this.isClosed = true;
      this.closeAllSubMenus();
    } else this.isClosed = false;
  }

  /**
   * Envia o id da empresa selecionada para o serviço de sidebar, que emite esse evento, deixando o ID desta empresa disponível para outros componentes.
   */
  public companySelect(): void {
    localStorage.setItem('lastCompanySelected', (this.companyFormControl.value != null ? this.companyFormControl.value : ''));
    localStorage.removeItem('selectedInstallations');
    this.sidebarService.sendCompany.emit(this.companyFormControl.value);
    if (this.companyFormControl.value === 'Q29tcGFueToyMA==') {
      this.isIpMinas = true;
    } else this.isIpMinas = false;
    location.reload();
  }

  // Função que realiza o filtro no campo de concentradores da sidebar
  public filterCompanies(searchTerm: any) {
    this.filteredCompanies = this.companies.filter(company => company.name?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
  }

  /**
   * Redireciona para tela de login, limpando todos os dados do localStorage.
   */
  public logout(): void {
    Swal.fire({
      title: 'Gostaria de deslogar?',
      text: "Você será redirecionado para a página de Login",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        this.loginService.showSideBar.emit(false);
        this.loginService.showSideBarUpdateUser.emit(false);
        this.router.navigate(['/login']);
      }
    })
  }

  /**
   * Redireciona para a página do usuário.
   */
  public goToProfile(): void {
    this.router.navigate(['/user']);
    this.isClosed = true;
  }

  public openCloseReportSubMenu(): void {
    this.isReportMenuOpen = !this.isReportMenuOpen;
    this.isUserMenuOpen = false;
    this.isAlertsMenuOpen = false;
    this.isClosed = false;
  }

  public openCloseUserSubMenu(): void {
    this.isUserMenuOpen = !this.isUserMenuOpen;
    this.isReportMenuOpen = false;
    this.isAlertsMenuOpen = false;
    this.isClosed = false;
  }

  public goToLogs(): void {
    this.router.navigate(['/logs']);
    this.isClosed = true;
  }

  public goToImportsCSV(): void {
    this.router.navigate(['/importsCSV']);
    this.isClosed = true;
  }

  public goToRegister(): void {
    this.router.navigate(['/register']);
    this.isClosed = true;
  }

  public openCloseAlertSubMenu(): void {
    this.isAlertsMenuOpen = !this.isAlertsMenuOpen;
    this.isReportMenuOpen = false;
    this.isUserMenuOpen = false;
    this.isClosed = false;
  }

  public goToReport(): void {
    this.router.navigate(['/report']);
    this.openCloseReportSubMenu()
    this.isClosed = true;
  }

  public goToAlertRules(): void {
    this.openCloseAlertSubMenu();
    this.isClosed = true;
    this.router.navigate(['/alertrules']);
  }

  public goToAlerts(): void {
    this.openCloseAlertSubMenu();
    this.isClosed = true;
    this.router.navigate(['/alerts']);
  }

  public goToCommands(): void {
    this.router.navigate(['/commands']);
    this.openCloseAlertSubMenu();
    this.isClosed = true;
  }

  public goToReportCharts(): void {
    this.router.navigate(['/reportcharts']);
    this.openCloseReportSubMenu();
    this.isClosed = true;
  }

  public goToAudit(): void {
    this.router.navigate(['/audit']);
    this.openCloseReportSubMenu();
    this.isClosed = true;
  }

  public goToManageUser(): void {
    this.router.navigate(['/manageuser']);
    this.openCloseUserSubMenu();
    this.isClosed = true;
  }

  public goToPermissionsGroup(): void {
    this.router.navigate(['/permissionsGroup']);
    this.openCloseUserSubMenu();
    this.isClosed = true;
  }

  public goToServiceOrder(): void {
    this.router.navigate(['/serviceorder']);
    this.isClosed = true;
  }

  public goHome(): void {
    this.router.navigate(['/map']);
    this.closeAllSubMenus();
    this.isClosed = true;
  }

  public checkLastCompany(): (string | null) {

    let lastCompanySelected = localStorage.getItem('lastCompanySelected');
    if (lastCompanySelected != null && lastCompanySelected != '') {
      return lastCompanySelected;
    }
    else {
      localStorage.setItem('lastCompanySelected', this.companies[0].id);
      return this.companies[0].id;
    }
  }

  public closeAllSubMenus(): void {
    this.isReportMenuOpen = false;
    this.isUserMenuOpen = false;
    this.isAlertsMenuOpen = false;
  }

  public useLanguage(language: string): void {
    localStorage.setItem('language', language);
    this.selectedLanguage = language;
    this.translate.use(language);
  }

  //Verifica se uma imagem existe baseado no path informado
  public checkImageExistence(path: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve(true); // A imagem foi carregada com sucesso, portanto, existe
      };
      img.onerror = () => {
        resolve(false); // A imagem não pôde ser carregada, portanto, não existe
      };
      img.src = path;
    });
  }

  /** Abre o modal p/ exibir os fusos horários **/
  public openDialog(): void {
    this.dialog.open(this.dialogTemplate, {
      data: {}
    });
  }

  /** Método que exibe todos os fusos horários formatados **/
  public viewAllTimeZones() {

    /** Criação de um objeto do tipo Date **/
    const dateTime = new Date();

    /** Mapeamento dos fusos horários e realizado a formatação correta p/ visualização **/
    this.formattedTimeZone = this.allTimeZones.map((tz: string) => {

      /** Variável que armazena a data/hora e o fuso horário especifico de cada local incluindo o Offset **/
      let strTime = dateTime.toLocaleString("en-US", { timeZone: `${tz}`, timeZoneName: 'longOffset' });

      /** Retorna a data/hora com o fuso horário correto e formatado para exibição do usuário **/
      return { value: tz, display: `${tz} (${strTime})` };
    });

    /** Abre o modal de visualização **/
    this.openDialog();
  }

  /** Método responsável por salvar o fuso horário selecionado pelo usuário no localStorage **/
  public saveTimeZone() {

    /** Caso algum fuso horário tenha sido selecionado pelo usuário **/
    if (this.selectedTimeZone) {
      /** Armazena a string do fuso horário no localStorage do navegador (Salva apenas o valor sem o Offset) **/
      localStorage.setItem('selectedTimeZone', this.selectedTimeZone?.value);
      /** Recarrega a página **/
      window.location.reload();
    }
  }

  /** Método responsável por remover o fuso horário selecionado pelo usuário no localStorage **/
  public removeTimeZone() {

    /** Constante que armazena o fuso horário do sistema **/
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    /** Substitui o fuso horário salvo pelo fuso horário do sistema **/
    localStorage.setItem('selectedTimeZone', browserTimeZone);

    /** Limpa o select **/
    this.selectedTimeZone = '';

    /** Recarrega a página **/
    window.location.reload();
  }

  /** Método que filtra os **/
  public filterTimeZone(event: any) {

    /** Armazena o que foi digitado pelo usuário **/
    const query = event.query.toLowerCase();

    /** Filtra os timezones conforme o filtro do usuário no autocomplete **/
    this.filteredTimezones = this.formattedTimeZone
      .filter(tz => tz.display.toLowerCase().includes(query));
  }

  /** Método Utilizado para converter a data que será exibida para o usuário na opção de manter horário padrão do navegador **/
  public formatDate(date: string): any {

    /** Define como um objeto do tipo date **/
    this.currentSystemDate = new Date();

    /** Cria um objeto de data **/
    const originalDateTime = new Date(this.currentSystemDate);

    /** Obter o fuso horário atual do navegador **/
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    /** Abreviação do nome do fuso horário atual do navegador **/
    const timezoneAbbr = new Date().toLocaleTimeString('en', { timeZoneName: 'short' }).split(' ')[2];

    /** Adicione a abreviação (timezoneAbbr) e converte o objeto de Date (originalDateTime) para um fuso horário especificado **/
    const convertedDateTime = originalDateTime.toLocaleString(undefined, {
      timeZone: timeZone,
    }) + ` (${timezoneAbbr})`;

    /** Retorna o valor convertido **/
    return convertedDateTime;
  }
}
