import { Subscription } from "rxjs";
import { Component, OnDestroy } from "@angular/core";

@Component({
    template: ''
})
export class Unsubscriber implements OnDestroy {
    public subscriptions: Subscription = new Subscription();

    constructor() { }

    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    protected resetSubscriptions() {
        if (this.subscriptions) this.subscriptions.unsubscribe();
    }

    addSubscription(sub: Subscription) {
        this.subscriptions.add(sub);
    }

    set anotherSubscription(sub: Subscription) {
        this.subscriptions.add(sub);
    }
}