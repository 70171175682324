import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FILTER_FIRMWARES, CREATE_FIRMWARES } from 'src/app/graphql/firmwares.queries';
import { CREATE_NEW_COMPATIBLE_HARDWARE, FILTER_HARDWARES } from 'src/app/graphql/hardwares.queries';

@Injectable({
  providedIn: 'root'
})
export class FirmwareService {
  constructor(private apollo: Apollo) { }

  /** Requisição do Filtro de Firmwares **/
  filterFirmwares(
    name: string | null,
    major: number | null,
    minor: number | null,
    revision: number | null,
    cursor: string | null,
    pageSize: number | null
  ): any {
    return this.apollo.watchQuery({
      query: FILTER_FIRMWARES,
      fetchPolicy: 'no-cache',
      variables: {
        major,
        minor,
        name,
        revision,
        pageSize,
        cursor
      }
    })
  }

  /** Requisição responsável por criar Firmwares **/
  createFirmware(
    name: any,
    major: any,
    minor: any,
    revision: any,
    description: any,
    releaseDate: any,
    compatibleHardwares: string[]
  ) {
    return this.apollo.mutate({
      mutation: CREATE_FIRMWARES,
      fetchPolicy: 'no-cache',
      variables: {
        name,
        major,
        minor,
        revision,
        description,
        releaseDate,
        compatibleHardwares
      }
    })
  }

  /** Requisição que lista todos os Hardwares **/
  getAllHardwares(): any {
    return this.apollo.watchQuery({
      query: FILTER_HARDWARES,
      fetchPolicy: 'no-cache',
      variables: {
        pageSize: 100,
      }
    })
  }

  /** Requisição que realiza a criação de um novo Hardware compatível **/
  createNewCompatibleHardware(
    name: any,
    major: any,
    minor: any,
    revision: any,
    description: any,
    releaseDate: any
  ) {
    return this.apollo.mutate({
      mutation: CREATE_NEW_COMPATIBLE_HARDWARE,
      variables: {
        name,
        major,
        minor,
        revision,
        description,
        releaseDate,
      }
    })
  };
}