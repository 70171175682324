import { createAction, props } from "@ngrx/store";
import { Login } from "../models/login.model";


export const enter = createAction(
    '[Login Page] Entered the page'
);

export const login = createAction(
    '[Login Page] User Login',
    props<{ login: Login }>()
);


