import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
import { AlertRulesService } from 'src/shared/services/alert-rules.service';
import { User } from '../../../models/user.model';
import { Installation } from '../../../models/installation.model';
import Swal from 'sweetalert2';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { Weekday } from '../../../models/weekday.model';
import { AlertProperty } from '../../../models/property.model';
import { Rule } from '../../../models/rule.model';

@Component({
  selector: 'app-manage-alert-rule',
  templateUrl: './manage-alert-rule.component.html',
  styleUrls: ['./manage-alert-rule.component.less']
})
export class ManageAlertRuleComponent extends Unsubscriber implements OnInit {

  constructor(public dialogRef: MatDialogRef<ManageAlertRuleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertRulesService: AlertRulesService,
    private errorLibService: ErrorLibService,
  ) {
    super();
  }

  ngOnInit(): void {
    console.log(this.data.selectedAlertRule);
    this.subscriptions = this.alertRulesService.usersGetter().subscribe((users: User[]) => {
      this.userList = users;
    });
    this.preSelectedUsers();
    this.preSelectedInstallations();
    this.weekDayBitmaskToWeekdays(this.data.selectedAlertRule.weekdays);
    console.log(this.data.selectedAlertRule.startDate);
    this.subscriptions = this.alertRulesService.alertRulespropertiesGetter()
      .subscribe({
        next: (properties: AlertProperty[]) => {
          this.propertiesList = properties;
          console.log(this.data.selectedAlertRule.rules);
        }
      })
  }

  public isEditMode: boolean = false;
  public isViewMode: boolean = false;
  public isCreatingNewCopy: boolean = false;
  public actionConfirmation: boolean = false;
  public isDuplicateMode: boolean = false;
  public alertRuleReferenceForm: FormControl = new FormControl({ value: this.data.selectedAlertRule.reference, disabled: this.data.manageMode === "view" });
  public fullCoverageForm: FormControl = new FormControl({ value: this.data.selectedAlertRule.fullCoverage, disabled: this.data.manageMode === "view" || this.data.manageMode === 'edit' });
  public alertTimeWindowForm: FormControl = new FormControl({ value: this.data.selectedAlertRule.timeWindow, disabled: (this.data.manageMode === "view" || this.data.manageMode === "edit" ? true : false) });
  public alertEventMaxCountForm: FormControl = new FormControl({ value: this.data.selectedAlertRule.eventCount, disabled: (this.data.manageMode === "view" || this.data.manageMode === "edit" ? true : false) });
  public startDateForm: FormControl = new FormControl({ value: this.data.selectedAlertRule.startDate, disabled: (this.data.manageMode === "view" || this.data.manageMode === "edit" ? true : false) });
  public endDateForm: FormControl = new FormControl({ value: this.data.selectedAlertRule.endDate, disabled: (this.data.manageMode === "view" || this.data.manageMode === "edit" ? true : false) });
  public startTimeForm: FormControl = new FormControl({ value: this.data.selectedAlertRule.startTime?.substring(0, 5), disabled: (this.data.manageMode === "view" || this.data.manageMode === "edit" ? true : false) });
  public endTimeForm: FormControl = new FormControl({ value: this.data.selectedAlertRule.endTime?.substring(0, 5), disabled: (this.data.manageMode === "view" || this.data.manageMode === "edit" ? true : false) });
  public searchInstallationForm: FormControl = new FormControl({ value: null, disabled: (this.data.manageMode === "view" || this.data.manageMode === "edit" ? true : false) });
  public instRefSearchForm: FormControl = new FormControl(null, Validators.required);
  public instGatewayForm: FormControl = new FormControl(null);
  public instModelForm: FormControl = new FormControl(null);
  public installationByReferenceForm: FormControl = new FormControl();
  public userList: User[] = [];
  public propertiesList: AlertProperty[] = [];
  public selectedUsersId: string[] = [];
  public preSelectedInstallation: any = {};
  public searchedInstallations: any[] = [];
  public company: string | null = localStorage.getItem('lastCompanySelected');
  public networkIdSelectionId: string = "UHJvcGVydHk6MTc3";
  public isNetworkIdSelectedArray: boolean[] = [];
  public isSearchingInstallation: boolean[] = [];
  public installationListNetworkId: Installation[][] = [[]];

  // Seleciona novos usuários para receberem notificações, por enquanto não será usado, pronto para uso futuro
  public selectUser(event: any, userId: string): void {
    if (event.checked) {
      this.userList.forEach((user: User) => {
        if (user.id == userId) user.checked = true;
        this.selectedUsersId.find((id: string) => id == userId) ? null
          : this.selectedUsersId.push(userId);
      });
    } else {
      this.userList.forEach((user: User, index) => {
        if (user.id == userId)
          user.checked = false;
        this.selectedUsersId = this.selectedUsersId.filter((user: string) => user !== userId);
      });
    }
  }

  public preSelectedUsers(): void {
    this.userList.forEach((user: User) => {
      if (this.data.selectedAlertRule.notification.includes(user.email)) {
        user.checked = true;
        this.selectedUsersId.push(user.id);
      }
    });
  }

  public preSelectedInstallations(): void {
    this.data.selectedAlertRule.installations.forEach((installation: any) => {
      this.preSelectedInstallation[installation.id] = installation.reference;
    })
  }

  public searchInstallation() {
    this.subscriptions = this.alertRulesService.filterInstallation(this.instRefSearchForm.value)
      .valueChanges
      .subscribe({
        next: (response: any) => {
          let installationList = response.data.installation.edges;
          installationList.forEach((installation: any) => {
            let device = installation.node.device?.macAddress;
            if (!this.preSelectedInstallation[installation.node.id] && device != undefined) {
              this.searchedInstallations.push(
                new Installation(
                  installation.node.id,
                  installation.node.reference,
                )
              );
            }
          });
          this.instRefSearchForm.reset();
        },
        error: (error: any) => {
          console.log(error.message);
        }
      }
      );
  }

  public deleteInstallation(installationId: any) {
    this.searchedInstallations = this.searchedInstallations.filter((installation: any) => installation.installationId !== installationId);
  }

  public joinInstallations(): string[] {
    let searchedInstallationsIdArray = this.searchedInstallations.map((installation: any) => installation.installationId)
    this.data.selectedAlertRule.installations.forEach((installation: any) =>
      searchedInstallationsIdArray.push(installation.id));
    return searchedInstallationsIdArray;
  }

  public saveEditData(): void {
    this.subscriptions = this.alertRulesService.updateAlertRule(
      this.data.selectedAlertRule.id,
      this.alertRuleReferenceForm.value,
      this.joinInstallations()
    )
      .subscribe({
        next: (response: any) => {
          if (response) {
            Swal.fire({
              title: 'Sucesso!',
              text: 'Regra de alerta editada com sucesso!',
              icon: 'success',
              timer: 5000,
              showConfirmButton: true
            }).then(() => {
              location.reload();
            })
          }
        },
        error: (error: any) => {
          this.errorLibService.errorAlert(error);
        }
      })
  }

  public weekdaysBitmask: number = 0;

  public weekDayBitmaskToWeekdays(weekdaysBitmask: Weekday[]): void {
    weekdaysBitmask.forEach((day: Weekday, index: any) => {
      if (day.selected) {
        this.weekdaysBitmask += Math.pow(2, index);
      }
    });
  }

  public calculateWeekDays(event: any, value: number): void {
    let powerValue = Math.pow(2, value);
    if (event.source.checked) this.weekdaysBitmask += powerValue;
    else this.weekdaysBitmask -= powerValue;
  }

  public changePropertiesMonitoredProperty(event: any, index: number): void {
    console.log("Monitored property Id: ", this.data.selectedAlertRule.rules[index].monitoredProperty, " -> ", event.value);
    if (event.value === this.networkIdSelectionId) {
      this.isNetworkIdSelectedArray[index] = true;
    } else {
      this.isNetworkIdSelectedArray[index] = false;
    }
    this.data.selectedAlertRule.rules[index].monitoredProperty = event.value;
  }

  public changePropertiesComparisonRule(event: any, index: number): void {
    console.log("Comparison Rule: ", this.data.selectedAlertRule.rules[index].comparisonRule, " -> ", event.value);
    this.data.selectedAlertRule.rules[index].comparisonRule = event.value;
  }

  public changePropertiesComparisonValue(event: any, index: number): void {
    console.log("Monitored property Value: ", this.data.selectedAlertRule.rules[index].comparisonValue, " -> ", event.value);
    this.data.selectedAlertRule.rules[index].comparisonValue = event.value;
  }

  public selectInstallationMacAddress(event: any, index: number): void {
    const decimalValue = parseInt(event.value, 16).toString();
    this.data.selectedAlertRule.rules[index].comparisonValue = decimalValue;
    this.isNetworkIdSelectedArray[index] = false;
  }

  public addNewRule(): void {
    this.data.selectedAlertRule.rules.push(
      new Rule('', '', '', '')
    )
  }

  public removeRule(i: number): void {
    this.data.selectedAlertRule.rules.splice(i, 1);
  }

  public searchInstallationByReference(index: number) {
    this.isSearchingInstallation[index] = true;
    this.installationListNetworkId[index] = [];
    this.subscriptions = this.alertRulesService.getInstallationListByReference(this.installationByReferenceForm.value)
      .valueChanges
      .subscribe({
        next: (response: any) => {
          response.data.installation.edges.forEach((installation: any) => {
            let device = installation.node.device?.macAddress;
            if (device != undefined && device != null) {
              this.installationListNetworkId[index].push(new Installation(installation.node.id, installation.node.reference, installation.node.device.macAddress));
            }
          });
          console.log(this.installationListNetworkId)
          this.isSearchingInstallation[index] = false;
        },
        error: (err: any) => {
          this.errorLibService.errorAlert(err);
          this.isSearchingInstallation[index] = false;
        }
      })
  }

  public saveAlertRuleCopy() {
    if (!this.alertRuleReferenceForm.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Por favor, preencha o campo de referência.',
      });
      return;
    }
    if (!this.monitoredPropertiesIsValid()) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Por favor, preencha todas as propriedades de monitoramento.',
      });
      return;
    }
    if (!this.alertTimeWindowForm.valid || !this.alertTimeWindowForm.value) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Por favor, preencha o campo de janela de análise e o campo N. de eventos.',
      });
      return;
    }
    if (this.fullCoverageForm.value === false) {
      if (this.joinInstallations().length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Por favor, selecione ao menos uma instalação.',
        });
        return;
      }
    }
    this.isCreatingNewCopy = true;
    this.subscriptions = this.alertRulesService.alertRuleCreate(
      this.alertEventMaxCountForm.value,
      this.alertTimeWindowForm.value,
      this.company,
      this.startTimeForm.value ? this.startTimeForm.value : null,
      this.endTimeForm.value ? this.endTimeForm.value : null,
      this.endDateForm.value ? this.endDateForm.value : null,
      this.fullCoverageForm.value,
      this.joinInstallations(),
      this.data.selectedAlertRule.rules,
      this.alertRuleReferenceForm.value,
      this.startDateForm.value ? this.startDateForm.value : null,
      this.selectedUsersId,
      this.weekdaysBitmask
    )
      .subscribe({
        next: () => {
          this.isCreatingNewCopy = false;
          Swal.fire({
            title: 'Regra de alerta criada com sucesso!',
            text: `A regra de alerta ${this.alertRuleReferenceForm.value} foi copiada com sucesso.`,
            icon: 'success',
            confirmButtonText: 'Ok'
          }).then(() => {
            this.dialogRef.close();
          })
        },
        error: (error: any) => {
          this.isCreatingNewCopy = false;
          this.errorLibService.errorAlert(error);
        }
      })
  }

  monitoredPropertiesIsValid(): boolean {
    let isValid = true;
    console.log(this.data.selectedAlertRule.rules);
    this.data.selectedAlertRule.rules.forEach((rule: Rule) => {
      if (rule.monitoredProperty === ''
        || rule.comparisonRule === ''
        || rule.comparisonValue === '') {
        isValid = false
      }
    });
    return isValid;
  }

  confirmationCall(): void {
    this.actionConfirmation = true;
  }

  confirmAction(actionType: string) {
    if (actionType === 'activate') {
      this.activateAlertRule();
    } else if (actionType === 'deactivate') {
      this.deactivateAlertRule();
    }
  }

  public setFullCoverage(event: any): void {
    this.fullCoverageForm.setValue(event.checked);
    console.log(this.fullCoverageForm.value)
  }

  public deactivateAlertRule(): void {
    console.log(this.data.selectedAlertRule.id, this.data.selectedAlertRule.reference, this.data.selectedAlertRule.installations);
    this.subscriptions = this.alertRulesService.deactivateAlertRule(this.data.selectedAlertRule.id, this.data.selectedAlertRule.reference, this.preSelectedInstallation)
      .subscribe({
        next: (response: any) => {
          if (response) {
            Swal.fire({
              title: 'Sucesso!',
              text: 'Regra de alerta desativada com sucesso!',
              icon: 'success',
              timer: 5000,
              showConfirmButton: true
            }).then(() => {
              this.dialogRef.close();
            })
          }
        },
        error: (error: any) => {
          this.errorLibService.errorAlert(error);
        }
      })
  }

  public activateAlertRule(): void {
    this.subscriptions = this.alertRulesService.activateAlertRule(this.data.selectedAlertRule.id, this.data.selectedAlertRule.reference, this.preSelectedInstallation)
      .subscribe({
        next: (response: any) => {
          if (response) {
            Swal.fire({
              title: 'Sucesso!',
              text: 'Regra de alerta ativada com sucesso!',
              icon: 'success',
              timer: 5000,
              showConfirmButton: true
            }).then(() => {
              this.dialogRef.close();
            })
          }
        },
        error: (error: any) => {
          this.errorLibService.errorAlert(error);
        }
      })
  }
}
