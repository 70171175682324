export class Installation {
    constructor(
        public id: string,
        public reference: string,
        public deviceMac: string | null,
        public check: boolean = false
    ) { }
}

export class Favorite {
    constructor(
        public reference: string,
        public installations: Installation[]
    ) { }
}