<div class="component-main-container">
  <app-page-header parentTitle="{{ 'logs.logs-parent-label' | translate }}"
    pageTitle="{{ 'logs.form-filter-installation' | translate }}">
  </app-page-header>

  <!--Corpo do componente-->
  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-12">
      <div class="component-forms-box">
        <app-logs-form></app-logs-form>

        <div class="form-fields-box" [ngClass]="!filterBox ? 'closed' : ''" [formGroup]="filterLogsForm">
          <h2 (click)="filterBoxOpen()">
            {{ "logs.form-filter-label" | translate }}
          </h2>
          <hr [ngClass]="!filterBox ? 'hr-open' : ''" />

          <!-- Campo de filtro por Operação. -->
          <div class="form-step">
            <div class="circle-number"> 1 </div>
            <mat-form-field appearance="outline" class="example-form-field">
              <mat-label>{{ "logs.form-operation" | translate }}</mat-label>
              <mat-select formControlName="operation" name="operation">
                <mat-option value="">{{
                  "logs.form-filter-null" | translate
                  }}</mat-option>
                <mat-option value="CREATE">{{
                  "logs.form-create-label" | translate
                  }}</mat-option>
                <mat-option value="UPDATE">{{
                  "logs.form-update-label" | translate
                  }}</mat-option>
                <mat-option value="DELETE">{{
                  "logs.form-delete-label" | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Campo de filtro por data. -->
          <div class="form-step">
            <div class="circle-number"> 2 </div>
            <mat-form-field appearance="outline">
              <mat-label>{{ "logs.form-date" | translate }}</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate formControlName="startDateForm" name="startDateForm" placeholder="Start date" />
                <input matEndDate formControlName="endDateForm" name="endDateForm" placeholder="End date" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>

          <!-- Input para buscar a instalação. -->
          <div class="form-step">
            <div class="circle-number"> 3 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{ "logs.form-select-reference" | translate }}</mat-label>
              <input matInput formControlName="reference" #referenceLog [(ngModel)]="reference"
                formControlName="reference">
              <mat-icon class="component-search-icon" svgIcon="bx-search" *ngIf="!filterInstallationLoading"
                (click)="searchInstallationLogsByReference(referenceLog.value)"></mat-icon>

              <!-- Loading. -->
              <mat-spinner class="mat-spinner" [diameter]="30" *ngIf="filterInstallationLoading"></mat-spinner>
            </mat-form-field>
          </div>

          <!-- Exibe um alerta caso o usuário digite algum conteúdo no input e não filtre a instalação. -->
          <div class="alert alert-danger" role="alert" *ngIf="reference && !inputReferenceController ">
            {{'logs.alert-search-installation' | translate}}
          </div>

          <!-- Lista as instalações filtradas. -->
          <div class=" form-step" *ngIf="installationList.length > 0">
            <div class="circle-number-ready"> 3.1 </div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{"logs.form-select-installation" | translate}}
              </mat-label>
              <mat-select (selectionChange)="onSelectInstallation($event)">
                <mat-option apperance="outline" *ngFor="let installation of installationList" [value]="installation.id">
                  {{installation.reference}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Exibe um alerta caso o usuário filtre todas as instalações e não selecione nenhuma opção. -->
          <div class="alert alert-danger" role="alert"
            *ngIf="!selectedInstallationController && inputReferenceController">
            {{'logs.alert-select-installation' | translate}}
          </div>

          <!-- Campo de filtro por usuário. -->
          <div class="form-step">
            <div class="circle-number"> 4</div>
            <mat-form-field appearance="outline">
              <mat-label>{{ "logs.form-filter-user" | translate }}</mat-label>
              <input matInput formControlName="username" />
            </mat-form-field>
          </div>

          <!-- Botão de filtro. -->
          <button
            [disabled]="reference && !inputReferenceController || filterInstallationLoading || !selectedInstallationController && inputReferenceController"
            title="{{ 'logs.form-filter-button' | translate }}" class="btn btn-success"
            (click)="filterInstallationLogs(null)" *ngIf="!filterLoading">
            {{ "logs.form-filter-button" | translate }}
          </button>
          <!-- Loading. -->
          <mat-spinner [diameter]="30" *ngIf="filterLoading"></mat-spinner>
        </div>
      </div>
    </div>

    <!-- Sessão tabela. -->
    <div class="col-lg-7 col-xl-8 col-sm-8" *ngIf="paginationProperties.total">
      <app-table-header [itensNumber]="paginationProperties.count" [totalNumber]="paginationProperties.total"
        [loadingData$]="fileLoading" [hasCSV]="true" [hasPDF]="true" (downloadCSV)="generateFileData(null, 'CSV')"
        (downloadPDF)="generateFileData(null, 'PDF')"></app-table-header>

      <mat-progress-bar class="mb-2" *ngIf="filterLoading" mode="indeterminate"></mat-progress-bar>

      <div class="table-div mt-2 mt-lg-3">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

          <!-- Username Column -->
          <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-filter-user" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.username }}</td>
          </ng-container>

          <!-- Operation Column -->
          <ng-container matColumnDef="operation">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-operation" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">

              <div class="view" [ngClass]="element.view === true ? '' : 'hover'">
                {{ getTranslationKey(element) | translate }}
              </div>

              <!-- Botão de visualização -->
              <button (click)="openModalInfoOperations(element)" [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success view">
                <mat-icon class="component-svg-icon" svgIcon="bx-show-white"></mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Datetime Column -->
          <ng-container matColumnDef="logDatetime">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-logDatetime" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ formatDate(element.logDatetime)}}</td>
          </ng-container>

          <!-- SerialNumber Column -->
          <ng-container matColumnDef="equipmentSerialNumber">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-serialNumber" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.equipmentSerialNumber }}</td>
          </ng-container>

          <!-- PreviousSerialNumber Column -->
          <ng-container matColumnDef="previousEquipmentSerialNumber">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-previousSerialNumber" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.previousEquipmentSerialNumber }}</td>
          </ng-container>

          <!-- Reference Column -->
          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-reference" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.reference }}</td>
          </ng-container>

          <!-- SiteReference Column -->
          <ng-container matColumnDef="siteReference">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-siteReference" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.siteReference }}</td>
          </ng-container>

          <!-- GatewayReference Column -->
          <ng-container matColumnDef="gatewayReference">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-gatewayReference" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.gatewayReference }}</td>
          </ng-container>

          <!-- IsActive Column -->
          <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-isActive" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ getTranslationKeyIsActive(element) | translate }}</td>
          </ng-container>

          <!-- LampModel Column -->
          <ng-container matColumnDef="lampModel">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-lampModel" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.lampModel }}</td>
          </ng-container>

          <!-- DivisionReference Column -->
          <ng-container matColumnDef="divisionReference">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-divisionReference" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.divisionReference }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" aria-label="Selecione as páginas de Logs"
          class="mat-paginator-sticky" (page)="pageChanged($event)">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<!-- Abre o modal que exibe as informações alteradas da coluna de operações -->
<ng-template #dialogTemplate let-dialogRef="dialogRef">
  <div class="dialogRef">
    <h2 mat-dialog-title>{{'logs.title-info-operations' | translate}}</h2>
    <mat-divider></mat-divider>
    <mat-dialog-content class="mat-typography">
      <mat-list>
        <ul>
          <!-- Exibe informações para equipamento atualizado -->
          <li *ngIf="updatedEquipment">
            <mat-list-item> {{'logs.the-equipment' | translate}}
              <b>{{previousEquipmentSerialNumber}}</b>
              {{'logs.info-updated-equipment' | translate}} <b>{{equipmentSerialNumber}}.</b>
            </mat-list-item>
            <mat-divider></mat-divider>
          </li>

          <!-- Exibe informações para equipamento adicionado -->
          <li *ngIf="addEquipment">
            <mat-list-item>
              {{'logs.the-equipment' | translate}} <b>{{equipmentSerialNumber}}</b>
              {{'logs.info-add-equipment' | translate}}
            </mat-list-item>
            <mat-divider></mat-divider>
          </li>

          <!-- Exibe informações para equipamento removido -->
          <li *ngIf="removedEquipment">
            <mat-list-item> {{'logs.the-equipment' | translate}}
              <b>{{previousEquipmentSerialNumber}}</b>
              {{'logs.info-removed-equipment' | translate}}
            </mat-list-item>
            <mat-divider></mat-divider>
          </li>

          <!-- Exibe informações para instalação atualizada -->
          <li *ngIf="updatedInstallationReference">
            <mat-list-item>{{'logs.the-installation' | translate}}
              <b>{{previousReferenceInstallation}}</b>
              {{'logs.info-updated-installation' | translate}} <b>{{referenceInstallation}}</b>.
            </mat-list-item>
            <mat-divider></mat-divider>
          </li>

          <!-- Exibe informação para quando não há nenhuma alteração relacionada aos equipamentos ou instalações -->
          <li *ngIf="updatedInfoCount <= 0">
            <mat-list-item>
              {{'logs.info-no-changes' | translate}}
            </mat-list-item>
          </li>
        </ul>
      </mat-list>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-flat-button color="success" mat-dialog-close>OK</button>
    </mat-dialog-actions>
  </div>
</ng-template>