import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CREATE_DIVISION, DELETE_DIVISION, FILTER_DIVISIONS, UPDATE_DIVISION } from 'src/app/graphql/divisions.graphql';

@Injectable({
  providedIn: 'root'
})
export class DivisionService {

  constructor(private apollo: Apollo) {
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
  }

  /** Armazena ID da empresa selecionada **/
  company: string | null;

  /** Requisição para filtrar divisão **/
  filterDivisions(
    reference: string | null,
    cursor: string | null,
    pageSize: number | null
  ) {
    return this.apollo.watchQuery({
      query: FILTER_DIVISIONS,
      fetchPolicy: 'no-cache',
      variables: {
        company: this.company,
        reference,
        pageSize,
        cursor
      }
    })
  }

  /** Requisição para criar uma divisão **/
  createDivision(reference: string | null) {
    return this.apollo.mutate({
      mutation: CREATE_DIVISION,
      fetchPolicy: 'no-cache',
      variables: {
        company: this.company,
        reference,
      }
    })
  }

  /** Requisição para editar divisão **/
  updateDivision(
    id: string | null,
    reference: string | null,
  ) {
    return this.apollo.mutate({
      mutation: UPDATE_DIVISION,
      fetchPolicy: 'no-cache',
      variables: {
        id,
        reference,
        company: this.company,
      }
    })
  }

  /** Requisição para deletar divisão **/
  deleteDivision(id: string | null) {
    return this.apollo.mutate({
      mutation: DELETE_DIVISION,
      fetchPolicy: 'network-only',
      variables: {
        id,
        company: this.company,
      }
    })
  }
}
