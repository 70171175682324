import { EventEmitter, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GET_USER_COMPANIES } from 'src/app/graphql/users.graphql';


@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  sendCompany = new EventEmitter<string | null>();

  constructor(private apollo: Apollo) { }

  getUserCompanies(username: any) {
    //Query for GET_USER_COMPANIES
    return this.apollo.watchQuery<any>({
      query: GET_USER_COMPANIES,
      variables: {
        username: username
      }
    })
  }
}
