import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { Observable, catchError, from, mergeMap, of, take, tap } from 'rxjs';
import { DivisionService } from 'src/shared/services/divisions.service';
import { ImportsCSVFileService } from 'src/shared/services/imports-CSV.service';
import { InstallationService } from 'src/shared/services/installation.service';
import { Division, Gateway, Installation, LampsType, Site } from '../../../assets-imports-csv/imports-csv-installations-model';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-imports-csv-update-installations',
  templateUrl: './imports-csv-update-installations.component.html',
  styleUrls: ['./imports-csv-update-installations.component.less']
})

export class ImportsCsvUpdateInstallationsComponent {

  constructor(
    private importsCSVFileService: ImportsCSVFileService,
    private installationService: InstallationService,
    private divisionService: DivisionService,
    public dialog: MatDialog
  ) {
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
  }

  /** Utilizado para acessar o campo de input dos arquivos **/
  @ViewChild('fileUploadSimple') fileUploadSimple: ElementRef;

  /** Utilizado para acessar o elemento do modal do passo a passo **/
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;

  /** Utilizado para acessar o stepper de "duvidas" no html **/
  @ViewChild('stepper') private stepper: MatStepper;

  /** Armazena o conteúdo do arquivo **/
  private data: string;

  /** Armazena ID da empresa selecionada **/
  public company: string | null;

  /** Array que armazena os dados do arquivo CSV **/
  private dataToImport: Array<any> = [];

  /** Lista para armazenar os logs **/
  private log: Array<string> = new Array<string>();

  /** Utilizado para manipular a mensagem exibida após os logs estarem disponíveis para download **/
  public logReadyMessage: boolean = false;

  /** Loading de update **/
  public loadingUpdate: boolean = false;

  /** Utilizado para manipular o botão de download dos logs **/
  public isExported: boolean = false;

  /** Propriedade utilizada para manipular as linhas do arquivo  **/
  public actualLine: number = 0;

  /** Utilizado para manipular a inativação do botão de importação **/
  public disableImportButton: boolean;

  /** Abre o modal que exibe o passo a passo para a importação **/
  public openDialog(): void {
    this.dialog.open(this.dialogTemplate, {
      data: {}
    });
  }

  /** Método utilizado para redirecionar o usuário para um stepper especifico definindo o seu index no html **/
  public goToStep(index: number) {
    this.stepper.selectedIndex = index;
  }

  /** Método que lê o arquivo CSV **/
  public async getTextFromFile(event: any) {
    const file: File = event.target.files[0];
    let fileContent = await file.text();

    this.data = fileContent;

    /** Reseta todas as mensagens exibidas após o envio de algum arquivo **/
    this.logReadyMessage = false;

    /** Desabilita o botão de exportar logs **/
    this.isExported = false;

    /** Limpa a lista de logs **/
    this.log = [];

    /** Habilita o botão de importação **/
    this.disableImportButton = false;
  }

  /** Método que realiza o mapeamento do retorno das propriedades de aplicações nas instalações **/
  private applicationMapping(application: string): any {

    let response: string = '';

    switch (application) {
      case 'light':
        response = 'PUBLIC_LIGHTING';
        break;

      case 'electricity':
        response = 'ELECTRICITY_CONSUMPTION';
        break;

      case 'water':
        response = 'WATER_CONSUMPTION';
        break;

      case 'gas':
        response = 'GAS_CONSUMPTION';
        break;

      default:
        response = ''
        break;
    }
    return response;
  }

  /** Método que realiza a leitura e importação dos arquivos CSV **/
  public async importDataFromCSV() {

    /** Armazena os dados do CSV **/
    this.dataToImport = this.importsCSVFileService.importDataFromCSV(this.data);

    /** Constante que armazena o objeto que contém as propriedades para atualização das instalações **/
    const dataMappings = this.dataToImport.map(row => ({
      reference: row.reference,
      model: row.model,
      gateway: row.gateway,
      division: row.division,
      magneticKey: row.magneticKey,
      site: row.site,
      applications: row.applications
    }));

    /** Inicia o processo de importação do arquivo utilizando os métodos do rxjs **/
    from(dataMappings).pipe(
      /** Processa as instalações e todos os campos, limitando as solicitações em 5 **/
      mergeMap(({ reference, model, gateway, division, magneticKey, site, applications }) => this.processInstallationAndUpdate(reference, model, gateway, division, magneticKey, site, applications), 5),
      /** Caso ocorra algum erro **/
      catchError(err => {
        /** Adiciona o erro na lista de logs **/
        this.log.push('Erro no processamento geral: ' + err);

        console.log('Erro no processamento geral', err)

        /** Ativa o botão de exportar logs **/
        this.isExported = true;
        /** Desativa o loading **/
        this.loadingUpdate = false;
        /** Botão é ativado **/
        this.disableImportButton = false;
        /** Exibe mensagem de confirmação ao usuário **/
        this.logReadyMessage = true;

        return of(null); // Continua a execução mesmo com erro
      }),

      /** Manipulação do arquivo após cada linha do arquivo ser processada **/
      tap(() => {

        /** Acrescenta +1 a linha atual **/
        this.actualLine += 1;

        if (this.actualLine === dataMappings.length) {
          /** Após 6 segundos **/
          setTimeout(() => {
            /** Ativa o botão de exportar logs **/
            this.isExported = true;
            /** Desativa o loading **/
            this.loadingUpdate = false;
            /** Botão é ativado **/
            this.disableImportButton = true;
            /** Exibe mensagem de confirmação ao usuário **/
            this.logReadyMessage = true;
            /** Reseta o contador das linhas **/
            this.actualLine = 0;
            /** Limpa o input dos arquivos que contém o arquivo anexado 
             já que o método que lê o arquivo não limpa o input caso o nome do arquivo seja igual) **/
            this.fileUploadSimple.nativeElement.value = null;
          }, 6000);
        }
      })
    ).subscribe();
  }

  /** Método que realiza todo o processamentos dos dados para atualizar as instalações do arquivo e retorna de um Observable **/
  private processInstallationAndUpdate(
    reference: string,
    model: string,
    gatewayRef: string,
    divisionRef: string,
    magneticKey: string,
    siteRef: string,
    applications: string
  ): Observable<any> {

    /** Ativa o loading na tela **/
    this.loadingUpdate = true;
    /** Retira a mensagem de confirmação dos logs ao usuário **/
    this.logReadyMessage = false;
    /** Desabilita o botão de importação **/
    this.disableImportButton = true;

    /** Variável que armazena as informações das instalações  **/
    let installation: Installation;

    /** Variável que armazena as informações das luminárias **/
    let lampType: LampsType;

    /** Variável que armazena as informações dos gateways **/
    let gateway: Gateway;

    /** Variável que armazena as informações das divisões (subprefeitura) **/
    let division: Division;

    /** Variável que armazena as informações dos locais de instalação **/
    let site: Site;

    /** Realiza o filtro de instalações passando a referência do arquivo como parâmetro **/
    return this.installationService.getInstallationByReference(reference).valueChanges.pipe(
      take(1),

      /** Mapeamento dos resultados obtidos do filtro de instalação **/
      mergeMap((res: any) => {

        /** Filtrando instalações pela referência exata obtida no arquivo **/
        const installations: Installation[] = res.data.installation.edges.map((edge: any) => edge.node)
          .filter((install: Installation) => install.reference === reference)

        /** Caso a instalação não seja encontrada **/
        if (installations.length === 0) {
          /** Adiciona na lista de logs **/
          this.log.push(`Referência ${reference} não encontrada nas instalações válidas.`);

          /** Encerrando esse processo nas referências */
          return of(null);
        }

        /** Atribuindo apenas uma instalação com a referência correta **/
        installation = installations[0];

        /** Variável controladora das chaves magnéticas **/
        let magneticKeyUpdate: boolean;

        /** Caso o campo de chave magnética possuir string '0' **/
        if (magneticKey === '0') {
          /** Chave magnética é enviada como 'false' para api **/
          magneticKeyUpdate = false;
        }
        /** Caso no arquivo o campo de chave magnética possuir string '1' **/
        else if (magneticKey === '1') {
          /** Chave magnética é enviado como 'true' para api **/
          magneticKeyUpdate = true;

          /** Caso o campo de chave magnética não seja preenchudo ou seja preenchido com alguma string diferente */
        } else if (magneticKey === null || undefined) {
          /** É enviado para api o valor atual **/
          magneticKeyUpdate = installation?.hasMagneticKey;
        }

        /** Caso o campo de aplicação existente no arquivo não seja válido **/
        if (applications === null || applications === undefined || applications === '') {
          /** Envia o campo de aplicação já existente para api **/
          applications = installation.applications[0];
        }

        /** Caso não encontre nenhum campo correspondente nas aplicações **/
        if (applications !== 'water' && applications !== 'gas' && applications !== 'electricity' && applications !== 'light') {
          /** Envia o campo de aplicação já existente para api **/
          applications = installation.applications[0];
        }

        /** Realiza a requisição que filtra as luminárias através do modelo **/
        return this.importsCSVFileService.getLampsTypeByModel(model).valueChanges.pipe(
          take(1),
          mergeMap((res: any) => {
            /** Constante que armazena o nó das luminárias realizando o filtro pelo modelo exato da luminária exata **/
            const lampsType: LampsType[] = res.data.lampType.edges.map((edge: any) => edge.node)
              /** Verificando se o modelo da luminária do arquivo corresponde a algum modelo de luminária existente na api **/
              .filter((lamps: LampsType) => lamps.model === model);

            /** Variável controladora das luminárias válidas **/
            let lampsTypeToUpdate: string;

            /** Caso nenhuma luminária com o modelo informado no arquivo seja encontrada **/
            if (lampsType.length === 0) {
              /** É enviado o campo com o valor já existente na API **/
              lampsTypeToUpdate = installation?.lampType?.id;
              /** Caso o modelo informado no arquivo seja válido **/
            } else {
              /** A luminária encontrada é armazenada **/
              lampType = lampsType[0];
              /** Variável controladora armazena o id da luminária encontrada para ser enviado na api**/
              lampsTypeToUpdate = lampType?.id;
            }

            /** Realiza o filtro dos gateways passando a referência do gateway existente no arquivo **/
            return this.importsCSVFileService.getGatewaysByReference(gatewayRef, false)
              .valueChanges
              .pipe(
                take(1),
                mergeMap((res: any) => {
                  /** Constante que armazena o nó dos gateways realizando o filtro pela referência do gateway exata **/
                  const gateways: Gateway[] = res.data.gatewayInstallation.edges.map((edge: any) => edge.node)
                    /** Verificando se a referência do gateway do arquivo corresponde a algum gateway existente na api **/
                    .filter((gateway: Gateway) => gateway.reference === gatewayRef);

                  /** Variável controladora dos gateways válidos para incluir na atualização **/
                  let gatewaysUpdate: any;

                  /** Caso nenhum gateway válido seja encontrado **/
                  if (gateways.length === 0) {
                    /** É envido o gateway já existente na api **/
                    gatewaysUpdate = installation?.gateway?.id;
                    /** Caso o gateway seja encontrado **/
                  } else {
                    /** O gateway encontrado é armazenado **/
                    gateway = gateways[0];
                    /** Variável controladora armazena o id do gateway encontrado para ser enviado na api**/
                    gatewaysUpdate = gateway?.id;
                  }

                  /** Realiza o filtro de divisão passando como parâmetro a referência da divisão existente no arquivo **/
                  return this.divisionService.filterDivisions(divisionRef, null, null).valueChanges
                    .pipe(
                      take(1),
                      mergeMap((res: any) => {
                        /** Constante que armazena o nó das divisões realizando o filtro pela referência da divisão exata **/
                        const divisions: Division[] = res.data.division.edges.map((edge: any) => edge.node)
                          /** Verificando se a referência da divisão no arquivo corresponde a alguma divisão existente na api **/
                          .filter((division: Division) => division.reference === divisionRef);

                        /** Variável controladora das divisões válidas para incluir na atualização **/
                        let divisionUpdate: any;

                        /** Caso nenhuma divisão válida seja encontrada **/
                        if (divisions.length === 0) {
                          /** É envido a divisão já existente na api **/
                          divisionUpdate = installation?.division?.id;
                          /** Caso a divisão seja encontrada **/
                        } else {
                          /** Divisão encontrada é armazenada **/
                          division = divisions[0];
                          /** Variável controladora armazena o id da divisão encontrada para ser enviado na api**/
                          divisionUpdate = division?.id;
                        }

                        /** Realiza o filtro do local de instalação por referência **/
                        return this.importsCSVFileService.getInstallationSiteByReference(siteRef).valueChanges
                          .pipe(
                            take(1),
                            mergeMap((res: any) => {
                              /** Constante que armazena o nó dos locais de instalações realizando o filtro pela referência do local de instalação **/
                              const installationSite: Site[] = res.data.installationSite.edges.map((edge: any) => edge.node)
                                /** Verificando se a referência do local de instalação no arqvuivo corresponde a algum local de instalação existente na api **/
                                .filter((site: Site) => site.reference === siteRef);

                              /** Variável controladora dos locais de instalação válidos para incluir na atualização **/
                              let installationSiteUpdate;

                              /** Caso nenhum local de instalação válido seja encontrado **/
                              if (installationSite.length === 0) {
                                /** É envido o local de instalação já existente na api **/
                                installationSiteUpdate = installation?.site?.id;
                                /** Caso o local de instalação seja encontrado **/
                              } else {
                                /** local de instalação encontrado é armazenado **/
                                site = installationSite[0];
                                /** Variável controladora armazena o id do local de instalação encontrado para ser enviado na api**/
                                installationSiteUpdate = site?.id;
                              }

                              /** Realiza a atualização da instalação com todos os campos válidos existentes, 
                               *  
                               * Por padrão, caso algum campo no arquivo não for válido será enviado os dados que já existem na api). **/
                              return this.importsCSVFileService.updateInInstallation(
                                installation?.id,
                                installation?.reference,
                                installationSiteUpdate,
                                installation?.device?.id,
                                installation?.isActive,
                                magneticKeyUpdate,
                                this.applicationMapping(applications),
                                lampsTypeToUpdate,
                                gatewaysUpdate,
                                divisionUpdate
                              ).pipe(
                                tap(() => {
                                  this.log.push(`Instalação ${reference} atualizada com sucesso!`);
                                }),

                                /** Caso ocorra algum erro na atualização **/
                                catchError(err => {
                                  /** Exibe o erro no console **/
                                  console.error(`Erro ao atualizar a instalação ${installation.reference}`, err);

                                  /** Adiciona o erro na lista de logs **/
                                  this.log.push(`Erro ao atualizar a instalação ${installation.reference}: ${err}`);

                                  /** Continua o processo mesmo com erro **/
                                  return of(null);
                                })
                              );
                            })
                          );
                      })
                    );
                })
              );
          })
        );
      }),
      /** Caso ocorra algum erro nas instalações **/
      catchError(err => {
        /** Exibe o erro no console **/
        console.log('Erro na obtenção ou atualização de instalações', err);

        /** Adiciona o erro na lista de logs **/
        this.log.push(`Erro na obtenção ou atualização de instalações: ${installation.reference}` + err);

        /** Continua o processo mesmo com erro **/
        return of(null);
      })
    );
  }

  /** Método utilizado para exportar os Logs de sucesso ou falha da importação dos arquivos **/
  public exportLogs() {
    const logText = this.log.join("\n");
    const blob = new Blob([logText], { type: 'text/plain' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}_Update_Installations.log`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  /** Método utilizado para gerar o conteúdo do CSV a partir dos cabeçalhos **/
  private generateCsvContent(headers: string[]): string {
    return headers.join(';') + '\n'; // Agrupa os cabeçalhos separando por ponto e vírgula e adiciona uma nova linha no final
  }

  /** Método que realiza o download de um arquivo csv que contém o cabeçalho com o modelo para os usuários **/
  downloadCsvFile(): void {
    const headers = ['reference', 'model', 'gateway', 'division', 'magneticKey', 'site', 'applications']; //Define o cabeçalho do arquivo csv
    const fileName = 'model-import-update-installation.csv'; //Nome do arquivo
    const csvContent = this.generateCsvContent(headers); // Gera o conteúdo do CSV com base nos cabeçalhos
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }); // Cria um objeto Blob com o conteúdo do CSV e define o tipo MIME
    const link = document.createElement('a');  // Cria um elemento <a> dinamicamente
    const url = URL.createObjectURL(blob);  // Cria uma URL para o Blob
    link.setAttribute('href', url);  // Define o atributo href do link com a URL do Blob
    link.setAttribute('download', fileName); // Define o atributo download do link com o nome do arquivo
    link.style.visibility = 'hidden'; // Define a visibilidade do link como 'hidden' para que ele não apareça na página
    document.body.appendChild(link); // Adiciona o link ao corpo do documento
    link.click(); // Simula um clique no link para iniciar o download do arquivo
    document.body.removeChild(link); // Remove o link do documento após o clique
  }
}
