export class ServiceOrder {
    constructor(
        public id: string,
        public serviceIdentifier: string,
        public problem: Problem,
        public complainant: Complainant,
        public status: string,
        public installation: Installation,
        public priority: string,
    ){}
}
/** Classe referente ao problema da ordem de serviço **/
export class Problem {
    constructor(
        public reference: string,
        public details: string,
        public address: string,
    ){}
}
/** Classe referente ao solicitante da ordem de serviço **/
export class Complainant {
    constructor(
        public name: string,
        public email: string,
        public landlineNumber: string,
        public phoneNumber: string,
    ){}
}
/** Classe referente a instalação da ordem de serviço**/
export class Installation {
    constructor (
        public id: string,
        public reference: string | null
    ){}    
}
/** Utilizado para exportar as informações de paginação **/
export class PaginationInfo {
    constructor (
        public count: number,
        public total: number,
        public hasNextPage: boolean,
        public hasPreviousPage: boolean,
        public startCursor: string | null,
        public endCursor: string | null
    ){}
}