import { gql } from "apollo-angular";

// Filtra os tipos de equipamentos
const FILTER_ALL_EQUIPMENT_TYPES = gql`
query EquipmentType(
	$pageSize: Int
	$cursor: String
	$major: Int
	$minor: Int
	$reference: String
	$revision: Int
) {
	equipmentType(
		pageSize: $pageSize
		cursor: $cursor
		major: $major
		minor: $minor
		reference: $reference
		revision: $revision
	) {
		edges {
			node {
				id
				reference
				major
				minor
				revision
				description
				releaseDate
				equipmentParts {
					edges {
						node {
							id
							hardware {
								id
								name
								major
								minor
								revision
							}
							firmwares {
								edges {
									node {
										id
										name
										major
										minor
										revision
									}
								}
							}
						}
					}
				}
				commandSheet {
					id
					hasActuation
					hasClockSync
					hasPowerQuality
					channelsCount
					oldCrc
					schedulingSlotsCountPerChannel
					schedulingSlotsCount
					parameters {
						edges {
							node {
								reference
								maxValue
								minValue
								targetReference
								type
								choices {
									edges {
										node {
											value
											description
										}
									}
								}
							}
						}
					}
					queryCommands {
						edges {
							node {
								type
								responseProperties
							}
						}
					}
				}
			}
		}
		total
		count
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
	}
}`;

// Filtra os tipos de equipamentos
const FILTER_ALL_VERSIONS = gql`
query
EquipmentType {
    equipmentType (
        orderBy:{
            direction: ASC,
            field: REFERENCE
            }
        ){
        edges {
            node {
                id
                reference
                major
                minor
                revision
            }
        }
    }
}`;

// Cria tipos de equipamentos
const CREATE_EQUIPMENT_TYPE = gql`
mutation
			EquipmentTypeCreate(
				$reference: String!,
				$major: Int!,
				$minor: Int!,
				$revision: Int!,
				$description: String,
				$releaseDate: Date,
				$hardware:ID,
				$firmwares:[ID],
				$commandSheet: CommandSheetInput
			) {
			equipmentTypeCreate(
				input: {
					reference: $reference
					major: $major
					minor: $minor
					revision: $revision
					description: $description
					releaseDate: $releaseDate
					equipmentParts: {
						hardware: $hardware
						firmwares: $firmwares
					}
					commandSheet: $commandSheet
				}) {
				equipmentType {
					id
				}
				operationErrors
			}
		}`;

const UPDATE_COMMANDS_EQUIPMENT_TYPE = gql`
mutation EquipmentTypeUpdate(
	$id: ID!
	$description: String
	$releaseDate: Date
	$hardware: ID
	$firmwares: [ID]
	$commandSheet: CommandSheetInput
) {
	equipmentTypeUpdate(
		input: {
			id: $id
			description: $description
			releaseDate: $releaseDate
			equipmentParts: { hardware: $hardware, firmwares: $firmwares }
			commandSheet: $commandSheet
		}
	) {
		equipmentType {
			id
		}
	}
}`;

const GET_EQUIPMENT_TYPE = gql`
query node($id: ID!) {
	node(id: $id) {
		... on EquipmentType {
			id
			major
			minor
			revision
			description
			releaseDate
			reference
			equipmentParts {
				edges {
					node {
						id
						hardware {
							id
						}
						firmwares {
							edges {
								node {
									id
								}
							}
						}
					}
				}
			}
			commandSheet {
				id
				channelsCount
				hasActuation
				schedulingSlotsCount
				schedulingSlotsCountPerChannel
				hasPowerQuality
				hasClockSync
				oldCrc
				queryCommands {
					edges {
						node {
							type
							responseProperties
						}
					}
				}
				parameters {
					edges {
						node {
							reference
							maxValue
							minValue
							targetReference
							type
							choices {
								edges {
									node {
										description
										value
									}
								}
							}
						}
					}
				}
			}
		}
	}
}`;

const UPDATE_EQUIPMENT_TYPE = gql`
mutation EquipmentTypeUpdate(
	$id: ID!
	$description: String
	$releaseDate: Date
	$commandSheet: CommandSheetInput
	$equipmentParts: [PartsInput]
) {
	equipmentTypeUpdate(
		input: {
			id: $id
			description: $description
			releaseDate: $releaseDate
			equipmentParts: $equipmentParts
			commandSheet: $commandSheet
		}
	) {
		equipmentType {
			id
			reference
			releaseDate
			description
			major
			minor
			revision
			equipmentParts {
				edges {
					node {
						id
						hardware {
							id
						}
						firmwares {
							edges {
								node {
									id
									major
									minor
									revision
								}
							}
						}
					}
				}
			}
			commandSheet {
				id
				channelsCount
				schedulingSlotsCount
				schedulingSlotsCountPerChannel
				hasActuation
				hasClockSync
				hasPowerQuality
				oldCrc
				parameters {
					edges {
						node {
							reference
							type
							targetReference
							maxValue
							minValue
							choices {
								edges {
									node {
										description
										value
									}
								}
							}
						}
					}
				}
				queryCommands {
					edges {
						node {
							type
							responseProperties
						}
					}
				}
			}
		}
	}
}`;

export {
	// Queries
	FILTER_ALL_EQUIPMENT_TYPES,
	GET_EQUIPMENT_TYPE,
	FILTER_ALL_VERSIONS,
	// Mutations
	CREATE_EQUIPMENT_TYPE,
	UPDATE_COMMANDS_EQUIPMENT_TYPE,
	UPDATE_EQUIPMENT_TYPE
}