export class Unit{
    constructor(
        public id:string,
        public name:string,
        public symbol:string,
    ){

    }
}

//Variaveis de paginação
export class PaginationInfo {
    constructor(
      public count: number,
      public total: number,
      public hasNextPage: boolean,
      public hasPreviousPage: boolean,
      public startCursor: string | null,
      public endCursor: string | null
    ) {}
}