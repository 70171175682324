import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, EMPTY, map, mergeMap, switchMap, tap } from "rxjs";
import { LoginPageActions, LoginPageApiActions } from "src/shared/actions-index";
import { LoginService } from "src/shared/services/login.service";
import { AuthInterceptor } from "src/app/interceptors/auth.interceptor";
import { encrypt } from "src/util/cryptography.util";

@Injectable()
export class LoginApiEffects {
    constructor(
        private router: Router,
        private loginService: LoginService,
        private actions$: Actions,
        private store: Store
    ) { }

    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoginPageActions.login),
            switchMap((action) =>
                this.loginService.logIn(action.login.username, action.login.password!).pipe(
                    switchMap((result: any) => {
                        AuthInterceptor.loggedUserId = result.data.login.user.id;
                        AuthInterceptor.accessToken = result.data.login.user.token;
                        localStorage.setItem('token', result.data.login.user.token);
                        localStorage.setItem('tokenMap', result.data.login.user.password);
                        localStorage.setItem('userId', result.data.login.user.id);
                        localStorage.setItem('username', result.data.login.user.username);
                        localStorage.setItem('expiredPassword', result.data.login.user.expiredPassword);
                        localStorage.setItem('geoserverPassword', result.data.login.user.geoserverPassword);

                        const encryptedPassword = encrypt(action.login.password!, result.data.login.user.token);
                        localStorage.setItem('geoserverPassword', encryptedPassword);

                        if (localStorage.getItem('expiredPassword') === 'false') {
                            return this.loginService.checkGeoServerAuth(action.login.username!, action.login.password!).pipe(
                                map(() => {
                                    this.loginService.showSideBar.emit(true);
                                    this.loginService.showSideBarUpdateUser.emit(localStorage.getItem('expiredPassword') === 'true');
                                    return LoginPageApiActions.loginSuccess({
                                        loggedUserId: result.data.login.user.id,
                                        token: result.data.login.user.token,
                                        error: null
                                    });
                                }),
                                catchError((error) => {
                                    this.store.dispatch(LoginPageApiActions.loginFailed({ error: error.message }));
                                    this.loginService.showSideBar.emit(false);
                                    this.loginService.showSideBarUpdateUser.emit(false);
                                    return EMPTY;
                                })
                            );

                        } else {
                            this.loginService.showSideBar.emit(true);
                            this.loginService.showSideBarUpdateUser.emit(localStorage.getItem('expiredPassword') === 'true');
                            return [LoginPageApiActions.loginSuccess({
                                loggedUserId: result.data.login.user.id,
                                token: result.data.login.user.token,
                                error: null
                            })];
                        }
                    }),
                    catchError((error) => {
                        this.store.dispatch(LoginPageApiActions.loginFailed({ error: error.message }));
                        this.loginService.showSideBar.emit(false);
                        this.loginService.showSideBarUpdateUser.emit(false);
                        return EMPTY;
                    })
                )
            ),
            catchError((error) => {
                this.store.dispatch(LoginPageApiActions.loginFailed({ error: error.message }));
                this.loginService.showSideBar.emit(false);
                this.loginService.showSideBarUpdateUser.emit(false);
                return EMPTY;
            })
        )
    );

    //Faça a ação do tap quando essa action for chamada.
    loginRedirect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LoginPageApiActions.loginSuccess),
            tap(() => {
                this.router.navigate(['/map']);
            })
        );
    }, { dispatch: false });
}
