import { Component, OnInit, ViewChild } from '@angular/core';
import { Unsubscriber } from '../../unsubscriber/unsubscriber.component';
import { ReportService } from 'src/shared/services/report.service';
import { ReportFilter } from '../models/reportFilter.model';
import { InstallationService } from 'src/shared/services/installation.service';
import { InstallationsModalComponent } from 'src/shared/components/installations-modal/installations-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { DateformatterService } from 'src/shared/services/dateformatter.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import {

  CommandReportModel,
  EventsModel,
  IndividualConsumptionModel,
  IndividualStatusReportModel,
  InstallationListModel,
  LastTransmissionModel,
  MetereologicalReportModel,
  NoTransmissionReportModel,
  ReportTable,
  PaginationInfo,
  StatusModel,
  InstallationRecordModel,
  LatestDataModel,
  FirstOnFirstOff,
  PossibleStatus
} from '../models/report.model';

import { Installation, Gateway } from '../models/installation.model';
import { tzConvertISOEndDate, tzConvertISOStartDate, tzConvertUTC2Local } from 'src/assets/convertTimeZone/convertTimeZone';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.less'],
})

export class ReportComponent extends Unsubscriber implements OnInit {

  public isIluminaSP: boolean = false;

  constructor(
    private reportService: ReportService,
    private errorLibService: ErrorLibService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private installationService: InstallationService,
    private dateFormatterService: DateformatterService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    // Verifica se existe instalações selecionadas no mapa
    if (
      localStorage.getItem('selectedInstallations') &&
      JSON.parse(localStorage.getItem('selectedInstallations')!).length > 0
    ) {
      // permite a seleção de instalações a partir das selecionadas
      this.selectedInstallationsView = true;

      // Armazena as instalações selecionadas nesta página
      this.selectedInstallations = JSON.parse(localStorage.getItem('selectedInstallations')!);
    }

    // Guarda a informação de se a empresa é Iluminação Paulistana.
    if (this.company == 'Q29tcGFueToxNg==') {
      this.isIluminaSP = true;
    } else {
      this.isIluminaSP = false;
    }
  }

  /** Variáveis que contém as listas para serem carregadas no select de edição **/
  public gatewayListUpdate: Gateway[] = [];
  public gatewayList: Gateway[] = [];

  // Variável que guarda a empresa selecionada
  public company: string | null = localStorage.getItem('lastCompanySelected')
    ? localStorage.getItem('lastCompanySelected')
    : null;

  public timeZone = localStorage.getItem('selectedTimeZone');

  // Armazena os dados do formulário de Geração de Relatórios
  public genForm: FormGroup = this.formBuilder.group({
    referenceForm: [null, [Validators.required]],
    reportTypeForm: [null, [Validators.required]],
    installationForm: [null, [Validators.required]],
    selectedInstallationForm: [null, [Validators.required]],
    startDateForm: [null, [Validators.required]],
    endDateForm: [null, [Validators.required]],
    gateway: [null],
    gatewaySelected: [null, [Validators.required]],
    multiInstallationForm: [null, [Validators.required]],
    hoursForm: [null, [Validators.required, Validators.pattern('^[0-9]+$')]],
  });

  // Armazena os dados do formulário de Filtro de Relatórios
  public filterForm: FormGroup = this.formBuilder.group({
    referenceFilterForm: [null],
    reportTypeFilterForm: [null],
    userFilter: [null],
  });

  public reportFilter: ReportFilter | null;

  /** Variável utilizada para controlar o loading **/
  public filterLoading: boolean = false;
  public loadingGateway: boolean = false;

  /** Variáveis utilizadas para manipular os boxs de registro e filtro de relatórios **/
  public genFormBox: boolean = false;
  public filterReportBox: boolean = false;

  // Manipula a renderização da sidebar de instalações selecionadas
  public selectedInstallationsView: boolean = false;

  // Armazena as instalações selecionadas no mapa
  public selectedInstallations = [];

  // Armazena o tipo de equipamento para o relatório de consumo
  public equipmentType: any = '';

  /** Funções que realizam a manipulação dos boxs de registro e filtro de relatórios **/
  public genFormBoxOpen() {
    this.genFormBox = !this.genFormBox;
    this.filterReportBox = false;
  }

  public filterReportBoxOpen() {
    this.filterReportBox = !this.filterReportBox;
    this.genFormBox = false;
  }

  // Variável que irá guardar o resultado da requisição inicial de relatórios
  public ELEMENT_DATA: ReportTable[] = [];
  public filteredReports: ReportTable[] = [];

  // Variáveis do data table
  public dataSource = new MatTableDataSource<ReportTable>(this.ELEMENT_DATA);
  public displayedColumns: string[] = [
    'reference',
    'requestedBy',
    'reportType',
    'generationDate',
    'actions',
  ];

  // Variável que guarda o tipo de relatório
  public consumptionTypeForm: string = '';

  // General flags
  public isHourError: boolean = false;
  public isLoading: boolean = false;
  public isThereInstallationListed: boolean = false;

  // Flags para a geração de relatório
  public flagsGenForm: any = {
    lastTransmissions: false,
    installationsList: false,
    events: false,
    status: false,
    weather: false,
    individualConsumption: false,
    commands: false,
    alerts: false,
    noTransmission: false,
    installationRecord: false,
    latestData: false,
    firstOnFirstOff: false,
    possibleStatus: false,
    gatewayConsumption: false,
  };

  // Variáveis que guardam as instalações selecionadas
  public selectedInstallation: Installation = new Installation('', '', '', '');
  public installations: Installation[] = [];
  public multiInstallations: Installation[] = [];
  public selectedGateway: Gateway;

  // Variáveis usadas para o paginator e para o sort.
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null);

  /** Variáveis utilizadas para manipulação do Paginator **/
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public currentPage: number = 0;
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  /** Função chamada toda vez que ocorre um evento no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.handlePreviousPage(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterReport(this.paginationProperties.endCursor)
    }
  }

  /**
   * Busca por instalações.
   */
  public searchInstallation(): void {
    if (!this.genForm.get('installationForm')?.valid) {
      return this.showFireMessage(
        this.translateService,
        'report.errorMessages.fill-all-fields-correctly',
        'error',
        'Ops...'
      );
    }

    this.isLoading = true;
    // Armazena as requisições utilizadas para preencher o campo do filtro
    let installations = this.reportService
      .getInstallationByReference(
        this.genForm.get('installationForm')?.value,
      )

    let circuitBoxes = this.reportService
      .getCircuitBoxesByReference(
        this.company,
        this.genForm.get('installationForm')?.value
      )

    let installationsData: any;
    let circuitData: any;
    // Executa as 2 requisições e aguarda o retorno das duas antes de preencher o campo
    forkJoin(
      [circuitBoxes, installations],
    ).subscribe(([resultCircuitBox, resultInstallations]) => {

      circuitData = resultCircuitBox;
      // Itera a resposta da requisição de caixas de circuito
      circuitData.data.circuitBox.edges?.forEach((node: any) => {
        if (node.node.sgIpc?.macAddress) {
          this.installations.push(
            new Installation(
              node.node.id,
              node.node.reference,
              node.node.sgIpc.macAddress,
              node.node.sgIpc?.equipmentType.reference
            )
          );
        }
      })

      installationsData = resultInstallations;
      // Itera a resposta da requisição de instalações
      installationsData.data.installation.edges?.forEach((node: any) => {
        if (!this.thisInstallationExists(node.id)) {
          this.installations.push(
            new Installation(
              node.node.id,
              node.node.reference,
              node.node.device?.macAddress,
              node.node.device?.equipmentType.reference
            )
          );
        }
      })

      if (this.installations.length > 0) {
        this.isLoading = false;
        this.isThereInstallationListed = true;
      } else {
        this.isLoading = false;
        this.isThereInstallationListed = false;

        this.showFireMessage(
          this.translateService,
          'report.errorMessages.noInstallationReference',
          'warning',
          'Ops...'
        );
      }
    });
  }

  /**
   * Busca por múltiplas instalações.
   */
  public searchMultiInstallation(): void {
    this.multiInstallations = [];

    // Armazena as requisições utilizadas para preencher o campo do filtro
    let installations = this.reportService
      .getInstallationByReference(
        this.genForm.get('multiInstallationForm')?.value,
      )

    let circuitBoxes = this.reportService
      .getCircuitBoxesByReference(
        this.company,
        this.genForm.get('multiInstallationForm')?.value
      )

    let installationsData: any;
    let circuitData: any

    // Executa as 2 requisições e aguarda o retorno das duas antes de preencher o campo
    forkJoin(
      [circuitBoxes, installations],
    ).subscribe(([resultCircuitBox, resultInstallations]) => {

      circuitData = resultCircuitBox;
      // Itera a resposta da requisição de caixas de circuito
      circuitData.data.circuitBox.edges?.forEach((node: any) => {
        if (node.node.sgIpc?.macAddress) {
          this.multiInstallations.push(
            new Installation(
              node.node.id,
              node.node.reference,
              node.node.sgIpc.macAddress,
              node.node.sgIpc?.equipmentType.reference
            )
          );
        }
      })

      installationsData = resultInstallations;
      // Itera a resposta da requisição de instalações
      installationsData.data.installation.edges?.forEach((node: any) => {
        if (!this.thisInstallationExists(node.id)) {
          this.multiInstallations.push(
            new Installation(
              node.node.id,
              node.node.reference,
              node.node.device?.macAddress,
              node.node.device?.equipmentType.reference
            )
          );
        }
      })
      // Verificação se existe instalações antes de abrir o modal
      if (this.multiInstallations.length > 0) {
        const dialogRef = this.dialog.open(InstallationsModalComponent, {
          data: { installationList: this.multiInstallations },
          disableClose: true,
        });

        this.subscriptions = dialogRef
          .afterClosed()
          .subscribe((result) => {
            if (result !== undefined) {
              this.multiInstallations = result;
            }
          });
      } else {
        this.isLoading = false;
        this.isThereInstallationListed = false;

        this.showFireMessage(
          this.translateService,
          'report.errorMessages.noInstallationReference',
          'warning',
          'Ops...'
        );
      }
    });
  }

  /**
   * Função chamada quando uma instalação é selecionada
   */
  public installationSelected(selectReports: Installation): void {
    this.selectedInstallation = selectReports;
  }

  public gatewaySelected(selectGateway: Gateway): void {
    this.selectedGateway = selectGateway;
  }

  /**
   * Verifica se foi completo todos os passos dependendo do tipo de relatório.
   * @returns true ou false
   */
  public enableGenerateButton(): boolean {
    if (
      this.genForm.get('referenceForm')?.valid &&
      this.genForm.get('reportTypeForm')?.valid
    ) {
      if (
        this.flagsGenForm.lastTransmissions ||
        this.flagsGenForm.installationsList ||
        this.flagsGenForm.installationRecord ||
        this.flagsGenForm.latestData
      ) {
        return true;
      } else if (
        this.flagsGenForm.events ||
        this.flagsGenForm.status ||
        this.flagsGenForm.weather
      ) {
        if (
          this.genForm.get('selectedInstallationForm')?.valid &&
          this.genForm.get('startDateForm')?.valid &&
          this.genForm.get('endDateForm')?.valid
        ) {
          return true;
        }
      } else if (this.flagsGenForm.individualConsumption) {
        if (
          this.genForm.get('selectedInstallationForm')?.valid &&
          this.genForm.get('startDateForm')?.valid &&
          this.genForm.get('endDateForm')?.valid &&
          this.consumptionTypeForm != ''
        ) {
          return true;
        }
      } else if (this.flagsGenForm.commands) {
        if (
          this.genForm.get('startDateForm')?.valid &&
          this.genForm.get('endDateForm')?.valid
        ) {
          return true;
        }
      } else if (this.flagsGenForm.alerts) {
        if (this.genForm.get('startDateForm')?.valid &&
          this.genForm.get('endDateForm')?.valid) {
          return true;
        }
      } else if (this.flagsGenForm.noTransmission) {
        if (this.genForm.get('hoursForm')?.valid) {
          return true;
        }
      } else if (this.flagsGenForm.firstOnFirstOff) {
        if (this.genForm.get('startDateForm')?.valid) {
          return true;
        }
      } else if (this.flagsGenForm.firstOnFirstOff) {
        if (this.genForm.get('startDateForm')?.valid) {
          return true;
        }
      } else if (this.flagsGenForm.possibleStatus) {
        if (this.genForm.get('startDateForm')?.valid) {
          return true;
        }
      }
      if (this.flagsGenForm.gatewayConsumption) {
        if (this.genForm.get('gatewaySelected')?.valid &&
          this.genForm.get('startDateForm')?.valid &&
          this.genForm.get('endDateForm')?.valid) {
          return true;
        }
      }
    }
    return false;
  }
  /**
   * Gera o relatório
   */
  public generateReport(): void {
    switch (this.genForm.get('reportTypeForm')?.value) {
      case 'lastTransmissions':
        let lastTransmision = new LastTransmissionModel(
          this.genForm.get('referenceForm')?.value,
          this.timeZone
        );

        this.subscriptions = this.reportService
          .generateLastTransmissionReport(lastTransmision)
          .subscribe({
            next: () => {
              this.apiSuccessResponse();
            },
            error: (error: any) => {
              this.errorLibService.errorAlert(error);
            },
          });

        break;
      case 'installationsList':
        let installationList = new InstallationListModel(
          this.genForm.get('referenceForm')?.value
        );

        this.subscriptions = this.reportService
          .generateInstallationListReport(installationList)
          .subscribe({
            next: () => {
              this.apiSuccessResponse();
            },
            error: (error: any) => {
              this.errorLibService.errorAlert(error);
            },
          });

        break;
      case 'installationRecord':
        let installationRecord = new InstallationRecordModel(
          this.genForm.get('referenceForm')?.value,
          this.timeZone
        );

        this.subscriptions = this.reportService
          .generateInstallationRecordReport(installationRecord)
          .subscribe({
            next: () => {
              this.apiSuccessResponse();
            },
            error: (error: any) => {
              this.errorLibService.errorAlert(error);
            },
          });

        break;
      case 'latestData':
        let latestData = new LatestDataModel(
          this.genForm.get('referenceForm')?.value,
          this.timeZone
        );

        this.subscriptions = this.reportService
          .generateLatestDataReport(latestData)
          .subscribe({
            next: () => {
              this.apiSuccessResponse();
            },
            error: (error: any) => {
              this.errorLibService.errorAlert(error);
            },
          });

        break;
      case 'events':

        if (
          this.selectedInstallation.macAddress == '' ||
          this.selectedInstallation.macAddress == null
        ) {
          this.noMacAddressError();
          return;
        }

        let events = new EventsModel(
          this.genForm.get('referenceForm')?.value,
          tzConvertISOStartDate(this.genForm.get('startDateForm')?.value),
          tzConvertISOEndDate(this.genForm.get('endDateForm')?.value),
          this.selectedInstallation.macAddress,
          this.timeZone
        );

        this.subscriptions = this.reportService
          .generateEventsReport(events)
          .subscribe({
            next: () => {
              this.apiSuccessResponse();
            },
            error: (error: any) => {
              this.errorLibService.errorAlert(error);
            },
          });
        break;

      case 'status':
        if (this.selectedInstallation.macAddress == '') {
          this.noMacAddressError();
          return;
        }

        let individualStatusReport = new IndividualStatusReportModel(
          this.genForm.get('referenceForm')?.value,
          tzConvertISOStartDate(this.genForm.get('startDateForm')?.value),
          tzConvertISOEndDate(this.genForm.get('endDateForm')?.value),
          this.selectedInstallation.macAddress,
          this.timeZone
        );

        this.subscriptions = this.reportService
          .generateIndividualStatusReport(individualStatusReport)
          .subscribe({
            next: () => {
              this.apiSuccessResponse();
            },
            error: (error: any) => {
              this.errorLibService.errorAlert(error);
            },
          });

        break;
      case 'weather':
        if (
          this.selectedInstallation.macAddress == '' ||
          this.selectedInstallation.macAddress === null
        ) {
          this.noMacAddressError();
          return;
        }

        let metereologicalReport = new MetereologicalReportModel(
          this.genForm.get('referenceForm')?.value,
          tzConvertISOStartDate(this.genForm.get('startDateForm')?.value),
          tzConvertISOEndDate(this.genForm.get('endDateForm')?.value),
          this.selectedInstallation.macAddress,
          this.timeZone
        );

        this.subscriptions = this.reportService
          .generateMeteorologicalReport(metereologicalReport)
          .subscribe({
            next: () => {
              this.apiSuccessResponse();
            },
            error: (error: any) => {
              this.errorLibService.errorAlert(error);
            },
          });

        break;
      case 'individualConsumption':
        if (
          this.selectedInstallation.macAddress == '' ||
          this.selectedInstallation.macAddress == null
        ) {
          this.noMacAddressError();
          return;
        }

        let individualConsumption: IndividualConsumptionModel =
          new IndividualConsumptionModel(
            this.genForm.get('referenceForm')?.value,
            tzConvertISOStartDate(this.genForm.get('startDateForm')?.value),
            tzConvertISOEndDate(this.genForm.get('endDateForm')?.value),
            this.consumptionTypeForm,
            this.selectedInstallation.macAddress,
            this.timeZone
          );

        this.equipmentType = this.selectedInstallation.equipmentType?.toLocaleLowerCase()

        if (this.equipmentType.includes("pulso")) {
          this.subscriptions = this.reportService
            .generatePulseConsumptionReport(individualConsumption)
            .subscribe({
              next: () => {
                this.apiSuccessResponse();
              },
              error: (error: any) => {
                this.errorLibService.errorAlert(error);
              },
            });
        } else if (this.equipmentType.includes("read")) {
          this.subscriptions = this.reportService
            .generateReadConsumptionReport(individualConsumption)
            .subscribe({
              next: () => {
                this.apiSuccessResponse();
              },
              error: (error: any) => {
                this.errorLibService.errorAlert(error);
              },
            });
        } else if (this.equipmentType.includes("ipc")) {
          this.subscriptions = this.reportService
            .generateCircuitConsumptionReport(individualConsumption)
            .subscribe({
              next: () => {
                this.apiSuccessResponse();
              },
              error: (error: any) => {
                this.errorLibService.errorAlert(error);
              },
            });
        } else {
          this.subscriptions = this.reportService
            .generateIndividualConsumptionReport(individualConsumption)
            .subscribe({
              next: () => {
                this.apiSuccessResponse();
              },
              error: (error: any) => {
                this.errorLibService.errorAlert(error);
              },
            });
        }
        break;
      case 'commands':
        let commandReport = new CommandReportModel(
          this.genForm.get('referenceForm')?.value,
          tzConvertISOStartDate(this.genForm.get('startDateForm')?.value),
          tzConvertISOEndDate(this.genForm.get('endDateForm')?.value),
          this.timeZone
        );

        this.subscriptions = this.reportService
          .generateCommandsListReport(commandReport)
          .subscribe({
            next: () => {
              this.apiSuccessResponse();
            },
            error: (error: any) => {
              this.errorLibService.errorAlert(error);
            },
          });

        break;
      case 'alerts':
        let installationIdList: string[] = [];

        this.multiInstallations.forEach((installation: Installation) => {
          installation.id ? installationIdList.push(installation.id) : null;
        });

        let statusModel = new StatusModel(
          this.genForm.get('referenceForm')?.value,
          [],
          tzConvertISOStartDate(this.genForm.get('startDateForm')?.value),
          tzConvertISOEndDate(this.genForm.get('endDateForm')?.value),
          this.timeZone
        );

        this.subscriptions = this.reportService
          .generateAlertReport(statusModel)
          .subscribe({
            next: () => {
              this.apiSuccessResponse();
            },
            error: (error: any) => {
              this.errorLibService.errorAlert(error);
            },
          });

        break;
      case 'noTransmission':
        let noTransmissionReport = new NoTransmissionReportModel(
          this.genForm.get('referenceForm')?.value,
          this.genForm.get('hoursForm')?.value,
          this.timeZone
        );

        this.subscriptions = this.reportService
          .generateNoTransmissionReport(noTransmissionReport)
          .subscribe({
            next: () => {
              this.apiSuccessResponse();
            },
            error: (error: any) => {
              this.errorLibService.errorAlert(error);
            },
          });

        break;

      case 'firstOnFirstOff':
        let firstOnFirstOffReport = new FirstOnFirstOff(
          this.genForm.get('referenceForm')?.value,
          this.genForm.get('startDateForm')?.value,
          this.timeZone
        );

        this.subscriptions = this.reportService.generateFirstOnFirstOff(
          firstOnFirstOffReport.reference,
          this.dateFormatterService.dateWithHyphen(firstOnFirstOffReport.targetDate),
          firstOnFirstOffReport.timezone
        ).subscribe({
          next: () => {
            this.apiSuccessResponse();
          },
          error: (error: any) => {
            this.errorLibService.errorAlert(error);
          }
        });
        break;

      case 'possibleStatus':
        let possibleStatusReport = new PossibleStatus(
          this.genForm.get('referenceForm')?.value,
          this.genForm.get('startDateForm')?.value,
          this.timeZone
        );

        this.subscriptions = this.reportService.generatePossibleStatus(
          possibleStatusReport.reference,
          this.dateFormatterService.dateWithHyphen(possibleStatusReport.targetDate),
          possibleStatusReport.timezone
        ).subscribe({
          next: () => {
            this.apiSuccessResponse();
          },
          error: (error: any) => {
            this.errorLibService.errorAlert(error);
          }
        });
        break;

      case 'gatewayConsumption':
        this.subscriptions = this.reportService.generateGatewayConsumption(
          this.genForm.get('referenceForm')?.value,
          this.selectedGateway,
          tzConvertISOStartDate(this.genForm.get('startDateForm')?.value),
          tzConvertISOEndDate(this.genForm.get('endDateForm')?.value),
          this.timeZone
        ).subscribe({
          next: () => {
            this.apiSuccessResponse();
          },
          error: (error: any) => {
            this.errorLibService.errorAlert(error);
          }
        });
        break;

      default:
        this.showFireMessage(
          this.translateService,
          'report.errorMessages.something-wrong',
          'error',
          'Ops...'
        );
        this.resetGenerationForm();

        break;
    }

    this.ELEMENT_DATA = [];
  }

  /**
   * Filtra os relatórios.
   */
  public filterReport(cursor: string | null): void {

    if (cursor == null) {
      this.pageIndex = 0;
    }

    this.reportFilter = new ReportFilter(
      this.filterForm.get('userFilter')?.value,
      this.filterForm.get('referenceFilterForm')?.value,
      this.filterForm.get('reportTypeFilterForm')?.value
    );

    this.subscriptions = this.reportService.getAllReports(
      cursor,
      this.reportFilter,
      this.pageSize,
    )
      .valueChanges.subscribe({
        next: ((response: any) => {
          this.paginationProperties = new PaginationInfo(
            response.data.promiseReports.count,
            response.data.promiseReports.total,
            response.data.promiseReports.pageInfo.hasNextPage,
            response.data.promiseReports.pageInfo.hasPreviousPage,
            response.data.promiseReports.pageInfo.startCursor,
            response.data.promiseReports.pageInfo.endCursor
          )

          this.ELEMENT_DATA = [];
          this.length = this.paginationProperties.total;

          let responseData: any[] = response.data.promiseReports.edges;
          responseData.forEach((report: any) => {
            this.ELEMENT_DATA.push(
              new ReportTable(
                report.node.id,
                report.node.ready,
                report.node.generationDate,
                report.node.requestedBy,
                report.node.reference,
                report.node.reportType,
              ))
          })

          this.setDataSourceAttributes();

          if (!response.data.promiseReports.pageInfo.hasNextPage) {
            if (this.ELEMENT_DATA.length === 0) {
              Swal.fire({
                title: 'Sua pesquisa não retornou resultados',
                icon: 'warning',
                confirmButtonText: 'Ok',
              });
            }
          }
        })
      });
  }

  public handlePreviousPage(cursor: string | null): void {

    if (cursor == null) {
      this.pageIndex = 0;
    }

    this.reportFilter = new ReportFilter(
      this.filterForm.get('userFilter')?.value,
      this.filterForm.get('referenceFilterForm')?.value,
      this.filterForm.get('reportTypeFilterForm')?.value
    );

    this.reportService.handlePreviousPage(
      cursor,
      this.reportFilter,
      this.pageSize,
    )
      .valueChanges.subscribe({
        next: ((response: any) => {
          this.paginationProperties = new PaginationInfo(
            response.data.promiseReports.count,
            response.data.promiseReports.total,
            response.data.promiseReports.pageInfo.hasNextPage,
            response.data.promiseReports.pageInfo.hasPreviousPage,
            response.data.promiseReports.pageInfo.startCursor,
            response.data.promiseReports.pageInfo.endCursor
          )

          this.ELEMENT_DATA = [];
          this.length = this.paginationProperties.total;

          let responseData: any[] = response.data.promiseReports.edges;
          responseData.forEach((report: any) => {
            this.ELEMENT_DATA.push(
              new ReportTable(
                report.node.id,
                report.node.ready,
                report.node.generationDate,
                report.node.requestedBy,
                report.node.reference,
                report.node.reportType,
              ))
          })

          this.setDataSourceAttributes();

          if (!response.data.promiseReports.pageInfo.hasNextPage) {
            if (this.ELEMENT_DATA.length === 0) {
              Swal.fire({
                title: 'Sua pesquisa não retornou resultados',
                icon: 'warning',
                confirmButtonText: 'Ok',
              });
            }
          }
        })
      });
  }

  /**
   * Envia a requisição que solicita o URL de download do relatório disponível no servidor,
   * windows.open abre o link e baixa o arquivo.
   * @param id Id do relatório selecionado na tabela
   */
  public downloadReport(id: any): void {
    this.showFireMessage(
      this.translateService,
      'report.successMessages.downloadSuccess',
      'success',
      'Download'
    );
    this.subscriptions = this.reportService
      .getReportDownloadLink(id, localStorage.getItem('lastCompanySelected'))
      .valueChanges.subscribe((result) => {
        window.open(result.data.generatedReport.preSignedUrl);
      });
  }

  /**
   * Envia a requisição que solicita a remoção do relatório.
   * @param id Id do relatório selecionado na tabela
   */
  public deleteReport(id: any): void {
    this.subscriptions = this.translateService.get([
      "report.confirmDelete.title",
      "report.confirmDelete.text",
      "report.confirmDelete.confirm",
      "report.confirmDelete.cancel"
    ]).subscribe((message: any) => {
      Swal.fire({
        title: message["report.confirmDelete.title"],
        text: message["report.confirmDelete.text"],
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: message["report.confirmDelete.confirm"],
        cancelButtonText: message["report.confirmDelete.cancel"]
      }).then((result) => {
        if (result.isConfirmed) {
          this.subscriptions = this.reportService
            .deleteReport(id, localStorage.getItem('lastCompanySelected'))
            .subscribe({
              next: (response: any) => {
                this.filterReport(null);

                this.showFireMessage(
                  this.translateService,
                  'report.successMessages.deleteSuccess',
                  'success',
                  'Delete'
                );
              },
              error: (error: any) => {
                this.errorLibService.errorAlert(error);
              },
            });
        }
      });
    });
  }

  /**
   * HELPERS
   */

  /**
   * Função que lerá o valor do campo de busca de instalações e irá filtrar a lista de instalações para fazer a requisição baseado no valor do campo.
   */
  public showFireMessage(
    translateService: TranslateService,
    messageKey: string,
    icon: string = 'error',
    title: string = 'Oops...'
  ): void {
    this.subscriptions = translateService.get(messageKey).subscribe((message: string) => {
      const iconMap: Record<string, SweetAlertIcon> = {
        success: 'success',
        error: 'error',
        warning: 'warning',
        info: 'info',
      };
      const sweetalertIcon: SweetAlertIcon = iconMap[icon] ?? 'error';

      Swal.fire({
        icon: sweetalertIcon,
        title: title,
        text: message,
      });
    });
  }

  /**
   * Função que irá resetar o formulário de geração de relatório para o estado inicial.
   */
  public resetGenerationForm(): void {
    this.flagsGenForm = {
      lastTransmissions: false,
      installationsList: false,
      events: false,
      status: false,
      weather: false,
      individualConsumption: false,
      commands: false,
      alerts: false,
      noTransmission: false,
      installationRecord: false,
      latestData: false,
      firstOnFirstOff: false,
      PossibleStatus: false,
      gatewayConsumption: false
    };

    this.genForm.reset();

    this.consumptionTypeForm = '';
    this.isHourError = false;
    this.isLoading = false;
    this.isThereInstallationListed = false;
    this.selectedInstallation = new Installation('', '', '', '');
    this.installations = [];
    this.multiInstallations = [];
    this.isThereInstallationListed = false;
    this.gatewayList = [];
    this.selectedGateway = new Gateway('', '',);
  }

  /**
   * Função que irá resetar o formulário de filtro de relatório para o estado inicial.
   */
  public resetFilterForm(): void {
    this.filterForm.reset();
  }

  /**
   * Função que retorna uma mensagem de sucesso para a geração de relatórios
   */
  public apiSuccessResponse(): void {
    this.showFireMessage(
      this.translateService,
      'report.will-be-available-in-few-minutes',
      'success',
      'Relatório gerado com sucesso!'
    );
    // Filtra os relatórios gerados para exibir a tabela novamente
    this.filterReport(null);
    this.resetGenerationForm();
    // Realiza um segundo filtro após 10 segundos para dar um refresh na tabela
    setTimeout(() => {
      this.filterReport(null);
    }, 10000);
  }

  /**
   * Função que retorna uma mensagem de erro para quando não há o mac_address
   */
  public noMacAddressError(): void {
    this.showFireMessage(
      this.translateService,
      'report.errorMessages.noMacAddress',
      'error',
      'Ops...'
    );
  }

  /**
   * Determina quais campos do formulário de geração de relatórios serão exibidos.
   */
  public showReportForm(selectReports: any): void {
    this.flagsGenForm = {
      lastTransmissions: false,
      installationsList: false,
      events: false,
      status: false,
      weather: false,
      individualConsumption: false,
      commands: false,
      alerts: false,
      noTransmission: false,
      installationRecord: false,
      latestData: false,
      firstOnFirstOff: false,
      PossibleStatus: false,
      gatewayConsumption: false
    };

    this.flagsGenForm[selectReports] = true;

    // Verifica se tem instalações já seleciondas
    if (
      localStorage.getItem('selectedInstallations') &&
      JSON.parse(localStorage.getItem('selectedInstallations')!).length > 0
    ) {
      // Dispoem ao usuário o input de seleção de instalações
      this.isThereInstallationListed = true;

      // Adiciona as instalações
      JSON.parse(localStorage.getItem('selectedInstallations')!).forEach((installation: any) => {
        this.installations.push(
          new Installation(
            btoa(`Installation:${installation.id}`),
            installation.reference,
            installation.deviceMac,
            installation.node.device?.equipmentType.reference
          )
        );
      });
    }
    // Caso contrário o input é removido e a lista é zerada para o próximo filtro do usuário
    else {
      this.isThereInstallationListed = false;

      this.installations = [];
    }
    this.genForm.get('installationForm')?.reset();
  }

  /**
   * Verifica a existência de uma instalação
   * @param installationNodeId id da instalação
   * @returns uma instalação ou undefined
   */
  public thisInstallationExists(installationNodeId: string | null): Installation | undefined {
    let installationExists: Installation | undefined = this.installations.find(
      (installationFound) => installationFound.id === installationNodeId
    );
    return installationExists;
  }

  /**
   * Altera a formatação de uma data.
   * @param date Data vinda da requisição
   * Utilizado na tabela
   */
  public formatDate(date: string): any {
    //Converte o valor de Date para o Data do Fuso Horário do sistena ou do fuso horário especificado pelo usuário
    return tzConvertUTC2Local(date)
  }

  /**
   * Função que configura o paginator.
   */
  public setDataSourceAttributes(): void {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  /**
   * Valida o valor do input de horas sem transmissão.
   */
  public isHourStepConcluded(): void {
    if (this.genForm.get('hoursForm')?.valid) {
      this.isHourError = false;
    } else {
      this.isHourError = true;
    }
  }

  /**
   * Pega o tipo de consumo do formulário
   */
  public consumptionTypeSet(radioButton: any): void {
    this.consumptionTypeForm = radioButton;
  }

  /**
   * Método para obter a chave de tradução correta com base em 'report.reportType'
   */
  public getTranslationKey(report: any): string {
    if (report.reportType != null) {
      return `report.${report.reportType}-label`.replace(/_/g, '-');
    }

    return ""
  }

  public searchGateway(reference: string | null): any {
    /** Ativa o loading **/
    this.loadingGateway = true;

    this.gatewayList = [];

    this.subscriptions = this.installationService.getGateways(reference).valueChanges.subscribe({
      next: ((response: any) => {
        response.data.gatewayInstallation.edges.forEach((node: any) => {
          this.gatewayList.push(new Gateway(
            node.node?.id,
            node.node?.reference
          ))
        })
        /** Desativa o loading **/
        this.loadingGateway = false;

        /** Caso a lista esteja vazia **/
        if (this.gatewayList.length === 0) {
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        }
      }),
      error: ((error: any) => {
        this.errorLibService.errorAlert(error);
        console.log("searchGateways")

        /** Desativa o loading **/
        this.loadingGateway = false;
      })
    })
  }
}
