export class AuditData {
    constructor(
        public icti: number | null,
        public icvi: number | null,
        public idt: number | null,
        public opdiu: number | null,
        public opnot: number | null,
        public sdft: number | null,
        public sdot: number | null,
        public mprev: number | null
    ) { }
}