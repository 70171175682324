<div class="component-main-container">
    <!-- sessão header -->
    <div class="header">
        <app-page-header parentTitle="{{'permissions-group.parentTitle' | translate}}"
            pageTitle="{{'permissions-group.pageTitle' | translate}}"></app-page-header>
    </div>

    <!-- div responsável por fazer com que a tela se comporte como uma linha -->
    <div class="row">
        <div class="col-lg-5 col-xl-4 col-sm-12">
            <!-- div responsavel por armazenas as box da página -->
            <div class="component-forms-box">

                <!-- box responsável pela criação de grupos de permissões -->
                <div class="form-fields-box" [ngClass]="!registerPermissionsGroupBox ? 'closed' : ''"
                    [formGroup]="permissionsGroupRegisterForm">
                    <h2 (click)="registerPermissionsGroupOpen()">
                        {{'permissions-group.register-form' | translate}}
                    </h2>
                    <hr [ngClass]="!registerPermissionsGroupBox ? 'hr-open' : ''" />

                    <div class="form-step">
                        <div class="circle-number">1</div>
                        <mat-form-field appearance="outline">
                            <mat-label> {{'permissions-group.register-form-name' | translate}} </mat-label>
                            <input matInput formControlName="name" />
                        </mat-form-field>
                    </div>

                    <div class="form-step">
                        <div class="circle-number">2</div>
                        <mat-form-field appearance="outline">
                            <mat-label> {{'permissions-group.register-form-user-permission' | translate}} </mat-label>
                            <mat-select multiple>
                                <mat-option #availablePermissionsCheck
                                    (click)="selectAllAvailablePermissions(availablePermissionsCheck)">
                                    {{'permissions-group.select-all-permissions' | translate}}
                                </mat-option>
                                <mat-checkbox color="primary"
                                    *ngFor="let availablePermissions of availablePermissionsList"
                                    (change)="checkAvailablePermissions(availablePermissions.id)"
                                    [checked]="availablePermissions.checked" [id]="availablePermissions.id">
                                    {{availablePermissions.name}}
                                </mat-checkbox>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <button title="Salvar" class="btn btn-success" (click)="createPermissionsGroup()"
                        *ngIf="!createPermissionsGroupLoading">
                        {{'permissions-group.register-form-button' | translate}}
                    </button>
                    <mat-spinner *ngIf="createPermissionsGroupLoading" [diameter]="30"></mat-spinner>
                </div>

                <!-- box responsável pelo filtro de grupos de permissões -->
                <div class="form-fields-box" [ngClass]="!filterPermissionsGroupBox ? 'closed' : ''"
                    [formGroup]="permissionsGroupFilterForm">
                    <h2 (click)="filterPermissionsGroupOpen()">
                        {{'permissions-group.filter-form' | translate}}
                    </h2>
                    <hr [ngClass]="!filterPermissionsGroupBox ? 'hr-open' : ''" />

                    <div class="form-step">
                        <div class="circle-number">1</div>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'permissions-group.filter-form-name' | translate}} </mat-label>
                            <input matInput formControlName="name" />
                        </mat-form-field>
                    </div>

                    <button *ngIf="!filterPermissionsGroupLoading" title="Filtrar"
                        (click)="filterPermissionsGroup('nextPage', null)" class="btn btn-success">
                        {{'permissions-group.filter-form-button' | translate}}
                    </button>
                    <mat-spinner [diameter]="30" *ngIf="filterPermissionsGroupLoading"></mat-spinner>
                </div>
            </div>
        </div>
        <!--Tabela-->
        <div class="col-lg-7 col-xl-8 col-sm-12" *ngIf="paginationProperties.total">
            <app-table-header *ngIf="length > 0" [itensNumber]="paginationProperties.count"
                [totalNumber]="paginationProperties.total" [hasCSV]="true" [hasPDF]="true"
                (downloadCSV)="generateFileData(null, 'CSV')" (downloadPDF)="generateFileData(null, 'PDF')">
            </app-table-header>

            <div class="table-div mt-2 mt-lg-3 mt-xl-3" *ngIf="ELEMENT_DATA.length">
                <mat-progress-bar mode="indeterminate" *ngIf="filterPermissionsGroupLoading"></mat-progress-bar>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'permissions-group.table-name' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.name }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="userSet">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'permissions-group.table-user' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.userSet.count }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> {{'permissions-group.table-actions' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
                            on-mouseleave="element.view = false">
                            <button (click)="openSidebarUpdate(element, false)"
                                [ngClass]="element.view === true ? 'hover' : ' '" class="btn btn-success view">
                                <mat-icon class="component-svg-icon" svgIcon="bx-edit"></mat-icon>
                            </button>

                            <button (click)="openSidebarUpdate(element, true)"
                                [ngClass]="element.view === true ? 'hover' : ' '" class="btn btn-success view">
                                <mat-icon class="component-svg-icon" svgIcon="bx-show-white"></mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <!--Paginator-->
                <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex"
                    (page)="pageChanged($event)" aria-label="Selecione as páginas de Grupos de Permissões"
                    class="mat-paginator-sticky">
                </mat-paginator>
            </div>
        </div>
    </div>
    <!-- Modal de edição de Grupos de Permissões -->
    <div class="sidebar-edit" *ngIf="isUpdatePermissionsGroup" [formGroup]="permissionsGroupUpdateForm">
        <mat-icon (click)="closeSidebarUpdate()">clear</mat-icon>
        <h2 *ngIf="!viewController"> {{'permissions-group.edit-form' | translate}} </h2>
        <h2 *ngIf="viewController"> {{'permissions-group.view-form' | translate}} </h2>
        <hr>
        <mat-form-field appearance="outline">
            <mat-label> {{'permissions-group.edit-form-name' | translate}} </mat-label>
            <input matInput formControlName="name" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label> {{'permissions-group.edit-form-user-permissions' | translate}} </mat-label>
            <mat-select multiple>
                <mat-option #availablePermissionsCheck
                    (click)="selectAllAvailablePermissionsUpdate(availablePermissionsCheck)">
                    {{'permissions-group.select-all-permissions' | translate}}
                </mat-option>
                <mat-checkbox color="primary" *ngFor="let availablePermissions of availablePermissionsListUpdate"
                    (change)="checkAvailablePermissionsUpdate(availablePermissions)"
                    [checked]="availablePermissions.checked" [id]="availablePermissions.id">
                    {{availablePermissions.name}}
                </mat-checkbox>
            </mat-select>
        </mat-form-field>

        <button class="btn btn-success" title="Salvar" (click)="updatePermissionsGroup()"
            *ngIf="!updatePermissionsGroupLoading" [disabled]="viewController">
            {{'permissions-group.edit-form-save-button' | translate}}
        </button>
        <button class="btn btn-danger" title="Deletar" (click)="deletePermissionGroup()" [disabled]="viewController">
            {{'permissions-group.edit-form-delete-button' | translate}}
        </button>
        <mat-spinner [diameter]="30" *ngIf="updatePermissionsGroupLoading"></mat-spinner>
    </div>
</div>