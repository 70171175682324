<div class="component-main-container">
  <div class="header">
    <app-page-header parentTitle="{{ 'logs.logs-parent-label' | translate }}"></app-page-header>
  </div>

  <div class="row">
    <div class="col-lg-4 col-xl-4 col-sm-12">
      <div class="component-forms-box">
        <app-logs-form></app-logs-form>
      </div>
    </div>
  </div>
</div>
