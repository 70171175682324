export class AlertStatusCheckbox {
  constructor(
    public label: string,
    public value: string,
    public checked: boolean
  ) { }
}

export class ALertsTable {
  constructor(
    public id: string,
    public date: string,
    public point: string,
    public rule: string,
    public occurrences: string,
    public status: string,
    public total: number = 0
  ) { }
}

export class Alert {
  constructor(
    public alertDatetime: string,
    public pointReference: string,
    public status: string,
    public rule: string
  ) { }
}

export class AlertRules {
  constructor(
    public id: string,
    public name: string,
  ) { }
}

export class ServiceOrder {
  constructor(
    public id: string,
    public serviceIdentifier: string,
    public creationDate: string,
    public status: string,
  ) { }
}

export class ServiceOrderInformation {
  constructor(
    public id: string,
    public creationDate: string,
    public operator: string,
    public installation: string,
    public problem: string,
    public serviceIdentifier: string,
    public status: string,
  ) { }
}

export class GeneratedEvents {
  constructor(
    public alertRuleName: string,
    public generatedEvents: EventModel[]
  ) { }
}

export class EventModel {
  constructor(
    public eventName: string,
    public eventValue: any
  ) { }
}

export class AlertFilter {
  constructor(
    public installationReference: string | null,
    public startDateTime: string | null,
    public endDateTime: string | null,
    public status: string | null,
    public alertRuleId: string | null
  ) { }
}

export class PaginationInfo {
  constructor(
    public count: number,
    public total: number,
    public hasNextPage: boolean,
    public hasPreviousPage: boolean,
    public startCursor: string | null,
    public endCursor: string | null
  ) { }
}
