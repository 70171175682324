export class AuditInstallations {
    constructor(
        public installationId: string | null,
        public installationReference: string | null,
        public transmissionComplianceIndicator: boolean | null,
        public register: boolean | null,
        public status: boolean | null,
        public variables: boolean | null,
        public daytimeOperation: boolean | null,
        public nighttimeOperation: boolean | null,
        public actuation: string | null,
        public checked?: boolean | null
    ) { }
}

export class Installation {
    constructor(
        public installationId: string | null,
        public installationReference: string | null
    ) { }
}

export class InstallationImports {
    constructor(
        public id: string | null,
        public reference: string | null
    ) { }
}