import { EventEmitter, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FILTER_ALL_VERSIONS } from 'src/app/graphql/equipment-type.graphql';
import { FILTER_GATEWAYS } from 'src/app/graphql/gateways.graphql';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  installationByCompanyIdEvent = new EventEmitter<boolean>();

  constructor(private apollo: Apollo) { }

  getGatewayByCompanyId(id: string | null) {
    //Query for GET_GATEWAY_BY_COMPANY_ID
    return this.apollo.watchQuery<any>({
      query: FILTER_GATEWAYS,
      variables: {
        company: id,
        first: 150
      }
    })
  }

  getEquipmentTypeByCompanyId(id: string | null) {
    return this.apollo.watchQuery<any>({
      query: FILTER_ALL_VERSIONS,
      variables: {
        company: id,
        first: 150
      }
    })
  }


}
