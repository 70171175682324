import { gql } from 'apollo-angular';

/** Filtrar componentes **/
const FILTER_COMPONENTS = gql`
  query Material(
    $company: ID!
    $pageSize: Int
    $cursor: String
    $search: String
    $materialTypes: [ID]
  ) {
    material(
      company: $company
      pageSize: $pageSize
      cursor: $cursor
      search: $search
      materialTypes: $materialTypes
      isMeter: false
    ) {
      edges {
        node {
          id
          serialNumber
          type {
            id
            reference
          }
          installation {
            id
            reference
          }
        }
        cursor
      }
      total
      count
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

/** Filtrar tipos de componentes **/
const FILTER_COMPONENTS_TYPE = gql`
  query MaterialType(
    $pageSize: Int
    $cursor: String
    $company: ID!
    $manufacturer: String
    $model: String
  ) {
    materialType(
      pageSize: $pageSize
      cursor: $cursor
      company: $company
      manufacturer: $manufacturer
      model: $model
      isMeter: false
    ) {
      edges {
        node {
          id
          reference
          description
          manufacturer
          model
          modifiers {
            edges {
              node {
                propertyIn {
                  id
                }
                propertyOut {
                  id
                }
                conversionFactor
              }
            }
          }
        }
        cursor
      }
      total
      count
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

/** Criação de componentes **/
const CREATE_COMPONENT = gql`
  mutation MaterialCreate(
    $installationId: ID!
    $company: ID!
    $serialNumber: String!
    $materialType: ID!
  ) {
    materialCreate(
      input: {
        installationId: $installationId
        companyId: $company
        serialNumber: $serialNumber
        typeId: $materialType
      }
    ) {
      material {
        id
      }
    }
  }
`;

const UPDATE_COMPONENT = gql`
  mutation MaterialUpdate(
    $id: ID!
    $company: ID!
    $installationId: ID!
    $serialNumber: String!
    $materialType: ID!
  ) {
    materialUpdate(
      input: {
        id: $id
        installationId: $installationId
        companyId: $company
        serialNumber: $serialNumber
        typeId: $materialType
      }
    ) {
      material {
        id
      }
    }
  }
`;

export {
  // Queries:
  FILTER_COMPONENTS,
  FILTER_COMPONENTS_TYPE,
  // Mutations:
  CREATE_COMPONENT,
  UPDATE_COMPONENT,
};
