<div class="component-main-container">
  <div class="header">
    <app-page-header parentTitle="{{'division.parentTitle' | translate}}"
      pageTitle="{{'division.pageTitle' | translate}}"></app-page-header>
  </div>
  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-1">
      <div class="component-forms-box">
        <app-register-form></app-register-form>

        <!-- REGISTRO DE DIVISÃO -->
        <div class="form-fields-box" [ngClass]="!registerBoxOpen ? 'closed' : ''" [formGroup]="registerForm">
          <h2 (click)="registerBox()"> {{"division.register-form" | translate}}</h2>
          <hr [ngClass]="!registerBoxOpen ? 'hr-open' : ''">
          <div class="form-step">
            <div class="circle-number"> 1 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{"division.register-form-reference" | translate}} </mat-label>
              <input matInput formControlName="reference">
            </mat-form-field>
          </div>
          <!-- Botões de registro -->
          <button *ngIf="!createLoading" class="btn btn-success" (click)="registerDivision()">
            {{"division.button-save" | translate}}
          </button>
          <mat-spinner *ngIf="createLoading" [diameter]="30"></mat-spinner>
        </div>

        <!-- FILTRO DE DIVISÃO -->
        <div class="form-fields-box" [ngClass]="!filterBoxOpen ? 'closed' : ''" [formGroup]="filterForm">
          <h2 (click)="filterBox()"> {{"division.filter-form" | translate}} </h2>
          <hr [ngClass]="!filterBoxOpen ? 'hr-open' : ''">

          <div class="form-step">
            <div class="circle-number"> 1 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{"division.filter-form-reference" | translate}} </mat-label>
              <input matInput formControlName="reference">
            </mat-form-field>
          </div>

          <!-- Botões de filtro -->
          <button *ngIf="!filterLoading" class="btn btn-success" (click)="filterDivision(null)">
            {{"division.button-filter" | translate}}
          </button>
          <mat-spinner *ngIf="filterLoading" [diameter]="30"></mat-spinner>
        </div>
      </div>
    </div>

    <!-- TABELA -->
    <div class="col-lg-7 col-xl-8 col-sm-12" *ngIf="paginationProperties.total">
      <app-table-header *ngIf="length > 0" [itensNumber]="paginationProperties.count"
        [totalNumber]="paginationProperties.total" [hasCSV]="true" [hasPDF]="true" [loadingData$]="fileLoading"
        (downloadCSV)="generateFileData(null, 'CSV')" (downloadPDF)="generateFileData(null, 'PDF')">
      </app-table-header>

      <div class="table-div mt-2 mt-lg-3" *ngIf="ELEMENT_DATA.length">
        <table mat-table [dataSource]="dataSource" class=" mat-elevation-z8">

          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef> {{"division.column-reference" | translate}} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">{{ element.reference }}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> {{"division.column-actions" | translate}} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <button (click)="openSidebarUpdate(element, false)" class="btn btn-success view"
                [ngClass]="element.view === true ? 'hover' : ' '">
                <!-- SVG do ícone de edição -->
                <mat-icon class="component-svg-icon" svgIcon="bx-edit"></mat-icon>
              </button>
              <button (click)="openSidebarUpdate(element, true)" [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success view">
                <mat-icon class="component-svg-icon" svgIcon="bx-show-white"></mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <!-- Paginator -->
        <mat-paginator #paginator [pageIndex]="pageIndex" [length]="length" [pageSize]="pageSize"
          aria-label="Selecione as páginas de Empresas" class="mat-paginator-sticky" (page)="pageChanged($event)">
        </mat-paginator>
      </div>
    </div>
    <div class="sidebar-edit" *ngIf="editorController" [formGroup]="updateForm">
      <mat-icon class="close-sidebar" (click)="closeEditSidebar()">clear</mat-icon>

      <h2 *ngIf="!viewController"> {{"division.edit-form" | translate}} </h2>
      <h2 *ngIf="viewController"> {{"division.view-form" | translate}} </h2>
      <hr>

      <mat-form-field appearance="outline">
        <mat-label> {{"division.edit-form-reference" | translate}} </mat-label>
        <input matInput formControlName="reference">
      </mat-form-field>
      <button class="btn btn-success" (click)="updateDivision()"> {{"division.button-save" |
        translate}} </button>
      <button class="btn btn-danger" (click)="deleteDivision()"> {{"division.button-delete" |
        translate}} </button>
    </div>
  </div>
</div>