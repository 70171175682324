import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface UserData {
  id: string;
  name: string;
}

const userData: UserData[] = [
  {id: '1', name: 'Registro'},
];

const tableInformation = {
  columnData: ['id', 'name'],
  columnLabels: ['ID', 'Name'],
  dataSource: userData,
}


@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.less']
})
export class CustomTableComponent implements AfterViewInit {
  tableInformation = {
    columnData: ['id', 'name'],
    columnLabels: ['ID', 'Name'],
    dataSource: userData,
  }
  displayedColumns: string[] = this.tableInformation.columnData;
  dataSource: MatTableDataSource<UserData> = new MatTableDataSource(this.tableInformation.dataSource);
  

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    if(this.dataSource.data){
      this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    }
  }

}
