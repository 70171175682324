import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import Chart from 'chart.js/auto';
import { GET_INDIVIDUAL_CONSUMPTION_CHART } from 'src/app/graphql/consumption.graphql';

@Injectable({
  providedIn: 'root',
})
export class ReportChartService {
  constructor(private apollo: Apollo) { }

  /** Método que gera o gráfico com os dados do consumo retornados **/
  public generateChart(
    voltageData: any,
    currentData: any,
    apparentPowerData: any,
    consumptionData: any,
    dateTimeData: any,
    powerFactorData: any
  ) {
    let voltageChart = new Chart('MyChart', {
      type: 'line',
      data: {
        labels: dateTimeData,
        datasets: [
          {
            label: 'Fator de potência',
            data: powerFactorData,
            backgroundColor: ['rgba(15, 9, 12, 0.5)'],
            borderColor: ['rgba(15, 9, 12, 1)'],
            borderWidth: 1,
            fill: true,
            hidden: true,
          },
          {
            label: 'Consumo (kWh)',
            data: consumptionData,
            backgroundColor: ['rgba(25, 129, 32, 0.5)'],
            borderColor: ['rgba(25, 129, 32, 1)'],
            borderWidth: 1,
            fill: true,
            hidden: true,
          },
          {
            label: 'Potência aparente (VA)',
            data: apparentPowerData,
            backgroundColor: ['rgba(2, 214, 175, 0.5)'],
            borderColor: ['rgba(2, 214, 175, 1)'],
            borderWidth: 1,
            fill: true,
            hidden: true
          },
          {
            label: 'Tensão (V)',
            data: voltageData,
            backgroundColor: ['rgba(242, 74, 2, 0.5)'],
            borderColor: ['rgba(242, 74, 2, 1)'],
            borderWidth: 1,
            fill: true,
          },
          {
            label: 'Corrente (mA)',
            data: currentData,
            backgroundColor: ['rgba(155, 0, 125, 0.5)'],
            borderColor: ['rgba(155, 0, 125, 1)'],
            borderWidth: 1,
            fill: true,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return voltageChart;
  }

  public generatePulseChart(
    consumptionData: any,
    dateTimeData: any
  ) {
    let voltageChart = new Chart('MyChart', {
      type: 'line',
      data: {
        labels: dateTimeData,
        datasets: [
          {
            label: 'Consumo (m³)',
            data: consumptionData,
            backgroundColor: ['rgba(25, 129, 32, 0.5)'],
            borderColor: ['rgba(25, 129, 32, 1)'],
            borderWidth: 1,
            fill: true,
            hidden: false,
          }
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return voltageChart;
  }

  public generateReadChart(
    consumptionData: any,
    dateTimeData: any
  ) {
    let voltageChart = new Chart('MyChart', {
      type: 'line',
      data: {
        labels: dateTimeData,
        datasets: [
          {
            label: 'Consumo (kWh)',
            data: consumptionData,
            backgroundColor: ['rgba(25, 129, 32, 0.5)'],
            borderColor: ['rgba(25, 129, 32, 1)'],
            borderWidth: 1,
            fill: true,
            hidden: false,
          }
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return voltageChart;
  }

  /** Método que gera o gráfico para consumo vazio **/
  public generateEmptyConsumptionChart(range: any) {

    /** Mapear as datas do range **/
    const labels = range.map((date: any) => date.toLocaleDateString());

    /** Dados correspondentes as labels (no caso o range das datas) **/
    const dataPoints = range.map(() => 0);

    let voltageChart: any = new Chart('MyChart', {
      type: 'line',

      data: {
        labels: labels,
        datasets: [{
          label: 'Fator de Potência',
          data: dataPoints,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 3,
          fill: true,
        },
        {
          label: 'Consumo (kWh)',
          data: dataPoints,
          backgroundColor: ['rgba(25, 129, 32, 0.5)'],
          borderColor: ['rgba(25, 129, 32, 1)'],
          borderWidth: 3,
          fill: true,
        },
        {
          label: 'Potência Aparente (VA)',
          data: dataPoints,
          backgroundColor: ['rgba(2, 214, 175, 0.5)'],
          borderColor: ['rgba(2, 214, 175, 1)'],
          borderWidth: 3,
          fill: true,
        },
        {
          label: 'Tensão (V)',
          data: dataPoints,
          backgroundColor: ['rgba(242, 74, 2, 0.5)'],
          borderColor: ['rgba(242, 74, 2, 1)'],
          borderWidth: 3,
          fill: true,
        },
        {
          label: 'Corrente (mA)',
          data: dataPoints,
          backgroundColor: ['rgba(155, 0, 125, 0.5)'],
          borderColor: ['rgba(155, 0, 125, 1)'],
          borderWidth: 3,
          fill: true,
        },
        ]
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Não há dados de consumo para serem exibidos', // Exibe legenda p/ gráfico
            align: 'center',
            position: 'bottom',
            font: { size: 20 }
          }
        },
        responsive: true,
        scales: {
          x: {
            type: 'category', // O eixo x deve tratar as labels como categorias
          },
          y: {
            beginAtZero: true, // Inicia o eixo y em zero
          }
        },
        elements: {
          line: {
            tension: 0.4 // Curvatura da linha (0 para linha reta)
          },
          point: {
            radius: 3, // Tamanho padrão dos pontos
          }
        }
      },
    }
    );

    return voltageChart;
  }

  public generateEmptyPulseConsumptionChart(range: any) {

    /** Mapear as datas do range **/
    const labels = range.map((date: any) => date.toLocaleDateString());

    /** Dados correspondentes as labels (no caso o range das datas) **/
    const dataPoints = range.map(() => 0);

    let voltageChart: any = new Chart('MyChart', {
      type: 'line',

      data: {
        labels: labels,
        datasets: [
          {
            label: 'Consumo (m³)',
            data: dataPoints,
            backgroundColor: ['rgba(25, 129, 32, 0.5)'],
            borderColor: ['rgba(25, 129, 32, 1)'],
            borderWidth: 3,
            fill: true,
          },
        ]
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Não há dados de consumo para serem exibidos', // Exibe legenda p/ gráfico
            align: 'center',
            position: 'bottom',
            font: { size: 20 }
          }
        },
        responsive: true,
        scales: {
          x: {
            type: 'category', // O eixo x deve tratar as labels como categorias
          },
          y: {
            beginAtZero: true, // Inicia o eixo y em zero
          }
        },
        elements: {
          line: {
            tension: 0.4 // Curvatura da linha (0 para linha reta)
          },
          point: {
            radius: 3, // Tamanho padrão dos pontos
          }
        }
      },
    }
    );

    return voltageChart;
  }

  public generateEmptyReadConsumptionChart(range: any) {

    /** Mapear as datas do range **/
    const labels = range.map((date: any) => date.toLocaleDateString());

    /** Dados correspondentes as labels (no caso o range das datas) **/
    const dataPoints = range.map(() => 0);

    let voltageChart: any = new Chart('MyChart', {
      type: 'line',

      data: {
        labels: labels,
        datasets: [
          {
            label: 'Consumo (kWh)',
            data: dataPoints,
            backgroundColor: ['rgba(25, 129, 32, 0.5)'],
            borderColor: ['rgba(25, 129, 32, 1)'],
            borderWidth: 3,
            fill: true,
          },
        ]
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Não há dados de consumo para serem exibidos', // Exibe legenda p/ gráfico
            align: 'center',
            position: 'bottom',
            font: { size: 20 }
          }
        },
        responsive: true,
        scales: {
          x: {
            type: 'category', // O eixo x deve tratar as labels como categorias
          },
          y: {
            beginAtZero: true, // Inicia o eixo y em zero
          }
        },
        elements: {
          line: {
            tension: 0.4 // Curvatura da linha (0 para linha reta)
          },
          point: {
            radius: 3, // Tamanho padrão dos pontos
          }
        }
      },
    }
    );

    return voltageChart;
  }

  public getConsumptionData(
    startDateTime: string,
    endDateTime: string,
    installationId: string
  ) {
    return this.apollo.query({
      query: GET_INDIVIDUAL_CONSUMPTION_CHART,
      variables: {
        startDatetime: startDateTime,
        endDatetime: endDateTime,
        installationId: installationId,
      },
    });
  }
}
