import { gql } from "apollo-angular";

const CREATE_FILTER_PARAMETER = gql`
  mutation commandParameter(
    $reference: String!,
    $param: String!
    ) {
    commandParameterCreate(
      input: { reference: $reference,
        targetReference: $param
    }
    ) {
      commandParameter {
        id
        reference
        targetReference
      }
    }
  }
`;
const FILTER_PARAMETER = gql`query commandParameter(
	$reference: String,
	$pageSize: Int,
	$cursor: String,
	$param: String
) {
	commandParameter(
		reference: $reference
		pageSize: $pageSize
		cursor: $cursor
		targetReference: $param
	){
		edges {
			node {
				id
				reference
				targetReference
			}
		}
				total
				count
				pageInfo{
					hasNextPage
					hasPreviousPage
					startCursor
					endCursor
				}
	}
}`;

const UPDATE_PARAMETER = gql`
mutation commandParameter(
	$id: ID!, 
	$reference: String!, 
	$param: String!
	) {
	commandParameterUpdate(
		input: {
			id: $id ,
			reference: $reference, 
			targetReference: $param 
		}
	) {
		commandParameter {
			id
			reference
			targetReference
		}
	}
}`;

export {
	// Queries
	FILTER_PARAMETER,
	// Mutations
	CREATE_FILTER_PARAMETER,
	UPDATE_PARAMETER
}
