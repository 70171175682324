/** Classe que contém os dados que serão utilizados na lista de divisão **/

export class Division {
    constructor(
        public id: string | null,
        public reference: string | null
    ) { }
}

export class PaginationInfo {
    constructor(
        public count: number,
        public total: number,
        public hasNextPage: boolean,
        public hasPreviousPage: boolean,
        public startCursor: string | null,
        public endCursor: string | null
    ) { }
}
