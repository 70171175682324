export class Parameters {
    constructor(
        public id: string,
        public reference: string,
        public parameter: string,
    ) { }
}
export class PaginationInfo {
    constructor(
        public count: number,
        public total: number,
        public hasNextPage: boolean,
        public hasPreviousPage: boolean,
        public startCursor: string | null,
        public endCursor: string | null
    ) { }
}
