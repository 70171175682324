import { gql } from "apollo-angular";

/** Filtro de Firmwares **/
const FILTER_FIRMWARES = gql`
query firmwares(
    $major: Int, 
    $minor: Int, 
    $name: String, 
    $revision: Int,
	$pageSize: Int,
	$cursor: String
    ) {
	firmware(
        major: $major, 
        minor: $minor, 
        name: $name, 
        revision: $revision,
		pageSize: $pageSize,
		cursor: $cursor
		) {
		edges {
			node {
				id
				name
				major
				minor
				revision
				description
				releaseDate
			}
		}
		count
		total
		pageInfo{
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
	}
}`;

/** Criar Firmwares **/
const CREATE_FIRMWARES = gql`
mutation
	FirmwareCreate(
		$name: String!,
		$major: Int!,
		$minor: Int!,
		$revision: Int!,
		$description: String,
		$releaseDate: Date,
		$compatibleHardwares:[ID]) {
		firmwareCreate(
			input: {
				name: $name
				major: $major
				minor: $minor
				revision: $revision
				description: $description
				releaseDate: $releaseDate
				compatibleHardwares: $compatibleHardwares
			}) {
			firmware {
				id
			}
		}
	}`;

/** Lista Todos os Firmwares  */
const GET_ALL_FIRMWARES = gql`
query
	Firmware (
		$pageSize: Int
	){
		firmware (
			pageSize: $pageSize	 
			){
			edges {
				node {
					id
					name
					minor
					major
					revision
					description
					releaseDate
				}
			}
		}
}`;

// Filtra firmwares compativeis com o hardware selecionado pelo usuário
const FILTER_COMPATIBLE_FIRMWARE = gql`
query node($id: ID!) {
    node(id: $id) {
        ... on Hardware {
            id
            major
            minor
            revision
            description
            releaseDate
            name
            compatibleFirmware {
                edges {
                    node {
                        id
                        major
                        minor
                        revision
                        name
                    }
                }
            }
        }
    }
}`;

/** Criar novo Firmware compatível */
const CREATE_NEW_COMPATIBLE_FIRMWARE = gql`
mutation
	FirmwareCreate(
		$name: String!,
		$major: Int!,
		$minor: Int!,
		$revision: Int!,
		$description: String,
		$releaseDate: Date) {
		firmwareCreate(
			input: {
				name: $name
				major: $major
				minor: $minor
				revision: $revision
				description: $description
				releaseDate: $releaseDate
			}) {
			firmware {
				id
			}
		}
}`;

export {
	// Queries
	FILTER_FIRMWARES,
	GET_ALL_FIRMWARES,
	FILTER_COMPATIBLE_FIRMWARE,
	// Mutations
	CREATE_FIRMWARES,
	CREATE_NEW_COMPATIBLE_FIRMWARE
}