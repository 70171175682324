import { createAction, props } from "@ngrx/store";
import { ReportFilter } from "../models/reportFilter.model";

export const enter = createAction(
    '[Report Page] Entered the report page'
);

export const createReport = createAction(
    '[Report Page] Create Report',
    props<{reportType : string}>()
)

export const filterReport = createAction(
    '[Report Page] Filter Report',
    props<{reportFilter: ReportFilter}>()
)



