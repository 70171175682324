import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-circle-number',
  templateUrl: './circle-number-substep.component.html',
  styleUrls: ['./circle-number-substep.component.less']
})
export class CircleNumberSubstepComponent {

  @Input() stepNumber: string;
  @Input() isSubstep: boolean;
}
