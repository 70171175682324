import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Favorite, Installation } from './painel-fav.model';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Favorites } from '../map/ngrx/map.reducer';
import { addFavorite } from '../map/ngrx/favorite.actions';
import Swal from 'sweetalert2';
import { Unsubscriber } from '../unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-painel-fav',
  templateUrl: './painel-fav.component.html',
  styleUrls: ['./painel-fav.component.less']
})

export class PainelFavComponent extends Unsubscriber implements OnChanges {

  constructor(
    private store: Store<{ favorite: Favorites }>
  ) {

    super();

    this.subscriptions = this.store.select('favorite').subscribe((favorites) => {
      this.favoriteList = favorites;
    });

    let payloadInitial = {
      favorite: localStorage.getItem('favorites') ? JSON.parse(localStorage.getItem('favorites')!).favorites : {}
    };

    this.store.dispatch((addFavorite(payloadInitial)));
  }

  // Armazena o ID da empresa selecionada(Pode iniciar com o valor armazenado localStorage ou null)
  public companyId: string | null = localStorage.getItem('lastCompanySelected')
    ? localStorage.getItem('lastCompanySelected')
    : null;

  public panelOpenState: boolean = true;
  public isInputFocused: boolean = false;

  public favoriteList: any;

  @Input() options: Installation[] = [];
  //Armazena as opções que se encaixam no filtro do usuário
  public filteredOptions: Installation[] = [];
  //Armazena as opções selecionadas pelo usuário
  @Input() selectedOption: Installation[] = [];
  // Armazena o termo usado como filtro pelo usuário
  public searchTerm: string = '';

  public favoriteReference = new FormControl('', Validators.required);
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedOption']) {
      // Chama a função para atualizar as opções filtradas quando selectedOption mudar
      this.updateOptions();
    }
  }

  public filterOptions() {
    // Filtra as opções com base no termo de pesquisa
    this.filteredOptions = this.options.filter(option =>
      option.reference!.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  public toggleOption(option: Installation) {

    // Altera o a ordem da lista para que as instalações selecionadas apareçam primeiro
    this.filteredOptions.sort((a, b) => (b.check === a.check) ? 0 : b.check ? 1 : -1);

    // Verifica se o obj selecionado já existe na lista de selecionados
    if (this.selectedOption.includes(option)) {
      // Retira o obj da lista
      this.selectedOption = this.selectedOption.filter(item => item.id !== option.id);
      localStorage.setItem('selectedInstallations', JSON.stringify(this.selectedOption));
    } else {
      // Se não existir adiciona na lista
      this.selectedOption = [...this.selectedOption, option]
      localStorage.setItem('selectedInstallations', JSON.stringify(this.selectedOption));
    }
  }

  // Fecha o painel favorito 
  public closePainelFav() {
    this.panelOpenState = false;
  }

  // Função responsável por mostrar as opções do select de instalações
  public onFocus() {
    this.isInputFocused = true;
  }

  // Intercepta cada event de click na tela inteira
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    // Armazena o obj clicado
    const clickedElement = event.target as HTMLElement;
    // Garante que clickedElement.className seja uma string
    const classNameString = clickedElement.className.toString();
    // Verifica se o clique foi dentro do select de instalaçoes
    if (
      classNameString.includes('select-wrapper') ||
      classNameString.includes('input-filter') ||
      classNameString.includes('select') ||
      classNameString.includes('selected-options') ||
      classNameString.includes('option') ||
      classNameString.includes('input-checkbox') ||
      classNameString.includes('select-label')
    ) {
      this.updateOptions();
      return
    }
    else {
      // Se foi fora o select é fechado
      this.isInputFocused = false;
    }
  }

  public addFavorite(): any {
    if (!this.selectedOption.length) {
      return Swal.fire({
        icon: 'warning',
        text: 'Nenhuma instalação selecionada!',
      });
    }
    let payload: any;

    if (this.favoriteList?.favorites) {
      if (this.favoriteList?.favorites[this.companyId!]) {
        payload = {
          favorite: {
            ...this.favoriteList.favorites,
            [this.companyId!]: [
              ...this.favoriteList.favorites[this.companyId!],
              new Favorite(this.favoriteReference.value!, this.selectedOption)
            ]
          }
        };
      }
      else {
        payload = {
          favorite: {
            ...this.favoriteList?.favorites,
            [this.companyId!]: [
              new Favorite(this.favoriteReference.value!, this.selectedOption)
            ]
          }
        };
      }
    }
    else {
      payload = {
        favorite: {
          [this.companyId!]: [
            new Favorite(this.favoriteReference.value!, this.selectedOption)
          ]
        }
      };
    }

    this.store.dispatch((addFavorite(payload)))

    localStorage.setItem('favorites', JSON.stringify(this.favoriteList));

    Swal.fire({
      icon: 'success',
      text: 'Favorito criado!',
    });
  }

  public selectAllOptions(checkValue: boolean) {
    this.filteredOptions.forEach(option => {
      option.check = checkValue;
    });

    this.selectedOption = this.filteredOptions.filter(option => option.check === true);
    localStorage.setItem('selectedInstallations', JSON.stringify(this.selectedOption));
  }

  public updateOptions() {
    this.filteredOptions = this.options;
    this.panelOpenState = true;
  }
}




