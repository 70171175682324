import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { InstallationSiteService } from 'src/shared/services/installation-location.service';
import { InstallationSite, PaginationInfo } from './assets/installation-site-model';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import { mapStyleLight } from 'src/shared/components/map/mapAssets/map.config';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-installation-site',
  templateUrl: './installation-site.component.html',
  styleUrls: ['./installation-site.component.less']
})
export class InstallationSiteComponent extends Unsubscriber {
  constructor(
    private installationSiteService: InstallationSiteService,
    private errorLibService: ErrorLibService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

    // Variaveis responsáveis por filtrar na API do google os endereços referentes aos viewchild
    this.autocompleteRegister = new google.maps.places.Autocomplete(this.registerField.nativeElement);
    this.autocompleteFilter = new google.maps.places.Autocomplete(this.filterField.nativeElement);

    // Listener para interceptar alterações no campo de endereço completo(formulário de resgistro)
    this.autocompleteRegister.addListener('place_changed', () => {
      this.updateRegisterForm();
    });

    // Listener responsável por interceptar alterações no campo endereço completo(Formulário de filtro)
    this.autocompleteFilter.addListener('place_changed', () => {
      this.updateFilterForm();
    });
  }

  // Variaveis responsáveis por receber os endereços da lib autocomplete
  private autocompleteRegister: google.maps.places.Autocomplete | undefined;
  private autocompleteFilter: google.maps.places.Autocomplete | undefined;
  private autocompleteUpdate: google.maps.places.Autocomplete | undefined;

  // ViewChild responsáveis por receber os endereços da lib autocomplete
  @ViewChild('register') registerField: ElementRef;
  @ViewChild('filter') filterField: ElementRef;
  @ViewChild('update') updateField: ElementRef;
  /** Responsável por receber o elemento #map que é referente ao mapa no DOM **/
  @ViewChild('map', { static: false }) mapElement!: ElementRef;

  // Variável responsável por armazenar o valor que será passado para a tabela
  public ELEMENT_DATA: InstallationSite[] = [];

  // Variável que guarda o número de relatórios retornados do backend.
  public resultsLength = this.ELEMENT_DATA.length;

  // Renderiza os comandos da lista ELEMENT_DATA para a tabela
  public dataSource = new MatTableDataSource<InstallationSite>(this.ELEMENT_DATA);

  //Variáveis usadas para o paginator e para o sort.
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // Variável responsável por setar o nome das colunas da tabela
  public displayedColumns: string[] = ['reference', 'address', 'number', 'district', 'city', 'state', 'coordinates', 'actions'];

  //Define as propriedades da paginação
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null);

  // Variavel responsável por abrir e fechar a box de filtro
  public filterInstallationSiteBox: boolean = false;

  // Variavel responsável por abrir e fechar a box de registro
  public registerInstallationSite: boolean = false;

  // Variável responsável por manipular o loading do filtro de locais de instalações
  public filterInstallationSiteLoading: boolean = false;

  // Variável responsável por manipular o loading do registro de locais de instalações
  public registerInstallationSiteLoading: boolean = false;

  /** Variáveis utilizadas para geração dos arquivos **/
  public FILE_DATA: InstallationSite[] = [];
  public isReady: boolean = false;
  public fileLoading: boolean = false;

  /** Variáveis utilizadas para manipulação do Paginator **/
  public currentPage: number = 0;
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  /** Variáveis utilizadas para manipular o sidebar de edição/visualização **/
  public viewController: boolean = false;
  public sideBarOpen: boolean = false;

  /** Variável utilizada para manipular o marker quando estiver sendo arrastado ou não **/
  public markerIsDragged = false;

  /** Variáveis utilizadas para exibir o mapa e o marker do local de instalação no update **/
  public map: google.maps.Map | any;
  public marker: google.maps.Marker | any;

  /**
  * Função que configura o paginator.
  */
  public setDataSourceAttributes() {
    this.dataSource = new MatTableDataSource<InstallationSite>(this.ELEMENT_DATA);
  }

  /** Função chamada toda vez que ocorre um evento no paginator **/
  public pageChanged(event: any) {
    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.filterIntallationSite(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterIntallationSite(this.paginationProperties.endCursor)
    }
  }

  /**
  * Função responsável por manipular a box de filtro e fechar a box de registro
  */
  public filterInstallationSiteOpen() {
    this.filterInstallationSiteBox = !this.filterInstallationSiteBox;
    this.registerInstallationSite = false;
  }

  /**
  * Função responsável por manipular a registro de filtro e fechar a box de filtro
  */
  public registerInstallationSiteOpen() {
    this.registerInstallationSite = !this.registerInstallationSite;
    this.filterInstallationSiteBox = false;
  }

  // Variáveis responsáveis por armazenar os dados do formulário de registro de locais de instalações
  public createForm: FormGroup = this.formBuilder.group({
    reference: [null, Validators.required],
    country: [null],
    state: [null, Validators.required],
    city: [null, Validators.required],
    district: [null, Validators.required],
    street: [null, Validators.required],
    number: [null, Validators.required],
    details: [null, Validators.required],
    locationCode: [null, Validators.required],
    lat: [null, Validators.required],
    long: [null, Validators.required],
    address: [null]
  })

  // Variáveis responsáveis por armazenar os dados do formulário de filtro de locais de instalações
  public filterForm: FormGroup = this.formBuilder.group({
    reference: [null],
    country: [null],
    state: [null],
    city: [null],
    district: [null],
    street: [null],
    locationCode: [null],
    number: [null],
    lat: [null],
    long: [null],
    address: [null]
  })

  /** Variável que armazena os campos do formulário de edição de locais de instalações **/
  public updateForm: FormGroup = this.formBuilder.group({
    id: [null],
    lat: [null, Validators.required],
    long: [null, Validators.required],
    country: [null, Validators.required],
    district: [null, Validators.required],
    state: [null, Validators.required],
    city: [null, Validators.required],
    street: [null, Validators.required],
    number: [null, Validators.required],
    locationCode: [null],
    address: [null],
  });

  /** Campos do formulário de edição que não serão utilizados  no autocomplete do update
  * (foram criados separados para não serem resetados ao realizar o autocomplete)
  * **/
  public reference: FormControl = new FormControl(null, Validators.required);
  public details: FormControl = new FormControl(null, Validators.required);
  public id: FormControl = new FormControl(null);

  /** Não está sendo utilizado no momento até que a correção de inativar o local de instalação seja corrigido no back-end **/
  public isActive: FormControl = new FormControl();

  /**
  * Função responsável por preencher os campos do formulário de registro 
  */
  public updateRegisterForm() {
    // Constante responsável por receber o último endereço selecionado pelo usuário
    const place = this.autocompleteRegister?.getPlace();

    // Reseta todos os campos de formulário de registro
    this.createForm.reset();

    // Atualiza os campos do formulário de acordo com o último endereço selecionado
    place?.address_components?.forEach((property) => {
      if (property.types[0] === 'postal_code') {
        this.createForm.patchValue({
          locationCode: property.short_name
        });
      }
      else if (property.types[0] === "street_number") {
        this.createForm.patchValue({
          number: parseInt(property.short_name)
        });
      }
      else if (property.types[0] === "sublocality_level_1") {
        this.createForm.patchValue({
          district: property.short_name
        });
      }
      else if (property.types[0] === "administrative_area_level_2") {
        this.createForm.patchValue({
          city: property.short_name
        });
      }
      else if (property.types[0] === "administrative_area_level_1") {
        this.createForm.patchValue({
          state: property.long_name
        });
      }
      else if (property.types[0] === "country") {
        this.createForm.patchValue({
          country: property.long_name
        });
      }
      else if (property.types[0] === "route") {
        this.createForm.patchValue({
          street: property.long_name
        });
      }
    });

    this.createForm.patchValue({
      lat: place?.geometry?.location?.lat()
    });

    this.createForm.patchValue({
      long: place?.geometry?.location?.lng()
    });
  }

  /**
  * Função responsável por preencher os campos do formulário de filtro
  */
  public updateFilterForm() {
    // Variável responsável por receber o último endereço selecionado pelo usuário
    const place = this.autocompleteFilter?.getPlace();

    // Reseta todos os campos do formulário
    this.filterForm.reset();

    // Atualiza todos os campos do formulário de acordo com o último endereço selecionado
    place?.address_components?.forEach((property) => {
      if (property.types[0] === 'postal_code') {
        this.filterForm.patchValue({
          locationCode: property.short_name
        });
      }
      else if (property.types[0] === "street_number") {
        this.filterForm.patchValue({
          number: property.short_name
        });
      }
      else if (property.types[0] === "sublocality_level_1") {
        this.filterForm.patchValue({
          district: property.short_name
        });
      }
      else if (property.types[0] === "administrative_area_level_2") {
        this.filterForm.patchValue({
          city: property.short_name
        });
      }
      else if (property.types[0] === "administrative_area_level_1") {
        this.filterForm.patchValue({
          state: property.long_name
        });
      }
      else if (property.types[0] === "country") {
        this.filterForm.patchValue({
          country: property.long_name
        });
      }
      else if (property.types[0] === "route") {
        this.filterForm.patchValue({
          street: property.long_name
        });
      }
    });

    this.filterForm.patchValue({
      lat: place?.geometry?.location?.lat()
    });

    this.filterForm.patchValue({
      long: place?.geometry?.location?.lng()
    });
  }

  /**
  * Função responsável por preencher os campos do formulário de edição
  */
  public updateEditForm() {
    // Variável responsável por receber o último endereço selecionado pelo usuário
    const place = this.autocompleteUpdate?.getPlace();

    // Reseta todos os campos do formulário
    this.updateForm.reset();

    // Atualiza todos os campos do formulário de acordo com o último endereço selecionado
    place?.address_components?.forEach((property) => {
      if (property.types[0] === 'postal_code') {
        this.updateForm.patchValue({
          locationCode: property.short_name
        });
      }
      else if (property.types[0] === "street_number") {
        this.updateForm.patchValue({
          number: property.short_name
        });
      }
      else if (property.types[0] === "sublocality_level_1") {
        this.updateForm.patchValue({
          district: property.short_name
        });
      }
      else if (property.types[0] === "administrative_area_level_2") {
        this.updateForm.patchValue({
          city: property.short_name
        });
      }
      else if (property.types[0] === "administrative_area_level_1") {
        this.updateForm.patchValue({
          state: property.long_name
        });
      }
      else if (property.types[0] === "country") {
        this.updateForm.patchValue({
          country: property.long_name
        });
      }
      else if (property.types[0] === "route") {
        this.updateForm.patchValue({
          street: property.long_name
        });
      }
      else if (property.types[0] === "administrative_area_level_1") {
        this.updateForm.patchValue({
          address: property.long_name
        })
      }
    });

    this.updateForm.patchValue({
      lat: place?.geometry?.location?.lat()
    });

    this.updateForm.patchValue({
      long: place?.geometry?.location?.lng()
    });
  }

  /**
  * Função responsável por fazer a requisição de filtro dos locais de instações
  */
  public filterIntallationSite(cursor: string | null) {

    this.filterInstallationSiteLoading = true;

    if (cursor == null) {
      this.pageIndex = 0;
      this.isReady = true;
    }

    this.subscriptions = this.installationSiteService.getInstallationSite(
      this.filterForm.value.reference,
      this.filterForm.value.country,
      this.filterForm.value.state,
      this.filterForm.value.city,
      this.filterForm.value.district,
      this.filterForm.value.street,
      this.filterForm.value.locationCode,
      cursor,
      this.pageSize
    ).valueChanges.subscribe({
      next: ((response: any) => {

        //Variável responsável pelas propriedades da paginação
        this.paginationProperties = new PaginationInfo(
          response.data.installationSite.count,
          response.data.installationSite.total,
          response.data.installationSite.pageInfo.hasNextPage,
          response.data.installationSite.pageInfo.hasPreviousPage,
          response.data.installationSite.pageInfo.startCursor,
          response.data.installationSite.pageInfo.endCursor
        )

        this.ELEMENT_DATA = [];

        //Responsável por inserir os dados na lista de instalações a partir das lista de dados recebido pelo service
        response.data.installationSite.edges.forEach((node: any) => {
          this.ELEMENT_DATA.push(new InstallationSite(
            node.node.id,
            node.node.reference,
            node.node.country,
            node.node.state,
            node.node.city,
            node.node.district,
            node.node.street,
            node.node.number,
            node.node.details,
            node.node.locationCode,
            node.node.geoposition.latitude,
            node.node.geoposition.longitude,
            node.node.isActive
          ));
        });
        this.filterInstallationSiteLoading = false;

        this.length = response.data.installationSite.total;

        //Configura o paginator
        this.setDataSourceAttributes()
        this.isReady = false;

        if (this.ELEMENT_DATA.length === 0) {
          /** Retorna alerta ao usuário **/
          Swal.fire({
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        }
      }), error: ((error: any) => {
        // Emite uma mensagem de erro ao usuário
        this.errorLibService.errorAlert(error);
        console.log(error);
      })
    })
  }

  /**
  * Função responsável por fazer a requisição de cadastro dos locais de instalações
  */
  public createInstallationSite() {
    this.subscriptions = this.installationSiteService.createIntallationSite(
      this.createForm.value.reference,
      this.createForm.value.country,
      this.createForm.value.state,
      this.createForm.value.city,
      this.createForm.value.district,
      this.createForm.value.street,
      String(this.createForm.value.number),
      this.createForm.value.details,
      this.createForm.value.locationCode,
      this.createForm.value.lat,
      this.createForm.value.long
    ).subscribe({
      next: ((res: any) => {
        //Insere o novo dado criado na lista de elementos
        this.ELEMENT_DATA.push(
          new InstallationSite(
            res.data.installationSiteCreate.installationSite.id,
            res.data.installationSiteCreate.installationSite.reference,
            res.data.installationSiteCreate.installationSite.country,
            res.data.installationSiteCreate.installationSite.state,
            res.data.installationSiteCreate.installationSite.city,
            res.data.installationSiteCreate.installationSite.district,
            res.data.installationSiteCreate.installationSite.street,
            res.data.installationSiteCreate.installationSite.number,
            res.data.installationSiteCreate.installationSite.details,
            res.data.installationSiteCreate.installationSite.locationCode,
            res.data.installationSiteCreate.installationSite.lat,
            res.data.installationSiteCreate.installationSite.long,
            res.data.installationSiteCreate.installationSite.isActive
          )
        )

        //Configura o paginator
        this.setDataSourceAttributes()

        //Exibe uma modal de sucesso
        Swal.fire({
          title: 'Local de instalação',
          text: 'Local de instalação criado com sucesso',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      }), error: ((error: any) => {
        // Emite uma mensagem de erro ao usuário
        this.errorLibService.errorAlert(error);
        console.log(error);
      })
    })
  }

  /**
  * Função responsável por limpar os campos do formulário de filtro
  */
  public clearFilter() {
    this.filterForm.reset();
  }

  /**
  * Função responsável por limpar os campos do formulário de registro
  */
  public clearRegister() {
    this.createForm.reset();
  }

  /**
  * Função responsável por checar se todos os campos de cadastro foram preechidos corretamente
  */
  public checkInputsCreateInstallationSite(): any {
    if (this.createForm.invalid) {
      return Swal.fire({
        title: 'Todos os campos são obrigatórios!',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }

    // Caso todos os campos estejam preechidos a requisição é feita
    this.createInstallationSite();
  }

  /** Método utilizado para exibir o mapa de visualização apenas **/
  initMapView(element: any) {
    /** Verifica se o mapElement existe e garantir a inicialização do mapa **/
    if (this.mapElement && !this.mapElement.nativeElement.firstChild) {

      /** Instanciando o mapa com as configurações de apenas visualização **/
      this.map = new google.maps.Map(this.mapElement.nativeElement, {
        zoom: 15,
        minZoom: 2,
        center: new google.maps.LatLng(element.lat, element.long),
        styles: mapStyleLight,
        mapTypeControl: false,
        zoomControl: true,
        fullscreenControl: false,
      });

      /** Setando o marker no mapa **/
      this.marker = new google.maps.Marker({
        position: { lat: element.lat, lng: element.long },
        map: this.map,
        draggable: false,
      })
    }
  }

  /** Método responsável por inicializar o mapa no modal de update **/
  private initMap(element: any) {
    /** Verifica se o mapElement existe e garantir a inicialização do mapa **/
    if (this.mapElement && !this.mapElement.nativeElement.firstChild) {

      /** Instanciando o mapa **/
      this.map = new google.maps.Map(this.mapElement.nativeElement, {
        zoom: 15,
        minZoom: 2,
        center: new google.maps.LatLng(element.lat, element.long),
        styles: mapStyleLight,
        mapTypeControl: true,
        zoomControl: true,
        fullscreenControl: false
      });

      /** Setando o marker no mapa **/
      this.marker = new google.maps.Marker({
        position: { lat: element.lat, lng: element.long },
        map: this.map,
        draggable: true,
      });

      /** Evento que identifica quando o marker começou a ser arrastado **/
      this.marker.addListener('dragstart', () => {
        /** O marcador começou a ser arrastado **/
        this.markerIsDragged = true;
      });

      /** Evento que identifica quando o marker parou de ser arrastado **/
      this.marker.addListener('dragend', () => {
        /** O marcador terminou de ser arrastado **/
        this.markerIsDragged = false;
      });

      /** Evento para quando o marker estiver sendo arrastado **/
      this.marker.addListener('drag', () => {
        /** Toda vez que o marker for arrastado vai alterar as coordenadas lat e long dos inputs **/
        this.updateForm.get('lat')?.setValue(this.marker.getPosition().lat());
        this.updateForm.get('long')?.setValue(this.marker.getPosition().lng());
      });

      /** Adicionado um observador ao FormGroup para detectar mudanças nos valores de latitude e longitude **/
      this.updateForm.valueChanges.subscribe((formValues) => {
        if (!this.markerIsDragged && formValues !== null) {
          this.updateMarkerPosition(formValues.lat, formValues.long);
        }
      });
    }
  }

  /**  Método para atualizar a posição do marcador no mapa ao usuário alterar no input as coordenadas lat e long **/
  private updateMarkerPosition(lat: number, lng: number): void {
    const newPosition = new google.maps.LatLng(lat, lng);
    this.marker.setPosition(newPosition);
    this.map.setCenter(newPosition);
  }

  /** Método responsável por abrir e preencher o modal com os campos de edição **/
  openSidebarUpdate(element: any, isViewing: boolean) {

    /** Limpa os campos do filtro (para caso existam campos preenchidos) **/
    this.clearFilter();

    this.sideBarOpen = !this.sideBarOpen;

    this.viewController = isViewing;

    /** Caso o usuário cloque no botão de visualizar **/
    if (!isViewing) {
      /** Todos os campos do formulário são desabilitados para edição **/
      this.updateForm.disable();
      this.reference.disable();
      this.details.disable();

      /** Utilizado para garantir que o mapa seja executado após o modal ser completamente aberto **/
      setTimeout(() => {
        /** Abre o mapa para apenas visualização **/
        this.initMapView(element);
      }, 0);
    }

    /** Caso o usuário clique na opção de edição **/
    else {
      /** Habilita todos os campos para edição **/
      this.updateForm.enable();
      this.reference.enable();
      this.details.enable();

      /** Utilizado para garantir que o mapa e o autocomplete serão executados após o modal ser completamente aberto **/
      setTimeout(() => {
        /** Exibe o mapa **/
        this.initMap(element);

        /** Realiza o autocomplete dos campos **/
        this.autocompleteUpdate = new google.maps.places.Autocomplete(this.updateField.nativeElement);

        this.autocompleteUpdate.addListener('place_changed', () => {
          this.updateEditForm();
        });
      }, 0);
    }

    /** Preenche todos os campos com os dados já existentes **/
    this.updateForm.patchValue({
      lat: element.lat,
      long: element.long,
      country: element.country,
      district: element.district,
      state: element.state,
      city: element.city,
      street: element.street,
      number: element.number,
      locationCode: element.locationCode,
      address: element.address,
    });

    /** Preenche os campos de referencia e detalhes **/
    this.id.setValue(element.id);
    this.reference.setValue(element.reference);
    this.details.setValue(element.details);
    // this.isActive.setValue(element.isActive);

    /** Abre o sidebar **/
    this.sideBarOpen = true;
  }

  /** Método que realiza a atualização dos locais de instalações **/
  public updateInstallationSite() {
    /** Caso todos os campos obrigatórios do formulário sejam preenchidos **/
    if (this.updateForm.valid) {
      /** Realiza a requisição de atualização **/
      this.subscriptions = this.installationSiteService.updateInstallationSite(
        this.id.value,
        this.updateForm.value?.lat,
        this.updateForm.value?.long,
        this.reference.value,
        this.updateForm.value?.country,
        this.updateForm.value?.district,
        this.updateForm.value?.state,
        this.updateForm.value?.city,
        this.updateForm.value?.street,
        this.updateForm.value?.number,
        this.details.value,
        this.updateForm.value?.locationCode,
        true
      ).subscribe({
        next: ((res: any) => {
          /** Exibe alerta de sucesso **/
          Swal.fire({
            title: 'Local de instalação atualizado',
            text: 'Local de instalação atualizado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          /** Realiza um novo filtro **/
          this.filterIntallationSite(null);

          /** Cria um novo elemento da tabela com os dados que foram editados **/
          this.ELEMENT_DATA = [
            new InstallationSite(
              res.data.installationSiteUpdate.installationSite?.id,
              res.data.installationSiteUpdate.installationSite?.reference,
              res.data.installationSiteUpdate.installationSite?.country,
              res.data.installationSiteUpdate.installationSite?.state,
              res.data.installationSiteUpdate.installationSite?.city,
              res.data.installationSiteUpdate.installationSite?.district,
              res.data.installationSiteUpdate.installationSite?.street,
              res.data.installationSiteUpdate.installationSite?.number,
              res.data.installationSiteUpdate.installationSite?.details,
              res.data.installationSiteUpdate.installationSite?.locationCode,
              res.data.installationSiteUpdate.installationSite?.lat,
              res.data.installationSiteUpdate.installationSite?.long,
              res.data.installationSiteUpdate.installationSite?.isActive
            )
          ];
          /** Atualiza os elementos da tabela **/
          this.setDataSourceAttributes();

          /** Fecha o sidebar **/
          this.sideBarOpen = false;
        }),

        /** Caso ocorra algum erro **/
        error: ((error: any) => {
          /** Exibe o erro no console do navegador **/
          console.log("ErrorUpdateInstallationSite", error);
          this.errorLibService.errorAlert(error);
        })
      });
      /** Caso algum campo obrigatório não tenha sido preenchido **/
    } else {
      /** Exibe um alerta ao usuário **/
      Swal.fire({
        text: 'Confirme todos os dados antes de atualizar!',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }

  /** Método utilizado para fechar o sidebar de edição **/
  public closeSidebarUpdate() {
    this.sideBarOpen = false;
    this.viewController = false;
  }

  /** Método que realiza a exclusão do local de instalação **/
  public deleteInstallationSite(): any {
    /** Exibe alerta para confirmação **/
    Swal.fire({
      title: 'Tem certeza que deseja deletar o local de instalação?',
      text: 'Ao excluir este local, todas as instalações e instalações de concentradores cadastradas com este local também serão apagadas. Deseja prosseguir?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#198754',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Deletar',
      cancelButtonText: 'Cancelar'
      /** Captura o resultado **/
    }).then((result) => {
      /** Se a opção for confirmada, realiza a requisição de exclusão **/
      if (result.isConfirmed) {
        this.subscriptions = this.installationSiteService.deleteInstallationSite(this.id.value).subscribe({
          next: () => {
            /** Realiza o filtro atualizando a tabela **/
            this.filterIntallationSite(null);
            /** Fecha o sidebar de edição **/
            this.sideBarOpen = false;
            /** Exibe mensagem de sucesso **/
            Swal.fire({
              icon: 'success',
              title: 'Sucesso',
              text: 'Local de instalação deletado com sucesso'
            })
          },
          /** Captura o erro e exibe no console **/
          error: (error: any) => {
            console.log('mutation delete installation', error)
            this.errorLibService.errorAlert(error);
            this.sideBarOpen = false;
          }
        })
      }
      /** Caso não seja possível deletar exibe uma mensagem **/
      else if (result.isDenied) {
        Swal.fire({
          title: 'Exclusão não realizada',
          text: 'Tente novamente',
          icon: 'warning',
          confirmButtonText: 'Ok'
        });
      }
    })
  }

  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady === false) {
      this.subscriptions = this.installationSiteService.getInstallationSite(
        this.filterForm.value.filterReference,
        this.filterForm.value.country,
        this.filterForm.value.state,
        this.filterForm.value.city,
        this.filterForm.value.district,
        this.filterForm.value.street,
        this.filterForm.value.locationCode,
        cursor,
        200
      ).valueChanges.subscribe({
        next: ((response: any) => {
          response.data.installationSite.edges.forEach((node: any) => {
            this.FILE_DATA.push(new InstallationSite(
              node.node.id,
              node.node.reference,
              node.node.country,
              node.node.state,
              node.node.city,
              node.node.district,
              node.node.street,
              node.node.number,
              node.node.details,
              node.node.locationCode,
              node.node.geoposition.latitude,
              node.node.geoposition.longitude,
              node.node.isActive
            ));
          });

          /** Caso seja a última página a lista está completa **/
          if (!response.data?.installationSite?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }
          this.generateFileData(response.data?.installationSite?.pageInfo?.endCursor, fileType);
        }),
        error: ((error: any) => {
          this.errorLibService.errorAlert(error);
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  // Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe.
  private convertToCSV(arr: any) {
    // Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas.
    const content = [Object.keys(arr[0])].concat(arr);

    // Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um.
    return content.map((item) => {
      return Object.values(item).toString().replace(',', ';');
    }).join('\n');
  }

  // Função que realiza o download do CSV.
  public downloadCSV() {
    let content: any = [];

    // Adicoina cada elemento como um objeto na lista de conteúdo.
    this.FILE_DATA.forEach((element) => {

      content.push({
        'Referência': element.reference,
        'Endereço': element.street,
        'Número': element.number,
        'Bairro': element.district,
        'Cidade': element.city,
        'Estado': element.state,
        'Coordenadas': `${element.lat} ${element.long}`,
      });
    });

    // Adiciona o conteúdo convertido dentro de uma nova variável.
    let data = this.convertToCSV(content);
    // Transforma o conteúdo para o formato blob para geração do arquivo.
    let file = new Blob([data], { type: 'text/csv' });
    // Cria um endereçamento para o arquivo.
    let url = window.URL.createObjectURL(file);
    // Cria um elemento para a linkagem do arquivo.
    let link = document.createElement('a');
    // Adiciona a url no elemento de linkagem.
    link.href = url;
    // Nomeia o arquivo que será feito o download.
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-installation-site.csv`;
    // Adiciona o link no documento.
    document.body.appendChild(link);
    // Atica o evento de click para realizar o download.
    link.click();
    // Remove o link do documento.
    document.body.removeChild(link);
    // Remove o endereço do download.
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  // Função que realiza o download do PDF.
  public downloadPDF() {

    const tableLayout = [
      'Referência',
      'Endereço',
      'Número',
      'Bairro',
      'Cidade',
      'Estado',
      'Coordenadas',
    ]

    let content: any = []

    // Adicoina cada elemento como um objeto na lista de conteúdo.
    this.FILE_DATA.forEach((element) => {
      content.push([
        element.reference,
        element.street,
        element.number,
        element.district,
        element.city,
        element.state,
        `${element.lat}, ${element.long}`,
      ]);
    });

    // Define a orientação da tabela (paisagem nesse caso)
    const orientation = 'l';
    // Cria o objeto da lib jsPDF com a orientação e tamanho da página
    const doc: any = new jsPDF(orientation, "mm", "a4");

    // Cria a tabela com o tableLayout como header e a a lista de conteúdo como corpo da tabela
    doc.autoTable({
      theme: "grid",
      margin: { top: 20 },
      head: [tableLayout],
      body: content
    });
    // Baixa o arquivo para o usuário, definindo o nome com a data de download
    doc.save(`${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-installation-site.pdf`);

    this.FILE_DATA = [];
    this.isReady = false;
  }
}
