import { Component } from '@angular/core';

@Component({
  selector: 'app-components.modal',
  templateUrl: './components.modal.component.html',
  styleUrls: ['./components.modal.component.less']
})
export class ComponentsModalComponent {

}
