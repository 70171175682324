import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.less']
})
export class NotFoundComponent {
  
  clientX='';
  clientY='';

  @HostListener('document:mousemove', ['$event'])

  onMouseMove(e: any) {
    this.clientX = (e.clientX/5) +'px';
    this.clientY = (e.clientY/5) +'px';
  } 
  
}




  
