<div class="component-main-container">
  <div class="header">
    <app-page-header parentTitle="{{
      'unit-measurement.unit-measurement-parent-label' | translate
    }}" pageTitle="{{ 'unit-measurement.unit-measurement-page-label' | translate }}">
    </app-page-header>
  </div>
  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-12">
      <div class="component-forms-box">
        <app-register-form></app-register-form>

        <div class="form-fields-box" [ngClass]="!register ? 'closed' : ''" [formGroup]="createForm">
          <h2 (click)="Register()">
            {{ "unit-measurement.register-unit-measurement" | translate }}
          </h2>
          <hr [ngClass]="!register ? 'hr-open' : ''" />
          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "unit-measurement.form-name" | translate
                }}</mat-label>
              <input matInput formControlName="reference" />
            </mat-form-field>
          </div>
          <div class="form-step">
            <div class="circle-number">2</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "unit-measurement.form-symbol" | translate
                }}</mat-label>
              <input matInput formControlName="symbol" />
            </mat-form-field>
          </div>

          <button title="Salvar" class="btn btn-success" (click)="createUnits()">
            {{ "unit-measurement.button-save" | translate }}
          </button>
        </div>

        <div class="form-fields-box" [ngClass]="!filter ? 'closed' : ''" [formGroup]="filterForm">
          <h2 (click)="Filter()">
            {{ "unit-measurement.filter-unit-measurement" | translate }}
          </h2>
          <hr [ngClass]="!filter ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "unit-measurement.form-name" | translate
                }}</mat-label>
              <input matInput formControlName="reference" />
            </mat-form-field>
          </div>
          <mat-spinner *ngIf="filterLoading" [diameter]="30"></mat-spinner>
          <button *ngIf="!filterLoading" title="Filtrar" class="btn btn-success" (click)="filterUnits(null)">
            {{ "unit-measurement.button-filter" | translate }}
          </button>
        </div>
      </div>
    </div>

    <!-- Sessão tabela -->
    <div class="col-lg-7 col-xl-8 col-sm-12">
      <app-table-header [itensNumber]="paginator.pageSize" [totalNumber]="paginationProperties.total" [hasCSV]="true"
        [hasPDF]="true" (downloadCSV)="generateFileData(null, 'CSV')" (downloadPDF)="generateFileData(null, 'PDF')"
        [loadingData$]="fileLoading"></app-table-header>

      <div class="table-div mt-2 mt-lg-3 mt-xl-3">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
              {{ "unit-measurement.form-name" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef>
              {{ "unit-measurement.form-symbol" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.symbol }}</td>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
              {{ "unit-measurement.actions-table" | translate }}
            </th>

            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <button [ngClass]="element.view === true ? 'hover' : ' '" class="btn btn-success view"
                (click)="OpenUpdateSideBar(element)">
                <mat-icon class="component-svg-icon" svgIcon="bx-edit"></mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <!--Paginator-->
        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex"
          (page)="pageChanged($event)" aria-label="Selecione as páginas de Un. Medida" class="mat-paginator-sticky">
        </mat-paginator>
      </div>
    </div>
  </div>

  <!--Sidebar edit-->
  <div class="sidebar-edit" *ngIf="openSideBar" [formGroup]="updateForm">
    <mat-icon (click)="openSideBar = false">clear</mat-icon>
    <h2>{{"unit-measurement.edit-unit-measurement" | translate}}</h2>
    <hr>
    <mat-form-field appearance="outline">
      <mat-label>{{ "unit-measurement.form-name" | translate }}</mat-label>
      <input matInput formControlName="reference" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ "unit-measurement.form-symbol" | translate }}</mat-label>
      <input matInput formControlName="symbol" />
    </mat-form-field>
    <button class="btn btn-success" (click)="updateUnits()">{{"unit-measurement.button-edit" | translate}}</button>
  </div>
</div>