import { gql } from "apollo-angular";

const FILTER_USER_LOG = gql`
query UserLogs(
  $company: ID!,
  $operation: String,
  $startDatetime: DateTime,
  $endDatetime: DateTime,
  $requestedField: String,
  $username: String,
	$first: Int,
	$cursor: String
  ){
  userLogs(
		companyId: $company,
    operationType: $operation,
    requestDatetime_Gte: $startDatetime,
    requestDatetime_Lte: $endDatetime,
    requestedField: $requestedField,
    user_Username: $username,
		first: $first,
		after: $cursor
	){
    pageInfo{
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
		count
		total
		edges{
			node{
				requestDatetime
				operationType
				requestedField
				user{
					username
				}
			}
		}
	}
}`;

const FILTER_INSTALLATION_LOG = gql`
query InstallationLog(
	$company: ID!
	$operation: Operations
	$startDatetime: DateTime
	$endDatetime: DateTime
	$username: String
	$pageSize: Int
	$cursor: String
	$installationID: ID
) {
	installationLog(
		company: $company
		operation: $operation
		startDatetime: $startDatetime
		endDatetime: $endDatetime
		username: $username
		pageSize: $pageSize
		cursor: $cursor
		installationId: $installationID
	) {
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
		count
		total
		edges {
			node {
				id
				installationId
				operation
				equipmentSerialNumber
				previousEquipmentSerialNumber
				reference
				siteReference
				gatewayReference
				username
				isActive
				lampModel
				logDatetime
				divisionReference
				company {
					name
				}
			}
		}
	}
}`;

const FILTER_EQUIPMENT_LOG = gql`
query EquipmentLog(
  $company: ID!,
  $operation: Operations,
  $startDatetime: DateTime,
  $endDatetime: DateTime,
  $serialNumber: String,
  $macAddress: String,
  $username: String,
  $pageSize: Int,
  $cursor: String
  ){
    equipmentLog(
      company: $company,
      operation: $operation,
      startDatetime: $startDatetime,
      endDatetime: $endDatetime,
      serialNumber: $serialNumber,
      macAddress: $macAddress,
      username: $username,
      pageSize: $pageSize,
      cursor: $cursor
      ){
        pageInfo{
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        count
		    total
        edges{
            node{
                operation
                serialNumber
                macAddress
                username
                logDatetime
                company{
                    id
                    name
                    cnpj
                }
            }
        }
    }
}`;

const FILTER_INSTALLATION_SITE_LOG = gql`
query InstallationSiteLog(
  $company: ID!,
  $operation: Operations,
  $startDatetime: DateTime,
  $endDatetime: DateTime,
  $reference: String,
  $username: String,
  $pageSize: Int,
  $cursor: String
  ){
installationSiteLog(
	company: $company,
	operation: $operation,
	startDatetime: $startDatetime,
	endDatetime: $endDatetime,
	reference: $reference,
	username: $username,
  pageSize: $pageSize,
  cursor: $cursor
		) {
		pageInfo{
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
		count
		total
		edges{
			node{
				geoposition{
					latitude
					longitude
				}
				operation
				reference
				username
				isActive
				logDatetime
				company{
					name
				}
			}
		}
	}
}`;

export {
	FILTER_USER_LOG,
	FILTER_INSTALLATION_LOG,
	FILTER_EQUIPMENT_LOG,
	FILTER_INSTALLATION_SITE_LOG
}