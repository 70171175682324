import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  CREATE_CIRCUIT_BOX,
  GET_CIRCUIT_BOX,
  UPDATE_CIRCUIT_BOX
} from 'src/app/graphql/circuit-box.graphql';
import { FILTER_ALL_EQUIPMENT_TYPES } from 'src/app/graphql/equipment-type.graphql';
import { FILTER_EQUIPMENTS } from 'src/app/graphql/equipment.graphql';
import { FILTER_GATEWAYS } from 'src/app/graphql/gateways.graphql';


@Injectable({
  providedIn: 'root'
})
export class CircuitBoxService {

  constructor(private apollo: Apollo) { }

  company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;

  handlerRegister(reference: string, consumption: string, gateway: string, ipc: string) {
    return this.apollo.mutate({
      mutation: CREATE_CIRCUIT_BOX,
      variables: {
        reference,
        sgIpc: ipc,
        sgGateway: gateway,
        sgConsumption: consumption
      }
    })
  }

  handlerFilter(reference: string, cursor: string | null, pageSize: number | null) {
    return this.apollo.watchQuery({
      query: GET_CIRCUIT_BOX,
      variables: {
        company: this.company,
        reference,
        first: pageSize,
        cursor,
        sort_dir: "ASC",
        sort_field: "NAME"
      }
    })
  }

  handlerPrevious(reference: string, cursor: string | null, pageSize: number | null) {
    return this.apollo.watchQuery({
      query: GET_CIRCUIT_BOX,
      variables: {
        company: this.company,
        reference,
        first: pageSize,
        cursor,
        sort_dir: "ASC",
        sort_field: "NAME"
      }
    })
  }


  handlerUpdate(id: string, reference: string, consumption: string, gateway: string, ipc: string) {
    return this.apollo.mutate({
      mutation: UPDATE_CIRCUIT_BOX,
      variables: {
        id,
        reference,
        sgIpc: ipc,
        sgGateway: gateway,
        sgConsumption: consumption
      }
    })
  }


  handlerFilterEquipment(serialNumber: any) {
    return this.apollo.watchQuery({
      query: FILTER_EQUIPMENTS,
      variables: {
        company: this.company,
        serialNumber: serialNumber,
        sort_dir: "ASC",
        sort_field: "SERIAL_NUMBER"
      }
    })
  }

  handleFilterGateway() {
    return this.apollo.query({
      query: FILTER_GATEWAYS,
      variables: {
        company: this.company,
        sort_dir: "ASC",
        sort_field: "REFERENCE"
      }
    })
  }

  handlerFilterEquipmentType(reference: string) {
    return this.apollo.query({
      query: FILTER_ALL_EQUIPMENT_TYPES,
      variables: {
        reference: reference
      }
    })
  }
}
