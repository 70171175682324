import { gql } from "apollo-angular";

// Requisição de Filtrar comandos (Tela de comandos)
const COMMAND_BULK_REQUESTS = gql`
query CommandBulkRequests(
	$company: ID!
	$commandType: String
	$first: Int
	$last: Int
	$customBefore: String
	$customAfter: String
	$sort_field: CommandBulkRequestOrderingFields!
	$sort_dir: CommandBulkDirection!
	$total: Boolean
) {
	commandBulkRequests(
		company: $company
		commandType: $commandType
		first: $first
		last: $last
		customBefore: $customBefore
		customAfter: $customAfter
		orderBy: { direction: $sort_dir, field: $sort_field }
		total: $total
	) {
		edges {
			node {
				id
				type
				requestDt
				parameter
				value
				channel
				dimming
				queryType
				scheduleSlot
				switchOnTime
				switchOffTime
				commands {
					edges {
						node {
							id
							reference
							macAddress
							status
							statusDetail
							responseGenerationDt
							responseReceiptDt
							responseData
							commandReference
							parameterReference
							layout
						}
					}
				}
				user {
					firstName
					lastName
				}
			}
			cursor
		}
		total
		count
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
	}
}`;

// Mutation para fazer um agendamento novo (Tela de comandos)
const SET_SCHEDULE = gql`
mutation setSchedule(
	$channel: Int
	$devices: [String!]!
	$scheduleSlot: Int!
	$dimming: Int!
	$switchOnTime: Time!
	$switchOffTime: Time!
	$requestDt: DateTime!
) {
	sendSetScheduleCommand(
		input: {
			channel: $channel
			deviceMacs: $devices
			scheduleSlot: $scheduleSlot
			dimming: $dimming
			switchOnTime: $switchOnTime
			switchOffTime: $switchOffTime
			requestDt: $requestDt
		}
	) {
		commandBulkRequest {
			id
			type
			user {
				id
				username
			}
			company {
				id
				name
			}
			requestDt
			commands {
				edges {
					node {
						macAddress
						status
						statusDetail
						responseGenerationDt
						responseReceiptDt
						responseData
						commandReference
						parameterReference
						layout
					}
				}
			}
			parameter
			value
			channel
			dimming
			queryType
			scheduleSlot
			switchOffTime
			switchOnTime
		}
	}
}`;

const SEND_QUERY_COMMAND = gql`
mutation SendQueryCommand(
	$devices: [String]!
	$type: QueryCommandType!
	$requestDt: DateTime!
) {
	sendQueryCommand(
		input: { deviceMacs: $devices, type: $type, requestDt: $requestDt }
	) {
		commandBulkRequest {
			id
			commands {
				edges {
					node {
						id
						status
					}
				}
			}
		}
	}
}`;

// requisição usada para fazer consultas de agendamentos ativos (tela de comandos)
const SEND_QUERY_SCHEDULE_COMMAND = gql`
mutation SendQueryScheduleCommand(
	$devices: [String]!
	$requestDt: DateTime!
	$scheduleSlot: Int!
) {
	sendQueryScheduleCommand(
		input: {
			deviceMacs: $devices
			requestDt: $requestDt
			scheduleSlot: $scheduleSlot
		}
	) {
		commandBulkRequest {
			id
			type
			user {
				username
			}
			commands {
				edges {
					node {
						statusDetail
						id
						status
					}
				}
			}
		}
	}
}`;

// Mutation para desativar um equipamento por comando (Tela de comandos)
const SEND_SWITCH_ON_COMMAND = gql`
mutation sendSwitchOnCommand($devices: [String]!, $requestDt: DateTime!) {
	sendSwitchOnCommand(input: { deviceMacs: $devices, requestDt: $requestDt }) {
		commandBulkRequest {
			id
			commands {
				edges {
					node {
						id
						status
					}
				}
			}
		}
	}
}`;

// Mutation para desativar equipamentos através de comando (Tela de comandos)
const SEND_SWITCH_OFF_COMMAND = gql`
mutation sendSwitchOffCommand($devices: [String]!, $requestDt: DateTime!) {
	sendSwitchOffCommand(input: { deviceMacs: $devices, requestDt: $requestDt }) {
		commandBulkRequest {
			id
			commands {
				edges {
					node {
						id
						status
					}
				}
			}
		}
	}
}`;

// Mutation para enviar um novo comando para equipamentos (tela de comandos)
const SEND_SET_PARAMETER_COMMAND = gql`
mutation SendSetParameterCommand(
	$devices: [String]!
	$parameter: String!
	$requestDt: DateTime!
	$value: GenericScalar!
) {
	sendSetParameterCommand(
		input: {
			deviceMacs: $devices
			parameter: $parameter
			value: $value
			requestDt: $requestDt
		}
	) {
		commandBulkRequest {
			id
			commands {
				edges {
					node {
						id
						status
					}
				}
			}
		}
	}
}`;

export {
	// Queries
	COMMAND_BULK_REQUESTS,
	// Mutations
	SET_SCHEDULE,
	SEND_QUERY_COMMAND,
	SEND_SWITCH_ON_COMMAND,
	SEND_SWITCH_OFF_COMMAND,
	SEND_SET_PARAMETER_COMMAND,
	SEND_QUERY_SCHEDULE_COMMAND
};