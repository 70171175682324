import { gql } from "apollo-angular";

const FILTER_PROPERTIES = gql`
query Property(
    $pageSize: Int,
    $cursor: String,
    $nameContains: String,
    ) {
    property(
        pageSize: $pageSize,
        cursor: $cursor,
        nameContains: $nameContains,
        orderBy:{
				direction: ASC,
				field: NAME
				}
        ) {
        edges {
            node {
                id
                sourceName
                name
                shortName
                service
                measurementUnit{
                    id
                    name
                    symbol
                }
            }
            cursor
        }
        total
        count
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
    }
}`;

const CREATE_PROPERTIES = gql`
mutation
	PropertyCreate(
		$name: String!,
		$sourcename: String!,
		$shortname: String!,
		$service: String!,
		$measurementunit:ID) {
		    propertyCreate(
				input: {
					name: $name,
					sourceName: $sourcename,
					shortName: $shortname,
					service: $service,
					measurementUnit: $measurementunit
					}) {
					property {
						id
					}
				}
	}`;

const UPDATE_PROPERTIES = gql`
mutation 
PropertyUpdate(
	$id:ID!,
	$name: String!,
	$sourcename: String!,
	$shortname: String!,
	$service: String!,
	$measurementunit:ID!) {
		propertyUpdate(
			input: {
				id: $id,
				name: $name,
				sourceName: $sourcename,
				shortName: $shortname,
				service: $service,
				measurementUnit: $measurementunit
			}) {
			property {
				id
                name
				sourceName
                shortName
                service
                measurementUnit{
                    id
                    name
                    symbol
                }				
			}
	}
}`;

export {
    // Queries
    FILTER_PROPERTIES,
    // Mutations
    CREATE_PROPERTIES,
    UPDATE_PROPERTIES
}