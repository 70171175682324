/** Model para propriedades **/
export class Properties {
    constructor(
        public id: string,
        public name: string | null,// nome
        public sourceName: string | null, //fonte
        public shortName: string | null, // abreviação
        public service: string | null, // serviço
        public measurementUnit: MeasurementUnit
    ){}
}

/** Model para unidades de medida **/

export class MeasurementUnit {
    constructor(
        public id: string,
        public name: string | null,
        public symbol: string | null
    ){}
}

/** Model para o paginator **/
export class PaginationInfo {
    constructor(
      public count: number,
      public total: number,
      public hasNextPage: boolean,
      public hasPreviousPage: boolean,
      public startCursor: string | null,
      public endCursor: string | null
    ) {}
}
