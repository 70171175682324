export class InstallationLog {
  constructor(
    public id: string | null,
    public username: string | null,
    public operation: string | null,
    public logDatetime: string,
    public equipmentSerialNumber: string | null,
    public previousEquipmentSerialNumber: string | null,
    public reference: string | null,
    public siteReference: string | null,
    public gatewayReference: string | null,
    public isActive: any,
    public lampModel: any,
    public divisionReference: string | null,
    public installationId: string | null
  ) { }
}
export class Installation {
  constructor(
    public id: string | null,
    public reference: string | null
  ) { }
}

export class PaginationInfo {
  constructor(
    public count: number,
    public total: number,
    public hasNextPage: boolean,
    public hasPreviousPage: boolean,
    public startCursor: string | null,
    public endCursor: string | null
  ) { }
}
