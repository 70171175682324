import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DivisionService } from 'src/shared/services/divisions.service';
import { Division, PaginationInfo } from './model/division.model';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.less']
})
export class DivisionComponent extends Unsubscriber implements OnInit {

  @Input() totalNumber: number = 10;
  @Input() hasCSV: boolean = false;
  @Input() hasPDF: boolean = false;

  constructor(
    public divisionService: DivisionService,
    public errorLibService: ErrorLibService,
    public formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  /** Variável que armazena as colunas da tabela **/
  public displayedColumns: string[] = ['reference', 'actions'];

  /** Variáveis utilizadas para manipulação do Paginator **/
  public currentPage: number = 0;
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 10;

  /** Define as propriedades de paginação **/
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null)

  /** Variáveis utilizadas para geração e manipulação dos arquivos PDF e CSV **/
  public FILE_DATA: Division[] = [];
  public isReady = false;

  /** Variável que contém a lista de divisão utilziada para popular a tabela ao realizar o filtro de divisão **/
  public ELEMENT_DATA: Division[] = [];

  /** Variáveis de loading **/
  public filterLoading: boolean = false;
  public createLoading: boolean = false;
  public fileLoading: boolean = false;

  /** Variável utilizada para armazenar o último filtro feito pelo usuário **/
  public filteredValue: string;

  public dataSource = new MatTableDataSource<Division>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  /** Função chamada toda vez que ocorre um evento no paginator **/
  pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.filterDivision(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterDivision(this.paginationProperties.endCursor)
    }
  }

  /** Método e variáveis utilizadas no paginator **/
  public setDataSourceAttributes() {
    this.dataSource = new MatTableDataSource<Division>(this.ELEMENT_DATA);
  }

  /** Variáveis para manipular o box de registro e filtro **/
  public registerBoxOpen: boolean = false
  public filterBoxOpen: boolean = false;

  /** Métodos quer realizam a manipulação das variáveis do box de filtro e registro de divisões **/
  public registerBox() {
    this.filterBoxOpen = false;
    this.registerBoxOpen = !this.registerBoxOpen
  }

  public filterBox() {
    this.registerBoxOpen = false;
    this.filterBoxOpen = !this.filterBoxOpen
  }

  /** Contém os elementos utilizados no formulário de filtro **/
  public filterForm: FormGroup = this.formBuilder.group({
    reference: [null],
    divisionId: [null]
  })

  /** Contém os elementos utilizados no formulário de registro **/
  public registerForm: FormGroup = this.formBuilder.group({
    reference: [null, Validators.required]
  })

  /** Contém os elementos utilizados no formulário de edição **/
  public updateForm: FormGroup = this.formBuilder.group({
    id: [null],
    reference: [null]
  })

  /** Método que realiza o filtro de divisão **/
  public filterDivision(cursor: string | null) {

    /** Armazena o filtro realizado pelo usuário **/
    this.filteredValue = this.filterForm.get("reference")?.value;

    this.filterLoading = true;

    /** Reseta o índice da página ao realizar o filtro sem cursor **/
    if (cursor == null) {
      /** Reseta o índice da página e força a conclusão do arquivo que está sendo gerado **/
      this.pageIndex = 0;
      this.isReady = true;
    }

    /** Realiza o filtro para preencher a tabela **/
    this.subscriptions = this.divisionService.filterDivisions(
      this.filteredValue,
      cursor,
      this.pageSize
    ).valueChanges.subscribe({
      next: ((response: any) => {
        this.paginationProperties = new PaginationInfo(
          response.data?.division?.count,
          response.data?.division?.total,
          response.data?.division?.pageInfo?.hasNextPage,
          response.data?.division?.pageInfo?.hasPreviousPage,
          response.data?.division?.pageInfo?.startCursor,
          response.data?.division?.pageInfo?.endCursor
        )

        this.ELEMENT_DATA = [];

        response.data.division.edges.forEach((node: any) => {
          this.ELEMENT_DATA.push(new Division(
            node?.node?.id,
            node?.node?.reference
          ))
        });

        this.length = this.paginationProperties.total;

        /** Atualiza a tabela com os novos dados **/
        this.setDataSourceAttributes();
        this.isReady = false;

        if (this.ELEMENT_DATA.length === 0) {
          /** Retorna alerta ao usuário **/
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        }

        this.filterLoading = false;

      }),
      error: ((error: any) => {
        this.errorLibService.errorAlert(error);
      })
    })
  }

  /** Método que realiza o registro de divisão **/
  public registerDivision() {

    this.createLoading = true;

    if (this.registerForm.valid) {
      this.subscriptions = this.divisionService.createDivision(
        this.registerForm?.value.reference
      ).subscribe({
        next: (() => {
          this.filterForm.patchValue({
            reference: this.registerForm?.value.reference
          });

          this.filterDivision(null);

          this.filterForm.patchValue({
            reference: null
          })
          Swal.fire({
            title: 'Divisão criada',
            text: 'Divisão criada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          this.createLoading = false;

        })
      })
    }
    else {
      Swal.fire({
        title: 'Formulário Incompleto',
        text: 'Preencha todos os campos',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });

      this.createLoading = false;
    }
  }

  public viewController: boolean = false;
  public editorController: boolean = false;

  public closeEditSidebar() {
    this.editorController = false;
    this.viewController = false;
  }

  public openSidebarUpdate(element: any, isViewing: boolean): any {
    this.viewController = isViewing;

    if (isViewing) {
      this.updateForm.disable()
    }
    else {
      this.updateForm.enable();
    }

    this.updateForm.get('id')?.setValue(element?.id)
    this.updateForm.get('reference')?.setValue(element?.reference)

    this.editorController = true;
  }

  /** Método que realiza a edição de divisão **/
  public updateDivision() {
    if (this.updateForm.valid) {
      this.subscriptions = this.divisionService.updateDivision(
        this.updateForm?.value?.id,
        this.updateForm?.value?.reference
      ).subscribe({
        next: ((response: any) => {
          Swal.fire({
            title: 'Divisão atualizada',
            text: 'Divisão atualizada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });
          this.ELEMENT_DATA = [
            new Division(
              response.data.divisionUpdate.division?.id,
              response.data.divisionUpdate.division?.reference,
            )
          ];
          this.setDataSourceAttributes();
          this.editorController = false;
        }),
        error: (error: any) => {
          console.log("updateDivision", error)
          this.errorLibService.errorAlert(error);
        }
      })
    }
    else {
      Swal.fire({
        text: 'Confirme os dados antes de atualizar',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }

  /** Método que realiza a exclusão de uma divisão **/
  public deleteDivision(): any {
    Swal.fire({
      title: 'Tem certeza que deseja deletar essa divisão?',
      text: 'Esta ação poderá afetar em outras funcionalidades do sistema que dependem desta divisão',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#198754',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Deletar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.subscriptions = this.divisionService.deleteDivision(this.updateForm?.value?.id).subscribe({
          next: () => {

            this.filterDivision(null);

            this.editorController = false;

            Swal.fire({
              title: 'Divisão deletada',
              text: 'Divisão deletada com sucesso',
              icon: 'success',
              confirmButtonText: 'Ok'
            });
          },
          error: (error: any) => {
            console.log("deleteDivision", error);
          }
        })
      }
    })
  }
  /** 
   * Função criada para filtrar todos os elementos
   * e colocar em uma lista para transformar em PDF/CSV depois 
   * **/
  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscriptions = this.divisionService.filterDivisions(
        this.filteredValue,
        cursor,
        1
      ).valueChanges.subscribe({
        next: ((response: any) => {
          response.data.division.edges.forEach((node: any) => {
            this.FILE_DATA.push(new Division(
              node?.node?.id,
              node?.node?.reference
            ))
          });
          /** Caso seja a última página a lista está completa **/
          if (!response.data?.division?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.division?.pageInfo?.endCursor, fileType);
        }),
        error: ((error: any) => {
          this.errorLibService.errorAlert(error);
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  /** Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe **/
  private convertToCSV(arr: any) {
    /** Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas **/
    const content = [Object.keys(arr[0])].concat(arr);

    /** Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um **/
    return content.map((item) => {
      return Object.values(item).toString().replace(',', ';');
    })
      .join('\n');
  }
  /** Formata e realiza o download do CSV **/
  private downloadCSV() {
    let content: any = [];
    /** Adiciona cada elemento como um objeto na lista de conteúdo **/
    this.FILE_DATA.forEach((element) => {
      content.push({
        'Referência': element.reference
      });
    });

    /** Adiciona o conteúdo convertido dentro de uma nova variável. **/
    let data = this.convertToCSV(content);
    /**  Transforma o conteúdo para o formato blob para geração do arquivo. **/
    let file = new Blob([data], { type: 'text/csv' });
    /** Cria um endereçamento para o arquivo. **/
    let url = window.URL.createObjectURL(file);
    /** Cria um elemento para a linkagem do arquivo. **/
    let link = document.createElement('a');
    /** Adiciona a url no elemento de linkagem. **/
    link.href = url;
    /**  Nomeia o arquivo que será feito o download. **/
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-division.csv`;
    /** Adiciona o link no documento. **/
    document.body.appendChild(link);
    /** Atica o evento de click para realizar o download. **/
    link.click();
    /** Remove o link do documento. **/
    document.body.removeChild(link);
    /**  Remove o endereço do download. **/
    window.URL.revokeObjectURL(url);

    /** Limpa a lista usada para gerar o arquivo e reinicia o status do arquivo**/
    this.FILE_DATA = [];
    this.isReady = false;
  }

  /** Formata e realiza o download do PDF **/
  private downloadPDF() {
    // Colunas que serão adicionadas ao header da tabela no PDF
    const tableLayout = [
      "Referência",
    ];

    let content: any = []
    // Adiciona os elementos na lista de conteúdo como um array
    this.FILE_DATA.forEach((element) => {
      content.push([
        element.reference
      ]);
    });
    // Define a orientação da tabela (paisagem nesse caso)
    const orientation = 'l';
    // Cria o objeto da lib jsPDF com a orientação e tamanho da página
    const doc: any = new jsPDF(orientation, "mm", "a4");

    // Cria a tabela com o tableLayout como header e a a lista de conteúdo como corpo da tabela
    doc.autoTable({
      theme: "grid",
      margin: { top: 20 },
      head: [tableLayout],
      body: content
    });
    // Baixa o arquivo para o usuário, definindo o nome com a data de download
    doc.save(`${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-division.pdf`);

    /** Limpa a lista usada para gerar o arquivo e reinicia o status do arquivo**/
    this.FILE_DATA = [];
    this.isReady = false;
  }
}
