<div class="component-main-container">
  <div class="header">
    <app-page-header parentTitle="{{ 'components.components-parent-label' | translate }}"
      pageTitle="{{ 'components.components-title-label' | translate }}">
    </app-page-header>
  </div>

  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-12">
      <div class="component-forms-box">
        <app-register-form></app-register-form>
        <!-- Sessão de filtro de Componentes -->
        <div class="form-fields-box" [ngClass]="!filterComponents ? 'closed' : ''" [formGroup]="filterComponentForm">
          <h2 (click)="filterComponentsOpen()">
            {{ "components.form-filter-label" | translate }}
          </h2>
          <hr [ngClass]="!filterComponents ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "components.form-filter-keyword" | translate }}
              </mat-label>
              <input matInput #search formControlName="keyword" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">2</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "components.form-filter-select-component" | translate }}
              </mat-label>
              <mat-select #materialTypes formControlName="componentType">
                <mat-option appearance="outline" *ngFor="let item of componentsTypeList" [value]="item.id">
                  {{ item.reference }}
                </mat-option>
                <mat-option *ngIf="getcomponentsType"><mat-spinner [diameter]="30"></mat-spinner></mat-option>
                <mat-option *ngIf="componentsTypeList.length === 0">
                  {{ "components.no-components-registred" | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button *ngIf="!filterComponentLoading" class="btn btn-success" title="Filtrar"
            (click)="filterComponent(search.value, materialTypes.value)">
            {{ "components.form-button-filter" | translate }}
          </button>
          <mat-spinner *ngIf="filterComponentLoading" [diameter]="30"></mat-spinner>
        </div>

        <!-- Sessão criar Componentes -->
        <div class="form-fields-box" [ngClass]="!createComponents ? 'closed' : ''" [formGroup]="createComponentForm">
          <h2 (click)="createComponentsOpen()">
            {{ "components.form-register-label" | translate }}
          </h2>
          <hr [ngClass]="!createComponents ? 'hr-open' : ''" />
          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "components.form-register-serial-number" | translate }}
              </mat-label>
              <input matInput formControlName="serialNumber" maxLength="13" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">2</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{
                "components.form-search-by-component-type" | translate
                }}</mat-label>
              <mat-select formControlName="componentType" #componentId
                (valueChange)="componentsTypeGetId(componentId.value)">
                <mat-option *ngFor="let item of componentsTypeList" [value]="item.id">
                  {{ item.reference }}
                </mat-option>

                <mat-option *ngIf="componentTypeLoading$ | async">
                  <mat-spinner [diameter]="30"> </mat-spinner>
                </mat-option>

                <mat-option *ngIf="componentsTypeList.length === 0">
                  {{ "components.no-components-registred" | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">3</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "components.form-search-by-installation" | translate }}
              </mat-label>
              <mat-select formControlName="installations" #installationId
                (valueChange)="installationTypeGetId(installationId.value)">
                <mat-option *ngFor="let item of installationTypeList" [value]="item.id">
                  {{ item.reference }}
                </mat-option>

                <mat-option *ngIf="componentInstallationLoading$ | async">
                  <mat-spinner [diameter]="30"> </mat-spinner>
                </mat-option>

                <mat-option *ngIf="installationTypeList.length === 0">
                  {{ "components.no-installations-registred" | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button title="Salvar" class="btn btn-success" *ngIf="!(componentTypeLoading$ | async)"
            (click)="createComponent()">
            {{ "components.form-button-save" | translate }}
          </button>
          <mat-spinner *ngIf="componentTypeLoading$ | async" [diameter]="30"></mat-spinner>
        </div>
      </div>
    </div>

    <!-- sessão componente table header -->
    <div class="col-lg-7 col-xl-8 col-sm-12">
      <app-table-header *ngIf="ELEMENT_DATA.length" [itensNumber]="paginationProperties.count"
        [totalNumber]="paginationProperties.total" [hasPDF]="true" [hasCSV]="true" (downloadCSV)="downloadCSV()"
        (downloadPDF)="downloadPDF()">
        ></app-table-header>

      <!-- Sessão tabela -->
      <div *ngIf="ELEMENT_DATA.length" class="table-div mt-2 mt-lg-3 mt-xl-3">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!--Serial Number Column -->
          <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef>
              {{ "components.column-serial-number" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.serialNumber }}
            </td>
          </ng-container>

          <!-- Component Type Column -->
          <ng-container matColumnDef="componentType">
            <th mat-header-cell *matHeaderCellDef>
              {{ "components.column-component-type" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.componentType.reference }}
            </td>
          </ng-container>

          <!-- Installation Column -->
          <ng-container matColumnDef="installations">
            <th mat-header-cell *matHeaderCellDef>
              {{ "components.column-installations" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.installation.reference }}
            </td>
          </ng-container>

          <!-- view Column / Btn-editar-->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              {{ "components.column-actions" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <button [ngClass]="element.view === true ? 'hover' : ' '" class="btn btn-success mb-2 view"
                (click)="openSidebar(element)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-pencil-square">
                  <path
                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                </svg>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <!-- Sessão da paginação -->
        <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="resultsLength" [pageSize]="10"
          [pageSizeOptions]="[5, 10]" aria-label="Selecione as páginas" class="mat-paginator-sticky"
          showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>
  </div>

  <!-- Editar informações sidebar -->
  <div class="sidebar-edit" *ngIf="openEdit" [formGroup]="updateComponentForm">
    <mat-icon (click)="closeSidebar()">clear</mat-icon>
    <h2>Editar Componente</h2>
    <hr />
    <mat-form-field appearance="outline">
      <mat-label>{{
        "components.form-register-serial-number" | translate
        }}</mat-label>
      <input matInput formControlName="serialNumber" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ "components.column-component-type" | translate }}
      </mat-label>
      <mat-select formControlName="componentType" #componentId>
        <mat-option *ngFor="let item of componentsTypeList" [value]="item.id">
          {{ item.reference }}
        </mat-option>

        <mat-option *ngIf="componentTypeLoading$ | async">
          <mat-spinner [diameter]="30"> </mat-spinner>
        </mat-option>

        <mat-option *ngIf="componentsTypeList.length === 0">
          {{ "components.no-components-registred" | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        {{ "components.form-search-by-installation" | translate }}
      </mat-label>
      <mat-select #installationId formControlName="installations">
        <mat-option *ngFor="let item of installationTypeList" [value]="item.id">
          {{ item.reference }}
        </mat-option>

        <mat-option *ngIf="componentInstallationLoading$ | async">
          <mat-spinner [diameter]="30"> </mat-spinner>
        </mat-option>

        <mat-option *ngIf="installationTypeList.length === 0">
          {{ "components.no-installations-registred" | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button class="btn btn-success" (click)="updateComponent()" *ngIf="!updateComponentLoading">
      {{ "components.btn-send" | translate }}
    </button>
    <mat-spinner [diameter]="30" *ngIf="updateComponentLoading"></mat-spinner>
  </div>
</div>