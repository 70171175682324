<div *ngIf="tableIsLoading" class="component-spinner-box">
    <mat-spinner [diameter]="100"></mat-spinner>
</div>

<table *ngIf="!tableIsLoading" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef>
            {{'report-audit.report-audit-download.column-table-reference' |
            translate}} </th>
        <td mat-cell *matCellDef="let auditList"> {{auditList.reference}} </td>
    </ng-container>

    <ng-container class="preSignedUrl" matColumnDef="preSignedUrlMprev">
        <th mat-header-cell *matHeaderCellDef> MPREV </th>
        <td mat-cell *matCellDef="let auditList">
            <a *ngIf="auditList.preSignedUrlMprev" href="{{auditList.preSignedUrlMprev}}">
                <mat-icon>file_download</mat-icon>
            </a>
            <mat-icon *ngIf="!auditList.preSignedUrlMprev" matTooltip="Arquivo sendo gerado...">file_download</mat-icon>
        </td>
    </ng-container>

    <ng-container class="preSignedUrl" matColumnDef="preSdft">
        <th mat-header-cell *matHeaderCellDef> SDFT </th>
        <td mat-cell *matCellDef="let auditList">
            <button *ngIf="auditList.preSdft" (click)="getAuditorshipInstallations(auditList.preSdft)"
                class=" btn-mprev btn-link p-0 m-0 align-baseline text-decoration-none">
                <mat-icon>file_download</mat-icon>
            </button>
            <mat-icon *ngIf="!auditList.preSdft" matTooltip="Arquivo sendo gerado...">file_download</mat-icon>
        </td>
    </ng-container>
    <ng-container class="preSignedUrl" matColumnDef="preSignedUrlSdot">
        <th mat-header-cell *matHeaderCellDef> SDOT </th>
        <td mat-cell *matCellDef="let auditList">
            <a *ngIf="auditList.preSignedUrlSdot" href="{{auditList.preSignedUrlSdot}}">
                <mat-icon>file_download</mat-icon>
            </a>
            <mat-icon *ngIf="!auditList.preSignedUrlSdot" matTooltip="Arquivo sendo gerado...">file_download</mat-icon>
        </td>
    </ng-container>

    <ng-container class="preSignedUrl" matColumnDef="preSignedUrlIcti">
        <th mat-header-cell *matHeaderCellDef> ICTI </th>
        <td mat-cell *matCellDef="let auditList">
            <a *ngIf="auditList.preSignedUrlIcti" href="{{auditList.preSignedUrlIcti}}">
                <mat-icon>file_download</mat-icon>
            </a>
            <mat-icon *ngIf="!auditList.preSignedUrlIcti" matTooltip="Arquivo sendo gerado...">file_download</mat-icon>
        </td>
    </ng-container>

    <ng-container class="preSignedUrl" matColumnDef="preSignedUrlIcvi">
        <th mat-header-cell *matHeaderCellDef> ICVI </th>
        <td mat-cell *matCellDef="let auditList">
            <a *ngIf="auditList.preSignedUrlIcvi" href="{{auditList.preSignedUrlIcvi}}">
                <mat-icon>file_download</mat-icon>
            </a>
            <mat-icon matTooltip="Arquivo sendo gerado..." *ngIf="!auditList.preSignedUrlIcvi">file_download</mat-icon>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>