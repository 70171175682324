import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MapService } from 'src/shared/services/map.service';
import { EquipmentType, Gateway } from './assets/equipmentsModel';
import { SidebarService } from 'src/shared/services/sidebar.service';
import { Unsubscriber } from '../unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-map-sidebar-filter',
  templateUrl: './map-sidebar-filter.component.html',
  styleUrls: ['./map-sidebar-filter.component.less']
})
export class MapSidebarFilterComponent extends Unsubscriber implements OnInit {

  // Evento responsável por fechar a sidebar
  @Output() closeSideBarFilter = new EventEmitter();
  // Evento responsavel por fazer a requisição
  @Output() sendquery = new EventEmitter();

  reference: string | null = null;
  adress: string = '';
  gateway: string | null = null;
  gatewayList: Array<Gateway> = []
  equipment_typeList: Array<EquipmentType> = []
  equipmentTypeChecked: Array<string> = [];
  data: Array<any> = [];

  // Variavel responsável por ativar o loading enquanto a requisição esta sendo feita
  isLoading: boolean = false;

  // Variaveis de erro e sucesso de requisição
  success: boolean = false;
  fail: boolean = false;

  constructor(private mapService: MapService, private sidebarService: SidebarService) {
    super();

    this.subscriptions = this.mapService.installationByCompanyIdEvent.subscribe((data: boolean) => {
      // Condição que substitui o botão de filtro pelo loading, informando ao usuário que a requisição esta sendo feita
      this.isLoading = false;

      // Mapeamento de erro para informar ao usuário se a requisição teve sucesso ou não
      if (data === true) {
        this.fail = false;
        this.success = data;
      }
      else {
        this.fail = data;
        this.success = false;
      }
      setTimeout(() => {
        this.fail = false;
        this.success = false;
      }, 5000);
    });
  }

  ngOnInit(): void {
    // Filtrando gateway pela ultima empresa selecionada
    if (localStorage.getItem('lastCompanySelected')) {
      this.subscriptions = this.mapService.getGatewayByCompanyId(localStorage.getItem('lastCompanySelected')).valueChanges.subscribe(data => {
        data.data.mapNodes.edges.forEach((node: any) => {
          if (node.node.gateway) {
            this.gatewayList.push(new Gateway(
              node.node.gateway.id,
              node.node.gateway.reference
            ));
          }
        })
      })
      // Filtrando tipos de equipamentos pela ultima empresa selecionada
      this.subscriptions = this.mapService.getEquipmentTypeByCompanyId(localStorage.getItem('lastCompanySelected')).valueChanges.subscribe(data => {
        data.data.equipmentType.edges.forEach((node: any) => {
          if (node.node.id) {
            this.equipment_typeList.push(new EquipmentType(
              node.node.id,
              node.node.reference
            ));
          }
        })
      })
    }
    // Filtrando gateway pela a empresa atual selecionada
    else {
      this.subscriptions = this.sidebarService.sendCompany.subscribe((id: string | null) => {
        this.mapService.getGatewayByCompanyId(id).valueChanges.subscribe(data => {
          data.data.mapNodes.edges.forEach((node: any) => {
            if (node.node.gateway) {
              this.gatewayList.push(new Gateway(
                node.node.gateway.id,
                node.node.gateway.reference
              ));
            };
          });
        });
      });
      // Filtrando tipos de equipamentos pela empresa atual selecionada
      this.subscriptions = this.sidebarService.sendCompany.subscribe((id: string | null) => {
        this.mapService.getEquipmentTypeByCompanyId(id).valueChanges.subscribe(data => {
          data.data.equipmentType.edges.forEach((node: any) => {
            if (node.node.id) {
              this.equipment_typeList.push(new EquipmentType(
                node.node.id,
                node.node.reference
              ));
            };
          });
        });
      })
    };
  }

  // Função responsável por setar variável gateway
  setGateway(id: string): void {
    this.gateway = id;
  }

  // Função responsável por setar variável equipment_type
  setEquipmentType(id: string): void {
    for (let i = 0; i < this.equipment_typeList.length; i++) {
      // Verifica qual foi o equipmentType escolhido
      if (this.equipment_typeList[i].id === id) {
        // Verifica se o equipmetType não esta na lista dos selecionados
        if (this.equipment_typeList[i].checkbox) {
          const index = this.equipmentTypeChecked.indexOf(id);
          if (index > -1) {
            this.equipmentTypeChecked.splice(index, 1);
          }
          else {
            this.equipmentTypeChecked.splice(0, 1);
          }
          this.equipment_typeList[i].checkbox = false;
        }
        // Caso esteja, ele é retirado da lista
        else {
          this.equipmentTypeChecked.push(this.equipment_typeList[i].id);
          this.equipment_typeList[i].checkbox = true;
        }
      }
    }
  }

  // Função responsável por emitir o evento que fechará a sidebar
  closeSidebarFilter(): void {
    this.closeSideBarFilter.emit();
  }

  // Função responsável por enviar os dados para a requisição
  sendQuery(): void {
    this.isLoading = true;
    this.data.push(this.reference, this.gateway, this.equipmentTypeChecked);
    this.sendquery.emit(this.data);
    this.data = [];
  }
}
