import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AvailablePermissions, PaginationInfo, PermissionsGroup, Users } from './permissions-group-assets/permissions-group.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { PermissionsGroupService } from 'src/shared/services/permissions-group.service';
import Swal from 'sweetalert2';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-permissions-group',
  templateUrl: './permissions-group.component.html',
  styleUrls: ['./permissions-group.component.less']
})

export class PermissionsGroupComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private permissionsGroupService: PermissionsGroupService,
    private errorLibService: ErrorLibService,
  ) { }

  ngOnInit(): void {
    /** Realiza o filtro de todas as permissões (utilizado na criação de grupos de permissões) **/
    this.permissionsGroupService.getAvailablePermission().valueChanges.subscribe({
      next: (res: any) => {
        res.data.availablePermissions.edges.forEach((node: any) => {
          this.availablePermissionsList.push(
            new AvailablePermissions(
              node?.node?.id,
              node?.node?.name,
              node?.node?.codename
            )
          );
        });
        /** Caso ocorra algum erro na requisição **/
      }, error: (err: any) => {
        /** Exibe o erro na tela para o usuário **/
        this.errorLibService.errorAlert(err);
        /** Exibe erro no console **/
        console.log('Error on query AvailablePermissions', err);
      }
    });

    /** Realiza o filtro de todas as permissões (utilizado na edição de grupos de permissões) **/
    this.permissionsGroupService.getAvailablePermission().valueChanges.subscribe({
      next: (res: any) => {
        res.data.availablePermissions.edges.forEach((node: any) => {
          this.availablePermissionsListUpdate.push(
            new AvailablePermissions(
              node?.node?.id,
              node?.node?.name,
              node?.node?.codename
            )
          );
        });
        /** Caso ocorra algum erro na requisição **/
      }, error: (err: any) => {
        /** Exibe o erro na tela para o usuário **/
        this.errorLibService.errorAlert(err);
        /** Exibe erro no console **/
        console.log('Error on query AvailablePermissions', err);
      }
    });
  }

  /** Propriedade que armazena os dados dos grupos de permissões (utilizado para popular a tabela) **/
  public ELEMENT_DATA: PermissionsGroup[] = [];
  /** Propriedade que armazena os dados dos grupos de permissões (utilizado no arquivo) **/
  public FILE_DATA: PermissionsGroup[] = [];
  /** Propriedade que armazena uma lista que contém os dados relacionados as permissões **/
  public availablePermissionsList: AvailablePermissions[] = [];
  /** Propriedade que armazena uma lista que contém os dados relacionados as permissões (utilizado na edição) **/
  public availablePermissionsListUpdate: AvailablePermissions[] = [];
  /** Propriedade utilizada para manipular quando o sidebar será aberto ou fechado **/
  public isUpdatePermissionsGroup: boolean = false;
  /** Propriedades responsáveis pelos loadings **/
  public filterPermissionsGroupLoading: boolean = false;
  public createPermissionsGroupLoading: boolean = false;
  public updatePermissionsGroupLoading: boolean = false;
  public fileLoading: boolean = false;

  /** Variável utilizada para manipular o sidebar para edição/visualização **/
  public viewController: boolean = false;

  /**Abrem e fecham as boxs da página **/
  public registerPermissionsGroupBox: boolean = false;
  public filterPermissionsGroupBox: boolean = false;

  /** Armazena os dados do formulário de criação de grupos de permissões, o valor é setado automáticamente pelo HTML **/
  public permissionsGroupRegisterForm: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
  });

  /** Armazena os dados do formulário de filro de grupos de permissões, o valor é setado automáticamente pelo HTML **/
  public permissionsGroupFilterForm: FormGroup = this.formBuilder.group({
    name: [null]
  });

  /** Armazena os dados do formulário de edção de grupos de permissões, o valor é setado automáticamente pelo HTML **/
  public permissionsGroupUpdateForm: FormGroup = this.formBuilder.group({
    id: [null],
    name: [null, Validators.required]
  })

  /** Método responsável por abrir a box de registro de grupos de permissões **/
  public registerPermissionsGroupOpen(): void {
    this.registerPermissionsGroupBox = !this.registerPermissionsGroupBox;
    this.filterPermissionsGroupBox = false;
  }

  /** Método responsável por abrir a box de filtro de grupos de permissões **/
  public filterPermissionsGroupOpen(): void {
    this.filterPermissionsGroupBox = !this.filterPermissionsGroupBox;
    this.registerPermissionsGroupBox = false;
  }

  /** 
   * Propriedades relacionadas a paginação
   * **/
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null);

  /** Propriedades utilizadas para manipulação do Paginator **/
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;
  public isReady: boolean = false;
  public after: string | null = null;
  public before: string | null = null;
  public first: number | null = null;
  public last: number | null = null;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /** Função que controla os botões de anterior e pŕoximo no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.filterPermissionsGroup('previousPage', this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterPermissionsGroup('nextPage', this.paginationProperties.endCursor)
    }
  }

  /** Define os dados que serão exibidos na tabela, a partir da lista de grupos de permissões **/
  public dataSource = new MatTableDataSource<PermissionsGroup>(this.ELEMENT_DATA)

  /** Atualiza a tabela **/
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  /** Propriedade que contém as colunas da tabela **/
  public displayedColumns: string[] = ['name', 'userSet', 'actions'];

  /** Método que realiza a criação de grupos de permissões **/
  public createPermissionsGroup() {

    /** Ativa o loading na tela **/
    this.createPermissionsGroupLoading = true;

    /** Caso todos os campos do formulário de criação estejam preenchidos **/
    if (this.permissionsGroupRegisterForm.valid) {
      /** Realiza a requisição para criação de grupos de permissões **/
      this.permissionsGroupService.createPermissionsGroup(
        /** Envia o campo nome **/
        this.permissionsGroupRegisterForm?.value?.name
      ).subscribe({
        next: ((res: any) => {
          /** Desativa o loading na tela **/
          this.createPermissionsGroupLoading = false;

          /** Constante que armazena o id do grupo criado **/
          const permissionGroupId = res.data.groupCreate.group.id

          /** Constante que armazena uma lista de permissões **/
          const availablePermissions: string[] = [];

          /** Percorre por toda lista de permissões **/
          this.availablePermissionsList.forEach((permissions) => {
            /** Verifica se alguma permissão foi marcada **/
            if (permissions.checked) {
              /** Adiciona a permissão na lista **/
              availablePermissions.push(permissions.id)
            }
          });

          /** Seta as permissões para o grupo criado **/
          this.permissionsGroupService.setPermissionsGroup(permissionGroupId, availablePermissions).subscribe({
            next: () => { }
            /** Caso ocorra algum erro na requisição que seta as permissões **/
            , error: (error) => {
              /** Exibe erro no console **/
              console.log('Error on setPermissionGroup mutation', error);
              /** Exibe o erro na tela para o usuário **/
              this.errorLibService.errorAlert(error);
              /** Desativa o loading na tela **/
              this.createPermissionsGroupLoading = false;
            }
          });

          /** Atribui o valor do formulário do filtro para o campo name que foi criado **/
          this.permissionsGroupFilterForm.patchValue({
            name: this.permissionsGroupRegisterForm?.value?.name
          })

          /** Realiza o filtro retornando apenas o elemento que foi criado **/
          this.filterPermissionsGroup('nextPage', null);

          /** Limpa o campo 'name' do formulário de filtro **/
          this.permissionsGroupFilterForm.patchValue({
            name: null
          });

          /** Exibe mensagem de sucesso ao usuário **/
          Swal.fire({
            title: 'Grupo de Permissões cadastrado ',
            text: 'Grupo de Permissões cadastrado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancelar'
          })
          /** Caso ocorra algum erro na requisição de criação de grupos de permissões **/
        }), error: (error) => {
          /** Exibe o erro no console do navegador **/
          console.log('Error on GroupCreate mutation', error);
          /** Exibe o erro na tela para o usuário **/
          this.errorLibService.errorAlert(error);
          /** Desativa o loading na tela **/
          this.createPermissionsGroupLoading = false;
        }
      })
      /** Caso nenhum campo obrigatório tenha sido preenchido **/
    } else {
      Swal.fire({
        title: 'Preencha todos os campos do formulário!',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });

      /** Desativa o loading **/
      this.createPermissionsGroupLoading = false;
    }
  }

  /** Método que realiza o filtro dos grupos de permissões **/
  public filterPermissionsGroup(page: string | null, cursor: string | null) {

    /** Verifica se possui próxima página **/
    if (page == 'nextPage') {
      this.after = cursor;
      this.first = this.pageSize;

      /** Verifica se possui página anterior **/
    } else if (page == 'previousPage') {
      this.before = cursor;
      this.last = this.pageSize;
    }

    /** se o cursor for nulo  **/
    if (this.before == null) {
      this.pageIndex = 0;
      /** Ativa o download dos arquivos**/
      this.isReady = true;
    }

    /** Ativa o loading **/
    this.filterPermissionsGroupLoading = true;

    /** Realiza o filtro dos grupos de permissões **/
    this.permissionsGroupService.filterPermissionsGroup(
      this.permissionsGroupFilterForm.value.name,
      this.after,
      this.before,
      this.first,
      this.last,
      this.pageSize
    ).valueChanges.subscribe({
      next: ((res: any) => {
        this.paginationProperties = new PaginationInfo(
          res.data?.availableGroups?.count,
          res.data?.availableGroups?.total,
          res.data?.availableGroups?.pageInfo?.hasNextPage,
          res.data?.availableGroups?.pageInfo?.hasPreviousPage,
          res.data?.availableGroups?.pageInfo?.startCursor,
          res.data?.availableGroups?.pageInfo?.endCursor
        )
        /** Limpa a tabela para os dados não se repetirem **/
        this.ELEMENT_DATA = [];

        this.length = this.paginationProperties.total

        /** Itera sobre o resultado da requisição do filtro **/
        res.data.availableGroups.edges.forEach((availableGroups: any) => {

          /** Variável que armazena uma lista de permissões disponíveis do usuário **/
          let availablePermissions: AvailablePermissions[] = [];

          /** Itera sobre as permissões **/
          availableGroups?.node?.permissions?.edges.forEach((availablePermission: any) => {
            availablePermissions.push(
              new AvailablePermissions(
                availablePermission?.node?.id,
                availablePermission?.node?.name,
                availablePermission?.node?.codename,
                true //Define o checkbox de permissões como true para todas as permissões existentes
              ))
          });

          /** Preenche a lista que será utilizada para popular a tabela com os dados referentes ao grupo de permissão **/
          this.ELEMENT_DATA.push(
            new PermissionsGroup(
              availableGroups.node.id,
              availableGroups.node.name,
              new Users(
                availableGroups?.node?.userSet?.id,
                availableGroups?.node?.userSet?.count,
                availableGroups?.node?.userSet?.username,
                availableGroups?.node?.userSet?.firstName,
                availableGroups?.node?.userSet?.lastName
              ),
              availablePermissions
            )
          )
        });

        /** Atualiza a tabela **/
        this.setDataSourceAttributes();

        /** Proprieade que ativa o download dos arquivos é alterada para seu valor inicial **/
        this.isReady = false;

        /** Limpa as variáveis da paginação **/
        this.after = null;
        this.first = null;
        this.before = null;
        this.last = null;

        /** Desativa o loading na tela s**/
        this.filterPermissionsGroupLoading = false;

        /** Caso a resposta não retorne nenhum elemento **/
        if (this.ELEMENT_DATA.length === 0) {

          /** Exibe alerta ao usuário **/
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });

          /** Desativa o loading na tela **/
          this.filterPermissionsGroupLoading = false;
        }
      }),
      /** Caso ocorra algum erro na requisição **/
      error: ((error: any) => {
        /** Exibe um alerta na tela com o erro **/
        this.errorLibService.errorAlert(error);
        /** Exibe erro no console **/
        console.log('Error on query AvailableGroups', error);
        /** Desativa o loading na tela **/
        this.filterPermissionsGroupLoading = false;
      })
    })
  }

  /** Método responsável pela manipulação do checkbox quando todas as permissões são marcadas ou desmarcadas */
  public selectAllAvailablePermissions(event: any) {
    /**  Caso todas as permissões sejam marcadas **/
    if (event.selected) {
      this.availablePermissionsList.forEach((availablePermissions) => {
        /** Todas as permissões receberão o valor do checkbox como true **/
        availablePermissions.checked = true;
      });
      /** Caso todas as permissões sejam desmarcadas **/
    } else {
      this.availablePermissionsList.forEach((availablePermissions) => {
        /** Todas as permissões receberão o valor de checked como false **/
        availablePermissions.checked = false;
      });
    }
  }

  /** Método para validar o id das permissões e marcar ou desmarcar o checkbox de cada permissão especifica **/
  public checkAvailablePermissions(id: string) {
    /** Percorre toda a lista de permissões **/
    this.availablePermissionsList.forEach((availablePermissions) => {
      /** Verifica se o id da permissão selecionada corresponde as permissões existentes **/
      if (availablePermissions.id === id) {
        /** Permissão é marcada como checada **/
        availablePermissions.checked = true;
      }
    });
  }

  /** Método responsável pela manipulação do checkbox quando todas as permissões são marcadas ou desmarcadas 
   * (utilizado na edição de grupos de permissões) **/
  public selectAllAvailablePermissionsUpdate(event: any) {
    /**  Caso todas as permissões sejam marcadas **/
    if (event.selected) {
      this.availablePermissionsListUpdate.forEach((availablePermissions: any) => {
        /** Todas as permissões receberão o valor do checkbox como true **/
        availablePermissions.checked = true;
      });
      /** Caso todas as permissões sejam desmarcadas **/
    } else {
      this.availablePermissionsListUpdate.forEach((availablePermissions: any) => {
        /** Todas as permissões receberão o valor de checked como false **/
        availablePermissions.checked = false;
      });
    }
  }

  /** Método para validar o id das permissões e marcar ou desmarcar o checkbox de cada permissão especifica
   * (utilizada na edição de grupos de permissões) **/
  public checkAvailablePermissionsUpdate(availablePermissions: AvailablePermissions) {
    /** Alterna o valor de checkbox dependendo da alteração do usuário **/
    availablePermissions.checked = !availablePermissions.checked;
  }

  /** Método utilizado para retornar os checkbox das permissões existentes já marcados **/
  public setUpdateAvailablePermissions(availableGroups: PermissionsGroup) {
    /** Percorre a lista de permissões **/
    this.availablePermissionsListUpdate.forEach((permission) => {

      /** Define os checkboxs como desmarcados por padrão **/
      permission.checked = false;

      /** Verifica se a permissão está presente no grupo de permissões atual**/
      availableGroups.permissions.forEach((permissionsGroup) => {
        if (permission.id === permissionsGroup.id) {
          /** Define como checado as permissões existentes naquele grupo **/
          permission.checked = true;
        }
      });
    });
  }

  /** Método utilizado ao abrir o modal de edição de grupos de permissões e preencher com os campos já existentes **/
  public openSidebarUpdate(element: PermissionsGroup, isViewing: boolean) {

    /** Abre o modal **/
    this.isUpdatePermissionsGroup = true;

    /** Valor é definido de acordo com o botão selecionado pelo usuário (edição ou visualização) **/
    this.viewController = isViewing;

    /** Caso seja o botão de visualização **/
    if (isViewing === true) {
      /** Desabilita edição dos campos **/
      this.permissionsGroupUpdateForm.disable();
    } else {
      /** Habilita os campos para edição **/
      this.permissionsGroupUpdateForm.enable();
    }

    /** Atribuição do id de cada elemento a ser editado **/
    this.permissionsGroupUpdateForm.get('id')?.setValue(element.id);
    /** Atribui o campo do formulário para preencher o campo de nome **/
    this.permissionsGroupUpdateForm.get('name')?.setValue(element.name);
    /** Atribuição dos checkboxs de permissões **/
    this.setUpdateAvailablePermissions(element);
  }

  /** Fecha o sidebar de edição **/
  public closeSidebarUpdate() {
    this.isUpdatePermissionsGroup = false;
  }

  /** Método que realiza a edição dos grupos de permissões **/
  public updatePermissionsGroup() {

    /** Ativa o loading **/
    this.updatePermissionsGroupLoading = true;

    /** Lista que armazenará as permissões que deverão ser enviadas no update **/
    const availablePermissions: any[] = [];

    /** Percorre por toda a lista de permissões buscando as permissões checadas **/
    this.availablePermissionsListUpdate.forEach((availablePermission) => {
      /** Para todas as permissões checadas **/
      if (availablePermission.checked) {
        /** Adiciona todas as permissões checadas na lista que será enviada para a api **/
        availablePermissions.push(availablePermission.id)
      }
    });

    /** Caso todos os campos obrigatórios do formulário de edição forem preenchidos **/
    if (this.permissionsGroupUpdateForm.valid) {
      this.permissionsGroupService.updatePermissionsGroup(
        this.permissionsGroupUpdateForm.value.id,
        this.permissionsGroupUpdateForm.value.name
      ).subscribe({
        next: ((res: any) => {
          /** Desativa o loading **/
          this.updatePermissionsGroupLoading = false;

          /** Constante que armazena o id do grupo de permissão que foi atualizado **/
          const groupId = res.data.groupUpdate.group.id;

          /** Realiza a requisição para alterar as permissões, passando o id do grupo e as permissões que serão enviadas**/
          this.permissionsGroupService.setPermissionsGroup(groupId, availablePermissions).subscribe({
            next: () => { },
            /** Caso ocorra algum erro na requisição que seta as permissões **/
            error: (error) => {
              /** Exibe o erro no console do navegador**/
              console.log('Error on setPermissionGroup mutation', error);
              /** Exibe o erro na tela para o usuário **/
              this.errorLibService.errorAlert(error);
              /** Desativa o loading **/
              this.updatePermissionsGroupLoading = false;
            }
          })

          /** Exibe mensagem de sucesso ao usuário **/
          Swal.fire({
            title: 'Grupo de Permissões atualizado',
            text: 'Grupo de Permissões atualizado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          })

          /** Atribui o valor do formulário do filtro para o campo name que foi editado **/
          this.permissionsGroupFilterForm.patchValue({
            name: this.permissionsGroupUpdateForm?.value?.name
          })

          /** Realiza o filtro retornando apenas o elemento que foi editado **/
          this.filterPermissionsGroup('nextPage', null);

          /** Limpa o campo 'name' do formulário de filtro **/
          this.permissionsGroupFilterForm.patchValue({
            name: null
          })

          /** Atualiza a tabela **/
          this.setDataSourceAttributes();

          /** Fecha o sidebar de edição **/
          this.isUpdatePermissionsGroup = false;
          this.viewController = false;

          /** Caso ocorra algum erro na requisição de edição de grupos de permissões **/
        }), error: (error) => {
          /** Exibe erro no console **/
          console.log('Error on GroupUpdate mutation', error);
          /** Exibe o erro na tela para o usuário **/
          this.errorLibService.errorAlert(error);
          /** Desativa o loading **/
          this.updatePermissionsGroupLoading = false;
        }
      })
      /** Caso os campos obrigatórios não forem preenchidos **/
    } else {
      /** Desativa o loading **/
      this.updatePermissionsGroupLoading = false;

      /** Exibe alerta na tela para o usuário **/
      Swal.fire({
        title: 'Preencha todos os campos do formulário!',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
  }

  /** Método que realiza a deleção do grupo de permissão **/
  public deletePermissionGroup(): any {
    /** Exibe alerta para confirmação **/
    Swal.fire({
      title: 'Tem certeza que deseja deletar o grupo de permissão?',
      text: 'Esta ação poderá afetar em outras funcionalidades do sistema que dependem deste grupo',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#198754',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Deletar',
      cancelButtonText: 'Cancelar'
      /** Captura o resultado **/
    }).then((result) => {
      /** Se a opção for confirmada, realiza a requisição de exclusão **/
      if (result.isConfirmed) {
        this.permissionsGroupService.deletePermissionsGroup(this.permissionsGroupUpdateForm.value?.id).subscribe({
          next: () => {
            /** Realiza o filtro atualizando a tabela **/
            this.filterPermissionsGroup('nextPage', null);
            /** Atualiza a tabela **/
            this.setDataSourceAttributes();
            /** Fecha o sidebar de edição **/
            this.isUpdatePermissionsGroup = false;

            /** Exibe mensagem de sucesso **/
            Swal.fire({
              icon: 'success',
              title: 'Sucesso',
              text: 'Grupo de Permissões deletado com sucesso'
            })
          },
          /** Captura o erro e exibe no console **/
          error: (error: any) => {
            this.isUpdatePermissionsGroup = false;
            console.log('mutation delete permissions group', error)
            this.errorLibService.errorAlert(error);
          }
        })
      }
    })
  }

  /** Método que realiza a geração dos arquivos PDF e CSV**/
  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.permissionsGroupService.filterPermissionsGroup(
        this.permissionsGroupFilterForm.value.name,
        cursor,
        null,
        10,
        null,
        this.pageSize
      ).valueChanges
        .subscribe({
          next: ((res: any) => {

            res.data.availableGroups.edges.forEach((availableGroups: any) => {
              /** Itera cada elemento dos grupos de permissões **/
              this.FILE_DATA.push(
                new PermissionsGroup(
                  availableGroups.node.id,
                  availableGroups.node.name,
                  new Users(
                    availableGroups?.node?.userSet?.id,
                    availableGroups?.node?.userSet?.count,
                    availableGroups?.node?.userSet?.username,
                    availableGroups?.node?.userSet?.firstName,
                    availableGroups?.node?.userSet?.lastName
                  ),
                  []
                )
              )
            });

            /** Caso seja a última página a lista está completa **/
            if (!res.data?.availableGroups?.pageInfo?.hasNextPage) {
              this.isReady = true;
            }

            this.generateFileData(res.data?.availableGroups?.pageInfo?.endCursor, fileType);
          }),
          error: ((error: any) => {
            console.log('Error on filter PermissionsGroup', error);
            this.errorLibService.errorAlert(error);
          })
        })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  /** Método para realizar o download do arquivo PDF **/
  public downloadPDF() {
    // Colunas que serão adicionadas ao header da tabela no PDF
    const tableLayout = [
      "Nome",
      "Usuário"
    ];

    let content: any = []
    // Adiciona os elementos na lista de conteúdo como um array
    this.FILE_DATA.forEach((element) => {
      content.push([
        element.name,
        element.userSet.count
      ]);
    });
    // Define a orientação da tabela (paisagem nesse caso)
    const orientation = 'l';
    // Cria o objeto da lib jsPDF com a orientação e tamanho da página
    const doc: any = new jsPDF(orientation, "mm", "a4");

    // Cria a tabela com o tableLayout como header e a a lista de conteúdo como corpo da tabela
    doc.autoTable({
      theme: "grid",
      margin: { top: 20 },
      head: [tableLayout],
      body: content
    });
    // Baixa o arquivo para o usuário, definindo o nome com a data de download
    doc.save(`${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-permissionsGroup.pdf`);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  /** Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe **/
  private convertToCSV(arr: any) {
    /** Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas **/
    const content = [Object.keys(arr[0])].concat(arr);

    /** Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um **/
    return content.map((item) => {
      return Object.values(item).toString().replace(',', ';');
    })
      .join('\n');
  }

  /** Método para realizar o download do arquivo CSV **/
  public downloadCSV() {
    let content: any = [];

    /** Adiciona cada elemento como um objeto na lista de conteúdo **/
    this.FILE_DATA.forEach((element) => {
      content.push({
        'Nome': element.name,
        'Usuario': element.userSet.count
      });
    });

    /** Adiciona o conteúdo convertido dentro de uma nova variável. **/
    let data = this.convertToCSV(content);
    /**  Transforma o conteúdo para o formato blob para geração do arquivo. **/
    let file = new Blob([data], { type: 'text/csv' });
    /** Cria um endereçamento para o arquivo. **/
    let url = window.URL.createObjectURL(file);
    /** Cria um elemento para a linkagem do arquivo. **/
    let link = document.createElement('a');
    /** Adiciona a url no elemento de linkagem. **/
    link.href = url;
    /**  Nomeia o arquivo que será feito o download. **/
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-permissionsGroup.csv`;
    /** Adiciona o link no documento. **/
    document.body.appendChild(link);
    /** Atica o evento de click para realizar o download. **/
    link.click();
    /** Remove o link do documento. **/
    document.body.removeChild(link);
    /**  Remove o endereço do download. **/
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }
}
