import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Equipment, EquipmentType, PaginationInfo } from "./equipments-assets/equipments-model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EquipmentsService } from "src/shared/services/equipments.service";
import { ErrorLibService } from "src/shared/services/error-lib.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import { Subscription } from "rxjs";


@Component({
  selector: 'app-equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.less']
})

export class EquipmentsComponent implements OnInit, OnDestroy {
  /**
    * Variável utilizada para armazenar todas as subscrições, 
    * para realizar o processo de unsubscribe. 
   **/
  private subscription: Subscription;

  constructor(
    private equipmentsService: EquipmentsService,
    private formBuilder: FormBuilder,
    private errorLibService: ErrorLibService
  ) { }

  ngOnInit(): void {
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
    this.getEquipmentsTypes();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  /** 
    * Ciclo de vida do angular utilizado para encerrar 
    * todas as subscrições(unsubscribe) existentes no componente. 
    * Todas as subscrições serão encerradas sempre que o usuário trocar de página. 
    **/
  ngOnDestroy(): void {
    /** Caso ocorra uma subscrição **/
    if (this.subscription) {
      /** A subscrição é encerrada **/
      this.subscription.unsubscribe();
    }
  }

  // Abrem e fecham as boxs da página
  public registerEquipment: boolean = false;
  public filterEquipment: boolean = false;

  // Variaveis responsaveis por loading
  public equipmentTypeLoading: boolean = false;
  public getEquipmentType: boolean = false;
  public equipmentLoading: boolean = false;
  public fileLoading: boolean = false;
  // Armazena o ID da empresa selecionada
  public company: any = null;

  public keywordSearch: string;

  public equipmentTypeId: string;
  // Armazena os tipos de equipamentos
  public equipmentTypeList: EquipmentType[] = [];
  // Armazena os dados da tabela
  public ELEMENT_DATA: Equipment[] = [];
  //Abre o sidebar de edição
  public sideBarEdit: boolean = false;
  // Seta as colunas que serão apresentadas na tabela
  public displayedColumns: string[] = ['macAddress', 'serialNumber', 'equipmentType', 'actions'];
  // Renderiza os comandos da lista ELEMENT_DATA para a tabela
  public dataSource = new MatTableDataSource<Equipment>;
  //Define as propriedades da paginação
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null)
  /** Variáveis utilizadas para manipulação do Paginator **/
  public currentPage: number = 0;
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  /** Função chamada toda vez que ocorre um evento no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.filterEquipments(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterEquipments(this.paginationProperties.endCursor)
    }
  }

  //Função que configura o paginator.
  public setDataSourceAttributes() {
    this.dataSource = new MatTableDataSource<Equipment>(this.ELEMENT_DATA);
  }

  /** Variáveis utilizadas para geração e manipulação dos arquivos PDF e CSV **/
  public FILE_DATA: Equipment[] = [];
  public isReady = false;

  // Armazena os dados do formulário de criação das instalações, o valor é setado automáticamente pelo HTML
  public equipmentForm: FormGroup = this.formBuilder.group({
    macAddress: [null, Validators.required],
    serialNumber: [null, Validators.required],
    equipmentType: [null, Validators.required]
  })

  //Armazena os dados do formulário de filtro
  public filterForm: FormGroup = this.formBuilder.group({
    equipmentTypeId: [null],
    search: [null, Validators.required]
  })

  // Armazena os dados do formulário de atualização das instalações
  public updateForm: FormGroup = this.formBuilder.group({
    id: [null],
    macAddress: [null, Validators.required],
    serialNumber: [null, Validators.required],
    equipmentTypeId: [null],
    equipmentType: [null]
  })

  //Função responsável por abrir a box de registro de equipamentos
  public registerEquipmentOpen(): void {
    this.registerEquipment = !this.registerEquipment;
    this.filterEquipment = false;
  }

  //Função responsável por abrir a box de filtro de equipamentos
  public filterEquipmentOpen(): void {
    this.filterEquipment = !this.filterEquipment;
    this.registerEquipment = false;
  }

  //Função responsável por filtrar equipamentos
  public filterEquipments(cursor: string | null) {
    this.equipmentLoading = true;

    /** Armazena os filtros feitos pelo usuário**/
    this.keywordSearch = this.filterForm.get("search")?.value;
    this.equipmentTypeId = this.filterForm.get("equipmentTypeId")?.value != null ? this.filterForm.get("equipmentTypeId")?.value : ''

    /** Reseta o índice da página ao realizar o filtro sem cursor **/
    if (cursor == null) {
      this.pageIndex = 0;
      this.isReady = true;
    }

    this.subscription = this.equipmentsService.filterEquipments(
      this.equipmentTypeId,
      this.keywordSearch,
      cursor,
      this.pageSize
    ).valueChanges.subscribe({
      next: ((response: any) => {

        //Variável responsável pelas propriedades da paginação
        this.paginationProperties = new PaginationInfo(
          response.data.equipment.count,
          response.data.equipment.total,
          response.data.equipment.pageInfo.hasNextPage,
          response.data.equipment.pageInfo.hasPreviousPage,
          response.data.equipment.pageInfo.startCursor,
          response.data.equipment.pageInfo.endCursor
        )
        /** Remove os dados anteriores da tabela **/
        this.ELEMENT_DATA = [];

        //Responsável por inserir os dados na table
        response.data.equipment.edges.forEach((equipment: any) => {
          this.ELEMENT_DATA.push(
            new Equipment(
              equipment.node.id,
              equipment.node.serialNumber,
              equipment.node.macAddress,
              equipment.node.equipmentType.id,
              equipment.node.equipmentType
            )
          )
        });
        /** Armazena o total de resultados do filtro para a paginação **/
        this.length = response.data.equipment.total;

        //Configura o paginator
        this.setDataSourceAttributes()
        this.isReady = false;

        if (this.ELEMENT_DATA.length === 0) {
          /** Retorna alerta ao usuário **/
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        }

        this.equipmentLoading = false
      })
    })
  }

  //Função responsável por criar equipamentos
  public createEquipment(): void {
    // Verifica se todos os campos foram preechidos corretamente
    if (!this.equipmentForm.valid) {
      Swal.fire({
        text: 'Verifique os campos e tente novamente!',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
    // Caso algum campo não tenha sido preechido, uma mensagem de alerta é emita ao usuário
    this.equipmentTypeLoading = true

    this.subscription = this.equipmentsService.createEquipment(
      this.company,
      this.equipmentForm.get('macAddress')?.value,
      String(this.equipmentForm.get('serialNumber')?.value),
      this.equipmentForm.get('equipmentType')?.value
    ).subscribe({
      next: ((response: any) => {
        //Atualiza o ELEMENT_DATA com a unidade que foi criada
        this.ELEMENT_DATA = [
          new Equipment(
            response.data.equipmentCreate.equipment.id,
            this.equipmentForm.get("serialNumber")?.value,
            this.equipmentForm.get("macAddress")?.value,
            response.data.equipmentCreate.equipment.equipmentType.id,
            response.data.equipmentCreate.equipment.equipmentType,
          )
        ]
        //Configura o paginator
        this.setDataSourceAttributes()

        Swal.fire({
          title: 'Equipamento criado',
          text: 'Equipamento criado com sucesso',
          icon: 'success',
          confirmButtonText: 'Ok'
        });

        this.equipmentTypeLoading = false
      }),
      error: (error: any) => {
        /** Exibe alerta do erro ao usuário **/
        this.errorLibService.errorAlert(error);
        this.equipmentTypeLoading = false
      }
    })
  }

  //Função responsável por abrir o sidebar
  public openSidebarUpdate(element: any): void {
    //Define o valor do sideBarEdit, para abrir o sidebar
    this.sideBarEdit = true

    //Recebe os valores e atualiza o formulário
    this.updateForm.get("id")?.setValue(element?.id)
    this.updateForm.get("macAddress")?.setValue(element?.macAddress)
    this.updateForm.get("serialNumber")?.setValue(element?.serialNumber)
    this.updateForm.get("equipmentType")?.setValue(element.equipmentType)
    this.updateForm.get("equipmentTypeId")?.setValue(element?.equipmentTypeId)
  }

  //Função responsável por atualizar o equipamento
  public updateEquipment() {
    //Verifica se o formulário é valido, caso não, exibe o modal de error
    if (!this.updateForm.valid) {
      Swal.fire({
        text: 'Verifique os campos e tente novamente!',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }

    this.subscription = this.equipmentsService.updateEquipment(
      this.updateForm.get("id")?.value,
      this.company,
      this.updateForm.get("macAddress")?.value,
      this.updateForm.get("serialNumber")?.value,
      this.updateForm.get("equipmentTypeId")?.value,
    ).subscribe({
      //Manipula o response, atualizando os elementos da DATA
      next: ((response: any) => {
        this.getEquipmentType == true
        //Atualiza o ELEMENT_DATA com a unidade que foi atualizada
        this.ELEMENT_DATA = [
          new Equipment(
            response.data.equipmentUpdate.equipment.id,
            response.data.equipmentUpdate.equipment.serialNumber,
            response.data.equipmentUpdate.equipment.macAddress,
            response.data.equipmentUpdate.equipment.equipmentType.id,
            response.data.equipmentUpdate.equipment.equipmentType
          )
        ]
        //Configura o paginator
        this.setDataSourceAttributes()

        //Exibe o modal de sucesso
        Swal.fire({
          title: 'Equipamento atualizado',
          text: 'Equipamento atualizado com sucesso',
          icon: 'success',
          confirmButtonText: 'Ok'
        });

        //Fecha o sidebar após a atualização do equipamento!
        this.sideBarEdit = false
        this.getEquipmentType == false
      }),
      //Caso de algum error a função abaixo é responsável por manipular
      error: (error: any) => {
        //Exibe no console o error
        console.log("updateUnits", error)
        //Exibe um alerta
        this.errorLibService.errorAlert(error);
      }
    })
  }

  //Função para recuperar o tipo de equipamento
  public getEquipmentsTypes() {
    this.subscription = this.equipmentsService.getEquipmentTypes().valueChanges.subscribe({
      next: ((response: any) => {
        response.data.equipmentType.edges.forEach((node: any) => {
          this.equipmentTypeList.push(
            new EquipmentType(node.node.id, node.node.reference, node.node.major, node.node.minor, node.node.revision));
        });
      }),
      error: ((error: any) => {
        this.errorLibService.errorAlert(error);
      })
    })
  }

  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscription = this.equipmentsService.filterEquipments(
        this.equipmentTypeId,
        this.keywordSearch,
        cursor,
        200
      ).valueChanges.subscribe({
        next: ((response: any) => {

          response.data.equipment.edges.forEach((equipment: any) => {
            this.FILE_DATA.push(
              new Equipment(
                equipment.node.id,
                equipment.node.serialNumber,
                equipment.node.macAddress,
                equipment.node.equipmentType.id,
                equipment.node.equipmentType
              )
            )
          });

          /** Caso seja a última página a lista está completa **/
          if (!response.data?.equipment?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.equipment?.pageInfo?.endCursor, fileType);
        }),
        error: ((error: any) => {
          this.errorLibService.errorAlert(error);
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  // Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe.
  private convertToCSV(arr: any) {
    // Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas.
    const content = [Object.keys(arr[0])].concat(arr);

    // Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um.
    return content.map((item) => {
      return Object.values(item).toString().replace(',', ';');
    }).join('\n');
  }

  // Função que realiza o download do CSV.
  public downloadCSV() {
    let content: any = [];

    // Adicoina cada elemento como um objeto na lista de conteúdo.
    this.FILE_DATA.forEach((element) => {
      content.push({
        'MacAddress': element.macAddress,
        'SerialNumber': element.serialNumber,
        'equipmentType': `${element.equipmentType?.reference}.${element.equipmentType.major}.${element.equipmentType.minor}.${element.equipmentType.revision}`
      });
    });

    // Adiciona o conteúdo convertido dentro de uma nova variável.
    let data = this.convertToCSV(content);
    // Transforma o conteúdo para o formato blob para geração do arquivo.
    let file = new Blob([data], { type: 'text/csv' });
    // Cria um endereçamento para o arquivo.
    let url = window.URL.createObjectURL(file);
    // Cria um elemento para a linkagem do arquivo.
    let link = document.createElement('a');
    // Adiciona a url no elemento de linkagem.
    link.href = url;
    // Nomeia o arquivo que será feito o download.
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-equipments.csv`;
    // Adiciona o link no documento.
    document.body.appendChild(link);
    // Atica o evento de click para realizar o download.
    link.click();
    // Remove o link do documento.
    document.body.removeChild(link);
    // Remove o endereço do download.
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  // Função que realiza o download do PDF.
  public downloadPDF() {
    // Define o cabeçalho do documento.
    const header = [
      'MacAddress',
      'SerialNumber',
      'EquipmentType'
    ];
    // Lista que irá conter o conteúdo retornado pela API.
    let content: any = [];
    // Inserção do conteúdo na lista.
    this.FILE_DATA.forEach((element) => {
      content.push([
        element.macAddress,
        element.serialNumber,
        `${element.equipmentType?.reference}.${element.equipmentType.major}.${element.equipmentType.minor}.${element.equipmentType.revision}`
      ]);
    });
    // Define o formato do documento.
    let file: any = new jsPDF('l', 'mm', 'a4');
    // Cria o PDF.
    file.autoTable({
      theme: 'grid',
      margin: { top: 20 },
      head: [header],
      body: content
    });
    // Gera o download do documento.
    file.save(`${new Date().toLocaleDateString()}-${new Date().getHours()}:${new Date().getMinutes()}-equipments.pdf`)

    this.FILE_DATA = [];
    this.isReady = false;
  }
}