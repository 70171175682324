import { gql } from "apollo-angular";

const CHANGE_FAVORITE = gql`
mutation
SavePreferences($json: String $preferencesGroup: String) {
    savePreferences(
        input: {json: $json, preferencesGroup: $preferencesGroup}) {
        json
        preferencesGroup
    }
}`;

export {
    CHANGE_FAVORITE
}