import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alertRulePipe'
})
export class AlertRulePipePipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case "EQUALS":
        return "=";
      case "NOT_EQUALS":
        return `≠`;
      case "GREATER_THAN":
        return `>`;
      case "GREATER_EQUAL":
        return `≥`;
      case "LESS_THAN":
        return '<';
      case "LESS_EQUAL":
        return `≤`;
      default:
        return "-";
    }
  }

}
