<div class="component-main-container">
  <!-- Header do componente -->
  <div class="header">
    <app-page-header parentTitle="{{'service-order.sevice-order-label' | translate}}">
    </app-page-header>
  </div>
  <!-- Corpo do componente -->
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-sm-12">
      <div class="forms-box">
        <!-- formulário -->
        <div class="component-forms-box">
          <div class="form-fields-box" [formGroup]="createServiceOrderForm"
            [ngClass]="!createServiceOrderBox ? 'closed' : ''">
            <h2 (click)="createServiceOrderBoxOpen()">{{'service-order.create-form-title' | translate }}</h2>
            <hr [ngClass]="!createServiceOrderBox ? 'hr-open' : ''">

            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
              <mat-tab label="{{'service-order.problem-label' | translate }}">
                <br>
                <div class="form-step">
                  <div class="circle-number"> 1 </div>
                  <mat-form-field class="example-form-field" appearance="outline">
                    <mat-label> {{'service-order.problem-label' | translate }} </mat-label>
                    <input matInput formControlName="problem">
                  </mat-form-field>
                </div>
                <div class="form-step">
                  <div class="circle-number circle-number-ready"> 2 </div>
                  <mat-form-field class="example-form-field" appearance="outline">
                    <mat-label> {{'service-order.problem-description-label' | translate }} </mat-label>
                    <input matInput formControlName="problemDetails">
                  </mat-form-field>
                </div>
                <div class="form-step">
                  <div class="circle-number"> 3 </div>
                  <mat-form-field class="example-form-field" appearance="outline">
                    <mat-label>{{'service-order.location-table' | translate }} </mat-label>
                    <input matInput formControlName="problemAdress">
                  </mat-form-field>
                </div>
                <!-- Campo para filtrar instalações -->
                <div class="form-step">
                  <div class="circle-number circle-number-ready"> 4 </div>
                  <mat-form-field appearance="outline">
                    <mat-label>{{'service-order.installation-label' | translate }}</mat-label>
                    <input formControlName="searchInstallation" matInput placeholder="Instalação" />
                    <mat-icon class="component-search-icon" (click)="searchInstallation(true)">
                      search
                    </mat-icon>
                  </mat-form-field>
                </div>
                <!-- Campo com a lista de instalações filtradas -->
                <div class="form-step" *ngIf="installationList.length > 0">
                  <div class="circle-number circle-number-ready"> 4.1 </div>
                  <mat-form-field appearance="outline">
                    <mat-label>
                      {{'service-order.select_an_installation-label' | translate }}
                    </mat-label>
                    <mat-select [value]="installationList[0].id" formControlName="selectedInstallation" required>
                      <mat-option apperance="outline" *ngFor="let installation of installationList"
                        [value]="installation.id">
                        {{ installation.reference }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="form-step">
                  <div class="circle-number"> 5 </div>
                  <mat-form-field appearance="outline">
                    <mat-label> {{'service-order.priority-label' | translate}} </mat-label>
                    <mat-select formControlName="priority">
                      <mat-option apperance="outline" value="LOW">
                        {{'service-order.priority-label-low' | translate}}
                      </mat-option>

                      <mat-option apperance="outline" value="MEDIUM">
                        {{'service-order.priority-label-medium' | translate}}
                      </mat-option>

                      <mat-option apperance="outline" value="HIGH">
                        {{'service-order.priority-label-high' | translate}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="form-step">
                  <div class="circle-number circle-number-ready"> 6 </div>
                  <mat-form-field appearance="outline">
                    <mat-label> {{'service-order.status-label' | translate}} </mat-label>
                    <mat-select formControlName="status">
                      <mat-option apperance="outline" value="OPEN">{{'service-order.status-label-open' | translate}}
                      </mat-option>
                      <mat-option apperance="outline" value="CLOSED">{{'service-order.status-label-closed' | translate}}
                      </mat-option>
                      <mat-option apperance="outline" value="IN_PROGRESS">
                        {{'service-order.status-label-in-progress' | translate}}</mat-option>
                      <mat-option apperance="outline" value="CLOSED_WITH_ISSUES">
                        {{'service-order.status-label-closed_with_issues' | translate}} </mat-option>
                      <mat-option apperance="outline" value="DELETED">
                        {{'service-order.status-label-deleted' | translate}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-tab>
              <mat-tab label="{{'service-order.requester-title' | translate }}">
                <br>
                <div class="form-step">
                  <div class="circle-number"> 1 </div>
                  <mat-form-field class="example-form-field" appearance="outline">
                    <mat-label> {{'service-order.name-label' | translate}} </mat-label>
                    <input matInput formControlName="name">
                  </mat-form-field>
                </div>

                <div class="form-step">
                  <div class="circle-number circle-number-ready"> 2 </div>
                  <mat-form-field class="example-form-field" appearance="outline">
                    <mat-label> {{'service-order.email-label' | translate}} </mat-label>
                    <input matInput formControlName="email">
                  </mat-form-field>
                </div>

                <div class="form-step">
                  <div class="circle-number"> 3 </div>
                  <mat-form-field class="example-form-field" appearance="outline">
                    <mat-label> {{'service-order.phone-number-label' | translate}} </mat-label>
                    <input matInput formControlName="cellphone">
                  </mat-form-field>
                </div>

                <div class="form-step">
                  <div class="circle-number circle-number-ready"> 4 </div>
                  <mat-form-field class="example-form-field" appearance="outline">
                    <mat-label> {{'service-order.landline-number-label' | translate}}</mat-label>
                    <input matInput formControlName="landlineNumber">
                  </mat-form-field>
                </div>

                <!--BTN-->
                <div class="d-flex m-2">
                  <button class="btn btn-success m-1 w-50" (click)="createServiceOrder()">
                    {{'service-order.button-save' | translate}}

                  </button>
                  <button class="btn btn-danger m-1 w-50" (click)="cleanFilter()">
                    {{'service-order.button-cancel' | translate }}
                  </button>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>

          <div class="form-fields-box" [ngClass]="!filterServiceOrderBox ? 'closed' : ''" [formGroup]="filterForm">
            <h2 (click)="filterServiceOrderBoxOpen()"> {{'service-order.filter-form-title' | translate }} </h2>
            <hr [ngClass]="!filterServiceOrderBox ? 'hr-open' : ''" />
            <div class="form-step">
              <div class="circle-number"> 1 </div>
              <mat-form-field class="example-form-field" appearance="outline">
                <mat-label> {{'service-order.identifier-label' | translate }} </mat-label>
                <input matInput formControlName="identifier">
              </mat-form-field>
            </div>
            <button title="{{ 'service-order.button-filter' | translate }}" class="btn btn-success"
              (click)="filterServiceOrder(null)" *ngIf="!filterLoading">{{ 'service-order.button-filter' | translate
              }}</button>
            <mat-spinner [diameter]="30" *ngIf="filterLoading"></mat-spinner>
          </div>
        </div>
      </div>
    </div>
    <!-- Tabela de busca de usuário-->
    <div class="col-xl-8 col-lg-8 col-sm-12">

      <app-table-header *ngIf="length" [loadingData$]="fileLoading" [hasCSV]="true" [hasPDF]="true" [itensNumber]="10"
        [totalNumber]="length" (downloadCSV)="generateFileData(null, 'CSV')"
        (downloadPDF)="generateFileData(null, 'PDF')">
      </app-table-header>

      <div class="table-div mt-2 mt-lg-3 mt-xl-3" *ngIf="ELEMENT_DATA.length">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!-- Name Column -->
          <ng-container matColumnDef="identifier">
            <th mat-header-cell *matHeaderCellDef> {{'service-order.identifier-label' | translate }} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false"> {{element.serviceIdentifier}} </td>
          </ng-container>

          <ng-container matColumnDef="installation">
            <th mat-header-cell *matHeaderCellDef> {{'service-order.installation-label' | translate }} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false"> {{element.installation.reference}} </td>
          </ng-container>

          <ng-container matColumnDef="problem">
            <th mat-header-cell *matHeaderCellDef> {{'service-order.problem-label' | translate }} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false"> {{element.problem.reference}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> {{'service-order.status-label' | translate }} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <span *ngIf="element.status === 'OPEN'">{{'service-order.status-label-open' | translate}}</span>
              <span *ngIf="element.status === 'CLOSED'">{{'service-order.status-label-closed' | translate}}</span>
              <span *ngIf="element.status === 'IN_PROGRESS'">{{'service-order.status-label-in-progress' |
                translate}}</span>
              <span *ngIf="element.status === 'CLOSED_WITH_ISSUES'">{{'service-order.status-label-closed_with_issues' |
                translate}}</span>
              <span *ngIf="element.status === 'DELETED'">{{'service-order.status-label-deleted' | translate}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef> {{'service-order.problem-description-label' | translate }} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false"> {{element.problem.details}} </td>
          </ng-container>

          <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef> {{'service-order.priority-label' | translate}} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <span *ngIf="element.priority === 'LOW'">{{'service-order.priority-label-low' | translate}}</span>
              <span *ngIf="element.priority === 'MEDIUM'">{{'service-order.priority-label-medium' | translate}}</span>
              <span *ngIf="element.priority === 'HIGH'">{{'service-order.priority-label-high' | translate}}</span>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              {{'lamps-type.actions-table' | translate}}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <button *ngIf="element.status != 'CLOSED'" (click)="updateServiceOrderGetter(
                            element.id,
                            element.installation.reference,
                            element.status,
                            element.priority,
                            element.problem.reference,
                            element.problem.details,
                            element.problem.address,
                            element.complainant.name,
                            element.complainant.landlineNumber,
                            element.complainant.phoneNumber,
                            element.complainant.email,
                            false)" [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success mb-2  view">
                <mat-icon class="component-svg-icon" svgIcon="bx-edit"></mat-icon>
              </button>

              <button [ngClass]="element.view === true ? 'hover' : ' '" class="btn btn-success mb-2 m-1 view" (click)="updateServiceOrderGetter(
                            element.id,
                            element.installation.reference,
                            element.status,
                            element.priority,
                            element.problem.reference,
                            element.problem.details,
                            element.problem.address,
                            element.complainant.name,
                            element.complainant.landlineNumber,
                            element.complainant.phoneNumber,
                            element.complainant.email,
                            true)">
                <mat-icon class="component-svg-icon" svgIcon="bx-show-white"></mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" (page)="pageChanged($event)"
          aria-label="Selecione as páginas de Luminárias" class="mat-paginator-sticky">
        </mat-paginator>
      </div>
    </div>
  </div>

  <div class="sidebar-edit" *ngIf="editorController" [formGroup]="updateServiceOrderForm">
    <mat-icon (click)="closeEditionSideBar()">clear</mat-icon>
    <h2 *ngIf="!viewController"> {{'service-order.edit-form-title' | translate }} </h2>
    <h2 *ngIf="viewController"> {{'service-order.view-form-title' | translate }} </h2>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
      <mat-tab label="{{'service-order.problem-label' | translate }}">
        <br>
        <mat-form-field appearance="outline">
          <mat-label> {{'service-order.problem-label' | translate }} </mat-label>
          <input matInput formControlName="problem">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{'service-order.problem-description-label' | translate }} </mat-label>
          <input matInput formControlName="problemDetails">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{'service-order.location-table' | translate }} </mat-label>
          <input matInput formControlName="problemAdress">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{'service-order.installation-label' | translate }} </mat-label>
          <input matInput formControlName="installations" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> {{'service-order.priority-label' | translate}} </mat-label>
          <mat-select formControlName="priority">
            <mat-option apperance="outline" value="LOW">
              {{'service-order.priority-label-low' | translate}}
            </mat-option>

            <mat-option apperance="outline" value="MEDIUM">
              {{'service-order.priority-label-medium' | translate}}
            </mat-option>

            <mat-option apperance="outline" value="HIGH">
              {{'service-order.priority-label-high' | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> {{'service-order.status-label' | translate}} </mat-label>
          <mat-select formControlName="status">
            <mat-option apperance="outline" value="OPEN">{{'service-order.status-label-open' | translate}}</mat-option>
            <mat-option apperance="outline" value="CLOSED">{{'service-order.status-label-closed' | translate}}
            </mat-option>
            <mat-option apperance="outline" value="IN_PROGRESS">{{'service-order.status-label-in-progress' | translate}}
            </mat-option>
            <mat-option apperance="outline" value="CLOSED_WITH_ISSUES">
              {{'service-order.status-label-closed_with_issues' | translate}} </mat-option>
            <mat-option apperance="outline" value="DELETED">{{'service-order.status-label-deleted' | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </mat-tab>
      <mat-tab label="{{'service-order.requester-title' | translate }}">
        <br>
        <mat-form-field appearance="outline">
          <mat-label> {{'service-order.name-label' | translate}} </mat-label>
          <input matInput formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> {{'service-order.email-label' | translate}} </mat-label>
          <input matInput formControlName="email">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> {{'service-order.phone-number-label' | translate}} </mat-label>
          <input matInput formControlName="cellphone">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> {{'service-order.landline-number-label' | translate}} </mat-label>
          <input matInput formControlName="landlineNumber">
        </mat-form-field>
        <button *ngIf="!viewController" class="btn btn-success"
          (click)="updateServiceOrder()">{{'service-order.button-send' | translate}}</button>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>