/** Variáveis utilizadas nas requisições de filto e renderização na tabela **/
export class LampsType {
    constructor(
        public id: string,
        public model: string | null,
        public manufacturer: string | null,
        public power: number | null,
        public dimmingType: string | null,
        public brightness: number | null,
        public consumption: number | null,
        public current: number | null,
        public powerFactor: number | null,
        public voltage: number | null,
    ) { }
}
/** Utilizado para configuração do checkbox de dimerização **/
export class DimmingsStatusCheckbox {
    constructor(
        public label: string,
        public value: string,
        public checked: boolean
    ) { }
}
/** Utilizado para exportar as informações de paginação**/
export class PaginationInfo {
    constructor(
        public count: number,
        public total: number,
        public hasNextPage: boolean,
        public hasPreviousPage: boolean,
        public startCursor: string | null,
        public endCursor: string | null
    ) { }
}
