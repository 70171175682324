import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
import { EquipmentTypeService } from 'src/shared/services/equipment-type.service';
import { ConfigCommands, ParameterTypeBool } from '../../equipment-type-modal/model';
import { MatSelectChange } from '@angular/material/select';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-config-commands-modal',
  templateUrl: './config-commands-modal.component.html',
  styleUrls: ['./config-commands-modal.component.less']
})

export class ConfigCommandsModalComponent extends Unsubscriber implements OnInit {

  /**  Subject utilizado para emitir os dados referente aos comandos de configuração **/
  private commandsSubject = new BehaviorSubject<any[]>([]);
  public commands$ = this.commandsSubject.asObservable();

  constructor(
    public dialogRef: MatDialogRef<ConfigCommandsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public equipmentTypeService: EquipmentTypeService,
    public formBuilder: FormBuilder,
    public dialog: MatDialog,

  ) {
    super();
  }

  ngOnInit(): void {
    /** Realiza a requisição de filtro dos parâmetros **/
    this.subscriptions = this.equipmentTypeService.filterParameter().valueChanges.subscribe({
      next: ((response: any) => {
        response.data.commandParameter.edges.forEach((parameter: any) => {
          this.parameterList.push(
            new ConfigCommands(
              parameter?.node?.reference,
              parameter?.node?.targetReference,
              null,
              null,
              null,
              null
            ));
        })
      })
      /** Caso ocorra algum erro na requisição **/
    }), ((error: any) => {
      /** Exibe erro no console **/
      console.log("ErrorParameterFilter", error);
      /** Exibe alerta ao usuário **/
      Swal.fire({
        title: 'Falha ao filtrar os parâmetros',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    })
  }

  /** Propriedade utilizada para popular os dados dos parâmetros **/
  public parameterList: ConfigCommands[] = [];

  /** Propriedade que armazena o tipo de parâmetro (podendo ser INT ou BOOL) **/
  public parameterType: string | null = null;

  /** Objeto que armazena os valores de confguração de parametro **/
  public selectedParameter: any = {
    reference: null,
    targetReference: null
  };

  /** Propriedades que armazenam os valores dos campos dos inputs dos tipos de parâmetros **/
  public settingsMaxValue: number | null = null;
  public settingsMinValue: number | null = null;
  public settingsDescription: string | null = null;
  public settingsValue: number | null = null;
  public settingsDescriptionFalse: string | null = null;
  public settingsDescriptionTrue: string | null = null;

  /** Propriedade utilizada para desabilitar o input de seleção dos tipos de parâmetros **/
  public disableParametersTypeInput: boolean = true;

  /** Método utilizado para emitir um evento sempre que um parâmetro for selecionado **/
  public onOptionsParameterSelected(event: MatSelectChange) {
    this.disableParametersTypeInput = false;
  }

  /** Método responsável por fechar e salvar o modal, enviando os dados dos comandos de configuração para o componente de tipo de equipamento **/
  public closeAndSaveModalConfigCommands() {

    /** Variável que recebe os dados dos comandos de configuração **/
    let configCommands: any = null;

    /** Verifica se algum parametro dos comandos de configuração foi selecionado
    * e se algum tipo de Parâmetro não foi selecionado **/
    if (this.selectedParameter !== null
      &&
      this.selectedParameter?.targetReference !== null
      &&
      this.parameterType === null
    ) {
      /** Exibe um alerta ao usuário para que o tipo de parâmetro seja selecionado **/
      Swal.fire({
        title: 'Selecione o tipo de Parâmetro!',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
      /** Retorna a aplicação **/
      return;
    }

    /** Verifica se o tipo de parâmetro é do tipo INT e se os campos foram preenchidos **/
    if (this.parameterType === 'INT') {

      /** Verifica se os campos referente os campos do tipo de parâmetro INT foram devidamente preenchidos **/
      if (
        !Number.isFinite(this.settingsMinValue) ||
        !Number.isFinite(this.settingsMaxValue) ||
        !this.settingsDescription ||
        !Number.isFinite(this.settingsValue)
      ) {
        /** Exibe alerta ao usuário **/
        Swal.fire({
          title: 'Preencha todos os campos de configuração para o tipo de parâmetro inteiro!',
          icon: 'warning',
          confirmButtonText: 'Ok'
        });
        /** Retorna para a aplicação normalmente **/
        return;

        /** Caso todos os campos estejam preenchidos **/
      } else {
        /** Salva os dados do comando na variável **/
        configCommands = new ConfigCommands(
          this.selectedParameter?.reference,
          this.selectedParameter?.targetReference,
          this.parameterType,
          this.settingsMinValue,
          this.settingsMaxValue,
          []
        );
      }
    }

    /** Verifica se o tipo de parâmetro selecionado é do tipo BOOL **/
    if (this.parameterType === 'BOOL') {
      /** Verifica se os campos do parâmetro do tipo booleano foram devidamente preenchidos **/
      if (!this.settingsDescriptionFalse || !this.settingsDescriptionTrue) {
        /** Exibe alerta ao usuário **/
        Swal.fire({
          title: 'Preencha todos os campos de configuração para o tipo de parâmetro booleano!',
          icon: 'warning',
          confirmButtonText: 'Ok'
        });

        /** Retorna para a aplicação normalmente **/
        return;
        /** Caso os campos estejam preenchidos **/
      } else {
        /** Cria uma lista que armazenará os parâmetros do tipo booleanos que podem ser true ou false **/
        const listChoices: ParameterTypeBool[] = [
          /** Através do valor existente no input do tipo true, armazena esse valor na descrição **/
          new ParameterTypeBool(
            true, // Tipo booleando
            this.settingsDescriptionTrue // Descrição
          ),
          new ParameterTypeBool(
            false, // Tipo booleano
            this.settingsDescriptionFalse // Descrição
          )
        ];
        /** Salva os dados do parâmetro do tipo booleano **/
        configCommands = new ConfigCommands(
          this.selectedParameter?.reference,
          this.selectedParameter?.targetReference,
          this.parameterType,
          this.settingsMinValue,
          this.settingsMaxValue,
          listChoices
        );
      }
    }

    /** Envia os dados referente aos comandos de configuração para o componente de tipo de equipamentos **/
    this.sendData([configCommands]);

    /** Limpa todos os campos **/
    this.clearFields();
  }

  public sendData(data: any) {
    /** Emite os dados para os observadores **/
    this.commandsSubject.next(data);
  }

  /**  Método responsável por limpar todos os campos e variáveis utilizadas no modal assim que ele for salvo **/
  private clearFields() {
    this.parameterType = null;
    this.settingsMinValue = null;
    this.settingsMaxValue = null;
    this.settingsDescription = null;
    this.settingsValue = null;
    this.settingsDescriptionFalse = null;
    this.settingsDescriptionTrue = null;
    this.disableParametersTypeInput = true; // Desabilita o input de seleção de tipos de parâmetros
  }
}
