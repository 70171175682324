import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AlertRulesService } from 'src/shared/services/alert-rules.service';
import { AlertRuleTable } from '../models/alert-rule.model';
import { Rule } from '../models/rule.model';
import { Installation } from '../models/installation.model';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { InstallationsModalComponent } from 'src/shared/components/installations-modal/installations-modal.component';
import { ManageAlertRuleComponent } from './manage-alert-rules/manage-alert-rule/manage-alert-rule.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Unsubscriber } from '../../unsubscriber/unsubscriber.component';
import { tzConvertUTC2Local } from 'src/assets/convertTimeZone/convertTimeZone';

@Component({
  selector: 'app-alert-rules',
  templateUrl: './alert-rules.component.html',
  styleUrls: ['./alert-rules.component.less'],
})
export class AlertRulesComponent extends Unsubscriber implements OnInit {

  constructor(
    private alertRulesService: AlertRulesService,
    private errorLibService: ErrorLibService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,

  ) {
    super();
  }

  ngOnInit(): void {
    this.filterAlertRules();
  }

  public isCreateMenuOpen: boolean = false;
  public isFilterMenuOpen: boolean = false;
  public isInEditMode: boolean = false;
  public isLoadingTableData$ = this.alertRulesService.tableDataLoading$;

  public displayedColumns: string[] = ['reference', 'weekdays', 'startDate', 'endDate', 'actions'];

  public dataSource = new MatTableDataSource<AlertRuleTable>();

  public ELEMENT_DATA: AlertRuleTable[] = [];
  public filterLoading: boolean = false;

  public rules: Rule[] = [
    new Rule('', '', '', '')
  ];

  public selectedUsers: string[] = [];
  public company: string | null = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
  public installationList: Installation[] = [];
  public installationListNetworkId: Installation[][] = [[]];
  public isLoadingNewInstallation: boolean = false;
  public creatingAlertRuleMutation: boolean = false;

  //async variables
  public properties$ = this.alertRulesService.properties$;
  public equipment_types$ = this.alertRulesService.equipment_types$;
  public propertiesLoading$ = this.alertRulesService.propertiesDataLoading$;
  public equipmentTypesLoading$ = this.alertRulesService.equipmentTypesDataLoading$;
  public userLoading$ = this.alertRulesService.usersDataLoading$;
  public users$ = this.alertRulesService.users$;
  public asyncAlertRuleTableData$ = this.alertRulesService.alertRules$;
  public installations$ = this.alertRulesService.installations$;

  // form control variables
  public today = new Date();
  public searchInstallationForm: FormControl = new FormControl('');
  public alertRuleReferenceForm: FormControl = new FormControl('', Validators.required);
  public fullCoverageForm: FormControl = new FormControl(false);
  public alertTimeWindowForm: FormControl = new FormControl(null, Validators.required);
  public alertEventMaxCountForm: FormControl = new FormControl(null, Validators.required);
  public startDateForm: FormControl = new FormControl(this.today);
  public endDateForm: FormControl = new FormControl(null);
  public startTimeForm: FormControl = new FormControl(null);
  public endTimeForm: FormControl = new FormControl(null);
  public installationByReferenceForm: FormControl = new FormControl();
  /** Campos do formulário de filtro **/
  public filterForm: FormGroup = this.formBuilder.group({
    referenceFilter: [null],
  });

  public networkIdSelectionId: string = "UHJvcGVydHk6MTc3";
  public isNetworkIdSelectedArray: boolean[] = [];
  public searchedInstallationList: string[] = [];

  public weekdaysBitmask: number = 127;

  public alertrulestest: any;

  public formStep = 0;

  public isSearchingInstallation: boolean[] = [];

  //Weekdays boolean array
  public weekdaysBooleanArray: boolean[] = [false, false, false, false, false, false, false];

  // subscriptions variables
  public searchInstallationSubscription: Subscription;
  public alertRulesGetterSubscription: Subscription;
  public dialogSubscription: Subscription;
  public alertRuleCreationSubscription: Subscription;

  public openCloseCreateMenu() {
    this.isCreateMenuOpen = !this.isCreateMenuOpen;
    this.isFilterMenuOpen = false;
  }

  public openCloseFilterMenu() {
    this.isFilterMenuOpen = !this.isFilterMenuOpen;
    this.isCreateMenuOpen = false;
  }

  public setStep(index: number) {
    this.formStep = index;
  }

  public nextStep() {
    this.formStep++;
  }

  public prevStep() {
    this.formStep--;
  }

  public addNewRule(): void {
    this.rules.push(new Rule('', '', '', ''));
    this.isNetworkIdSelectedArray.push(false);
  }

  public removeRule(i: number): void {
    this.rules.splice(i, 1);
    this.isNetworkIdSelectedArray[i] = false;
  }

  public selectUser(event: any, userId: string): void {
    if (event.checked) {
      this.selectedUsers.push(
        userId
      )
    } else {
      this.selectedUsers = this.selectedUsers.filter((user: string) => user !== userId);
    }
  }

  public thisInstallationExists(installationNodeId: string | null): Installation | undefined {
    let installationExists: Installation | undefined = this.installationList.find(installationFound => installationFound.installationId === installationNodeId);
    return installationExists;
  }

  //filtra as instalações
  public searchInstallation(): void {
    this.isLoadingNewInstallation = true;
    this.subscriptions = this.alertRulesService.filterInstallation(this.searchInstallationForm.value)
      .valueChanges
      .subscribe({
        next: (response: any) => {
          let responseData = response.data.installation.edges;
          responseData.forEach((installation: any) => {
            let device = installation.node.device?.macAddress;
            if (!this.thisInstallationExists(installation.node.id) && device != undefined) {
              this.installationList.push(
                new Installation(
                  installation.node.id,
                  installation.node.reference
                )
              )
            }
          });
          if (this.installationList.length > 0) {
            const dialogRef = this.dialog.open(InstallationsModalComponent, { data: { installationList: this.installationList }, disableClose: true });
            this.subscriptions = dialogRef.afterClosed().subscribe(result => {
              if (result !== undefined) {
                this.installationList = result;
              }
            });
          } else {
            Swal.fire({
              title: 'Instalação não encontrada',
              text: 'Não foi encontrada nenhuma instalação com a referência informada.',
              icon: 'warning',
              confirmButtonText: 'Ok'
            })
          }
          this.isLoadingNewInstallation = false;
          this.searchInstallationForm.reset();
        },
        error: (error: any) => {
          this.errorLibService.errorAlert(error);
          this.isLoadingNewInstallation = false;
        }
      })
  }

  public openModalWithInstallations(): void {
    this.dialog.open(InstallationsModalComponent, { data: { installationList: this.installationList }, disableClose: true });
  }

  public selectEquipmentType(event: any, index: number): void {
    if (event.value == undefined) {
      this.rules[index].equipmentType = ''
    } else {
      this.rules[index].equipmentType = event.value;
    }
  }

  public selectProperty(event: any, index: number): void {
    if (event.value === this.networkIdSelectionId) {
      this.isNetworkIdSelectedArray[index] = true;
    } else {
      this.isNetworkIdSelectedArray[index] = false;
    }
    this.rules[index].monitoredProperty = event.value;
  }

  public selectRule(event: any, index: number): void {
    this.rules[index].comparisonRule = event.value;
  }

  public selectValue(event: any, index: number): void {
    this.rules[index].comparisonValue = event.target.value;
  }

  public searchInstallationByReference(index: number) {
    this.isSearchingInstallation[index] = true;
    this.installationListNetworkId[index] = [];
    this.subscriptions = this.alertRulesService.getInstallationListByReference(this.installationByReferenceForm.value)
      .valueChanges
      .subscribe({
        next: (response: any) => {
          response.data.installation.edges.forEach((installation: any) => {
            let device = installation.node.device?.macAddress;
            if (device != undefined && device != null) {
              this.installationListNetworkId[index].push(new Installation(installation.node.id, installation.node.reference, installation.node.device.macAddress));
            }
          });
          console.log(this.installationListNetworkId)
          this.isSearchingInstallation[index] = false;
        },
        error: (err: any) => {
          this.errorLibService.errorAlert(err);
          this.isSearchingInstallation[index] = false;
        }
      })
  }

  public selectInstallationMacAddress(event: any, index: number): void {
    const decimalValue = parseInt(event.value, 16).toString();
    this.rules[index].comparisonValue = decimalValue;
    this.isNetworkIdSelectedArray[index] = false;
  }

  public calculateWeekDays(event: any, value: number): void {
    if (event.source.checked) this.weekdaysBitmask += value;
    else this.weekdaysBitmask -= value;
  }

  public setFullCoverage(event: any): void {
    this.fullCoverageForm.setValue(event.checked);
  }

  public monitoredPropertiesIsValid(): boolean {
    let isValid = true;
    this.rules.forEach((rule: Rule) => {
      if (rule.monitoredProperty === null
        || rule.comparisonRule === null
        || rule.comparisonValue === null
        || rule.monitoredProperty === ''
        || rule.comparisonRule === ''
        || rule.comparisonValue === ''
        || rule.monitoredProperty === undefined
        || rule.comparisonRule === undefined
        || rule.comparisonValue === undefined) isValid = false;
    })
    return isValid;
  }

  public createAlertRule(): void {
    this.creatingAlertRuleMutation = true;
    if (!this.alertRuleReferenceForm.valid) {
      this.setStep(1);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Por favor, preencha o campo de referência.',
      });
      this.creatingAlertRuleMutation = false;
      return;
    }
    if (!this.company) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Não foi possível identificar a empresa selecionada.',
      });
      this.creatingAlertRuleMutation = false;
      return;
    }
    if (!this.monitoredPropertiesIsValid()) {
      this.setStep(2);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Por favor, preencha todas as propriedades de monitoramento.',
      });
      this.creatingAlertRuleMutation = false;
      return;
    }
    if (!this.alertTimeWindowForm.valid || !this.alertTimeWindowForm.value) {
      this.setStep(2);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Por favor, preencha o campo de janela de análise e o campo N. de eventos.',
      });
      this.creatingAlertRuleMutation = false;
      return;
    }
    if (this.installationList.length === 0 && this.fullCoverageForm.value == false) {
      this.setStep(4);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Por favor, selecione ao menos uma instalação.',
      });
      this.creatingAlertRuleMutation = false;
      return;
    }
    let installations: (string | null)[] = this.installationList.map((installation: Installation) => installation.installationId);

    // usar a função da biblioteca!!!
    let formatedStartDate = this.startDateForm.value ? this.startDateForm.value.getFullYear() + '-' + ('0' + (this.startDateForm.value.getMonth() + 1)).slice(-2) + '-' + ('0' + this.startDateForm.value.getDate()).slice(-2) : null;
    let formatedEndDate = this.endDateForm.value ? this.endDateForm.value.getFullYear() + '-' + ('0' + (this.endDateForm.value.getMonth() + 1)).slice(-2) + '-' + ('0' + this.endDateForm.value.getDate()).slice(-2) : null;

    this.subscriptions = this.alertRulesService.alertRuleCreate(
      this.alertEventMaxCountForm.value,
      this.alertTimeWindowForm.value,
      this.company,
      this.startTimeForm.value,
      this.endTimeForm.value,
      formatedEndDate,
      this.fullCoverageForm.value,
      installations,
      this.rules,
      this.alertRuleReferenceForm.value,
      formatedStartDate,
      this.selectedUsers,
      this.weekdaysBitmask
    )
      .subscribe({
        next: () => {
          this.creatingAlertRuleMutation = false;
          Swal.fire({
            title: 'Regra de alerta criada com sucesso!',
            text: `A regra de alerta ${this.alertRuleReferenceForm.value} foi criada com sucesso.`,
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        },
        error: (error: any) => {
          this.creatingAlertRuleMutation = false;
          this.errorLibService.errorAlert(error);
        }
      })
  }

  public manageAlertRule(alertRuleId: string, manageMode: string): void {
    let selectedAlertRule;
    selectedAlertRule = this.ELEMENT_DATA.find((alertRule: AlertRuleTable) => alertRule.id === alertRuleId);
    this.isInEditMode = true;
    const dialogRef = this.dialog.open(ManageAlertRuleComponent,
      {
        data: { manageMode, selectedAlertRule },
        disableClose: true,
        height: '90vh',
        width: '80vw',
      });

    this.subscriptions = dialogRef.afterClosed().subscribe((result: any) => {
      this.isInEditMode = false;
      if (result !== undefined) {
      }
    });
  }

  public filterAlertRulesByStatus(value: any): void {
    if (value === 'all') {
      this.dataSource.data = this.convertedTimeDateResponse(this.ELEMENT_DATA);
    }
    else if (value === 'active') {
      let activeList = this.ELEMENT_DATA.filter((alertRule: AlertRuleTable) => alertRule.isActive)
      this.dataSource.data = this.convertedTimeDateResponse(activeList);
    }
    else if (value === 'inactive') {
      let inactiveList = this.ELEMENT_DATA.filter((alertRule: AlertRuleTable) => !alertRule.isActive)
      this.dataSource.data = this.convertedTimeDateResponse(inactiveList);
    }
  }

  public filterAlertRules(): void {
    this.filterLoading = true
    this.dataSource.data = [];
    this.alertRulesService.getAlertRules(this.filterForm?.value?.referenceFilter)
      .valueChanges.subscribe({
        next: ((response: any) => {

          this.ELEMENT_DATA = [];
          // response.data.alertRules.edges.forEach((node: any) => {
          //   let alertRuleList: AlertRuleTable[] = [];
          response.data.alertRules.edges.forEach((alertRule: any) => {
            let ruleList: Rule[] = [];
            alertRule.node.propertyRules.forEach((rule: any) => {
              ruleList.push(new Rule('', rule.monitoredProperty.id, rule.comparisonRule, rule.comparisonValue));
            });
            let installations: any[] = [];
            alertRule.node.installations.edges?.forEach((installation: any) => {
              installations.push(installation.node);
            })
            this.ELEMENT_DATA.push(
              new AlertRuleTable(
                alertRule.node.id,
                alertRule.node.name,
                ruleList,
                alertRule.node.emails,
                alertRule.node.alertTimeWindow,
                alertRule.node.alertEventMaxCount,
                this.alertRulesService.bitmaskToWeekdays(alertRule.node.weekDayBitmask),
                alertRule.node.startDate,
                alertRule.node.endDate,
                alertRule.node.dailyStartTime,
                alertRule.node.dailyStopTime,
                alertRule.node.fullCoverage,
                installations,
                alertRule.node.isActive
              ));
          });

          this.dataSource.data = this.ELEMENT_DATA;

          /** Caso não retorne nada na lista **/
          if (this.ELEMENT_DATA.length === 0) {
            /** Retorna alerta ao usuário **/
            Swal.fire({
              title: 'Sua busca não retornou resultados',
              text: 'Nenhum resultado para este filtro',
              icon: 'warning',
              confirmButtonText: 'Ok'
            });

            /** Desativa o loading **/
            this.filterLoading = false;
          }
          this.filterLoading = false
        })

      })
    // })
  }

  public convertedTimeDateResponse(value: any) {
    const convertedStartDate = value?.map((item: any) => {
      return { ...item, startDate: tzConvertUTC2Local(item.startDate), endDate: item.endDate }
    })
    return convertedStartDate
  }
}
