<div class="component-main-container">
  <!-- Cabeçalho -->
  <div class="header">
    <app-page-header parentTitle="{{'installations.parentTitle' | translate}}"
      pageTitle="{{'installations.pageTitle' | translate}}">
    </app-page-header>
  </div>

  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-12">
      <div class="component-forms-box">
        <!-- Tipos de registro -->
        <app-register-form></app-register-form>

        <!-- Modal de filtros -->
        <div class="form-fields-box" [formGroup]="filterForm" [ngClass]="!filterInstallation ? 'closed' : ''">
          <h2 (click)="filterBoxOpen()"> {{"installations.filter-form" | translate}} </h2>
          <hr [ngClass]="!filterInstallation ? 'hr-open' : ''" />

          <!-- Campo de busca por referencia da instalação -->
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
            <mat-tab label="{{'installations.mat-tab-filter-reference' | translate}}">
              <br>
              <div class="form-step">
                <div class="circle-number"> 1 </div>
                <mat-form-field appearance="outline">
                  <mat-label> {{"installations.filter-form-reference" | translate}} </mat-label>
                  <input matInput formControlName="referenceFilter" #referenceFilter>
                </mat-form-field>
              </div>
              <!-- Botões do filtro. -->
              <div class="d-flex m-2">
                <button *ngIf="!filterLoading" class="btn btn-success m-1 w-50" (click)="filterInstallations(null)">
                  {{"installations.button-filter" | translate}}
                </button>
                <button *ngIf="!filterLoading" (click)="clearFilterForm()" class="btn btn-secondary m-1 w-50">
                  {{"installations.button-clean-filter" | translate}} </button>
                <mat-spinner *ngIf="filterLoading" [diameter]="30"></mat-spinner>
              </div>
            </mat-tab>

            <!-- Campo para realizar a busca de equipamentos por numero de série -->
            <mat-tab label="{{'installations.mat-tab-filter-equipment-type' | translate}}">
              <br>
              <div class="form-step">
                <div class="circle-number">2</div>
                <mat-form-field appearance="outline">
                  <mat-label> {{"installations.filter-form-equipment-type" | translate}}</mat-label>
                  <input matInput formControlName="equipmentsTypeFilter" #serialNumberFilter>
                  <mat-icon class="component-search-icon" (click)="searchEquipmentsFilter(serialNumberFilter.value)"
                    svgIcon="bx-search"></mat-icon>
                </mat-form-field>
              </div>

              <!-- Loading -->
              <mat-progress-bar class="mb-2" *ngIf="equipmentsLoadingFilter" mode="indeterminate"></mat-progress-bar>

              <!-- Lista os equipamentos filtrados -->
              <div class="form-step" *ngIf="equipmentListFilter.length > 0">
                <div class="circle-number-ready"> 2.1 </div>
                <mat-form-field appearance="outline">
                  <mat-label>
                    {{"installations.filter-form-select-equipment" | translate}}
                  </mat-label>
                  <mat-select formControlName="selectedEquipmentFilter" #value>
                    <mat-option apperance="outline" *ngFor="let equipment of equipmentListFilter"
                      [value]="equipment.id">
                      {{equipment.serialNumber}} ({{equipment.reference}}
                      {{equipment.major}}.{{equipment.minor}}.{{equipment.revision}} )
                    </mat-option>
                    <mat-option *ngIf="equipmentListFilter.length < 0">
                      {{"installations.filter-form-no-equipments" | translate}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- Botões do filtro. -->
              <div class="d-flex m-2">
                <button *ngIf="!filterLoading" class="btn btn-success m-1 w-50" (click)="filterInstallations(null)">
                  {{"installations.button-filter" | translate}}
                </button>
                <button *ngIf="!filterLoading" (click)="clearFilterForm()" class="btn btn-secondary m-1 w-50">
                  {{"installations.button-clean-filter" | translate}}
                </button>
                <mat-spinner *ngIf="filterLoading" [diameter]="30"></mat-spinner>
              </div>
            </mat-tab>

            <!-- Campo para realizar a busca por locais de instalações -->
            <mat-tab label="{{'installations.mat-tab-filter-installation-site' | translate}}">
              <br>
              <div class="form-step">
                <div class="circle-number"> 3 </div>
                <mat-form-field appearance="outline">
                  <mat-label> {{"installations.filter-form-installation-location" | translate}} </mat-label>
                  <input matInput [placeholder]="filterPlaceholder" #filter>
                  <mat-icon class="component-search-icon" svgIcon="bx-search"></mat-icon>
                </mat-form-field>
              </div>

              <div class="row form">
                <div class="form-step">
                  <mat-form-field appearance="outline">
                    <mat-label> CEP </mat-label>
                    <input matInput mask="00000-000" [(ngModel)]="filterPostalCode" disabled=""
                      [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                </div>

                <div class="form-step">
                  <mat-form-field appearance="outline">
                    <mat-label> {{"installations.filter-form-public-place" | translate}} </mat-label>
                    <input matInput [(ngModel)]="filterPublicPlace" disabled="" #street
                      [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                </div>

                <div class="form-step">
                  <mat-form-field appearance="outline">
                    <mat-label> {{"installations.filter-form-district" | translate}} </mat-label>
                    <input matInput [(ngModel)]="filterDistrict" disabled="" #district
                      [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                </div>

                <div class="form-step">
                  <mat-form-field appearance="outline">
                    <mat-label> {{"installations.filter-form-city" | translate}} </mat-label>
                    <input matInput [(ngModel)]="filterCity" disabled="" #city [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                </div>

                <div class="form-step">
                  <mat-form-field appearance="outline">
                    <mat-label> {{"installations.filter-form-state" | translate}} </mat-label>
                    <input matInput [(ngModel)]="filterState" disabled="" #state [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                </div>

                <div class="form-step">
                  <mat-form-field appearance="outline">
                    <mat-label> {{"installations.filter-form-country" | translate}} </mat-label>
                    <input matInput [(ngModel)]="filterCountry" disabled="" #country
                      [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                </div>
              </div>

              <!-- Botões do filtro . -->
              <div class="d-flex m-2">
                <button *ngIf="!filterLoading" class="btn btn-success m-1 w-50" (click)="filterInstallations(
                  null)">
                  {{"installations.button-filter" | translate}}
                </button>

                <button *ngIf="!filterLoading" (click)="clearFilterForm()" class="btn btn-secondary m-1 w-50">
                  {{"installations.button-clean-filter" | translate}}
                </button>
                <mat-spinner *ngIf="filterLoading" [diameter]="30"></mat-spinner>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <!-- Modal que registra a instalação -->
        <div class="form-fields-box" [formGroup]="createForm" [ngClass]="!registerInstallation ? 'closed' : ''">
          <h2 (click)="registerBoxOpen()"> {{"installations.register-form" | translate}} </h2>
          <hr [ngClass]="!registerInstallation ? 'hr-open' : ''" />

          <section>
            <mat-checkbox *ngIf="!hasMagneticKey" (change)="isVirtualInstallation($event.checked)">
              {{"installations.register-form-virtual-installation" | translate}}
            </mat-checkbox>
            <mat-checkbox *ngIf="!virtualInstallationCheck" (change)="ifHasMagneticKey($event.checked)"
              formControlName="hasMagneticKey">
              {{"installations.register-form-magnetic-key" | translate}}
            </mat-checkbox>
          </section>
          <br>

          <div class="form-step">
            <div class="circle-number"> 1 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{"installations.register-form-reference" | translate}}</mat-label>
              <input matInput formControlName="referenceCreate">
            </mat-form-field>
          </div>

          <!-- Campo para realizar a busca de locais de instalações -->
          <div class="form-step">
            <div class="circle-number"> 2 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{"installations.register-form-installation-location" | translate}} </mat-label>
              <input matInput formControlName="installationSite" #referenceSiteCreate>
              <mat-icon class="component-search-icon" (click)="searchInstallationSite(referenceSiteCreate.value)"
                svgIcon="bx-search"></mat-icon>
            </mat-form-field>
          </div>

          <!-- Campo que lista os locais de instalações  -->
          <div class="form-step" *ngIf="installationSiteList.length > 0">
            <div class="circle-number-ready"> 2.1 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{"installations.register-form-select-installation-location" | translate}} </mat-label>
              <mat-select formControlName="selectedInstallationSite" required="true">
                <mat-option *ngFor="let site of installationSiteList" [value]="site.id">
                  {{site.reference}} - ({{site.street}} {{site.city}}/{{site.state}})
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- Loading -->
          <mat-progress-bar class="mb-2" *ngIf="installationSiteLoading" mode="indeterminate"></mat-progress-bar>

          <!-- Campo que contém a lista de tipos de luminárias -->
          <div class="form-step">
            <div class="circle-number"> 3 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{"installations.register-form-lamps-type" | translate}} </mat-label>
              <mat-select formControlName="lampType">
                <mat-option *ngFor="let lamps of lampsTypeList" [value]="lamps.id">
                  {{lamps.model}}
                </mat-option>
                <mat-option *ngIf="lampsTypeList.length < 0">
                  {{"installations.register-form-no-lamps-types" | translate}}
                </mat-option>
                <mat-option *ngIf="createLoading">
                  <mat-spinner [diameter]="30"></mat-spinner>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Campo que contém a lista as divisiões (Subprefeituras) -->
          <div class="form-step" *ngIf="divisionList.length > 0">
            <div class="circle-number"> 3 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{"installations.register-form-division" | translate}} </mat-label>
              <mat-select formControlName="division">
                <mat-option *ngFor="let divisions of divisionList" [value]="divisions.id">
                  {{divisions.reference}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Campo que lista as aplicações -->
          <div class="form-step">
            <div class="circle-number"> 4 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{"installations.register-form-application" | translate}} </mat-label>
              <mat-select formControlName="applications">
                <mat-option value='PUBLIC_LIGHTING'> {{"installations.application-public-light" | translate}}
                </mat-option>
                <mat-option value='ELECTRICITY_CONSUMPTION'> {{"installations.application-electricity-consumption" |
                  translate}}</mat-option>
                <mat-option value='WATER_CONSUMPTION'> {{"installations.application-water-consumption" | translate}}
                </mat-option>
                <mat-option value='GAS_CONSUMPTION'> {{"installations.application-gas-consumption" | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Campo para realizar a busca de concentradores -->
          <div class="form-step" *ngIf="!virtualInstallationCheck">
            <div class="circle-number"> 5 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{"installations.register-form-gateway" | translate}}</mat-label>
              <input matInput formControlName="gateway" #referenceGatewayCreate>
              <mat-icon class="component-search-icon" (click)="searchGateway(referenceGatewayCreate.value)"
                svgIcon="bx-search"></mat-icon>
            </mat-form-field>
          </div>
          <!-- Loading -->
          <mat-progress-bar class="mb-2" *ngIf="loadingGateway" mode="indeterminate"></mat-progress-bar>

          <!-- Campo que contém a lista de concentradores filtrados -->
          <div class="form-step" *ngIf="gatewayList.length > 0">
            <div class="circle-number-ready"> 5.1 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{"installations.register-form-select-gateway" | translate}} </mat-label>
              <mat-select formControlName="gatewaySelected">
                <mat-option *ngFor="let gateway of gatewayList" [value]="gateway.id">
                  {{gateway.reference}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Campo para realizar a busca dos equipamentos por numero de série -->
          <div class="form-step" *ngIf="!virtualInstallationCheck">
            <div class="circle-number"> 6 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{"installations.register-form-equipment-type" | translate}} </mat-label>
              <input matInput formControlName="equipmentsTypeCreate" #serialNumberEqpCreate>
              <mat-icon class="component-search-icon" (click)="searchEquipments(serialNumberEqpCreate.value)"
                svgIcon="bx-search"></mat-icon>
            </mat-form-field>
          </div>

          <mat-progress-bar class="mb-2" *ngIf="equipmentsLoading" mode="indeterminate"></mat-progress-bar>

          <!-- Campo que contém a lista de equipamentos filtradas -->
          <div class="form-step" *ngIf="equipmentList.length > 0">
            <div class="circle-number-ready"> 6.1 </div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{"installations.register-form-select-equipment" | translate}}
              </mat-label>
              <mat-select formControlName="selectedEquipmentCreate">
                <mat-option apperance="outline" *ngFor="let equipments of equipmentList" [value]="equipments.id">
                  {{equipments.serialNumber}} ({{equipments.reference}}
                  {{equipments.major}}.{{equipments.minor}}.{{equipments.revision}} )
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Botão de criação -->
          <button *ngIf="!createLoading" class="btn btn-success" (click)="createInstallation()">
            {{"installations.button-save" | translate}}
          </button>
          <mat-spinner *ngIf="createLoading" [diameter]="30"></mat-spinner>
        </div>
      </div>
    </div>

    <!-- Tabela de conteúdos. -->
    <div class="col-lg-7 col-xl-8 col-sm-12" *ngIf="paginationProperties.total">
      <!-- Header -->
      <app-table-header *ngIf="length > 0" [itensNumber]="paginationProperties.count"
        [totalNumber]="paginationProperties.total" [hasCSV]="true" [hasPDF]="true"
        (downloadCSV)="generateFileData(null, 'CSV')" (downloadPDF)="generateFileData(null, 'PDF')"
        [loadingData$]="fileLoading">
      </app-table-header>

      <!-- Listagem -->
      <div class="table-div mt-2 mt-lg-3" *ngIf="length > 0">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef>{{"installations.column-table-reference" | translate}}</th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">{{element.reference}}</td>
          </ng-container>

          <ng-container matColumnDef="installationSite">
            <th mat-header-cell *matHeaderCellDef> {{"installations.column-table-installation-location" | translate}}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">

              {{element.site.district}}
              <span
                *ngIf="element.site.district && element.site.city && element.site.street && element.site.number !=  null">
                ,
              </span>

              {{element.site.city}}
              <span
                *ngIf="element.site.district && element.site.city && element.site.street && element.site.number !=  null">
                ,
              </span>

              {{element.site.street}}
              <span
                *ngIf="element.site.district && element.site.city && element.site.street && element.site.number !=  null">
                ,
              </span>
              {{element.site.number}}
            </td>
          </ng-container>

          <ng-container matColumnDef="equipmentType">
            <th mat-header-cell *matHeaderCellDef> {{"installations.column-table-equipment-type" | translate}}</th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">

              {{element.device.reference}}
              {{element.device.major}}
              <span
                *ngIf="element.device.reference && element.device.major && element.device.minor && element.device.revision !=  null">.</span>
              {{element.device.minor}}

              <span
                *ngIf="element.device.reference && element.device.major && element.device.minor && element.device.revision !=  null">.</span>
              {{element.device.revision}}
            </td>
          </ng-container>

          <ng-container matColumnDef="gateway">
            <th mat-header-cell *matHeaderCellDef> {{"installations.column-table-gateway" | translate}} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              {{element.gateway.reference}}</td>
          </ng-container>

          <ng-container matColumnDef="lampsType">
            <th mat-header-cell *matHeaderCellDef> {{"installations.column-table-lamp-type" | translate}} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              {{element.lampType.model}}</td>
          </ng-container>

          <ng-container matColumnDef="division">
            <th mat-header-cell *matHeaderCellDef> {{"installations.column-table-division" | translate}} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              {{element.division.reference}}
              <span *ngIf="element.division.reference == ''"> {{"installations.division-unbound" | translate}} </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="magneticKey">
            <th mat-header-cell *matHeaderCellDef> {{"installations.column-table-magnetic-key" | translate}} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <span *ngIf="element.hasMagneticKey === true ">{{"installations.has-magnetic-key" | translate}}</span>
              <span *ngIf="element.hasMagneticKey === false">{{"installations.no-magnetic-key" | translate}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> {{"installations.column-table-status" | translate}} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <span *ngIf="element.isActive === true ">{{"installations.status-active" | translate}}</span>
              <span *ngIf="element.isActive === false">{{"installations.status-inactive" | translate}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> {{"installations.column-table-actions" | translate}} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <button (click)="openSidebarUpdate(element, false)" [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success view">
                <mat-icon class="component-svg-icon" svgIcon="bx-edit"></mat-icon>
              </button>
              <button (click)="openSidebarUpdate(element, true)" [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success view">
                <mat-icon class="component-svg-icon" svgIcon="bx-show-white"></mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex"
          (page)="pageChanged($event)" aria-label="Selecione as páginas de Instalações" class="mat-paginator-sticky">
        </mat-paginator>
      </div>
    </div>

    <!-- SideBar para edição/visualização -->
    <div class="sidebar-edit" *ngIf="editorController" [formGroup]="updateForm">
      <mat-icon class="component-close-icon" (click)="closeEditionSideBar()"> clear </mat-icon>

      <h2 class="title-edit" *ngIf="!viewController"> {{"installations.edit-form" | translate}} </h2>
      <h2 *ngIf="viewController"> {{"installations.view-form" | translate}} </h2>
      <mat-form-field appearance="outline">
        <mat-label> {{"installations.edit-form-reference" | translate}} </mat-label>
        <input matInput formControlName="referenceUpdate">
      </mat-form-field>
      <section>
        <mat-checkbox formControlName="hasMagneticKey">
          {{"installations.edit-form-magnetic-key" | translate}}
        </mat-checkbox>
      </section>
      <br>

      <!-- Campo para realizar a busca de local de instalação -->
      <mat-form-field appearance="outline">
        <mat-label> {{"installations.edit-form-installation-location" | translate}} </mat-label>
        <input matInput formControlName="searchSiteUpdate" #referenceSiteUpdate required="">
        <mat-icon class="component-search-icon" (click)="searchInstallationSite(referenceSiteUpdate.value)"
          svgIcon="bx-search"></mat-icon>
      </mat-form-field>

      <!-- Loading -->
      <mat-progress-bar class="mb-2" *ngIf="installationSiteLoading" mode="indeterminate"></mat-progress-bar>

      <!-- Campo que lista os locais de instalações filtrados -->
      <mat-form-field appearance="outline" *ngIf="installationSiteList.length > 0">
        <mat-label>
          {{"installations.edit-form-select-installation-location" | translate}}
        </mat-label>
        <mat-select formControlName="siteSelectedUpdate" required="true">
          <mat-option apperance="outline" #value *ngFor="let site of installationSiteList" [value]="site.id">
            {{site.reference}} - ({{site.street}} {{site.city}}/{{site.state}})
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Campo para realizar a busca de equipamentos-->
      <mat-form-field appearance="outline">
        <mat-label> {{"installations.edit-form-equipment-type" | translate}}</mat-label>
        <input matInput formControlName="searchEquipmentUpdate" #referenceEquipUpdate>
        <mat-icon class="component-search-icon" (click)="searchEquipments(referenceEquipUpdate.value)"
          svgIcon="bx-search"></mat-icon>
      </mat-form-field>
      <mat-progress-bar class="mb-2" *ngIf="equipmentsLoading" mode="indeterminate"></mat-progress-bar>

      <!-- Campo que lista os equipamentos filtrados -->
      <mat-form-field appearance="outline" *ngIf="equipmentList.length > 0">
        <mat-label> {{"installations.edit-form-select-equipment" | translate}} </mat-label>
        <mat-select formControlName="equipmentSelectedUpdate" #value>
          <mat-option apperance="outline" *ngFor="let equipments of equipmentList" [value]="equipments.id">
            {{equipments.serialNumber}} ({{equipments.reference}}
            {{equipments.major}}.{{equipments.minor}}.{{equipments.revision}} )
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Campo para realizar a busca de concentradores -->
      <mat-form-field appearance="outline">
        <mat-label> {{"installations.edit-form-gateway" | translate}} </mat-label>
        <input matInput formControlName="searchGatewayUpdate" #referenceGatewayUpdate>
        <mat-icon class="component-search-icon" (click)="searchGateway(referenceGatewayUpdate.value)"
          svgIcon="bx-search"></mat-icon>
      </mat-form-field>

      <!-- Loading -->
      <mat-progress-bar class="mb-2" *ngIf="loadingGateway" mode="indeterminate"></mat-progress-bar>

      <!-- Campo que lista os concentradores filtrados -->
      <mat-form-field appearance="outline" *ngIf="gatewayList.length > 0">
        <mat-label>
          {{"installations.edit-form-select-gateway" | translate}}
        </mat-label>
        <mat-select formControlName="gatewaySelectedUpdate" #value>
          <mat-option apperance="outline" *ngFor="let gateway of gatewayList" [value]="gateway.id">
            {{gateway.reference}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Campo que lista as divisões/subprefeituras (caso exista na empresa selecionada) -->
      <mat-form-field appearance="outline" *ngIf="divisionList.length > 0">
        <mat-label> {{"installations.edit-form-division" | translate}} </mat-label>
        <mat-select formControlName="division">
          <mat-option *ngFor="let divisions of divisionList" [value]="divisions.id" #value>
            {{divisions.reference}}
          </mat-option>
          <mat-option *ngIf="createLoading">
            <mat-spinner [diameter]="30"></mat-spinner>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Lista as aplicações -->
      <mat-form-field appearance="outline">
        <mat-label> {{"installations.edit-form-application" | translate}} </mat-label>
        <mat-select formControlName="applications">
          <mat-option value='PUBLIC_LIGHTING'> {{"installations.edit-public-light" | translate }} </mat-option>
          <mat-option value='ELECTRICITY_CONSUMPTION'> {{"installations.edit-electricity-consumption" | translate}}
          </mat-option>
          <mat-option value='WATER_CONSUMPTION'> {{"installations.edit-water-consumption" | translate}} </mat-option>
          <mat-option value='GAS_CONSUMPTION'> {{"installations.edit-gas-consumption" | translate}} </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Botões de edição. -->
      <div class="d-flex m-2" id="buttons-edit" *ngIf="!viewController">
        <button *ngIf="!viewController && updateForm.value.isActive " class="btn btn-success m-1 w-50"
          (click)="updateInstallation()">
          {{"installations.button-save" | translate}}
        </button>

        <button class="btn btn-secondary m-1 w-50" (click)="updateStatusInstallation(false)"
          *ngIf="updateForm.value.isActive">
          {{"installations.button-inactivate" | translate}}
        </button>
        <button class="btn btn-secondary m-1 w-50" (click)="updateStatusInstallation(true)"
          *ngIf="!updateForm.value.isActive">
          {{"installations.button-activate" | translate}}
        </button>
        <button class="btn btn-danger m-1 w-50" (click)="deleteInstallation()">
          {{"installations.button-delete" | translate}}
        </button>
      </div>
    </div>
  </div>