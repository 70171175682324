import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, catchError, take, tap } from 'rxjs';
import { GET_ALL_METERS_TABLE, FILTER_METERS, CREATE_METERS, GET_METERS_TYPE } from 'src/app/graphql/meters.graphql';
import Swal from 'sweetalert2';
import { Installations, Meters, MetersTable, MetersType } from '../components/register/pages/meters/meters-assets/meters.model';
import { ErrorLibService } from './error-lib.service';
import { FILTER_INSTALLATIONS } from 'src/app/graphql/installations.graphql';

@Injectable({
  providedIn: 'root'
})

export class MetersService {

  constructor(private apollo: Apollo, private errorLibService: ErrorLibService) {

    /** Verifica a empresa selecionada **/
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;

    /** Chama a função que realiza a requisição dos tipos de medidores **/
    this.getMetersType();

    /** Chama a função que realiza a requisição de instalações **/
    this.getInstallations();
  }

  /** Armazena ID da empresa selecionada **/
  company: string | null;

  /** Variável Subject resposável por manipular a variável assincrona meters$ **/
  private metersSubject = new BehaviorSubject<Meters[]>([])
  meters$ = this.metersSubject.asObservable();

  /** Variável Subject resposável por manipular a variável assincrona metersType$ **/
  private metersTypeSubject = new BehaviorSubject<MetersType[]>([])
  metersType$ = this.metersTypeSubject.asObservable();

  /** Variável Subject resposável por manipular a variável assincrona installation$ **/
  private installationSubject = new BehaviorSubject<Installations[]>([])
  installation$ = this.installationSubject.asObservable();

  /** Variável Subject resposável por manipular a variável assincrona installationLoading$ **/
  private installationLoadingSubject = new BehaviorSubject<boolean>(false)
  installationLoading$ = this.installationLoadingSubject.asObservable();

  /** Variável Subject resposável por manipular a variável assincrona meterTypeLoading$ **/
  private metersTypeLoadingSubject = new BehaviorSubject<boolean>(false)
  meterTypeLoading$ = this.metersTypeLoadingSubject.asObservable();

  /** Variável Subject resposável por manipular a variável assincrona meterLoading$ **/
  private metersLoadingSubject = new BehaviorSubject<boolean>(false)
  metersLoading$ = this.metersLoadingSubject.asObservable();

  /** Requisição de todos os medidores da tabela (filtro inicial) **/
  getMetersTable(company: string): any {
    /** Ativa o loading**/
    this.metersLoadingSubject.next(true)
    /** Chama a requisição **/
    this.apollo.watchQuery({
      query: GET_ALL_METERS_TABLE,
      fetchPolicy: 'network-only',
      variables: {
        company,
        sort_dir: "ASC",
        sort_field: "SERIAL"
      }
    }).valueChanges
      .pipe(
        take(1),
        /** Trata o resultado de sucesso **/
        tap((res: any) => {
          /** Cria uma variavel que contém uma lista de medidores vazia **/
          let metersTableList: MetersTable[] = [];
          /** Adiciona cada medidor dentro da lista de medidores **/
          res.data.material.edges.forEach((node: any) => {
            metersTableList.push(new MetersTable(node.node?.id, node.node?.serialNumber,
              node.node?.type?.reference, node.node?.installation?.reference))
          });
          /** Atualiza a variavel assincrona com a lista de medidores **/
          this.metersSubject.next(metersTableList);
          /** Desativa o loading **/
          this.metersLoadingSubject.next(false);
          /** Chama a função que retorna a lista de medidores **/
        }),
        catchError((err) => { throw err })
      ).subscribe({
        error: (error) => {
          console.log(error)
          /** Desativa o loading **/
          this.metersLoadingSubject.next(false);
          /** Emite mensagem de erro **/
          this.errorLibService.errorAlert(error);
        }
      });
  }

  /** Função que retorna a variável assincrona com a lista de medidores **/
  getMetersTableGetter() {
    return this.meters$;
  }

  /** Requisição que filtra os medidores **/
  private filterMeters(company: string, search: string, materialTypes: string[]): any {
    /** Ativa o loading **/
    this.metersLoadingSubject.next(true)
    /** Realiza a requisição **/
    this.apollo.watchQuery({
      query: FILTER_METERS,
      fetchPolicy: 'network-only',
      variables: {
        company,
        search,
        materialTypes,
        cursor: null,
        sort_dir: "ASC",
        sort_field: "SERIAL"
      }
    })
      .valueChanges
      .pipe(
        take(1),
        /** Trata a resposta de sucesso **/
        tap((res: any) => {
          /** Cria um variável que contém uma lista de medidores vazia **/
          let metersList: Meters[] = [];
          /** Adiciona cada medidor a lista de medidores **/
          res.data.material.edges.forEach((node: any) => {
            metersList.push(new Meters(node.node?.id, node.node?.serialNumber, node.node.type?.reference,
              node.node.installation?.reference))
          });
          console.log(metersList)
          /** Atualiza a variavel assincrona com a lista de medidores **/
          this.metersSubject.next(metersList);

          this.metersGetter();
          /** Desativa o loading **/
          this.metersLoadingSubject.next(false);
        }), /** Tratamento de erros **/
        catchError((err) => { throw err })
      ).subscribe({
        error: (error) => {
          console.log(error)
          /** Desativa o loading **/
          this.metersLoadingSubject.next(false);
          /** Emite mensagem de erro **/
          this.errorLibService.errorAlert(error);
        }
      });
  }

  /** Função que retorna a variável assincrona que contém a lista de medidores **/
  metersGetter() {
    return this.meters$
  }

  /** Função que contém a lista manipulavel dos medidores para ser utilizada no TS **/
  metersRefresh(company: string, search: string, type: string[]): any {
    this.filterMeters(company, search, type)
  }

  /** Requisição de criação de medidores **/
  createMeters(company: string, serialNumber: string, installationId: string[], materialType: string[]) {
    /** Ativa o loading **/
    this.metersLoadingSubject.next(true);
    /** Envia a mutation **/
    this.apollo.mutate({
      mutation: CREATE_METERS,
      variables: {
        company: company,
        serialNumber: serialNumber,
        installationId: installationId,
        materialType: materialType
      }
    }).pipe(
      tap(() => {
        /** Desativa o loading **/
        this.metersLoadingSubject.next(false);

        /** Atualiza o filtro de medidores **/
        this.filterMeters(company, serialNumber, materialType)

        /** Ao criar o medidor exibe mensagem de sucesso ao usuário **/
        Swal.fire({
          title: 'Medidor Criado',
          text: 'Medidor criado com sucesso',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      }),
      /** Tratamento de erros **/
      catchError((err) => { throw err })
    ).subscribe({
      error: (error) => {
        /** Desativa o loading na tela **/
        this.metersLoadingSubject.next(false);
        /** Exibe alerta do erro ao usuário **/
        this.errorLibService.errorAlert(error);
      }
    });
  }

  /** Função  que chama a requisição de criação de medidores **/
  getCreateMeters(company: string, serialNumber: string, installationId: string[], materialType: string[]) {
    this.createMeters(company, serialNumber, installationId, materialType)
  }

  /** Requisição de tipos de medidores **/
  getMetersType() {
    /** Ativa o loading **/
    this.metersTypeLoadingSubject.next(true)
    /** Chama a requisição **/
    this.apollo.watchQuery({
      query: GET_METERS_TYPE,
      fetchPolicy: 'network-only',
      variables: {
        pageSize: 100,
        company: this.company,
        sort_dir: "ASC",
        sort_field: "MODEL"
      }
    }).valueChanges
      .pipe(
        take(1),
        /** Trata o resultado de sucesso **/
        tap((res: any) => {
          /** Cria uma variável que contém uma lista de tipos de medidores vazia **/
          let meterTypeList: MetersType[] = [];
          /** Adiciona cada tipo de medidor a lista de medidores **/
          res.data.materialType.edges.forEach((node: any) => {
            meterTypeList.push(new MetersType(node.node?.id, node.node?.reference));
          });
          /** Atualiza a variável assincrona com a lista de tipos de medidores **/
          this.metersTypeSubject.next(meterTypeList);
          /** Desativa o loading **/
          this.metersTypeLoadingSubject.next(false)
          /** Chama a função que contém a lista de tipos de medidores **/
          this.metersTypeGetter();
          /** Tratamento de erros**/
        }), catchError((err) => { throw err })
      ).subscribe({
        error: (error) => {
          console.log(error)
          /** Desativa o loading **/
          this.metersLoadingSubject.next(false);
          /** Emite mensagem de erro **/
          this.errorLibService.errorAlert(error);
        }
      })
  }

  /** Função que retorna a variável assincrona que contém lista com tipos de medidores **/
  metersTypeGetter() {
    return this.metersType$;
  }

  /** Requisição de instalações **/
  getInstallations(): any {
    /** Ativa o loading **/
    this.installationLoadingSubject.next(true)
    /** Chama a requisição **/
    this.apollo.watchQuery({
      query: FILTER_INSTALLATIONS,
      fetchPolicy: 'network-only',
      variables: {
        company: this.company,
        sort_dir: "ASC",
        sort_field: "REFERENCE"
      }
    }).valueChanges
      .pipe(
        take(1),
        /** Trata a resposta de sucesso **/
        tap((res: any) => {
          /** Cria uma variável que contém uma lista de instalações vazia **/
          let installationList: Installations[] = [];
          /** Adiciona cada instalação a lista de instalações **/
          res.data.installation.edges.forEach((node: any) => {
            installationList.push(new Installations(node.node?.id, node.node?.reference))
          });
          /** Atualiza a variável assincrona **/
          this.installationSubject.next(installationList);
          /** Desativa o loading **/
          this.installationLoadingSubject.next(false);
          /** Chama a função que contém a lista de instalações **/
          this.installationsGetter();
          /** Tratamento de erros **/
        }), catchError((err) => { throw err })
      ).subscribe({
        error: (error) => {
          console.log(error)
          /** Desativa o loading **/
          this.metersLoadingSubject.next(false);
          /** Emite mensagem de erro **/
          this.errorLibService.errorAlert(error);
        }
      })
  }

  /** Função que retorna a variável assincrona que contém a lista de instalações **/
  installationsGetter() {
    return this.installation$;
  }
}