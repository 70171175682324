import { gql } from "apollo-angular";

const FILTERS_UNIT_MEASUREMENT = gql`
query MeasurementUnit(
    $pageSize: Int,
    $cursor: String,
    $nameContains: String,
    ) {
    measurementUnit(
        pageSize: $pageSize,
        cursor: $cursor,
        nameContains: $nameContains,
        ) {
        edges {
            node {
                id
                name
                symbol
            }
            cursor
        }
        total
        count
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
    }
}
`;

const CREATE_UNIT_MEASUREMENT = gql`
mutation measurementunitCreate($name: String!, $symbol: String!) {
    measurementUnitCreate(
        input: {
            name: $name,
            symbol: $symbol
        }) {
        measurementUnit {
            id
            name
            symbol
        }
    }
}
`

const UPDATE_UNIT_MEASUREMENT = gql`
mutation measurementunitUpdate($id: ID!, $name: String!, $symbol: String!) {
    measurementUnitUpdate(
        input: {
            id: $id,
            name: $name,
            symbol: $symbol
        }) {
        measurementUnit {
            id
            name
            symbol
        }
    }
}
`

export {
    // Queries
    FILTERS_UNIT_MEASUREMENT,
    // Mutations
    CREATE_UNIT_MEASUREMENT,
    UPDATE_UNIT_MEASUREMENT
}