import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LampsTypeService } from 'src/shared/services/lamps-type.service';
import Swal from 'sweetalert2';
import { DimmingsStatusCheckbox, LampsType, PaginationInfo } from './lamps-type-assets/lamps-types.model';
import { Subject } from 'rxjs';
import { ThemePalette } from '@angular/material/core';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-lamps-type',
  templateUrl: './lamps-type.component.html',
  styleUrls: ['./lamps-type.component.less']
})
export class LampsTypeComponent extends Unsubscriber {

  constructor(
    private formBuilder: FormBuilder,
    private lampsTypeService: LampsTypeService,
    private errorLibService: ErrorLibService
  ) {
    super();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  /** Campos dos formulário de filtro de tipos de luminárias **/
  public filterLampsForm: FormGroup = this.formBuilder.group({
    referenceFilterForm: [null],
    powerFilterForm: [null]
  });

  /** Campos do formulário de registo de tipos de luminárias **/
  public registerLampsForm: FormGroup = this.formBuilder.group({
    modelRegisterForm: [null, Validators.required],
    manufacturerRegisterForm: [null, Validators.required],
    powerRegisterForm: [null, Validators.required],
    brightnessRegisterForm: [null, Validators.required],
    consumptionRegisterForm: [null, Validators.required],
    currentRegisterForm: [null, Validators.required],
    powerFactorRegisterForm: [null, Validators.required],
    voltageRegisterForm: [null, Validators.required],
    dimmerTypeRegisterForm: [null, Validators.required]
  });

  /** Campos do formulário de edição de tipos de luminárias **/
  public updateForm: FormGroup = this.formBuilder.group({
    modelUpdateForm: [null, Validators.required],
    manufacturerUpdateForm: [null, Validators.required],
    powerUpdateForm: [null, Validators.required],
    brightnessUpdateForm: [null, Validators.required],
    consumptionUpdateForm: [null, Validators.required],
    currentUpdateForm: [null, Validators.required],
    powerFactorUpdateForm: [null, Validators.required],
    voltageUpdateForm: [null, Validators.required],
    dimmerTypeUpdateForm: [null, Validators.required]
  });

  /** Variáveis utilizadas para manipular os boxs de registro e filtro de tipos de luminarias **/
  public registerLampsBox: boolean = false;
  public filterLampsBox: boolean = false;

  /** Variáveis utilizadas para armazenar o filtro do usuário **/
  public power: number;
  public manufacturer: string;

  /** Variáveis de Loading **/
  public filterLoadingLampsType: boolean = false;
  public registerLoadingLampsType: boolean = false;
  public fileLoading: boolean = false;

  /** Variável para controle da edição **/
  public editorController: boolean = false;

  /** Variável para controle de visualização**/
  public viewController: boolean = false;

  /** Variável que recebe o ID do tipo de luminária para edição**/
  public lampTypeSelected: string;

  /** Variáveis utilizadas para controlar o checkbox de dimetrização **/
  public cleaningFilter: Subject<boolean> = new Subject();

  public dimmingsOptions: DimmingsStatusCheckbox[] = [
    new DimmingsStatusCheckbox('DALI', 'T_DALI', false),
    new DimmingsStatusCheckbox('1-10V', 'T_1_10V', false),
  ];

  public selectedDimmingsOptions: string[] = [];

  /** Variavel que armazena os valores dos campos da tabela **/
  public ELEMENT_DATA: LampsType[] = [];

  /** Variável com as colunas da tabela **/
  public displayedColumns: string[] = ['manufacturer', 'power', 'dimmingType', 'description', 'actions'];

  public dataSource = new MatTableDataSource<LampsType>(this.ELEMENT_DATA);

  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  /** Variável utilizada para controle de paginação **/
  public paginationProperties: PaginationInfo = new PaginationInfo(
    0,
    0,
    false,
    false,
    null,
    null
  );

  /** Variáveis utilizadas para manipulação do Paginator **/
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public currentPage: number = 0;
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  /** Função chamada toda vez que ocorre um evento no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.filterLampsType(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterLampsType(this.paginationProperties.endCursor)
    }
  }

  /** Variáveis utilizadas para geração e download dos arquivos **/
  public FILE_DATA: LampsType[] = [];
  public isReady: boolean = false;

  /** Funções que realizam a manipulação dos boxs de registro e filtro de tipos luminarias **/
  public registerLampsBoxOpen() {
    this.registerLampsBox = !this.registerLampsBox
    this.filterLampsBox = false;
  }

  public filterLampsBoxOpen() {
    this.filterLampsBox = !this.filterLampsBox
    this.registerLampsBox = false;
  }

  public onSelectionOptionChange(event: string[]): any {
    this.selectedDimmingsOptions = event;
  }

  /** Função que chama a requisição de filtro de tipos de luminárias **/
  public filterLampsType(cursor: string | null): any {

    if (cursor === null) {
      this.pageIndex = 0;
      this.isReady = true;
    }

    /** Armazena os filtros feitos pelo usuário **/
    this.power = Number.parseInt(this.filterLampsForm?.value?.powerFilterForm);
    this.manufacturer = this.filterLampsForm?.value?.manufacturerFilterForm;

    this.filterLoadingLampsType = true;

    this.subscriptions = this.lampsTypeService.filterLampsType(
      cursor,
      this.manufacturer,
      this.power,
      this.pageSize
    ).valueChanges.subscribe({
      next: ((response: any) => {
        this.paginationProperties = new PaginationInfo(
          response.data.lampType.count,
          response.data.lampType.total,
          response.data.lampType.pageInfo.hasNextPage,
          response.data.lampType.pageInfo.hasPreviousPage,
          response.data.lampType.pageInfo.startCursor,
          response.data.lampType.pageInfo.endCursor
        )

        this.ELEMENT_DATA = [];
        this.length = response.data.lampType.total;
        this.isReady = false;

        response.data.lampType.edges.forEach((lampType: any) => {

          let dimmeringType;
          /** Tratamento de dados retornados da API **/
          if (lampType.node?.dimmingType === "DALI") {
            dimmeringType = "T_DALI";
          } else {
            dimmeringType = "T_1_10V";
          }
          this.ELEMENT_DATA.push(
            new LampsType(
              lampType.node?.id,
              lampType.node?.model,
              lampType.node?.manufacturer,
              lampType.node?.power,
              dimmeringType,
              lampType.node?.brightness,
              lampType.node?.consumption,
              lampType.node?.current,
              lampType.node?.powerFactor,
              lampType.node?.voltage,
            )
          );

          /** Atualiza os valores da tabela **/
          this.setDataSourceAttributes();

          /** Caso não retorne nada na lista **/
          if (this.ELEMENT_DATA.length === 0) {
            /** Retorna alerta ao usuário **/
            Swal.fire({
              title: 'Sua busca não retornou resultados',
              text: 'Nenhum resultado para este filtro',
              icon: 'warning',
              confirmButtonText: 'Ok'
            });

            /** Desativa o loading **/
            this.filterLoadingLampsType = false;
          }

          /** Desativa o loading **/
          this.filterLoadingLampsType = false;

        })
      }), /** Caso de algum erro **/
      error: (error: any) => {
        /** Exibe um alerta **/
        this.errorLibService.errorAlert(error);
      }
    })
  }

  /** Função que limpa os campos de filtro de tipos de liminárias **/
  public resetFilter() {
    this.filterLampsForm.reset();
  }

  /** Função que chama a requisição de tipo de luminárias **/
  public createLampsType(): void {

    if (this.registerLampsForm.valid) {
      this.subscriptions = this.lampsTypeService.createLampsType(
        (this.registerLampsForm.value?.brightnessRegisterForm),
        (this.registerLampsForm.value?.consumptionRegisterForm),
        (this.registerLampsForm.value?.currentRegisterForm),
        (this.registerLampsForm.value?.dimmerTypeRegisterForm),
        this.registerLampsForm.value?.manufacturerRegisterForm,
        this.registerLampsForm.value?.modelRegisterForm,
        (this.registerLampsForm.value?.powerRegisterForm),
        (this.registerLampsForm.value?.powerFactorRegisterForm),
        (this.registerLampsForm.value?.voltageRegisterForm),
      ).subscribe({
        next: (() => {
          this.filterLampsForm.patchValue({
            manufacturerFilterForm: this.registerLampsForm.value?.manufacturerRegisterForm,
            powerFilterForm: this.registerLampsForm.value.powerRegisterForm
          })

          this.filterLampsType(null);

          this.filterLampsForm.patchValue({
            manufacturerFilterForm: null,
            powerFilterForm: null
          });

          Swal.fire({
            title: 'Instalação criada',
            text: 'Instalação criada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          /** Desativa o loading **/
          this.registerLoadingLampsType = false;

          /** Reseta o formulário de criação após a criação **/
          this.registerLampsForm.reset();

        }),
        /* Caso de algum erro **/
        error: (error: any) => {
          /** Exibe no console o error **/
          console.log("createInstallation", error)
          /** Exibe um alerta **/
          this.errorLibService.errorAlert(error);

          this.registerLoadingLampsType = false;
        },
      })
      this.registerLampsForm.reset();
    }
    else {
      Swal.fire({
        title: 'Formulário Incompleto',
        text: 'Preencha todos os campos',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
  }

  /** Função que preenche o formulário de edição com o elemento selecionado **/
  public updateGetter(
    id: string,
    modelUpdateForm: string | null,
    brightnessUpdateForm: number | null,
    consumptionUpdateForm: number | null,
    currentUpdateForm: number | null,
    dimmerTypeUpdateForm: string | null,
    manufacturerUpdateForm: string | null,
    powerUpdateForm: number | null,
    powerFactorUpdateForm: number | null,
    voltageUpdateForm: number | null,
    isViewing: boolean) {
    /** Salva o ID do elemento selecionado na variável lampTypeSelected **/
    this.lampTypeSelected = id;

    this.updateForm.setValue({
      modelUpdateForm,
      manufacturerUpdateForm,
      powerUpdateForm,
      brightnessUpdateForm,
      consumptionUpdateForm,
      currentUpdateForm,
      powerFactorUpdateForm,
      voltageUpdateForm,
      dimmerTypeUpdateForm
    });
    this.editorController = true;

    this.viewController = isViewing;

    if (isViewing === true) {
      this.updateForm.disable();
    } else {
      this.updateForm.enable();
    }
  }

  /** Função que envia os dados do formulário para o update no service **/
  public updateLampType() {
    this.subscriptions = this.lampsTypeService.updateLampsType(
      this.lampTypeSelected,
      this.updateForm.get('brightnessUpdateForm')?.value,
      this.updateForm.get('consumptionUpdateForm')?.value,
      this.updateForm.get('currentUpdateForm')?.value,
      this.updateForm.get('dimmerTypeUpdateForm')?.value,
      this.updateForm.get('manufacturerUpdateForm')?.value,
      this.updateForm.get('modelUpdateForm')?.value,
      this.updateForm.get('powerUpdateForm')?.value,
      this.updateForm.get('powerFactorUpdateForm')?.value,
      this.updateForm.get('voltageUpdateForm')?.value,
    ).subscribe({
      /** Captura a resposta de sucesso **/
      next: ((response: any) => {

        /** Exibe alerta de sucesso **/
        Swal.fire({
          title: 'Instalação atualizada',
          text: 'Instalação atualizada com sucesso',
          icon: 'success',
          confirmButtonText: 'Ok'
        });

        let dimmeringType;

        if (response.data.lampTypeUpdate.lampType.node?.dimmingType === "DALI") {
          dimmeringType = "T_DALI";
        } else {
          dimmeringType = "T_1_10V";
        }

        /** Cria um novo elemento com as informações que foram editadas **/
        this.ELEMENT_DATA = [
          new LampsType(
            response.data.lampTypeUpdate.lampType.node?.id,
            response.data.lampTypeUpdate.lampType.node?.model,
            response.data.lampTypeUpdate.lampType.node?.manufacturer,
            response.data.lampTypeUpdate.lampType.node?.power,
            dimmeringType,
            response.data.lampTypeUpdate.lampType.node?.brightness,
            response.data.lampTypeUpdate.lampType.node?.consumption,
            response.data.lampTypeUpdate.lampType.node?.current,
            response.data.lampTypeUpdate.lampType.node?.powerFactor,
            response.data.lampTypeUpdate.lampType.node?.voltage,
          )
        ];

        /**Atualiza os valores da tabela **/
        this.setDataSourceAttributes();

        /** Fecha o sidebar de edição **/
        this.editorController = false;

      }),
      /** Caso de algum erro **/
      error: (error: any) => {
        /** Exibe um alerta **/
        this.errorLibService.errorAlert(error);
      }
    });
    /** Limpa a lista de dados antiga e atualiza com a nova após o update **/
    this.ELEMENT_DATA = [];

    /** Desativa o painel de edição **/
    this.editorController = false;
    this.lampTypeSelected = "";
  }

  public closeEditionSideBar() {
    // Fecha a tela lateral.
    this.editorController = false;
    // Limpa a variável com o ID do tipo de luminária selecionado.
    this.lampTypeSelected = "";
  }

  /** Código destinado à funções de download de CSV e PDF da página. **/
  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscriptions = this.lampsTypeService.filterLampsType(
        cursor,
        this.manufacturer,
        this.power,
        100
      ).valueChanges.subscribe({
        next: ((response: any) => {

          response.data.lampType.edges.forEach((lampType: any) => {

            let dimmeringType;
            /** Tratamento de dados retornados da API **/
            this.FILE_DATA.push(
              new LampsType(
                lampType.node?.id,
                lampType.node?.model,
                lampType.node?.manufacturer,
                lampType.node?.power,
                lampType.node?.dimmingType,
                lampType.node?.brightness,
                lampType.node?.consumption,
                lampType.node?.current,
                lampType.node?.powerFactor,
                lampType.node?.voltage,
              )
            );
          })

          /** Caso seja a última página a lista está completa **/
          if (!response.data?.lampType?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.lampType?.pageInfo?.endCursor, fileType);
        }),
        error: ((error: any) => {
          this.errorLibService.errorAlert(error);
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  /** Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe. **/
  private convertToCSV(arr: any) {
    /** Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas. **/
    const content = [Object.keys(arr[0])].concat(arr);

    /** Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um. **/
    return content.map((item) => {
      return Object.values(item).join(';');
    }).join('\n');
  }

  /**  Função que realiza o download do CSV. **/
  public downloadCSV() {
    let content: any = [];

    /** Adiciona cada elemento como um objeto na lista de conteúdo. **/
    this.FILE_DATA.forEach((lamp) => {
      content.push({
        'Fabricante': lamp.manufacturer,
        'Modelo': lamp.model,
        'Potência (w)': lamp.power,
        'Tipo de dimerização': lamp.dimmingType,
        'Brilho': lamp.brightness,
        'Consumo': lamp.consumption,
        'Corrente (mA)': lamp.current,
        'Fator de potência': lamp.powerFactor,
        'Tensão (V)': lamp.voltage
      });
    });

    /** Adiciona o conteúdo convertido dentro de uma nova variável. **/
    let data = this.convertToCSV(content);
    /** Transforma o conteúdo para o formato blob para geração do arquivo. **/
    let file = new Blob([data], { type: 'text/csv' });
    /** Cria um endereçamento para o arquivo. **/
    let url = window.URL.createObjectURL(file);
    /** Cria um elemento para a linkagem do arquivo. **/
    let link = document.createElement('a');
    /** Adiciona a url no elemento de linkagem. **/
    link.href = url;
    /** Nomeia o arquivo que será feito o download. **/
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-lampTypes.csv`;
    /** Adiciona o link no documento. **/
    document.body.appendChild(link);
    /** Ativa o evento de click para realizar o download. **/
    link.click();
    /** Remove o link do documento. **/
    document.body.removeChild(link);
    /** Remove o endereço do download. **/
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  // Função para download de dados como PDF
  public downloadPDF() {
    // Colunas que serão adicionadas ao header da tabela no PDF
    const tableLayout = [
      "Fabricante",
      "Modelo",
      "Potência (W)",
      "Tipo de dimerização",
      "Brilho",
      "Consumo",
      "Corrente (mA)",
      "Fator de potência",
      "Tensão (V)"
    ];

    let content: any = []
    // Adiciona os elementos na lista de conteúdo como um array
    this.FILE_DATA.forEach((lamp) => {
      content.push([
        lamp.manufacturer,
        lamp.model,
        lamp.power,
        lamp.dimmingType,
        lamp.brightness,
        lamp.consumption,
        lamp.current,
        lamp.powerFactor,
        lamp.voltage
      ]);
    });
    // Define a orientação da tabela (paisagem nesse caso)
    const orientation = 'l';
    // Cria o objeto da lib jsPDF com a orientação e tamanho da página
    const doc: any = new jsPDF(orientation, "mm", "a4");

    // Cria a tabela com o tableLayout como header e a a lista de conteúdo como corpo da tabela
    doc.autoTable({
      theme: "grid",
      margin: { top: 20 },
      head: [tableLayout],
      body: content
    });

    // Baixa o arquivo para o usuário, definindo o nome com a data de download
    doc.save(`${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-lampTypes.pdf`);

    this.FILE_DATA = [];
    this.isReady = false;
  }
}
