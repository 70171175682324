<div class="component-main-container">
  <!-- Header do componente -->
  <app-page-header parentTitle="{{ 'parameters.parameters-parent-label' | translate }}"
    pageTitle="{{ 'parameters.parameters-title-label' | translate }}"></app-page-header>

  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-12">
      <!-- criar parâmetros-->
      <div class="component-forms-box">
        <app-register-form></app-register-form>

        <div class="form-fields-box" [ngClass]="!isCreateParamOpen ? 'closed' : ''">
          <h2 (click)="openCloseCreateParam()">
            {{ "parameters.form-register-label" | translate }}
          </h2>
          <hr [ngClass]="!isCreateParamOpen ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "parameters.form-register-reference" | translate
                }}</mat-label>
              <input [formControl]="referenceCreateForm" matInput type="text" />
            </mat-form-field>
          </div>
          <div class="form-step">
            <div class="circle-number">2</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "parameters.form-register-parameters" | translate
                }}</mat-label>
              <input [formControl]="paramCreateForm" matInput type="text" />
            </mat-form-field>
          </div>
          <button class="btn btn-success mb-2 w-100" (click)="createParameters()" [disabled]="
              referenceCreateForm.value === null ||
              this.paramCreateForm.value === null
            " *ngIf="!loadingCreateParameters">
            {{ "parameters.form-button-save" | translate }}
          </button>
          <mat-spinner [diameter]="30" *ngIf="loadingCreateParameters"></mat-spinner>
        </div>

        <!-- Filtrar parâmetros-->
        <div class="form-fields-box" [ngClass]="!isFilterOpen ? 'closed' : ''">
          <h2 (click)="openCloseFilterParam()">
            {{ "parameters.form-filter-label" | translate }}
          </h2>
          <hr [ngClass]="!isFilterOpen ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "parameters.form-filter-reference" | translate
                }}</mat-label>
              <input [formControl]="referenceFilterForm" matInput type="text" />
            </mat-form-field>
          </div>
          <div class="form-step">
            <div class="circle-number">2</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "parameters.form-filter-parameters" | translate
                }}</mat-label>
              <input [formControl]="parameterFilterForm" matInput type="text" />
            </mat-form-field>
          </div>

          <button class="btn btn-success " (click)="filterParameters(null)" [disabled]="filter"
            *ngIf="!loadingParameters">
            {{ "parameters.form-button-filter" | translate }}
          </button>

          <mat-spinner [diameter]="30" *ngIf="loadingParameters"></mat-spinner>
        </div>
      </div>
    </div>

    <!-- Tabela parâmetros-->
    <div class="col-lg-7 col-xl-8 col-sm-12">
      <app-table-header [itensNumber]="paginator.pageSize" [totalNumber]="length" [hasCSV]="true" [hasPDF]="true"
        (downloadCSV)="generateFileData(null, 'CSV')" (downloadPDF)="generateFileData(null, 'PDF')">
      </app-table-header>

      <div class="component-table-box">
        <mat-progress-bar mode="indeterminate" *ngIf="loadingParameters"></mat-progress-bar>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef>
              {{ "parameters.column-reference" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.reference }}</td>
          </ng-container>

          <ng-container matColumnDef="parameter">
            <th mat-header-cell *matHeaderCellDef>
              {{ "parameters.column-parameters" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.parameter }}</td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{"parameters.column-actions" | translate}} </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <button (click)="OpenSidebarUpdate(element.id, element.reference, element.parameter, true)"
                [ngClass]="element.view === true ? 'hover' : ' '" class="btn btn-success view">
                <!-- SVG do ícone de edição -->
                <mat-icon class="component-svg-icon" svgIcon="bx-edit"></mat-icon>
              </button>
              <button (click)="OpenSidebarUpdate(element.id, element.reference, element.parameter, false)"
                [ngClass]="element.view === true ? 'hover' : ' '" class="btn btn-success view">
                <mat-icon class="component-svg-icon" svgIcon="bx-show-white"></mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <!--Paginator-->
        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex"
          (page)="pageChanged($event)" aria-label="Selecione as páginas de Parâmetros" class="mat-paginator-sticky">
        </mat-paginator>
      </div>
    </div>
  </div>
  <div class="sidebar-edit" [formGroup]="updateForm" *ngIf="editorController || viewController">
    <mat-icon class="component-close-icon" (click)="closeEditionSidebar()">clear</mat-icon>
    <h2 *ngIf="viewController"> {{"parameters.form-edit" | translate}} </h2>
    <h2 *ngIf="!viewController"> {{"parameters.form-view" | translate}} </h2>
    <hr>

    <mat-form-field appearance="outline">
      <mat-label> {{"parameters.edit-reference" | translate}} </mat-label>
      <input matInput formControlName="reference">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label> {{"parameters.edit-parameter" | translate}} </mat-label>
      <input matInput formControlName="parameter">
    </mat-form-field>

    <button class="btn btn-success" *ngIf="viewController" (click)="updateParameter()"> {{"parameters.edit-button" |
      translate}} </button>

  </div>
</div>