import { Pipe, PipeTransform } from '@angular/core';
/*
 * Recebe um erro do GraphQL e retorna uma mensagem de erro formatada
*/
@Pipe({name: 'grahpqlErrorLib'})
export class GraphQlErrorLib implements PipeTransform {

  transform(error: string | null): string {
    switch(error){
        case "Invalid username or password":
          return "Login e/ou senha incorretos";
        case null:
          return "";
        default:
          return "Erro desconhecido";
      }
  }


}