import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CREATE_FILTER_PARAMETER, FILTER_PARAMETER, UPDATE_PARAMETER } from 'src/app/graphql/parameters.graphql';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {

  constructor(private apollo: Apollo) { }

  //Criar Parametro
  createParameter(reference: any, param: any): any {
    // Envia a mutation
    return this.apollo.mutate({
      mutation: CREATE_FILTER_PARAMETER,
      variables: {
        reference,
        param,
      },
    })
  }

  // configuração da requisição, network-only possibilita fazer a requisição mais de uma vez 
  filterParameter(reference: any, param: any, cursor: string | null, pageSize: number | null): any {
    return this.apollo.watchQuery({
      query: FILTER_PARAMETER,
      fetchPolicy: 'network-only',
      variables: {
        reference,
        param,
        pageSize,
        cursor
      }
    })
  }

  updateParameter(id: string | null, reference: string | null, param: string | null) {
    return this.apollo.mutate({
      mutation: UPDATE_PARAMETER,
      variables: {
        id,
        reference,
        param
      }
    })
  }
}