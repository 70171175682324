import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PageHeaderService } from 'src/shared/services/page-header.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.less']
})
export class PageHeaderComponent {

  @Input() parentTitle: string;
  @Input() pageTitle: string;
  @Input() subRoute: string;

  constructor(private pageHeaderService: PageHeaderService,
    private router: Router) {

  }

  openSidebar(): void {
    this.pageHeaderService.openSideBar.emit(true);
  }

  openSubMenu(): void {
    this.pageHeaderService.openSubMenu.emit(this.parentTitle);
  }

  goToHome(): void {
    this.router.navigate(['/map']);
  }



}
