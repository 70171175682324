import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/shared/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import icons_json from '../shared/icons/icons.json'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  showSideBar = false;
  showPainelFav = false;
  showEditPassword = false;
  lastSelectedLanguage = localStorage.getItem('language');

  constructor(
    private loginService: LoginService,
    private translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    if (
      localStorage.getItem('userId') &&
      localStorage.getItem('userId') != '' &&
      localStorage.getItem('userId') != null &&
      localStorage.getItem('userId') != undefined
    ) {
      this.showSideBar = true;
      this.showPainelFav = true;

      if (
        localStorage.getItem('expiredPassword') &&
        localStorage.getItem('expiredPassword') === 'true'
      ) {
        this.showEditPassword = true;

      }
    }
    this.translate.setDefaultLang('pt');
    if (this.lastSelectedLanguage !== null) {
      translate.use(this.lastSelectedLanguage);
    } else {
      localStorage.setItem('language', 'pt');
      translate.use('pt');
    }

    icons_json.icons.forEach((icon) => {
      const [label, path] = Object.entries(icon)[0];
      this.matIconRegistry.addSvgIcon(
        label,
        this.domSanitizer.bypassSecurityTrustResourceUrl(path)
      );
    });
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.loginService.showSideBar.subscribe((show: boolean) => {
      this.showSideBar = show;
      this.showPainelFav = show;
      this.showEditPassword = show;
    });

    this.loginService.showSideBarUpdateUser.subscribe((show: boolean) => {
      this.showEditPassword = show;
    });
  }
  // esconde a navbar de edicao de senha
  public handleHideChangePassword(): void {
    localStorage.setItem("expiredPassword", 'false')
    this.showEditPassword = false;

  }
  title = 'Smartgreen';
}
