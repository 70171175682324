import { DateTime } from 'luxon'; // Biblioteca do js utilizada para manipular as datas com o timezone correto e converter para o formato ISO.

/** Utilizado para converter dateTime para o fuso horário especificado pelo usuário (Utilizado em campos de data nas tabelas)**/
export const tzConvertUTC2Local = (dateTime: string): string => {

    /** Armazena a string do fuso horário selecionado pelo usuário **/
    const timeZoneSelected: any = localStorage.getItem('selectedTimeZone');

    /** Caso exista alguma informação de data **/
    if (dateTime) {

        /** Cria um objeto do tipo Date **/
        const originalDateTime = new Date(dateTime);

        /** Cria um objeto DateTime a partir da data JS e aplicando o fuso horário a partir do local salvo no localstorage **/
        const currentDateTime = DateTime.fromJSDate(originalDateTime).setZone(timeZoneSelected);

        /** Formatação da data e hora no formato dd/MM/yyyy + (GMT) **/
        const formattedDateTime = currentDateTime.toFormat('dd/MM/yyyy, HH:mm:ss') + ` (${currentDateTime.offsetNameShort})`;

        /** Retorna a data formatada **/
        return formattedDateTime;

    } else {
        return ''
    }
}

/** Utilizado para converter a data inicial das entradas do usuário, converter para o formato ISO e setar o fuso horário de acordo com a preferência do usuário **/
export const tzConvertISOStartDate = (dateTime: string | any) => {

    /** Caso alguma data seja enviada no front no campo de data inicial **/
    if (dateTime) {
        /** Criando um objeto DateTime e aplicando o fuso horário de acordo com o local salvo no LocalStorage **/
        const currentDateTime = DateTime.fromJSDate(dateTime)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) // Define a data inicial p/ 00:00:00.000
            .toISO({ includeOffset: true }) // Converte a data em formato ISO + Offset(GMT).

        /** Retorna a data e hora com o fuso horário aplicado e devidamente formatado **/
        return currentDateTime;
    } else
        return ''
}

/** Utilizado para converter a data final das entradas do usuário, converter para o formato ISO e setar o fuso horário de acordo com a preferência do usuário **/
export const tzConvertISOEndDate = (dateTime: string | any) => {

    /** Caso alguma data seja enviada no front no campo de data final **/
    if (dateTime) {
        /** Criando um objeto DateTime e aplicando o fuso horário de acordo com o local salvo no LocalStorage **/
        const currentDateTime = DateTime.fromJSDate(dateTime)
            .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })  // Define a data final p/ 23:59:59.000
            .toISO({ includeOffset: true }); // Converte a data em formato ISO + Offset(GMT).

        /** Retorna a data e hora com o fuso horário aplicado e devidamente formatado **/
        return currentDateTime;
    } else {
        return ''
    }
}

/** Utilizado para converter dateTime para o fuso horário especificado pelo usuário (Utilizado no mapa)**/
export const tzConvertUTC2LocalMap = (dateTime: string): string => {
    if (dateTime) {

        /** Armazena a string do fuso horário selecionado pelo usuário **/
        const timeZoneSelected: any = localStorage.getItem('selectedTimeZone');

        /** Cria um objeto do tipo date **/
        const originalDateTime = new Date(dateTime);

        /** Realiza a formatação do fuso horário (retornando apenas o fuso horário com o offset de acordo com a seleção do usuário) **/
        const timezoneAbbr = new Date().toLocaleTimeString('en', { timeZone: timeZoneSelected, timeZoneName: 'longOffset' }).split(' ')[2];

        /**Realiza a formatação da data com o timezone selecionado pelo usuário **/
        const convertedDateTime = originalDateTime.toLocaleString(undefined, {
            timeZone: timeZoneSelected,
        }) + ` (${timezoneAbbr})`;

        /** Retorna a data, fuso horário + offset devidamente convertido **/
        return convertedDateTime;
    } else {
        return ''
    }
}