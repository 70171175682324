import { gql } from "apollo-angular"

const FILTER_INSTALLATION_SITE = gql`
query InstallationSite(
	$company: ID
	$reference: String
	$country: String
	$state: String
	$city: String
	$district: String
	$street: String
	$pageSize: Int
	$cursor: String
	$sort_dir: Direction!
	$sort_field: SiteOrderFields!
	$locationCode: String
) {
	installationSite(
		company: $company
		referenceContains: $reference
		country: $country
		state: $state
		city: $city
		districtContains: $district
		street: $street
		locationCode: $locationCode
		pageSize: $pageSize
		cursor: $cursor
		orderBy: { direction: $sort_dir, field: $sort_field }
	) {
		edges {
			node {
				locationCode
				id
				city
				state
				country
				geoposition {
					latitude
					longitude
				}
				reference
				district
				street
				number
				details
				isActive
			}
			cursor
		}
		total
		count
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
	}
}`;

const CREATE_INSTALLATION_SITE = gql`
mutation
	InstallationSiteCreate(
		$latitude    : Float!,
		$longitude   : Float!,
		$company     : ID!,
		$reference   : String!,
		$country     : String,
		$state       : String,
		$city        : String,
		$district    : String,
		$street      : String,
		$number      : String,
		$details     : String,
		$locationCode: String,
		) {
		installationSiteCreate(
			input: {
				latitude    : $latitude,
				longitude   : $longitude,
				company     : $company,
				reference   : $reference,
				country     : $country,
				state       : $state,
				city        : $city,
				district    : $district,
				street      : $street,
				number      : $number,
				details     : $details,
				locationCode: $locationCode,
			}) {
			installationSite {
                id
                city
                state
                country
                geoposition {
                    latitude
                    longitude
                }
                reference
                district
                street
                number
                details
			}
		}
	}`;

/** MUTATION DE UPDATE **/
const UPDATE_INSTALLATION_SITE = gql`
mutation InstallationSiteUpdate(
	$id	          : ID!,
	$latitude     : Float!,
	$longitude    : Float!,
	$reference    : String!,
	$company      : ID!,
	$country      : String!,
	$state        : String!,
	$city         : String!,
	$district     : String!,
	$street       : String!,
	$number       : String!,
	$details      : String!,
	$locationCode : String,
	$isActive	  : Boolean!
	) {
	installationSiteUpdate(
		input: {
			id           : $id,
			latitude     : $latitude,
			longitude    : $longitude,
			reference    : $reference,
			company      : $company,
			country      : $country,
			state        : $state,
			city         : $city,
			district     : $district,
			street       : $street,
			number       : $number,
			details      : $details,
			locationCode : $locationCode,
			isActive     : $isActive
		}) {
		installationSite {
			id
			reference
			country
			state
			city
			district
			street
			number	
			details
			locationCode
			isActive
		}
	}
}`;

/** Mutation que deleta o local de instalação **/
const DELETE_INSTALLATION_SITE = gql`
mutation Delete($id: ID!){
    installationSiteDelete(input:{
        id: $id
}){
        message
    }
}`;

export {
	// Queries
	FILTER_INSTALLATION_SITE,
	// Mutations
	CREATE_INSTALLATION_SITE,
	UPDATE_INSTALLATION_SITE,
	DELETE_INSTALLATION_SITE
}