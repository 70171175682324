import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { catchError, from, mergeMap, Observable, of, take, tap } from 'rxjs';
import { ImportsCSVFileService } from 'src/shared/services/imports-CSV.service';
import Swal from 'sweetalert2';
import { Equipment, EquipmentType } from '../../../assets-imports-csv/imports-csv-installations-model';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-imports-csv-update-equipment',
  templateUrl: './imports-csv-update-equipment.component.html',
  styleUrls: ['./imports-csv-update-equipment.component.less']
})
export class ImportsCsvUpdateEquipmentComponent {

  constructor(
    private importsCSVFileService: ImportsCSVFileService,
    private dialog: MatDialog
  ) {
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
  }

  /** Utilizado para acessar o campo de input dos arquivos **/
  @ViewChild('fileUploadSimple') fileUploadSimple: ElementRef;

  /** Utilizado para acessar o stepper de "duvidas" no html **/
  @ViewChild('stepper') private stepper: MatStepper;

  /** Utilizado para acessar o elemento do modal do passo a passo **/
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;


  /** Armazena a empresa selecionada pelo usuário **/
  public company: string | null;

  /** Armazena o conteúdo do arquivo **/
  private data: string;

  /** Array que armazena os dados do arquivo CSV **/
  private dataToImport: Array<any> = [];

  public logReadyMessage: boolean = false;

  /** Utilizado para manipular o botão de download dos logs **/
  public isExported: boolean = false;

  /** Lista para armazenar os logs **/
  private log: Array<string> = new Array<string>();

  /** Propriedade utilizada para manipular as linhas do arquivo  **/
  public actualLine: number = 0;

  /** Loading de update **/
  public loadingUpdate: boolean = false;

  /** Utilizado para manipular a inativação do botão de importação **/
  public disableImportButton: boolean;

  /** Método utilizado para redirecionar o usuário para um stepper especifico definindo o seu index no html **/
  public goToStep(index: number) {
    this.stepper.selectedIndex = index;
  }

  /** Abre o modal que exibe o passo a passo para a importação **/
  public openDialog(): void {
    this.dialog.open(this.dialogTemplate, {
      data: {}
    });
  }

  /** Método que lê o arquivo CSV **/
  public async getTextFromFile(event: any) {
    const file: File = event.target.files[0];
    let fileContent = await file.text();

    this.data = fileContent;

    /** Reseta todas as mensagens exibidas após o envio de algum arquivo **/
    this.logReadyMessage = false;

    /** Desabilita o botão de exportar logs **/
    this.isExported = false;

    /** Limpa a lista de logs sempre que um novo arquivo é selecionado **/
    this.log = [];

    /** Habilita o botão de importação **/
    this.disableImportButton = false;
  }

  /** Método que realiza a leitura e importação dos arquivos CSV **/
  public async importDataFromCSV() {
    this.dataToImport = this.importsCSVFileService.importDataFromCSV(this.data);

    const dataMappings = this.dataToImport.map(row => ({
      macAddress: row.macAddress,
      serialNumber: row.serialNumber,
      equipmentType: row.equipmentType,
    }));

    /** Para cada linha do arquivo **/
    for (const data of dataMappings) {
      /** Verifica se possui o cabeçalho de mac, numero de série e tipo de equipamento **/
      if (!data.macAddress || !data.serialNumber || !data.equipmentType) {
        await Swal.fire({
          title: 'Cabeçalho ou formato de arquivo incorreto',
          text: 'Por favor, verifique o arquivo e tente novamente',
          icon: 'warning',
          confirmButtonText: 'Ok'
        });
        /** Interrompe a execução do restante da função **/
        return;
      }
    }

    /** Inicia o processo de importação do arquivo utilizando os métodos do rxjs **/
    from(dataMappings).pipe(
      /** Processa as instalações e todos os campos, limitando as solicitações em 5 **/
      mergeMap(({ macAddress, serialNumber, equipmentType }) => this.processEquipmentAndUpdate(macAddress, serialNumber, equipmentType), 5),
      /** Caso ocorra algum erro **/
      catchError(err => {
        /** Adiciona o erro na lista de logs **/
        this.log.push('Erro no processamento geral: ' + err);

        console.log('Erro no processamento geral', err)

        /** Ativa o botão de exportar logs **/
        this.isExported = true;
        /** Desativa o loading **/
        this.loadingUpdate = false;
        /** Botão é ativado **/
        this.disableImportButton = false;
        /** Exibe mensagem de confirmação ao usuário **/
        this.logReadyMessage = true;

        return of(null); // Continua a execução mesmo com erro
      }),

      /** Manipulação do arquivo após cada linha do arquivo ser processada **/
      tap(() => {

        /** Acrescenta +1 a linha atual **/
        this.actualLine += 1;

        if (this.actualLine === dataMappings.length) {
          /** Após 6 segundos **/
          setTimeout(() => {
            /** Ativa o botão de exportar logs **/
            this.isExported = true;
            /** Desativa o loading **/
            this.loadingUpdate = false;
            /** Botão é ativado **/
            this.disableImportButton = true;
            /** Exibe mensagem de confirmação ao usuário **/
            this.logReadyMessage = true;
            /** Reseta o contador das linhas **/
            this.actualLine = 0;
            /** Limpa o input dos arquivos que contém o arquivo anexado 
             já que o método que lê o arquivo não limpa o input caso o nome do arquivo seja igual) **/
            this.fileUploadSimple.nativeElement.value = null;
          }, 6000);
        }
      })
    ).subscribe();
  }

  /** Processa todos os dados e realiza o update dos equipamentos retornando um observable **/
  private processEquipmentAndUpdate(macAddress: string, serialNumber: string, equipmentTypeReference: string): Observable<any> {

    /** Ativa o loading na tela **/
    this.loadingUpdate = true;
    /** Retira a mensagem de confirmação dos logs ao usuário **/
    this.logReadyMessage = false;
    /** Desativa o botão de importação **/
    this.disableImportButton = true;

    /** Validação para caso o campo de tipo de equipamento não esteja preenchido**/
    if (!equipmentTypeReference) {
      this.log.push(`Campo de tipo de equipamento inválido`);
      return of(null);
    }

    /** Variável que armazena as informações dos tipos de equipamentos **/
    let equipmentType: EquipmentType;

    /** Variável que armazena as informações dos equipamentos  **/
    let equipment: Equipment;

    /** Realiza a divisão da string por espaços vazios **/
    let reference = equipmentTypeReference.split(" ");

    /** Acessa o primeiro array que foi dividido onde armazenará a "referência" do tipo de equipamento **/
    let referenceEquipmentType = reference[0];

    /** Variável utilizada para realizar a separação de strings por ' . ' **/
    let separatorString = reference[1].split(".")

    /** Variável que contem a primeira  do array, onde armazenará o campo "major" do tipo de equipamento **/
    let major = separatorString[0];

    /** Variável que contem a segunda string do array, onde armazenará o campo "minor" do tipo de equipamento **/
    let minor = separatorString[1];

    /** Variável que contem a terceira string do array, onde armazenará o campo "revision" do tipo de equipamento **/
    let revision = separatorString[2];

    /** Realiza o filtro de equipamentos pelo endereço mac **/
    return this.importsCSVFileService.getEquipmentsByMacAddress(macAddress).valueChanges
      .pipe(
        take(1),
        mergeMap((res: any) => {

          /** Filtrando os macs do equipamento pelo mac exato obtido no arquivo **/
          const equipments: Equipment[] = res.data.equipment.edges.map((edge: any) => edge.node)
            .filter((equipment: Equipment) => equipment.macAddress === macAddress)

          /** Caso o mac informado no arquivo não seja válido **/
          if (equipments.length === 0) {

            /** Adiciona mensagem de falha na lista de logs **/
            this.log.push(`Endereço MAC ${macAddress} não encontrado.`);
            /** Encerrando esse processo nos endereço MAC **/
            return of(null);
          }

          /** Atribui ao equipamento o mac válido **/
          equipment = equipments[0];

          /** Realiza o filtro de tipo de equipamento **/
          return this.importsCSVFileService.getEquipmentTypeByReference(referenceEquipmentType, major, minor, revision).valueChanges
            .pipe(
              take(1),
              mergeMap((res: any) => {

                const equipmentsType: EquipmentType[] = res.data.equipmentType.edges.map((edge: any) => edge.node)
                  /** Filtro realizado para buscar os dados exatos do tipo de equipamento **/
                  .filter((equipmentType: EquipmentType) =>
                    equipmentType?.reference === referenceEquipmentType,
                    equipmentType?.major === major,
                    equipmentType?.minor === minor,
                    equipmentType?.revision === revision
                  )

                /** Caso o tipo de equipamento existente no arquivo não seja encontrado na lista de tipos de equipamentos **/
                if (equipmentsType.length === 0) {

                  /** Adiciona mensagem de falha na lista de logs **/
                  this.log.push(`Tipo de equipamento ${equipmentTypeReference} não encontrado.`);

                  /** Encerrando esse processo nos tipos de equipamentos */
                  return of(null);
                }

                /** Atribui o tipo de equipamento em um tipo de equipamento válido **/
                equipmentType = equipmentsType[0]

                /** Realiza a atualização dos equipamentos **/
                return this.importsCSVFileService.updateEquipment(
                  equipment.id,
                  this.company,
                  macAddress,
                  serialNumber,
                  equipmentType.id
                ).pipe(
                  tap(() => {
                    /** Adiciona mensagem de sucesso na lista de logs. **/
                    this.log.push(`Equipamento ${serialNumber} atualizado com sucesso!`);
                  }),
                  catchError(err => {
                    /** Exibe o erro no console **/
                    console.error(`Erro ao atualizar o equipamento ${serialNumber}`, err);

                    /** Adiciona o erro na lista de logs **/
                    this.log.push(`Erro ao atualizar o equipamento ${serialNumber}: ${err}`);

                    /** Continua o processo mesmo com erro **/
                    return of(null);
                  })
                )
              })
            )
        })
      )
  }

  /** Método utilizado para exportar os Logs de sucesso ou falha da importação dos arquivos **/
  public exportLogs() {
    const logText = this.log.join("\n");
    const blob = new Blob([logText], { type: 'text/plain' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}_Update_Equipments.log`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  /** Método utilizado para gerar o conteúdo do CSV a partir dos cabeçalhos **/
  private generateCsvContent(headers: string[]): string {
    return headers.join(';') + '\n'; // Agrupa os cabeçalhos separando por ponto e vírgula e adiciona uma nova linha no final
  }

  /** Método que realiza o download de um arquivo csv que contém o cabeçalho com o modelo para os usuários **/
  downloadCsvFile(): void {
    const headers = ['equipmentType', 'macAddress', 'serialNumber']; //Define o cabeçalho do arquivo csv
    const fileName = 'model-import-update-equipment.csv'; //Nome do arquivo
    const csvContent = this.generateCsvContent(headers); // Gera o conteúdo do CSV com base nos cabeçalhos
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }); // Cria um objeto Blob com o conteúdo do CSV e define o tipo MIME
    const link = document.createElement('a');  // Cria um elemento <a> dinamicamente
    const url = URL.createObjectURL(blob);  // Cria uma URL para o Blob
    link.setAttribute('href', url);  // Define o atributo href do link com a URL do Blob
    link.setAttribute('download', fileName); // Define o atributo download do link com o nome do arquivo
    link.style.visibility = 'hidden'; // Define a visibilidade do link como 'hidden' para que ele não apareça na página
    document.body.appendChild(link); // Adiciona o link ao corpo do documento
    link.click(); // Simula um clique no link para iniciar o download do arquivo
    document.body.removeChild(link); // Remove o link do documento após o clique
  }
}
