import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild
} from '@angular/core';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
import { AlertsService } from 'src/shared/services/alerts.service';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Alert, EventModel, GeneratedEvents, ServiceOrder, ServiceOrderInformation } from '../../models/alerts.model';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-alert-edit',
  templateUrl: './alert-edit.component.html',
  styleUrls: ['./alert-edit.component.less']
})
export class AlertEditComponent extends Unsubscriber implements OnChanges, OnInit {

  constructor(private alertsService: AlertsService, private errorLibSerivce: ErrorLibService, private formBuilder: FormBuilder,) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editedAlertId']) {
      this.getEditedAlertInformation(changes['editedAlertId'].currentValue);
    }
  }

  ngOnInit(): void {
    this.alertsService.getServiceOrder();
    this.loadingSelectedOsInformation = true;
    this.subscriptions = this.alertsService.getServiceOrderOnAlertInformation(this.editedAlertId)
      .valueChanges
      .subscribe({
        next: (res: any) => {
          console.log(res)
          if (res.data.serviceOrder.count > 0) {
            this.alertHasServiceOrder = true;
            let resObject = res.data.serviceOrder.edges[0].node;
            this.selectedOsInformation = new ServiceOrderInformation(
              resObject.id,
              resObject.creationDate,
              resObject.operator.username,
              resObject.installation.reference,
              resObject.problem.reference,
              resObject.serviceIdentifier,
              resObject.status,
            )
          }
          this.loadingSelectedOsInformation = false;
          console.log(this.alertHasServiceOrder);

        },
        error: (err: any) => {
          this.errorLibSerivce.errorAlert(err);
          this.loadingSelectedOsInformation = false;
        }
      })

  }

  @Input() editedAlertId: string;
  @Output() editCancelation = new EventEmitter<boolean>();
  @Output() editAlert = new EventEmitter<boolean>();

  public editedAlert: Alert | null = null;
  public editedInstallation: string = "";
  public loadingAlertData$ = this.alertsService.loadingAlertData$;
  public serviceOrdersLoading$ = this.alertsService.serviceOrdersLoading$;
  public serviceOrders$ = this.alertsService.serviceOrders$;
  public selectedOsInformation: ServiceOrderInformation | null = null;
  public loadingSelectedOsInformation: boolean = false;
  public alertHasServiceOrder: boolean = false;
  public generatedEvents: GeneratedEvents;
  // generatedEventsMap = new Map();

  // Armazena os dados do formulário de Criação de Ordem de Serviço
  public serviceOrderForm: FormGroup = this.formBuilder.group({
    priority: [null, [Validators.required]],
    status: [null, [Validators.required]],
    problem_reference: [null],
    problem_details: [null],
    problem_address: [null],
    complainant_name: [null],
    complainant_landlineNumber: [null],
    complainant_phoneNumber: [null],
    complainant_cpf: [null],
    solution_closure_date: [null],
    solution_details: [null],
    solution_address: [null],
  });

  public createServiceOrder(): void {
    if (this.serviceOrderForm.valid) {
      this.subscriptions = this.alertsService.createServiceOrder(
        this.serviceOrderForm.get('priority')?.value,
        this.serviceOrderForm.get('status')?.value,
        this.editedInstallation,
        this.editedAlertId,
        this.serviceOrderForm.get('problem_reference')?.value,
        this.serviceOrderForm.get('problem_details')?.value,
        this.serviceOrderForm.get('problem_address')?.value,
        this.serviceOrderForm.get('complainant_name')?.value,
        this.serviceOrderForm.get('complainant_landlineNumber')?.value,
        this.serviceOrderForm.get('complainant_phoneNumber')?.value,
        this.serviceOrderForm.get('complainant_cpf')?.value,
        this.serviceOrderForm.get('solution_closure_date')?.value,
        this.serviceOrderForm.get('solution_details')?.value,
        this.serviceOrderForm.get('solution_address')?.value
      )
        .subscribe({
          next: (res) => {
            console.log(res)
            this.alertHasServiceOrder = true;
            Swal.fire({
              title: 'Sucesso!',
              text: 'OS criada com sucesso',
              icon: 'success',
              confirmButtonText: 'Ok'
            });
          },
          error: (err: any) => {
            this.errorLibSerivce.errorAlert(err);
          }
        })
    } else {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o formulário.',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }

  public filter: boolean = true;

  public filterBox(): void {
    this.filter = !this.filter;
  }

  public cancelEdit(): void {
    this.editCancelation.emit(true);
  }

  public getEditedAlertInformation(editedAlertId: string): void {
    this.subscriptions = this.alertsService.getAlertById(editedAlertId)
      .subscribe({
        next: (res: any) => {
          let eventList: EventModel[] = [];
          console.log(res);
          this.editedInstallation = res.data.node.installation.id
          this.editedAlert = new Alert(
            res.data.node.alertDateTime,
            res.data.node.installation.reference,
            res.data.node.status,
            res.data.node.alertRule?.name
          )
          res.data.node.generatedEvents.forEach((event: any) => {
            Object.keys(event.properties).forEach((eventProp: any, i) => {
              eventList.push(new EventModel(
                eventProp,
                Object.values(event.properties)[i]
              ))
            })
          })

          if (res.data.node.alertRule) {
            this.generatedEvents = new GeneratedEvents(
              res.data.node.alertRule.name,
              eventList
            )
          }

        },
        error: (err: any) => {
          this.errorLibSerivce.errorAlert(err);
        }
      })
  }

  public setSelectedOsInformation(selectedSo: ServiceOrder): void {
    this.selectedOsInformation = null;
    this.loadingSelectedOsInformation = true;
    this.subscriptions = this.alertsService.getServiceOrderInformation(selectedSo.serviceIdentifier)
      .valueChanges
      .subscribe({
        next: (res: any) => {
          let resObject = res.data.serviceOrder.edges[0].node;
          this.selectedOsInformation = new ServiceOrderInformation(
            resObject.id,
            resObject.creationDate,
            resObject.operator.username,
            resObject.installation.reference,
            resObject.problem.reference,
            resObject.serviceIdentifier,
            resObject.status,
          )
          this.loadingSelectedOsInformation = false;
        }
      })
  }

  @ViewChild('matRef') matRef: MatSelect;

  public clearOsSelection(): void {
    this.matRef.options.forEach((data: MatOption) => {
      if (data.selected) {
        data.deselect();
      }
    })
    this.selectedOsInformation = null;
  }

  public addServiceOrderToAlert(): void {
    if (this.selectedOsInformation) {
      this.subscriptions = this.alertsService.addServiceOrderToAlert(this.editedAlertId, this.selectedOsInformation.id)
        .subscribe({
          next: () => {
            this.alertHasServiceOrder = true;
            Swal.fire({
              title: 'Sucesso!',
              text: 'OS adicionada com sucesso',
              icon: 'success',
              confirmButtonText: 'Ok'
            });
          },
          error: (err: any) => {
            console.log(err)
            this.errorLibSerivce.errorAlert(err);
          }
        })
    } else {
      this.errorLibSerivce.errorAlert('Nenhuma OS selecionada');
    }
  }

  public changeAlertStatus(status: string): void {
    this.subscriptions = this.alertsService.changeAlertStatus(this.editedAlertId, status)
      .subscribe({
        next: (res: any) => {
          if (res.data.alertStatusBulkUpdate && res.data.alertStatusBulkUpdate.errors && res.data.alertStatusBulkUpdate.errors.length > 0) {
            this.errorLibSerivce.errorAlert(res.data.alertStatusBulkUpdate.errors[0].cause);
            return;
          }
          this.editAlert.emit(true);
          Swal.fire({
            title: 'Sucesso!',
            text: 'Status alterado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          }).then(() => {
            this.getEditedAlertInformation(this.editedAlertId);
          })
        }
      })
  }
}
