<!-- container do mapa -->
<div class="component-main-container">
  <div class="full" id="map" #map></div>
</div>

<!-- Barra de instalações selecionadas -->
<app-painel-fav *ngIf="selectedInstallationsView" [options]="selectedInstallations"
  [selectedOption]="selectedOptions"></app-painel-fav>

<!-- Wheel p/ seleção -->
<div class="wheel" *ngIf="wheelView">
  <div class="arc" style="--i:1;">
    <div class="svg"></div>
  </div>
  <div class="arc" style="--i:2;" title="{{ 'map.wheel-command-label' | translate }}"
    (click)="commandSelectInstallation()">
    <div class="svg"></div>
  </div>
  <div class="arc" style="--i:3;" title="{{ 'map.wheel-report-label' | translate }}"
    (click)="reportSelectInstallation()">
    <div class="svg"></div>
  </div>
  <div class="arc" style="--i:4;" title="{{ 'map.wheel-installationList-label' | translate }}"
    (click)="selectInstallation()">
    <div class="svg"></div>
  </div>
  <!-- Redireciona para a tela de logs -->
  <div class="arc" style="--i:5;" title="Logs" (click)="logsSelectInstallation()">
    <div class="svg"></div>
  </div>
  <div class="arc" style="--i:6;">
    <div class="svg"></div>
  </div>
  <div class="arc" style="--i:7;">
    <div class="svg"></div>
  </div>
  <div class="arc" style="--i:8;">
    <div class="svg"></div>
  </div>
</div>

<button title="{{ 'map.download-csv-label' | translate}}" class="btn btn-success downloadCSV"
  *ngIf="!filterController && lastQuery != 'is_active=true'" (click)="downloadCSV()">
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-cloud-arrow-down-fill"
    viewBox="0 0 16 16">
    <path
      d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
  </svg>
</button>

<!-- Botão para alternar o tema do mapa entre modo claro e modo escuro -->
<button (click)="changeMapTheme()" class="btn btn-success view" id="modeDark">
  <mat-icon class="component-svg-icon" svgIcon="bx-theme"></mat-icon>
</button>

<button (click)="openDemonstrationMode()" class="btn btn-success view" id="demoMode">
  <mat-icon class="component-svg-icon" svgIcon="bx-play"></mat-icon>
</button>


<!-- Botão p/ filtro -->
<button title="{{ 'map.filter-label' | translate}}" class="btn btn-success search" *ngIf="!filterController"
  (click)="openSidebarEdit()"><mat-icon svgIcon="bx-search-white"
    style="font-size: 0px; margin-top: 5px;"></mat-icon></button>

<!-- Sidebar de filtro -->
<div class="sidebar-edit" *ngIf="filterController" [formGroup]="filterForm">

  <div class="info-icon">
    <app-helper></app-helper>
  </div>
  <mat-icon (click)="closeEditionSideBar()">clear</mat-icon>
  <h2> {{ 'map.filter-label' | translate}} </h2>
  <hr>

  <p class="select-filter" *ngIf="lastQuery != 'is_active=true'" (click)="selectLastQuery()">{{
    'map.select-last-filter' | translate }}</p>

  <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
    <!-- filtro por elementos -->
    <mat-tab label="{{ 'map.filter-fields' | translate}}">
      <hr>
      <mat-form-field appearance="outline">
        <mat-label> {{ 'map.reference-label' | translate}} </mat-label>
        <input matInput formControlName="reference">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'map.serial-number-label' | translate}} </mat-label>
        <input matInput formControlName="serialNumber">
      </mat-form-field>

      <!-------------------------------- Input do filtro por status(temporareamente removido)------------------------------- -->
      <mat-form-field appearance="outline">
        <mat-label> {{ 'map.status-label' | translate}} </mat-label>
        <mat-select formControlName="status">

          <mat-option apperance="outline" [value]="null">
            {{ 'map.none' | translate}}
          </mat-option>

          <mat-option apperance="outline" [value]="'on'">
            {{ 'map.on' | translate}}
          </mat-option>

          <mat-option apperance="outline" [value]="'off'">
            {{ 'map.off' | translate}}
          </mat-option>

          <mat-option apperance="outline" [value]="'6hours'">
            {{ 'map.6h-no-transmission' | translate}}
          </mat-option>

          <mat-option apperance="outline" [value]="'24hours'">
            {{ 'map.24h-no-transmission' | translate}}
          </mat-option>

          <mat-option apperance="outline" [value]="'never'">
            {{ 'map.never-transmitted' | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> {{ 'map.magnetic-key' | translate}} </mat-label>
        <mat-select formControlName="magneticKey">

          <mat-option apperance="outline" [value]="null">
            {{ 'map.none' | translate}}
          </mat-option>

          <mat-option apperance="outline" [value]="true">
            {{ 'map.maybe' | translate}}
          </mat-option>

          <mat-option apperance="outline" [value]="false">
            {{ 'map.it-has-not' | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> {{ 'map.subdivision' | translate }} </mat-label>
        <mat-select multiple formControlName="division">
          <mat-option *ngIf="!allDivisions.length" disabled>{{ 'map.no-subdivision' | translate
            }}</mat-option>
          <mat-option *ngFor="let division of allDivisions" apperance="outline" [value]="division.id">
            {{division.reference}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> {{ 'map.gateway' | translate }} </mat-label>
        <mat-select multiple formControlName="gateway" panelClass="gateway-select-panel">
          <mat-form-field [style.width.%]="100" appearance="outline">
            <mat-label>Filtrar</mat-label>
            <input matInput #searchGateways class="input-filter" focused="true"
              (keyup)="filterGateways(searchGateways.value)" (keydown)="$event.stopPropagation()">
          </mat-form-field>
          <mat-option *ngIf="!allGateways.length" disabled>{{ 'map.no-gateway' | translate }}</mat-option>
          <mat-option *ngFor="let gateway of filteredGateways" apperance="outline"
            [value]="gateway.id">{{gateway.reference}}</mat-option>
        </mat-select>
      </mat-form-field>


      <div class="buttons">
        <button class="btn btn-success filter" *ngIf="!filterLoading" (click)="filterByElements()">{{
          'map.filter-label' | translate }}</button>
        <button class="btn btn-secondary filter" *ngIf="!filterLoading" (click)="resetGateways()">{{
          'map.reset-label' | translate }}</button>

      </div>
      <div class="spinner" *ngIf="filterLoading">
        <div class="custom-loader"></div>
        <p *ngIf="markers.length > 0 && totalInstallations > 0">{{ ((markers.length / totalInstallations) *
          100).toFixed(0) }}%</p>
        <p *ngIf="markers.length === 0 && totalInstallations >= 0">0%</p>
      </div>

    </mat-tab>
    <!-- filtro por formas -->
    <mat-tab label="{{ 'map.area-label' | translate }}">
      <hr>
      <br>
      <mat-slide-toggle class="example-margin" color="accent" [formControl]="polygonSlideToggle"
        (change)="openPolygon()">
        {{ 'map.polygon' | translate }}
      </mat-slide-toggle>
      <br><br>
      <hr>
      <br>
      <div class="addres-label">
        <mat-label>Endereço</mat-label>
      </div>
      <br>
      <mat-form-field appearance="outline">
        <mat-label> {{ 'map.street' | translate }} </mat-label>
        <input matInput formControlName="street">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label> {{ 'map.district' | translate }} </mat-label>
        <input matInput formControlName="district">
      </mat-form-field>
      <br>
      <!-- <mat-slide-toggle
                class="example-margin"
                color="accent">
              Circulo
            </mat-slide-toggle> -->
      <br>

      <button class="btn btn-success filter" id="btn-filter" (click)="filterBypolygons()" *ngIf="!filterFormsLoading">{{
        'map.filter-label' | translate }}</button>

      <div class="spinner" *ngIf="filterFormsLoading">
        <div class="custom-loader"></div>
        <p *ngIf="markers.length > 0 && totalInstallations > 0">{{ ((markers.length / totalInstallations) *
          100).toFixed(0) }}%</p>
        <p *ngIf="markers.length === 0 && totalInstallations >= 0">0%</p>
      </div>
    </mat-tab>
    <!-- favoritos -->
    <mat-tab label="{{ 'map.favorites-label' | translate }}" *ngIf="favoriteList?.favorites">
      <hr>

      <div *ngIf="!viewFavorite">
        <div class="table" *ngIf="favoriteList?.favorites[companyId!]">
          <table>
            <tbody>
              <tr>
                <td><strong>{{ 'map.reference-label' | translate }}</strong></td>
                <td></td>
                <td><strong>{{ 'map.actions-label' | translate }}</strong></td>
                <td></td>
              </tr>

              <tr *ngFor="let favorite of favoriteList?.favorites[companyId!]; let index = index">
                <td>{{ favorite.reference }}</td>
                <td> <img title="{{ 'map.filter-label' | translate }}" src="../../../../assets/imgs/search.svg"
                    alt="search" (click)="filterByFavorite(favorite)"> </td>
                <td> <img title="{{ 'map.view-label' | translate }}" src="../../../../assets/imgs/eye.svg" alt="view"
                    (click)="viewOnFavorite(favorite)"> </td>
                <td> <img title="{{ 'map.trash-label' | translate }}" src="../../../../assets/imgs/trash.svg"
                    alt="trash" (click)="removeFavorite(index)"> </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div *ngIf="viewFavorite">

        <mat-form-field appearance="outline">
          <mat-label> {{ 'map.installations-label' | translate }} </mat-label>
          <mat-select>

            <mat-option apperance="outline" [value]="null" disabled
              *ngFor="let installation of favoriteSelected.installations">
              {{ installation.reference }}
            </mat-option>

          </mat-select>
        </mat-form-field>

        <img class="arrow-back" src="../../../../assets/imgs/arrow_back.svg" alt="arrow_back"
          (click)="viewOffFavorite()">

      </div>

      <p class="no-favorites" *ngIf="!favoriteList?.favorites[companyId!]">{{ 'map.there-is-no-favorites' |
        translate }}</p>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- Sidebar de mais informações -->
<div class="sidebar-edit" *ngIf="moreInfoController">
  <mat-icon (click)="closeMoreInformation()">clear</mat-icon>

  <mat-button-toggle-group [(ngModel)]="choiceOptionsToView">
    <!-- Detalhes -->
    <mat-button-toggle value="details">{{ 'map.details-label' | translate }}</mat-button-toggle>
    <!-- <mat-button-toggle value="consumption">CONSUMO</mat-button-toggle> -->
    <mat-button-toggle value="alerts">{{ 'map.alerts-label' | translate }}</mat-button-toggle>
  </mat-button-toggle-group>

  <div class="details" *ngIf="choiceOptionsToView === 'details'">

    <div class="table">
      <table>
        <tbody>
          <tr>
            <td><strong>{{ 'map.serial-number-label' | translate }}</strong></td>
            <td>{{ serialNumber }} <mat-spinner [diameter]="20" *ngIf="detailConsuptionLoading"></mat-spinner></td>
          </tr>
          <tr>
            <td><strong>{{ 'map.address-label' | translate }}</strong></td>
            <td>{{ address }} <mat-spinner [diameter]="20" *ngIf="detailConsuptionLoading"></mat-spinner>
            </td>
          </tr>
          <tr>
            <td><strong>{{ 'map.gateway-label' | translate }}</strong></td>
            <td>{{ gateway }}</td>
          </tr>
          <tr>
            <td><strong>{{ 'map.lat-label' | translate }}</strong></td>
            <td>{{ lat }}</td>
          </tr>
          <tr>
            <td><strong>{{ 'map.long-label' | translate }}</strong></td>
            <td>{{ long }}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

  <!-- Alertas -->
  <div class="alerts" *ngIf="choiceOptionsToView === 'alerts'">

    <div class="table">
      <table>
        <thead>
          <tr>
            <th>{{ 'map.reference-label' | translate }}</th>
            <th>{{ 'map.date-label' | translate }}</th>
            <th>{{ 'map.status-label' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let alert of alerts">
            <td><strong>{{alert.reference}}</strong></td>
            <td matTooltip="{{ 'time-zone-information-label' | translate }}" matTooltipPosition="above">
              {{
              alert.timestamp }}</td>
            <td>{{ alert.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Sidebar de mais informações -->
<div class="sidebar-edit sidebar-demonstration" *ngIf="demonstrationController && !isRunning">
  <mat-icon (click)="closeDemonstrationMode()">clear</mat-icon>
  <h2>{{ 'map.demonstration-mode' | translate }}</h2>
  <br>
  <mat-form-field appearance="outline" [formGroup]="demonstrationForm">
    <mat-label> {{ 'map.filter-delay' | translate }} <strong>[Min 5]</strong></mat-label>
    <input type="number" matInput min="5" formControlName="delay">
  </mat-form-field>

  <div class="buttons">
    <button class="btn btn-success filter" *ngIf="!isRunning" [disabled]="!demonstrationForm.valid"
      (click)="startDemonstration()"> {{
      'map.start-demonstration' | translate }} </button>
  </div>
</div>

<div class="sidebar-running" *ngIf="demonstrationController && isRunning">
  <mat-icon (click)="closeDemonstrationMode()">clear</mat-icon>
  <div class="gateway-info">
    <label>{{ 'map.gateway-reference' | translate }}</label>
    <h2>{{ gatewayName }}</h2>
  </div>
  <div class="table" *ngIf="elementIsFound">
    <table>
      <tbody>
        <tr>
          <td><strong>{{ 'map.serial-number-label' | translate }}</strong></td>
          <td>{{ selectedGateway.serialNumber }}</td>
        </tr>
        <tr>
          <td><strong>{{ 'map.cpu-usage' | translate }}</strong></td>
          <td>{{ selectedGateway.cpuPercent }}%</td>
        </tr>
        <tr>
          <td><strong>{{ 'map.current-temperature' | translate }}</strong></td>
          <td>{{ selectedGateway.temperatureCurrent }}Cº</td>
        </tr>
        <tr>
          <td><strong>{{ 'map.last-reboot' | translate }}</strong></td>
          <td>{{ selectedGateway.lastReboot }}</td>
        </tr>
        <tr>
          <td><strong>{{ 'map.disk-usage' | translate }}</strong></td>
          <td>{{ selectedGateway.diskPercent }}%</td>
        </tr>
        <tr>
          <td><strong>{{ 'map.equipment-count' | translate }}</strong></td>
          <td><label>{{ equipmentCount }}</label>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <button class="btn btn-secondary" *ngIf="isRunning" (click)="stopDemonstration()"> {{
    'map.stop-demonstration' | translate }}
  </button>
</div>