import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageHeaderService {

  openSideBar = new EventEmitter<boolean>();
  openSubMenu = new EventEmitter<string>();

  constructor() { }
}
