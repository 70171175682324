export class InstallationSiteLog {
  constructor(
    public username: string | null,
    public operation: string | null,
    public logDatetime: string,
    public latitude: number | null,
    public longitude: number | null,
    public reference: string | null,
    public isActive: any,
    public company: any
  ) { }
}


export class PaginationInfo {
  constructor(
    public count: number,
    public total: number,
    public hasNextPage: boolean,
    public hasPreviousPage: boolean,
    public startCursor: string | null,
    public endCursor: string | null
  ) { }
}
