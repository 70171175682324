export class Hardware {
    constructor(
        public id: string,
        public name: string,
        public major: number,
        public minor: number,
        public description: string | null,
        public revision: string
    ) { }
}
export class Firmwares {
    constructor(
        public id: string,
        public name: string,
        public reference: string | null,
        public version: string | null,
        public description: string | null,
        public realeaseDate: Date | null,
    ) { }
}
export class PaginationInfo {
    constructor(
        public count: number,
        public total: number,
        public hasNextPage: boolean,
        public hasPreviousPage: boolean,
        public startCursor: string | null,
        public endCursor: string | null
    ) { }
}
