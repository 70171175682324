<div class="component-main-container">
  <app-page-header parentTitle="{{ 'circuit-box.parentTitle' | translate }}"
    pageTitle="{{ 'circuit-box.pageTitle'| translate }}">
  </app-page-header>

  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-12">
      <div class="component-forms-box">
        <app-register-form></app-register-form>

        <div class="form-fields-box" [ngClass]="!register ? 'closed' : ''" [formGroup]="registerCircuitBoxForm">
          <h2 (click)="Register()">
            {{ "circuit-box.form-register-label" | translate }}
          </h2>
          <hr [ngClass]="!register ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>{{ "circuit-box.form-reference" | translate }}</mat-label>
              <input matInput formControlName="reference" />
            </mat-form-field>
          </div>

          <!-- Campo para realizar a busca dos equipamentos tipo IPC -->
          <div class="form-step" *ngIf="true">
            <div class="circle-number"> 2 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{ "circuit-box.form-ipc" | translate }} </mat-label>
              <input matInput formControlName="searchIPC">
              <mat-icon class="component-search-icon"
                (click)="filterEquipmentBySerialNumber('searchIPC','ipc', 'register')" svgIcon="bx-search"></mat-icon>
            </mat-form-field>
          </div>

          <!-- Loading -->
          <mat-progress-bar class="mb-2" *ngIf="typeFilter == 'register' && loadingEquipmentsIPC"
            mode="indeterminate"></mat-progress-bar>

          <div class="form-step" *ngIf="equipmentTypeIPC.length > 0">
            <div class="circle-number">2.1</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "circuit-box.form-ipc" | translate }}</mat-label>
              <mat-select formControlName="ipc" #value>
                <mat-option appearance="outline" *ngFor="let item of equipmentTypeIPC" [value]="item.id">
                  {{item.reference}} -
                  {{item.major}}.{{item.minor}}.{{item.revision}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">
              3
            </div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "circuit-box.form-gateway" | translate }}</mat-label>
              <mat-select formControlName="gateway">
                <mat-option appearance="outline" *ngFor="let item of gatewayInstallationList" [value]="item.id">{{
                  item.reference }}</mat-option>
                <mat-option *ngIf="gatewayInstallationList.length < 0"><mat-spinner
                    [diameter]="30"></mat-spinner></mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Campo para realizar a busca de equipamentos tipo Corte -->
          <div class="form-step" *ngIf="true">
            <div class="circle-number"> 4 </div>
            <mat-form-field appearance="outline">
              <mat-label> {{ "circuit-box.form-consumption" | translate }} </mat-label>
              <input matInput formControlName="searchCorte">
              <mat-icon class="component-search-icon"
                (click)="filterEquipmentBySerialNumber('searchCorte','corte', 'register')"
                svgIcon="bx-search"></mat-icon>
            </mat-form-field>
          </div>

          <!-- Loading -->
          <mat-progress-bar class="mb-2" *ngIf="typeFilter == 'register' && loadingEquipmentsCORTE"
            mode="indeterminate"></mat-progress-bar>


          <div class="form-step" *ngIf="equipmentTypeCorte.length > 0">
            <div class="circle-number">4.1</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "circuit-box.form-consumption" | translate }}</mat-label>
              <mat-select formControlName="consumption" #value>
                <mat-option appearance="outline" *ngFor="let item of equipmentTypeCorte" [value]="item.id">
                  {{item.reference}} -
                  {{item.major}}.{{item.minor}}.{{item.revision}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <button title="{{ 'circuit-box.form-save-button' | translate }}" class="btn btn-success"
            [disabled]="registerCircuitBoxForm.invalid" (click)="registerCircuitBox()">
            {{ "circuit-box.form-save-button" | translate }}
          </button>
        </div>

        <div class="form-fields-box" [ngClass]="!filter ? 'closed' : ''" [formGroup]="filterCircuitBoxForm">
          <h2 (click)="Filter()">
            {{ "circuit-box.form-filter-label" | translate }}
          </h2>
          <hr [ngClass]="!register ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>{{ "circuit-box.form-reference" | translate }}</mat-label>
              <input matInput formControlName="reference" />
            </mat-form-field>
          </div>

          <button title="{{ 'companies.form-save-button' | translate }}" class="btn btn-success"
            [disabled]="filterCircuitBoxForm.invalid" (click)="filterCircuitBox()" *ngIf="!filterLoading">
            Filtrar
          </button>
          <mat-spinner [diameter]="30" *ngIf="filterLoading"></mat-spinner>
        </div>
      </div>
    </div>

    <!-- Sessão tabela -->
    <div class="col-lg-7 col-xl-8 col-sm-12" *ngIf="paginationProperties.total">
      <app-table-header [itensNumber]="paginationProperties.count" [totalNumber]="paginationProperties.total"
        [hasCSV]="true" [hasPDF]="true" (downloadCSV)="generateFileData(null, 'CSV')" [loadingData$]="fileLoading"
        (downloadPDF)="generateFileData(null, 'PDF')"></app-table-header>

      <div class="component-table-box">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

          <!-- Name Column -->
          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef>
              {{ "circuit-box.form-reference" | translate}}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.reference}}</td>
          </ng-container>

          <!-- IPC Column -->
          <ng-container matColumnDef="sgIpc">
            <th mat-header-cell *matHeaderCellDef>IPC (Serial)</th>
            <td mat-cell *matCellDef="let element">{{ element.sgIpcSerialNumber }}</td>
          </ng-container>

          <!-- Gateway Column -->
          <ng-container matColumnDef="sgGateway">
            <th mat-header-cell *matHeaderCellDef>Gateway(Referência)</th>
            <td mat-cell *matCellDef="let element">{{ element.sgGatewayReference }}</td>
          </ng-container>

          <!-- Corte Column -->
          <ng-container matColumnDef="sgConsumption">
            <th mat-header-cell *matHeaderCellDef>Corte(Serial)</th>
            <td mat-cell *matCellDef="let element">{{ element.sgConsumptionSerialNumber }}</td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Editar caixa de circuito</th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <button (click)="updateGetter(element)" [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success view">
                <!-- SVG do ícone de edição -->
                <mat-icon class="component-svg-icon" svgIcon="bx-edit"></mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex"
          (page)="pageChanged($event)" aria-label="Selecione as páginas de Empresas" class="mat-paginator-sticky">
        </mat-paginator>
      </div>
    </div>
  </div>

  <!-- Componente de edição -->
  <div class="sidebar-edit" *ngIf="editorController" [formGroup]="updateCircuitBoxForm">
    <mat-icon (click)="closeEditionSideBar()">clear</mat-icon>
    <h2>{{ "circuit-box.form-edit-label" | translate }}</h2>
    <hr />

    <mat-form-field appearance="outline">
      <mat-label>{{ "circuit-box.form-reference" | translate }}</mat-label>
      <input matInput formControlName="reference" />
    </mat-form-field>

    <!-- Campo para realizar a busca do(s) equipamento(s) -->
    <mat-form-field appearance="outline">
      <mat-label> {{ "circuit-box.form-ipc" | translate }} </mat-label>
      <input matInput formControlName="searchUpdateIPC">
      <mat-icon class="component-search-icon" (click)="filterEquipmentBySerialNumber('searchUpdateIPC','ipc', 'update')"
        svgIcon="bx-search"></mat-icon>
    </mat-form-field>
    <!-- Loading -->
    <mat-progress-bar class="mb-2" mode="indeterminate"
      *ngIf="typeFilter == 'update' && loadingEquipmentsIPC"></mat-progress-bar>

    <mat-form-field appearance="outline" *ngIf="equipmentTypeIPCUpdate.length > 0">
      <mat-label>
        {{ "circuit-box.form-ipc" | translate }}</mat-label>
      <mat-select formControlName="sgIpcSerialNumber" #value>
        <mat-option appearance="outline" *ngFor="let item of equipmentTypeIPCUpdate" [value]="item.id">
          {{item.serialNumber}} -
          {{item.reference}} | {{item.major}}.{{item.minor}}.{{item.revision}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        {{ "circuit-box.form-gateway" | translate }}</mat-label>
      <mat-select formControlName="sgGatewayID" #value>
        <mat-option appearance="outline" *ngFor="let item of gatewayInstallationList" [value]="item.id">{{
          item.reference }}</mat-option>
        <mat-option *ngIf="gatewayInstallationList.length < 0"><mat-spinner [diameter]="30"></mat-spinner></mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label> {{ "circuit-box.form-consumption" | translate }} </mat-label>
      <input matInput formControlName="searchUpdateCorte">
      <mat-icon class="component-search-icon"
        (click)="filterEquipmentBySerialNumber('searchUpdateCorte','corte', 'update')" svgIcon="bx-search"></mat-icon>
    </mat-form-field>

    <!-- Loading -->
    <mat-progress-bar class="mb-3" *ngIf="typeFilter == 'update' && loadingEquipmentsCORTE"
      mode="indeterminate"></mat-progress-bar>


    <mat-form-field appearance="outline" *ngIf="equipmentTypeCorteUpdate.length > 0">
      <mat-label>
        {{ "circuit-box.form-consumption" | translate }}</mat-label>
      <mat-select formControlName="sgConsumptionSerialNumber" #value>
        <mat-option appearance="outline" *ngFor="let item of equipmentTypeCorteUpdate" [value]="item.id">
          {{item.serialNumber}} -
          {{item.reference}} | {{item.major}}.{{item.minor}}.{{item.revision}}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <button class="btn btn-success" (click)="updateCircuitBox()">{{"companies.form-save-button" |
      translate}}</button>
  </div>
</div>