import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ComponentsService } from 'src/shared/services/components.service';
import Swal from 'sweetalert2';
import {
  Components,
  ComponentType,
  Installation,
  PaginationInfo,
} from './components-assets/components.model';
import jsPDF from 'jspdf';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
//import { ComponentsModalComponent } from './components.modal/components.modal.component';

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.less'],
})
export class ComponentsComponent extends Unsubscriber {
  @Input() componentLoading$ = this.componentsService.componentsLoading$;

  constructor(
    private componentsService: ComponentsService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    /** Verifica se a empresa foi selecioanada pelo usuário **/
    this.company = localStorage.getItem('lastCompanySelected')
      ? localStorage.getItem('lastCompanySelected')
      : null;

    //listener do paginator (clicar no filtrar chama evento no service)
    this.subscriptions = this.componentsService.paginatorFilterEvent.subscribe((res) => {
      this.paginationProperties = res;
    });

    this.subscriptions = this.componentsService.updateComponentLoading.subscribe((res) => {
      this.updateComponentLoading = res;
    });

    /** Recebe os valores da requisição e atualiza na tabela **/
    this.subscriptions = this.componentsService.componentsGetter().subscribe((components) => {
      components.forEach((component: any) => {
        this.ELEMENT_DATA.push(component);
      });
      this.setDataSourceAttributes();

      if (this.paginationProperties.hasNextPage) {
        this.filterComponent(this.searchFilter, this.materialTypesFilter);
      } else {
        this.paginationProperties.endCursor = null;
      }
    });

    /** Recebe alterações dos tipos de componentes **/
    this.subscriptions = this.componentsService
      .componentsTypeGetter()
      .subscribe((componentsType: ComponentType[]) => {
        componentsType.forEach((componentType: any) => {
          this.componentsTypeList.push(componentType);
        });
      });

    /** Recebe alterações das  instalações **/
    this.subscriptions = this.componentsService
      .installationGetter()
      .subscribe((installationType: Installation[]) => {
        this.installationTypeList = installationType;
      });

    /** Realiza a manipulação no loading do filtro de componentes **/
    this.subscriptions = this.componentsService.componentFilterEvent.subscribe((data) => {
      this.filterComponentLoading = data;
    });
  }
  /** Armazena ID da empresa selecionada **/
  company: any = null;

  /** Variável que armazena os tipos de componentes **/
  componentsTypeList: ComponentType[] = [];

  searchFilter = '';
  materialTypesFilter = [];
  openEdit: boolean = false;
  updateComponentLoading: boolean = false;

  /** Variável que armazena as instalações **/
  installationTypeList: Installation[] = [];

  /** Variável do loading de filtro de componentes inicia desativado**/
  filterComponentLoading: boolean = false;

  paginationProperties: PaginationInfo = new PaginationInfo(
    0,
    0,
    false,
    false,
    null,
    null
  );

  /** Variáveis responsáveis por realizar o loading na tela de forma assincrona **/
  componentTypeLoading$ = this.componentsService.componentsTypeLoading$;

  componentInstallationLoading$ =
    this.componentsService.componentsInstallationLoading$;

  /** Variável utilizada para verificar os tipos de componentes**/
  getcomponentsType: boolean = false;

  /** Variável utilizada para verificar a instalação selecionada **/
  installationTypeSelected: any = null;

  componentTypeSelected: any = null;

  /** Variáveis do formulário de filtro de componentes **/
  filterComponentForm: FormGroup = this.formBuilder.group({
    keyword: [null],
    componentType: [null],
  });

  /** Variáveis do formulário de criação de componentes **/
  createComponentForm: FormGroup = this.formBuilder.group({
    serialNumber: [null, Validators.required],
    componentType: [null, Validators.required],
    installations: [null, Validators.required],
  });

  // Armazena os dados do formulário de filtro das instalações, o valor é setado automáticamente pelo HTML
  updateComponentForm: FormGroup = this.formBuilder.group({
    id: [null],
    serialNumber: [null, Validators.required],
    componentType: [null],
    installations: [null],
  });

  openSidebar(component: Components) {
    this.openEdit = true;

    this.updateComponentForm.setValue({
      id: component.id,
      serialNumber: component.serialNumber,
      componentType: component.componentType.id,
      installations: component.installation.id,
    });
  }

  closeSidebar() {
    this.openEdit = false;
  }

  updateComponent() {
    this.updateComponentForm.value;

    if (this.updateComponentForm.valid) {
      this.componentsService.updateComponent(
        this.updateComponentForm.value.id,
        this.company,
        this.updateComponentForm.value.installations,
        this.updateComponentForm.value.serialNumber,
        this.updateComponentForm.value.componentType
      );

      this.openEdit = false;
      this.ELEMENT_DATA = [];
    } else {
      /** Exibe mensagem de sucesso ao usuário assim que o componente é criado **/
      Swal.fire({
        title: 'Número se série obrigatório',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  }

  /** Elementos da tabela */
  ELEMENT_DATA: Components[] = [];

  /** Variável que configura os elementos da coluna na tabela **/
  displayedColumns: string[] = [
    'serialNumber',
    'componentType',
    'installations',
    'actions',
  ];

  /** Renderiza os comandos da lista ELEMENT_DATA para a tabela **/
  dataSource = new MatTableDataSource<Components>(this.ELEMENT_DATA);

  /** Variáveis usadas para o paginator e para o sort **/
  private paginator: MatPaginator;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  /** Função que configura o paginator **/
  setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
    this.dataSource.paginator = this.paginator;
  }

  /** Variável que guarda o número de relatórios retornados do backend **/
  resultsLength = this.ELEMENT_DATA.length;

  //atualiza o valor do header
  itemsPerPage: number = 0;

  handlePageEvent(event: PageEvent) {
    let control: number = event.length - event.pageSize * event.pageIndex;

    if (control && control < event.pageSize) {
      this.itemsPerPage = control;
      console.log(control);
    }
  }

  /** Variáveis para manipular o box de create e filter **/
  createComponents: boolean = false;
  filterComponents: boolean = false;

  /** Função responsável por abrir e fechar o box de criação de componentes **/
  createComponentsOpen() {
    this.createComponents = !this.createComponents;
    this.filterComponents = false;
  }

  /** Função responsável por abrir e fechar o box de filtro de componentes **/
  filterComponentsOpen() {
    this.filterComponents = !this.filterComponents;
    this.createComponents = false;
  }

  /** Função responsável por realizar o filtro de componentes  */
  filterComponent(search: any, materialTypes: any) {
    if (!this.paginationProperties.endCursor) {
      this.ELEMENT_DATA = [];

      this.paginationProperties = new PaginationInfo(
        0,
        0,
        false,
        false,
        null,
        null
      );
    }

    this.searchFilter = search;
    this.materialTypesFilter = materialTypes;
    this.componentsService.componentsRefresh(
      this.company,
      this.paginationProperties.endCursor,
      search,
      [materialTypes]
    );
  }

  /** Função responsável pela criação de componentes **/
  createComponent(): void {
    /** Caso os campos de formulário forem válidos(preenchidos) **/
    if (this.createComponentForm.valid) {
      /** Chama a função de criação de componentes **/
      this.componentsService.createComponents(
        /** Envia os dados da requisição **/
        this.company,

        [this.installationTypeSelected],
        this.createComponentForm.value?.serialNumber,
        [this.componentTypeSelected]
      );
      /** Limpa os campos de formulário **/
      this.createComponentForm.reset();
    } else {
      /** Caso algum campo não esteja preenchido **/
      /** Emite alerta ao usuário **/
      Swal.fire({
        title: 'Formulário Incompleto',
        text: 'Preencha todos os campos',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }
  }
  /** Função responsável por fazer a validação das instalações que foram selecionadas pelo usuário **/
  installationTypeGetId(installationId: any) {
    this.installationTypeSelected = installationId;
  }

  /** Função responsável por fazer a validação das instalações que foram selecionadas pelo usuário **/
  componentsTypeGetId(componentId: any) {
    this.componentTypeSelected = componentId;
  }

  /** Código destinado à funções de download de CSV e PDF da página. **/

  /** Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe. **/
  private convertToCSV(arr: any) {
    /** Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas. **/
    const content = [Object.keys(arr[0])].concat(arr);

    /** Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um. **/
    return content
      .map((item) => {
        return Object.values(item).join(';');
      })
      .join('\n');
  }

  /**  Função que realiza o download do CSV. **/
  public downloadCSV() {
    let content: any = [];

    /** Adiciona cada elemento como um objeto na lista de conteúdo. **/
    this.dataSource.data.forEach((components) => {
      content.push({
        'Número de série': components.serialNumber,
        'Tipo de Compinente': components.componentType.reference,
        Instalações: components.installation.reference,
      });
    });

    /** Adiciona o conteúdo convertido dentro de uma nova variável. **/
    let data = this.convertToCSV(content);
    /** Transforma o conteúdo para o formato blob para geração do arquivo. **/
    let file = new Blob([data], { type: 'text/csv' });
    /** Cria um endereçamento para o arquivo. **/
    let url = window.URL.createObjectURL(file);
    /** Cria um elemento para a linkagem do arquivo. **/
    let link = document.createElement('a');
    /** Adiciona a url no elemento de linkagem. **/
    link.href = url;
    /** Nomeia o arquivo que será feito o download. **/
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-lampTypes.csv`;
    /** Adiciona o link no documento. **/
    document.body.appendChild(link);
    /** Ativa o evento de click para realizar o download. **/
    link.click();
    /** Remove o link do documento. **/
    document.body.removeChild(link);
    /** Remove o endereço do download. **/
    window.URL.revokeObjectURL(url);
  }

  // Função para download de dados como PDF
  public downloadPDF() {
    // Colunas que serão adicionadas ao header da tabela no PDF
    const tableLayout = [
      'Número de série',
      'Tipo de componente',
      'Instalações',
    ];

    let content: any = [];
    // Adiciona os elementos na lista de conteúdo como um array
    this.ELEMENT_DATA.forEach((components) => {
      content.push([
        components.serialNumber,
        components.componentType.reference,
        components.installation.reference,
      ]);
    });
    // Define a orientação da tabela (paisagem nesse caso)
    const orientation = 'l';
    // Cria o objeto da lib jsPDF com a orientação e tamanho da página
    const doc: any = new jsPDF(orientation, 'mm', 'a4');

    // Cria a tabela com o tableLayout como header e a a lista de conteúdo como corpo da tabela
    doc.autoTable({
      theme: 'grid',
      margin: { top: 20 },
      head: [tableLayout],
      body: content,
    });

    // Baixa o arquivo para o usuário, definindo o nome com a data de download
    doc.save(
      `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-componentes.pdf`
    );
  }
}
