<!-- Container principal da página  -->
<div class="component-main-container">
  <div class="header">
    <app-page-header parentTitle="{{ 'firmwares.firmware-label' | translate }}"
      pageTitle="{{ 'firmwares.generate-firmware-label' | translate }}">
    </app-page-header>

    <div class="row">
      <div class="col-lg-5 col-xl-4 col-sm-12">
        <div class="component-forms-box">
          <app-register-form></app-register-form>
          <div class="form-fields-box" [ngClass]="!filterFirmwares ? 'closed' : ''" [formGroup]="filterFirmwareForm">
            <h2 (click)="filterFirmwaresOpen()">
              {{ "firmwares.filter-firmware" | translate }}
            </h2>
            <hr [ngClass]="!filterFirmwares ? 'hr-open' : ''" />

            <!-- Sessão de filtro de firmwares -->
            <div class="form-step">
              <div class="circle-number">1</div>
              <mat-form-field appearance="outline">
                <mat-label>
                  {{ "firmwares.label-name" | translate }}
                </mat-label>
                <input matInput formControlName="nameFilterForm" />
              </mat-form-field>
            </div>

            <div class="form-step">
              <div class="circle-number">2</div>
              <mat-form-field appearance="outline">
                <mat-label>
                  {{ "firmwares.label-major" | translate }}
                </mat-label>
                <input matInput type="number" min="0" formControlName="majorFilterForm" />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>
                  {{ "firmwares.label-minor" | translate }}
                </mat-label>
                <input matInput type="number" min="0" formControlName="minorFilterForm" />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>
                  {{ "firmwares.label-revision" | translate }}
                </mat-label>
                <input matInput type="number" min="0" formControlName="revisionFilterForm" />
              </mat-form-field>
            </div>
            <div class="d-flex">
              <button class="btn btn-success m-2 w-50" (click)="filterFirmware(null)">
                {{ "firmwares.button-filter" | translate }}
              </button>
              <button class="btn btn-secondary m-2 w-50" (click)="resetFilter()">
                {{ "firmwares.reset-button-filter" | translate }}
              </button>
            </div>
          </div>

          <!-- Sessão do campo para criar Firmware -->
          <div class="form-fields-box" [ngClass]="!registerFirmwares ? 'closed' : ''" [formGroup]="createFirmwareForm">
            <h2 (click)="registerFirmwaresOpen()">
              {{ "firmwares.create-firmware" | translate }}
            </h2>
            <hr [ngClass]="!registerFirmwares ? 'hr-open' : ''" />

            <div class="form-step">
              <div class="circle-number">1</div>
              <mat-form-field appearance="outline">
                <mat-label>
                  {{ "firmwares.label-name" | translate }}
                </mat-label>
                <input matInput formControlName="nameCreateForm" />
              </mat-form-field>
            </div>

            <div class="form-step">
              <div class="circle-number">2</div>
              <mat-form-field appearance="outline">
                <mat-label>
                  {{ "firmwares.label-release-date" | translate }}
                </mat-label>
                <input matInput [matDatepicker]="datepicker" formControlName="releaseDateCreateForm" />
                <mat-datepicker-toggle matIconSuffix [for]="datepicker">
                </mat-datepicker-toggle>
                <mat-datepicker #datepicker> </mat-datepicker>
              </mat-form-field>
            </div>

            <div class="form-step">
              <div class="circle-number">3</div>
              <mat-form-field appearance="outline">
                <mat-label>
                  {{ "firmwares.label-major" | translate }}
                </mat-label>
                <input matInput type="number" min="0" formControlName="majorCreateForm" />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>
                  {{ "firmwares.label-minor" | translate }}
                </mat-label>
                <input matInput type="number" min="0" formControlName="minorCreateForm" />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>
                  {{ "firmwares.label-revision" | translate }}
                </mat-label>
                <input matInput type="number" min="0" formControlName="revisionCreateForm" />
              </mat-form-field>
            </div>

            <div class="form-step">
              <div class="circle-number">4</div>
              <mat-form-field appearance="outline">
                <mat-label>
                  {{ "firmwares.label-description" | translate }}
                </mat-label>
                <textarea matInput formControlName="descriptionCreateForm"></textarea>
              </mat-form-field>
            </div>

            <div class="form-step">
              <div class="circle-number">5</div>
              <mat-form-field *ngIf="!firmwaresLoading" appearance="outline">
                <mat-label>
                  {{ "firmwares.label-compatible-hardware" | translate }}
                </mat-label>
                <mat-select value="default">
                  <mat-option disabled value="default">
                    {{ selectedHardwaresIdList.length }}
                    {{ "firmwares.selected-hardware" | translate }}
                  </mat-option>

                  <span *ngFor="let newHardware of hardwaresList"
                    class="d-flex justify-content-start align-items-center">
                    <mat-checkbox class="ms-2 me-2" (change)="filterSelectedHardwares($event, newHardware.id)"
                      class="ms-2 me-2">
                      {{ newHardware.name }} ({{ newHardware.version }})
                    </mat-checkbox>
                  </span>
                </mat-select>
              </mat-form-field>
              <mat-spinner *ngIf="firmwaresLoading" [diameter]="30"></mat-spinner>
            </div>

            <!-- Sessão do campo para criar novo hardware compatível -->
            <mat-accordion class="newHardware" [formGroup]="createHardwareForm">
              <mat-expansion-panel (opened)="panelOpenFirmware = true" (closed)="panelOpenFirmware = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ "firmwares.create-new-hardware" | translate }}
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="form-step">
                  <div class="circle-number">1</div>
                  <mat-form-field appearance="outline">
                    <mat-label>
                      {{ "firmwares.label-name" | translate }}
                    </mat-label>
                    <input matInput formControlName="nameHardwareCreateForm" />
                  </mat-form-field>
                </div>

                <div class="form-step">
                  <div class="circle-number">2</div>
                  <mat-form-field appearance="outline">
                    <mat-label>
                      {{ "firmwares.label-release-date" | translate }}
                    </mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="releaseDateHardwareCreateForm" />
                    <mat-datepicker-toggle matIconSuffix [for]="picker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker> </mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="form-step version">
                  <div class="circle-number">3</div>
                  <mat-form-field appearance="outline">
                    <mat-label>
                      {{ "firmwares.label-major" | translate }}
                    </mat-label>
                    <input matInput type="number" min="0" formControlName="majorHardwareCreateForm" />
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>
                      {{ "firmwares.label-minor" | translate }}
                    </mat-label>
                    <input matInput type="number" min="0" title="Menor" formControlName="minorHardwareCreateForm" />
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>
                      {{ "firmwares.label-revision" | translate }}
                    </mat-label>
                    <input matInput type="number" min="0" title="Revisão"
                      formControlName="revisionHardwareCreateForm" />
                  </mat-form-field>
                </div>

                <div class="form-step">
                  <div class="circle-number">4</div>
                  <mat-form-field appearance="outline">
                    <mat-label>
                      {{ "firmwares.label-description" | translate }}
                    </mat-label>
                    <textarea matInput formControlName="descriptionHardwareCreateForm"></textarea>
                  </mat-form-field>
                </div>
                <button class="btn btn-success" (click)="createNewHardware()" [disabled]="createHardwareForm.invalid">
                  {{ "firmwares.button-save" | translate }}
                </button>
                <mat-spinner *ngIf="registerHardwareLoading" [diameter]="30"></mat-spinner>
              </mat-expansion-panel>
            </mat-accordion>

            <button class="btn btn-success" (click)="createFirmware()" [disabled]="createFirmwareForm.invalid">
              {{ "firmwares.button-save" | translate }}
            </button>
            <mat-spinner *ngIf="registerFirmwareLoading" [diameter]="30"></mat-spinner>
          </div>
        </div>
      </div>

      <div class="col-lg-7 col-xl-8 col-sm-12">
        <app-table-header *ngIf="length > 0" [itensNumber]="paginationProperties.count"
          [totalNumber]="paginationProperties.total" [hasCSV]="true" [hasPDF]="true"
          (downloadCSV)="generateFileData(null, 'CSV')" (downloadPDF)="generateFileData(null, 'PDF')"
          [loadingData$]="fileLoading">
        </app-table-header>
        <!-- Sessão tabela -->
        <div class="table-div mt-2 mt-lg-3 mt-xl-3">
          <mat-progress-bar mode="query" *ngIf="firmwaresLoading">
          </mat-progress-bar>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>
                {{ "firmwares.label-name" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <!-- version Column -->
            <ng-container matColumnDef="version">
              <th mat-header-cell *matHeaderCellDef>
                {{ "firmwares.version-firmware" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.version }}</td>
            </ng-container>

            <!-- releaseDate Column -->
            <ng-container matColumnDef="releaseDate">
              <th mat-header-cell *matHeaderCellDef>
                {{ "firmwares.label-release-date" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.releaseDate | date : "dd/MM/YYYY" }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <!-- Paginator -->
          <mat-paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" (page)="pageChanged($event)"
            aria-label="Selecione as páginas de Empresas" class="mat-paginator-sticky">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>