import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { InstallationSiteLogService } from 'src/shared/services/installation-site-log.service';
import { jsPDF } from 'jspdf';
import { InstallationSiteLog, PaginationInfo } from './installation-site-log-assets/installation-site-log-model';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import Swal from 'sweetalert2';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
import { tzConvertISOEndDate, tzConvertISOStartDate, tzConvertUTC2Local } from 'src/assets/convertTimeZone/convertTimeZone';

@Component({
  selector: 'app-installation-site-log',
  templateUrl: './installation-site-log.component.html',
  styleUrls: ['./installation-site-log.component.less']
})
export class InstallationSiteLogComponent extends Unsubscriber implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private installationSiteLogService: InstallationSiteLogService,
    private errorLibService: ErrorLibService
  ) {
    super();
  }

  ngOnInit() { }

  // Armazena o ID da empresa selecionada (Pode iniciar com o valor armazenado localStorage ou null)
  public companyId: string | null = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;

  // Variaveis responáveis por abrir e fechar as boxes
  public filterBox: boolean = false;
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null);

  // Variaveis responsáveis pelos loadings da tela
  public filterLoading: boolean = false;
  public fileLoading: boolean = false;

  // Função responsável por mudar o estado da box de filtro
  public filterBoxOpen(): void {
    this.filterBox = !this.filterBox;
  }

  /** Variáveis utilizadas para armazenar o filtro do usuário **/
  public operation: string;
  public startDatetime: any;
  public endDatetime: any;
  public reference: string;
  public username: string;

  //Variavel responsável por armazenas os dados da tabela
  public ELEMENT_DATA: InstallationSiteLog[] = [];

  // Variavel responsável por configurar o nome das colunas da tabela
  public displayedColumns: string[] = ["username", "operation", "logDatetime", "latitude", "longitude", "reference", "isActive"];

  // Renderiza os comandos da lista ELEMENT_DATA para a tabela
  public dataSource = new MatTableDataSource<InstallationSiteLog>(this.ELEMENT_DATA);

  /** Variáveis utilizadas para manipulação do Paginator **/
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public currentPage: number = 0;
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  /** Função chamada toda vez que ocorre um evento no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.filterInstallationSiteLogs(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterInstallationSiteLogs(this.paginationProperties.endCursor)
    }
  }

  /** Variáveis utilizadas para geração e manipulação dos arquivos **/
  public FILE_DATA: InstallationSiteLog[] = [];
  public isReady: boolean = false;
  /**
   * Função que configura os dados da tabela.
   */
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  // Armazena os dados do formulário de filtro de logs, o valor é setado automáticamente pelo HTML
  public filterLogsForm: FormGroup = this.formBuilder.group({
    operation: null,
    startDateForm: null,
    endDateForm: null,
    reference: null,
    username: null,
  });

  /**
   * Função responsavel por filtrar logs
   */
  public filterInstallationSiteLogs(cursor: string | null) {
    this.operation = this.filterLogsForm.get('operation')?.value || null;

    this.startDatetime = this.filterLogsForm.get('startDateForm')?.value
      ? tzConvertISOStartDate(this.filterLogsForm.get('startDateForm')?.value)
      : null;

    this.endDatetime = this.filterLogsForm.get('endDateForm')?.value
      ? tzConvertISOEndDate(this.filterLogsForm.get('endDateForm')?.value)
      : null;

    this.reference = this.filterLogsForm.get('reference')?.value || null;

    this.username = this.filterLogsForm.get('username')?.value || null;

    if (cursor === null) {
      this.pageIndex = 0;
      this.isReady = true;
    }

    this.filterLoading = true;

    this.subscriptions = this.installationSiteLogService.filterLogs(
      this.companyId,
      this.operation,
      this.startDatetime,
      this.endDatetime,
      this.reference,
      this.username,
      cursor,
      this.pageSize
    ).valueChanges.subscribe({
      next: ((response: any) => {
        this.paginationProperties = new PaginationInfo(
          response.data.installationSiteLog.count,
          response.data.installationSiteLog.total,
          response.data.installationSiteLog.pageInfo.hasNextPage,
          response.data.installationSiteLog.pageInfo.hasPreviousPage,
          response.data.installationSiteLog.pageInfo.startCursor,
          response.data.installationSiteLog.pageInfo.endCursor
        )

        this.ELEMENT_DATA = [];
        this.isReady = false;
        this.length = this.paginationProperties.total;

        // Adiciona cada log retornado pela requisição na lista
        response.data.installationSiteLog.edges.forEach((node: any) => {
          this.ELEMENT_DATA.push(new InstallationSiteLog(
            node.node.username,
            node.node.operation,
            node.node.logDatetime,
            node.node.latitude,
            node.node.longitude,
            node.node.reference,
            node.node.isActive,
            node.node.company.name
          ));
        });

        this.setDataSourceAttributes();

        if (this.ELEMENT_DATA.length === 0) {
          // Retorna um aviso de erro ao usuário
          Swal.fire({
            title: 'Não Encontrado',
            text: 'Não há registros encontrados para esta busca.',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        }

        this.filterLoading = false;

      })
    });
  }

  // Método para obter a chave de tradução correta com base em 'element.operation'
  public getTranslationKey(element: any): string {
    return `logs.form-${element.operation}-label`;
  }

  // Método para obter a chave de tradução correta com base em 'element.isActive'
  public getTranslationKeyIsActive(element: any): string {
    return `logs.form-${element.isActive}-label`;
  }

  // ---------------------------------------------------------------------
  // Código destinado à funções de download de CSV e PDF da página.

  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscriptions = this.installationSiteLogService.filterLogs(
        this.companyId,
        this.operation,
        this.startDatetime,
        this.endDatetime,
        this.reference,
        this.username,
        cursor,
        100
      ).valueChanges.subscribe({
        next: ((response: any) => {

          response.data.installationSiteLog.edges.forEach((installationSiteLog: any) => {

            /** Tratamento de dados retornados da API **/
            this.FILE_DATA.push(
              new InstallationSiteLog(
                installationSiteLog.node.username,
                installationSiteLog.node.operation,
                installationSiteLog.node.logDatetime,
                installationSiteLog.node.latitude,
                installationSiteLog.node.longitude,
                installationSiteLog.node.reference,
                installationSiteLog.node.isActive,
                installationSiteLog.node.company.name
              )
            );
          })

          /** Caso seja a última página a lista está completa **/
          if (!response.data?.installationSiteLog?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.installationSiteLog?.pageInfo?.endCursor, fileType);
        }),
        error: ((error: any) => {
          this.errorLibService.errorAlert(error);
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  // Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe.
  private convertToCSV(arr: any) {
    // Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas.
    const content = [Object.keys(arr[0])].concat(arr);

    // Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um.
    return content
      .map((item) => {
        return Object.values(item).toString();
      })
      .join('\n');
  }

  // Função que realiza o download do CSV.
  public downloadCSV() {
    let content: any = [];

    // Adicoina cada elemento como um objeto na lista de conteúdo.
    this.FILE_DATA.forEach((log) => {
      content.push({
        Username: log.username,
        Operation: log.operation,
        LogDatetime: tzConvertUTC2Local(log.logDatetime),
        Latitude: log.latitude,
        Longitude: log.longitude,
        Reference: log.reference,
        IsActive: log.isActive,
        Company: log.company
      });
    });

    // Adiciona o conteúdo convertido dentro de uma nova variável.
    let data = this.convertToCSV(content);
    // Transforma o conteúdo para o formato blob para geração do arquivo.
    let file = new Blob([data], { type: 'text/csv' });
    // Cria um endereçamento para o arquivo.
    let url = window.URL.createObjectURL(file);
    // Cria um elemento para a linkagem do arquivo.
    let link = document.createElement('a');
    // Adiciona a url no elemento de linkagem.
    link.href = url;
    // Nomeia o arquivo que será feito o download.
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-installation-site-log.csv`;
    // Adiciona o link no documento.
    document.body.appendChild(link);
    // Atica o evento de click para realizar o download.
    link.click();
    // Remove o link do documento.
    document.body.removeChild(link);
    // Remove o endereço do download.
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  // Função que realiza o download do CSV.
  public downloadPDF() {
    // Define o cabeçalho do documento.
    const header = ["Username", "Operation", "LogDatetime", "Latitude", "Longitude", "Reference", "IsActive", "Company"];
    // Lista que irá conter o conteúdo retornado pela API.
    let content: any = [];
    // Inserção do conteúdo na lista.
    this.FILE_DATA.forEach((log) => {
      content.push([
        log.username,
        log.operation,
        tzConvertUTC2Local(log.logDatetime),
        log.latitude,
        log.longitude,
        log.reference,
        log.isActive,
        log.company
      ]);
    });
    // Define o formato do documento.
    let file: any = new jsPDF('l', 'mm', 'a4');
    // Cria o PDF.
    file.autoTable({
      theme: 'grid',
      margin: { top: 20 },
      head: [header],
      body: content,
    });
    // Gera o download do documento.
    file.save(
      `${new Date().toLocaleDateString()}-${new Date().getHours()}:${new Date().getMinutes()}-installation-site-log.pdf`
    );

    this.FILE_DATA = [];
    this.isReady = false;
  }

  /** Realiza a conversão da data p/ fuso horário do navegador ou fuso horário especificado pelo usuário 
   * (Utilizado na data exibida na tabela de logs) **/
  public formatDate(date: string): any {
    return tzConvertUTC2Local(date)
  }
}
