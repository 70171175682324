<div class="component-main-container">
  <app-page-header parentTitle="{{ 'meters.meters-label' | translate }}"
    pageTitle="{{ 'meters.generate-meters-label' | translate }}">
  </app-page-header>

  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-12">
      <div class="component-forms-box">
        <app-register-form></app-register-form>

        <!-- Sessão de Filtro de medidores -->
        <div class="form-fields-box" [ngClass]="!filterMetersBox ? 'closed' : ''" [formGroup]="filterMetersForm">
          <h2 (click)="filterMetersOpen()">
            {{ "meters.filter-meters" | translate }}
          </h2>
          <hr [ngClass]="!filterMetersBox ? 'hr-open' : ''" />
          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label> {{ "meters.label-keyword" | translate }} </mat-label>
              <input matInput formControlName="keyword" #search />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">2</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "meters.select-meters-type" | translate }}
              </mat-label>
              <mat-select formControlName="metersType" #type>
                <mat-option *ngFor="let item of metersTypeList" [value]="item.id">
                  {{ item.reference }}
                </mat-option>
                <mat-option *ngIf="metersTypeLoading$ | async">
                  <mat-spinner [diameter]="30"></mat-spinner>
                </mat-option>
                <mat-option *ngIf="metersTypeList.length === 0">
                  {{ "meters.select-option-meters-types" | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="d-flex m-2">
            <button *ngIf="!filterMetersLoading" class="btn btn-success m-1 w-50"
              (click)="filterMeters(search.value, type.value)">
              {{ "meters.button-filter" | translate }}
            </button>
            <mat-spinner *ngIf="filterMetersLoading" [diameter]="30"></mat-spinner>
            <button class="btn btn-secondary m-1 w-50" (click)="resetFilter()">
              {{ "meters.button-reset-filter" | translate }}
            </button>
          </div>
        </div>

        <!-- sessão de Registro de medidores -->
        <div class="form-fields-box" [ngClass]="!registerMetersBox ? 'closed' : ''" [formGroup]="createMetersForm">
          <h2 (click)="registerMetersOpen()">
            {{ "meters.register-meters" | translate }}
          </h2>
          <hr [ngClass]="!registerMetersBox ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "meters.label-serial-number" | translate }}
              </mat-label>
              <input matInput formControlName="serialNumber" />
            </mat-form-field>
          </div>
          <div class="form-step">
            <div class="circle-number">2</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "meters.label-filter-meters-types" | translate }}
              </mat-label>
              <mat-select formControlName="type" #metersId (valueChange)="metersTypeGetId(metersId.value)">
                <mat-option *ngFor="let item of metersTypeList" [value]="item.id">
                  {{ item.reference }}
                </mat-option>

                <mat-option *ngIf="metersTypeLoading$ | async">
                  <mat-spinner [diameter]="30"> </mat-spinner>
                </mat-option>
                <mat-option *ngIf="metersTypeList.length === 0">
                  {{ "meters.select-option-meters-types" | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">3</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "meters.label-filter-installations" | translate }}
              </mat-label>

              <mat-select formControlName="installations" #installationId
                (valueChange)="installationGetId(installationId.value)">
                <mat-option *ngFor="let item of installationList" [value]="item.id">
                  {{ item.reference }}
                </mat-option>

                <mat-option *ngIf="installationLoading$ | async">
                  <mat-spinner [diameter]="30"> </mat-spinner>
                </mat-option>

                <mat-option *ngIf="installationList.length === 0">
                  {{ "meters.select-option-installations" | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button class="btn btn-success" *ngIf="!registerMetersLoading" (click)="createMeters()">
            {{ "meters.button-save" | translate }}
          </button>
          <mat-spinner *ngIf="registerMetersLoading" [diameter]="30"></mat-spinner>
        </div>
      </div>
    </div>

    <!-- Sessão do cabeçalho da tabela -->
    <div class="col-lg-7 col-xl-8 col-sm-12">
      <div class="table-header">
        <div class="title-header">
          <h2>
            <strong>
              {{ "meters.display-items" | translate }}
              {{ 10 }}
              <span *ngIf="metersTable.length === 1">
                {{ "meters.item-table" | translate }}
              </span>
              <span *ngIf="metersTable.length > 1">
                {{ "meters.items-table" | translate }}
              </span>
              {{ "meters.table-length" | translate }} {{ metersTable.length }}
            </strong>
          </h2>
          <h3 *ngIf="metersLoading$ | async">
            {{ "meters.loading-data" | translate }}
          </h3>
          <h3 *ngIf="!(metersLoading$ | async) && metersTable.length === 0">
            {{ "meters.no-data" | translate }}
          </h3>
          <h3 *ngIf="(hasCSV || hasPDF) && metersTable.length > 0">
            {{ "meters.downloads" | translate }}:
            <strong *ngIf="hasCSV" (click)="exportCSV()">.CSV</strong>
            <strong *ngIf="hasPDF" (click)="exportPDF()">.PDF</strong>
          </h3>
        </div>
      </div>
      <div class="table-div mt-2 mt-lg-3 mt-lg-3">
        <mat-progress-bar mode="query" *ngIf="metersLoading$ | async">
        </mat-progress-bar>
        <!-- Sessão da tabela -->
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!-- SerialNumber Column -->
          <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef>
              {{ "meters.serial-number-table" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.serialNumber }}
            </td>
          </ng-container>

          <!-- typeMeters Column -->
          <ng-container matColumnDef="typeMeters">
            <th mat-header-cell *matHeaderCellDef>
              {{ "meters.meters-type-table" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.materialTypes }}
            </td>
          </ng-container>

          <!-- installations Column -->
          <ng-container matColumnDef="installations">
            <th mat-header-cell *matHeaderCellDef>
              {{ "meters.installations-table" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.installation }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <!-- Sessão Paginator -->
        <mat-paginator [length]="dataLength" [pageSize]="10" [pageSizeOptions]="[5, 10]"
          aria-label="Selecione as páginas de Empresas" class="mat-paginator-sticky" showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>
  </div>
</div>