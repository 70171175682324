import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
import { ParametersService } from 'src/shared/services/parameters.service';
import { PaginationInfo, Parameters } from './models/parameters.models';
import Swal from 'sweetalert2';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.less'],
})

export class ParametersComponent extends Unsubscriber {
  // Assim que acessar a página aparece os dados na tabela
  constructor(
    private formBuilder: FormBuilder,
    private parametersService: ParametersService,
    private errorLibService: ErrorLibService
  ) {
    /** Utilizado para acessar a classe pai (Unsubscriber) **/
    super();
  }

  // Variável por abrir e fechar o form
  public isCreateParamOpen: boolean = false;
  public isFilterOpen: boolean = false;

  /** Variáveis responsáveis por armazenar o filtro do usuário **/
  public filterReference: string;
  public filterParameter: string;

  /** Váriavel que armazena o parametro selecionado utilizado no update para armazenar o id **/
  public parameterSelected: string;

  //variáveis de loadings
  public loadingParameters: boolean = false;
  public loadingCreateParameters: boolean = false;
  public fileLoading: boolean = false;

  /** Variáveis utilizadas para manipular o sidebar para edição/visualização **/
  public editorController: boolean = false;
  public viewController: boolean = false;

  /**Variavel utilizadas para habilitar o botão de filtro **/
  public filter: boolean = false
  public isFiltering: boolean = false;

  // Form criar parâmetro
  public referenceCreateForm: FormControl = new FormControl(null)
  public paramCreateForm: FormControl = new FormControl(null)

  // Form filtrar parâmetros
  public referenceFilterForm: FormControl = new FormControl(null);
  public parameterFilterForm: FormControl = new FormControl(null);

  /** Form update parametros **/
  public updateForm: FormGroup = this.formBuilder.group({
    reference: [null, Validators.required],
    parameter: [null, Validators.required]
  });

  public ELEMENT_DATA: Parameters[] = [];

  // nome dos valores na tabela
  public displayedColumns: string[] = ['reference', 'parameter', 'actions'];

  // Renderiza os comandos da lista ELEMENT_DATA para a tabela
  public dataSource = new MatTableDataSource<Parameters>(this.ELEMENT_DATA);
  /** Variável utilizada para controle de paginação **/
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null)

  /** Métodos e variáveis responsáveis por configurar a paginação da tabela **/
  @ViewChild(MatPaginator) paginator: MatPaginator;
  /** Variáveis utilizadas para manipulação do Paginator **/
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  /** Função que controla os botões de anterior e pŕoximo no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.filterParameters(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterParameters(this.paginationProperties.endCursor)
    }
  }

  /** Variáveis utilizadas para geração e manipulação dos arquivos **/
  public isReady = false;
  public FILE_DATA: Parameters[] = [];

  // Função que configura o paginator.
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  // Função abrir/fechar form
  public openCloseCreateParam() {
    this.isCreateParamOpen = !this.isCreateParamOpen;
    this.isFilterOpen = false;
  }

  public openCloseFilterParam() {
    this.isFilterOpen = !this.isFilterOpen;
    this.isCreateParamOpen = false;
  }

  // Filtrar parâmetro
  public filterParameters(cursor: string | null): void {

    /** Ativa o spinner de loading **/
    this.loadingParameters = true;

    this.filterReference = this.referenceFilterForm.value;
    this.filterParameter = this.parameterFilterForm.value;

    if (cursor == null) {
      this.isReady = true;
      this.pageIndex = 0;
    }

    this.subscriptions = this.parametersService.filterParameter(
      this.filterReference,
      this.filterParameter,
      cursor,
      this.pageSize
    ).valueChanges.subscribe({
      next: ((response: any) => {
        // Preenche a variável usada para controle de paginação
        this.paginationProperties = new PaginationInfo(
          response.data?.commandParameter?.count,
          response.data?.commandParameter?.total,
          response.data?.commandParameter?.pageInfo?.hasNextPage,
          response.data?.commandParameter?.pageInfo?.hasPreviousPage,
          response.data?.commandParameter?.pageInfo?.startCursor,
          response.data?.commandParameter?.pageInfo?.endCursor
        )

        this.ELEMENT_DATA = [];
        this.isReady = false;
        this.length = this.paginationProperties.total;

        // Preenche a lista responsável por alimentar a tabela
        response.data.commandParameter.edges.forEach((parameter: any) => {
          this.ELEMENT_DATA.push(new Parameters(
            parameter.node.id,
            parameter.node.reference,
            parameter.node.targetReference
          ))
        });

        this.setDataSourceAttributes()

        if (this.ELEMENT_DATA.length === 0) {
          /** Retorna alerta ao usuário **/
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        }

        this.loadingParameters = false

      }),
      error: ((error: any) => {
        this.errorLibService.errorAlert(error);
      })
    })
    this.isFiltering = true;
  }

  //volta ao estado original da tabela e limpa os campos do form
  public clearFilter() {
    this.referenceFilterForm.reset();
    this.parameterFilterForm.reset();
    this.isFiltering = false;
  }

  // Criar Parâmetro
  public createParameters() {
    this.subscriptions = this.parametersService.createParameter(
      this.referenceCreateForm.value,
      this.paramCreateForm.value
    ).subscribe({
      next: (() => {

        this.referenceFilterForm.setValue(this.referenceCreateForm.value)

        this.filterParameters(null)

        this.referenceFilterForm.setValue("")
      })
    });
    this.referenceCreateForm.reset();
    this.paramCreateForm.reset();
  }

  /** Abre o sidebar e preenche os campos do formulário com os dados já existentes **/
  public OpenSidebarUpdate(
    id: string,
    reference: string | null,
    parameter: string | null,
    isViewing: boolean
  ) {

    this.viewController = isViewing;

    if (isViewing === false) {
      this.updateForm.disable();
    }
    /** se estiver na opção de edição habilita o formulário para edição normalmente **/
    else {
      this.updateForm.enable();
    }
    this.parameterSelected = id;

    this.updateForm.setValue({
      reference,
      parameter
    })

    /** Abre o sidebar de edição **/
    this.editorController = true;
  }

  // Fecha o sidebar de edição e de visualização.
  public closeEditionSidebar() {
    this.editorController = false;
    this.viewController = false;
    // Limpa a variável com o ID do parâmetro selecionada.
    this.parameterSelected = '';
  }

  /** Realiza o update dos parâmetros **/
  public updateParameter() {
    if (this.updateForm.valid) {
      this.subscriptions = this.parametersService.updateParameter(
        this.parameterSelected,
        this.updateForm.get('reference')?.value,
        this.updateForm.get('parameter')?.value
      ).subscribe({
        next: ((res: any) => {
          Swal.fire({
            title: 'Parâmetro atualizado',
            text: 'Parâmetro atualizado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });
          /** Retorna o elemento atualizado **/
          this.ELEMENT_DATA = [
            new Parameters(
              res.data.commandParameterUpdate.commandParameter.id,
              res.data.commandParameterUpdate.commandParameter.reference,
              res.data.commandParameterUpdate.commandParameter.targetReference
            )
          ];

          /** Atualiza a tabela **/
          this.setDataSourceAttributes();
          this.parameterSelected = '';
          /** Fecha o sidebar de edição **/
          this.editorController = false;
        })
      })
    }
    /** Caso algum campo obrigatório não tenha sido preenchido **/
    else {
      Swal.fire({
        text: 'Confirme todos os dados antes de atualizar!',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }

  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscriptions = this.parametersService.filterParameter(
        this.filterReference,
        this.filterParameter,
        cursor,
        100
      ).valueChanges.subscribe({
        next: ((response: any) => {
          // Preenche a lista responsável por alimentar a tabela
          response.data.commandParameter.edges.forEach((parameter: any) => {
            this.FILE_DATA.push(new Parameters(
              parameter.node.id,
              parameter.node.reference,
              parameter.node.targetReference
            ))
          })

          /** Caso seja a última página a lista está completa **/
          if (!response.data?.commandParameter?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.commandParameter?.pageInfo?.endCursor, fileType);
        }),
        error: ((error: any) => {
          this.errorLibService.errorAlert(error);
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  /** Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe **/
  private convertToCSV(arr: any) {
    /** Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas **/
    const content = [Object.keys(arr[0])].concat(arr);

    /** Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um **/
    return content.map((item) => {
      return Object.values(item).toString().replace(',', ';');
    }).join('\n');
  }

  /** Método que realiza o download do CSV **/
  public downloadCSV() {
    let content: any = [];

    /** Adiciona cada elemento como um objeto na lista de conteúdo **/
    this.FILE_DATA.forEach((element) => {
      content.push({
        'Referência': element.reference,
        'Parâmetro': element.parameter
      });
    });

    /** Adiciona o conteúdo convertido dentro de uma nova variável. **/
    let data = this.convertToCSV(content);
    /**  Transforma o conteúdo para o formato blob para geração do arquivo. **/
    let file = new Blob([data], { type: 'text/csv' });
    /** Cria um endereçamento para o arquivo. **/
    let url = window.URL.createObjectURL(file);
    /** Cria um elemento para a linkagem do arquivo. **/
    let link = document.createElement('a');
    /** Adiciona a url no elemento de linkagem. **/
    link.href = url;
    /**  Nomeia o arquivo que será feito o download. **/
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-parametros.csv`;
    /** Adiciona o link no documento. **/
    document.body.appendChild(link);
    /** Atica o evento de click para realizar o download. **/
    link.click();
    /** Remove o link do documento. **/
    document.body.removeChild(link);
    /**  Remove o endereço do download. **/
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  /** Método que realiza o download do PDF. **/
  public downloadPDF() {
    /** Define o cabeçalho do documento. **/
    const header = [
      'Referência',
      'Parâmetro'
    ];

    /** Lista que irá conter o conteúdo retornado pela API. **/
    let content: any = [];
    /** Inserção do conteúdo na lista. **/
    this.FILE_DATA.forEach((element) => {
      console.log(element)
      content.push([
        element.reference,
        element.parameter
      ]);
    });
    /** Define o formato do documento. **/
    let file: any = new jsPDF('l', 'mm', 'a4');
    /** Cria o PDF. **/
    file.autoTable({
      theme: "grid",
      margin: { top: 20 },
      head: [header],
      body: content
    });
    /** Gera o download do documento. **/
    file.save(`${new Date().toLocaleDateString()}-${new Date().getHours()}:${new Date().getMinutes()}-parametros.pdf`);

    this.FILE_DATA = [];
    this.isReady = false;

  }
}
