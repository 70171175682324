import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UserprofileService } from 'src/shared/services/userprofile.service';
import { ManageUserService } from 'src/shared/services/manage-user.service';

import Swal from 'sweetalert2';

import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
import { User } from '../models/user.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-userchangepassword',
  templateUrl: './userchangepassword.component.html',
  styleUrls: ['./userchangepassword.component.less']
})
export class UserchangepasswordComponent extends Unsubscriber implements OnInit {
  // valida se a senha do usuário está expirada ou não
  public expiredPassword = localStorage.getItem('expiredPassword');
  public isExpiredPassword = this.expiredPassword === 'true'; // converte para booleano

  public userForm: FormGroup;
  public isEqualPasswords: ValidatorFn;

  public user: User = new User('', '', '', '', '', '', '', '', [], [], [], '');

  @Output() hideChangePassword = new EventEmitter<void>()
  @Output() newUserData = new EventEmitter<User>();

  constructor(private manageUserService: ManageUserService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      username: new FormControl(),
      password: new FormControl(),
      confirmPassword: new FormControl()
    });

    this.isEqualPasswords = (control: AbstractControl): ValidationErrors | null => {
      const password = control.get('password')?.value;
      const confirmPassword = control.get('confirmPassword')?.value;
      return password === confirmPassword ? null : { passwordNotMatch: true };
    };

    this.getUserInfo()
  }

  /** Funções responsáveis por validar o input de senha */
  public hasNumber(password: string): boolean {
    return /\d/.test(password);
  }

  public hasSpecialCharacter(password: string): boolean {
    return /\W/.test(password);
  }

  public hasUpperCaseLetter(password: string): boolean {
    return /[A-Z]/.test(password);
  }

  public hasMinimumLength(password: string): boolean {
    return password.length >= 6;
  }

  /**atualiza senha e se está expirada ou não no usuario logado */
  public saveEdit(): void {
    this.subscriptions = this.manageUserService.updateUserProfile(
      this.user.username,
      this.user.firstName,
      this.user.lastName,
      this.user.email,
      (!this.isExpiredPassword),
      this.userForm.get('password')!.value
    ).subscribe({
      next: (result: any) => {

        this.hideChangePassword.emit();
        /** Exibe mensagem de sucesso ao usuário **/
        Swal.fire({
          title: this.translateService.instant('manage-user.updated-title'),
          text: this.translateService.instant('manage-user.updated-text-success'),
          icon: 'success',
          confirmButtonText: this.translateService.instant('manage-user.confirm-button-text'),
          cancelButtonText: this.translateService.instant('manage-user.cancel-button-text')
        });
      },
      /** Caso ocorra algum erro para realizar o update de usuários **/
      error: (error) => {

        console.log('Error on updateUser mutatiton', error);

        Swal.fire({
          title: this.translateService.instant('manage-user.updated-title-failed'),
          text: this.translateService.instant('manage-user.updated-text-failed'),
          icon: 'error',
          confirmButtonText: this.translateService.instant('manage-user.confirm-button-text'),
        });

      },
    });
  }

  public getUserInfo(): void {

    this.subscriptions = this.manageUserService.getUser(`${localStorage.getItem('username')}`).valueChanges
      .subscribe(
        {
          next: (result: any) => {
            this.user.id = localStorage.getItem('userId')!;
            this.user.username = result.data.user.edges[0].node.username;
            this.user.name = result.data.user.edges[0].node.firstName;
            this.user.lastName = result.data.user.edges[0].node.lastName;
            this.user.email = result.data.user.edges[0].node.email;

            this.userForm = this.formBuilder.group({

              username: [{ value: result.data.user.edges[0].node.username, disabled: true }],
              password: ['', Validators.required],
              confirmPassword: ['', Validators.required]
            }, { validators: this.isEqualPasswords });

          },
          error: (error: any) => {
            console.log(error);
          }
        }
      )
  }

}
