import { gql } from "apollo-angular";

// Filtra os equipamentos
const FILTER_EQUIPMENTS = gql`
query Equipment(
    $company: ID!,
    $pageSize: Int,
    $cursor: String,
    $search: String,
    $equipmentTypes: [ID],
    $serialNumber: String,
    $isInstalled: Boolean,
    $sort_dir: Direction!,
    $sort_field: EquipmentOrderFields!
	$deviceMacs: [String]
    ) {
    equipment(
        company: $company,
        pageSize: $pageSize,
        cursor: $cursor,
        search: $search,
        equipmentTypes: $equipmentTypes,
        serialNumber: $serialNumber,
		deviceMacs:$deviceMacs,
        isInstalled: $isInstalled,
        orderBy:{
            direction: $sort_dir,
            field: $sort_field
            }
        ) {
        edges {
            node {
                id
                serialNumber
                macAddress
                equipmentType {
                    id
                    reference
                    minor
                    major
                    revision
                }
            }
            cursor
        }
        total
        count
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
    }
}`;


// Cria equipamentos
const EQUIPMENT_CREATE = gql`
mutation
	EquipmentCreate(
		$company: ID!,
		$macAddress: String!,
		$serialNumber: String!,
		$equipmentType:ID!) {
		equipmentCreate(
			input: {
				company: $company,
				macAddress: $macAddress
				serialNumber: $serialNumber
				equipmentType: $equipmentType
			}) {
			equipment {
				id
                equipmentType {
                    id
                    reference
                    minor
                    major
                    revision
                    __typename
                }
			}
		}
	}`;

// Filtro de todos os equipamentos para a tabela(Filtro inicial)
const FILTER_EQUIPMENTS_TYPE_TABLE = gql`
    query Equipment(
        $company: ID!
        ) {
        equipment(
            company: $company
            ) {
            edges {
                node {
                    id
                    serialNumber
                    macAddress
                    equipmentType {
                        id
                        reference
                        minor
                        major
                        revision
                    }
                }
                cursor
            }
            total
            count
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }`;

const UPDATE_EQUIPMENTS = gql`
mutation
EquipmentUpdate(
    $id: ID!,
    $company: ID!,
    $macAddress: String!,
    $serialNumber: String!,
    $equipmentType: ID!) {
    equipmentUpdate(
        input: {
            id: $id
            company: $company
            macAddress: $macAddress
            serialNumber: $serialNumber
            equipmentType: $equipmentType
        }) {
        equipment {
            id
            macAddress
            serialNumber
            equipmentType {
                id
                reference
                minor
                major
                revision
                __typename
            }
        }
    }
}
`

export {
    // Queries
    FILTER_EQUIPMENTS,
    // Mutations
    EQUIPMENT_CREATE,
    UPDATE_EQUIPMENTS
}