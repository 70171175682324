import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EquipmentTypeService } from 'src/shared/services/equipment-type.service';
import Swal from 'sweetalert2';
import { QueryCommandsModalComponent } from './equipment-type-modal/equipment-type-modal.component';
import { QueryCommands, ConfigCommands, ParameterTypeBool } from './equipment-type-modal/model';
import { CommandSheet, EquipmentParts, EquipmentType, Firmware, Hardware, PaginationInfo } from './equipment-type.model';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import jsPDF from 'jspdf';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
import { ConfigCommandsModalComponent } from './equipment-type-modal-config-commands/config-commands-modal/config-commands-modal.component';

@Component({
  selector: 'app-equipment-type',
  templateUrl: './equipment-type.component.html',
  styleUrls: ['./equipment-type.component.less']
})
export class EquipmentTypeComponent extends Unsubscriber implements OnInit {

  constructor(
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    public equipmentTypeService: EquipmentTypeService,
    private errorLibService: ErrorLibService,
  ) {
    super();
  }

  ngOnInit(): void {
    /** Realiza os filtros de firmwares e hardwares **/
    this.refreshFirmware();
    this.getHardwares();
  }

  // Abre e fecha a box de registro de tipo de equipamento
  public registerEquipmentType: boolean = false;

  // Abre e fecha a box de filtro de tipo de equipamento
  public filterEquipmentType: boolean = false;

  // Abre e fecha o formulario de criação de hardware
  public panelOpenHardware: boolean = false;

  // Abre e fecha o formulario de criação de firmware
  public panelOpenFirmware: boolean = false;

  // Variaveis responsaveis por manipular os loadings da tela
  public equipmentTypeLoading: boolean = false;
  public equipmentTypeCreateLoading: boolean = false;
  public hardwareCreateLoading: boolean = false;
  public firmwareCreateLoading: boolean = false;

  // Atualiza os valores que irão para a tabela
  public ELEMENT_DATA: EquipmentType[] = [];

  // Recebe os hardwares retornados da API
  public hardwaresList: Hardware[] = [];

  // Recebe os firmwares retornados da API
  public firmwaresList: Firmware[] = [];

  public compatiblefirmwaresList: Firmware[] = [];

  // Recebe os dados de consulta do modal de comandos
  public queryCommands: QueryCommands[] = [];

  public configCommands: ConfigCommands[] = [];

  // Verifica se o tipo de equipamento é um gateway(Na box de registro)
  public isGatewaySelected: boolean = false;

  public viewController: boolean = false;
  public editorController: boolean = false;
  public updateEquipmentTypeLoading: boolean = false;

  /** Variavel utilizada para manipulação de gateway selecionado, utilizado no modal de edição **/
  public isGatewaySelectedUpdate: boolean = false;

  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null)

  /** Variáveis utilizadas para manipulação do Paginator **/
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  /** Variáveis utilizadas na geração dos arquivos CSV e PDF**/
  public FILE_DATA: EquipmentType[] = [];
  public isReady: boolean = false;
  public fileLoading: boolean = false;

  // Manipula o nome das colunas da tabela
  public displayedColumns: string[] = ['reference', 'version', 'release-data', 'actions'];

  // Renderiza os comandos da lista ELEMENT_DATA para a tabela
  public dataSource = new MatTableDataSource<EquipmentType>(this.ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator

  // Variável que guarda o número de tipos de equipamentos retornados do backend.
  public resultsLength = this.paginationProperties.total;

  /** Função chamada toda vez que ocorre um evento no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.equipmentTypeFilter(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.equipmentTypeFilter(this.paginationProperties.endCursor)
    }
  }
  /**
* Função que configura o paginator e atualiza os valores da tabela
*/
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  // Função responsável por abrir e fechar a box de registro
  public registerEquipmentTypeOpen(): void {
    this.registerEquipmentType = !this.registerEquipmentType;
    this.filterEquipmentType = false;
  }

  // Função responsável por abrir e fechar a box de filtro
  public filterEquipmentTypeOpen(): void {
    this.filterEquipmentType = !this.filterEquipmentType;
    this.registerEquipmentType = false;
  }

  // Armazena os valores do formulário de criação de tipo de equipamento
  public equipmentTypeCreateForm: FormGroup = this.formBuilder.group({
    reference: [null, Validators.required],
    major: [null, Validators.required],
    minor: [null, Validators.required],
    revision: [null, Validators.required],
    description: [null, Validators.required],
    releaseDate: [null, Validators.required],
    hardware: [null, Validators.required],
    compatiblefirmware: ['', Validators.required],
    hasActuation: [false],
    hasClockSync: [false],
    hasPowerQuality: [false],
    oldCrc: [false],
  });

  // Armazena os valores do formulário de filtro
  public equipmentTypeFilterForm: FormGroup = this.formBuilder.group({
    reference: [null],
    major: [null],
    minor: [null],
    revision: [null]
  });

  // Armazena os valores do formulário de criação de hardawre e firmware
  public createHardwareAndFirmwareForm: FormGroup = this.formBuilder.group({
    referenceHardware: [null],
    majorHardware: [null],
    minorHardware: [null],
    revisionHardware: [null],
    releaseDateHardware: [null],
    descriptionHardware: [null],
    firmwaresCompatible: [''],
    referenceFirmware: [null],
    majorFirmware: [null],
    minorFirmware: [null],
    revisionFirmware: [null],
    releaseDateFirmware: [null],
    descriptionFirmware: [null]
  });

  /** Armazena os campos do formulário de edição de tipos de equipamentos **/
  public updateEquipmentTypeForm: FormGroup = this.formBuilder.group({
    id: [null],
    reference: [null],
    releaseDate: [null],
    description: [''],
    major: [null],
    minor: [null],
    revision: [null],
    hardwareSelected: [null],
    hasActuation: [false],
    hasClockSync: [false],
    hasPowerQuality: [false],
    oldCrc: [false],
    parameterReference: [],
    targetReferenceParameters: [],
    configCommandsChoicesDescriptionTrue: [],
    configCommandsChoicesDescriptionFalse: [],
    queryCommandsType: [],
    queryCommandsresponseProperties: [],
    maxValueConfigParameters: [],
    minValueConfigParameters: [],
    isGateway: [],
  })

  /** Método que realiza o filtro dos tipos de equipamentos **/
  public equipmentTypeFilter(cursor: string | null) {
    /** se o cursor for nulo  **/
    if (cursor == null) {
      this.pageIndex = 0;
      this.isReady = true;
    }

    /** Ativa o spinner de loading **/
    this.equipmentTypeLoading = true;

    this.subscriptions = this.equipmentTypeService.EquipmentTypeFilter(
      this.equipmentTypeFilterForm.get('reference')?.value,
      this.equipmentTypeFilterForm.get('major')?.value,
      this.equipmentTypeFilterForm.get('minor')?.value,
      this.equipmentTypeFilterForm.get('revision')?.value,
      cursor,
      this.pageSize
    ).valueChanges.subscribe({
      next: ((response: any) => {
        this.paginationProperties = new PaginationInfo(
          response.data?.equipmentType?.count,
          response.data?.equipmentType?.total,
          response.data?.equipmentType?.pageInfo?.hasNextPage,
          response.data?.equipmentType?.pageInfo?.hasPreviousPage,
          response.data?.equipmentType?.pageInfo?.startCursor,
          response.data?.equipmentType?.pageInfo?.endCursor
        )

        this.ELEMENT_DATA = [];

        this.length = this.paginationProperties.total

        response.data.equipmentType.edges.forEach((node: any) => {

          let firmwares: Firmware[] = [];

          node?.node?.equipmentParts?.edges[0]?.node?.firmwares?.edges?.forEach((firmware: any) => {
            firmwares.push(
              new Firmware(
                firmware?.node?.id,
                firmware?.node?.name,
                firmware?.node?.major,
                firmware?.node?.minor,
                firmware?.node?.revision,
                true
              )
            )
          });

          this.ELEMENT_DATA.push(
            new EquipmentType(
              node?.node?.id,
              node?.node?.reference,
              node?.node?.major,
              node?.node?.minor,
              node?.node?.revision,
              node?.node?.releaseDate,
              node?.node?.description,
              [new EquipmentParts(
                new Hardware(
                  node?.node?.equipmentParts?.edges[0]?.node?.hardware?.id,
                  node?.node?.equipmentParts?.edges[0]?.node?.hardware?.name,
                  node?.node?.equipmentParts?.edges[0]?.node?.hardware?.major,
                  node?.node?.equipmentParts?.edges[0]?.node?.hardware?.minor,
                  node?.node?.equipmentParts?.edges[0]?.node?.hardware?.revision
                ),
                firmwares,
              )],
              new CommandSheet(
                node?.node?.commandSheet?.id,
                node?.node?.commandSheet?.hasActuation,
                node?.node?.commandSheet?.hasClockSync,
                node?.node?.commandSheet?.hasPowerQuality,
                node?.node?.commandSheet?.oldCrc,
                new ConfigCommands(
                  node?.node?.commandSheet?.parameters?.edges[0]?.node?.reference,
                  node?.node?.commandSheet?.parameters?.edges[0]?.node?.targetReference,
                  node?.node?.commandSheet?.parameters?.edges[0]?.node?.type,
                  node?.node?.commandSheet?.parameters?.edges[0]?.node?.minValue,
                  node?.node?.commandSheet?.parameters?.edges[0]?.node?.maxValue,
                  [new ParameterTypeBool(
                    node?.node?.commandSheet?.parameters?.edges[0]?.node?.choices?.edges[0]?.node?.value,
                    node?.node?.commandSheet?.parameters?.edges[0]?.node?.choices?.edges[0]?.node?.description,
                  ),
                  new ParameterTypeBool(
                    node?.node?.commandSheet?.parameters?.edges[0]?.node?.choices?.edges[1]?.node?.value,
                    node?.node?.commandSheet?.parameters?.edges[0]?.node?.choices?.edges[1]?.node?.description,
                  )
                  ]
                ),
                new QueryCommands(
                  node?.node?.commandSheet?.queryCommands?.edges[0]?.node?.type,
                  node?.node?.commandSheet?.queryCommands?.edges[0]?.node?.responseProperties,
                )
              )
            ));
        });

        this.setDataSourceAttributes();

        this.isReady = false;

        this.equipmentTypeLoading = false;

        if (this.ELEMENT_DATA.length === 0) {
          /** Retorna alerta ao usuário **/
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });

          this.equipmentTypeLoading = false;
        }
      }),
      error: ((error: any) => {
        this.errorLibService.errorAlert(error);
        this.equipmentTypeLoading = false;

      })
    })
  }

  /** Método responsável pela criação de tipos de equipamentos **/
  public createEquipmentType() {

    // Ativa o loading do form de cadastro
    this.equipmentTypeCreateLoading = true;

    // Verifica se o tipo de equipamento é um Gateway
    if (this.isGatewaySelected) {
      // Se for gateway é feita uma verificação específica
      if (
        !this.equipmentTypeCreateForm.value.major ||
        !this.equipmentTypeCreateForm.value.minor ||
        !this.equipmentTypeCreateForm.value.revision ||
        !this.equipmentTypeCreateForm.value.releaseDate ||
        !this.equipmentTypeCreateForm.value.description
      ) {
        /* Retorna um aviso caso o usuário tenha
        deixado de preencher algum campo e encerra a aplicação */
        Swal.fire({
          title: 'Preencha todos os campos do formulário informações',
          icon: 'warning',
          confirmButtonText: 'Ok'
        });

        this.equipmentTypeCreateLoading = false;
        return
      }
      // Faz outra verificação de inclusão de hardware e firmware no equipamento
      else if (
        !this.equipmentTypeCreateForm.value.hardware ||
        this.equipmentTypeCreateForm.value.compatiblefirmware === ''
      ) {
        // Retorna um aviso caso um dos campos não tenha sido preechidos
        Swal.fire({
          title: 'Preencha todos os campos do formulário de Hardware e Firmware',
          icon: 'warning',
          confirmButtonText: 'Ok'
        });

        this.equipmentTypeCreateLoading = false;

        return
      }
      // Se passar em todas as verificações é feita a requisição p/ criar um tipo de equipamento
      else {
        this.subscriptions = this.equipmentTypeService.createEquipmentType(
          'Gateway',
          this.equipmentTypeCreateForm.value.major,
          this.equipmentTypeCreateForm.value.minor,
          this.equipmentTypeCreateForm.value.revision,
          this.equipmentTypeCreateForm.value.description,
          this.equipmentTypeCreateForm.value.releaseDate,
          this.equipmentTypeCreateForm.value.hardware,
          this.equipmentTypeCreateForm.value.compatiblefirmware,
          this.equipmentTypeCreateForm.value.hasActuation,
          this.equipmentTypeCreateForm.value.hasClockSync,
          false,
          false,
          this.queryCommands,
          this.configCommands
        ).subscribe({
          next: (() => {
            this.equipmentTypeFilterForm.patchValue({
              reference: this.equipmentTypeCreateForm.value.reference
            })

            this.equipmentTypeFilter(null);

            this.equipmentTypeFilterForm.patchValue({
              reference: null
            });

            Swal.fire({
              title: 'Tipo de equipamento criado',
              text: 'Tipo de equipamento criado com sucesso',
              icon: 'success',
              confirmButtonText: 'Ok'
            });

            this.equipmentTypeCreateLoading = false;
          })
        });
      }
    }
    //caso contrário inicia a validação para qualquer tipo de equipamento
    else {
      // Checa se todos os campos foram preechidos corretamente
      if (this.equipmentTypeCreateForm.valid) {

        // Se passar em todas as validações a requisição de criação de tipo de equipamento é enviada
        this.subscriptions = this.equipmentTypeService.createEquipmentType(
          this.equipmentTypeCreateForm.value.reference,
          this.equipmentTypeCreateForm.value.major,
          this.equipmentTypeCreateForm.value.minor,
          this.equipmentTypeCreateForm.value.revision,
          this.equipmentTypeCreateForm.value.description,
          this.equipmentTypeCreateForm.value.releaseDate,
          this.equipmentTypeCreateForm.value.hardware,
          this.equipmentTypeCreateForm.value.compatiblefirmware,
          this.equipmentTypeCreateForm.value.hasActuation,
          this.equipmentTypeCreateForm.value.hasClockSync,
          this.equipmentTypeCreateForm.value.hasPowerQuality,
          this.equipmentTypeCreateForm.value.oldCrc,
          this.queryCommands,
          this.configCommands
        ).subscribe({
          next: (() => {
            this.equipmentTypeFilterForm.patchValue({
              reference: this.equipmentTypeCreateForm.value.reference
            })

            this.equipmentTypeFilter(null)

            this.equipmentTypeFilterForm.patchValue({
              reference: null
            })

            Swal.fire({
              title: 'Tipo de equipamento criado',
              text: 'Tipo de equipamento criado com sucesso',
              icon: 'success',
              confirmButtonText: 'Ok'
            });

            this.equipmentTypeCreateLoading = false;
          })
        })
        this.equipmentTypeCreateForm.reset()
        this.queryCommands = [];
        this.configCommands = [];
      }
      // Verifica quais campos não foram preenchidos para indicar ao usuário
      else {
        // Caso os campos do formulário de informações estejam incompletos
        if (
          !this.equipmentTypeCreateForm.value.reference ||
          !this.equipmentTypeCreateForm.value.major ||
          !this.equipmentTypeCreateForm.value.minor ||
          !this.equipmentTypeCreateForm.value.revision ||
          !this.equipmentTypeCreateForm.value.releaseDate ||
          !this.equipmentTypeCreateForm.value.description
        ) {
          // Retorna um aviso de ao usuário e encerra a aplicação
          Swal.fire({
            title: 'Preecha todos os campos do formulário de informações',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });

          this.equipmentTypeCreateLoading = false;
          return
        }
        //Caso o formulário de hardware e firmware esteja incompleto
        else if (
          !this.equipmentTypeCreateForm.value.hardware ||
          this.equipmentTypeCreateForm.value.compatiblefirmware === ''
        ) {
          // Retorna um aviso de ao usuário e encerra a aplicação
          Swal.fire({
            title: 'Preencha todos os campos do formulário de hardware e firmware',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
          this.equipmentTypeCreateLoading = false;
          return

        } else if (
          !this.equipmentTypeCreateForm.value.hasPowerQuality ||
          !this.equipmentTypeCreateForm.value.oldCrc ||
          !this.equipmentTypeCreateForm.value.hasActuation ||
          !this.equipmentTypeCreateForm.value.hasClockSync
        ) {
          // Retorna um aviso de ao usuário e encerra a aplicação
          Swal.fire({
            title: 'Preencha todos os campos do formulário comandos',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
          this.equipmentTypeCreateLoading = false;
          return
        }
      }
    }
  }

  /* Função abre a modal de comandos de consulta e envia para este componente
  os comandos solicitados pelo usuário(funcionalidade da box de registro e edição de tipos de equipamentos) */
  public openModalQueryCommands(): void {
    const dialogRef = this.dialog.open(QueryCommandsModalComponent, {
      data: {}, disableClose: true
    });

    /**  Assina o observable commands$ para receber dados atualizados **/
    const sub = dialogRef.componentInstance.commands$.subscribe(result => {
      if (result) {
        if (result[0]) {
          this.queryCommands.push(result[0]);
        }
      }
    });

    /** Adiciona na lista de subscrições para evitar vazamento de memória **/
    this.subscriptions.add(sub);
  }

  /* Função abre a modal de comandos de configuração e envia para este componente
 os comandos solicitados pelo usuário(funcionalidade da box de registro e edição de tipos de equipamentos) */
  public openModalConfigCommands(): void {
    const dialogRef = this.dialog.open(ConfigCommandsModalComponent, {
      data: {}, disableClose: true
    });

    /**  Assina o observable commands$ para receber dados atualizados **/
    const sub = dialogRef.componentInstance.commands$.subscribe(result => {
      if (result) {
        if (result[0]) {
          this.configCommands.push(result[0]);
        }
      }
    });

    /** Adiciona na lista de subscrições para evitar vazamento de memória **/
    this.subscriptions.add(sub);
  }

  /** Abre o modal de comandos de consulta permitindo a edição do comando
   * ao clicar no botão de edição de cada comando existente.
   * (utilizado na criação e edição de tipos de equipamentos) **/
  public editQueryCommands(index: number): void {
    const commandToEdit = this.queryCommands[index];
    const dialogRef = this.dialog.open(QueryCommandsModalComponent, {
      data: { command: commandToEdit },
      disableClose: true
    });

    dialogRef.componentInstance.commands$.subscribe(result => {
      if (result) {
        this.queryCommands[index] = result[0] || this.queryCommands[index];
      }
    });
  }

  /** Abre o modal de comandos de configuração permitindo a edição do comando
    * ao clicar no botão de edição de cada comando existente.
    * (utilizado na criação e edição de tipos de equipamentos) **/
  public editConfigCommands(index: number): void {
    const commandToEdit = this.configCommands[index];

    const dialogRef = this.dialog.open(ConfigCommandsModalComponent, {
      data: { command: commandToEdit },
      disableClose: true
    });

    /** Subscrição do observable commands$ ao componente do modal  **/
    dialogRef.componentInstance.commands$.subscribe(result => {
      /** Verifica se retornou algum dado **/
      if (result) {
        /** Através do indice do comando selecionado, atualiza o comando com o novo valor. **/
        this.configCommands[index] = result[0] || this.configCommands[index];
      }
    });
  }

  /** Remove o index do elemento selecionado na lista de comandos de consulta.
   * (utilizado na criação e edição de tipos de equipamentos) **/
  public clearQueryCommandsByIndex(index: number) {
    /** Verificação do indice atual percorrendo ao total da lista **/
    if (index > -1 && index < this.queryCommands.length) {
      /** Removendo o indice selecionado da lista **/
      this.queryCommands.splice(index, 1);
    }
  }

  /** Remove o index do elemento selecionado na lista de comandos de configuração.
   *  (utilizado na criação e edição de tipos de equipamentos)**/
  public clearConfigCommandsByIndex(index: number) {
    /** Verificação do indice atual percorrendo ao total da lista **/
    if (index > -1 && index < this.configCommands.length) {
      /** Removendo o indice selecionado da lista **/
      this.configCommands.splice(index, 1);
    }
  }

  /** Método utilizado para realizar a manipulação do checkbox 
   * de Sincronia com clock. (utilizado na criação e edição de tipos de equipamentos)
  * **/
  public hasClockSyncChecked(checked: boolean) {
    if (checked) {
      this.equipmentTypeCreateForm.value.hasClockSync = true;
      this.updateEquipmentTypeForm.value.hasClockSync = true;

    }
    else {
      this.equipmentTypeCreateForm.value.hasClockSync = false;
      this.updateEquipmentTypeForm.value.hasClockSync = false;
    }
  }

  /** Método utilizado para realizar a manipulação do checkbox 
 * de atuação. (utilizado na criação e edição de tipos de equipamentos)
* **/
  public hasActuationChecked(checked: boolean) {
    if (checked) {
      this.equipmentTypeCreateForm.value.hasActuation = true;
      this.updateEquipmentTypeForm.value.hasActuation = true;
    }
    else {
      this.equipmentTypeCreateForm.value.hasActuation = false;
      this.updateEquipmentTypeForm.value.hasActuation = false;
    }
  }

  /** Método utilizado para realizar a manipulação do checkbox 
 * de Qualidade de energia. (utilizado na criação e edição de tipos de equipamentos)
* **/
  public hasPowerQualityChecked(checked: boolean) {
    if (checked) {
      this.equipmentTypeCreateForm.value.hasPowerQuality = true;
      this.updateEquipmentTypeForm.value.hasPowerQuality = true;
    }
    else {
      this.equipmentTypeCreateForm.value.hasPowerQuality = false;
      this.updateEquipmentTypeForm.value.hasPowerQuality = false;
    }
  }

  /** Método utilizado para realizar a manipulação do checkbox 
 * de CRC antigo. (utilizado na criação e edição de tipos de equipamentos)
* **/
  public oldCrcChecked(checked: boolean) {
    if (checked) {
      this.equipmentTypeCreateForm.value.oldCrc = true;
      this.updateEquipmentTypeForm.value.oldCrc = true;
    }
    else {
      this.equipmentTypeCreateForm.value.oldCrc = false;
      this.updateEquipmentTypeForm.value.oldCrc = false;
    }
  }

  /* Método responsável por verificar se o tipo de equipamento é gateway,
   desabilitar e alterar valor do campo referência */
  public isGateway(checked: boolean) {
    // Se for gateway
    if (checked) {
      //Altera o valor da variavel que será utilizada como verificadora
      this.isGatewaySelected = true;

      //desabilita o campo referência
      this.equipmentTypeCreateForm.controls['reference'].disable();

      //Altera o valor do campo referência
      this.equipmentTypeCreateForm.controls['reference'].setValue('Gateway');

      // Limpa a lista de consulta de comandos
      this.queryCommands = [];

      // Limpa a lista de parametros de comandos
      this.configCommands = [];
    }
    // Se não for gateway
    else {
      //Altera o valor da variavel que será utilizada como verificadora
      this.isGatewaySelected = false;

      // Habilita o campo referência
      this.equipmentTypeCreateForm.controls['reference'].enable();

      // reseta o valor do campo referência
      this.equipmentTypeCreateForm.controls['reference'].reset();
    }
  }

  /** Método responsável por criar um novo firmware (utilizado na criação e edição de tipos de equipamentos) **/
  public createFirmware() {

    this.firmwareCreateLoading = true

    // faz a validação dos campos
    if (
      !this.createHardwareAndFirmwareForm.value.referenceFirmware ||
      !this.createHardwareAndFirmwareForm.value.majorFirmware ||
      !this.createHardwareAndFirmwareForm.value.minorFirmware ||
      !this.createHardwareAndFirmwareForm.value.revisionFirmware ||
      !this.createHardwareAndFirmwareForm.value.releaseDateFirmware ||
      !this.createHardwareAndFirmwareForm.value.descriptionFirmware
    ) {
      // Retorna um aviso ao usuário caso o formulário não passe na validação
      Swal.fire({
        title: 'Preecha todos os campos do formulário para criar um novo firmware',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
      return
    }
    // Se todos os campos estiverem preenchidos corretamente a requisição é enviada
    else {
      this.subscriptions = this.equipmentTypeService.createFirmware(
        this.createHardwareAndFirmwareForm.value.referenceFirmware,
        this.createHardwareAndFirmwareForm.value.majorFirmware,
        this.createHardwareAndFirmwareForm.value.minorFirmware,
        this.createHardwareAndFirmwareForm.value.revisionFirmware,
        this.createHardwareAndFirmwareForm.value.descriptionFirmware,
        this.createHardwareAndFirmwareForm.value.releaseDateFirmware
      ).subscribe({
        next: (() => {
          this.refreshFirmware()

          Swal.fire({
            title: 'Firmware criado',
            text: 'Firmware criado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          this.firmwareCreateLoading = false;

        })
      })
      this.createHardwareAndFirmwareForm.reset();
    }
  }

  /**  Método responsável por criar um novo hardware (utilizado na criação e edição de tipos de equipamentos) **/
  public createHardware() {
    // Faz a validação dos campos
    if (
      !this.createHardwareAndFirmwareForm.value.referenceHardware ||
      !this.createHardwareAndFirmwareForm.value.majorHardware ||
      !this.createHardwareAndFirmwareForm.value.minorHardware ||
      !this.createHardwareAndFirmwareForm.value.revisionHardware ||
      !this.createHardwareAndFirmwareForm.value.releaseDateHardware ||
      !this.createHardwareAndFirmwareForm.value.descriptionHardware ||
      this.createHardwareAndFirmwareForm.value.firmwaresCompatible === ''
    ) {
      // Retorna um aviso ao usuário caso um dos campos não tenha sido preechido corretamente
      Swal.fire({
        title: 'Preecha todos os campos do formulário para criar um novo hardware',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
      return
    }
    // Caso contrário a requisição é enviada
    else {
      this.subscriptions = this.equipmentTypeService.createHardware(
        this.createHardwareAndFirmwareForm.value.referenceHardware,
        this.createHardwareAndFirmwareForm.value.majorHardware,
        this.createHardwareAndFirmwareForm.value.minorHardware,
        this.createHardwareAndFirmwareForm.value.revisionHardware,
        this.createHardwareAndFirmwareForm.value.descriptionHardware,
        this.createHardwareAndFirmwareForm.value.releaseDateHardware,
        this.createHardwareAndFirmwareForm.value.firmwaresCompatible
      ).subscribe({
        next: (() => {

          this.getHardwares()

          Swal.fire({
            title: 'Hardware criado',
            text: 'Hardware criado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          this.hardwareCreateLoading = false;

        })
      })
      this.createHardwareAndFirmwareForm.reset();
    }
  }

  /** Método que filtra os firmwares **/
  public refreshFirmware() {
    // this.firmwaresList = []
    /** Filtra todos os hardwares para popular a lista que preenche o select **/
    this.subscriptions = this.equipmentTypeService.filterFirmware(
    ).valueChanges.subscribe({
      next: ((response: any) => {
        response.data.firmware.edges.forEach((firmwares: any) => {
          this.firmwaresList.push(
            new Firmware(
              firmwares.node.id,
              firmwares.node.name,
              firmwares.node.major,
              firmwares.node.minor,
              firmwares.node.revision,
            )
          )
        })
      })
    })
  }

  /** Método que filtra os hardwares **/
  public getHardwares(): any {
    this.subscriptions = this.equipmentTypeService.filterHardware().valueChanges.subscribe({
      next: ((response: any) => {
        response.data.hardware.edges.forEach((node: any) => {
          this.hardwaresList.push(new Hardware(
            node.node.id,
            node.node.name,
            node.node.major,
            node.node.minor,
            node.node.revision
          ))
        });

        if (this.hardwaresList.length === 0) {
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        }
      }),
      error: ((error: any) => {
        console.log("getHardwaresError", error);
        this.errorLibService.errorAlert(error);
      })
    })
  }

  /** Filtra os firmwares compativeis com o hardware selecionado pelo usuário (utilizado na criação e update de tipos de equipamentos) **/
  public filterCompatibleFirmware(id: string | null): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.subscriptions = this.equipmentTypeService.filterCompatibleFirmware(id).valueChanges.subscribe({
        next: ((res: any) => {
          this.compatiblefirmwaresList = res.data.node.compatibleFirmware.edges.map((node: any) =>
            new Firmware(
              node?.node?.id,
              node?.node?.name,
              node?.node?.major,
              node?.node?.minor,
              node?.node?.revision,
              false
            ));
          resolve();
        }),
        error: (err: any) => {
          reject(err);
          this.errorLibService.errorAlert(err);
        }
      });
    });
  }

  /** Método responsável por verificar se já existe algum firmware dentro da lista
   *  de firmwares compativeis que já está selecionado (utilizado apenas na edição de tipos de equipamentos) **/
  public updateCompatibleFirmwareSelected(equipmentType: EquipmentType) {

    /** Verifica por toda lista de firmwares compativeis **/
    this.compatiblefirmwaresList.forEach((compatibleFirmware) => {
      /** Verifica por todos os firmwares já existentes no tipo de equipamento**/
      equipmentType.equipmentParts.forEach(element => {
        element.firmwares.forEach((compatibleFirmwareEquipment: any) => {
          /** Compara se possuem o mesmo id (ou seja, se o firmware existe na lista de firmwares compativeis)**/
          if (compatibleFirmware.id === compatibleFirmwareEquipment.id) {
            /** Marca o firmware no checkbox */
            compatibleFirmware.checked = true;
          }
        });
      });
    });
  }

  /** Método responsável por abrir o modal de edição e 
   * preencher com os dadis já existentes**/
  public openSidebarUpdate(isViewing: any, element: any) {

    this.viewController = isViewing;

    /** Abre o modal de edição **/
    this.editorController = true;

    /** Caso o usuário clique no botão de visualização do tipo de equipamento **/
    if (isViewing === true) {
      /** Desabilita a edição dos campos **/
      this.updateEquipmentTypeForm.disable();
    }

    /** Caso clique no botão de edição **/
    else {
      /** Permite a edição dos campos **/
      this.updateEquipmentTypeForm.enable();
    }

    /** Associação dos dados a todos os campos do formulário existente no modal de edição de tipos de equipamentos **/
    this.updateEquipmentTypeForm.get('id')?.setValue(element?.id);
    this.updateEquipmentTypeForm.get('description')?.setValue(element?.description);
    this.updateEquipmentTypeForm.get('releaseDate')?.setValue(element?.releaseDate);
    this.updateEquipmentTypeForm.get('reference')?.setValue(element?.reference);
    this.updateEquipmentTypeForm.get('major')?.setValue(element?.major);
    this.updateEquipmentTypeForm.get('minor')?.setValue(element?.minor);
    this.updateEquipmentTypeForm.get('revision')?.setValue(element?.revision);
    this.updateEquipmentTypeForm.get('hardwareSelected')?.setValue(element?.equipmentParts[0]?.hardware?.id);
    this.updateEquipmentTypeForm.get('hasActuation')?.setValue(element?.commandSheet?.hasActuation);
    this.updateEquipmentTypeForm.get('hasClockSync')?.setValue(element?.commandSheet?.hasClockSync);
    this.updateEquipmentTypeForm.get('hasPowerQuality')?.setValue(element?.commandSheet?.hasPowerQuality);
    this.updateEquipmentTypeForm.get('oldCrc')?.setValue(element?.commandSheet?.oldCrc);

    /** Campos relacionados aos comandos **/
    this.updateEquipmentTypeForm.get('queryCommandsType')?.setValue(element?.commandSheet?.queryCommands?.type);
    this.updateEquipmentTypeForm.get('queryCommandsresponseProperties')?.setValue(element?.commandSheet?.queryCommands?.responseProperties);
    this.updateEquipmentTypeForm.get('parameterReference')?.setValue(element?.commandSheet?.parameters?.reference);
    this.updateEquipmentTypeForm.get('configCommandsChoicesDescriptionTrue')?.setValue(element?.commandSheet?.parameters?.choices[0]?.description);
    this.updateEquipmentTypeForm.get('configCommandsChoicesDescriptionFalse')?.setValue(element?.commandSheet?.parameters?.choices[1]?.description);
    this.updateEquipmentTypeForm.get('maxValueConfigParameters')?.setValue(element?.commandSheet?.parameters?.maxValue);
    this.updateEquipmentTypeForm.get('minValueConfigParameters')?.setValue(element?.commandSheet?.parameters?.minValue);

    /** Filtra os firmwares compátives passando o id do hardware existente no tipo de equipamento selecionado **/
    this.filterCompatibleFirmware(element?.equipmentParts[0]?.hardware?.id).then(() => {
      /** Realiza a atualização do firmware compátivel **/
      this.updateCompatibleFirmwareSelected(element);
    });

    /** Criado uma constante para armazenar os valores de todos os campos relacionado aos comandos
     * (apenas para legibilidade do código) **/
    const queryCommandsType = element?.commandSheet?.queryCommands?.type;
    const queryCommandsResponseProperties = element?.commandSheet?.queryCommands?.responseProperties;
    const parameterReference = element?.commandSheet?.parameters?.reference;
    const commandChoiceTrue = element?.commandSheet?.parameters?.choices[0]?.description;
    const commandChoiceFalse = element?.commandSheet?.parameters?.choices[1]?.description;
    const commandChoiceValueTrue = element?.commandSheet?.parameters?.choices[0]?.value;
    const commandChoiceValueFalse = element?.commandSheet?.parameters?.choices[1]?.value
    const minValueConfigParameters = element?.commandSheet?.parameters?.minValue;
    const maxValueConfigParameters = element?.commandSheet?.parameters?.minValue;

    /** Constante que armazena todas as informações relacionadas aos comandos de consulta **/
    const queryCommands = new QueryCommands(
      queryCommandsType,
      queryCommandsResponseProperties
    )

    /** Constante que armazena todos os dados relacionados aos comandos de configuração **/
    const configCommands = new ConfigCommands(
      parameterReference ? parameterReference : null,
      element?.commandSheet?.parameters?.targetReference ? element?.commandSheet?.parameters?.targetReference : null,
      element?.commandSheet?.parameters?.type ? element?.commandSheet?.parameters?.type : null,
      minValueConfigParameters ? minValueConfigParameters : null,
      maxValueConfigParameters ? maxValueConfigParameters : null,
      [
        { value: commandChoiceValueTrue ? commandChoiceValueTrue : "", description: commandChoiceTrue ? commandChoiceTrue : "" },
        { value: commandChoiceValueFalse ? commandChoiceValueFalse : "", description: commandChoiceFalse ? commandChoiceFalse : "" }
      ]
    );

    /** Caso existam dados relacionados os comandos de consulta **/
    if (queryCommands.responseProperties && queryCommands.type) {

      /** Adiciona os dados na lista de comandos de consulta que será enviado no update 
       * (essa lista contem todos os comandos de consulta existente no tipo de componente selecionado)**/
      this.queryCommands.push(queryCommands);
    }

    /** Caso existam dados relacionados aos comandos de configuração **/
    if (configCommands.reference && configCommands.targetReference && configCommands.type) {

      /** Adiciona os dados na lista de comandos de configuração que será enviado no update 
       * (essa lista contem todos os comandos de configuração existente no tipo de componente selecionado)**/
      this.configCommands.push(configCommands);
    }

    /** Utilizado para fechar o modal de criação de tipo de equipamento caso esteja aberto
     * (utilizado somente para evitar confusão na visualização dos comandos, já que para a criação e update está sendo utilizado a mesma lista) **/
    this.registerEquipmentType = false;
  }

  /** Método que fecha o modal de edição **/
  public closeSidebarUpdate() {

    /** Fecha o sidebar de edição **/
    this.editorController = false;
    this.viewController = false;

    /** Limpa as listas dos comandos de consulta e configuração **/
    this.queryCommands = [];
    this.configCommands = [];
  }

  /** Método que realiza a validação de quando o checkbox dos firmwares 
* é marcado ou desmarcado (utilizado na edição de tipos de equipamento) **/
  public checkFirmwareUpdate(firmware: Firmware) {
    /** Sempre que ocorrer o evento de checkbox o valor passa a ser invertido (podendo ser marcado e desmarcado) **/
    firmware.checked = !firmware.checked;
  }

  /** Método responsável pela manipulação do checkbox quando todos os firmwares compativeis são marcados ou desmarcados 
   * (utilizado na edição de tipos de equipamentos) **/
  public checkAllCompatiblesFirmwares(event: any) {
    /** Caso todos os firmwares sejam marcados **/
    if (event.selected) {
      this.compatiblefirmwaresList.forEach((firmware) => {
        /**para cada item na lista valor do checkbox recebe true **/
        firmware.checked = true;
      });
      /** Caso todos os firmwares compativeis sejam desmarcados **/
    } else {
      this.compatiblefirmwaresList.forEach((firmware) => {
        /** valor do checkbox recebe false **/
        firmware.checked = false;
      });
    }
  }

  /** Método utilizado para manipular quando o checkbox de gateway for marcado
   * utilizado no modal de edição.
   * **/
  public isGatewayCkeckedUpdate(checked: any) {
    if (checked) {
      this.isGatewaySelectedUpdate = true;

      /** Oculta o campo de referência**/
      this.updateEquipmentTypeForm.controls['reference'].disable();
    }

    else {
      /** Exibe o campo de referência **/
      this.isGatewaySelectedUpdate = false;
      this.updateEquipmentTypeForm.controls['reference'].enable();
    }
  }

  /** Método que realiza a atualização dos tipos de equipamentos **/
  public updateEquipmentType() {

    /** Ativa o loading na tela **/
    this.updateEquipmentTypeLoading = true;

    /** Variável que armazena os firmwares checados **/
    const compatibleFirmwares: any[] = [];

    /** Adiciona o id do(s) firmware(s) selecionado(s) **/
    this.compatiblefirmwaresList.forEach((firmwares) => {
      if (firmwares.checked) {
        compatibleFirmwares.push(firmwares.id)
      }
    });

    /**  Caso todos os campos estejam devidamente preenchidos **/
    this.subscriptions = this.equipmentTypeService.updateEquipmentType(
      this.updateEquipmentTypeForm?.value?.id,
      this.updateEquipmentTypeForm?.value?.description,
      this.updateEquipmentTypeForm?.value?.releaseDate,
      this.updateEquipmentTypeForm?.value?.hardwareSelected,
      compatibleFirmwares,
      this.updateEquipmentTypeForm?.value?.hasActuation,
      this.updateEquipmentTypeForm?.value?.hasClockSync,
      this.updateEquipmentTypeForm?.value?.hasPowerQuality,
      this.updateEquipmentTypeForm?.value?.oldCrc,
      this.queryCommands,
      this.configCommands
    ).subscribe({
      next: ((node: any) => {

        /** Exibe mensagem de sucesso ao usuário **/
        Swal.fire({
          title: 'Tipo de equipamento atualizado',
          text: 'Tipo de equipamento atualizado com sucesso',
          icon: 'success',
          confirmButtonText: 'Ok'
        })

        this.equipmentTypeFilterForm.patchValue({
          reference: this.updateEquipmentTypeForm.value.reference
        })

        this.equipmentTypeFilter(null);

        this.equipmentTypeFilterForm.patchValue({
          reference: null
        });

        let firmwareList: Firmware[] = [];

        node?.data?.equipmentTypeUpdate?.equipmentType?.equipmentParts?.edges[0]?.node.firmwares.edges.forEach((firmware: any) => {
          firmwareList.push(
            new Firmware(
              firmware?.node?.id,
              firmware?.node?.name,
              firmware?.node?.major,
              firmware?.node?.minor,
              firmware?.node?.revision
            )
          )
        })

        this.ELEMENT_DATA = [
          new EquipmentType(
            node?.data?.equipmentTypeUpdate?.equipmentType?.id,
            node?.data?.equipmentTypeUpdate?.equipmentType?.reference,
            node?.data?.equipmentTypeUpdate?.equipmentType?.major,
            node?.data?.equipmentTypeUpdate?.equipmentType?.minor,
            node?.data?.equipmentTypeUpdate?.equipmentType?.revision,
            node?.data?.equipmentTypeUpdate?.equipmentType?.releaseDate,
            node?.data?.equipmentTypeUpdate?.equipmentType?.description,
            [new EquipmentParts(
              new Hardware(
                node?.data?.equipmentTypeUpdate?.equipmentType?.equipmentParts?.edges[0]?.node?.hardware?.id,
                node?.data?.equipmentTypeUpdate?.equipmentType?.equipmentParts?.edges[0]?.node?.hardware?.name,
                node?.data?.equipmentTypeUpdate?.equipmentType?.equipmentParts?.edges[0]?.node?.hardware?.major,
                node?.data?.equipmentTypeUpdate?.equipmentType?.equipmentParts?.edges[0]?.node?.hardware?.minor,
                node?.data?.equipmentTypeUpdate?.equipmentType?.equipmentParts?.edges[0]?.node?.hardware?.revision
              ),
              firmwareList,
            )],
            new CommandSheet(
              node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.id,
              node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.hasActuation,
              node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.hasClockSync,
              node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.hasPowerQuality,
              node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.oldCrc,
              new ConfigCommands(
                node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.parameters?.edges[0]?.node?.reference,
                node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.parameters?.edges[0]?.node?.targetReference,
                node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.parameters?.edges[0]?.node?.type,
                node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.parameters?.edges[0]?.node?.minValue,
                node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.parameters?.edges[0]?.node?.maxValue,
                [new ParameterTypeBool(
                  node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.parameters?.edges[0]?.node?.choices?.edges[0]?.node?.value,
                  node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.parameters?.edges[0]?.node?.choices?.edges[0]?.node?.description,
                ),
                new ParameterTypeBool(
                  node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.parameters?.edges[0]?.node?.choices?.edges[1]?.node?.value,
                  node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.parameters?.edges[0]?.node?.choices?.edges[1]?.node?.description,
                )
                ]
              ),
              new QueryCommands(
                node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.queryCommands?.edges[0]?.node?.type,
                node?.data?.equipmentTypeUpdate?.equipmentType?.commandSheet?.queryCommands?.edges[0]?.node?.responseProperties,
              )
            )
          )
        ];

        /** Atualiza a tabela **/
        this.setDataSourceAttributes();

        /** Desativa o loading na tela **/
        this.updateEquipmentTypeLoading = false;

        /** Fecha o sidebar de edição **/
        this.editorController = false;
        this.viewController = false;

        /** Limpa as listas dos comandos **/
        this.queryCommands = [];
        this.configCommands = [];
      }),
      error: ((error: any) => {
        this.errorLibService.errorAlert(error);
        console.log("UpdateEquipmentTypeError", error),
          this.updateEquipmentTypeLoading = false;
      })
    })
  }

  /** Método que realiza a conversão para arquivo em CSV **/
  public convertCSV(arr: any) {
    const content = [Object.keys(arr[0])].concat(arr);

    return content.map((item) => {
      return Object.values(item).toString().replace(',', ';');
    })
      .join('\n')
  }

  /** Método que realiza o download do arquivo em CSV **/
  public downloadCSV() {

    let content: any = [];

    this.FILE_DATA.forEach((element) => {
      content.push({
        'Referência': element.reference,
        'Versão': `${element.major}${element?.major === '' ? '' : '.'}${element.minor}${element?.minor === '' ? '' : '.'}${element.revision}`,
        'Data da release': element.releaseDate
      });
    });

    let data = this.convertCSV(content);
    /**  Transforma o conteúdo para o formato blob para geração do arquivo. **/
    let file = new Blob([data], { type: 'text/csv' });
    /** Cria um endereçamento para o arquivo. **/
    let url = window.URL.createObjectURL(file);
    /** Cria um elemento para a linkagem do arquivo. **/
    let link = document.createElement('a');
    /** Adiciona a url no elemento de linkagem. **/
    link.href = url;
    /**  Nomeia o arquivo que será feito o download. **/
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-EquipmentTyp.csv`;
    /** Adiciona o link no documento. **/
    document.body.appendChild(link);
    /** Atica o evento de click para realizar o download. **/
    link.click();
    /** Remove o link do documento. **/
    document.body.removeChild(link);
    /**  Remove o endereço do download. **/
    window.URL.revokeObjectURL(url);
    /** Limpa a lista que contém as informações já baixadas pelo usuário
    * Para que o usuário não baixe o mesmo arquivo sempre
    * **/
    this.FILE_DATA = [];
    this.isReady = false;
  }

  /** Método que realiza o download do arquivo em PDF **/
  public downloadPDF() {
    const tableLayout = [
      "Referência",
      "Versão",
      "Data de release"
    ];

    let content: any = [];

    this.FILE_DATA.forEach((element) => {
      content.push([
        element?.reference,
        `${element.major}${element?.major === '' ? '' : '.'}${element.minor}${element?.minor === '' ? '' : '.'}${element.revision}`,
        element.releaseDate
      ]);
    });

    const orientation = 'l'

    const doc: any = new jsPDF(orientation, "mm", "a4");

    doc.autoTable({
      theme: "grid",
      margin: { top: 20 },
      head: [tableLayout],
      body: content
    });

    /** Baixa o arquivo para o usuário, definindo o nome com a data de download **/
    doc.save(`${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-EquipmentType.pdf`);
    /** Limpa a lista que contém as informações já baixadas pelo usuário
     * Para que o usuário não baixe o mesmo arquivo sempre
     * **/
    this.FILE_DATA = [];
    this.isReady = false;
  }

  /*** Método responsável por realizar a geração dos arquivos **/
  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    if (this.isReady == false) {
      this.subscriptions = this.equipmentTypeService.EquipmentTypeFilter(
        this.equipmentTypeFilterForm.get('reference')?.value,
        this.equipmentTypeFilterForm.get('major')?.value,
        this.equipmentTypeFilterForm.get('minor')?.value,
        this.equipmentTypeFilterForm.get('revision')?.value,
        cursor,
        100
      ).valueChanges.subscribe({
        next: ((response: any) => {
          response.data.equipmentType.edges.forEach((node: any) => {

            this.FILE_DATA.push(
              new EquipmentType(
                node?.node?.id,
                node.node.reference,
                node.node.major,
                node.node.minor,
                node.node.revision,
                node.node.releaseDate,
                node.node.description,
                [],
                new CommandSheet(
                  node?.node?.commandSheet?.id,
                  node?.node?.commandSheet?.hasActuation,
                  node?.node?.commandSheet?.hasClockSync,
                  node?.node?.commandSheet?.hasPowerQuality,
                  node?.node?.commandSheet?.oldCrc,
                  new ConfigCommands(
                    node?.node?.commandSheet?.parameters?.edges[0]?.node?.reference,
                    node?.node?.commandSheet?.parameters?.edges[0]?.node?.targetReference,
                    node?.node?.commandSheet?.parameters?.edges[0]?.node?.type,
                    node?.node?.commandSheet?.parameters?.edges[0]?.node?.minValue,
                    node?.node?.commandSheet?.parameters?.edges[0]?.node?.maxValue,
                    [new ParameterTypeBool(
                      node?.node?.commandSheet?.parameters?.edges[0]?.node?.choices?.edges[0]?.node?.value,
                      node?.node?.commandSheet?.parameters?.edges[0]?.node?.choices?.edges[0]?.node?.description,
                    ),
                    new ParameterTypeBool(
                      node?.node?.commandSheet?.parameters?.edges[0]?.node?.choices?.edges[1]?.node?.value,
                      node?.node?.commandSheet?.parameters?.edges[0]?.node?.choices?.edges[1]?.node?.description,
                    )
                    ]
                  ),
                  new QueryCommands(
                    node?.node?.commandSheet?.queryCommands?.edges[0]?.node?.type,
                    node?.node?.commandSheet?.queryCommands?.edges[0]?.node?.responseProperties,
                  )
                )
              ));
          });

          if (!response.data?.equipmentType?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.equipmentType?.pageInfo?.endCursor, fileType);
        }),

        error: ((error: any) => {
          console.log("generateFilteDataError", error);
          this.errorLibService.errorAlert(error);
          this.fileLoading = false;
        })
      });
    }

    else {
      this.fileLoading = false;
      if (fileType === 'PDF') {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }
}
