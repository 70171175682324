import { NgModule } from '@angular/core';
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
  StoreModule,
} from '@ngrx/store';
import * as fromLogin from './components/login/ngrx/login.reducer';

export const FEATURE_KEY = 'shared-login';

/**
 * State Shape
 **/
export interface State {
  login: fromLogin.State;
}

export const reducers: ActionReducerMap<State> = {
  login: fromLogin.reducer,
};

export const metaReducers: MetaReducer<State>[] = [];

/**
 * Module
 **/
@NgModule({
  imports: [StoreModule.forFeature(FEATURE_KEY, reducers, { metaReducers })],
})
export class SharedStateLoginModule { }

/**
 * Feature Selector
 **/
export const selectSharedLoginState = createFeatureSelector<State>(FEATURE_KEY);

/**
 * Login Selectors
 */
export const selectLoginState = createSelector(
  selectSharedLoginState,
  (sharedLoginFeatureState) => sharedLoginFeatureState.login
);
export const getLoginData = createSelector(
  selectLoginState,
  fromLogin.getLoginData
);
export const getLoggedUserId = createSelector(
  selectLoginState,
  fromLogin.getLoggedUserId
);
export const getToken = createSelector(
  selectLoginState,
  fromLogin.getToken
);
export const getError = createSelector(
  selectLoginState,
  fromLogin.getError
);
export const getLoading = createSelector(
  selectLoginState,
  fromLogin.getLoading
)