import { Injectable } from '@angular/core';
import { AuthInterceptor } from 'src/app/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor() { }

  isLoggedIn(): boolean {
    return (localStorage.getItem('token') != null && localStorage.getItem('userId') != null);
  }

  canAudit(): boolean{
    let companySelected = localStorage.getItem('lastCompanySelected');
    return(companySelected === "Q29tcGFueToyMA==" || companySelected === "Q29tcGFueTo5MQ==")
  }

}
