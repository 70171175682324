import { gql } from "apollo-angular";

const FILTER_PERMISSIONS_GROUP = gql`
query AvailableGroups(
	$after: String
	$before: String
	$name: String
	$sort_dir: Direction!
	$sort_field: GroupOrdenationFields!
	$first: Int
	$last: Int
) {
	availableGroups(
		after: $after
		before: $before
		name: $name
		first: $first,
		last: $last
		orderBy: { direction: $sort_dir, field: $sort_field }
	) {
		edges {
			node {
				id
				name
				userSet {
					count
				}
				permissions {
					edges{
						node{
							id
							name
							codename
						}
					}
				}
			}
		}
		total
		count
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
	}
}`;

const CREATE_PERMISSIONS_GROUP = gql`
mutation GroupCreate($name: String!) {
	groupCreate(input: { name: $name }) {
		group {
			id
		}
	}
}`;

const GROUP_SET_PERMISSIONS = gql`
mutation GroupSetPermission($groupId: ID!, $permissionIds: [ID]!) {
	groupSetPermission(
		input: { groupId: $groupId, permissionIds: $permissionIds }
	) {
		group {
			id
		}
	}
}`;

const GET_AVAILABLE_PERMISSIONS = gql`
query AvailablePermissions {
	availablePermissions(orderBy: { direction: ASC, field: NAME }) {
		edges {
			node {
				id
				name
				codename
			}
		}
	}
}`;

const UPDATE_PERMISSIONS_GROUP = gql`
mutation GroupUpdate($id: ID!, $name: String!) {
	groupUpdate(input: { id: $id, name: $name }) {
		group {
			id
		}
	}
}`;

const DELETE_PERMISSIONS_GROUP = gql`
mutation Delete(
    $id: ID!
){
    groupDelete(input: {
        id: $id
    }){
        message
    }
}`;

const SET_PERMISSION = gql`
mutation setPermission($userId: ID!, $permissionIds: [ID]!) {
	permissionSet(input: { userId: $userId, permissionIds: $permissionIds }) {
		user {
			id
			userPermissions {
				edges {
					node {
						id
						name
						codename
					}
				}
			}
		}
	}
}`;

const REMOVE_PERMISSION = gql`
mutation permissionUnset($userId: ID!, $permission_rem: [ID]!) {
	permissionUnset(input: { userId: $userId, permissionIds: $permission_rem }) {
		user {
			id
			userPermissions {
				edges {
					node {
						id
						name
						codename
					}
				}
			}
		}
	}
}`;

const GROUP_SET_USER = gql`
mutation groupSetUser($id: ID!, $groupIds: [ID]!) {
	groupSetUser(input: { userId: $id, groupIds: $groupIds }) {
		groups {
			id
			name
			permissions {
				edges {
					node {
						id
						name
					}
				}
			}
			userSet {
				edges {
					node {
						username
					}
				}
			}
		}
	}
}`;

export {
	// Queries
	FILTER_PERMISSIONS_GROUP,
	GET_AVAILABLE_PERMISSIONS,
	// Mutations
	CREATE_PERMISSIONS_GROUP,
	GROUP_SET_PERMISSIONS,
	UPDATE_PERMISSIONS_GROUP,
	DELETE_PERMISSIONS_GROUP,
	SET_PERMISSION,
	REMOVE_PERMISSION,
	GROUP_SET_USER
}