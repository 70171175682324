<div class="change-password"></div>
<div class="sidebar-edit" [formGroup]="userForm">
  <h2>{{ "change-password-user.title" | translate }}</h2>
  <hr />

  <div class="update-message">
    <p>{{ "change-password-user.internal-update-message" | translate }}</p>
    <p>{{ "change-password-user.password-requirements-message" | translate }}</p>
    <p>{{ "change-password-user.number-required" | translate }}</p>
    <p>{{ "change-password-user.caracter-required" | translate }}</p>
    <p>{{ "change-password-user.length-required" | translate }}</p>
    <p>{{ "change-password-user.letter-required" | translate }}</p>
  </div>
  <hr />

  <mat-form-field appearance="outline">
    <mat-label>{{ "change-password-user.username" | translate }}</mat-label>
    <input matInput formControlName="username" placeholder="{{ 'change-password-user.username' | translate }}"
      readonly="true" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ "change-password-user.password" | translate }}</mat-label>
    <input type="password" matInput formControlName="password"
      placeholder="{{ 'change-password-user.password' | translate }}" />
  </mat-form-field>

  <div style="align-self: start !important; list-style-type: none; margin-top: -3px">
    <li style="color:red;" *ngIf="userForm.get('password')?.hasError('required') 
    && (userForm.get('password')?.dirty || userForm.get('password')?.touched)
    || userForm.get('password')?.hasError('minlength')
    || !hasNumber(userForm.get('password')?.value)
    || !hasSpecialCharacter(userForm.get('password')?.value)
    || !hasUpperCaseLetter(userForm.get('password')?.value)
    || !hasMinimumLength(userForm.get('password')?.value)
    ">
      - {{'change-password-user.weak-password' | translate}}
    </li>
  </div>

  <mat-form-field appearance="outline">
    <mat-label>{{ "change-password-user.confirm-password" | translate }}</mat-label>
    <input type="password" matInput formControlName="confirmPassword"
      placeholder="{{ 'change-password-user.confirm-password' | translate }}" />
  </mat-form-field>

  <div style="align-self: start !important; list-style-type: none;">
    <li style="color:red;"
      *ngIf="!userForm.get('password')?.hasError('required') && userForm.hasError('passwordNotMatch')">
      -{{'change-password-user.passwords-not-match' | translate}}
    </li>
  </div>

  <button class="btn btn-success" (click)="saveEdit()" [disabled]="userForm.invalid || userForm.get('password')?.hasError('minlength')
  || !hasNumber(userForm.get('password')?.value)
  || !hasSpecialCharacter(userForm.get('password')?.value)
  || !hasUpperCaseLetter(userForm.get('password')?.value)
  || !hasMinimumLength(userForm.get('password')?.value)
  ">
    {{ "change-password-user.save" | translate }}
  </button>
</div>