import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HelperModalComponent } from '../helper-modal/helper-modal.component';

@Component({
  selector: 'app-helper',
  templateUrl: './helper.component.html',
  styleUrls: ['./helper.component.less']
})
export class HelperComponent {
  constructor(
    public dialog: MatDialog
  ) { }

  openModal() {
    this.dialog.open(HelperModalComponent);
  }

}
