import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-imports-csv-form',
  templateUrl: './imports-csv-form.component.html',
  styleUrls: ['./imports-csv-form.component.less']
})
export class ImportsCsvFormComponent {

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (this.router.url !== '/importsCSV') {
      this.typesFilter = this.router.url
    }
  }

  public filter: boolean = true;

  public Filter(): void {
    this.filter = !this.filter;
  }

  public typesFilter: string | null = null;

  Router(router: any[]): void {
    if (this.router.url !== '/importsCSV') {
      Swal.fire({
        title: 'Deseja mesmo alterar o formulário? Todas as alterações até o momento serão perdidas.',
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: 'Confirmar',
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(router);
        } else if (result.isDenied) {
          this.typesFilter = this.router.url
        }
      });
    }
    else {
      this.router.navigate(router);
    }
  }
}
