import { gql } from "apollo-angular";

/** Filtro de todos os Hardwares da tabela (Filtro Inicial) **/

/** Filtro de Hardwares **/
const FILTER_HARDWARES = gql`
query Hardware(
    $major: Int,
    $minor: Int,
    $name: String,
    $revision: Int,
	$pageSize: Int,
	$cursor: String
    ) {
    hardware(
        major: $major,
        minor: $minor,
        name: $name,
        revision: $revision,
		pageSize: $pageSize,
		cursor: $cursor
        ) {
        edges {
            node {
                id
                name
                major
                minor
                revision
                description
                releaseDate
            }  
        }
		count
        total
		pageInfo{
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
    }
}`;

/** Criar Hardwares **/
const CREATE_HARDWARES = gql`
mutation
	HardwareCreate(
		$name: String!,
		$major: Int!,
		$minor: Int!,
		$revision: Int!,
		$description: String,
		$releaseDate: Date) {
		hardwareCreate(
			input: {
				name: $name
				major: $major
				minor: $minor
				revision: $revision
				description: $description
				releaseDate: $releaseDate
			}) {
			hardware {
				id
		}
	}
}`;


/** Criar hardwares compatíveis */
const CREATE_NEW_COMPATIBLE_HARDWARE = gql`
mutation
	HardwareCreate(
		$name: String!,
		$major: Int!,
		$minor: Int!,
		$revision: Int!,
		$description: String,
		$releaseDate: Date) {
		hardwareCreate(
			input: {
				name: $name
				major: $major
				minor: $minor
				revision: $revision
				description: $description
				releaseDate: $releaseDate
			}) {
			hardware {
				id
			}
		}
	}`;

export {
	// Queries
	FILTER_HARDWARES,
	// Mutations
	CREATE_HARDWARES,
	CREATE_NEW_COMPATIBLE_HARDWARE
}