import { gql } from "apollo-angular";

// Auditoria 
const AUDITORSHIP_QUERY = gql`
query AuditorshipReportSummary(
	$company: ID!
	$first: Int
	$last: Int
	$before:String
	$after: String
) {
	auditorships(
		company: $company
		first: $first
		last: $last  
		before: $before
		after: $after
	) {
        edges {
            node {
                id
                accountable
				creationDate
                startDate
                endDate
                auditReference
                isOpen
                totals {
                    icti
                    icvi
                    idt
                    opDiu
                    opNot
					sdft
                    sdot
                    mprev
                }
                installationauditSet {
                    edges {
                        node {
                            id
                            installationId
                            installationReference
                            transmissionComplianceIndicator
                            register
                            status
                            variables
                            actuation
                            daytimeOperation
                            nighttimeOperation
                        }
                    }
                }
            }
        }
        count
        total
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
    }
}`;

const GET_ICTI_URL = gql`
mutation ICTI (
    $company: ID!
    $auditorship: ID!
) {
    minasGenerateIctiReport(input:{
        company: $company
        auditorship: $auditorship
    }){
        report {
            id
            reference
            columns
            reportType
            generationDate
            requestedBy
            ready
            fileName
            preSignedUrl
        }
    }
}`;

const GET_ICVI_URL = gql`
mutation ICVI (
    $company: ID!
    $auditorship: ID!
) {
    minasGenerateIcviReport(input:{
        company: $company
        auditorship: $auditorship
    }){
        report {
            id
            reference
            columns
            reportType
            generationDate
            requestedBy
            ready
            fileName
            preSignedUrl
        }
    }
}`;

const GET_MPREV_URL = gql`
mutation MPREV (
    $company: ID!
    $auditorship: ID!
) {
    minasGenerateMprevReport(input:{
        company: $company
        auditorship: $auditorship
    }){
        report {
            id
            reference
            columns
            reportType
            generationDate
            requestedBy
            ready
            fileName
            preSignedUrl
        }
    }
}`;

const GET_SDOT_URL = gql`
	mutation SDOT (
		$company: ID!
		$auditorship: ID!
	) {
	minasGenerateSdotReport(input:{
        company: $company
        auditorship: $auditorship
    }){
        report {
            id
            reference
            columns
            reportType
            generationDate
            requestedBy
            ready
            fileName
            preSignedUrl
        }
    }
}`;


const GET_MINAS_GENERATED_REPORTS = gql`
query GetMinasReports($company: ID!) {
	minasGeneratedReports(
		company: $company
		orderBy: { 
			direction: DESC, 
			field: GENERATION_DATE 
		}
	) {
		total
		edges {
			node {
				id
				companyId
				columns
				ready
				reference
				requestedBy
				generationDate
				fileName
				reportType
				preSignedUrl
				auditorship{
					id
				}
			}
		}
	}
}`;

const CREATE_AUDITORSHIP = gql`
mutation AuditorshipCreate (
    $company: ID!
	$reference: String!
){
    auditorshipCreate (
		input: {
        reference: $reference,
		company: $company
    	}
		) {
        auditorship {
            id
            startDate
            endDate
            auditReference
            isOpen
        }
    }
}`;

const GET_AUDIT_BY_ID = gql`
query AuditorshipById(
	$id: ID
	$company: ID!
) {
	auditorship(
	id: $id,
	company: $company
	) {
		edges {
			node {
				id
				accountable
				creationDate
				startDate
				endDate
				auditReference
				isOpen
				totals {
					icti
					icvi
					idt
					opDiu
					opNot
					sdft
					sdot
					mprev
				}
				installationauditSet {
					total
				}
			}
		}
	}
}`;

const GET_AUDIT_INSTALLATIONS_DATA = gql`
query AuditorshipById(
	$id: ID
	$company: ID!
) {
	auditorship(
	id: $id,
	company: $company
	) {
		count
		edges {
			node {
				id
				accountable
				creationDate
				startDate
				endDate
				auditReference
				isOpen
				totals {
					icti
					icvi
					idt
					opDiu
					opNot
					sdft
					sdot
					mprev
				}
				installationauditSet {
					total
					edges{
						node{
							id
							installationId
							installationReference
							transmissionComplianceIndicator
							register
							variables
							status
							actuation
							daytimeOperation
							nighttimeOperation
						}
					}
				}
				
			}
		}
	}
}`;

const UPDATE_AUDIT_INSTALLATION_DATA = gql`
mutation InstallationOnAuditUpdate(
	$auditorship: ID!
	$installations: [InstallationInput]
) {
	installationOnAuditUpdate(
		input: { auditorship: $auditorship, installations: $installations }
	) {
		installationOnAudit {
			id
		}
	}
}`;

const ADD_INSTALLATIONS_ON_AUDIT = gql`
mutation AddInstallationsOnAudit(
	$auditorship: ID!
	$installations: [InstallationInput]
) {
	addInstallationsOnAudit(
		input: { auditorship: $auditorship, installations: $installations }
	) {
		installationOnAudit {
			id
			auditorship {
				id
				startDate
				endDate
				auditReference
				isOpen
			}
			installationReference
			transmissionComplianceIndicator
			register
			status
			variables
			actuation
			daytimeOperation
			nighttimeOperation
		}
	}
}`;

const EDIT_AUDITORSHIP_BASIC_DATA = gql`
mutation AuditorshipUpdate(
	$startDate: Date!
	$endDate: Date!
	$id: ID!
	$auditReference: String
	$isOpen: Boolean!
	$company: ID!
) {
	auditorshipUpdate(
		input: {
			id: $id
			startDate: $startDate
			endDate: $endDate
			auditReference: $auditReference
			isOpen: $isOpen
			company: $company
		},
	) {
		auditorship {
			id 
			isOpen
		}
	}
}`;

const DELETE_AUDIT_INSTALLATION = gql`
mutation Remove(
	$auditorship: ID!
	$installations: [ID]!
){
	removeInstallationsFromAuditorship(input:{
		auditorship: $auditorship
		installations: $installations
	}){
		status
	}
}`;

// Requisição de instalações com todos os dados existentes nela (Multiplas telas)
const QUERY_INSTALLATIONS = gql`
  query Installation(
    $first: Int
    $cursor: String
    $company: ID!
    $installations: [ID]
    $applications: [ApplicationTags]
    $gateway: ID
    $equipmentTypes: [ID]
    $locationCode: String
    $referenceContains: String
    $street: String
    $city: String
    $district: String
    $state: String
    $country: String
    $latitude: Float
    $longitude: Float
    $radius: Float
    $devices: [ID]
  ) {
    installation(
      first: $first
      after: $cursor
      company: $company
      installations: $installations
      applications: $applications
      gateway: $gateway
      equipmentTypes: $equipmentTypes
      locationCode: $locationCode
      referenceContains: $referenceContains
      street: $street
      city: $city
      district: $district
      state: $state
      country: $country
      latitude: $latitude
      longitude: $longitude
      radius: $radius
      devices: $devices
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      total
      count
      edges {
        node {
          id
          reference
          applications
          device {
            macAddress
            serialNumber
            equipmentType {
              reference
            }
          }
          ##################
          parentInstallation {
            id
          }
          circuitBox {
            id
            sgIpc {
              id
              macAddress
            }
          }
        }
      }
    }
  }
`;

export {
    GET_ICTI_URL, GET_ICVI_URL, GET_MPREV_URL, GET_SDOT_URL, AUDITORSHIP_QUERY, CREATE_AUDITORSHIP, GET_AUDIT_BY_ID,
    GET_AUDIT_INSTALLATIONS_DATA, UPDATE_AUDIT_INSTALLATION_DATA, ADD_INSTALLATIONS_ON_AUDIT,
    EDIT_AUDITORSHIP_BASIC_DATA, DELETE_AUDIT_INSTALLATION, GET_MINAS_GENERATED_REPORTS, QUERY_INSTALLATIONS
};