import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserprofileService } from 'src/shared/services/userprofile.service';
import Swal from 'sweetalert2';
import { User } from '../../models/user.model';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.less']
})
export class UserprofileComponent extends Unsubscriber implements OnInit {

  //Flags 
  public isEditing: boolean = false;

  public userForm = new FormGroup({
    email: new FormControl(),
    user: new FormControl(),
    firstName: new FormControl(),

  });

  public user: User = new User('', '', '');

  constructor(private userprofileService: UserprofileService) {
    super();

    this.subscriptions = this.userprofileService.cancelEdit.subscribe((result: boolean) => {
      this.isEditing = result;
      this.getUserInfo();
    });
  }


  ngOnInit(): void {
    this.getUserInfo();

  }

  public enterEditMode(): void {
    this.userprofileService.cancelEdit.emit(true);
  }

  public getUserInfo(): void {
    this.subscriptions = this.userprofileService.getUserInfo(localStorage.getItem('username')).valueChanges.subscribe(result => {
      if (result) {
        this.user.email = result.data.user.edges[0].node.email;
        this.user.name = result.data.user.edges[0].node.firstName;
        this.user.username = result.data.user.edges[0].node.username;
        this.userForm.setValue({
          firstName: result.data.user.edges[0].node.firstName,
          email: result.data.user.edges[0].node.email,
          user: result.data.user.edges[0].node.username
        })
      }
    },
      ((error: any) => {
        console.log(error)
      }))
  }

  public getNewUserData(newUser: User): void {
    this.userForm.setValue({
      firstName: newUser.name, email: newUser.email,
      user: newUser.username
    })
    this.user = newUser;
    location.reload();
  }
}
