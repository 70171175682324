import { Rule } from "./rule.model";
import { Weekday } from "./weekday.model";

export class AlertRuleTable {
    constructor(
        public id: string | null,
        public reference: string | null,
        public rules: Rule[] | null,
        public notification: string[] | null,
        public timeWindow: number | null,
        public eventCount: number | null,
        public weekdays: Weekday[] | null,
        public startDate: string | null,
        public endDate: string | null,
        public startTime: string | null,
        public endTime: string | null,
        public fullCoverage: boolean | null,
        public installations: string[] | null,
        public isActive: boolean
    ) { }
}