<h1 mat-dialog-title>{{ 'equipment-type-modal.title' | translate }}</h1>
<hr>
<div mat-dialog-content class="content">
    <div class="settings">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'equipment-type-modal.parameter-label' | translate }}</mat-label>
            <mat-select (selectionChange)="onOptionsParameterSelected($event)" [(ngModel)]="selectedParameter" required
                name="parameter" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let parameter of parameterList" [value]="{
              reference: parameter?.reference,
              targetReference:parameter?.targetReference
            }">
                    {{parameter?.reference}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'equipment-type-modal.code-for-equipment-label' | translate }}</mat-label>
            <input matInput disabled [(ngModel)]="selectedParameter.targetReference"
                [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'equipment-type-modal.parameter-type-label' | translate }}</mat-label>
            <mat-select [disabled]="disableParametersTypeInput" [(ngModel)]="parameterType" name="parameterType"
                [ngModelOptions]="{standalone: true}">
                <mat-option value="INT">
                    {{ 'equipment-type-modal.integer-label' | translate }}
                </mat-option>
                <mat-option value="BOOL">
                    {{ 'equipment-type-modal.boolean-label' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <form class="form-integer" *ngIf="parameterType === 'INT'">
            <mat-form-field>
                <mat-label>{{ 'equipment-type-modal.min-value-label' | translate }}</mat-label>
                <input matInput type="number" [(ngModel)]="settingsMinValue" [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'equipment-type-modal.max-value-label' | translate }}</mat-label>
                <input matInput type="number" [(ngModel)]="settingsMaxValue" [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'equipment-type-modal.description-label' | translate }}</mat-label>
                <input matInput [(ngModel)]="settingsDescription" [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'equipment-type-modal.value-label' | translate }}</mat-label>
                <input matInput type="number" [(ngModel)]="settingsValue" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
        </form>

        <form class="form-boolean" *ngIf="parameterType === 'BOOL'">
            <mat-form-field>
                <mat-label>{{ 'equipment-type-modal.description-for-true-label' | translate }}</mat-label>
                <input matInput [(ngModel)]="settingsDescriptionTrue" [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'equipment-type-modal.description-for-false-label' | translate }}</mat-label>
                <input matInput [(ngModel)]="settingsDescriptionFalse" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
        </form>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-button cdkFocusInitial (click)="closeAndSaveModalConfigCommands()" class="btn btn-success">
        {{'equipment-type-modal.save-btn'|
        translate }}</button>
    <button mat-button [mat-dialog-close]="null" cdkFocusInitial class="btn btn-success">{{
        'equipment-type-modal.close-btn' | translate }}</button>
</div>