import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { auditDownload } from '../models/icti_icvi.model';
import { AuditorshipService } from 'src/shared/services/auditorship.service';
import { tzConvertUTC2Local } from 'src/assets/convertTimeZone/convertTimeZone';
import { Unsubscriber } from '../../unsubscriber/unsubscriber.component';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { AuditInstallations } from '../../report-audit/models/auditInstallations.model';

@Component({
  selector: 'app-report-audit-download',
  templateUrl: './report-audit-download.component.html',
  styleUrls: ['./report-audit-download.component.less']
})
export class ReportAuditDownloadComponent extends Unsubscriber implements OnInit {

  constructor(
    private auditorshipService: AuditorshipService,
    private errorLibService: ErrorLibService
  ) {
    super();
  }

  /** Armazena o id da empresa selecionada **/
  public company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;

  /** Propriedade que controla o loading na tela **/
  public tableIsLoading: boolean = true;

  /** Armazena a lista de relatórios de auditoria **/
  public auditDownloadList: auditDownload[] = [];

  /** Armazena as colunas da tabela **/
  public displayedColumns: string[] = ['reference', 'preSignedUrlMprev', 'preSdft', 'preSignedUrlSdot', 'preSignedUrlIcti', 'preSignedUrlIcvi'];

  /** Utilizado para exibir os dados da tabela na tela **/
  public dataSource = new MatTableDataSource<auditDownload>(this.auditDownloadList);

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    /** Realiza a requisição que traz os relatórios de icti e icvi **/
    this.subscriptions = this.auditorshipService.getIctiIcvi()
      .valueChanges
      .subscribe({
        next: (data: any) => {

          /** Variável que armazena o nó de response que contém os dados que serão manipulados **/
          let filteredResponse = data.data.minasGeneratedReports.edges;

          /** retorna os relatórios icvi, icti com ids iguais agrupados, onde item é o id atual procesado, e acc é o acumulador a ser retornado **/
          const groupedAuditFiles = filteredResponse.reduce((acc: any, item: any) => {
            /** armazena cada id dos relatórios **/
            const auditorshipIds = item?.node?.auditorship?.id;

            acc[auditorshipIds] = acc[auditorshipIds] || []; /// Verifica se já existe ids repetidos, caso não existir, cria um novo array vazio.
            acc[auditorshipIds].push(item); /// Adiciona o id ao acumulador
            return acc; // Retorna os ids agrupados
          }, {});

          /** Cria um set com os ids **/
          const uniqueIds = [...new Set(filteredResponse.map((item: any) => item?.node?.auditorship?.id))];

          uniqueIds.forEach((item: any) => {

            const icvi = groupedAuditFiles[item].find((reports: any) => reports.node.reportType === 'icvi'); // Armazena todos os relatórios do tipo icvi
            const icti = groupedAuditFiles[item].find((reports: any) => reports.node.reportType === 'icti'); // Armazena todos os relatórios do tipo icti
            const mprev = groupedAuditFiles[item].find((reports: any) => reports.node.reportType === 'mprev'); // Armazena todos os relatórios do tipo mprev
            const sdot = groupedAuditFiles[item].find((reports: any) => reports.node.reportType === 'sdot'); // Armazena todos os relatórios do tipo sdot

            /** Preenche a lista com os campos para popular a tabela **/
            this.auditDownloadList.push(
              new auditDownload(
                icti?.node?.id,
                icvi?.node?.id,
                sdot?.node?.id,
                mprev?.node?.id,
                icvi?.node?.reference ? icvi?.node?.reference : icti?.node?.reference,
                icti?.node?.requestedBy,
                tzConvertUTC2Local(icvi?.node?.generationDate ? icvi?.node?.generationDate : icti?.node?.generationDate),
                icti?.node?.preSignedUrl,
                icvi?.node?.preSignedUrl,
                sdot?.node?.preSignedUrl,
                mprev?.node?.preSignedUrl,
                item,
                icvi?.node?.ready
              )

            );
          });

          /** Desativa o loading **/
          this.tableIsLoading = false;
        },
        /** Caso ocorra algum erro **/
        error: (error: any) => {
          /** Desativa o loading na tela **/
          this.tableIsLoading = false;
          /** Exibe erro na tela **/
          this.errorLibService.errorAlert(error);
          /** Exibe o erro no console do navegador **/
          console.log(error);
        }
      });
  }

  private auditInstallationSet: AuditInstallations[] = []

  public createInstallationSet(installation: any): AuditInstallations {
    let newInstallationSet: AuditInstallations = new AuditInstallations(
      installation.installationId,
      installation.installationReference,
      installation.transmissionComplianceIndicator,
      installation.register,
      installation.status,
      installation.variables,
      installation.daytimeOperation,
      installation.nighttimeOperation,
      installation.actuation
    )
    return newInstallationSet
  }


  public getAuditorshipInstallations(id: any) {
    this.subscriptions = this.auditorshipService.getAuditorshipInstallations(id)
      .valueChanges
      .subscribe({
        next: (auditData: any) => {
          console.log(auditData)
          let auditPayload = auditData.data.auditorship.edges[0].node;
          let auditInstallationSet = auditPayload.installationauditSet.edges;
          auditInstallationSet.forEach((installation: any) => {
            this.auditInstallationSet.push(
              this.createInstallationSet(installation.node)
            );
          });

          this.auditorshipService.downloadCsv(this.auditInstallationSet);
          this.auditInstallationSet = [];
        }
      })
  }
}
