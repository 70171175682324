import { Injectable } from '@angular/core';
import { CREATE_INSTALLATION, UPDATE_INSTALLATION } from 'src/app/graphql/installations.graphql';
import { Apollo } from 'apollo-angular';
import { FILTER_GATEWAYS } from 'src/app/graphql/gateways.graphql';
import { CREATE_INSTALLATION_SITE, FILTER_INSTALLATION_SITE } from 'src/app/graphql/installation-site.graphql';
import { FILTER_LAMPS_TYPE } from 'src/app/graphql/lamp-type.graphql';
import { EQUIPMENT_CREATE, FILTER_EQUIPMENTS, UPDATE_EQUIPMENTS } from 'src/app/graphql/equipment.graphql';
import { FILTER_DIVISIONS } from 'src/app/graphql/divisions.graphql';
import { GET_CIRCUIT_BOX } from 'src/app/graphql/circuit-box.graphql';
import { FILTER_ALL_EQUIPMENT_TYPES } from 'src/app/graphql/equipment-type.graphql';

@Injectable({
    providedIn: 'root'
})

export class ImportsCSVFileService {

    /** Tipo de separador do arquivo **/
    private separatorString: string = ';';

    constructor(private apollo: Apollo,
    ) {
        this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
    }

    /** Armazena ID da empresa selecionada **/
    public company: string | null;

    /** Método que lê o arquivo CSV e retorna uma lista de objetos **/
    public importDataFromCSV(csvText: string): Array<any> {
        /** Constante que armazena uma lista onde estão sendo separadas com base no separador 
         * representa as propriedades do CSV (cabeçalho).
         * **/
        csvText = csvText?.replace(/\r/g, '');

        const propertyNames = csvText?.slice(0, csvText.indexOf('\n'))?.split(this.separatorString);

        /** Constante que armazena as linhas com os dados **/
        const dataRows = csvText?.slice(csvText.indexOf('\n') + 1)?.split('\n');

        /** Array para armazenar a lista de objetos **/
        let dataArray: any[] = [];

        /** Para cada linha do arquivo **/
        dataRows.forEach((row) => {

            /** Separa os valores com base no separador **/
            let values = row?.split(this.separatorString);

            /** Instancia um novo objeto abstrato **/
            let obj: any = new Object();

            /** Para cada uma das propriedades **/
            for (let index = 0; index < propertyNames.length; index++) {
                /** Pega o nome da propriedade pelo conteúdo do índice da lista **/
                const propertyName: string = propertyNames[index];

                /** Pega o valor da linha, baseado no índice da lista de valores **/
                let val: any = values[index];
                /** Seta nulo para itens vazios **/
                if (val === '') {
                    val = null;
                }
                /** Seta no objeto uma propriedade passando o valor do índice compatível **/
                obj[propertyName] = val;
            }

            /** Adiciona o objeto referente a linha de dados **/
            dataArray.push(obj);
        });

        /** Retorna uma lista de objetos referente aos dados do CSV **/
        return dataArray;
    }

    /** Serviço de geocodificação, utilizado para filtrar  o endereço completo do local de instalação através das coordenadas lat e lng **/
    geocodeLatLng(lat: number, lng: number): Promise<google.maps.GeocoderResult[]> {
        const geocoder = new google.maps.Geocoder();
        const latlng = { lat, lng };

        return new Promise((resolve, reject) => {
            geocoder.geocode({ location: latlng }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    if (status === google.maps.GeocoderStatus.OK && results) {
                        resolve(results);
                    }
                } else {
                    reject('Geocoder failed due to: ' + status);
                }
            });
        });
    }

    /**  Realiza a mutation de atualização de instalações **/
    public updateInInstallation(
        id: string | null,
        reference: string | null,
        site: string | null,
        deviceId: string | null,
        isActive: boolean,
        hasMagneticKey: boolean,
        applications: ['light', 'electricity', 'water', 'gas'] | null,
        lampType: string | null,
        gateway: string | null,
        division: string | null
    ) {
        return this.apollo.mutate({
            mutation: UPDATE_INSTALLATION,
            fetchPolicy: 'no-cache',
            variables: {
                id,
                reference,
                site,
                deviceId,
                isActive,
                hasMagneticKey,
                applications,
                lampType,
                gateway,
                division,
                channel: 0
            }
        })
    }

    /** Realiza a inativação das instalações (desassociar equipamentos) **/
    public activateAndInactivateInInstallation(
        id: string | null,
        reference: string | null,
        isActive: boolean,
        site: string | null,
        hasMagneticKey: boolean,
        applications: boolean,
        lampType: string | null,
        gateway: string | null,
        division: string | null
    ) {
        return this.apollo.mutate({
            mutation: UPDATE_INSTALLATION,
            fetchPolicy: 'no-cache',
            variables: {
                id,
                reference,
                site,
                isActive,
                hasMagneticKey,
                applications,
                lampType,
                gateway,
                division,
                channel: 0
            }
        })
    }

    /** Realiza a consulta dos gateways por referência **/
    public getGatewaysByReference(
        reference: string | null,
        isActive: boolean = true
    ) {
        return this.apollo.watchQuery({
            query: FILTER_GATEWAYS,
            fetchPolicy: 'no-cache',
            variables: {
                company: this.company,
                reference,
                isActive,
                sort_dir: 'ASC',
                sort_field: 'REFERENCE'
            }
        })
    }

    /** Realiza a consulta do local de instalação por referência **/
    public getInstallationSiteByReference(reference: string | null): any {
        return this.apollo.watchQuery({
            query: FILTER_INSTALLATION_SITE,
            fetchPolicy: 'no-cache',
            variables: {
                company: this.company,
                reference,
                sort_dir: "ASC",
                sort_field: "REFERENCE",
            }
        })
    }

    /** Realiza a consulta de tipos de luminárias, através do modelo **/
    public getLampsTypeByModel(model: string | null) {
        return this.apollo.watchQuery({
            query: FILTER_LAMPS_TYPE,
            fetchPolicy: 'no-cache',
            variables: {
                model
            }
        })
    }

    /** Realiza a consulta do tipo de equipamento por referência **/
    public getEquipmentTypeByReference(
        reference: string | null,
        major: string | null,
        minor: string | null,
        revision: string | null
    ) {
        // Faz a requisição
        return this.apollo.watchQuery({
            query: FILTER_ALL_EQUIPMENT_TYPES,
            fetchPolicy: 'no-cache',
            variables: {
                reference,
                major,
                minor,
                revision
            }
        })
    }

    getEquipmentsBySerialNumber(
        serialNumber: string | null,
        isInstalled: boolean = false
    ) {
        return this.apollo.watchQuery({
            query: FILTER_EQUIPMENTS,
            fetchPolicy: 'no-cache',
            variables: {
                serialNumber,
                isInstalled,
                company: this.company,
                sort_dir: "ASC",
                sort_field: "MAC_ADDRESS"
            }
        })
    }


    getEquipmentsByMacAddress(
        deviceMacs: string,
    ) {
        return this.apollo.watchQuery({
            query: FILTER_EQUIPMENTS,
            fetchPolicy: 'no-cache',
            variables: {
                deviceMacs,
                company: this.company,
                sort_dir: "ASC",
                sort_field: "MAC_ADDRESS"
            }
        })
    }

    /** Mutation que realiza a criação dos equipamentos **/
    public createEquipment(
        id: string | null,
        macAddress: string | null,
        serialNumber: string | null,
        equipmentType: string | null
    ) {
        return this.apollo.mutate({
            mutation: EQUIPMENT_CREATE,
            fetchPolicy: 'no-cache',
            variables: {
                company: this.company,
                id,
                macAddress,
                serialNumber,
                equipmentType
            }

        })
    }

    createInstallation(
        reference: string | null,
        equipment: string | null,
        gateway: string | null,
        lampType: string | null,
        hasMagneticKey: boolean = false,
        applications: ['light', 'electricity', 'water', 'gas'] | null,
        division: string | null,
        site: string | null,
    ) {
        return this.apollo.mutate({
            mutation: CREATE_INSTALLATION,
            fetchPolicy: 'no-cache',
            variables: {
                company: this.company,
                reference,
                equipment,
                gateway,
                lampType,
                hasMagneticKey,
                applications,
                division,
                site,
                channel: 0
            }
        })
    }

    createInstallationSite(
        latitude: number,
        longitude: number,
        reference: string | null,
        country: string | null,
        state: string | null,
        city: string | null,
        district: string | null,
        street: string | null,
        number: string | null,
        details: string | null,
        locationCode: string | null
    ) {
        return this.apollo.mutate({
            mutation: CREATE_INSTALLATION_SITE,
            fetchPolicy: 'no-cache',
            variables: {
                latitude,
                longitude,
                reference,
                country,
                state,
                city,
                district,
                street,
                number,
                details,
                locationCode,
                company: this.company
            }
        })
    }

    /** Requisição para filtrar divisão **/
    public getDivisionsByReference(
        reference: string | null,
    ) {
        return this.apollo.watchQuery({
            query: FILTER_DIVISIONS,
            fetchPolicy: 'no-cache',
            variables: {
                company: this.company,
                reference
            }
        })
    }

    public getCircuitBox(reference: string | null) {
        return this.apollo.watchQuery({
            query: GET_CIRCUIT_BOX,
            variables: {
                reference,
                company: this.company,
                sort_dir: "ASC",
                sort_field: "NAME"
            }
        })
    }

    // Função responsável por atualizar o equipamento
    updateEquipment(
        id: string | null,
        company: string | null,
        macAddress: string | null,
        serialNumber: string | null,
        equipmentType: any | null
    ) {
        return this.apollo.mutate({
            mutation: UPDATE_EQUIPMENTS,
            fetchPolicy: 'no-cache',
            variables: {
                id,
                company,
                macAddress,
                serialNumber,
                equipmentType
            }
        })
    }
}