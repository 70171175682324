/** Classe que contém as propriedades de paginação **/
export class PaginationInfo {
    constructor(
        public count: number,
        public total: number,
        public hasNextPage: boolean,
        public hasPreviousPage: boolean,
        public startCursor: string | null,
        public endCursor: string | null
    ) { }
}

/** Classe que contém as propriedadades dos Grupos de Permissões **/
export class PermissionsGroup {
    constructor(
        public id: string,
        public name: string,
        public userSet: Users,
        public permissions: AvailablePermissions[],
    ) { }
}

/** Classe que contém as propriedades das Permissões **/
export class AvailablePermissions {
    constructor(
        public id: string,
        public name: string,
        public codename: string,
        public checked: boolean = false
    ) { }
}

/** Classe que contém as propriedades dos usuários **/
export class Users {
    constructor(
        public id: string,
        public count: string,
        public username: string,
        public firstName: string,
        public lastName: string,
        public checked: boolean = false
    ) { }
}

