import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { ManageUserService } from 'src/shared/services/manage-user.service';
import Swal from 'sweetalert2';
import { Unsubscriber } from '../../unsubscriber/unsubscriber.component';
import {
  Company,
  PaginationInfo,
  Permission,
  PermissionGroup,
  User,
} from '../models/user.model';
import { tzConvertUTC2Local } from 'src/assets/convertTimeZone/convertTimeZone';

@Component({
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.less'],
})
export class ManageUserComponent extends Unsubscriber implements OnInit {

  constructor(
    private manageUserService: ManageUserService,
    private errorLibService: ErrorLibService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {

    /** Retorna todas as empresas existentes utilizado para preencher o select de empresas na criação e edição de usuários **/
    this.subscriptions = this.manageUserService.getAllCompanies().valueChanges.subscribe({
      next: (res: any) => {
        res.data.company.edges.forEach((company: any) => {
          this.companyList.push(
            new Company(company.node.id, company.node.name, false)
          );
          this.companyListUpdate.push(
            new Company(company.node.id, company.node.name, false)
          );
        });
      },
      error: (err: any) => {
        this.errorLibService.errorAlert(err);
        console.log("Error on getAllCompaniesQuery", err)
      },
    });

    /** Retorna todas as permissões existentes utilizado para preencher o select de empresas na criação e edição de usuários **/
    this.subscriptions = this.manageUserService.getAllPermission().valueChanges.subscribe({
      next: (res: any) => {
        res.data.availablePermissions.edges.forEach(
          (availablePermissions: any) => {
            this.permissionList.push(
              new Permission(
                availablePermissions.node.id,
                availablePermissions.node.name,
                availablePermissions.node.codename
              )
            );
            this.permissionListUpdate.push(
              new Permission(
                availablePermissions.node.id,
                availablePermissions.node.name,
                availablePermissions.node.codename
              )
            );
          }
        );
      },
      error: (err: any) => {
        this.errorLibService.errorAlert(err);
        console.log("Error on availablePermissionsQuery", err)
      },
    });

    /** Retorna todos os grupos de permissões existentes utilizado para preencher o select de empresas na criação e edição de usuários **/
    this.subscriptions = this.manageUserService.getAllPermissionGroups().valueChanges.subscribe({
      next: (res: any) => {
        res.data.availableGroups.edges.forEach((availableGroups: any) => {
          this.permissionGroupList.push(
            new PermissionGroup(
              availableGroups.node.id,
              availableGroups.node.name,
              availableGroups.node.userSet
            )
          );
          this.permissionGroupListUpdate.push(
            new PermissionGroup(
              availableGroups.node.id,
              availableGroups.node.name,
              availableGroups.node.userSet
            )
          );
        });
      },
      error: (err: any) => {
        this.errorLibService.errorAlert(err);
        console.log("Error on availableGroupsQuery", err)
      },
    });
  }

  /** Variável que contém uma lista de todas as empresas (Utilizado na criação de usuários) **/
  public companyList: Company[] = [];

  /** Variável que contém uma lista de todas as empresas (Utilizado na edição de usuários) **/
  public companyListUpdate: Company[] = [];

  /** Variável que contém uma lista de todas as permissões (Utilizado na criação de usuários) **/
  public permissionList: Permission[] = [];

  /** Variável que contém uma lista de todas as permissões (Utilizado na edição de usuários) **/
  public permissionListUpdate: Permission[] = [];

  /** Variável que contém uma lista de todos os grupos de permissões (Utilizado na criação de usuários) **/
  public permissionGroupList: PermissionGroup[] = [];

  /** Variável que contém uma lista de todos os grupos de permissões (Utilizado na edição de usuários) **/
  public permissionGroupListUpdate: PermissionGroup[] = [];

  /** Variável que contém a lista de usuários (Utilizado para popular a tabela) **/
  public ELEMENT_DATA: User[] = [];

  public filterLoading: boolean = false;

  /**  Renderiza os comandos da lista ELEMENT_DATA para a tabela **/
  public dataSource = new MatTableDataSource<User>(this.ELEMENT_DATA);

  /** Variável que armazena as colunas da tabela **/
  public displayedColumns: string[] = [
    'name',
    'username',
    'email',
    'lastaccess',
    'superuser',
    'isactive',
    'actions',
  ];

  /** Variáveis utilizadas para armazenar o filtro **/
  public usernameContains: string;
  public emailContains: string;
  public isSuperUser: boolean;

  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null)

  /** Métodos e variáveis responsáveis por configurar a paginação da tabela **/
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /** Variáveis utilizadas para manipulação do Paginator **/
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  /**variaveis que valida se a senha do usuário está expirada ou não**/
  public expiredPassword = localStorage.getItem('expiredPassword');
  public isExpiredPassword = this.expiredPassword === 'true'; // converte para booleano

  /** Função que controla os botões de anterior e pŕoximo no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /**
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     *
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.handlePreviousPage(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterUser(this.paginationProperties.endCursor)
    }
  }

  /** Atualiza a tabela **/
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  /** Loading variables **/
  public loadingCreateUser: boolean = false;
  public loadingFilterUser: boolean = false;
  public loadingUser: boolean = false;

  /** Variável utilizada na manipulação do box de criação de usuários **/
  public isCreateUser: boolean = false;

  /** Variável utilizada na manipulação do box de filtro de usuários **/
  public isFilterUser: boolean = false;

  /** limpar filtro checkbox **/
  public cleaningFilter: Subject<boolean> = new Subject();

  /** Variáveis utilizadas no formulário de filtro **/
  public superUserFilterForm: boolean = false;
  public activeUserFilterForm: boolean = true;
  public usernameFilterForm: FormControl = new FormControl('');
  public emailFilterForm: FormControl = new FormControl('');

  /** Variável para controle do sidebar de edição **/
  public isUpdateUser: boolean = false;

  /** Variável utilizada para manipular o sidebar para edição/visualização **/
  public viewController: boolean = false;

  /** Variável que armazena o id do usuário (utilizado na edição de usuários)**/
  public updateUserId: string = '';

  /** Variável que armazena os campos do formulário de criação de usuários **/
  public createUserForm: FormGroup = this.formBuilder.group({
    firstNameCreateForm: [null, Validators.required],
    lastNameCreateForm: [null, Validators.required],
    usernameCreateForm: [null, Validators.required],
    emailCreateForm: [null, Validators.required],
    passwordCreateForm: [null, [
      Validators.required,
      Validators.minLength(6),
      Validators.pattern(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/)
    ]]
  })

  /** Variável que armazena os campos do formulário de update/visualização de usuários **/
  public updateUserForm: FormGroup = this.formBuilder.group({
    userNameUpdateForm: [null, Validators.required],
    firstNameUpdateForm: [null, Validators.required],
    lastNameUpdateForm: [null, Validators.required],
    emailUpdateForm: [null, Validators.required],
    passwordUpdateForm: [null, [
      Validators.minLength(6),
      Validators.pattern(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/)
    ]],
    superUserUpdateForm: new FormControl(false),
    isactiveUserUpdateForm: new FormControl(true),
  });

  /** Método que maninupla a abertura e fechamento do box de criação de usuários **/
  public createUserBox() {
    this.isCreateUser = !this.isCreateUser;
    this.isFilterUser = false;
  }

  /** Método que maninupla a abertura e fechamento do box de filtro de usuários **/
  public filterUserBox() {
    this.isFilterUser = !this.isFilterUser;
    this.isCreateUser = false;
  }

  /** Método que fecha os formulários de filtro e criação de usuários **/
  public closeForms(): void {
    this.isCreateUser = false;
    this.isFilterUser = false;
  }

  /** Método utilizado para manipular o checkbox de super usuario **/
  public checkboxSuperuser(event: any) {
    this.superUserFilterForm = event.checked;
  }

  /** Método utilizado para manipular o checkbox ativar usuario **/
  public checkboxActiveuser(event: any) {
    this.activeUserFilterForm = event.checked;
  }

  /** Método para criação de usuários **/
  public createUser() {
    let companies: string[] = [];
    this.companyList.forEach((company) => {
      if (company.checked) {
        companies.push(company.id);
      }
    });

    /** Caso todos os campos tenham sidos preenchidos pelo usuário **/
    if (this.createUserForm.valid) {

      this.loadingCreateUser = true;

      /** Realiza a requisição para criação dos usuários **/
      this.subscriptions = this.manageUserService
        .createUser(
          this.createUserForm.get('firstNameCreateForm')?.value,
          this.createUserForm.get('lastNameCreateForm')?.value,
          companies,
          this.createUserForm.get('usernameCreateForm')?.value,
          this.createUserForm.get('passwordCreateForm')?.value,
          this.createUserForm.get('emailCreateForm')?.value,
          this.superUserFilterForm
        )
        .subscribe({
          next: (res: any) => {

            /** Armazena o id do usuário que foi criado **/
            const userId = res.data.userCreate.user.id;

            let permissions: string[] = [];
            let permissionsGroup: string[] = [];

            this.permissionList.forEach((permission) => {
              if (permission.checked) {
                permissions.push(permission.id);
              }
            });

            this.permissionGroupList.forEach((permission) => {
              if (permission.checked) {
                permissionsGroup.push(permission.id);
              }
            });

            /** Realiza a requisição para trazer as permissões **/
            this.subscriptions = this.manageUserService
              .setPermission(userId, permissions)
              .subscribe({
                next: () => { },
                error: (error) => {
                  this.errorLibService.errorAlert(error);
                  console.log('Error on setPermission mutation', error);
                },
              })

            /** Realiza a requisição para trazer os grupos de permissões **/
            this.subscriptions = this.manageUserService
              .setPermissionGroup(
                userId,
                permissionsGroup
              )
              .subscribe({
                next: () => { },
                error: (error) => {
                  this.errorLibService.errorAlert(error);
                  console.log('Error on groupSetUser mutation', error);
                },
              });

            this.loadingCreateUser = false;

            /** Exibe mensagem de sucesso ao usuário **/
            Swal.fire({
              title: 'Usuário cadastrado ',
              text: 'Usuário cadastrado com sucesso',
              icon: 'success',
              confirmButtonText: 'Ok',
              cancelButtonText: 'Cancelar'
            })

            /** Fecha o box de registro de usuários **/
            this.closeForms();

            /** Realiza o filtro **/
            this.filterUser(null);
            /** Atualiza a tabela **/
            this.setDataSourceAttributes();
          },
          error: (error) => {
            this.errorLibService.errorAlert(error);
            console.log('Error on updatePermission mutation', error);
            this.loadingCreateUser = false
          },
        });
    }

    /** Caso algum campo não tenha sido preenchido **/
    else {
      this.loadingCreateUser = false;
      /** Exibe alerta ao usuário **/
      Swal.fire({
        title: 'Formulário Incompleto!',
        text: 'Preencha todos os campos.',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
  }

  /** Método que realiza o filtro dos usuários **/
  public filterUser(cursor: string | null) {
    this.filterLoading = true;

    if (cursor == null) {
      this.pageIndex = 0;
    }

    this.usernameContains = this.usernameFilterForm.value;
    this.emailContains = this.emailFilterForm.value;
    this.isSuperUser = this.superUserFilterForm;

    this.subscriptions = this.manageUserService.filterUsers(
      this.usernameContains,
      this.emailContains,
      this.isSuperUser,
      cursor,
      this.pageSize
    ).valueChanges.subscribe({
      next: ((response: any) => {
        this.paginationProperties = new PaginationInfo(
          response.data?.user?.count,
          response.data?.user?.total,
          response.data?.user?.pageInfo?.hasNextPage,
          response.data?.user?.pageInfo?.hasPreviousPage,
          response.data?.user?.pageInfo?.startCursor,
          response.data?.user?.pageInfo?.endCursor
        )

        this.ELEMENT_DATA = [];
        //this.isReady = false;
        this.length = this.paginationProperties.total;

        response.data.user.edges.forEach((user: any) => {

          let permissions: Permission[] = [];
          user.node.userPermissions.edges.forEach((permission: any) => {
            permissions.push(new Permission(
              permission.node.id,
              permission.node.reference,
              permission.node.codeName,
              true
            ));
          });

          let permissionsGroups: PermissionGroup[] = [];
          user.node.groups.edges.forEach((permissionsGroup: any) => {
            permissionsGroups.push(
              new PermissionGroup(
                permissionsGroup.node.id,
                permissionsGroup.node.reference,
                permissionsGroup.node.userSet,
                true
              ))
          });

          let companies: Array<Company> = [];
          user.node.companies.edges.forEach((company: any) => {
            companies.push(new Company(company.node.id, company.node.name));
          });

          let name = user.node.firstName + ' ' + user.node.lastName;
          let superUser = user.node.isSuperuser;
          this.ELEMENT_DATA.push(
            new User(
              user.node.id,
              user.node.username,
              name,
              user.node.firstName,
              user.node.lastName,
              user.node.email,
              //Converter para fuso horário baseado no navegador do usuário
              tzConvertUTC2Local(user.node.lastLogin),
              superUser,
              companies,
              permissions,
              permissionsGroups,
              user.node.isActive
            )
          );
        })

        if (this.ELEMENT_DATA.length === 0) {
          Swal.fire({
            title: 'Sua pesquisa não retornou resultados',
            icon: 'warning',
            confirmButtonText: 'Ok',
          })
        }

        this.setDataSourceAttributes();

        this.filterLoading = false;

      })
    })
  }

  public handlePreviousPage(cursor: string | null): void {

    if (cursor == null) {
      this.pageIndex = 0;
    }

    this.subscriptions = this.manageUserService.handlePreviousPage(
      this.usernameContains,
      this.emailContains,
      this.isSuperUser,
      cursor,
      this.pageSize
    )
      .valueChanges.subscribe({
        next: ((response: any) => {
          this.paginationProperties = new PaginationInfo(
            response.data?.user?.count,
            response.data?.user?.total,
            response.data?.user?.pageInfo?.hasNextPage,
            response.data?.user?.pageInfo?.hasPreviousPage,
            response.data?.user?.pageInfo?.startCursor,
            response.data?.user?.pageInfo?.endCursor
          )

          this.ELEMENT_DATA = [];
          //this.isReady = false;
          this.length = this.paginationProperties.total;

          response.data.user.edges.forEach((user: any) => {
            let companies: Array<Company> = [];
            user.node.companies.edges.forEach((company: any) => {
              companies.push(new Company(company.node.id, company.node.name));
            });

            let permissions: Permission[] = [];
            user.node.userPermissions.edges.forEach((permission: any) => {
              permissions.push(new Permission(
                permission.node.id,
                permission.node.reference,
                permission.node.codeName,
                true
              ));
            });

            let permissionsGroups: PermissionGroup[] = [];
            user.node.groups.edges.forEach((permissionsGroup: any) => {
              permissionsGroups.push(
                new PermissionGroup(
                  permissionsGroup.node.id,
                  permissionsGroup.node.reference,
                  permissionsGroup.node.userSet,
                  true
                ));
            });

            let name = user.node.firstName + ' ' + user.node.lastName;
            let superUser = user.node.isSuperuser;
            this.ELEMENT_DATA.push(
              new User(
                user.node.id,
                user.node.username,
                name,
                user.node.firstName,
                user.node.lastName,
                user.node.email,
                //Converter para fuso horário baseado no navegador do usuário
                tzConvertUTC2Local(user.node.lastLogin),
                superUser,
                companies,
                permissions,
                permissionsGroups,
                user.node.isActive,
              )
            );

            this.setDataSourceAttributes();

            if (this.ELEMENT_DATA.length === 0) {
              Swal.fire({
                title: 'Sua pesquisa não retornou resultados',
                icon: 'warning',
                confirmButtonText: 'Ok',
              })
            }
          })
        })
      });
  }

  /** Utilizado no botão de cancelar que fecha o box de filtro **/
  public cancelCreateOrFilter() {
    this.isCreateUser = false;
    this.isFilterUser = false;
    this.usernameFilterForm.reset();
    this.emailFilterForm.reset();
  }

  /** Método responsável pela manipulação do checkbox quando todas as empresas são marcadas ou desmarcadas (utilizado na criação de usuários) **/
  public selectAllCompanies(event: any) {
    /**  Caso todas as empresas sejam marcadas **/
    if (event.selected) {
      this.companyList.forEach((company) => {
        company.checked = true;
      });
      /** Caso todas as empresas sejam desmarcadas **/
    } else {
      this.companyList.forEach((company) => {
        company.checked = false;
      });
    }
  }

  /** Método para validar o id das empresas e marcar ou desmarcar o checkbox (utilizado na criação de usuários) **/
  public checkCompany(id: string) {
    this.companyList.forEach((company) => {
      if (company.id === id) {
        company.checked = true;
      }
    });
  }

  /** Método responsável pela manipulação do checkbox quando todas as empresas são marcadas ou desmarcadas (utilizado na edição de usuários) **/
  public selectAllCompaniesUpdate(event: any) {
    /** Caso todas as empresas sejam marcadas **/
    if (event.selected) {
      this.companyListUpdate.forEach((company) => {
        company.checked = true;

      });
      /** Caso todas as empresas seja desmarcadas **/
    } else {
      this.companyListUpdate.forEach((company) => {
        company.checked = false;
      });
    }
  }

  /** Método que realiza a validação de quando o checkbox de empresas é marcado ou desmarcado (utilizado na edição de usuários) **/
  public checkCompanyUpdate(company: Company) {
    /** Sempre que ocorrer o evento de checkbox o valor passa a ser invertido (podendo ser marcado e desmarcado) **/
    company.checked = !company.checked;
  }

  /** Método responsável pela manipulação do checkbox quando todas as permissões são marcadas ou desmarcadas (utilizado na criação de usuários) **/
  public selectAllPermissions(event: any) {
    /** Caso todas as permissões sejam marcadas **/
    if (event.selected) {
      this.permissionList.forEach((permission) => {
        /**para cada item na lista valor do checkbox recebe true **/
        permission.checked = true;
      });
      // caso todas as permissões seja desmarcadas
    } else {
      this.permissionList.forEach((permission) => {
        /** valor do checkbox recebe false **/
        permission.checked = false;
      });
    }
  }

  /** Método para validar o id das permissões e marcar ou desmarcar o checkbox (utilizado na criação de usuários) **/
  public checkPermission(id: string) {
    this.permissionList.forEach((permission) => {
      /** Verifica se a permissão do usuário **/
      if (permission.id === id) {
        permission.checked = true;
      }
    });
  }

  /** Método responsável pela manipulação do checkbox quando todas as permissões são marcadas ou desmarcadas (utilizado na edição de usuários) **/
  public selectAllPermissionsUpdate(event: any) {
    /** Caso todas as permissões sejam marcadas **/
    if (event.selected) {
      this.permissionListUpdate.forEach((permission: any) => {
        permission.checked = true;
      });
      /** Caso todas as permissões seja desmarcadas **/
    } else {
      this.permissionListUpdate.forEach((permission: any) => {
        permission.checked = false;
      });
    }
  }

  /** Método que realiza a validação de quando o checkbox de permissões é marcado ou desmarcado (utilizado na edição de usuários) **/
  public checkPermissionUpdate(permission: Permission) {
    /** Sempre que ocorrer o evento de checkbox o valor passa a ser invertido (podendo ser marcado e desmarcado) **/
    permission.checked = !permission.checked;
  }

  /** Método responsável pela manipulação do checkbox quando todos os grupos de permissões são marcados ou desmarcados (utilizado na criação de usuários )**/
  public selectAllPermissionGroup(event: any) {
    /** Sempre que ocorrer o evento do checkbox **/
    if (event.selected) {
      /** Caso todos os grupos permissões sejam marcados **/
      this.permissionGroupList.forEach((permissionsGroup) => {
        permissionsGroup.checked = true;
      });
    } else {
      /** Caso todos os grupos permissões sejam desmarcados **/
      this.permissionGroupList.forEach((permissionsGroup) => {
        permissionsGroup.checked = false;
      });
    }
  }

  /** Método para validar o id dos grupos de permissões e marcar ou desmarcar o checkbox (utilizado na criação de usuários) **/
  public checkPermissionGroup(id: string) {
    this.permissionGroupList.forEach((permissionGroup) => {
      if (permissionGroup.id === id) {
        permissionGroup.checked = true;
      }
    });
  }

  /** Método responsável pela manipulação do checkbox quando todos os grupos de permissões são marcados ou desmarcados (utilizado na edição de usuários) **/
  public selectAllPermissionsGroupUpdate(event: any) {
    /** Caso todos os grupos de permissões sejam marcados **/
    if (event.selected) {
      this.permissionGroupListUpdate.forEach((permissionGroup) => {
        permissionGroup.checked = true;
      });
      /** caso todos os grupos de permissões sejam desmarcados **/
    } else {
      this.permissionGroupListUpdate.forEach((permissionGroup) => {
        permissionGroup.checked = false;
      });
    }
  }

  /** Método que realiza a validação de quando o checkbox de grupos de permissões é marcado ou desmarcado (utilizado na edição de usuários) **/
  public checkPermissionGroupUpdate(permissionGroup: PermissionGroup) {
    /** Sempre que ocorrer o evento de checkbox o valor passa a ser invertido (podendo ser marcado e desmarcado) **/
    permissionGroup.checked = !permissionGroup.checked;
  }

  /** Método utilizado para preencher os checkbox com as empresas, permissões e grupo de permissões em que o usuário possui acesso **/
  public setUpdateUser(user: User) {

    /** Verificação de quais empresas o usuário possui **/
    this.companyListUpdate.forEach((company) => {

      company.checked = false;
      /** Para cada usuário compara se a empresa é a mesma existente nas empresas que o usuário tem acesso **/
      user.companies.forEach((companyUser) => {
        /** Caso o usuário tenha acesso as empresa **/
        if (company.id === companyUser.id) {
          /** A empresa é marcada no checkbox**/
          company.checked = true;
        }
      });
    });

    /** Validação para verificar permissões que o usuário possui **/
    this.permissionListUpdate.forEach((permission) => {

      permission.checked = false;

      /** Para cada usuário compara se a permissão é a mesma existente nas permissões do usuário **/
      user.permissions.forEach((permissionUser) => {
        /** Caso o usuário tenha acesso a essa permissão **/
        if (permission.id === permissionUser.id) {
          /** A permissão é marcada no checkbox **/
          permission.checked = true;
        }
      });
    });

    /** Validação para verificar os grupos de permissões que o usuário possui **/
    this.permissionGroupListUpdate.forEach((permissionGroup) => {
      permissionGroup.checked = false;
      /** Para cada usuário compara se a permissão é a mesma existente nas permissões do usuário **/
      user.permissionsGroups.forEach((permissionGroupUser) => {
        /** Caso o usuário tenha acesso a esse grupo de permissão **/
        if (permissionGroup.id === permissionGroupUser.id) {
          /** O grupo de permissão é marcado no checkbox **/
          permissionGroup.checked = true;
        }
      });
    });
  }

  /** Método para fechar o sidebar de edição de usuários **/
  public closeSidebarUpdate() {
    this.isUpdateUser = false;
  }

  /** Método utilizado para preencher os campos do formulário de edição e visualização **/
  public OpenSidebarUpdate(element: User, isViewing: boolean) {

    /** Abre o sidebar de edição **/
    this.isUpdateUser = true;

    /** Variável utilizada para manipular o sidebar de edição/visualização **/
    this.viewController = isViewing;

    /** Caso for clicado no botão de visualização o formulário é desabilitado para edição **/
    if (isViewing === true) {
      this.updateUserForm.disable();
      /** Caso for clicado no botão de edição, habilita o formulário para edição **/
    } else {
      this.updateUserForm.enable();
      this.updateUserForm.controls['userNameUpdateForm'].disable()
    }

    /** armazena o id do usuário selecionado **/
    this.updateUserId = element.id;

    /** Setando os valores dos campos do formulário de edição/visualização **/
    this.updateUserForm.setValue({
      userNameUpdateForm: element.username,
      firstNameUpdateForm: element.firstName,
      lastNameUpdateForm: element.lastName,
      emailUpdateForm: element.email,
      passwordUpdateForm: '',
      superUserUpdateForm: element.superuser,
      isactiveUserUpdateForm: element.isActive,
    });

    /** Chama o método para preencher os campos dos checkbox de empresas, permissões e grupos de permissões **/
    this.setUpdateUser(element);
  }

  /** Método utilizado para (des)ativação de usuários **/
  public activateUser() {
    let selectedUser = this.ELEMENT_DATA.filter(user => user.id === this.updateUserId);
    let password = this.updateUserForm.get('passwordUpdateForm')?.value;

    if (this.updateUserForm.get('isactiveUserUpdateForm')?.value == true) {
      if (this.updateUserForm.get('isactiveUserUpdateForm')?.value != selectedUser[0].isActive) {
        if (password) {
          this.subscriptions = this.manageUserService.userActivation(selectedUser[0].id, password).subscribe({
            next: () => {
              /** Atualiza a tabela com as informações do usuário**/
              this.filterUser(null);
              this.setDataSourceAttributes();
            }
          });
        }
      }
    }
    else {
      this.subscriptions = this.manageUserService.userDeactivation(selectedUser[0].id).subscribe({
        next: () => {
          this.filterUser(null);
          this.setDataSourceAttributes();
        }
      });
    }
  }

  /** Método utilizado para edição de usuários **/
  public updateUser() {

    /** Variável que armazena as empresas checadas **/
    let companies: string[] = [];

    if (this.updateUserForm.get('isactiveUserUpdateForm')?.value == true &&
      !this.updateUserForm.get('passwordUpdateForm')?.value &&
      !this.ELEMENT_DATA.filter(user => user.id === this.updateUserId)[0].isActive) {
      Swal.fire({
        title: 'Informe uma senha para reativar o usuário.',
        text: 'Ele será obrigado e modifica-la após o primeiro acesso',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
      throw "exit";
    };

    /** Adiciona o id da(s) empresa(s) selecionada(s) **/
    this.companyListUpdate.forEach((company) => {
      if (company.checked) {
        companies.push(company.id)
      }
    });

    /** Realiza a requisição de edição de usuários caso todos os campos obrigatórios do formulário estejam preenchidos **/
    if (this.updateUserForm.valid) {
      // variavél responsavel por guardar a valor do campo de senha
      const password = this.updateUserForm.get('passwordUpdateForm')?.value;

      // variavél condicional para adicionar ou nãp o campo de senha na requisição
      const updatedUserRequest = password ?
        this.manageUserService.updateUser(
          this.updateUserId,
          companies,
          this.updateUserForm.get('firstNameUpdateForm')?.value,
          this.updateUserForm.get('lastNameUpdateForm')?.value,
          this.updateUserForm.get('emailUpdateForm')?.value,
          this.updateUserForm.get('superUserUpdateForm')?.value,
          this.isExpiredPassword,
          password
        )
        : this.manageUserService.updateUser(
          this.updateUserId,
          companies,
          this.updateUserForm.get('firstNameUpdateForm')?.value,
          this.updateUserForm.get('lastNameUpdateForm')?.value,
          this.updateUserForm.get('emailUpdateForm')?.value,
          this.updateUserForm.get('superUserUpdateForm')?.value,
          this.isExpiredPassword
        );

      /** Realiza a requisição de edição de usuários **/
      this.subscriptions = updatedUserRequest.subscribe({
        /** Caso a requisição seja realizada com sucesso **/
        next: () => {
          /** Chama a função de (des)ativar os usuários**/
          this.activateUser();

          /** filtro para verificar se o id do usuário selecionado é o mesmo id do usuário a ser atualizado
           * e armazena em uma variável **/
          let selectedUser = this.ELEMENT_DATA.filter(user => user.id === this.updateUserId);

          /** Variável que contem o index do usuário selecionado **/
          let index = this.ELEMENT_DATA.indexOf(selectedUser[0]);

          /** Através do index do usuário selecionado acessa a propriedade 'companies'
           Filtrando na lista de empresas apenas as empresas que forem selecionadas **/
          this.ELEMENT_DATA[index].companies = this.companyListUpdate.filter(company => company.checked === true);

          /** Variáveis utilizadas para manipular as listas com as permissões e grupos de permissões atualizados **/
          let permissions: string[] = [];
          let removePermission: string[] = [];
          let permissionsGroup: string[] = [];

          /** Validação para cada elemento na lista de permissões que for selecionado (checado) **/
          this.permissionListUpdate.forEach((permission) => {
            /** Caso a permissão esteja checada **/
            if (permission.checked === true) {
              /** Adiciona o id das permissões selecionadas **/
              permissions.push(permission.id);
            }
          });

          /** Realiza a requisição para setar as permissões **/
          this.subscriptions = this.manageUserService
            .setPermission(this.updateUserId, permissions)
            .subscribe({
              next: () => {
                /** Através do index do usuário selecionado, acessa a propriedade de "permissions",
                 * filtrando na lista de permissões apenas as permissões que estiverem marcadas (checadas).
                **/
                this.ELEMENT_DATA[index].permissions = this.permissionListUpdate.filter(permission => permission.checked);
              },
              /** Caso ocorra algum erro, exibe no console **/
              error: (error) => {
                console.log('Error on updatePermission mutation', error);
              },
            });

          /** Validação para cada elemento na lista de grupo de permissões que for selecionado (checado) **/
          this.permissionGroupListUpdate.forEach((permissionGroup) => {

            /** Se as permissões estiverem marcadas **/
            if (permissionGroup.checked) {
              /** O id de grupo de permissões é adicionado **/
              permissionsGroup.push(permissionGroup.id);
            }
          });

          /** Realiza a requisição para setar os grupos de permissões **/
          this.subscriptions = this.manageUserService
            .setPermissionGroup(
              this.updateUserId,
              permissionsGroup
            )
            .subscribe({
              next: () => {
                /*** Através do index do usuário selecionado, acessa a propriedade de "permissionsGroups" ,
                 * filtrando na lista de grupos de permissões apenas os grupos de permissões que estiverem marcados (checados).
                 ***/
                this.ELEMENT_DATA[index].permissionsGroups = this.permissionGroupListUpdate.filter(
                  permissionGroup => permissionGroup.checked
                );
              },
              /** Caso ocorra algum erro, exibe no console **/
              error: (error) => {
                console.log('Error on groupSetUser mutation', error);
              },
            });

          /** Validação para cada permissão na lista de permissões que estejam desmarcados no checkbox **/
          this.permissionListUpdate.forEach((permission) => {
            /** Caso as permissões não estejam checadas(selecionadas) no checkbox **/
            if (permission.checked === false) {
              /** Remove o id da permissão desmarcada **/
              removePermission.push(permission.id);
              /** Realiza a requisição para remover a permissão **/
              this.subscriptions = this.manageUserService
                .removePermission(this.updateUserId, removePermission)
                .subscribe({

                  /** Se a requisição for realizada com sucesso **/
                  next: () => {
                    /** Através do index do usuário selecionado, acessa a propriedade de "permissions",
                     * filtrando na lista de permissões apenas as permissões que forem desmarcadas
                    **/
                    this.ELEMENT_DATA[index].permissions = this.permissionListUpdate.filter(permissionRemove => !permissionRemove.checked);
                  },
                  /**  Caso ocorra algum erro na requisição, exibe erro no console **/
                  error: (error) => {
                    console.log('Error on removePermission mutation', error)
                  }
                });
            }
          });

          /** Exibe mensagem de sucesso ao usuário **/
          Swal.fire({
            title: 'Usuário Atualizado ',
            text: 'Usuário Atualizado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancelar'
          })

          /** Atualiza a tabela com os dados alterados **/
          this.filterUser(
            null
            // this.updateUserForm.get('userNameEditForm')?.value,
            // this.updateUserForm.get('emailEditForm')?.value,
            // this.updateUserForm.get('superUserEditForm')?.value
          )

          this.setDataSourceAttributes();

          /** Fecha o sidebar de edição **/
          this.isUpdateUser = false;
        },
        /** Caso ocorra algum erro para realizar o update de usuários **/
        error: (error) => {
          /** Exibe mensagem de erro **/
          this.errorLibService.errorAlert(error);
          console.log('Error on updateUser mutatiton', error);
        },
      });
    }
    /** Caso algum campo obrigatório do formulário não tenha sido preenchido **/
    else {
      Swal.fire({
        title: 'Formulário Incompleto!',
        text: 'Preencha todos os campos.',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
  }
}
