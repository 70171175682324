import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from "@angular/router";

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.less']
})
export class RegisterFormComponent implements OnInit{

  constructor(private router: Router) {

    }

  ngOnInit(): void {
    if (this.router.url !== '/register') {
      this.typesFilter = this.router.url
    }
  }

  filter: boolean = true;

  Filter(): void {
    this.filter = !this.filter;
  }
  typesFilter: string | null = null;

  Router(router: any[]): void {
    if(this.router.url !== '/register') {
      Swal.fire({
        title: 'Deseja mesmo alterar o formulário? Todas as alterações até o momento serão perdidas.',
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: 'Confirmar',
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(router);
        } else if (result.isDenied) {
          this.typesFilter = this.router.url
        }
      });
    }
    else {
      this.router.navigate(router);
    }
  }
}
