/** Classe que armazena as propriedades dos comandos de consulta **/
export class Property {
  constructor(
    public id: string | null,
    public sourceName: string | null,
    public name: string | null,
    public measurementUnit: string | null
  ) { }
}

/** Classe que armazena as propriedades dos comandos de consulta **/
export class QueryCommands {
  constructor(
    public type: string,
    public responseProperties: string[] | null,
  ) { }
}

/** Classe que armazena as propriedades dos comandos de configuração **/
export class ConfigCommands {
  constructor(
    public reference: string | null,
    public targetReference: string | null,
    public type: string | null,
    public minValue: number | null,
    public maxValue: number | null,
    public choices: ParameterTypeInt[] | ParameterTypeBool[] | null | any,
  ) { }
}

/** Classe que armazena as propriedades dos parametros do tipo INT referente aos comandos de configuração **/
export class ParameterTypeInt {
  constructor(
    public minValue: number,
    public maxValue: number
  ) { }
}

/** Classe que armazena as propriedades dos parametros do tipo BOOL referente aos comandos de configuração **/
export class ParameterTypeBool {
  constructor(
    public value: boolean,
    public description: string,
  ) { }
}