// Model das Empresas
export class Company {
    constructor(
        public id: string,
        public name: string | null,
        public cnpj: number | null
        ){}
}


export class PaginationInfo {
  constructor(
    public count: number,
    public total: number,
    public hasNextPage: boolean,
    public hasPreviousPage: boolean,
    public startCursor: string | null,
    public endCursor: string | null
  ){}
}
