<div class="main-container">
  <h1 *ngIf="data.manageMode === 'edit'" mat-dialog-title> {{"alert-rules.manage-alert-rule.edit-title" | translate}}
    '{{data.selectedAlertRule.reference}}'</h1>
  <h1 *ngIf="data.manageMode === 'view'" mat-dialog-title>{{data.selectedAlertRule.reference}}</h1>
  <h1 *ngIf="data.manageMode === 'copy'" mat-dialog-title> {{"alert-rules.manage-alert-rule.copy-title" | translate}}
    '{{data.selectedAlertRule.reference}}'</h1>
  <hr>

  <mat-tab-group animationDuration="300ms">
    <mat-tab label="{{'alert-rules.manage-alert-rule.general-data' | translate}}">
      <div class="container">
        <div class="row mt-3">
          <div class="col-md-6 mb-3">
            <mat-form-field appearance="outline">
              <mat-label> {{"alert-rules.manage-alert-rule.edit-reference" | translate}} </mat-label>
              <input matInput [formControl]="alertRuleReferenceForm">
            </mat-form-field>
          </div>

          <div class="col-md-6 mb-3">
            <mat-form-field appearance="outline">
              <mat-label> {{"alert-rules.manage-alert-rule.edit-users-notified" | translate}} </mat-label>
              <mat-select [disabled]="data.manageMode === 'view'" value="default">
                <mat-option disabled value="default">{{selectedUsersId.length}}
                  {{"alert-rules.manage-alert-rule.edit-users-selected" |
                  translate}}</mat-option>
                <span *ngFor="let user of userList" class="d-flex justify-content-start align-items-center">
                  <mat-checkbox (click)="data.manageMode === 'edit' ? $event.preventDefault() : null"
                    (change)="selectUser($event, user.id)" [checked]="user.checked" class="ms-2 me-2">
                    {{user.firstName}} {{user.lastName}}
                  </mat-checkbox>
                </span>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-1 mb-2">
            <mat-checkbox color="primary"
              (click)="(data.manageMode==='view' || data.manageMode==='edit') ? $event.preventDefault() : null"
              (change)="(data.manageMode==='copy') ? setFullCoverage($event) : null" [formControl]="fullCoverageForm">
              {{"alert-rules.manage-alert-rule.edit-full-coverage" | translate}}
            </mat-checkbox>
          </div>

          <div *ngFor="let rule of data.selectedAlertRule.rules; index as i">
            <div class="col-md-12 d-flex">
              <mat-form-field appearance="outline" style="width: 50%; margin: 3px">
                <mat-label> {{"alert-rules.manage-alert-rule.edit-monitoring-properties" | translate}} </mat-label>
                <mat-select [disabled]="data.manageMode==='view' || data.manageMode==='edit'"
                  [value]="rule.monitoredProperty" (selectionChange)="changePropertiesMonitoredProperty($event ,i)">
                  <mat-option *ngFor="let property of propertiesList" [value]="property.id">
                    {{property.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field style="width: 30%; margin: 3px">
                <mat-select [disabled]="data.manageMode==='view' || data.manageMode==='edit'"
                  (selectionChange)="changePropertiesComparisonRule($event ,i)" [value]="rule.comparisonRule">
                  <mat-option value="EQUALS">=</mat-option>
                  <mat-option value="GREATER_THAN">&gt;</mat-option>
                  <mat-option value="LESS_THAN">&lt;</mat-option>
                  <mat-option value="NOT_EQUALS">&ne;</mat-option>
                  <mat-option value="GREATER_EQUAL">&ge;</mat-option>
                  <mat-option value="LESS_EQUAL">&le;</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field style="width: 10%; margin: 3px"
                *ngIf="!isNetworkIdSelectedArray[i]; else installationSelect">
                <mat-label> {{"alert-rules.manage-alert-rule.edit-value" | translate}} </mat-label>
                <input [disabled]="data.manageMode==='view' || data.manageMode==='edit'"
                  (keyup)="changePropertiesComparisonValue($event.target, i)" [value]="rule.comparisonValue" matInput
                  type="number">
              </mat-form-field>

              <ng-template #installationSelect>
                <div *ngIf="isNetworkIdSelectedArray[i]" class="d-flex" style="width: 20%; margin: 3px">
                  <mat-form-field>
                    <mat-label> {{"alert-rules.manage-alert-rule.edit-search-installation" | translate}} </mat-label>
                    <input matInput [formControl]="installationByReferenceForm" type="text">
                  </mat-form-field>

                  <div class="d-flex flex-column">
                    <mat-icon (click)="searchInstallationByReference(i)" class="search-btn">search</mat-icon>
                    <div *ngIf="isSearchingInstallation[i]">
                      <mat-spinner [diameter]="20"></mat-spinner>
                    </div>
                  </div>
                </div>
              </ng-template>

              <mat-icon *ngIf="i > 0" (click)="removeRule(i)" class="text-black clear-btn">clear</mat-icon>
            </div>

            <div *ngIf="isNetworkIdSelectedArray[i] && installationListNetworkId[i]?.length != 0"
              class="d-flex justify-content-evenly">
              <mat-form-field>
                <mat-label> {{'alert-rules.manage-alert-rule.edit-select-installation' | translate}} </mat-label>
                <mat-select (selectionChange)="selectInstallationMacAddress($event, i)">
                  <mat-option [value]="installation.installationMacAddress"
                    *ngFor="let installation of installationListNetworkId[i]">
                    {{installation.installationReference}} -
                    {{installation.installationMacAddress}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <button [disabled]="data.manageMode==='view' || data.manageMode==='edit'" class="btn btn-primary"
                (click)="addNewRule()">
                {{"alert-rules.manage-alert-rule.edit-add-rule" | translate}}
              </button>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-6 mb-3">
            <mat-form-field>
              <mat-label> {{"alert-rules.manage-alert-rule.edit-alert-time-window" | translate}} </mat-label>
              <input [formControl]="alertTimeWindowForm" matInput type="number">
            </mat-form-field>
          </div>

          <div class="col-md-6 mb-3">
            <mat-form-field>
              <mat-label> {{"alert-rules.manage-alert-rule.edit-number-events" | translate}} </mat-label>
              <input [formControl]="alertEventMaxCountForm" matInput type="number">
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 mb-3">
            <mat-form-field appearance="outline" class="example-form-field">
              <mat-label>{{"alert-rules.manage-alert-rule.edit-start-date" | translate}} </mat-label>
              <input matInput [matDatepicker]="datepickerstart" [formControl]="startDateForm">
              <mat-datepicker-toggle matIconSuffix [for]="datepickerstart"></mat-datepicker-toggle>
              <mat-datepicker #datepickerstart>
                <mat-datepicker-actions>
                  <button mat-raised-button class="btn btn-success" matDatepickerApply>Aplicar</button>
                  <button mat-button matDatepickerCancel class="btn btn-warning me-1">Cancelar</button>
                </mat-datepicker-actions>
              </mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-md-6 mb-3">
            <mat-form-field appearance="outline" class="example-form-field">
              <mat-label> {{"alert-rules.manage-alert-rule.edit-end-date" | translate}} </mat-label>
              <input matInput [matDatepicker]="datepickerend" [formControl]="endDateForm">
              <mat-datepicker-toggle matIconSuffix [for]="datepickerend"></mat-datepicker-toggle>
              <mat-datepicker #datepickerend>
                <mat-datepicker-actions>
                  <button mat-raised-button class="btn btn-success" matDatepickerApply>{{"edit-button-apply" |
                    translate}}</button>
                  <button mat-button matDatepickerCancel class="btn btn-warning me-1"> {{"edit-button-cancel" |
                    translate}}
                  </button>
                </mat-datepicker-actions>
              </mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 mt-1 mb-2">
            <label> {{"alert-rules.manage-alert-rule.edit-start" | translate}} </label>
            <ngx-timepicker-field [format]="24" [defaultTime]="'12:00'" class="p-2"
              [formControl]="startTimeForm"></ngx-timepicker-field>
          </div>

          <div class="col-md-3 mt-1 mb-2">
            <label> {{"alert-rules.manage-alert-rule.edit-end" | translate}} </label>
            <ngx-timepicker-field [format]="24" [defaultTime]="'12:00'" class="p-2"
              [formControl]="endTimeForm"></ngx-timepicker-field>
          </div>

          <div class="col-md-6">
            <p> {{"alert-rules.manage-alert-rule.edit-week-days" | translate}}</p>
            <mat-button-toggle-group name="dias" aria-label="Dias" multiple>
              <mat-button-toggle *ngFor="let days of data.selectedAlertRule.weekdays; index as i"
                [disabled]="data.manageMode==='view' || data.manageMode==='edit'" value="1" [checked]="days.selected"
                (change)="calculateWeekDays($event, i)">
                {{days.day}}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{'alert-rules.manage-alert-rule.selected-installations' | translate}}">
      <div class="row m-2" *ngIf="data.manageMode === 'edit' || data.manageMode === 'copy'">
        <h3 class="font-weight-bold m-2"> Filtro de instalações </h3>
        <div class="col-md-3">
          <mat-form-field appearance="outline">
            <mat-label> {{"alert-rules.manage-alert-rule.edit-installation-reference" | translate}} </mat-label>
            <input [formControl]="instRefSearchForm" matInput>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field class="example-full-width">
            <mat-label> {{"alert-rules.manage-alert-rule.edit-installation-gateway" | translate}} </mat-label>
            <mat-select multiple>
              <mat-option>
                SGGateway Web
              </mat-option>
              <mat-option>
                SGSei lá Web
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field class="example-full-width">
            <mat-label> {{"alert-rules.manage-alert-rule.edit-installation-model" | translate}} </mat-label>
            <mat-select multiple>
              <mat-option>
                SGGCorte
              </mat-option>
              <mat-option>
                Consumo Pulso V.2.0
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field class="example-full-width">
            <mat-label> {{"alert-rules.manage-alert-rule.edit-installation-favorites" | translate}} </mat-label>
            <mat-select multiple>
              <mat-option>
                Instalação 1
              </mat-option>
              <mat-option>
                Instalação 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4 mb-2 mt-2">
          <button (click)="searchInstallation()" [disabled]="!instRefSearchForm.valid" class="btn btn-primary">
            {{"alert-rules.manage-alert-rule.edit-button-search-installation" | translate}}
          </button>
        </div>
      </div>
      <div *ngIf="data.manageMode === 'edit' || data.manageMode === 'copy'" class="row m-2">
        <h3 class="font-weight-bolder m-2"> {{"alert-rules.manage-alert-rule.edit-installation-new-installations" |
          translate}}
        </h3>
        <hr>
        <div class="chip-div">
          <mat-chip-listbox>
            <mat-chip *ngFor="let installation of searchedInstallations">
              <span>{{installation.installationReference}}</span>
              <mat-icon (click)="deleteInstallation(installation.installationId)" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-listbox>
        </div>
      </div>
      <div class="row m-2">
        <h3 class="font-weight-bold m-2"> {{"alert-rules.manage-alert-rule.edit-installation-rule" | translate}} </h3>
        <hr>
        <div class="preselected-chip-div" [ngClass]="data.manageMode === 'view' ? 'chip-grow' : 'preselected-chip-div'">
          <mat-chip-listbox>
            <mat-chip *ngFor="let installation of preSelectedInstallation| keyvalue">
              <span>{{installation.value}}</span>
            </mat-chip>
          </mat-chip-listbox>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="actions">
    <button mat-button *ngIf="data.manageMode === 'edit'" cdkFocusInitial class="btn btn-success m-2"
      (click)="saveEditData()"> {{"alert-rules.manage-alert-rule.edit-button-save" | translate}} </button>

    <button mat-button (click)="confirmationCall()"
      *ngIf="(data.manageMode === 'edit' && data.selectedAlertRule.isActive) && !actionConfirmation" kFocusInitial
      class="btn btn-danger m-2">{{"alert-rules.manage-alert-rule.edit-button-deactivate-rule" | translate}} </button>

    <button mat-button (click)="confirmationCall()"
      *ngIf="(data.manageMode === 'view' && !data.selectedAlertRule.isActive) && !actionConfirmation" kFocusInitial
      class="btn btn-success m-2"> {{"alert-rules.manage-alert-rule.edit-button-activate-rule"| translate}}</button>

    <mat-button-toggle-group *ngIf="actionConfirmation" #group="matButtonToggleGroup">
      <mat-button-toggle class="confirmation-btn"
        (change)="data.manageMode === 'view' ? confirmAction('activate') : confirmAction('deactivate')" value="left"
        aria-label="Confirmar"> {{"alert-rules.manage-alert-rule.edit-button-confirm" |
        translate}}<mat-icon>check_circle</mat-icon>
      </mat-button-toggle>

      <mat-button-toggle class="cancel-btn" (change)="actionConfirmation = false" value="center" aria-label="Cancelar">
        Cancelar? <mat-icon>cancel</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <div *ngIf="isCreatingNewCopy; else btnSave" class="component-spinner-box-sm d-flex">
      <mat-spinner [diameter]="40"></mat-spinner>
      <p>{{"alert-rules.manage-alert-rule.generate-copy" | translate}} </p>
    </div>
    <ng-template #btnSave>
      <button mat-button (click)="saveAlertRuleCopy()" *ngIf="data.manageMode === 'copy'" class="btn btn-success m-2">
        {{"alert-rules.manage-alert-rule.save-copy" | translate}}</button>
      <button mat-button [mat-dialog-close]="" cdkFocusInitial class="btn btn-primary m-2">
        {{"alert-rules.manage-alert-rule.button-close" | translate}}
      </button>
    </ng-template>
  </div>
</div>