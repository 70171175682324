import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { FILTERS_UNIT_MEASUREMENT } from "src/app/graphql/measurement-unit.graphql";
import { CREATE_PROPERTIES, FILTER_PROPERTIES, UPDATE_PROPERTIES } from "src/app/graphql/properties.grapqhl";

@Injectable({
    providedIn: 'root'
})

export class PropertiesService {
    constructor(
        private apollo: Apollo,
    ) { }

    /** Retorna a requisição que realiza o filtro de todas as propriedades **/
    filterProperties(nameContains: string | null, cursor: string | null, pageSize: number | null) {
        return this.apollo.watchQuery({
            query: FILTER_PROPERTIES,
            fetchPolicy: 'no-cache',
            variables: {
                cursor,
                pageSize,
                nameContains,
            }
        })
    }

    /** Retorna a requisição que realiza o filtro de todas as Unidades de medida **/
    getMeasurementUnit() {
        return this.apollo.watchQuery({
            query: FILTERS_UNIT_MEASUREMENT,
            fetchPolicy: 'network-only',
            variables: {
                pageSize: 100
            }
        })
    }

    /** Retorna a mutation de criaçãod de nova propriedade **/
    createProperties(name: string | null, sourcename: string, shortname: string, service: string | null, measurementunit: string | null) {
        return this.apollo.mutate({
            mutation: CREATE_PROPERTIES,
            variables: {
                name: name,
                sourcename: sourcename,
                shortname: shortname,
                service: service,
                measurementunit: measurementunit
            }
        })
    }

    /** Retorna a mutation que realiza o update das propriedades **/
    updateProperties(
        id: string,
        name: string | null,
        sourcename: string | null,
        shortname: string | null,
        service: string | null,
        measurementunit: any | null
    ) {
        return this.apollo.mutate({
            mutation: UPDATE_PROPERTIES,
            fetchPolicy: 'no-cache',
            variables: {
                id,
                name,
                sourcename,
                shortname,
                service,
                measurementunit
            }
        })
    }
}

