<div class="component-main-container">
    <div class="header">
        <app-page-header parentTitle="{{'imports-csv.parentTitle' | translate}}"></app-page-header>
    </div>
    <div class="row">
        <div class="col-lg-4 col-xl-4 col-sm-12">
            <div class="component-forms-box">
                <app-imports-csv-form></app-imports-csv-form>
            </div>
        </div>
    </div>
</div>