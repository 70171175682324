<div class="component-main-container">
    <app-page-header parentTitle="{{'imports-csv.parentTitle' | translate}}"
        pageTitle="{{'imports-csv.pageTitle-update-equipments' | translate}}">
    </app-page-header>
    <div class="row">
        <div class="col-lg-12 col-xl-12 col-sm-12">
            <div class="component-forms-box">
                <div class="form-fields-box">
                    <mat-stepper class="example-stepper" #stepper>
                        <mat-step label="{{'imports-csv.step-load-file' | translate}}">
                            <form>
                                <h4> {{'imports-csv.update-equipment-title' | translate}} </h4>
                                <div class="input-group">
                                    <input type="file" class="form-control" id="inputGroupFile04" [accept]="'.csv'"
                                        aria-describedby="inputGroupFileAddon04" aria-label="Upload" #fileUploadSimple
                                        (change)="getTextFromFile($event)">
                                    <br>
                                    <button class="btn btn-secondary" type="button" id="inputGroupFileAddon04"
                                        (click)="importDataFromCSV()" type="submit" [disabled]="disableImportButton">
                                        {{'imports-csv.button-import-csv' |
                                        translate}}
                                    </button>
                                    <mat-progress-bar mode="indeterminate" *ngIf="loadingUpdate"></mat-progress-bar>
                                    <p *ngIf="loadingUpdate"
                                        style="color: red;margin-right: auto; margin-left: auto; margin-top: 17px">
                                        {{'imports-csv.loading-update' | translate}}
                                    </p>
                                </div>
                                <p id="text-information" *ngIf="logReadyMessage">
                                    {{'imports-csv.text-available-logs' | translate}}
                                </p>
                                <br>
                                <div>
                                    <button mat-button (click)="goToStep(2)">
                                        {{'imports-csv.doubts-importing' | translate}}
                                        <mat-icon iconPositionEnd>info</mat-icon>
                                    </button>
                                </div>

                                <button [disabled]="!isExported" style="margin: 2px" mat-fab color="primary" extended
                                    matStepperNext>
                                    <mat-icon iconPositionEnd>keyboard_arrow_right</mat-icon>
                                    {{'imports-csv.next-page' | translate}}
                                </button>
                            </form>
                        </mat-step>
                        <mat-step label="{{'imports-csv.step-export-logs' | translate}}">
                            <form>
                                <mat-spinner *ngIf="loadingUpdate"></mat-spinner>
                                <div>
                                    <p *ngIf="!loadingUpdate && !isExported"
                                        style="color: red;margin-right: auto; margin-left: auto; margin-top: 17px">
                                        {{'imports-csv.no-logs-message' | translate}}
                                    </p>
                                </div>
                                <div>
                                    <h4 *ngIf="isExported"> {{'imports-csv.import-completed-message' | translate}} </h4>
                                    <img *ngIf="isExported"
                                        src='../../../../../../../assets/imports-csv-imgs/concluded.png'>
                                </div>
                                <p *ngIf="loadingUpdate"
                                    style="color: red;margin-right: auto; margin-left: auto; margin-top: 17px">
                                    {{'imports-csv.waiting-logs' | translate}} </p>
                                <br>
                                <button mat-flat-button color="success" *ngIf="isExported" (click)="exportLogs()">
                                    {{'imports-csv.button-export-logs' | translate}}
                                </button>

                                <br>
                                <button style="margin: 2px; top: 7px;" mat-fab color="primary" extended
                                    matStepperPrevious>
                                    <mat-icon iconPositionStart>keyboard_arrow_left</mat-icon>
                                    {{'imports-csv.previous-page' | translate}}
                                </button>
                                <button style="margin: 2px" mat-fab color="primary" extended matStepperNext>
                                    <mat-icon iconPositionEnd>keyboard_arrow_right</mat-icon>
                                    {{'imports-csv.next-page' | translate}}
                                </button>
                            </form>
                        </mat-step>
                        <mat-step label="{{'imports-csv.step-doubts' | translate}}" aria-disabled="true">
                            <form>
                                <h4> {{'imports-csv.doubts-update-equipment-title' | translate}} </h4>
                                <img src='../../../../../../../assets/imports-csv-imgs/download-files.jpg'>
                                <div>
                                    <h3> {{'imports-csv.doubt-import-info' | translate}} <br>
                                        {{'imports-csv.doubt-import-info-update-equipment' | translate}}
                                    </h3>
                                </div>
                                <div>
                                    <button (click)="openDialog()" mat-flat-button color="primary">
                                        {{'imports-csv.how-to-send-file' | translate}}
                                    </button>
                                </div>
                                <a class="button-download-example" color="primary" mat-button
                                    (click)="downloadCsvFile()" target="_blank">{{'imports-csv.example-download' |
                                    translate}}</a>
                                <div>
                                    <button style="margin: 2px; top: 7px;" mat-fab color="primary" extended
                                        matStepperPrevious>
                                        <mat-icon iconPositionStart>keyboard_arrow_left</mat-icon>
                                        {{'imports-csv.previous-page' | translate}}
                                    </button>
                                </div>
                            </form>
                        </mat-step>
                    </mat-stepper>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #dialogTemplate let-dialogRef="dialogRef">
    <div class="dialogRef">
        <h2 mat-dialog-title>{{'imports-csv.instructions-import-update-equipment' | translate}} </h2>
        <mat-dialog-content class="mat-typography">
            <h3>{{'imports-csv.import-description' | translate}}</h3>
            <p>{{'imports-csv.import-update-equipments-description' | translate}}</p>
            <h3>{{'imports-csv.preparation-file-title' | translate}}</h3>
            <p>{{'imports-csv.preparation-file-text' | translate}}</p>
            <ul>
                <li>{{'imports-csv.preparation-file-semicolon' | translate}} (<code>;</code>).</li>
                <li>{{'imports-csv.preparation-file-filled-fields' | translate}}</li>
                <li>{{'imports-csv.preparation-header' | translate}}</li>
            </ul>
            <p>
                <span>equipmentType<code>;</code>macAddress<code>;</code>serialNumber</span>
            </p>

            <h3>{{'imports-csv.field-details' | translate}}</h3>
            <table border="1" cellpadding="5" cellspacing="0">
                <thead>
                    <tr>
                        <th>{{'imports-csv.field' | translate}}</th>
                        <th>{{'imports-csv.expected-type' | translate}}</th>
                        <th>{{'imports-csv.content' | translate}}</th>
                        <th>{{'imports-csv.mandatory' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>equipmentType</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-equipment-type' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-yes' | translate}}</td>
                    </tr>
                    <tr>
                        <td>macAddress</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-mac-address' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-yes' | translate}}</td>
                    </tr>
                    <tr>
                        <td>serialNumber</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-serial-number' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-yes' | translate}}</td>
                    </tr>
                </tbody>
            </table>
            <br>
            <h3> {{'imports-csv.file-structure-title' | translate}} </h3>
            <p>{{'imports-csv.file-structure-text' | translate}}</p>
            <pre>equipmentType<code>;</code>macAddress<code>;</code>serialNumber<br>EQUIPTYPE01<code>;</code>ABC12345678<code>;</code>123456789</pre>
            <ul>
                <li> {{'imports-csv.info-field-equipment-type' | translate}} </li>
            </ul>
            <b>{{'imports-csv.info-field-equipment-type-example' | translate}} <span>SGIPF
                    5<code>.</code>0<code>.</code>0</span></b>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-flat-button color="success" mat-dialog-close>OK</button>
        </mat-dialog-actions>
    </div>
</ng-template>