import { createReducer, on } from "@ngrx/store";
import { Installation } from "../../painel-fav/painel-fav.model";
import { addFavorite } from "./favorite.actions";

export interface Favorite {
    reference: string;
    installationList: Installation[];
}

export interface Favorites {
    favorites: {}
}

export const initialStateFavorites: Favorites = {
    favorites: {}
};

export const FavoriteReducer = createReducer(
    initialStateFavorites,
    on(addFavorite, (state, { favorite }) => {
        return {
            ...state,
            favorites: favorite
        };
    })
)

