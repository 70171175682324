<mat-accordion *ngIf="panelOpenState">
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-icon>assignment_turned_in</mat-icon>
      <mat-panel-title>
        {{ 'panel-selected.title-label' | translate }}
      </mat-panel-title>
      <mat-panel-description>
        {{ selectedOption.length }}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="select-manipulate">
      <mat-icon class="position-closer" (click)=" closePainelFav()">clear</mat-icon>
      <h3>{{ 'panel-selected.manipulate-label' | translate }}
      </h3>
    </div>

    <div class="select-wrapper">
      <input class="input-filter" (focus)="onFocus()" type="text" [(ngModel)]="searchTerm"
        (ngModelChange)="filterOptions()" placeholder="{{ 'panel-selected.filter-label' | translate }}">

      <div class="select" [ngClass]="{ 'small-div': !isInputFocused }">

        <div class="selected-options">

          <div class="option">

            <input class="input-checkbox" type="checkbox" #check (click)="selectAllOptions(check.checked)">

            <span class="select-label">{{ 'panel-selected.select-all' | translate }}</span>
          </div>

          <div class="option" *ngFor="let option of filteredOptions" [class.checked]="option.check">

            <input class="input-checkbox" type="checkbox" [(ngModel)]="option.check" (change)="toggleOption(option)">

            <span class="select-label">{{ option.reference }}</span>

          </div>

        </div>

      </div>

    </div>

    <div class="hr"></div>

    <h3>{{ 'panel-selected.favorite-selected' | translate }}</h3>

    <input type="text" class="reference-favorites" [formControl]="favoriteReference"
      placeholder="{{ 'panel-selected.favorite-name-label' | translate }}">

    <button class="btn btn-light save-favorites" (click)="addFavorite()" [disabled]="favoriteReference.invalid">{{
      'panel-selected.button-save' | translate }}</button>
  </mat-expansion-panel>
</mat-accordion>