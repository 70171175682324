<div class="component-main-container">
  <!-- Sessão cabeçalho -->
  <div class="header">
    <app-page-header parentTitle="{{ 'lamps-type.lamps-type-label' | translate }}"
      pageTitle="{{ 'lamps-type.generate-lamps-type-label' | translate }}">
    </app-page-header>
  </div>
  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-12">
      <div class="component-forms-box">
        <!-- Tipos de registros -->
        <app-register-form></app-register-form>

        <!-- Sessão de filtro de tipos Luminárias -->
        <div class="form-fields-box" [ngClass]="!filterLampsBox ? 'closed' : ''" [formGroup]="filterLampsForm">
          <h2 (click)="filterLampsBoxOpen()">
            {{ "lamps-type.filter-lamps-type" | translate }}
          </h2>
          <hr [ngClass]="!filterLampsBox ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "lamps-type.label-reference" | translate }}
              </mat-label>
              <input matInput formControlName="referenceFilterForm" #manufacturer />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number circle-number-ready">2</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "lamps-type.label-power" | translate }}
              </mat-label>
              <input matInput type="number" min="0" formControlName="powerFilterForm" #power />
            </mat-form-field>
          </div>

          <!-- Botões de filtro -->
          <div class="d-flex m-2">
            <button *ngIf="!filterLoadingLampsType" class="btn btn-success m-1 w-50" (click)="filterLampsType(null)">
              {{ "lamps-type.button-filter" | translate }}
            </button>
            <mat-spinner *ngIf="filterLoadingLampsType" [diameter]="30"></mat-spinner>
            <button *ngIf="!filterLoadingLampsType" class="btn btn-secondary m-1 w-50" (click)="resetFilter()">
              {{ "lamps-type.button-reset-filter" | translate }}
            </button>
          </div>
        </div>

        <!-- Sessão de registro de tipos de Luminárias -->
        <div class="form-fields-box" [formGroup]="registerLampsForm" [ngClass]="!registerLampsBox ? 'closed' : ''">
          <h2 (click)="registerLampsBoxOpen()">
            {{ "lamps-type.register-lamps-type" | translate }}
          </h2>
          <hr [ngClass]="!registerLampsBox ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "lamps-type.label-reference" | translate }}
              </mat-label>
              <input matInput formControlName="modelRegisterForm" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number circle-number-ready">2</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "lamps-type.label-manufacturer" | translate }}
              </mat-label>
              <input matInput formControlName="manufacturerRegisterForm" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">3</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "lamps-type.dimmings-type" | translate }}
              </mat-label>
              <mat-select formControlName="dimmerTypeRegisterForm">
                <mat-option appearance="outline" value="T_DALI">
                  DALI
                </mat-option>
                <mat-option value="T_1_10V" appearance="outline">
                  1-10V
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Sessão de descrições -->
          <div class="form-step descriptions">
            <div class="circle-number circle-number-ready">4</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "lamps-type.descriptions-power" | translate }}
              </mat-label>
              <input matInput type="number" min="0" formControlName="powerRegisterForm" title="Potência(W)" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>
                {{ "lamps-type.descriptions-brightness" | translate }}
              </mat-label>
              <input matInput type="number" min="0" formControlName="brightnessRegisterForm" title="Brilho(K)" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>
                {{ "lamps-type.descriptions-consumption" | translate }}
              </mat-label>
              <input matInput type="number" min="0" formControlName="consumptionRegisterForm" title="Consumo" />
            </mat-form-field>
          </div>

          <!-- Sessão de descrições -->
          <div class="form-step descriptions">
            <app-circle-number stepNumber="4.1" [isSubstep]="true">
            </app-circle-number>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "lamps-type.descriptions-current" | translate }}
              </mat-label>
              <input matInput type="number" min="0" formControlName="currentRegisterForm" title="Corrente(mA)" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>
                {{ "lamps-type.descriptions-powerFactor" | translate }}
              </mat-label>
              <input matInput type="number" min="0" formControlName="powerFactorRegisterForm"
                title="Fator de potência" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>
                {{ "lamps-type.descriptions-voltage" | translate }}
              </mat-label>
              <input matInput type="number" min="0" formControlName="voltageRegisterForm" title="Tensão(V)" />
            </mat-form-field>
          </div>

          <!-- botão de registro de tipos de luminárias -->
          <button class="btn btn-success" (click)="createLampsType()">
            {{ "lamps-type.button-save" | translate }}
          </button>
        </div>
      </div>
    </div>

    <!-- Sessão table header -->
    <div class="col-lg-7 col-xl-8 col-sm-12">
      <app-table-header *ngIf="length > 0" [itensNumber]="paginationProperties.count"
        [totalNumber]="paginationProperties.total" [hasCSV]="true" [hasPDF]="true"
        (downloadCSV)="generateFileData(null, 'CSV')" (downloadPDF)="generateFileData(null, 'PDF')"
        [loadingData$]="fileLoading">
      </app-table-header>
      <!-- Sessão da Tabela -->
      <div *ngIf="ELEMENT_DATA.length" class="table-div mt-2 mt-lg-3 mt-lg-3">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="manufacturer">
            <th mat-header-cell *matHeaderCellDef>
              {{ "lamps-type.manufacturer-table" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              {{ element.manufacturer }}
            </td>
          </ng-container>

          <ng-container matColumnDef="power">
            <th mat-header-cell *matHeaderCellDef>
              {{ "lamps-type.power-table" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              {{ element.power }}
            </td>
          </ng-container>

          <ng-container matColumnDef="dimmingType">
            <th mat-header-cell *matHeaderCellDef>
              {{ "lamps-type.dimming-type-table" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.dimmingType === 'T_DALI'">DALI</span>
              <span *ngIf="element.dimmingType === 'T_1_10V'">1-10V</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>
              {{ "lamps-type.description-table" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              {{ "lamps-type.brightness-table" | translate }} ({{
              element.brightness
              }}) {{ "lamps-type.consumption-table" | translate }}({{
              element.consumption
              }})<br />
              {{ "lamps-type.current-table" | translate }} ({{
              element.current
              }}) {{ "lamps-type.power-factor-table" | translate }} ({{
              element.powerFactor
              }}) <br />
              {{ "lamps-type.voltage-table" | translate }} ({{
              element.voltage
              }})
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              {{ "lamps-type.actions-table" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <button [ngClass]="element.view === true ? 'hover' : ' '" class="btn btn-success mb-2 view" (click)="
                  updateGetter(
                    element.id,
                    element.model,
                    element.brightness,
                    element.consumption,
                    element.current,
                    element.dimmingType,
                    element.manufacturer,
                    element.power,
                    element.powerFactor,
                    element.voltage,
                    false
                  )
                ">
                <mat-icon class="component-svg-icon" svgIcon="bx-edit"></mat-icon>
              </button>

              <button [ngClass]="element.view === true ? 'hover' : ' '" class="btn btn-success mb-2 gap-2 view" (click)="
                  updateGetter(
                    element.id,
                    element.model,
                    element.brightness,
                    element.consumption,
                    element.current,
                    element.dimmingType,
                    element.manufacturer,
                    element.power,
                    element.powerFactor,
                    element.voltage,
                    true
                  )
                ">
                <mat-icon class="component-svg-icon" svgIcon="bx-show-white"></mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" (page)="pageChanged($event)"
          aria-label="Selecione as páginas de Luminárias" class="mat-paginator-sticky">
        </mat-paginator>
      </div>
    </div>
  </div>

  <!-- Componente de edição -->
  <div class="sidebar-edit" *ngIf="editorController" [formGroup]="updateForm">
    <mat-icon (click)="closeEditionSideBar()">clear</mat-icon>
    <h2 *ngIf="viewController === false">
      {{ "lamps-type.form-edit-label" | translate }}
    </h2>
    <h2 *ngIf="viewController === true">
      {{ "lamps-type.form-view-label" | translate }}
    </h2>
    <hr>
    <mat-form-field appearance="outline">
      <mat-label> {{ "lamps-type.label-reference" | translate }} </mat-label>
      <input matInput formControlName="modelUpdateForm" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label> {{ "lamps-type.label-manufacturer" | translate }} </mat-label>
      <input matInput formControlName="manufacturerUpdateForm" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label> {{ "lamps-type.dimmings-type" | translate }} </mat-label>
      <mat-select formControlName="dimmerTypeUpdateForm">
        <mat-option appearance="outline" value="T_DALI"> DALI </mat-option>
        <mat-option value="T_1_10V" appearance="outline"> 1-10V </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Sessão de descrições -->
    <div class="form-step descriptions">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "lamps-type.descriptions-power" | translate }}
        </mat-label>
        <input matInput type="number" min="0" formControlName="powerUpdateForm" title="Potência(W)" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>
          {{ "lamps-type.descriptions-brightness" | translate }}
        </mat-label>
        <input matInput type="number" min="0" formControlName="brightnessUpdateForm" title="Brilho(K)" />
      </mat-form-field>
    </div>

    <div class="form-step descriptions">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "lamps-type.descriptions-consumption" | translate }}
        </mat-label>
        <input matInput type="number" min="0" formControlName="consumptionUpdateForm" title="Consumo" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>
          {{ "lamps-type.descriptions-current" | translate }}
        </mat-label>
        <input matInput type="number" min="0" formControlName="currentUpdateForm" title="Corrente(mA)" />
      </mat-form-field>
    </div>

    <div class="form-step descriptions">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ "lamps-type.descriptions-powerFactor" | translate }}
        </mat-label>
        <input matInput type="number" min="0" formControlName="powerFactorUpdateForm" title="Fator de potência" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>
          {{ "lamps-type.descriptions-voltage" | translate }}
        </mat-label>
        <input matInput type="number" min="0" formControlName="voltageUpdateForm" title="Tensão(V)" />
      </mat-form-field>
    </div>

    <button class="btn btn-success" *ngIf="!viewController" (click)="updateLampType()">
      {{ "lamps-type.form-edit-button" | translate }}
    </button>
  </div>
</div>