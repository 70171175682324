import { gql } from 'apollo-angular';

/** mutation para criação de gateways **/
const CREATE_GATEWAY_INSTALLATION = gql`
  mutation GatewayInstallationCreate(
    $reference: String!
    $equipment: ID!
    $site: ID!
  ) {
    gatewayInstallationCreate(
      input: { reference: $reference, deviceId: $equipment, site: $site }
    ) {
      gatewayInstallation {
        id
      }
    }
  }
`;

/** Query para filtrar os gateways **/
const FILTER_GATEWAYS = gql`
query GatewayInstallation(
  $company: ID!,
  $reference: String,
  $country: String,
  $state: String,
  $city: String,
  $district: String,
  $street: String,
  $pageSize: Int,
  $cursor: String,
  $deviceid: ID,
  $keyword: String,
  $sort_dir: Direction!,
  $sort_field: GatewayInstallationOrderFields!
  ) {
  gatewayInstallation(
    company: $company,
    referenceContains: $reference,
    country: $country,
    state: $state,
    city: $city,
    district: $district,
    street: $street,
    search: $keyword,
    device: $deviceid,
    pageSize: $pageSize,
    cursor: $cursor,
    orderBy:{
      direction: $sort_dir,
      field: $sort_field
      }
    ) {
    edges {
      node {
        id
        reference
        site {
          id
          reference
          country
          state
          city
          district
          street
          number
          details
          geoposition {
            latitude
            longitude
          }
        }
        device {
          serialNumber
          id
          equipmentType {
            reference
            major
            minor
            revision
          }
        }
        isActive
      }
      cursor
    }
    total
    count
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}`;
/** Mutation para realizar a edição dos gateways **/
const UPDATE_GATEWAY = gql`
mutation GatewayInstallationUpdate(
	$id: ID!,
	$reference: String!,
	$equipment: ID!,
	$site: ID!
	$isActive: Boolean!
) {
		gatewayInstallationUpdate (
			input: {
				id: $id,
				reference: $reference,
				deviceId: $equipment,
				site: $site
				isActive: $isActive
			}) {
		gatewayInstallation {
			id
			reference
			isActive
			site{
				id
				reference
				state
				street
				country
				district
				number
				city
				geoposition{
					id
				latitude
				longitude
				}
			}
			device{
				id
				macAddress
				serialNumber
				equipmentType{
          id
          reference
          major
          minor
          revision
				}
			}
		}
	}
}`;

/** Mutation para realizar a atualização do status do gateway **/
const UPDATE_STATUS_GATEWAY = gql`
mutation GatewayInstallationUpdate(
	$id: ID!
	$reference: String!
	$equipment: ID!
	$site: ID!
	$isActive: Boolean!
) {
	gatewayInstallationUpdate(
		input: {
			id: $id
			reference: $reference
			deviceId: $equipment
			site: $site
			isActive: $isActive
		}
	) {
		gatewayInstallation {
			id
			reference
			isActive
			site {
				id
			}
			device {
				id
			}
		}
	}
}`;

const GET_GATEWAY_FOR_FILTER = gql`
query gateway(
  $company: ID,
  $pageSize: Int
) {
  gatewayInstallation(
    company: $company,
    pageSize: $pageSize
    orderBy: { direction: ASC, field: REFERENCE }
  ) {
    edges{
      node {
      id
      reference
      device{
        serialNumber
      }
    }
  }
    total
    count
    pageInfo {
      hasNextPage
    }
  }
}`;

export {
  // Queries
  CREATE_GATEWAY_INSTALLATION,
  FILTER_GATEWAYS,
  GET_GATEWAY_FOR_FILTER,
  // Mutations
  UPDATE_GATEWAY,
  UPDATE_STATUS_GATEWAY,
};
