import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserprofileService } from 'src/shared/services/userprofile.service';
import Swal from 'sweetalert2';
import { User } from '../../models/user.model';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-useredit',
  templateUrl: './useredit.component.html',
  styleUrls: ['./useredit.component.less']
})
export class UsereditComponent extends Unsubscriber implements OnInit {
  emailFormEdit: FormControl = new FormControl('');
  userFormEdit: FormControl = new FormControl('');
  nameFormEdit: FormControl = new FormControl('');

  @Input() user?: User;
  @Output() newUserData = new EventEmitter<User>();

  constructor(private userprofileService: UserprofileService) {
    super();
  }

  ngOnInit(): void {
    this.emailFormEdit.setValue(this.user?.email);
    this.userFormEdit.setValue(this.user?.username);
    this.nameFormEdit.setValue(this.user?.name);
  }

  public cancelEdit(): void {
    this.userprofileService.cancelEdit.emit(false);
  }

  public saveEdit(): void {
    this.subscriptions = this.userprofileService.editProfile(
      this.emailFormEdit.value,
      this.userFormEdit.value,
      this.nameFormEdit.value).subscribe((() => {
        this.sendNewUserData();
        localStorage.setItem('username', this.userFormEdit.value);
        this.cancelEdit();
      }),
        (e => {
          console.log(e);
        }),
      )
  }

  public sendNewUserData(): void {
    let newUser = new User(this.userFormEdit.value, this.emailFormEdit.value, this.nameFormEdit.value)
    this.newUserData.emit(newUser);
  }
}
