import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { EquipmentTypeService } from 'src/shared/services/equipment-type.service';
import Swal from 'sweetalert2';
import { Property, QueryCommands } from './model';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
import { MatSelectChange } from '@angular/material/select';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-equipment-type-modal',
  templateUrl: './equipment-type-modal.component.html',
  styleUrls: ['./equipment-type-modal.component.less']
})

export class QueryCommandsModalComponent extends Unsubscriber implements OnInit {

  /** Subject utilizado para emitir os dados referente aos comandos de consulta **/
  private commandsSubject = new BehaviorSubject<any[]>([]);
  public commands$ = this.commandsSubject.asObservable();

  constructor(
    public dialogRef: MatDialogRef<QueryCommandsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public equipmentTypeService: EquipmentTypeService,
    public formBuilder: FormBuilder,
    public dialog: MatDialog,

  ) {
    super();
  }

  ngOnInit(): void {

    /** Realiza a requisição que filtra as propriedades assim que o componente é inicializado **/
    this.subscriptions = this.equipmentTypeService.filterProperty().valueChanges.subscribe({

      next: ((response: any) => {
        response?.data?.property?.edges.forEach((property: any) => {

          /** Ativa o loading na tela **/
          this.propertiesLoading = true;

          this.responsePropertiesList.push(
            new Property(
              property?.node?.id,
              property?.node?.sourceName,
              property?.node?.name,
              property?.node?.measurementUnit
            )
          )
        })
      })
    }), ((error: any) => {
      /** Exibe erro no console **/
      console.log("ErrorPropertyFilter", error);
      /** Exibe alerta ao usuário **/
      Swal.fire({
        title: 'Falha ao filtrar as propriedades',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    })

    /** Desativa o loading na tela **/
    this.propertiesLoading = false;
  }

  /** Utilizado para popular a lista de propriedades **/
  public responsePropertiesList: Property[] = [];

  /** Propriedade utilizada para manipular o loading na tela referente ao filtro das propriedades **/
  public propertiesLoading: boolean = false;

  /** Armazena os campos de formulário dos comandos de consulta **/
  public queryForm: FormGroup = this.formBuilder.group({
    parameterQuery: [null, Validators.required],
    propertiesQuery: ['', Validators.required]
  })

  /** Utilizado para desabilitar os checkbox de parâmetros até que alguma propriedade de resposta seja preenchida  **/
  public disableParametersInput: boolean = true;

  /** Propriedade utilizada para realizar a verificação de quando o select for marcado **/
  public propertiesSelected: boolean = false;

  /** Método que emite um evento assim que alguma opção do select de propriedades de resposta for selecionado **/
  public onOptionsPropertiesSelected(event: MatSelectChange) {
    /** Validador passa a ser true (garantindo que alguma opção do select foi selecionada)**/
    this.propertiesSelected = true;
    /** Habilita os checkboxes de parâmetros **/
    this.disableParametersInput = false;
  }

  /** Método responsável por fechar e salvar o modal de dialogo enviando os dados para o componente de tipo de equipamento **/
  public closeAndSaveModalQueryCommands() {
    /** Variável utilizada para preencher com os dados que seram enviados para o componente de tipo de equipamento **/
    let queryCommands: any = null;

    /** Caso as propriedades dos comandos for selecionada e o checkbox de parâmetros estiver vazio **/
    if (this.propertiesSelected === true &&
      this.queryForm?.value?.parameterQuery === null) {

      /** Exibe mensagem de alerta ao usuário **/
      Swal.fire({
        title: 'Selecione um parâmetro!',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });

      /**Retorna para a aplicação **/
      return;
    } else if (this.queryForm.invalid) {
      Swal.fire({
        title: 'Selecione a(s) propriedade(s) de resposta!',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }

    /** Caso as propriedade(s) estiverem marcadas e o checkbox também for marcado **/
    if (this.propertiesSelected === true &&
      this.queryForm?.value?.parameterQuery != null) {

      /** Preenche a lista com os valores existentes no checkbox e no select de propriedades **/
      queryCommands = new QueryCommands(
        this.queryForm?.value?.parameterQuery,
        this.queryForm?.value?.propertiesQuery
      );
    }
    /**  Envia os dados dos comandos de consulta para o observable, onde será passado ao componente de tipo de equipamento **/
    this.sendData([queryCommands]);

    /** Limpa todos os campos preenchidos **/
    this.clearFields();
  }

  /** Método utilizado para limpar todos os campos dos comandos de consulta **/
  clearFields() {
    this.queryForm.reset();
    this.propertiesSelected = false;
  }

  public sendData(data: any) {
    /** Emite os dados para os observadores referente aos dados que serão enviados ao componente de tipo de equipamento**/
    this.commandsSubject.next(data);
  }
}
