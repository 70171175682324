<div class="component-main-container">
  <div class="header">
    <app-page-header parentTitle="{{ 'logs.logs-parent-label' | translate }}"
      pageTitle="{{ 'logs.form-filter-installation-site' | translate }}">
    </app-page-header>
  </div>

  <!--Corpo do componente-->
  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-12">
      <div class="component-forms-box">
        <app-logs-form></app-logs-form>

        <div class="form-fields-box" [ngClass]="!filterBox ? 'closed' : ''" [formGroup]="filterLogsForm">
          <h2 (click)="filterBoxOpen()">
            {{ "logs.form-filter-label" | translate }}
          </h2>
          <hr [ngClass]="!filterBox ? 'hr-open' : ''" />

          <div class="form-step">
            <mat-form-field appearance="outline" class="example-form-field">
              <mat-label>{{ "logs.form-operation" | translate }}</mat-label>

              <mat-select formControlName="operation" name="operation">
                <mat-option value="">{{
                  "logs.form-filter-null" | translate
                  }}</mat-option>
                <mat-option value="CREATE">{{
                  "logs.form-create-label" | translate
                  }}</mat-option>
                <mat-option value="UPDATE">{{
                  "logs.form-update-label" | translate
                  }}</mat-option>
                <mat-option value="DELETE">{{
                  "logs.form-delete-label" | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-step">
            <mat-form-field appearance="outline">
              <mat-label>{{ "logs.form-date" | translate }}</mat-label>

              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate formControlName="startDateForm" name="startDateForm" placeholder="Start date" />
                <input matEndDate formControlName="endDateForm" name="endDateForm" placeholder="End date" />
              </mat-date-range-input>

              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>

          <div class="form-step">
            <mat-form-field appearance="outline">
              <mat-label>{{ "logs.form-reference" | translate }}</mat-label>
              <input matInput formControlName="reference" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <mat-form-field appearance="outline">
              <mat-label>{{ "logs.form-filter-user" | translate }}</mat-label>
              <input matInput formControlName="username" />
            </mat-form-field>
          </div>

          <button title="{{ 'logs.form-filter-button' | translate }}" class="btn btn-success"
            (click)="filterInstallationSiteLogs(null)" *ngIf="!filterLoading">
            {{ "logs.form-filter-button" | translate }}
          </button>
          <mat-spinner [diameter]="30" *ngIf="filterLoading"></mat-spinner>
        </div>
      </div>
    </div>

    <!-- Sessão tabela -->
    <div class="col-lg-7 col-xl-8 col-sm-12" *ngIf="paginationProperties.total">
      <app-table-header [itensNumber]="paginationProperties.count" [totalNumber]="paginationProperties.total"
        [hasCSV]="true" [hasPDF]="true" [loadingData$]="fileLoading" (downloadCSV)="generateFileData(null, 'CSV')"
        (downloadPDF)="generateFileData(null, 'PDF')"></app-table-header>

      <mat-progress-bar class="mb-2" *ngIf="filterLoading" mode="indeterminate"></mat-progress-bar>

      <div class="table-div mt-2 mt-lg-3 mt-xl-3">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

          <!-- Username Column -->
          <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-filter-user" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.username }}</td>
          </ng-container>

          <!-- Operation Column -->
          <ng-container matColumnDef="operation">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-operation" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ getTranslationKey(element) | translate }}</td>
          </ng-container>

          <!-- Datetime Column -->
          <ng-container matColumnDef="logDatetime">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-logDatetime" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{formatDate(element.logDatetime)}}</td>
          </ng-container>

          <!-- Latitude Column -->
          <ng-container matColumnDef="latitude">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-latitude" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.latitude }}</td>
          </ng-container>

          <!-- Longitude Column -->
          <ng-container matColumnDef="longitude">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-longitude" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.longitude }}</td>
          </ng-container>

          <!-- Reference Column -->
          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-reference" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.reference }}</td>
          </ng-container>

          <!-- IsActive Column -->
          <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef>
              {{ "logs.form-isActive" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ getTranslationKeyIsActive(element) | translate }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex"
          (page)="pageChanged($event)" aria-label="Selecione as páginas de Logs" class="mat-paginator-sticky">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>