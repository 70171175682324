import { Component } from '@angular/core';
import { AuditorshipService } from 'src/shared/services/auditorship.service';
import { Unsubscriber } from '../../unsubscriber/unsubscriber.component';


@Component({
  selector: 'app-report-audit',
  templateUrl: './report-audit.component.html',
  styleUrls: ['./report-audit.component.less']
})
export class ReportAuditComponent extends Unsubscriber {
  constructor(private auditorshipService: AuditorshipService) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions = this.auditorshipService.editingAudit.subscribe({
      next: (value: boolean) => {
        if (value) {
          this.isManagingAudit = false;
        } else this.isManagingAudit = true;
        this.isEditingAudit = value;
      }
    });
    this.subscriptions = this.auditorshipService.selectedAuditId.subscribe({
      next: (auditId: string) => {
        this.editedAuditId = auditId;
      }
    })
  }

  isManagingAudit = true;
  isEditingAudit = false;
  editedAuditId = '';
  isViewingDownloadList = false;

}
