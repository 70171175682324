import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { FILTER_EQUIPMENTS } from "src/app/graphql/equipment.graphql";
import { CREATE_GATEWAY_INSTALLATION, FILTER_GATEWAYS, UPDATE_GATEWAY, UPDATE_STATUS_GATEWAY } from "src/app/graphql/gateways.graphql";
import { FILTER_INSTALLATION_SITE } from "src/app/graphql/installation-site.graphql";

@Injectable({
    providedIn: 'root'
})

export class GatewaysService {

    constructor(private apollo: Apollo) {
        this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
    }

    /** Armazena ID da empresa selecionada **/
    company: string | null;

    /** Chama a requisição que realiza o filtro dos gateways **/
    public filterGateways(
        reference: string | null,
        deviceid: string | null,
        country: string | null,
        state: string | null,
        city: string | null,
        district: string | null,
        street: string | null,
        cursor: string | null,
        pageSize: number | null
    ) {
        return this.apollo.watchQuery({
            query: FILTER_GATEWAYS,
            fetchPolicy: 'no-cache',
            variables: {
                company: this.company,
                reference,
                deviceid,
                country,
                state,
                city,
                district,
                street,
                cursor,
                pageSize,
                sort_dir: 'ASC',
                sort_field: 'REFERENCE'
            }
        })
    }

    /** Chama a requisição que realiza a criação de gateways **/
    public createGateway(
        reference: string | null,
        equipment: string | null,
        site: string | null
    ) {
        return this.apollo.mutate({
            mutation: CREATE_GATEWAY_INSTALLATION,
            fetchPolicy: 'no-cache',
            variables: {
                company: this.company,
                reference,
                equipment,
                site
            }
        })
    }

    /** Chama a requisição que realiza a requisição de atualização de gateways **/
    public updateGateway(
        id: string,
        reference: string | null,
        equipment: string | null,
        site: string | null,
        isActive: boolean
    ) {
        return this.apollo.mutate({
            mutation: UPDATE_GATEWAY,
            fetchPolicy: 'no-cache',
            variables: {
                id,
                reference,
                equipment,
                site,
                isActive
            }
        })
    }

    /** Chama a requisição que realiza a atualização do status do gateway **/
    public updateStatusGateway(
        id: string,
        reference: string | null,
        equipment: string | null,
        site: string | null,
        isActive: boolean
    ) {
        return this.apollo.mutate({
            mutation: UPDATE_STATUS_GATEWAY,
            fetchPolicy: 'network-only',
            variables: {
                id,
                reference,
                equipment,
                site,
                isActive
            }
        })
    }

    /** Chama a requisição do filtro de local de instalação **/
    getInstallationsSite(
        reference: string | null,
    ) {
        return this.apollo.watchQuery({
            query: FILTER_INSTALLATION_SITE,
            fetchPolicy: 'no-cache',
            variables: {
                company: this.company,
                reference,
                sort_dir: "ASC",
                sort_field: "REFERENCE"
            }
        })
    }

    /** Chama a requisição de filtro de equipamentos **/
    getEquipments(
        serialNumber: string | null,
    ) {
        return this.apollo.watchQuery({
            query: FILTER_EQUIPMENTS,
            fetchPolicy: 'no-cache',
            variables: {
                company: this.company,
                serialNumber,
                cursor: null,
                pageSize: 20,
                sort_dir: "ASC",
                sort_field: "MAC_ADDRESS"
            }
        })
    }
}
