import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HardwareService } from 'src/shared/services/hardware.service';
import Swal from 'sweetalert2';
import { Firmwares, Hardware, PaginationInfo } from './hardwaresAssets/hardware-model';
import { HardwaresTable } from './hardwaresAssets/HardwareTable.model';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import jsPDF from 'jspdf';
import { Subscription } from 'rxjs';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-hardwares',
  templateUrl: './hardwares.component.html',
  styleUrls: ['./hardwares.component.less']
})
export class HardwaresComponent extends Unsubscriber implements OnInit {

  constructor(
    private hardwareService: HardwareService,
    private errorLibService: ErrorLibService
  ) {
    super();
  }


  ngOnInit(): void {
    this.refreshFirmwares()
  }

  /**  Variáveis responsáveis por abrir e fechar  as box das páginas register e filter **/
  public filterHardwares: boolean = false;
  public registerHardwares: boolean = false;

  /** Variáveis para habilitar o painel de criação de novo Firmware **/
  public panelOpenHardware: boolean = false;
  public panelOpenFirmware: boolean = false;
  public panelOpenState: boolean = false;

  /** Variáveis usadas para armazenar o filtro feito pelo usuário **/
  public name: string = '';
  public major: number;
  public minor: number;
  public revision: number;

  /** Define os estados de loading **/
  public firmwaresLoading: boolean = false;
  public hardwaresLoading: boolean = false;
  public registerFirmwareLoading: boolean = false;
  public registerHardwareLoading: boolean = false;
  public fileLoading: boolean = false;

  /** Variável com a lista de Firmwares */
  public firmwaresList: Firmwares[] = [];

  /** Variável para controlar os firmwares selecionados no checkbox **/
  public selectedNewFirmwaresIdList: string[] = [];

  /** Variável responsável por configurar os nomes das colunas da tabela **/
  public displayedColumns: string[] = ['name', 'version', 'releaseDate'];

  public ELEMENT_DATA: HardwaresTable[] = [];

  /** Renderiza os comandos da lista ELEMENT_DATA para a tabela **/
  public dataSource = new MatTableDataSource<HardwaresTable>(this.ELEMENT_DATA);

  /** Variável responsável por realizar a conversão da data da tabela **/
  public date = Date.now();

  /** Variável utilizada para controle de paginação **/
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null)

  /** Variáveis utilizadas para manipulação do Paginator **/
  public currentPage: number = 0;
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  /** Métodos e variáveis responsáveis por configurar a paginação da tabela **/
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /** Valor do paginator **/
  public dataLength: number = 0;
  /** Variáveis utilizada para controle da geração de arquivo **/
  public FILE_DATA: HardwaresTable[] = [];
  public isReady: boolean = false;

  /** Função que configura o paginator **/
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  /** Função que controla os botões de anterior e pŕoximo no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.filterHardware(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterHardware(this.paginationProperties.endCursor)
    }
  }

  /** Função responsável por abrir o Box de filtro de Hardwares **/
  public filterHardwaresOpen() {
    this.filterHardwares = !this.filterHardwares;
    this.registerHardwares = false;
  }

  /** Função responsável por abrir o Box de registro de Hardwares **/
  public registerHardwaresOpen() {
    this.registerHardwares = !this.registerHardwares;
    this.filterHardwares = false;
  }

  /** FormGroup das variáveis de filtro de hardwares **/
  public filterHardwareForm = new FormGroup({
    name: new FormControl(),
    major: new FormControl(),
    minor: new FormControl(),
    revision: new FormControl(),
  });

  /** FormGroup das variáveis de criação de novo Hardware **/
  public createHardwareForm = new FormGroup({
    nameCreateForm: new FormControl('', [Validators.required]),
    majorCreateForm: new FormControl(null, [Validators.required]),
    minorCreateForm: new FormControl(null, [Validators.required]),
    revisionCreateForm: new FormControl(null, [Validators.required]),
    descriptionCreateForm: new FormControl(null, [Validators.required]),
    releaseDateCreateForm: new FormControl(null, [Validators.required]),
  });

  /** FormGroup das variáveis de criação de novo Firmware compatível **/
  public createFirmwareForm = new FormGroup({
    nameCreateFirmwareForm: new FormControl('', [Validators.required]),
    majorCreateFirmwareForm: new FormControl(null, [Validators.required]),
    minorCreateFirmwareForm: new FormControl(null, [Validators.required]),
    revisionCreateFirmwareForm: new FormControl(null, [Validators.required]),
    releaseDateCreateFirmwareForm: new FormControl(null, [Validators.required]),
    descriptionCreateFirmwareForm: new FormControl(null, [Validators.required]),
  })

  /** Função que realiza o filtro de Hardwares **/
  public filterHardware(cursor: string | null) {

    /** Ativa o spinner de loading **/
    this.hardwaresLoading = true;

    if (cursor === null) {
      this.pageIndex = 0;
      this.isReady = true;
    }

    this.subscriptions = this.hardwareService.filterHardwares(
      this.filterHardwareForm.value.name,
      this.filterHardwareForm.value.major,
      this.filterHardwareForm.value.minor,
      this.filterHardwareForm.value.revision,
      cursor,
      this.pageSize
    ).valueChanges.subscribe({
      next: ((response: any) => {
        /** Preenche a variável de paginação com a resposta da API **/
        this.paginationProperties = new PaginationInfo(
          response.data?.hardware?.count,
          response.data?.hardware?.total,
          response.data?.hardware?.pageInfo?.hasNextPage,
          response.data?.hardware?.pageInfo?.hasPreviousPage,
          response.data?.hardware?.pageInfo?.startCursor,
          response.data?.hardware?.pageInfo?.endCursor
        )

        this.ELEMENT_DATA = [];
        this.length = response.data?.hardware?.total
        this.isReady = false;

        /** Preenche a lista com todos os nodes de firmwares retornados pela API**/
        response.data.hardware.edges.forEach((hardware: any) => {
          this.ELEMENT_DATA.push(new HardwaresTable(
            hardware.node.id,
            hardware.node.name,
            `${hardware.node.major}.${hardware.node.minor}.${hardware.node.revision}`,
            hardware.node.releaseDate,
          ));
        })

        this.setDataSourceAttributes();

        if (this.ELEMENT_DATA.length === 0) {
          /** Retorna alerta ao usuário **/
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        }

        this.hardwaresLoading = false;
      }),
      error: ((error: any) => {
        this.errorLibService.errorAlert(error);
      })
    })
  }

  public resetFilter() {
    this.filterHardwareForm.reset();
  }


  /** Função responsável por criar Hardwares **/
  public createHardware(): void {
    /** Verifica se todos os campos estão válidos e se um firmware foi selecionado **/
    if (this.createHardwareForm.valid && this.selectedNewFirmwaresIdList.length != 0) {

      this.registerHardwareLoading = true;

      /** Realiza a função de criar novo Hardware **/
      this.subscriptions = this.hardwareService.createHardware(
        this.createHardwareForm.value.nameCreateForm,
        this.createHardwareForm.value.majorCreateForm,
        this.createHardwareForm.value.minorCreateForm,
        this.createHardwareForm.value.revisionCreateForm,
        this.createHardwareForm.value.descriptionCreateForm,
        this.formatDate(this.createHardwareForm.value.releaseDateCreateForm),
        this.selectedNewFirmwaresIdList
      ).subscribe({
        next: (() => {
          this.filterHardwareForm.patchValue({
            name: this.createHardwareForm?.value.nameCreateForm
          });

          this.filterHardware(null)

          /** Limpa o form após o filtro **/
          this.filterHardwareForm.patchValue({
            name: null
          });

          Swal.fire({
            title: 'Hardware criado',
            text: 'Hardware criado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          this.registerHardwareLoading = false;
        })
      })
      this.createHardwareForm.reset();
    }
    /** Caso nenhum firmware esteja selecionado no checkbox **/
    else if (this.selectedNewFirmwaresIdList.length === 0) {
      /** Alerta é emitido ao usuário **/
      Swal.fire({
        title: 'Formulário Incompleto!',
        text: 'Selecione os Firmwares Compatíveis',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
    /** E caso algum campo do formulário não esteja preenchido **/
    else {
      Swal.fire({
        title: 'Formulário Incompleto!',
        text: 'Preencha todos os campos',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
    this.registerHardwareLoading = false;
  }

  /** Função responsável por criar um novo firmware compatível **/
  public createCompatibleFirmware(): void {
    if (this.createFirmwareForm.valid) {

      this.registerFirmwareLoading = true;

      this.subscriptions = this.hardwareService.createNewCompatibleFirmware(
        this.createFirmwareForm.value.nameCreateFirmwareForm,
        this.createFirmwareForm.value.majorCreateFirmwareForm,
        this.createFirmwareForm.value.minorCreateFirmwareForm,
        this.createFirmwareForm.value.revisionCreateFirmwareForm,
        this.createFirmwareForm.value.descriptionCreateFirmwareForm,
        this.formatDate(this.createFirmwareForm.value.releaseDateCreateFirmwareForm)
      ).subscribe({
        next: (() => {
          this.refreshFirmwares();

          Swal.fire({
            title: 'Firmware criado',
            text: 'Firmware criado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          this.registerFirmwareLoading = false;
        })
      })
      this.createFirmwareForm.reset();
    }
    /** Caso os campos do formulário não estejam preenchidos **/
    else {
      /** Informa ao usuário que o formulário está incompleto **/
      Swal.fire({
        title: 'Formulário Incompleto!',
        text: 'Preencha todos os campos.',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
  }

  /** Função para verificar firmwares selecionados no CheckBox **/
  public filterSelectedFirmwares(event: any, selectedNewFirmwaresIdList: string) {
    /** Caso o firmware tenha sido selecionado **/
    if (event.checked) {
      /** Adiciona  o firmware selecionado na lista de selecionados **/
      this.selectedNewFirmwaresIdList.push(selectedNewFirmwaresIdList);
    }
    /** Caso o firmware tenha sido desmarcado **/
    else {
      /** Remove o firmware da lista **/
      this.selectedNewFirmwaresIdList = this.selectedNewFirmwaresIdList.filter((selectedFirmwareId: string) =>
        selectedFirmwareId != selectedNewFirmwaresIdList
      );
    }
  }

  public refreshFirmwares() {
    /** Limpa a lista para repopular com os resultados de uma nova requisição**/
    this.firmwaresList = []
    /** Filtra todos os hardwares para popular a lista que preenche o select **/
    this.subscriptions = this.hardwareService.getAllFirmwares(
    ).valueChanges.subscribe({
      next: ((response: any) => {
        response.data.firmware.edges.forEach((firmwares: any) => {
          this.firmwaresList.push(
            new Firmwares(
              firmwares.node.id,
              firmwares.node.name,
              firmwares.node.reference,
              `${firmwares.node.major}.${firmwares.node.minor}.${firmwares.node.revision}`,
              firmwares.node.description,
              firmwares.node.releaseDate
            )
          )
        })
      })
    })
  }

  /** Função responsável por realizar a conversão da data utilizada nos inputs **/
  public formatDate(releaseDate: any) {
    /** Define um objeto do tipo date passando a data **/
    releaseDate = new Date(releaseDate);
    /** Retorna os valores de dia, mês e ano convertidos **/
    return `${releaseDate.getFullYear()}-${String(releaseDate.getMonth() + 1).padStart(2, '0')}-${releaseDate.getDate().toString().padStart(2, '0')}`
  }

  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscriptions = this.hardwareService.filterHardwares(
        this.name,
        this.major,
        this.minor,
        this.revision,
        cursor,
        100
      ).valueChanges.subscribe({
        next: ((response: any) => {

          response.data.hardware.edges.forEach((hardware: any) => {
            /** Itera cada elemento de instalação,
             *  divisão, local de instalação, device, 
             * gateway e tipos de luminária na lista **/

            this.FILE_DATA.push(new HardwaresTable(
              hardware.node.id,
              hardware.node.name,
              `${hardware.node.major}.${hardware.node.minor}.${hardware.node.revision}`,
              hardware.node.releaseDate,
            ));
          });

          /** Caso seja a última página a lista está completa **/
          if (!response.data?.hardware?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.hardware?.pageInfo?.endCursor, fileType);
        }),
        error: ((error: any) => {
          this.errorLibService.errorAlert(error);
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  /** Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe **/
  private convertToCSV(arr: any) {
    /** Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas **/
    const content = [Object.keys(arr[0])].concat(arr);

    /** Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um **/
    return content.map((item) => {
      return Object.values(item).toString().replace(',', ';');
    })
      .join('\n');
  }

  /** Método para realizar o download do arquivo CSV **/
  public downloadCSV() {
    let content: any = [];

    /** Adiciona cada elemento como um objeto na lista de conteúdo **/
    this.FILE_DATA.forEach((element) => {
      content.push({
        'Referência': element.name,
        'Versão': element.version,
        'Data de release': element.releaseDate
      });
    });

    /** Adiciona o conteúdo convertido dentro de uma nova variável. **/
    let data = this.convertToCSV(content);
    /**  Transforma o conteúdo para o formato blob para geração do arquivo. **/
    let file = new Blob([data], { type: 'text/csv' });
    /** Cria um endereçamento para o arquivo. **/
    let url = window.URL.createObjectURL(file);
    /** Cria um elemento para a linkagem do arquivo. **/
    let link = document.createElement('a');
    /** Adiciona a url no elemento de linkagem. **/
    link.href = url;
    /**  Nomeia o arquivo que será feito o download. **/
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-Hardwares.csv`;
    /** Adiciona o link no documento. **/
    document.body.appendChild(link);
    /** Atica o evento de click para realizar o download. **/
    link.click();
    /** Remove o link do documento. **/
    document.body.removeChild(link);
    /**  Remove o endereço do download. **/
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  /** Método para realizar o download do arquivo PDF **/
  public downloadPDF() {
    // Colunas que serão adicionadas ao header da tabela no PDF
    const tableLayout = [
      "Referência",
      "Versão",
      "Data de release"
    ];

    let content: any = []
    // Adiciona os elementos na lista de conteúdo como um array
    this.FILE_DATA.forEach((element) => {
      content.push([
        element.name,
        element.version,
        element.releaseDate
      ]);
    });
    // Define a orientação da tabela (paisagem nesse caso)
    const orientation = 'l';
    // Cria o objeto da lib jsPDF com a orientação e tamanho da página
    const doc: any = new jsPDF(orientation, "mm", "a4");

    // Cria a tabela com o tableLayout como header e a a lista de conteúdo como corpo da tabela
    doc.autoTable({
      theme: "grid",
      margin: { top: 20 },
      head: [tableLayout],
      body: content
    });
    // Baixa o arquivo para o usuário, definindo o nome com a data de download
    doc.save(`${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-Hardwares.pdf`);

    this.FILE_DATA = [];
    this.isReady = false;
  }

}
