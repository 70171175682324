import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CREATE_COMPANIES, FILTER_COMPANIES, UPDATE_COMPANY } from 'src/app/graphql/companies.graphql';
import { ErrorLibService } from './error-lib.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private apollo: Apollo, private errorLibService: ErrorLibService) { }

  /**
    * Função responsável por filtrar as Empresas
    */
  filterCompanies(name: string | null, cursor: string | null, pageSize: number | null): any {
    // Faz a requisição
    return this.apollo.watchQuery({
      query: FILTER_COMPANIES,
      fetchPolicy: 'network-only',
      variables: {
        name,
        first: pageSize,
        cursor,
        sort_dir: "ASC",
        sort_field: "NAME"
      }
    })
  }
  handlePreviousPage(name: string | null, cursor: string | null, pageSize: number | null): any {
    // Faz a requisição
    return this.apollo.watchQuery({
      query: FILTER_COMPANIES,
      fetchPolicy: 'network-only',
      variables: {
        name,
        last: pageSize,
        before: cursor,
        sort_dir: "ASC",
        sort_field: "NAME"
      }
    })
  }

  //------------------ session register companies -------------------------------------------

  /**
    * Função responsavel por criar Empresas
    */
  registerCompanies(name: string, cnpj: string) {
    // Envia a mutation
    return this.apollo.mutate({
      mutation: CREATE_COMPANIES,
      variables: {
        name,
        cnpj
      }
    })
  }

  //------------------ session register companies -----------------------------------------------

  /*
   Método que realiza a alteração de uma empresa no graphql.
  */
  updateCompany(id: string, name: string | null, cnpj: string | null) {
    return this.apollo.mutate({
      mutation: UPDATE_COMPANY,
      variables: {
        id,
        name,
        cnpj
      }
    })
  }
}
