import { Component, OnInit, ViewChild } from '@angular/core';
import { ServiceOrderService } from 'src/shared/services/service-order.service';
import Swal from 'sweetalert2';
import { Complainant, Installation, PaginationInfo, Problem, ServiceOrder } from './models/service-order.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { Unsubscriber } from '../unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-service-order',
  templateUrl: './service-order.component.html',
  styleUrls: ['./service-order.component.less']
})
export class ServiceOrderComponent extends Unsubscriber implements OnInit {

  constructor(
    private serviceOrderService: ServiceOrderService,
    private formBuilder: FormBuilder,
    private errorLibService: ErrorLibService,
  ) {
    super();
  }

  ngOnInit(): void { }

  public company: any | null = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;

  public createServiceOrderBox: boolean = false;

  public filterServiceOrderBox: boolean = false;

  public serviceOrderSelected: string;

  public installationList: Installation[] = [];

  public filterInstallationList: Installation[] = [];

  /** Variável utilizada para armazenar o último filtro feito pelo usuário **/
  public filteredValue: string;

  public ELEMENT_DATA: ServiceOrder[] = [];

  /** Variável utilizada para manipular as instalações selecionadas **/
  public selectedInstallation: any = null;
  // Controlador para apresentação side menu de edição de ordem de serviço.
  public editorController: boolean = false;
  // Controlador para manipulação do side menu para visualização
  public viewController: boolean = false;

  // Formulário com o conteúdo a ser editado na API.
  public updateServiceOrderForm: FormGroup = this.formBuilder.group({
    installations: [null, Validators.required],
    status: [null, Validators.required],
    priority: [null, Validators.required],
    problem: [null, Validators.required],
    problemDetails: [null, Validators.required],
    problemAdress: [null, Validators.required],
    name: [null, Validators.required],
    landlineNumber: [null, Validators.required],
    cellphone: [null, Validators.required],
    email: [null, Validators.required],
  });

  public createServiceOrderForm: FormGroup = this.formBuilder.group({
    problem: [null, Validators.required],
    problemDetails: [null, Validators.required],
    problemAdress: [null, Validators.required],
    searchInstallation: [null, Validators.required],
    selectedInstallation: [null, Validators.required],
    priority: [null, Validators.required],
    status: [null, Validators.required],
    name: [null, Validators.required],
    landlineNumber: [null, Validators.required],
    cellphone: [null, Validators.required],
    email: [null, Validators.required],
  });

  /** Contém os elementos utilizados no formulário de filtro **/
  public filterForm: FormGroup = this.formBuilder.group({
    identifier: [null],
  })

  public resultsLength = this.ELEMENT_DATA.length;

  public displayedColumns: string[] = ['identifier', 'installation', 'problem', 'status', 'details', 'priority', 'actions'];

  public dataSource = new MatTableDataSource<ServiceOrder>(this.ELEMENT_DATA)

  //Variáveis para controle de loading
  public filterLoading: boolean = false;
  public fileLoading: boolean = false;

  /** Métodos e variáveis responsáveis por configurar a paginação da tabela **/
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /** Variáveis utilizadas para manipulação do Paginator **/
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  /** Variáveis utilizadas para geração e manipulação dos arquivos PDF e CSV **/
  public FILE_DATA: ServiceOrder[] = [];
  public isReady = false;

  /** Função que controla os botões de anterior e pŕoximo no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.handlePreviousPage(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterServiceOrder(this.paginationProperties.endCursor)
    }
  }

  /** Variável utilizada para controle de paginação **/
  public paginationProperties: PaginationInfo = new PaginationInfo(
    0,
    0,
    true,
    false,
    null,
    null
  );

  /** Função que configura o paginator **/
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  public createServiceOrderBoxOpen() {
    this.createServiceOrderBox = !this.createServiceOrderBox
    this.filterServiceOrderBox = false;
  }

  public filterServiceOrderBoxOpen() {
    this.filterServiceOrderBox = !this.filterServiceOrderBox;
    this.createServiceOrderBox = false;
  }

  public filterServiceOrder(cursor: string | null): any {

    this.filterLoading = true;

    /** Armazena o filtro realizado pelo usuário **/
    this.filteredValue = this.filterForm.get("identifier")?.value

    /** Reseta o índice da página ao realizar o filtro sem cursor **/
    if (cursor == null) {
      /** Reseta o índice da página e força a conclusão do arquivo que está sendo gerado **/
      this.pageIndex = 0;
      this.isReady = true;
    }

    this.subscriptions = this.serviceOrderService
      .filterServiceOrder(
        this.filteredValue,
        cursor,
        this.pageSize
      )
      .valueChanges.subscribe({
        next: ((response: any) => {
          console.log("response: ", response)
          this.paginationProperties = new PaginationInfo(
            response.data?.serviceOrder?.count,
            response.data?.serviceOrder?.total,
            response.data?.serviceOrder?.pageInfo?.hasNextPage,
            response.data?.serviceOrder?.pageInfo?.hasPreviousPage,
            response.data?.serviceOrder?.pageInfo?.startCursor,
            response.data?.serviceOrder?.pageInfo?.endCursor
          )

          this.ELEMENT_DATA = [];

          this.length = this.paginationProperties.total;

          response.data.serviceOrder?.edges.forEach((node: any) => {
            // Preenche a lista de resultados com as OS retornadas na query
            this.ELEMENT_DATA.push(
              new ServiceOrder(
                node.node.id,
                node.node.serviceIdentifier,
                new Problem(
                  node.node.problem?.reference,
                  node.node.problem?.details,
                  node.node.problem?.address
                ),
                new Complainant(
                  node.node.complainant?.name ?? '',
                  node.node.complainant?.cpf ?? '',
                  node.node.complainant?.landlineNumber ?? '',
                  node.node.complainant?.phoneNumber ?? ''
                ),
                node.node?.status,
                new Installation(
                  node.node.installation?.id,
                  node.node.installation?.reference,
                ),
                node.node.priority
              )
            )
          }
          )

          if (this.ELEMENT_DATA.length === 0) {
            Swal.fire({
              title: 'Sua pesquisa não retornou resultados',
              icon: 'warning',
              confirmButtonText: 'Ok',
            })
          }

          this.setDataSourceAttributes();
          this.isReady = false;
          this.filterLoading = false
        })
      })
  }

  public handlePreviousPage(cursor: string | null): any {

    this.filterLoading = true;

    this.subscriptions = this.serviceOrderService.handlePreviousPage(
      cursor,
      this.pageSize
    )
      .valueChanges
      .subscribe({
        next: ((response: any) => {
          this.paginationProperties = new PaginationInfo(
            response.data?.serviceOrder?.count,
            response.data?.serviceOrder?.total,
            response.data?.serviceOrder?.pageInfo?.hasNextPage,
            response.data?.serviceOrder?.pageInfo?.hasPreviousPage,
            response.data?.serviceOrder?.pageInfo?.startCursor,
            response.data?.serviceOrder?.pageInfo?.endCursor
          )

          this.ELEMENT_DATA = [];
          //this.isReady = false;
          this.length = this.paginationProperties.total;

          response.data.serviceOrder.edges.forEach((node: any) => {
            // Preenche a lista de resultados com as OS retornadas na query
            this.ELEMENT_DATA.push(
              new ServiceOrder(
                node.node.id,
                node.node.serviceIdentifier,
                new Problem(
                  node.node.problem.reference,
                  node.node.problem.details,
                  node.node.problem.address
                ),
                new Complainant(
                  node.node.complainant?.name ?? '',
                  node.node.complainant?.cpf ?? '',
                  node.node.complainant?.landlineNumber ?? '',
                  node.node.complainant?.phoneNumber ?? ''
                ),
                node.node?.status,
                new Installation(
                  node.node.installation?.id,
                  node.node.installation?.reference,
                ),
                node.node.priority
              )
            )
          })

          if (this.ELEMENT_DATA.length === 0) {
            Swal.fire({
              title: 'Sua pesquisa não retornou resultados',
              icon: 'warning',
              confirmButtonText: 'Ok',
            })
          }

          this.setDataSourceAttributes();

          this.filterLoading = false;
        })
      });
  }

  // Função para setar os valores do elemento nos campos do formulário de edição
  public updateServiceOrderGetter(
    id: string,
    installations: string | null,
    status: string | null,
    priority: string | null,
    problem: string | null,
    problemDetails: string | null,
    problemAdress: string | null,
    name: string | null,
    landlineNumber: string | null,
    cellphone: string | null,
    email: string | null,
    isViewing: boolean,
  ) {
    this.serviceOrderSelected = id;

    // Verificação para manipular o formulário caso esteja visualizando ou editando
    this.viewController = isViewing;

    if (isViewing) {
      this.updateServiceOrderForm.disable();
    } else {
      this.updateServiceOrderForm.enable();
    }

    this.updateServiceOrderForm.setValue({
      installations,
      status,
      priority,
      problem,
      problemDetails,
      problemAdress,
      name,
      landlineNumber,
      cellphone,
      email
    })
    this.editorController = true;
  }

  public updateServiceOrder() {
    if (this.updateServiceOrderForm.valid) {
      this.serviceOrderService.updateServiceOrder(
        this.serviceOrderSelected,
        this.updateServiceOrderForm.value?.status,
        this.updateServiceOrderForm.value?.priority,
        this.updateServiceOrderForm.value?.problem,
        this.updateServiceOrderForm.value?.problemDetails,
        this.updateServiceOrderForm.value?.problemAdress,
        this.updateServiceOrderForm.value?.name,
        this.updateServiceOrderForm.value?.landlineNumber,
        this.updateServiceOrderForm.value?.cellphone,
        this.updateServiceOrderForm.value?.email
      ).subscribe({
        next: ((response: any) => {
          Swal.fire({
            title: 'Ordem de serviço atualizada',
            text: 'Ordem de serviço atualizada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          this.ELEMENT_DATA = [];

          this.editorController = false;
          this.serviceOrderSelected = "";

        }),
        error: (error: any) => {
          console.log("update service order", error)
          this.errorLibService.errorAlert(error);
        }
      })
    }
    else {
      /** Emite alerta ao usuário **/
      Swal.fire({
        title: 'Formulário Incompleto',
        text: 'Preencha todos os campos',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
  }

  public createServiceOrder(): void {
    if (this.createServiceOrderForm.valid) {
      this.subscriptions = this.serviceOrderService.createServiceOrder(
        this.createServiceOrderForm.value?.selectedInstallation,
        this.createServiceOrderForm.value?.status,
        this.createServiceOrderForm.value?.priority,
        this.createServiceOrderForm.value?.problem,
        this.createServiceOrderForm.value?.problemDetails,
        this.createServiceOrderForm.value?.problemAdress,
        this.createServiceOrderForm.value?.name,
        this.createServiceOrderForm.value?.landlineNumber,
        this.createServiceOrderForm.value?.cellphone,
        this.createServiceOrderForm.value?.email,
      ).subscribe({
        next: () => {
          Swal.fire({
            title: 'Ordem de serviço Criada',
            text: 'Ordem de serviço criada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        },
        error: (error) => {
          this.errorLibService.errorAlert(error);
        },
      });
    }

    else {
      /** Emite alerta ao usuário **/
      Swal.fire({
        title: 'Formulário Incompleto',
        text: 'Preencha todos os campos',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
  }

  // Carrega a lista de instalações filtradas no form de update/cadastro
  public searchInstallation(isCreateForm: boolean): void {
    this.subscriptions = this.serviceOrderService
      .getInstallations(this.createServiceOrderForm.value?.searchInstallation)
      .valueChanges.subscribe({
        next: (res: any) => {
          console.log(res);
          let installationList: Installation[] = [];
          res.data.installation.edges.forEach((installation: any) => {
            installationList.push(
              new Installation(
                installation.node.id,
                installation.node.reference,
              )
            );
            // Verifica em qual dos 2 formulários deve preencher a lista de instalações filtrada
            if (isCreateForm) this.installationList = installationList;
            else this.filterInstallationList = installationList;
          });
        },
      });
  }

  public closeEditionSideBar() {
    this.editorController = false;
    this.viewController = false;
    this.serviceOrderSelected = "";
  }

  /** Função que armazena as instalações que foram selecionadas pelo usuário **/
  public installationGetId(installationId: any) {
    this.selectedInstallation = installationId;
  }

  public cleanFilter() {
    this.createServiceOrderForm.reset();
  }

  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscriptions = this.serviceOrderService
        .filterServiceOrder(
          this.filteredValue,
          cursor,
          100
        ).valueChanges.subscribe({
          next: ((response: any) => {
            response.data.serviceOrder?.edges.forEach((node: any) => {
              // Preenche a lista de resultados com as OS retornadas na query
              this.FILE_DATA.push(
                new ServiceOrder(
                  node.node.id,
                  node.node.serviceIdentifier,
                  new Problem(
                    node.node.problem?.reference,
                    node.node.problem?.details,
                    node.node.problem?.address
                  ),
                  new Complainant(
                    node.node.complainant?.name ?? '',
                    node.node.complainant?.cpf ?? '',
                    node.node.complainant?.landlineNumber ?? '',
                    node.node.complainant?.phoneNumber ?? ''
                  ),
                  node.node?.status,
                  new Installation(
                    node.node.installation?.id,
                    node.node.installation?.reference,
                  ),
                  node.node.priority
                )
              )
            })
            /** Caso seja a última página a lista está completa **/
            if (!response.data?.serviceOrder?.pageInfo?.hasNextPage) {
              this.isReady = true;
            }

            this.generateFileData(response.data?.serviceOrder?.pageInfo?.endCursor, fileType);
          }),
          error: ((error: any) => {
            this.errorLibService.errorAlert(error);
          })
        })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  /** Função que realiza a conversão para arquivo CSV */
  public convertToCSV(arr: any) {
    const array = [Object.keys(arr[0])].concat(arr);

    return array.map(it => {
      return Object.values(it).toString()
    }).join(',\n')
  }

  /** Função que realiza o download em CSV */
  public downloadCSV() {
    let listServiceOrder: any = [];

    this.FILE_DATA.forEach((serviceOrder) => {
      listServiceOrder.push(
        {
          'Identificador': serviceOrder.serviceIdentifier,
          'Instalação': serviceOrder.installation.reference,
          'Problema': serviceOrder.problem,
          'Status': this.translateStatus(serviceOrder.status),
          'Prioridade': this.translatePriority(serviceOrder.priority),
          'Detalhes': serviceOrder.problem.details
        }
      );
    });

    let csvData = this.convertToCSV(listServiceOrder);
    let fileCSV = new Blob([csvData], { type: 'text/csv' });
    let url = window.URL.createObjectURL(fileCSV);
    let a = document.createElement("a");
    a.href = url;

    a.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}:${new Date().getMinutes()}-serviceorder.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  public downloadPDF() {
    // Colunas que serão adicionadas ao header da tabela no PDF
    const tableLayout = [
      "Identificador",
      "Instalação",
      "Problema",
      "Status",
      "Prioridade",
      "Detalhes",
    ];

    let content: any = []
    // Adiciona os elementos na lista de conteúdo como um array
    this.FILE_DATA.forEach((serviceOrder) => {
      content.push([
        serviceOrder.serviceIdentifier,
        serviceOrder.installation.reference,
        serviceOrder.problem.reference,
        this.translateStatus(serviceOrder.status),
        this.translatePriority(serviceOrder.priority),
        serviceOrder.problem.details
      ]);
    });
    // Define a orientação da tabela (paisagem nesse caso)
    const orientation = 'l';
    // Cria o objeto da lib jsPDF com a orientação e tamanho da página
    const doc: any = new jsPDF(orientation, "mm", "a4");

    // Cria a tabela com o tableLayout como header e a a lista de conteúdo como corpo da tabela
    doc.autoTable({
      theme: "grid",
      margin: { top: 20 },
      head: [tableLayout],
      body: content
    });

    // Baixa o arquivo para o usuário, definindo o nome com a data de download
    doc.save(`${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-serviceOrder.pdf`);

    this.FILE_DATA = [];
    this.isReady = false;
  }
  // Função criada para tratar os dados de prioridade para o relatório
  private translatePriority(priority: string): string {
    switch (priority) {
      case "LOW":
        return "Baixa";

      case "MEDIUM":
        return "Média";

      case "HIGH":
        return "Alta";
    }
    return priority;
  }
  // Função criada para tratar os dados de status para o relatório
  private translateStatus(status: string): string {
    switch (status) {
      case "OPEN":
        return "Aberta";

      case "CLOSED":
        return "Fechada";

      case "IN_PROGRESS":
        return "Em progresso";

      case "CLOSED_WITH_ISSUES":
        return "Fechada com pendências";

      case "DELETED":
        return "Apagadas";

    }
    return status;
  }
}

