<div class="component-main-container">

    <app-page-header parentTitle='{{"report-audit-edit.parentTitle" | translate}}'
        pageTitle='{{"report-audit-edit.pageTitle" | translate}}'></app-page-header>

    <div class="row">
        <div class="col-lg-5 col-xl-4 col-sm-12">
            <div class="component-forms-box">


                <div class="form-fields-box">
                    <h2 class="header-link">{{'report-audit-edit.form-title' | translate}}</h2>
                    <hr>
                    <!-- Passo de preencher referencia -->
                    <div class="form-step">
                        <div class="circle-number"> 1
                        </div>
                        <mat-form-field class="example-form-field" appearance="outline">
                            <mat-label>{{'general-simple-label.reference-label' | translate}}</mat-label>
                            <input [disabled]="true" [formControl]="referenceForm" matInput type="text">
                        </mat-form-field>
                    </div>

                    <div class="form-step">
                        <div class="circle-number"> 2
                        </div>
                        <mat-form-field appearance="outline" class="example-form-field">
                            <mat-label>{{'general-simple-label.start-date' | translate}}</mat-label>
                            <input (dateChange)="changeAuditField()" [formControl]="startDateForm" matInput
                                [matDatepicker]="datepickerstart">
                            <mat-datepicker-toggle mat-icon-button matIconSuffix
                                [for]="datepickerstart"></mat-datepicker-toggle>
                            <mat-datepicker #datepickerstart>
                                <mat-datepicker-actions>
                                    <button mat-raised-button class="btn btn-success"
                                        matDatepickerApply>{{'general-simple-label.apply' | translate}}</button>
                                    <button mat-button matDatepickerCancel
                                        class="btn btn-warning me-1">{{'general-simple-label.cancel' |
                                        translate}}</button>
                                </mat-datepicker-actions>
                            </mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="form-step">
                        <div class="circle-number"> 3
                        </div>
                        <mat-form-field appearance="outline" class="example-form-field">
                            <mat-label>{{'general-simple-label.end-date' | translate}}</mat-label>
                            <input (dateChange)="changeAuditField()" [formControl]="endDateForm" matInput
                                [matDatepicker]="datepickerend">
                            <mat-datepicker-toggle matIconSuffix [for]="datepickerend"></mat-datepicker-toggle>
                            <mat-datepicker #datepickerend>
                                <mat-datepicker-actions>
                                    <button mat-raised-button class="btn btn-success"
                                        matDatepickerApply>{{'general-simple-label.apply' | translate}}</button>
                                    <button mat-button matDatepickerCancel
                                        class="btn btn-warning me-1">{{'general-simple-label.cancel' |
                                        translate}}</button>
                                    <button mat-button matDatepickerCancel class="btn btn-danger me-1"
                                        (click)="cleanEndDate()">{{'general-simple-label.clear-selection' |
                                        translate}}</button>
                                </mat-datepicker-actions>
                            </mat-datepicker>
                        </mat-form-field>
                    </div>

                    <!-- <button *ngIf="auditIsOpen" (click)="saveBasicAuditData()" [disabled]="!auditDataWasModified" class="btn btn-success mb-2 w-100">Editar
                        dados</button> -->
                </div>

                <div class="form-fields-box" *ngIf="auditIsOpen">
                    <h2>{{'report-audit-edit.add-new-installation' | translate}}</h2>
                    <hr>
                    <div *ngIf="auditIsOpen" class="form-step">
                        <div class="circle-number"> 1
                        </div>
                        <mat-form-field class="example-form-field" appearance="outline">
                            <mat-label>{{'general-simple-label.add-installation' | translate}}</mat-label>
                            <input [formControl]="searchInstallationForm" matInput type="text">
                            <button [disabled]="!auditIsOpen || !searchInstallationForm.valid"
                                (click)="searchInstallation()" matSuffix mat-icon-button aria-label="Clear"
                                class="component-search-icon-button">
                                <mat-icon class="component-search-icon">search</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>

                    <div *ngIf="isLoadingNewInstallation" class="component-spinner-box-sm">
                        <mat-spinner diameter="30"></mat-spinner>
                    </div>

                    <div class="form-step inputFile">
                        <div class="circle-number">3</div>
                        <input type="file" class="form-control" id="inputGroupFile04" [accept]="'.csv'"
                            aria-describedby="inputGroupFileAddon04" aria-label="Upload" #fileUploadSimple
                            (change)="getTextFromFile($event)">
                        <br>
                        <button class="btn btn-secondary" type="button" id="inputGroupFileAddon04"
                            [disabled]="!disabledImportButton" *ngIf="!isLoadingImportFilter"
                            (click)="importDataFromCSV()" type="submit">
                            Importar
                        </button>
                        <mat-spinner [diameter]="30" *ngIf="isLoadingImportFilter"></mat-spinner>
                    </div>

                    <div *ngIf="auditIsOpen && installationList.length" class="form-step mb-4">
                        <div class="circle-number"> 2
                        </div>
                        <button (click)="openModalWithInstallations()"
                            class="btn btn-primary w-100 pb-2 ms-1 me-1 mb-3">
                            {{'report-audit-edit.selected-installations' |
                            translate}}
                        </button>
                    </div>

                    <div *ngIf="auditIsOpen && installationList.length > 0" class="form-step mb-4">
                        <div class="circle-number"> 3
                        </div>
                        <button (click)="addNewInstallationToAudit()" class="btn btn-success w-100 pb-2 ms-1 me-1 mb-3">
                            {{'general-simple-label.add-installation' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-7 col-xl-8 col-sm-12">
            <div class="component-table-action-buttons">
                <div *ngIf="installationDeletionList.length > 0">
                    <button class="btn btn-danger m-1 mt-2 mb-2" (click)="deleteInstallations()">
                        {{'report-audit-edit.delete-selected-installation' | translate}}
                    </button>
                </div>
                <div *ngIf="auditIsOpen">
                    <button *ngIf="installationDataWasModified || installationList.length > 0 || auditDataWasModified"
                        class="btn btn-success m-1 mt-2 mb-2" (click)="saveAuditNewData()">
                        {{'report-audit-edit.save-audit' | translate}}
                    </button>
                    <button class="btn btn-warning m-1 mt-2 mb-2" (click)="closeAudit()">
                        {{'report-audit-edit.close-audit' | translate}}
                    </button>
                </div>
                <button class="btn btn-success m-1 mt-2 mb-2" (click)="backToAuditTables()"
                    *ngIf="!installationDataWasModified && !auditDataWasModified">
                    {{'report-audit-edit.back-to-audit' | translate}}
                </button>
                <button class="btn btn-danger m-1 mt-2 mb-2" (click)="backToAuditTables()"
                    *ngIf="installationDataWasModified || auditDataWasModified">
                    {{'report-audit-edit.discard-go-back-to-audit' | translate}}
                </button>
            </div>

            <div class="component-table-box">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="selectAllinstallationForDeletion($event)">

                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox [checked]="element.checked"
                                (change)="selectInstallationForDeletion($event, element.installationId)">
                            </mat-checkbox>
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="installationReference">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'report-audit-edit.table-header.point' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.installationReference}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef>{{'report-audit-edit.table-header.sample-total' |
                            translate}} </td>
                    </ng-container>


                    <ng-container matColumnDef="transmissionComplianceIndicator">
                        <th mat-header-cell *matHeaderCellDef> ICTI </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox disabled='true'
                                [checked]="element.transmissionComplianceIndicator"></mat-checkbox>
                        </td>
                        <td mat-footer-cell *matFooterCellDef>
                            {{auditDataTotal ? (auditDataTotal.icti ? auditDataTotal.icti : '-') : '-'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="icvi">
                        <th mat-header-cell *matHeaderCellDef> ICVI </th>
                        <td mat-cell *matCellDef="let element"> - </td>
                        <td mat-footer-cell *matFooterCellDef>

                        </td>
                    </ng-container>
                    <ng-container matColumnDef="aaa">
                        <th mat-header-cell *matHeaderCellDef> aaa </th>
                        <td mat-cell *matCellDef="let element"> </td>
                        <td mat-footer-cell *matFooterCellDef>SDFT: </td>
                    </ng-container>

                    <ng-container matColumnDef="sdft">
                        <th mat-header-cell *matHeaderCellDef> SDFT </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox disabled='true'
                                [checked]="element.register && element.status && element.variables && element.actuation">
                            </mat-checkbox>
                        </td>
                        <td mat-footer-cell *matFooterCellDef>SDFT: </td>
                    </ng-container>


                    <ng-container matColumnDef="register">
                        <th mat-header-cell *matHeaderCellDef> {{'report-audit-edit.table-header.register' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox [disabled]="!auditIsOpen"
                                (change)="changeInstallationField($event, 'register', element.installationId)"
                                [checked]="element.register"></mat-checkbox>
                        </td>
                        <td mat-footer-cell *matFooterCellDef>
                            {{auditDataTotal ? (auditDataTotal.register ? auditDataTotal.register : '-') : '-' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> {{'report-audit-edit.table-header.status' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox [disabled]="!auditIsOpen"
                                (change)="changeInstallationField($event, 'status', element.installationId)"
                                [checked]="element.status"></mat-checkbox>
                        </td>
                        <td mat-footer-cell *matFooterCellDef>
                            {{auditDataTotal ? (auditDataTotal.state ? auditDataTotal.state : '-') : '-' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="variables">
                        <th mat-header-cell *matHeaderCellDef> {{'report-audit-edit.table-header.variables' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox [disabled]="!auditIsOpen"
                                (change)="changeInstallationField($event, 'variables', element.installationId)"
                                [checked]="element.variables"></mat-checkbox>
                        </td>
                        <td mat-footer-cell *matFooterCellDef>
                            {{auditDataTotal ? (auditDataTotal.variables ? auditDataTotal.variables : '-') : '-' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="daytimeOperation">
                        <th mat-header-cell *matHeaderCellDef> OPDIU </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox [disabled]="!auditIsOpen"
                                (change)="changeInstallationField($event, 'daytimeOperation', element.installationId)"
                                [checked]="element.daytimeOperation"></mat-checkbox>
                        </td>
                        <td mat-footer-cell *matFooterCellDef>
                            {{auditDataTotal ? (auditDataTotal.opdiu ? auditDataTotal.opdiu : '-') : '-' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="nighttimeOperation">
                        <th mat-header-cell *matHeaderCellDef> OPNOT </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox [disabled]="!auditIsOpen"
                                (change)="changeInstallationField($event, 'nighttimeOperation', element.installationId)"
                                [checked]="element.nighttimeOperation"></mat-checkbox>
                        </td>
                        <td mat-footer-cell *matFooterCellDef>
                            {{auditDataTotal ? (auditDataTotal.opnot ? auditDataTotal.opnot : '-') : '-' }}
                        </td>
                    </ng-container>



                    <ng-container matColumnDef="actuation">
                        <th mat-header-cell *matHeaderCellDef> {{'report-audit-edit.table-header.actuation' |
                            translate}} </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox [disabled]="!auditIsOpen"
                                (change)="changeInstallationField($event, 'actuation', element.installationId)"
                                [checked]="element.actuation"></mat-checkbox>
                        </td>
                        <td mat-footer-cell *matFooterCellDef>
                            {{auditDataTotal ? (auditDataTotal.actuation ? auditDataTotal.actuation : '-') : '-'}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>

        </div>
    </div>
</div>