import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FILTER_EQUIPMENT_LOG } from 'src/app/graphql/logs.graphql';

@Injectable({
  providedIn: 'root'
})
export class EquipmentLogService {

  constructor(private apollo: Apollo) { }

  /**
    * Função responsável por filtrar os logs
    */
  public filterLogs(
    company: string | null,
    operation: any | null,
    startDatetime: any | null,
    endDatetime: any | null,
    serialNumber: any | null,
    macAddress: any | null,
    username: any | null,
    cursor: string | null,
    pageSize: number | null
  ): any {

    return this.apollo.watchQuery({
      query: FILTER_EQUIPMENT_LOG,
      fetchPolicy: 'network-only',
      variables: {
        company,
        operation,
        startDatetime,
        endDatetime,
        serialNumber,
        macAddress,
        username,
        pageSize,
        cursor
      }
    })
  }
}
