<!-- Sidebar de filtro -->
<div class="sidebar-filter">
  <!-- Botão responsável por fechar a sidebar de filtro -->
  <button title="Fechar" (click)="closeSidebarFilter()" class="close-sidebar">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg"
      viewBox="0 0 16 16">
      <path
        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
    </svg>
  </button>
  <h1> Filtrar equipamentos </h1>
  <!-- div que armazena os inputs de filtro -->
  <div class="inputs">
    <!-- Campo responsável por armazenar o filtro de referência -->
    <mat-form-field appearance="outline">
      <mat-label> Referência </mat-label>
      <input matInput [(ngModel)]="reference">
    </mat-form-field>
    <!-- <mat-form-field [(ngModel)]="adress" appearance="fill">
        <mat-label>Endereço(Parcial ou completo)</mat-label>
        <input matInput>
      </mat-form-field> -->
    <!-- Campo responsável por armazenar o filtro de gateway -->
    <mat-form-field appearance="outline">
      <mat-label> Concentrador </mat-label>
      <mat-select *ngIf="gatewayList.length" class="mat-select">
        <mat-option class="form-control" *ngFor="let gateway of gatewayList"
          (onSelectionChange)="setGateway(gatewaySelected.value)" value="{{ gateway.id }}"
          #gatewaySelected>{{gateway.reference}}</mat-option>
      </mat-select>
      <!-- Campo responsável por informar ao usuário que não gateway( caso esta sentença esteja correta) -->
      <mat-select *ngIf="gatewayList.length === 0" class="mat-select">
        <mat-option class="form-control" disabled> Não há concentradores nesta empresa </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- Campo responsável por armazenar o filtro de tipo de equipamento -->
    <mat-form-field appearance="outline">
      <mat-label> Tipo de equipamento </mat-label>
      <mat-select *ngIf="equipment_typeList.length" class="mat-select">
        <mat-option class="form-control" *ngFor="let equipmentType of equipment_typeList" type="check" disabled
          value="{{ equipmentType.id }}" #equipment_Type><mat-checkbox
            (click)="setEquipmentType(equipment_Type.value)"></mat-checkbox>{{ equipmentType.reference }}
        </mat-option>
      </mat-select>
      <!-- Campo responsável por informar ao usuário que não há tipos de equipamento(caso esta sentença esteja correta) -->
      <mat-select *ngIf="equipment_typeList.length === 0" class="mat-select">
        <mat-option class="form-control" disabled> Não há opções disponíveis </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-spinner *ngIf="isLoading" [diameter]="30"></mat-spinner>
  <button *ngIf="!isLoading" title="Filtrar" class="btn btn-success filter" (click)="sendQuery()">Filtrar</button>
  <p *ngIf="success && !fail " class="success"> Requisição concluída com sucesso! </p>
  <p *ngIf="!success && fail " class="fail"> Falha na requisição, por favor tente novamente! </p>
</div>