import { ConfigCommands, QueryCommands } from "./equipment-type-modal/model";

/** Classe que contém as propriedades do tipo de equipamento **/
export class EquipmentType {
    constructor(
        public id: string,
        public reference: string | null,
        public major: string | null,
        public minor: string | null,
        public revision: string | null,
        public releaseDate: string | null,
        public description: string | null,
        public equipmentParts: EquipmentParts[], // Contém uma lista de hardware e firmware
        public commandSheet: CommandSheet | null
    ) { }
}

/** Classe que contém todas as propriedades dos hardwares **/
export class Hardware {
    constructor(
        public id: string,
        public name: string | null,
        public major: number | null,
        public minor: number | null,
        public revision: number | null
    ) { }
}

/** Classe que contém todas as propriedades do firmwares **/
export class Firmware {
    constructor(
        public id: string,
        public name: string | null,
        public major: number | null,
        public minor: number | null,
        public revision: number | null,
        public checked: boolean = false
    ) { }
}

/** Clase que armazena o hardware e a lista de firmwares **/
export class EquipmentParts {
    constructor(
        public hardware: Hardware,
        public firmwares: Firmware[],
    ) { }
}

/** Classe que armazena as propriedades relacionadas aos comandos **/
export class CommandSheet {
    constructor(
        public id: string | null,
        public hasActuation: boolean,
        public hasClockSync: boolean,
        public hasPowerQuality: boolean,
        public oldCrc: boolean,
        public parameters: ConfigCommands,
        public queryCommands: QueryCommands
    ) { }
}

/** Classe que contém as propriedades de paginação **/
export class PaginationInfo {
    constructor(
        public count: number,
        public total: number,
        public hasNextPage: boolean,
        public hasPreviousPage: boolean,
        public startCursor: string | null,
        public endCursor: string | null
    ) { }
}