<div>
    <h1 mat-dialog-title *ngIf="propertiesCommand">{{ "command-modal.title" | translate }}</h1>
    <hr *ngIf="propertiesCommand">
    <div mat-dialog-content *ngIf="data">
        <table mat-table [dataSource]="dataSource" *ngIf="propertiesCommand" class="table">
            <!-- Reference Column -->
            <ng-container matColumnDef="demo-name">
                <th mat-header-cell *matHeaderCellDef> {{ "command-modal.reference" | translate }} </th>
                <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
                    on-mouseleave="element.view = false"> {{element.reference}} </td>
            </ng-container>
            <!-- Status Column -->
            <ng-container matColumnDef="demo-status" class="paginator">
                <th mat-header-cell *matHeaderCellDef> {{ "command-modal.status" | translate }} </th>
                <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
                    on-mouseleave="element.view = false">
                    <p *ngIf="element.status === 'SUCCESS'"> {{ "command-modal.success" | translate }}</p>
                    <p *ngIf="element.status === 'QUEUED'"> {{ "command-modal.queued" | translate }}</p>
                    <p *ngIf="element.status === 'DEV_ERR'"> {{ "command-modal.error" | translate }}</p>
                    <p *ngIf="element.status === 'INVALID'"> {{ "command-modal.invalid" | translate }}</p>
                </td>
            </ng-container>

            <!--responseGenerationDt/responseReceiptDt Column -->
            <ng-container matColumnDef="response-date">
                <th mat-header-cell *matHeaderCellDef>
                    {{"command-modal.generating-receiving-the-response" | translate}}:
                </th>
                <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
                    on-mouseleave="element.view = false">
                    {{element.responseGenerationDt }} {{element.responseReceiptDt}}
                </td>
            </ng-container>

            <!-- view status commands column -->
            <ng-container matColumnDef="view-status-commands">
                <th mat-header-cell *matHeaderCellDef>{{"command-modal.button-view" | translate}}</th>
                <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
                    on-mouseleave="element.view = false">
                    <button (click)="viewPropertiesCommand(element)" [ngClass]="element.view === true ? 'hover' : ' '"
                        title="Visualizar comando" class="btn btn-success view">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-eye" viewBox="0 0 16 16">
                            <path
                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                            <path
                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                        </svg>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator *ngIf="propertiesCommand" [length]="resultsLength" [pageSize]="10" [pageSizeOptions]="[5, 10]"
            aria-label="Selecione as páginas de Comandos" class="mat-paginator-sticky" showFirstLastButtons>
        </mat-paginator>

        <!-- Modal de status das propriedades dos comandos -->
        <div class="modal-properties-commands" *ngIf="!propertiesCommand">

            <!-- Botão para voltar ao modal de equipamentos -->
            <button (click)="openEquipmentsModal()" class="btn btn-success" id="btn-back">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-chevron-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                </svg>
            </button>

            <!-- Ícone para fechar o modal -->
            <i class="close-properties-command">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-x"
                    [mat-dialog-close]="data" id="btn-close " cursor="pointer" color="green" viewBox="0 0 16 16">
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </i>

            <!-- Título com o tipo de comando -->
            <h1 *ngIf="equipmentSelected.commands.type">
                {{"command-modal.command-status-title" | translate}}
                <h2 *ngIf="equipmentSelected.commands.type">
                    {{equipmentSelected.commands.type}}
                </h2>
            </h1>
            <hr>

            <!-- Lista das propriedades dos comandos -->
            <li *ngIf="equipmentSelected.responseData.relay != null">
                <strong> {{"command-modal.relay" | translate}}: </strong>
                {{equipmentSelected.responseData.relay}}
            </li>

            <li *ngIf="equipmentSelected.responseData.ir">
                <strong>{{"command-modal.network-identification" | translate}}: </strong>
                {{equipmentSelected.responseData.ir}}
            </li>
            <li *ngIf="equipmentSelected.responseData.tga != null">
                <strong> {{"command-modal.timing-application-messages" | translate}}: </strong>
                {{equipmentSelected.responseData.tga}}
            </li>

            <li *ngIf="equipmentSelected.responseData.tge != null">
                <strong> {{"command-modal.timing-diagnostic-messages" | translate}}: </strong>
                {{equipmentSelected.responseData.tge}}
            </li>

            <li *ngIf="equipmentSelected.responseData.tgm != null">
                <strong> {{"command-modal.timing-after-relay-docking" | translate}}: </strong>
                {{equipmentSelected.responseData.tgm}}
            </li>

            <!-- Verificar tradução -->
            <li *ngIf="equipmentSelected.responseData.tc != null">
                <strong> Tc: </strong> {{equipmentSelected.responseData.tc}}
            </li>

            <li *ngIf="equipmentSelected.responseData.has_digital_measurement != null">
                <strong> {{"command-modal.has-digital-measurement" | translate}}: </strong>
                {{equipmentSelected.responseData.has_digital_measurement}}
            </li>

            <li *ngIf="equipmentSelected.responseData.has_ldr != null">
                <strong> {{"command-modal.has-ldr" | translate}}:</strong>
                {{equipmentSelected.responseData.has_ldr}}
            </li>

            <li *ngIf="equipmentSelected.responseData.ldr_priority != null">
                <strong>{{"command-modal.ldr-priority" | translate}}:</strong>
                {{equipmentSelected.responseData.ldr_priority}}
            </li>

            <li *ngIf="equipmentSelected.responseData.serial_number != null">
                <strong>{{"command-modal.serial-number" | translate}}:</strong>
                {{equipmentSelected.responseData.serial_number}}
            </li>

            <li *ngIf="equipmentSelected.responseData.major != null">
                <strong>{{"command-modal.version" | translate}}:</strong>
                {{equipmentSelected.responseData.major}}.{{equipmentSelected.responseData.minor}}.{{equipmentSelected.responseData.rev}}
            </li>

            <li *ngIf="equipmentSelected.responseData.switch_on_time != null">
                <strong>{{"command-modal.switch-on" | translate}}:</strong>
                {{equipmentSelected.responseData.switch_on_time}}
            </li>

            <li *ngIf="equipmentSelected.responseData.switch_off_time != null">
                <strong>{{"command-modal.switch-off" | translate}}:</strong>
                {{equipmentSelected.responseData.switch_off_time}}
            </li>

            <li *ngIf="equipmentSelected.responseData.nacks_count != null">
                <strong>{{"command-modal.nacks-count" | translate}}:</strong>
                {{equipmentSelected.responseData.nacks_count}}
            </li>

            <li *ngIf="equipmentSelected.responseData.acks_count != null">
                <strong>{{"command-modal.acks-count" | translate}}:</strong>
                {{equipmentSelected.responseData.acks_count}}
            </li>

            <li *ngIf="equipmentSelected.responseData.reboots != null">
                <strong>{{"command-modal.reboots" | translate}}:</strong>
                {{equipmentSelected.responseData.reboots}}
            </li>

            <li *ngIf="equipmentSelected.responseData.command_fail_count != null">
                <strong>{{"command-modal.command-fail-count" | translate}}:</strong>
                {{equipmentSelected.responseData.command_fail_count}}
            </li>

            <li *ngIf="equipmentSelected.responseData.internal_temperature != null">
                <strong>{{"command-modal.internal-temperature" | translate}}:</strong>
                {{equipmentSelected.responseData.internal_temperature}}ºC
            </li>

            <li *ngIf="equipmentSelected.responseData.lux_switch_on_value != null">
                <strong> {{"command-modal.lux-switch-on-value" | translate}}:</strong>
                {{equipmentSelected.responseData.lux_switch_on_value}}
            </li>

            <li *ngIf="equipmentSelected.responseData.lux_switch_off_value != null">
                <strong> {{"command-modal.lux-switch-off-value" | translate}}: </strong>
                {{equipmentSelected.responseData.lux_switch_off_value}}
            </li>

            <li *ngIf="equipmentSelected.responseData.ldr_check_interval != null">
                <strong> {{"command-modal.ldr-check-interval" | translate}}: </strong>
                {{equipmentSelected.responseData.ldr_check_interval}}
            </li>

            <li *ngIf="equipmentSelected.responseData.lamp_status_detection != null">
                <strong> {{"command-modal.lamp_status_detection" | translate}}: </strong>
                {{equipmentSelected.responseData.lamp_status_detection}}
            </li>

            <li *ngIf="equipmentSelected.responseData.message_bitmask != null">
                <strong> {{"command-modal.message-bitmask" | translate}}: </strong>
                {{equipmentSelected.responseData.message_bitmask}}
            </li>

            <li *ngIf="equipmentSelected.responseData.dissociation_interval != null">
                <strong> {{"command-modal.dissociation-interval" | translate}}: </strong>
                {{equipmentSelected.responseData.dissociation_interval}}
            </li>

            <li *ngIf="equipmentSelected.responseData.ts_app != null">
                <strong> {{"command-modal.ts-app" | translate}}: </strong>
                {{equipmentSelected.responseData.ts_app}}
            </li>

            <li *ngIf="equipmentSelected.responseData.dimming != null">
                <strong> {{"command-modal.dimming" | translate}}: </strong>
                {{equipmentSelected.responseData.dimming}} %
            </li>

            <li *ngIf="equipmentSelected.responseData.current_consumption != null">
                <strong> {{"command-modal.current-consumption" | translate}}: </strong>
                {{equipmentSelected.responseData.current_consumption}} mA
            </li>

            <li *ngIf="equipmentSelected.responseData.has_current_consumption != null">
                <strong> {{"command-modal.has-current-consumption"| translate}}: </strong>
                {{equipmentSelected.responseData.has_current_consumption}}
            </li>

            <li *ngIf="equipmentSelected.responseData.light_sensor_value != null">
                <strong> {{"command-modal.light-sensor-value" | translate}}: </strong>
                {{equipmentSelected.responseData.light_sensor_value}} Lux
            </li>

            <li *ngIf="equipmentSelected.responseData.operating_voltage != null">
                <strong> {{"command-modal.operating-voltage" | translate}}: </strong>
                {{equipmentSelected.responseData.operating_voltage}}
            </li>

            <li *ngIf="equipmentSelected.responseData.meter_current_consumption != null">
                <strong> {{"command-modal.meter-current-consumption" | translate}}: </strong>
                {{equipmentSelected.responseData.meter_current_consumption}}
            </li>

            <li *ngIf="equipmentSelected.responseData.active_power != null">
                <strong> {{"command-modal.active_power" | translate}}: </strong>
                {{equipmentSelected.responseData.active_power}}
            </li>

            <li *ngIf="equipmentSelected.responseData.reactive_power != null">
                <strong> {{"command-modal.reactive_power" | translate}}: </strong>
                {{equipmentSelected.responseData.reactive_power}}
            </li>

            <li *ngIf="equipmentSelected.responseData.active_energy != null">
                <strong> {{"command-modal.active_energy"| translate}}: </strong>
                {{equipmentSelected.responseData.active_energy}}
            </li>

            <li *ngIf="equipmentSelected.responseData.power_factor != null">
                <strong> {{"command-modal.power_factor" | translate}}: </strong>
                {{equipmentSelected.responseData.power_factor}}
            </li>

            <li *ngIf="equipmentSelected.responseData.ts_reference != null">
                <strong> {{"command-modal.ts_reference" | translate}}: </strong>
                {{equipmentSelected.responseData.ts_reference}}
            </li>
            <br>

            <h1> {{"command-modal.data-sent-title" | translate}} </h1>
            <hr>

            <!-- Título dados enviados -->
            <h2 *ngIf="equipmentSelected.commands.requestDt">
                <strong> {{"command-modal.request-date" | translate}}: </strong>
                {{equipmentSelected.commands.requestDt}}
            </h2>

            <h2 *ngIf="equipmentSelected.commands.type">
                <strong> {{"command-modal.query-type" | translate}}: </strong>
                {{equipmentSelected.commands.type}}
            </h2>

            <h2 *ngIf="equipmentSelected.commands.parameter">
                <strong> {{"command-modal.parameter" | translate}}: </strong>
                {{equipmentSelected.commands.parameter}}
            </h2>

            <h2 *ngIf=" equipmentSelected.commands.value != null">
                <strong> {{"command-modal.value" | translate}}:</strong>
                {{equipmentSelected.commands.value}}%
            </h2>

            <h2 *ngIf="equipmentSelected.commands.user.firstName">
                <strong> {{"command-modal.user" | translate}}: </strong>
                {{equipmentSelected.commands.user.firstName}} {{equipmentSelected.commands.user.lastName}}
            </h2>


        </div>
    </div>

    <div mat-dialog-actions class="actions">
        <button mat-button [mat-dialog-close]="data" cdkFocusInitial *ngIf="propertiesCommand" class="btn btn-success">
            {{"command-modal.close" | translate }}
        </button>
    </div>
</div>