import { gql } from "apollo-angular";

const GET_ALL_ALERTS = gql`
query Alert(
  $company: ID!
  $First: Int
  $cursor: String
  $sort_dir: RatatoskrDirection!
  $sort_field: AlertOrdenationFields!
  $installation: String
  $startDateTime: DateTime
  $endDateTime: DateTime,
  $status: [AlertStatus]
  $alertRule: ID
  ) {
  alert(
    company: $company
    First: $First
    After: $cursor
    orderBy:{
      direction: $sort_dir
      field: $sort_field
    }
    startDatetime: $startDateTime
    endDatetime: $endDateTime
    installation: {
		reference: $installation
	}
    status: $status
    alertRule: $alertRule
    ) {
      count
      total
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          alertDatetime
          status
          installation {
            reference
          }
          alertRule {
            name
          }
          occurrences
        }
        cursor
    }
  }
}`

const GET_ALERT_BY_ID = gql`
query node($id: ID!) {
			node(id: $id) {
				...on Alert {
					id
					alertDatetime
					deviceMac
					status
					installation {
						id
						reference
						applications
						site {
							timezone
						}
					}
					alertRule {
						name
						propertyRules {
							comparisonRule
							comparisonValue
							monitoredProperty {
								name
								measurementUnit {
									symbol
								}
							}
						}
					}
					generatedEvents {
						timestamp
						properties
					}
				}
			}
		}
`


const CHANGE_ALERT_STATUS = gql`
mutation
						AlertStatusBulkUpdate(
							$ids: [ID]!,
							$status: AlertStatus!) {
							alertStatusBulkUpdate(
								input: {
									ids: $ids,
									status: $status
								}) {
								alertsUpdated {
									id
									status
								}
								errors {
									id
									cause
								}
							}
						}`


export {
	// Queries
	GET_ALL_ALERTS,
	GET_ALERT_BY_ID,
	// Mutations
	CHANGE_ALERT_STATUS,
}
