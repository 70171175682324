import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Command, Equipment } from '../commands/commands-assets/channel-model';

@Component({
  selector: 'app-commands-modal',
  templateUrl: './commands-modal.component.html',
  styleUrls: ['./commands-modal.component.less']
})
export class CommandsModalComponent {
  constructor(public dialogRef: MatDialogRef<CommandsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog
  ) { }

  // Seta o nome de cada coluna da tabela
  public displayedColumns: string[] = ['demo-name', 'demo-status', 'response-date', 'view-status-commands'];

  // Renderiza os comandos da lista ELEMENT_DATA para a tabela
  public dataSource = new MatTableDataSource<any>(this.data);

  //Variáveis usadas para o paginator e para o sort.
  private paginator: MatPaginator;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  /**
 * Função que configura o paginator.
 */
  public setDataSourceAttributes() {
    this.dataSource.data = this.data;
    this.dataSource.paginator = this.paginator;
  }

  // Variável que guarda o número de relatórios retornados do backend.
  public resultsLength = this.data.length;

  /** Variável utilizada para realizar a manipulação do modal de status dos comandos **/
  public propertiesCommand: boolean = true;

  /** Variável utilizada para acessar as propriedades do equipamento selecionado **/
  public equipmentSelected: Equipment;

  /** Método utilizado para manipular o modal de status dos comandos,
   *  passando a lista de equipamentos para acessar suas propriedades **/
  viewPropertiesCommand(equipment: Equipment) {
    this.equipmentSelected = equipment;
    /** Fecha o modal de status dos comandos **/
    this.propertiesCommand = false;
  }

  /** Método utilizado para abrir o modal de equipamentos **/
  openEquipmentsModal() {
    this.propertiesCommand = true;
  }
}
