import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CompaniesService } from 'src/shared/services/companies.service';
import { Company, PaginationInfo } from './companies-assets/companies-model';
import { jsPDF } from 'jspdf';
import Swal from 'sweetalert2';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { Subscription } from 'rxjs';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.less'],
})

export class CompaniesComponent extends Unsubscriber implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    public errorLibService: ErrorLibService,
    private companiesService: CompaniesService
  ) {
    super();
  }

  ngOnInit(): void { }

  // Variaveis responáveis por abrir e fechar as boxes
  public register: boolean = false;
  public filter: boolean = false;

  // Variaveis responsáveis pelos loadings da tela
  public filterLoading: boolean = false;
  public registerLoading: boolean = false;
  public fileLoading: boolean = false;

  public filteredReference: string;

  //Variavel responsável por armazenas os dados da tabela
  public ELEMENT_DATA: Company[] = [];

  /** Variáveis utilizadas para geração e manipulação dos arquivos PDF e CSV **/
  public FILE_DATA: Company[] = [];
  public isReady = false;

  // Variável que guarda o número de relatórios retornados do backend.
  public resultsLength = this.ELEMENT_DATA.length;

  // Para receber o ID da empresa selecionada para edição.
  public companySelected: string;

  // Controlador para apresentação da label edição de empresas.
  public editorController: boolean = false;

  // Variavel responsável por configurar o nome das colunas da tabela
  public displayedColumns: string[] = ['name', 'cnpj', 'actions'];

  // Renderiza os comandos da lista ELEMENT_DATA para a tabela
  public dataSource = new MatTableDataSource<Company>(this.ELEMENT_DATA);

  /**
* Função que configura o paginator.
*/
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  //Variáveis usadas para o paginator e para o sort.
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null);

  /** Variáveis utilizadas para manipulação do Paginator **/
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 10;

  /** Função chamada toda vez que ocorre um evento no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.handlePreviousPage(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterCompanies(this.paginationProperties.endCursor)
    }
  }

  // Armazena os dados do formulário de filtro de empresas, o valor é setado automáticamente pelo HTML
  public filterCompaniesForm: FormGroup = this.formBuilder.group({
    search: [null],
  });

  // Armazena os dados do formulário de filtro de empresas, o valor é setado automáticamente pelo HTML
  public registerCompaniesForm: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
    cnpj: [null, Validators.required],
  });

  // Formulário com o conteúdo a ser editado na API.
  updateForm: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
    cnpj: [null, Validators.required],
  });


  // Função responsável por mudar o estado da box de filtro e fechar a de registro
  Filter(): void {
    this.register = false;
    this.filter = !this.filter;
  }

  // Função responsável por mudar o estado da box de registro e fechar a de filtro
  Register(): void {
    this.filter = false;
    this.register = !this.register;
  }

  /**
   * Função responsavel por filtrar Empresas
   */
  public filterCompanies(cursor: string | null) {

    this.filteredReference = this.filterCompaniesForm.get('search')?.value;

    this.subscriptions = this.companiesService.filterCompanies(
      this.filteredReference,
      cursor,
      this.pageSize
    ).valueChanges.subscribe({
      next: ((response: any) => {
        // Adiciona as informações de paginação da API na página.
        this.paginationProperties = new PaginationInfo(
          response.data?.company?.count,
          response.data?.company?.total,
          response.data?.company?.pageInfo?.hasNextPage,
          response.data?.company?.pageInfo?.hasPreviousPage,
          response.data?.company?.pageInfo?.startCursor,
          response.data?.company?.pageInfo?.endCursor
        )

        this.ELEMENT_DATA = [];
        this.length = this.paginationProperties.total;

        // Adiciona cada empresa retornada pela requisição na lista
        response.data.company.edges.forEach((node: any) => {
          this.ELEMENT_DATA.push(
            new Company(
              node.node.id,
              node.node.name,
              node.node.cnpj
            ));
        })

        /** Atualiza a tabela com os novos dados **/
        this.setDataSourceAttributes();
        //this.isReady = false;

        if (this.ELEMENT_DATA.length === 0) {
          /** Retorna alerta ao usuário **/
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        }

        this.filterLoading = false;
      }),
      error: ((error: any) => {
        this.errorLibService.errorAlert(error);
      })
    });
  }

  public handlePreviousPage(cursor: string | null): void {

    if (cursor == null) {
      this.pageIndex = 0;
    }

    this.subscriptions = this.companiesService.handlePreviousPage(
      this.filteredReference,
      cursor,
      this.pageSize
    )
      .valueChanges.subscribe({
        next: ((response: any) => {
          this.paginationProperties = new PaginationInfo(
            response.data?.company?.count,
            response.data?.company?.total,
            response.data?.company?.pageInfo?.hasNextPage,
            response.data?.company?.pageInfo?.hasPreviousPage,
            response.data?.company?.pageInfo?.startCursor,
            response.data?.company?.pageInfo?.endCursor
          )

          this.ELEMENT_DATA = [];
          this.length = this.paginationProperties.total;

          // Adiciona cada empresa retornada pela requisição na lista
          response.data.company.edges.forEach((node: any) => {
            this.ELEMENT_DATA.push(
              new Company(
                node.node.id,
                node.node.name,
                node.node.cnpj
              ));
          })

          this.setDataSourceAttributes();

          if (!response.data.company.pageInfo.hasNextPage) {
            if (this.ELEMENT_DATA.length === 0) {
              Swal.fire({
                title: 'Sua pesquisa não retornou resultados',
                icon: 'warning',
                confirmButtonText: 'Ok',
              });
            }
          }
        })
      });
  }

  /**
   * Função responsavel por criar Empresas
   */
  public registerCompanies() {
    if (this.registerCompaniesForm.valid) {

      this.registerLoading = true;

      this.subscriptions = this.companiesService.registerCompanies(
        this.registerCompaniesForm.get('name')?.value,
        this.registerCompaniesForm.get('cnpj')?.value
      ).subscribe({
        next: (() => {
          this.filterCompaniesForm.patchValue({
            reference: this.registerCompaniesForm?.value.reference
          });

          this.filterCompanies(null);

          this.filterCompaniesForm.patchValue({
            reference: null
          })
          Swal.fire({
            title: 'Empresa criada',
            text: 'Empresa criada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          this.registerLoading = false;
        }),
        error: (error: any) => {
          console.log("CreateCompanies", error)
          this.errorLibService.errorAlert(error);
        }
      })

    }
    else {
      Swal.fire({
        title: 'Formulário Incompleto',
        text: 'Preencha todos os campos',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });

      this.registerLoading = false;;

    }
  }

  public updateGetter(id: string, name: string | null, cnpj: string | null) {
    this.companySelected = id;
    this.updateForm.setValue({
      name,
      cnpj,
    });
    this.editorController = true;
  }

  // Realiza a alteração dos dados da empresa.
  public updateCompany() {
    if (this.updateForm.valid) {
      this.subscriptions = this.companiesService.updateCompany(
        this.companySelected,
        this.updateForm.get('name')?.value,
        this.updateForm.get('cnpj')?.value
      ).subscribe({
        next: ((response: any) => {
          Swal.fire({
            title: 'Empresa atualizada',
            text: 'Empresa atualizada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });
          this.ELEMENT_DATA = [
            new Company(
              response.node.id,
              response.node.name,
              response.node.cnpj
            )
          ];
          this.setDataSourceAttributes();
          this.editorController = false;
        }),
        error: (error: any) => {
          console.log("updateCompanies", error)
          this.errorLibService.errorAlert(error);
        }
      })
    }
    else {
      Swal.fire({
        text: 'Confirme os dados antes de atualizar',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
    // Fecha a tela lateral.
    this.editorController = false;
    // Limpa a variável com o ID da empresa selecionada.
    this.companySelected = '';
  }

  // Fecha a barra lateral quando clicado no botão.
  closeEditionSideBar() {
    // Fecha a tela lateral.
    this.editorController = false;
    // Limpa a variável com o ID da empresa selecionada.
    this.companySelected = '';
  }

  // ---------------------------------------------------------------------
  // Código destinado à funções de download de CSV e PDF da página.

  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscriptions = this.companiesService.filterCompanies(
        this.filteredReference,
        cursor,
        50
      ).valueChanges.subscribe({
        next: ((response: any) => {
          response.data.company.edges.forEach((node: any) => {
            this.FILE_DATA.push(
              new Company(
                node.node.id,
                node.node.name,
                node.node.cnpj
              )
            )
          });
          /** Caso seja a última página a lista está completa **/
          if (!response.data?.company?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.company?.pageInfo?.endCursor, fileType);
        }),
        error: ((error: any) => {
          this.errorLibService.errorAlert(error);
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  // Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe.
  private convertToCSV(arr: any) {
    // Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas.
    const content = [Object.keys(arr[0])].concat(arr);

    // Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um.
    return content
      .map((item) => {
        return Object.values(item).toString().replace(',', ';');
      })
      .join('\n');
  }

  // Função que realiza o download do CSV.
  public downloadCSV() {
    let content: any = [];

    // Adicoina cada elemento como um objeto na lista de conteúdo.
    this.FILE_DATA.forEach((company) => {
      content.push({
        Nome: company.name,
        CNPJ: company.cnpj,
      });
    });

    // Adiciona o conteúdo convertido dentro de uma nova variável.
    let data = this.convertToCSV(content);
    // Transforma o conteúdo para o formato blob para geração do arquivo.
    let file = new Blob([data], { type: 'text/csv' });
    // Cria um endereçamento para o arquivo.
    let url = window.URL.createObjectURL(file);
    // Cria um elemento para a linkagem do arquivo.
    let link = document.createElement('a');
    // Adiciona a url no elemento de linkagem.
    link.href = url;
    // Nomeia o arquivo que será feito o download.
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-companies.csv`;
    // Adiciona o link no documento.
    document.body.appendChild(link);
    // Atica o evento de click para realizar o download.
    link.click();
    // Remove o link do documento.
    document.body.removeChild(link);
    // Remove o endereço do download.
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  // Função que realiza o download do CSV.
  public downloadPDF() {
    // Define o cabeçalho do documento.
    const header = ['Nome', 'CNPJ'];
    // Lista que irá conter o conteúdo retornado pela API.
    let content: any = [];
    // Inserção do conteúdo na lista.
    this.FILE_DATA.forEach((company) => {
      content.push([company.name, company.cnpj]);
    });
    // Define o formato do documento.
    let file: any = new jsPDF('l', 'mm', 'a4');
    // Cria o PDF.
    file.autoTable({
      theme: 'grid',
      margin: { top: 20 },
      head: [header],
      body: content,
    });
    // Gera o download do documento.
    file.save(
      `${new Date().toLocaleDateString()}-${new Date().getHours()}:${new Date().getMinutes()}-companies.pdf`
    );

    this.FILE_DATA = [];
    this.isReady = false;
  }
}
