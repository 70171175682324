import { createReducer, on } from "@ngrx/store";
import { LoginPageActions } from "src/shared/actions-index";
import { LoginPageApiActions } from "src/shared/actions-index";
import { Login } from "../models/login.model";

export interface State {
    loginData: Login;
    loggedUserId: string | null;
    token: string | null;
    error: string | null;
    loading: boolean;
}

export const initialState: State = {
    loginData: new Login(null, null),
    loggedUserId: null,
    token: null,
    error: null,
    loading: false
}

export const reducer = createReducer(
    initialState,
    on(LoginPageActions.enter, (state) => {
        return {
            ...state,
            loginData: new Login(null, null),
            loggedUserId: null,
            token: null,
            loading: false
        }
    }),
    on(LoginPageActions.login, (state, { login }) => {
        return {
            ...state,
            loginData: login,
            loggedUserId: null,
            token: null,
            error: null,
            loading: true
        }
    }),
    on(LoginPageApiActions.loginSuccess, (state, { loggedUserId, token }) => {
        return {
            ...state,
            loggedUserId: loggedUserId,
            token: token,
            error: null,
            loading: false
        }
    }),
    on(LoginPageApiActions.loginFailed, (state, { error }) => {
        return {
            ...state,
            error: error,
            loggedUserId: null,
            token: null,
            loading: false
        }
    }),
)

export const getLoginData = (state: State) => state.loginData;
export const getLoggedUserId = (state: State) => state.loggedUserId;
export const getToken = (state: State) => state.token;
export const getError = (state: State) => state.error;
export const getLoading = (state: State) => state.loading;

