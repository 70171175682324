<!-- Container principal da página  -->
<div class="component-main-container">
  <app-page-header parentTitle="{{ 'hardwares.hardware-label' | translate }}"
    pageTitle="{{ 'hardwares.generate-hardware-label' | translate }}">
  </app-page-header>

  <div class="row">
    <div class="col-lg-5 col-xl-4 col-sm-12">
      <div class="component-forms-box">
        <app-register-form></app-register-form>

        <!-- Sessão de filtro de Hardwares -->
        <div class="form-fields-box" [ngClass]="!filterHardwares ? 'closed' : ''" [formGroup]="filterHardwareForm">
          <h2 (click)="filterHardwaresOpen()">
            {{ "hardwares.filter-hardware" | translate }}
          </h2>
          <hr [ngClass]="!filterHardwares ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label> {{ "hardwares.label-name" | translate }} </mat-label>
              <input matInput formControlName="name" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">2</div>
            <mat-form-field appearance="outline">
              <mat-label> {{ "hardwares.label-major" | translate }} </mat-label>
              <input matInput type="number" min="0" formControlName="major" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> {{ "hardwares.label-minor" | translate }} </mat-label>
              <input matInput type="number" min="0" formControlName="minor" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>
                {{ "hardwares.label-revision" | translate }}
              </mat-label>
              <input matInput type="number" min="0" formControlName="revision" />
            </mat-form-field>
          </div>
          <div class="d-flex m-2">
            <button class="btn btn-success m-2 w-50" (click)="filterHardware(null)">
              {{ "hardwares.button-filter" | translate }}
            </button>
            <button class="btn btn-secondary m-2 w-50" (click)="resetFilter()">
              {{ "hardwares.reset-button-filter" | translate }}
            </button>
          </div>
        </div>

        <!-- Sessão para criar novo Hardware -->
        <div class="form-fields-box" [ngClass]="!registerHardwares ? 'closed' : ''" [formGroup]="createHardwareForm">
          <h2 (click)="registerHardwaresOpen()">
            {{ "hardwares.create-hardware" | translate }}
          </h2>
          <hr [ngClass]="!registerHardwares ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label> {{ "hardwares.label-name" | translate }} </mat-label>
              <input matInput formControlName="nameCreateForm" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">2</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "hardwares.label-release-date" | translate }}
              </mat-label>
              <input matInput [matDatepicker]="datepicker" formControlName="releaseDateCreateForm" />
              <mat-datepicker-toggle matIconSuffix [for]="datepicker">
              </mat-datepicker-toggle>
              <mat-datepicker #datepicker> </mat-datepicker>
            </mat-form-field>
          </div>

          <div class="form-step version">
            <div class="circle-number">3</div>
            <mat-form-field appearance="outline">
              <mat-label> {{ "hardwares.label-major" | translate }} </mat-label>
              <input matInput type="number" formControlName="majorCreateForm" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> {{ "hardwares.label-minor" | translate }} </mat-label>
              <input matInput type="number" min="0" formControlName="minorCreateForm" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>
                {{ "hardwares.label-revision" | translate }}
              </mat-label>
              <input matInput type="number" min="0" formControlName="revisionCreateForm" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">4</div>
            <mat-form-field appearance="outline">
              <mat-label>
                {{ "hardwares.label-description" | translate }}
              </mat-label>
              <textarea matInput formControlName="descriptionCreateForm"></textarea>
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">5</div>
            <mat-form-field *ngIf="!hardwaresLoading" appearance="outline">
              <mat-label>
                {{ "hardwares.label-compatible-firmware" | translate }}
              </mat-label>
              <mat-select value="default">
                <mat-option disabled value="default">
                  {{ selectedNewFirmwaresIdList.length }}
                  {{ "hardwares.selected-firmware" | translate }}
                </mat-option>
                <span *ngFor="let newFirmware of firmwaresList" class="d-flex justify-content-start align-items-center">
                  <mat-checkbox class="ms-2 me-2" (change)="filterSelectedFirmwares($event, newFirmware.id)"
                    class="ms-2 me-2">
                    {{ newFirmware.name }} ({{ newFirmware.version }})
                  </mat-checkbox>
                </span>
              </mat-select>
            </mat-form-field>
            <mat-spinner *ngIf="hardwaresLoading" [diameter]="30"></mat-spinner>
          </div>

          <!-- Sessão para criar Firmware compatível -->
          <mat-accordion class="newFirmware" [formGroup]="createFirmwareForm">
            <mat-expansion-panel (opened)="panelOpenHardware = true" (closed)="panelOpenHardware = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "hardwares.create-new-firmware" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="form-step">
                <div class="circle-number">1</div>
                <mat-form-field appearance="outline">
                  <mat-label>
                    {{ "hardwares.label-name" | translate }}
                  </mat-label>
                  <input matInput formControlName="nameCreateFirmwareForm" />
                </mat-form-field>
              </div>

              <div class="form-step">
                <div class="circle-number">2</div>
                <mat-form-field appearance="outline">
                  <mat-label>
                    {{ "hardwares.label-release-date" | translate }}
                  </mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="releaseDateCreateFirmwareForm" />
                  <mat-datepicker-toggle matIconSuffix [for]="picker">
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker> </mat-datepicker>
                </mat-form-field>
              </div>

              <div class="form-step version">
                <div class="circle-number">3</div>
                <mat-form-field appearance="outline">
                  <mat-label>
                    {{ "hardwares.label-major" | translate }}
                  </mat-label>
                  <input matInput type="number" min="0" title="{{ 'hardwares.title-major' | translate }}"
                    formControlName="majorCreateFirmwareForm" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    {{ "hardwares.label-minor" | translate }}
                  </mat-label>
                  <input matInput type="number" min="0" title="{{ 'hardwares.title-minor' | translate }}"
                    formControlName="minorCreateFirmwareForm" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    {{ "hardwares.label-revision" | translate }}
                  </mat-label>
                  <input matInput type="number" min="0" title="{{ 'hardwares.title-revision' | translate }}"
                    formControlName="revisionCreateFirmwareForm" />
                </mat-form-field>
              </div>

              <div class="form-step">
                <div class="circle-number">4</div>
                <mat-form-field appearance="outline">
                  <mat-label>
                    {{ "hardwares.label-description" | translate }}
                  </mat-label>
                  <input matInput formControlName="descriptionCreateFirmwareForm" />
                </mat-form-field>
              </div>
              <button class="btn btn-success" (click)="createCompatibleFirmware()"
                [disabled]="createFirmwareForm.invalid">
                {{ "hardwares.button-save" | translate }}
              </button>
              <mat-spinner *ngIf="firmwaresLoading" [diameter]="30"></mat-spinner>
            </mat-expansion-panel>
          </mat-accordion>
          <button class="btn btn-success" (click)="createHardware()" [disabled]="createHardwareForm.invalid">
            {{ "hardwares.button-save" | translate }}
          </button>
          <mat-spinner *ngIf="firmwaresLoading" [diameter]="30"></mat-spinner>
        </div>
      </div>
    </div>

    <div class="col-lg-7 col-xl-8 col-sm-12">
      <app-table-header *ngIf="length > 0" [itensNumber]="paginationProperties.count"
        [totalNumber]="paginationProperties.total" [hasCSV]="true" [hasPDF]="true"
        (downloadCSV)="generateFileData(null, 'CSV')" (downloadPDF)="generateFileData(null, 'PDF')"
        [loadingData$]="fileLoading">
      </app-table-header>
      <!-- Sessão tabela -->
      <div class="table-div mt-2 mt-lg-3 mt-xl-3">
        <mat-progress-bar mode="query" *ngIf="hardwaresLoading">
        </mat-progress-bar>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
              {{ "hardwares.label-name" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <!-- Version Column -->
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef>
              {{ "hardwares.version-hardware" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.version }}</td>
          </ng-container>

          <!-- ReleaseDate -->
          <ng-container matColumnDef="releaseDate">
            <th mat-header-cell *matHeaderCellDef>
              {{ "hardwares.label-release-date" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.releaseDate | date : "dd/MM/YYYY" }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" (page)="pageChanged($event)"
          aria-label="Selecione as páginas de Hardwares" class="mat-paginator-sticky">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>