import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Device, Gateway, InstallationSite, PaginationInfo } from './gateways-model/gateways.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GatewaysService } from 'src/shared/services/gateways.service';
import Swal from 'sweetalert2';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import jsPDF from 'jspdf';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-gateways',
  templateUrl: './gateways.component.html',
  styleUrls: ['./gateways.component.less']
})

export class GatewaysComponent extends Unsubscriber implements OnInit {

  ngOnInit(): void { }

  constructor(
    private formBuilder: FormBuilder,
    private gatewaysService: GatewaysService,
    private errorLibService: ErrorLibService,
  ) {
    super();
  }

  /** Variáveis utilizadas para manipulação da abertura e fechamento dos boxs **/
  public filterBox: boolean = false;
  public registerBox: boolean = false;

  /** Método utilizado para abrir e fechar o box de filtro de concentradores **/
  public filterBoxOpen() {
    this.filterBox = !this.filterBox;
    this.registerBox = false;
  }

  /** Método utilizado para abrir e fechar o box de registro de concentradores **/
  public registerBoxOpen() {
    this.registerBox = !this.registerBox;
    this.filterBox = false;
  }

  /** Variáveis responsáveis por armazenar os campos de local de instalação no filtro de gateways  **/
  public filterReference: string | null = null;
  public filterAddress: string | null = null;
  public filterPostalCode: string | null = null;
  public filterPublicPlace: string | null = null;
  public filterNumber: number | null = null;
  public filterComplement: string | null = null;
  public filterDistrict: string | null = null;
  public filterCity: string | null = null;
  public filterState: string | null = null;
  public filterCountry: string | null = null;
  public filterLat: any = null;
  public filterLong: any = null;
  public filterPlaceholder: any = '';

  /** Campo que armazena o campo do endereço completo do local de instalação **/
  public fullAddress: FormControl = new FormControl();

  /** ViewChild responsáveis por receber os endereços da lib autocomplete **/
  @ViewChild('filter') filterField: ElementRef;

  /** Variável responsáveis por receber os endereços da lib autocomplete **/
  public autocompleteFilter: google.maps.places.Autocomplete | undefined;

  ngAfterViewInit() {
    /** Variaveis responsáveis por filtrar na API do google os endereços referentes aos viewchild **/
    this.autocompleteFilter = new google.maps.places.Autocomplete(this.filterField.nativeElement);
    /** Listener responsável por interceptar alterações no campo endereço completo(Formulário de filtro) **/
    this.autocompleteFilter.addListener('place_changed', () => {

      /** Variável responsável por receber o último endereço selecionado pelo usuário **/
      const place = this.autocompleteFilter?.getPlace();

      /**  Reseta todos os campos do formulário **/
      this.filterPostalCode, this.filterNumber, this.filterDistrict,
        this.filterCity, this.filterState, this.filterCountry,
        this.filterLat, this.filterLong = null;

      /** Atualiza todos os campos do formulário de acordo com o último endereço selecionado **/
      place?.address_components?.forEach((property) => {
        if (property?.types[0] === 'postal_code') {

          this.filterPostalCode = property?.short_name;
        }
        else if (property.types[0] === "street_number") {
          this.filterNumber = parseInt(property?.short_name);
        }
        else if (property.types[0] === "sublocality_level_1") {
          this.filterDistrict = property?.short_name;
        }
        else if (property.types[0] === "administrative_area_level_2") {
          this.filterCity = property?.short_name;
        }
        else if (property.types[0] === "administrative_area_level_1") {
          this.filterState = property?.short_name;
        }
        else if (property.types[0] === "country") {
          this.filterCountry = property?.short_name;
        }
        else if (property.types[0] === "route") {
          this.filterPublicPlace = property?.long_name
        }
      });

      this.filterLat = place?.geometry?.location?.lat();
      this.filterLong = place?.geometry?.location?.lng();
    });
  }

  /** Variáveis que armazenam os campos do formulário de criação de gateways **/
  public reference: FormControl = new FormControl(null, Validators.required)
  public equipment: FormControl = new FormControl(null, Validators.requiredTrue)
  public equipmentSelected: FormControl = new FormControl(null, Validators.required)
  public installationSite: FormControl = new FormControl(null, Validators.requiredTrue)
  public installationSiteSelected: FormControl = new FormControl(null, Validators.required)

  /** Variáveis que armazenam os campos do formulário de filtro de gateways **/
  public filterForm: FormGroup = this.formBuilder.group({
    reference: [null],
    equipment: [null],
    equipmentSelected: [null]
  })

  /** Variáveis que armazenam os campos do formulário de edição **/
  public updateForm: FormGroup = this.formBuilder.group({
    id: [null],
    reference: [null, Validators.required],
    equipment: [null, Validators.requiredTrue],
    equipmentSelected: [null, Validators.required],
    installationSite: [null, Validators.requiredTrue],
    installationSiteSelected: [null, Validators.required],
    isActive: []
  })

  /** Variável que armazena a lista de gateways utilizada para manipular a tabela dos gateways **/
  public ELEMENT_DATA: Gateway[] = [];
  /** Variável que armazena a lista de tipos de equipamentos (utilizada apenas no filtro de gateways) **/
  public equipmentListFilter: Device[] = [];
  /** Variável que armazena a lista de tipos de equipamentos (utilizada apenas na criação de gateways) **/
  public equipmentListCreate: Device[] = [];
  /** Variável que armazena a lista de tipos de equipamentos (utilizada apenas na atualização de gateways) **/
  public equipmentListUpdate: Device[] = [];
  /** Variável que armazena a lista de locais de instalações (utilizado apenas na criação de gateways) **/
  public installationSiteListCreate: InstallationSite[] = [];
  /** Variável que armazena a lista de locais de instalações (utilizado apenas na atualização de gateways) **/
  public installationSiteListUpdate: InstallationSite[] = [];

  /** Variáveis de loading **/
  public filterLoading: boolean = false;
  public createLoading: boolean = false;
  public updateLoading: boolean = false;
  public equipmentLoadingFilter: boolean = false;
  public equipmentLoadingCreate: boolean = false;
  public equipmentLoadingUpdate: boolean = false;
  public installationSiteLoading: boolean = false;
  public installationSiteLoadingUpdate: boolean = false;

  /** Variáveis utilizadas para manipular o sidebar de edição/ visualização **/
  public viewController: boolean = false;
  public editorController: boolean = false;

  /** Variáveis utilizadas na geração dos arquivos CSV e PDF**/
  public FILE_DATA: Gateway[] = [];
  public isReady: boolean = false;
  public fileLoading: boolean = false;

  /** Variáveis utilizadas na manipulação da renderizção da tabela **/
  public dataSource = new MatTableDataSource<Gateway>(this.ELEMENT_DATA)
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  /** Variável que armazena as colunas da tabela **/
  public displayedColumns: string[] = [
    'reference',
    'installationSite',
    'equipmentType',
    'serialNumber',
    'status',
    'actions'
  ];

  /** Variável que define as propriedades de paginação **/
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null);

  /** Variáveis utilizadas para manipulação do Paginator **/
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /** Função chamada toda vez que ocorre um evento no paginator **/
  pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.filterGateways(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterGateways(this.paginationProperties.endCursor)
    }
  }

  /** Método que realiza o filtro dos gateways **/
  filterGateways(cursor: string | null): any {
    /** se o cursor for nulo  **/
    if (cursor == null) {
      this.pageIndex = 0;
      /** A lista dos arquivos é definida como pronta (download é realizado caso o usuário tenha solicitado) **/
      this.isReady = true;
    }

    /** Ativa o loading  **/
    this.filterLoading = true;
    /** Realiza a requisição que filtra os gateways **/
    this.subscriptions = this.gatewaysService.filterGateways(
      this.filterForm?.value?.reference,
      this.filterForm?.value?.equipmentSelected,
      this.filterCountry,
      this.filterState,
      this.filterCity,
      this.filterDistrict,
      this.filterPublicPlace,
      cursor,
      this.pageSize
    ).valueChanges.subscribe({
      next: ((res: any) => {
        this.paginationProperties = new PaginationInfo(
          res.data?.gatewayInstallation?.count,
          res.data?.gatewayInstallation?.total,
          res.data?.gatewayInstallation?.pageInfo?.hasNextPage,
          res.data?.gatewayInstallation?.pageInfo?.hasPreviousPage,
          res.data?.gatewayInstallation?.pageInfo?.startCursor,
          res.data?.gatewayInstallation?.pageInfo?.endCursor
        )
        /** Limpa a tabela para os dados não se repetirem **/
        this.ELEMENT_DATA = [];
        this.length = this.paginationProperties.total
        res.data.gatewayInstallation.edges.forEach((node: any) => {
          /** Preenche a tabela com os dados dos gateways **/
          this.ELEMENT_DATA.push(
            new Gateway(
              node.node?.id,
              node.node?.reference,
              node.node?.isActive,
              new InstallationSite(
                node?.node?.site?.id,
                node?.node?.site?.reference,
                node?.node?.site?.number,
                node?.node?.site?.district,
                node?.node?.site?.state,
                node?.node?.site?.city,
                node.node?.site?.country,
                node.node?.site?.geoposition?.latitude,
                node.node?.site?.geoposition?.longitude,
                node.node?.site?.street
              ),
              new Device(
                node.node?.device?.id,
                node.node?.device?.serialNumber,
                node.node?.device?.equipmentType?.reference ?? '',
                node.node?.device?.equipmentType?.major ?? '',
                node.node?.device?.equipmentType?.minor ?? '',
                node.node?.device?.equipmentType?.revision ?? ''
              )
            )
          )
        });

        /** Atualiza a tabela **/
        this.setDataSourceAttributes();
        /** Download é iterrompido **/
        this.isReady = false;

        /** Assim que a requisição de filtro é realizada o loading é desativado**/
        this.filterLoading = false;

        this.equipmentListFilter = [];

        /** Caso não existam dados para preencher a tabela **/
        if (this.ELEMENT_DATA.length === 0) {
          /** Exibe alerta ao usuário **/
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
          /** Desativa o loading **/
          this.filterLoading = false;
        }
      }),
      /** Caso ocorra algum erro **/
      error: (error: any) => {
        /** Exibe no console o erro **/
        console.log("filterGatewaysError", error)
        /** Exobe alerta de erro ao usuário **/
        this.errorLibService.errorAlert(error);
        /** Desativa o loading **/
        this.filterLoading = false;
      }
    });
  }

  /** Método para realizar o filtro dos equipamentos (utilizado apenas no formulário de filtro) **/
  public searchEquipmentsFilter(serialNumber: string | null): any {
    /** Limpa a lista de equipamentos sempre que o usuário clicar para buscar **/
    this.equipmentListFilter = [];
    /** Ativa o loading na tela **/
    this.equipmentLoadingFilter = true;
    /** Realiza a requisição que filtra os equipamentos **/
    this.subscriptions = this.gatewaysService
      .getEquipments(serialNumber).valueChanges
      .subscribe({
        next: ((response: any) => {
          response.data.equipment.edges.forEach((node: any) => {
            /** Preenche a lista de equipamentos com os dados existentes **/
            this.equipmentListFilter.push(new Device(
              node.node?.id,
              node.node?.serialNumber,
              node.node?.equipmentType?.reference,
              node.node?.equipmentType?.major,
              node.node?.equipmentType?.minor,
              node.node?.equipmentType?.revision,
            ));
          })
          /** Caso a lista esteja vazia **/
          if (this.equipmentListFilter.length === 0) {
            /** Exibe alerta ao usuário **/
            Swal.fire({
              title: 'Sua busca não retornou resultados',
              text: 'Nenhum resultado para este filtro',
              icon: 'warning',
              confirmButtonText: 'Ok'
            });
          }
          /** Após a requisição ser realizada, desativa o loading **/
          this.equipmentLoadingFilter = false;
        }),
        /** Caso ocorra algum erro **/
        error: ((error: any) => {
          /** Exibe erro no console **/
          console.log("searchEquipmentsFilterError", error)
          /** Exibe alerta ao usuário **/
          this.errorLibService.errorAlert(error);
          /** Desativa o loading **/
          this.equipmentLoadingFilter = false;
        })
      });
  }

  /** Método utilizado no botão de limpar filtro que reseta as informações nos campos do formulário de filtro **/
  public clearFilterForm(): any {
    this.filterForm.reset();
    this.fullAddress.reset();
    this.filterReference = null;
    this.filterAddress = null;
    this.filterPostalCode = null;
    this.filterPublicPlace = null;
    this.filterNumber = null;
    this.filterComplement = null;
    this.filterDistrict = null;
    this.filterCity = null;
    this.filterState = null;
    this.filterCountry = null;
    this.filterLat = null;
    this.filterLong = null;
  }

  /** Método que realiza a criação de gateways **/
  createGateway() {
    /** Caso todos os campos do formulário de criação sejam preenchidos **/
    if (
      this.reference.valid &&
      this.equipment.valid &&
      this.equipmentSelected.valid &&
      this.installationSite.valid &&
      this.installationSiteSelected.valid
    ) {

      /** Ativa o loading **/
      this.createLoading = true;

      /** Realiza a requisição de criação de gateways 
       * passando as informações que foram preenchidas pelo usuário no formulário referente ao gateway **/
      this.subscriptions = this.gatewaysService.createGateway(
        this.reference.value,
        this.equipmentSelected.value,
        this.installationSiteSelected.value
      ).subscribe({
        next: (() => {
          /** Altera o valor do campo de referência do filtro com o valor de referência cadastrado **/
          this.filterForm.patchValue({
            reference: this.reference.value
          });

          /** Realiza o filtro **/
          this.filterGateways(
            null
          );

          /** Limpa o campo de referência do filtro **/
          this.filterForm.patchValue({
            reference: null
          });

          /** Após a requisição ser realizada, exibe mensagem de sucesso para o usuário **/
          Swal.fire({
            title: 'Gateway criado',
            text: 'Gateway criado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          /** Desativa o loading na tela **/
          this.createLoading = false;

          /** Limpa todos os campos do formulário de criação de gateways **/
          this.reference.reset();
          this.equipment.reset();
          this.installationSite.reset();
          this.equipmentSelected.reset();
          this.installationSiteSelected.reset();

          /** Limpa as listas de equipamentos e de locais de instalações **/
          this.equipmentListCreate = [];
          this.installationSiteListCreate = [];

          /** Adiciona o validator novamente nos campos de equipamentos e local de instalação **/
          this.equipment?.setValidators(Validators.requiredTrue)
          this.equipment?.updateValueAndValidity();
          this.installationSite?.setValidators(Validators.requiredTrue);
          this.installationSite?.updateValueAndValidity();
        }),

        /** Caso ocorra algum erro **/
        error: (error: any) => {
          /** Exibe o erro no console **/
          console.log("createGatewayError", error);
          /** Exibe mensagem de erro para o usuário **/
          this.errorLibService.errorAlert(error);
          /** Desativa o loading na tela **/
          this.createLoading = false;
        }
      })
    }

    /** Caso algum campo não tenha sido preenchido pelo usuário **/
    else {
      /** Exibe alerta informando ao usuário **/
      Swal.fire({
        title: 'Formulário Incompleto',
        text: 'Preencha todos os campos',
        icon: 'warning',
        confirmButtonText: 'Ok'
      })
    }
  }

  /** Campo de equipamento fica visivel após o campo de referência ser preenchido (utilizado no formulário de criação) **/
  validateStep1Create(): boolean {
    return this.reference.valid
  }

  /** Campo de local de instalação fica visivel após usuário selecionar um equipamento (utilizado no formulário de criação) **/
  validateStep2Create(): boolean {
    return this.equipmentSelected.valid
  }

  /** Método utilizado para realizar o filtro dos equipamentos (utilizado apenas no formulário de criação) **/
  public searchEquipmentsCreate(serialNumber: string | null): any {
    /** Limpa a lista sempre que o usuário fizer uma nova busca de equipamentos **/
    this.equipmentListCreate = [];
    /** Após o usuário clicar para buscar o equipamento o validator é desativado **/
    this.equipment?.clearValidators();
    /** Atualiza o valor do validator **/
    this.equipment?.updateValueAndValidity();
    /** Ativa o loading **/
    this.equipmentLoadingCreate = true;
    /** Realiza a requisição que filtra os equipamentos **/
    this.subscriptions = this.gatewaysService
      .getEquipments(serialNumber).valueChanges
      .subscribe({
        next: ((response: any) => {
          response.data.equipment.edges.forEach((node: any) => {
            /** Preenche a lista de equipamentos com os dados existentes referente ao equipamento **/
            this.equipmentListCreate.push(new Device(
              node.node?.id,
              node.node?.serialNumber,
              node.node?.equipmentType?.reference,
              node.node?.equipmentType?.major,
              node.node?.equipmentType?.minor,
              node.node?.equipmentType?.revision,
            ));
          })
          /** Caso a lista esteja vazia **/
          if (this.equipmentListCreate.length === 0) {
            /** Exibe alerta ao usuário **/
            Swal.fire({
              title: 'Sua busca não retornou resultados',
              text: 'Nenhum resultado para este filtro',
              icon: 'warning',
              confirmButtonText: 'Ok'
            });
          }
          /** Após a requisição ser realizada, desativa o loading na tela **/
          this.equipmentLoadingCreate = false
        }),
        /** Caso ocorra algum erro **/
        error: ((error: any) => {
          /** Exibe o erro no console **/
          console.log("searchEquipmentsCreateError", error)
          /** Exibe alerta ao usuário **/
          this.errorLibService.errorAlert(error);
          /** Desativa o loading **/
          this.equipmentLoadingCreate = false;
        })
      });
  }

  /** Método que realiza o filtro de locais de instalações (utilizado apenas no formulário de criação) **/
  public searchInstallationSiteCreate(reference: string | null): any {
    /** Limpa a lista de locais de instalações sempre que o usuário realizar uma nova busca**/
    this.installationSiteListCreate = [];

    /** Desativa o validator após o usuário clicar para buscar o local de instalação **/
    this.installationSite?.clearValidators();
    /** Atualiza o valor do validator **/
    this.installationSite?.updateValueAndValidity();
    /** Ativa o loading na tela **/
    this.installationSiteLoading = true;

    /** Realiza a requisição que busca os locais de instlações **/
    this.subscriptions = this.gatewaysService.getInstallationsSite(reference).valueChanges.subscribe({
      next: ((response: any) => {
        response.data.installationSite.edges.forEach((node: any) => {
          /** Preenche cada local de instalação com os dados existentes **/
          this.installationSiteListCreate.push(new InstallationSite(
            node.node?.id,
            node.node?.reference,
            node.node?.number,
            node.node?.district,
            node.node?.state,
            node.node?.city,
            node.node?.country,
            node.node?.geoposition?.latitude,
            node.node?.geoposition?.longitude,
            node.node?.street
          ));
        })
        /** Caso a lista esteja vazia **/
        if (this.installationSiteListCreate.length === 0) {
          /** Exibe alerta ao usuário **/
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        }
        /** Após a requisição ser realizada, o loading é desativado  **/
        this.installationSiteLoading = false;
      }),
      /** Caso ocorra algum erro **/
      error: ((error: any) => {
        /** Exibe o erro no console **/
        console.log("searchInstallationSiteCreateError", error)
        /** Exibe alerta ao usuário **/
        this.errorLibService.errorAlert(error);
        /** Desativa o loading **/
        this.installationSiteLoading = false;
      })
    });
  }

  /** Método para realizar o filtro dos equipamentos (utilizado apenas no formulário de update) **/
  public searchEquipmentsUpdate(serialNumber: string | null): any {
    /** Limpa a lista de equipamentos sempre que o usuário realizar uma nova busca **/
    this.equipmentListUpdate = [];
    /** Remove o validator após o usuário clicar para buscar os equipamentos **/
    this.updateForm.get('equipment')?.clearValidators();
    /** Atualiza o valor do validator **/
    this.updateForm.get('equipment')?.updateValueAndValidity();
    /** Ativa o loading **/
    this.equipmentLoadingUpdate = true;
    /** Realiza a requisição que filtra os equipamentos **/
    this.subscriptions = this.gatewaysService
      .getEquipments(serialNumber).valueChanges
      .subscribe({
        next: ((response: any) => {
          response.data.equipment.edges.forEach((node: any) => {
            /** Preenche cada equipamento com os dados existentes **/
            this.equipmentListUpdate.push(new Device(
              node.node?.id,
              node.node?.serialNumber,
              node.node?.equipmentType?.reference,
              node.node?.equipmentType?.major,
              node.node?.equipmentType?.minor,
              node.node?.equipmentType?.revision,
            ));
          })
          /** Caso a lista de equipamentos esteja vazia **/
          if (this.equipmentListUpdate.length === 0) {
            Swal.fire({
              title: 'Sua busca não retornou resultados',
              text: 'Nenhum resultado para este filtro',
              icon: 'warning',
              confirmButtonText: 'Ok'
            });
          }
          /** Após a requisição ser realizada o loading é desativado **/
          this.equipmentLoadingUpdate = false;
        }),
        /** Caso ocorra algum erro **/
        error: ((error: any) => {
          /** Exibe o erro no console **/
          console.log("searchEquipmentsUpdateError", error);
          /** Exibe alerta ao usuário **/
          this.errorLibService.errorAlert(error);
          /** Desativa o loading **/
          this.equipmentLoadingUpdate = false;
        })
      });
  }

  /** Método que realiza o filtro de locais de instalações (utilizado na atualização de gateways) **/
  public searchInstallationSiteUpdate(reference: string | null): any {
    /** Limpa a lista de locais de instalações sempre que o usuário realizar uma nova busca **/
    this.installationSiteListUpdate = [];

    /** Remove o validator após o usuário clicar para buscar os locais de instalações **/
    this.updateForm.get('installationSite')?.clearValidators();
    /** Atualiza o valor do validator **/
    this.updateForm.get('installationSite')?.updateValueAndValidity();
    /** Ativa o loading na tela **/
    this.installationSiteLoadingUpdate = true;

    /** Realiza a requisição que filtra os locais de instalações **/
    this.subscriptions = this.gatewaysService.getInstallationsSite(reference).valueChanges.subscribe({
      next: ((response: any) => {
        response.data.installationSite.edges.forEach((node: any) => {
          /** Preenche cada local de instalação com os dados existentes **/
          this.installationSiteListUpdate.push(new InstallationSite(
            node.node?.id,
            node.node?.reference,
            node.node?.number,
            node.node?.district,
            node.node?.state,
            node.node?.city,
            node.node?.country,
            node.node?.geoposition?.latitude,
            node.node?.geoposition?.longitude,
            node.node?.street
          ));
        });
        /** Após a requisição ser realizada o loading é desativado **/
        this.installationSiteLoadingUpdate = false;

        /** Caso a lista de locais de instalações esteja vazia **/
        if (this.installationSiteListUpdate.length === 0) {
          /** Exibe alerta ao usuário **/
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        }
      }),
      /** Caso ocorra algum erro **/
      error: ((error: any) => {
        /** Exibe o erro no console **/
        console.log("searchInstallationSiteUpdateError", error);
        /** Exibe o erro ao usuário **/
        this.errorLibService.errorAlert(error);
        /** Desativa o loading **/
        this.installationSiteLoadingUpdate = false;
      })
    });
  }

  /** Método utilizado para abrir o sidebar de edição/visualização e preencher os campos do formulário de edição **/
  openSidebarUpdate(element: Gateway, isViewing: boolean) {
    /** Parâmetro para validar quando o sidebar deverá ter os campos habilitados ou não **/
    this.viewController = isViewing;
    /** Caso o usuário selecione o botão de visualizar **/
    if (isViewing) {
      /** Os campos do formulário são desabilitados para edição **/
      this.updateForm.disable()
    }
    /** Caso o usuário clique na opção de edição **/
    else {
      /** O formulário é habilitado para edição normalmente **/
      this.updateForm.enable();
    }
    /** Atualiza todos os campos do formulário com seus respectivos valores **/
    this.updateForm.get('id')?.setValue(element?.id),
      this.updateForm.get('installationSite')?.setValue(element?.site?.reference),
      this.updateForm.get('installationSiteSelected')?.setValue(element.site.id),
      this.updateForm.get('reference')?.setValue(element?.reference),
      this.updateForm.get('equipment')?.setValue(element?.device?.serialNumber),
      this.updateForm.get('equipmentSelected')?.setValue(element?.device?.id),
      this.updateForm.get('isActive')?.setValue(element?.isActive),

      /** Abre o sidebar **/
      this.editorController = true;
  }

  /** Método utilizado para fechar o sidebar de edição /visualização **/
  public closeSidebarUpdate() {

    /** Fecha o sidebar **/
    this.editorController = false;
    this.viewController = false;
    /** Limpa as listas de equipamentos e locais de instalações **/
    this.equipmentListUpdate = [];
    this.installationSiteListUpdate = [];
    /** Adiciona o validators nos campos de equipamentos e local de instalação novamente **/
    this.updateForm.get('equipment')?.setValidators(Validators.requiredTrue);
    this.updateForm.get('equipment')?.updateValueAndValidity();
    this.updateForm.get('installationSite')?.setValidators(Validators.requiredTrue);
    this.updateForm.get('installationSite')?.updateValueAndValidity();
  }

  /** Método que realiza o update dos gateways **/
  updateGateway() {
    /** Caso todos os campos obrigatórios tenham sido preenchidos **/
    if (this.updateForm.valid) {
      /** Ativa o loading **/
      this.updateLoading = true;
      /** Realiza a requisição que faz o update dos gateways **/
      this.subscriptions = this.gatewaysService.updateGateway(
        this.updateForm.get('id')?.value,
        this.updateForm.get('reference')?.value,
        this.updateForm.get('equipmentSelected')?.value,
        this.updateForm.get('installationSiteSelected')?.value,
        this.updateForm.get('isActive')?.value
      ).subscribe({
        /** Caso a requisição tenha sido realizada com sucesso **/
        next: ((res: any) => {
          /** Desativa o loading **/
          this.updateLoading = false;
          /** Exibe informação de sucesso ao usuário **/
          Swal.fire({
            title: 'Gateway atualizado',
            text: 'Gateway atualizado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });
          /** Atualiza os elementos da tabela com apenas o gateway que foi atualizado **/
          this.ELEMENT_DATA = [
            new Gateway(
              res.data.gatewayInstallationUpdate.gatewayInstallation?.id,
              res.data.gatewayInstallationUpdate.gatewayInstallation?.reference,
              res.data.gatewayInstallationUpdate.gatewayInstallation?.isActive,
              new InstallationSite(
                res.data.gatewayInstallationUpdate.gatewayInstallation?.site.id,
                res.data.gatewayInstallationUpdate.gatewayInstallation?.site.reference,
                res.data.gatewayInstallationUpdate.gatewayInstallation?.site.number,
                res.data.gatewayInstallationUpdate.gatewayInstallation?.site.district,
                res.data.gatewayInstallationUpdate.gatewayInstallation?.site.state,
                res.data.gatewayInstallationUpdate.gatewayInstallation?.site.city,
                res.data.gatewayInstallationUpdate.gatewayInstallation?.site.country,
                res.data.gatewayInstallationUpdate.gatewayInstallation?.site.lat,
                res.data.gatewayInstallationUpdate.gatewayInstallation?.site.long,
                res.data.gatewayInstallationUpdate.gatewayInstallation?.site.street
              ),
              new Device(
                res.data.gatewayInstallationUpdate.gatewayInstallation?.device.id,
                res.data.gatewayInstallationUpdate.gatewayInstallation?.device.serialNumber,
                res.data.gatewayInstallationUpdate.gatewayInstallation?.device.equipmentType.reference,
                res.data.gatewayInstallationUpdate.gatewayInstallation?.device.equipmentType.major,
                res.data.gatewayInstallationUpdate.gatewayInstallation?.device.equipmentType.minor,
                res.data.gatewayInstallationUpdate.gatewayInstallation?.device.equipmentType.revision,
              )
            )
          ];
          /** Habilita o validator nos campos de equipamento e local de instalação novamente **/
          this.updateForm.get('equipment')?.setValidators(Validators.requiredTrue);
          /** Atualiza o valor do validator **/
          this.updateForm.get('equipment')?.updateValueAndValidity();
          this.updateForm.get('installationSite')?.setValidators(Validators.requiredTrue);
          /** Atualiza o valor do validator **/
          this.updateForm.get('installationSite')?.updateValueAndValidity();
          /** Fecha o sidebar de edição **/
          this.editorController = false;
          /** Atualiza a tabela com as informações editadas **/
          this.setDataSourceAttributes();
        }),
        /** Caso ocorra algum erro **/
        error: (error: any) => {
          /** Exibe no console o error **/
          console.log("updateInstallationError", error)
          /** Exibe um alerta ao usuário **/
          this.errorLibService.errorAlert(error);
          /** Desativa o loading **/
          this.updateLoading = false;
        }
      })
    }
    /** Caso algum campo não tenha sido preenchido **/
    else {
      /** Exibe alerta ao usuário **/
      Swal.fire({
        text: 'Confirme todos os dados antes de atualizar!',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }
  }

  /** Método que valida o status do gateway inativado ou ativado **/
  public updateStatusGateway(status: boolean) {
    /** Variáveis criadas para definir o texto a ser exibido quando o gateway for ativado/inativado **/
    let title: string;
    let text: string;

    /** Caso o status esteja como inativado **/
    if (status) {
      title = 'Tem certeza que deseja ativar o gateway?'
      text = 'Este gateway será ativado'
    }

    /** Caso o status esteja como ativado **/
    else {
      title = 'Tem certeza que deseja inativar o gateway?'
      text = 'Este gateway será inativado'
    }

    /** Exibe alerta para confirmação **/
    Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#198754',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar'
      /** Pega o resultado **/
    }).then((result) => {
      /** Caso seja confirmado **/
      if (result.isConfirmed) {
        /** Chama a requisição para inativar/ativar o gateway **/
        this.subscriptions = this.gatewaysService.updateStatusGateway(
          this.updateForm.get('id')?.value,
          this.updateForm.get('reference')?.value,
          this.updateForm.get('equipmentSelected')?.value,
          this.updateForm.get('installationSiteSelected')?.value,
          status
        ).subscribe({
          next: (() => {
            /** Fecha o sidebar de edição **/
            this.editorController = false;
            /** Realiza o filtro dos gateways **/
            this.filterGateways(
              null
            )
          }),
          /** Caso ocorra algum erro **/
          error: (error: any) => {
            /** Exibe no console o error **/
            console.log("updateStatusInstallationError", error)
            /** Exibe um alerta ao usuário **/
            this.errorLibService.errorAlert(error);
          }
        })

        /** Exibe mensagem de sucesso ao usuário **/
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Operação realizada com sucesso'
        })
      }

      /** Caso não for possível ativar/inativar o gateway **/
      else if (result.isDenied) {
        /** Exibe informação ao usuário **/
        Swal.fire({
          title: 'Operação não realizada',
          text: 'Tente novamente',
          icon: 'warning',
          confirmButtonText: 'Ok'
        });
      }

      else {
        Swal.fire({
          text: 'Confirme todos os dados antes de atualizar!',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    })
  }

  /** Método criado para tratar os dados do status do gateway para os relatórios CSV e PDF **/
  public translateStatus(status: any): string {
    switch (status) {
      /** Caso o status seja ativado **/
      case true:
        /** Retorna o status do gateway como ativo */
        return "Ativo";

      /** Caso o status seja inativo **/
      case false:
        /** Retorna o status do gateway como inativo **/
        return "Inativo";
    }
    /** Retorna o status para utilizar nos arquivos que geram os arquivos de PDF e CSV **/
    return status;
  }

  /** Método que realiza a conversão para arquivo CSV **/
  convertCSV(arr: any) {
    /** Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas **/
    const content = [Object.keys(arr[0])].concat(arr);

    /** Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um **/
    return content.map((item) => {
      return Object.values(item).toString().replace(',', ';');
    })
      .join('\n')
  }

  /** Método que realiza o download CSV dos gateways **/
  downloadCSV() {

    let content: any = [];
    /** Adiciona cada elemento como um objeto na lista de conteúdo **/
    this.FILE_DATA.forEach((element) => {
      content.push({
        'Referência': element.reference,
        'Local de instalação ': `${element?.site?.street}${element?.site?.street === '' ? '' : '.'} ${element?.site?.district}${element?.site?.district === '' ? '' : '.'} ${element?.site?.city} ${element?.site.city === '' ? '' : '-'} ${element?.site?.number} ${element?.site.lat} ${element?.site?.long} `,
        'Tipo de Equipamento': `${element?.device?.reference} ${element?.device?.major}${element?.device?.major === '' ? '' : '.'}${element?.device?.minor}${element?.device?.minor === '' ? '' : '.'}${element?.device?.revision}`,
        'Numero de Série': element?.device?.serialNumber,
        'Status': this.translateStatus(element?.isActive)
      });
    });

    /** Adiciona o conteúdo convertido dentro de uma nova variável. **/
    let data = this.convertCSV(content);
    /**  Transforma o conteúdo para o formato blob para geração do arquivo. **/
    let file = new Blob([data], { type: 'text/csv' });
    /** Cria um endereçamento para o arquivo. **/
    let url = window.URL.createObjectURL(file);
    /** Cria um elemento para a linkagem do arquivo. **/
    let link = document.createElement('a');
    /** Adiciona a url no elemento de linkagem. **/
    link.href = url;
    /**  Nomeia o arquivo que será feito o download. **/
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-Gateways.csv`;
    /** Adiciona o link no documento. **/
    document.body.appendChild(link);
    /** Atica o evento de click para realizar o download. **/
    link.click();
    /** Remove o link do documento. **/
    document.body.removeChild(link);
    /**  Remove o endereço do download. **/
    window.URL.revokeObjectURL(url);

    /** Limpa a lista que contém as informações já baixadas pelo usuário
    * Para que o usuário não baixe o mesmo arquivo sempre
    * **/
    this.FILE_DATA = [];
    this.isReady = false;
  }

  /** Método que realiza o download dos gateways em PDF **/
  downloadPDF() {
    /** Colunas que serão adicionadas ao header da tabela no PDF **/
    const tableLayout = [
      "Referência",
      "Local de instalação",
      "Tipo de Equipamento",
      "Numero de Série",
      "Status"
    ];

    let content: any = []
    /** Adiciona os elementos na lista de conteúdo como um array **/
    this.FILE_DATA.forEach((element) => {
      content.push([
        element?.reference,
        `${element?.site?.street}${element.site.street === '' ? '' : ','} ${element?.site?.district} ${element.site.district === '' ? '' : '-'} ${element?.site?.city}${element.site.city === '' ? '' : ','} ${element?.site?.number} `,
        `${element?.device?.reference}${element?.device?.major}${element?.device?.minor}${element?.device?.revision}`,
        element?.device?.serialNumber,
        this.translateStatus(element?.isActive)
      ]);
    });

    /** Define a orientação da tabela (paisagem nesse caso) **/
    const orientation = 'l';
    /** Cria o objeto da lib jsPDF com a orientação e tamanho da página **/
    const doc: any = new jsPDF(orientation, "mm", "a4");

    /** Cria a tabela com o tableLayout como header e a a lista de conteúdo como corpo da tabela **/
    doc.autoTable({
      theme: "grid",
      margin: { top: 20 },
      head: [tableLayout],
      body: content
    });

    /** Baixa o arquivo para o usuário, definindo o nome com a data de download **/
    doc.save(`${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-Gateways.pdf`);
    /** Limpa a lista que contém as informações já baixadas pelo usuário
     * Para que o usuário não baixe o mesmo arquivo sempre
     * **/
    this.FILE_DATA = [];
    this.isReady = false;
  }

  /** Método que realiza a paginação de geração dos arquivos CSV e PDF **/
  public generateFileData(cursor: string | null, fileType: string) {
    /** Ativa o loading **/
    this.fileLoading = true;
    /** Se o arquivo não estiver pronto pra download **/
    if (this.isReady == false) {
      /** Realiza o filtro dos gateways **/
      this.subscriptions = this.gatewaysService.filterGateways(
        this.filterForm?.value?.reference,
        this.filterForm?.value?.equipmentSelected,
        this.filterCountry,
        this.filterState,
        this.filterCity,
        this.filterDistrict,
        this.filterPublicPlace,
        cursor,
        100
      ).valueChanges.subscribe({
        next: ((response: any) => {
          response.data.gatewayInstallation.edges.forEach((node: any) => {
            /** Preenche com as propriedades existentes de gateway**/
            this.FILE_DATA.push(
              new Gateway(
                node.node?.id,
                node.node?.reference,
                node.node?.isActive,
                new InstallationSite(
                  node?.node?.site?.id,
                  node?.node?.site?.reference,
                  node?.node?.site?.number,
                  node?.node?.site?.district,
                  node?.node?.site?.state,
                  node?.node?.site?.city,
                  node.node?.site?.country,
                  node.node?.site?.geoposition?.latitude,
                  node.node?.site?.geoposition?.longitude,
                  node.node?.site?.street
                ),
                new Device(
                  node.node?.device?.id,
                  node.node?.device?.serialNumber,
                  node.node?.device?.equipmentType?.reference,
                  node.node?.device?.equipmentType?.major,
                  node.node?.device?.equipmentType?.minor,
                  node.node?.device?.equipmentType?.revision,
                )
              ));
          });

          /** Caso não tenha a próxima página (paginação finalizada) **/
          if (!response.data?.gatewayInstallation?.pageInfo?.hasNextPage) {
            /** Download pronto para o download **/
            this.isReady = true;
          }
          /** Realiza o filtro até não existir uma próxima página **/
          this.generateFileData(response.data?.gatewayInstallation?.pageInfo?.endCursor, fileType);
        }),
        /** Caso ocorra algum erro **/
        error: ((error: any) => {
          /** Exibe o erro no console **/
          console.log("generateFileDataError", error)
          /** Exibe o erro ao usuário **/
          this.errorLibService.errorAlert(error);
          /** Desativa o loading **/
          this.fileLoading = false;
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }
}
