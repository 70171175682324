import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MetersService } from 'src/shared/services/meters.service';
import Swal from 'sweetalert2';
import { Installations, Meters, MetersTable, MetersType } from './meters-assets/meters.model';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-meters',
  templateUrl: './meters.component.html',
  styleUrls: ['./meters.component.less']
})
export class MetersComponent extends Unsubscriber {

  /** Inputs utilizados para configurar o table header */
  @Input() metersTable: MetersTable[] = [];
  @Input() hasCSV: boolean = true;
  @Input() hasPDF: boolean = true;
  @Input() metersLoading$ = this.metersService.metersLoading$;
  @Input() downloadCSV: any;
  @Input() downloadPDF: any;

  constructor(private formBuilder: FormBuilder, private metersService: MetersService) {
    super();
  }

  /** Utilizado para exportar o relatório de medidores em CSV **/
  exportCSV() {
    this.downloadCSV();
  }

  /** Utilizado para exportar o relatório de medidores em PDF **/
  exportPDF() {
    this.downloadPDF();
  }

  ngOnInit() {

    /** Verifica se a empresa foi selecioanada pelo usuário **/
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;

    /** Filtra os medidores (filtro inicial) **/
    this.metersService.getMetersTable(this.company);

    /** Recebe os valores da requisição e atualiza na tabela **/
    this.subscriptions = this.metersService.metersGetter().subscribe((meters) => {
      this.ELEMENT_DATA = meters;
      this.setDataSourceAttributes();
    })

    /** Recebe alterações dos tipos de medidores **/
    this.subscriptions = this.metersService.metersTypeGetter().subscribe((type: MetersType[]) => {
      this.metersTypeList = type;
    })

    /** Recebe alterações de instalações **/
    this.subscriptions = this.metersService.installationsGetter().subscribe((installation: Installations[]) => {
      this.installationList = installation;
    })

    /** Recebe alterações de medidores na tabela (filtro inicial) **/
    this.subscriptions = this.metersService.getMetersTableGetter().subscribe((metersTableList: MetersTable[]) => {
      this.metersTable = metersTableList
    })
  }

  /** Armazena ID da empresa selecionada **/
  company: any = null;

  /** Variável que armazena os tipos de medidores **/
  metersTypeList: MetersType[] = [];

  /** Armazena as instalações **/
  installationList: Installations[] = [];

  /** Recebe os medidores da tabela **/

  /** Variáveis de loading **/
  filterMetersLoading: boolean = false;

  registerMetersLoading: boolean = false;

  metersLoadingTable = this.metersService.metersLoading$;

  metersTypeLoading$ = this.metersService.meterTypeLoading$;

  installationLoading$ = this.metersService.installationLoading$

  /** Variável utilizada para manipular as instalações selecionadas **/
  installationsSelected: any = null;

  /** Variável utilizada para manipular os tipos de medições selecionadas **/
  metersTypeSelected: any = null;

  /** Variável que armazena as colunas da tabela **/
  displayedColumns: string[] = ['serialNumber', 'typeMeters', 'installations'];

  /** Armazena elementos da tabela **/
  ELEMENT_DATA: Meters[] = [];

  /** Renderiza os comandos da lista ELEMENT_DATA para a tabela **/
  dataSource = new MatTableDataSource<Meters>(this.ELEMENT_DATA);

  /** Variáveis usadas para o paginator e para o sort **/
  private paginator: MatPaginator;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  /** Função que configura o paginator **/
  setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
    this.dataSource.paginator = this.paginator;
  }

  /** Variável que guarda o número de equipamentos retornados do backend **/
  dataLength = 0;

  /** Variáveis utilizadas para manipular os boxs de registro e de criação de medidores **/
  registerMetersBox: boolean = false;
  filterMetersBox: boolean = false;

  /** Função que manipula o box de registro de medidores **/
  registerMetersOpen() {
    this.registerMetersBox = !this.registerMetersBox
    this.filterMetersBox = false;
  }

  /** Função que manipula o box de filtro de medidores **/
  filterMetersOpen() {
    this.filterMetersBox = !this.filterMetersBox
    this.registerMetersBox = false;
  }

  filterMetersForm: FormGroup = this.formBuilder.group({
    keyword: [null],
    metersType: [null]
  })

  /** FormGroup dos campos de registro de medidores **/
  createMetersForm: FormGroup = this.formBuilder.group({
    serialNumber: [null, Validators.required],
    type: [null, Validators.required],
    installations: [null, Validators.required]
  })

  /** Função que chama a requisição de filtrar medidores **/
  filterMeters(search: any, type: any): void {
    this.metersService.metersRefresh(this.company, search, [type]);

    this.subscriptions = this.metersService.metersGetter().subscribe((meters) => {

      if (meters.length === 0) {
        // Retorna um aviso de pesquisa vazia para o usuário
        Swal.fire({
          title: 'Sua pesquisa não retornou resultados',
          icon: 'warning',
          confirmButtonText: 'Ok'
        });
      }
      else {
        /** Exibe mensagem de sucesso ao usuário **/
        Swal.fire({
          title: 'Pesquisa realizada com sucesso',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      }
    });
  }
  /** Função que chama a requisição de criar medidores **/
  createMeters(): void {
    if (this.createMetersForm.valid) {
      this.metersService.createMeters(
        this.company,
        this.createMetersForm.value?.serialNumber,
        [this.installationsSelected],
        [this.metersTypeSelected]
      )
      /** Limpa os campos após criar um medidor **/
      this.createMetersForm.reset()
    }
    else {
      /** Emite alerta ao usuário **/
      Swal.fire({
        title: 'Formulário Incompleto',
        text: 'Preencha todos os campos',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
  }

  /** Função que armazena as instalações que foram selecionadas pelo usuário **/
  installationGetId(installationId: any) {
    this.installationsSelected = installationId;
  }

  /** Função que armazena os tipos de medidores que foram selecionados pelo usuário **/
  metersTypeGetId(metersId: any) {
    this.metersTypeSelected = metersId;
  }
  /** Função que reseta os campos de filtro **/
  resetFilter() {
    this.filterMetersForm.reset();
  }
}