// Model dos tipos de equipamentos
export class EquipmentType {
    constructor(
        public id: string,
        public reference: string | null,
        public major: number | null,
        public minor: number | null,
        public revision: number | null,
    ) { }
}
// Model dos equipamentos
export class Equipment {
    constructor(
        public id: string | null,
        public serialNumber: string | null,
        public macAddress: string | null,
        public equipmentTypeId: string | null,
        public equipmentType: any
    ) { }
}

export class PaginationInfo {
    constructor(
        public count: number,
        public total: number,
        public hasNextPage: boolean,
        public hasPreviousPage: boolean,
        public startCursor: string | null,
        public endCursor: string | null
    ) { }
}