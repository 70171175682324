<div>
    <h1 mat-dialog-title>{{ "command-modal.installations" | translate }}</h1>
    <hr>
    <div mat-dialog-content *ngIf="data.installationList.length">
        <div class="chip" *ngFor="let installation of data.installationList; let i = index">
            {{ installation.reference ? installation.reference : installation.installationReference }}
            <span class="closebtn" (click)="removeInstallation(i)">&times;</span>
        </div>
    </div>
    <div mat-dialog-content *ngIf="!data.installationList.length">
        <p>{{ "command-modal.no-installations" | translate }}</p>
    </div>
    <div mat-dialog-actions class="actions">
        <button mat-button [mat-dialog-close]="data.installationList" cdkFocusInitial class="btn btn-success">{{ "command-modal.close" | translate }}</button>
        <button mat-button (click)="clearList()" cdkFocusInitial class="btn btn-success">{{ "command-modal.clean-list" | translate }}</button>
      </div>
</div>
