import CryptoJS from 'crypto-js';

//funçao de encriptar com salt de 256 bits
export function encrypt(msg: string, token: string): any {
    try {
        var salt = CryptoJS.lib.WordArray.random(128 / 8);

        var key = CryptoJS.PBKDF2(token, salt, {
            keySize: 256 / 32,
            iterations: 100
        });

        var iv = CryptoJS.lib.WordArray.random(128 / 8);

        var encrypted = CryptoJS.AES.encrypt(msg, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
            hasher: CryptoJS.algo.SHA256
        });
        var transitmessage = salt.toString() + iv.toString() + encrypted.toString();
        return transitmessage;
    } catch (error) {
        console.log(error);
    }
}

//funçao de descriptar com 256 bits utlizado para pegar a senha do usuario
export function decrypt(transitmessage: string, token: string): any {
    try {
        var salt = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32));
        var iv = CryptoJS.enc.Hex.parse(transitmessage.substr(32, 32))
        var encrypted = transitmessage.substring(64);

        var key = CryptoJS.PBKDF2(token, salt, {
            keySize: 256 / 32,
            iterations: 100
        });

        var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
            hasher: CryptoJS.algo.SHA256
        })
        return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error('Erro :', error);
        return null;
    }
}