<div (document:mousemove)="onMouseMove($event)" 
    [style.backgroundPositionX]="clientX"
    [style.backgroundPositionY]="clientY" 
    id="container">
    <div class="content">
        <h2>404</h2>
        <h4>Ops! Página não encontrada</h4>
        <p>A página que você procura não existe.</p>
        <a href="#">Voltar para Home</a>
    </div>
</div>