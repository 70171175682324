import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableHeaderRoutingModule } from './table-header-routing.module';
import { TableHeaderComponent } from './table-header.component';


@NgModule({
  declarations: [
    TableHeaderComponent
  ],
  imports: [
    CommonModule,
    TableHeaderRoutingModule
  ],
  exports: [
    TableHeaderComponent
  ]
})
export class TableHeaderModule { }
