<div class="body">
  <div class="bckgd-img"></div>
  <div class="container">
    <div class="row col-md-12">
      <div class="col-md-12">
        <div class="card mb-3 shadow" [ngClass]="isEditing ? 'editingClass' : ''">
          <div class="card-body">
            <form class="row form-card">
              <div class="col-md-6 icon-div">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
                  class="bi bi-person-fill mt-3" viewBox="0 0 16 16">
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
              </div>

              <div class="user-profile" [formGroup]="userForm">

                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="inputNome" class="form-label"> {{"user-profile-edit.form-name" | translate}} </label>
                    <input type="text" class="form-control" id="inputNome" formControlName="firstName"
                      placeholder="Nome" />
                  </div>
                  <div class="col-md-6">
                    <label for="inputEmail" class="form-label"> {{"user-profile-edit.form-email" | translate}} </label>
                    <input type="text" class="form-control" id="inputEmail" formControlName="email"
                      placeholder="email@email.com" />
                  </div>
                  <div class="col-md-6">
                    <label for="inputContato" class="form-label"> {{"user-profile-edit.form-user" | translate}} </label>
                    <input type="text" class="form-control" formControlName="user" id="inputUsuario"
                      placeholder="nome.nome" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 div-btn">
        <button (click)="enterEditMode()" type="button" [disabled]="isEditing" class="btn btn-success btn me-0 shadow">
          {{"user-profile-edit.button-edit" |translate}}
        </button>
      </div>

      <app-useredit [user]="user" (newUserData)="getNewUserData($event)" *ngIf="isEditing"></app-useredit>

    </div>
  </div>
</div>