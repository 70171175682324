<h1 mat-dialog-title>{{ 'equipment-type-modal.title' | translate }}</h1>
<hr>
<div mat-dialog-content class="content">

  <div class="query" [formGroup]="queryForm">
    <div>
      <mat-radio-group formControlName="parameterQuery">
        <h2>{{ 'equipment-type-modal.parameter-label' | translate }}</h2>
        <mat-radio-button [disabled]="disableParametersInput" value="STATUS">{{ 'equipment-type-modal.status-label'
          |
          translate }}</mat-radio-button>
        <mat-radio-button [disabled]="disableParametersInput" value="DIAGNOSTIC">{{
          'equipment-type-modal.diagnostic-label' |
          translate
          }}</mat-radio-button>
        <mat-radio-button [disabled]="disableParametersInput" value="PARAMETERS">{{
          'equipment-type-modal.parameter-label' |
          translate
          }}</mat-radio-button>
        <mat-radio-button [disabled]="disableParametersInput" value="MEASUREMENT">{{
          'equipment-type-modal.measurement-label'
          | translate
          }}</mat-radio-button>
        <mat-radio-button [disabled]="disableParametersInput" value="LATEST_MEASUREMENT">{{
          'equipment-type-modal.last-measurement-label' | translate
          }}</mat-radio-button>
        <mat-radio-button [disabled]="disableParametersInput" value="VERSION">{{
          'equipment-type-modal.version-label' |
          translate }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="propertiesLoading">
      <h2>{{ 'equipment-type-modal.response-properties-label' | translate }}</h2>
      <mat-form-field appearance="outline" class="response-properties">
        <mat-label>{{ 'equipment-type-modal.response-properties-label' | translate }}</mat-label>
        <mat-select formControlName="propertiesQuery" multiple (selectionChange)="onOptionsPropertiesSelected($event)">
          <mat-select-trigger>
            {{queryForm.value.propertiesQuery?.[0] || ''}}
            <span *ngIf="(queryForm.value.propertiesQuery?.length || 0) > 1" class="label">
              (+{{(queryForm.value.propertiesQuery?.length || 0) - 1}} {{queryForm.value.propertiesQuery?.length ===
              2
              ?
              "{{ 'equipment-type-modal.other' | translate }}" : "{{ 'equipment-type-modal.others' | translate
              }}"}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let property of responsePropertiesList"
            [value]="property.sourceName">{{property.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="!propertiesLoading">
      <h2> Carregando as propriedades de resposta...</h2>
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button cdkFocusInitial (click)="closeAndSaveModalQueryCommands()" class="btn btn-success">{{
    'equipment-type-modal.save-btn'
    |
    translate }}</button>
  <button mat-button [mat-dialog-close]="null" cdkFocusInitial class="btn btn-success">{{
    'equipment-type-modal.close-btn' | translate }}</button>
</div>