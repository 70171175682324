import { createAction, props } from "@ngrx/store";

export const loginSuccess = createAction(
    '[Login Page API] Login Success',
    props<{ loggedUserId: string, token: string, error: string | null }>()
);

export const loginFailed = createAction(
    '[Login Page API] Login Failed',
    props<{ error: string }>()
);
