import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { FirmwareService } from 'src/shared/services/firmware.service';
import Swal from 'sweetalert2';
import { FirmwaresTable, Hardwares } from './Firmware-assets/firmwareTable.model';
import { PaginationInfo } from './Firmware-assets/firmware-model';
import jsPDF from 'jspdf';
import { HardwaresTable } from '../hardwares/hardwaresAssets/HardwareTable.model';
import { Subscription } from 'rxjs';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'app-firmwares',
  templateUrl: './firmwares.component.html',
  styleUrls: ['./firmwares.component.less']

})

export class FirmwaresComponent extends Unsubscriber implements OnInit {

  constructor(
    private firmwaresService: FirmwareService,
    private errorLibService: ErrorLibService
  ) {
    super();
  }

  ngOnInit(): void {
    this.refreshHardwares()
  }


  /** Variáveis responsaveis por abrir e fechar  as box das páginas register e filter **/
  public filterFirmwares: boolean = false;
  public registerFirmwares: boolean = false;

  /** Variáveis para habilitar o painel de criação de novo Hardware compatível **/
  public panelOpenFirmware: boolean = false;
  public panelOpenHardware: boolean = false;
  public panelOpenState: boolean = false;

  /** Variáveis usadas para armazenar o filtro feito pelo usuário **/
  public name: string = '';
  public major: number;
  public minor: number;
  public revision: number;

  /** Variávis responsáveis pelo loading **/
  public hardwaresLoading: boolean = false;
  public firmwaresLoading: boolean = false;
  public registerFirmwareLoading: boolean = false;
  public registerHardwareLoading: boolean = false;
  public fileLoading: boolean = false;

  /** Variável que recebe a lista de hardwares **/
  public hardwaresList: Hardwares[] = [];

  /** Variável que armazena os hardwares selecionados no checkbox **/
  public selectedHardwaresIdList: string[] = [];

  public ELEMENT_DATA: FirmwaresTable[] = [];

  /** Variável responsável por configurar os nomes das colunas da tabela **/
  public displayedColumns: string[] = ['name', 'version', 'releaseDate'];

  /** Variável responsável por armazenar a data atual que vai para a tabela **/
  public date = Date.now();

  /** Variável utilizada para controle de paginação **/
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null)

  /** Renderiza os firmwares da lista ELEMENT_DATA para a tabela **/
  public dataSource = new MatTableDataSource<FirmwaresTable>(this.ELEMENT_DATA);

  /** Variáveis utilizadas para manipulação do Paginator **/
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  /** Variáveis utilizada para controle da geração de arquivo **/
  public FILE_DATA: FirmwaresTable[] = [];
  public isReady: boolean = false;

  /** Métodos e variáveis responsáveis por configurar a paginação da tabela **/
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /** Função que controla os botões de anterior e pŕoximo no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.filterFirmware(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterFirmware(this.paginationProperties.endCursor)
    }
  }

  /** Função que configura o paginator **/
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  /** Função para abrir o box de registro de Firmwares **/
  public registerFirmwaresOpen() {
    /** Altera o estado da box de registro de Firmwares **/
    this.registerFirmwares = !this.registerFirmwares;
    /** Fecha box de filtro de Firmwares **/
    this.filterFirmwares = false;
  }

  /** Função para abrir o box de filtro de Firmwares **/
  public filterFirmwaresOpen() {
    /** Altera o estado da box de filtro de Firmwares **/
    this.filterFirmwares = !this.filterFirmwares;
    /** Fecha box de registro de Firmwares **/
    this.registerFirmwares = false;
  }

  /** FormGroup das variáveis de filtro de firmwares **/
  public filterFirmwareForm = new FormGroup({
    nameFilterForm: new FormControl(),
    majorFilterForm: new FormControl(),
    minorFilterForm: new FormControl(),
    revisionFilterForm: new FormControl()
  });

  /** FormGroup das variáveis para criação de Firmwares **/
  public createFirmwareForm = new FormGroup({
    nameCreateForm: new FormControl('', [Validators.required]),
    majorCreateForm: new FormControl(null, [Validators.required]),
    minorCreateForm: new FormControl(null, [Validators.required]),
    releaseDateCreateForm: new FormControl(null, [Validators.required, Validators.max(8), Validators.min(8)]),
    revisionCreateForm: new FormControl(null, [Validators.required]),
    descriptionCreateForm: new FormControl(null, [Validators.required]),
  });

  /** FormGroup das variáveis para criar novo Hardware compatível **/
  public createHardwareForm = new FormGroup({
    nameHardwareCreateForm: new FormControl('', [Validators.required]),
    minorHardwareCreateForm: new FormControl(null, [Validators.required]),
    majorHardwareCreateForm: new FormControl(null, [Validators.required]),
    releaseDateHardwareCreateForm: new FormControl(null, [Validators.required]),
    revisionHardwareCreateForm: new FormControl(null, [Validators.required]),
    descriptionHardwareCreateForm: new FormControl(null, [Validators.required]),
  });

  /** Função responsável por filtrar os Firmwares **/
  public filterFirmware(cursor: string | null) {
    /** Ativa o spinner de loading **/
    this.firmwaresLoading = true;

    if (cursor === null) {
      this.pageIndex = 0;
      this.isReady = true;
    }

    this.name = this.filterFirmwareForm.value.nameFilterForm;
    this.major = this.filterFirmwareForm.value.majorFilterForm;
    this.minor = this.filterFirmwareForm.value.minorFilterForm;
    this.revision = this.filterFirmwareForm.value.revisionFilterForm;

    this.subscriptions = this.firmwaresService.filterFirmwares(
      this.name,
      this.major,
      this.minor,
      this.revision,
      cursor,
      this.pageSize
    ).valueChanges.subscribe({
      next: ((response: any) => {
        /** Preenche a variável de paginação com a resposta da API **/
        this.paginationProperties = new PaginationInfo(
          response.data?.firmware?.count,
          response.data?.firmware?.total,
          response.data?.firmware?.pageInfo?.hasNextPage,
          response.data?.firmware?.pageInfo?.hasPreviousPage,
          response.data?.firmware?.pageInfo?.startCursor,
          response.data?.firmware?.pageInfo?.endCursor
        )

        this.ELEMENT_DATA = [];
        this.length = response.data?.firmware?.total
        this.isReady = false;

        /** Preenche a lista com todos os nodes de firmwares retornados pela API**/
        response.data.firmware.edges.forEach((firmware: any) => {
          this.ELEMENT_DATA.push(new FirmwaresTable(
            firmware.node.id,
            firmware.node.name,
            `${firmware.node.major}.${firmware.node.minor}.${firmware.node.revision}`,
            firmware.node.releaseDate
          ));
        })

        this.setDataSourceAttributes();

        if (this.ELEMENT_DATA.length === 0) {
          /** Retorna alerta ao usuário **/
          Swal.fire({
            title: 'Sua busca não retornou resultados',
            text: 'Nenhum resultado para este filtro',
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
        }

        this.firmwaresLoading = false;
      }),
      error: ((error: any) => {
        this.errorLibService.errorAlert(error);
      })
    })
  }

  /** Função responsável por criar Firmwares **/
  public createFirmware(): void {
    /** Verifica se os campos do formulário de criação de Firmware estão válidos **/
    if (this.createFirmwareForm.valid && this.selectedHardwaresIdList.length != 0) {

      this.registerFirmwareLoading = true;
      /** Envia os dados do formulário para a função responsável por criar Firmwares **/
      this.subscriptions = this.firmwaresService.createFirmware(
        this.createFirmwareForm.value.nameCreateForm,
        this.createFirmwareForm.value.majorCreateForm,
        this.createFirmwareForm.value.minorCreateForm,
        this.createFirmwareForm.value.revisionCreateForm,
        this.createFirmwareForm.value.descriptionCreateForm,
        this.formatDate(this.createFirmwareForm.value.releaseDateCreateForm),
        this.selectedHardwaresIdList
      ).subscribe({
        next: (() => {
          /** Realiza o filtro da tabela novamente com o novo firmware **/
          this.filterFirmwareForm.patchValue({
            nameFilterForm: this.filterFirmwareForm?.value.nameFilterForm
          });

          this.filterFirmware(null)

          /** Limpa o form após o filtro **/
          this.filterFirmwareForm.patchValue({
            nameFilterForm: null
          });

          Swal.fire({
            title: 'Firmware criado',
            text: 'Firmware criado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          this.registerFirmwareLoading = false;
        })
      })
      this.createFirmwareForm.reset();
    }
    /** Caso nenhum equipamento tenha sido selecionado */
    else if (this.selectedHardwaresIdList.length === 0) {
      /** Informa ao usuário que o formulário está incompleto */
      Swal.fire({
        title: 'Formulário Incompleto!',
        text: 'Selecione os hardwares compatíveis',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
    /** Caso os campos do formulário não estejam preenchidos */
    else {
      /** Informa para o usuário que o formulário está incompleto */
      Swal.fire({
        title: 'Formulário Incompleto!',
        text: 'Preencha todos os campos.',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
    this.registerFirmwareLoading = false;
  }

  /** Função responsável por criar um novo Hardware compatível **/
  public createNewHardware(): void {
    /** Verifica se os campos estão válidos **/
    if (this.createHardwareForm.valid) {

      this.registerHardwareLoading = true;
      /** Envia os dados do formulário para a função de criação de novo Hardware compatível **/
      this.subscriptions = this.firmwaresService.createNewCompatibleHardware(
        this.createHardwareForm.value.nameHardwareCreateForm,
        this.createHardwareForm.value.majorHardwareCreateForm,
        this.createHardwareForm.value.minorHardwareCreateForm,
        this.createHardwareForm.value.revisionHardwareCreateForm,
        this.createHardwareForm.value.descriptionHardwareCreateForm,
        this.formatDate(this.createHardwareForm.value.releaseDateHardwareCreateForm),
      ).subscribe({
        next: (() => {
          /** Atualiza a lista de hardwares para popular o select de hardwares compatíveis **/
          this.refreshHardwares()

          Swal.fire({
            title: 'Hardware criado',
            text: 'Hardware criado com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok'
          });

          this.registerHardwareLoading = false;
        })
      })
      this.createHardwareForm.reset();
    }
    /** Caso os campos do formulário não estejam preenchidos **/
    else {
      /** Informa ao usuário que o formulário está incompleto **/
      Swal.fire({
        title: 'Formulário Incompleto!',
        text: 'Preencha todos os campos.',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }
  }

  /** Função para verificar hardwares que foram selecionados no checkbox **/
  public filterSelectedHardwares(event: any, selectedCompatibleHardwaresIdList: string) {
    /** Caso o hardware tenha sido selecionado **/
    if (event.checked) {
      /** Adiciona hardware selecionado na lista de selecionados **/
      this.selectedHardwaresIdList.push(selectedCompatibleHardwaresIdList);
    }
    /** Caso o hardware tenha sido desmarcado **/
    else {
      /** Remove o hardware da lista **/
      this.selectedHardwaresIdList = this.selectedHardwaresIdList.filter((selectedHardId: string) =>
        selectedHardId != selectedCompatibleHardwaresIdList
      );
    }
  }

  public refreshHardwares() {
    /** Limpa a lista para repopular com os resultados de uma nova requisição**/
    this.hardwaresList = []
    /** Filtra todos os hardwares para popular a lista que preenche o select **/
    this.subscriptions = this.firmwaresService.getAllHardwares(
    ).valueChanges.subscribe({
      next: ((response: any) => {
        response.data.hardware.edges.forEach((hardwares: any) => {
          this.hardwaresList.push(
            new Hardwares(
              hardwares.node.id,
              hardwares.node.name,
              hardwares.node.reference,
              `${hardwares.node.major}.${hardwares.node.minor}.${hardwares.node.revision}`,
              hardwares.node.description,
              hardwares.node.releaseDate
            )
          );
        });
      })
    })
  }

  /** Função responsável por realizar a conversão da data utilizada nos inputs **/
  public formatDate(releaseDate: any) {
    /** Define um objeto do tipo date passando a data **/
    releaseDate = new Date(releaseDate);
    /** Retorna os valores de dia, mês e ano convertidos **/
    return `${releaseDate.getFullYear()}-${String(releaseDate.getMonth() + 1).padStart(2, '0')}-${releaseDate.getDate().toString().padStart(2, '0')}`
  }

  resetFilter() {
    this.filterFirmwareForm.reset();
  }

  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscriptions = this.firmwaresService.filterFirmwares(
        this.name,
        this.major,
        this.minor,
        this.revision,
        cursor,
        100
      ).valueChanges.subscribe({
        next: ((response: any) => {

          response.data.firmware.edges.forEach((firmware: any) => {

            this.FILE_DATA.push(new HardwaresTable(
              firmware.node.id,
              firmware.node.name,
              `${firmware.node.major}.${firmware.node.minor}.${firmware.node.revision}`,
              firmware.node.releaseDate,
            ));
          });

          /** Caso seja a última página a lista está completa **/
          if (!response.data?.firmware?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.firmware?.pageInfo?.endCursor, fileType);
        }),
        error: ((error: any) => {
          this.errorLibService.errorAlert(error);
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  /** Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe **/
  private convertToCSV(arr: any) {
    /** Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas **/
    const content = [Object.keys(arr[0])].concat(arr);

    /** Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um **/
    return content.map((item) => {
      return Object.values(item).toString().replace(',', ';');
    })
      .join('\n');
  }

  /** Método para realizar o download do arquivo CSV **/
  public downloadCSV() {
    let content: any = [];

    /** Adiciona cada elemento como um objeto na lista de conteúdo **/
    this.FILE_DATA.forEach((element) => {
      content.push({
        'Referência': element.name,
        'Versão': element.version,
        'Data de release': element.releaseDate
      });
    });

    /** Adiciona o conteúdo convertido dentro de uma nova variável. **/
    let data = this.convertToCSV(content);
    /**  Transforma o conteúdo para o formato blob para geração do arquivo. **/
    let file = new Blob([data], { type: 'text/csv' });
    /** Cria um endereçamento para o arquivo. **/
    let url = window.URL.createObjectURL(file);
    /** Cria um elemento para a linkagem do arquivo. **/
    let link = document.createElement('a');
    /** Adiciona a url no elemento de linkagem. **/
    link.href = url;
    /**  Nomeia o arquivo que será feito o download. **/
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-Firmwares.csv`;
    /** Adiciona o link no documento. **/
    document.body.appendChild(link);
    /** Atica o evento de click para realizar o download. **/
    link.click();
    /** Remove o link do documento. **/
    document.body.removeChild(link);
    /**  Remove o endereço do download. **/
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  /** Método para realizar o download do arquivo PDF **/
  public downloadPDF() {
    // Colunas que serão adicionadas ao header da tabela no PDF
    const tableLayout = [
      "Referência",
      "Versão",
      "Data de release"
    ];

    let content: any = []
    // Adiciona os elementos na lista de conteúdo como um array
    this.FILE_DATA.forEach((element) => {
      content.push([
        element.name,
        element.version,
        element.releaseDate
      ]);
    });
    // Define a orientação da tabela (paisagem nesse caso)
    const orientation = 'l';
    // Cria o objeto da lib jsPDF com a orientação e tamanho da página
    const doc: any = new jsPDF(orientation, "mm", "a4");

    // Cria a tabela com o tableLayout como header e a a lista de conteúdo como corpo da tabela
    doc.autoTable({
      theme: "grid",
      margin: { top: 20 },
      head: [tableLayout],
      body: content
    });
    // Baixa o arquivo para o usuário, definindo o nome com a data de download
    doc.save(`${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-Firmwares.pdf`);

    this.FILE_DATA = [];
    this.isReady = false;
  }
}
