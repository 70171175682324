<div class="form-fields-box" [ngClass]="!filter ? 'closed' : ''">
  <h2 (click)="filterBox()"> {{"alerts.alert-edit.title" | translate}} </h2>
  <hr [ngClass]="!filter ? 'hr-open' : ''" />
  <div *ngIf="loadingAlertData$ | async">
    <p> {{"alerts.alert-edit.loading" | translate}} </p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <mat-accordion *ngIf="!(loadingAlertData$ | async)">
    <!-- informações básicas -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> {{"alerts.alert-edit.informations" | translate}} </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row mb-2">
        <span class="col-xl-12 text-start"><strong> {{"alerts.alert-edit.point" | translate}} </strong> {{
          editedAlert?.pointReference }}</span>
        <mat-divider></mat-divider>
        <span class="col-xl-12 text-start"><strong> {{"alerts.alert-edit.status" | translate}} </strong> {{
          editedAlert?.status }}</span>
      </div>

      <div *ngIf="editedAlert?.rule">
        <span class="d-flex text-start w-100 mt-2 mb-1"><strong> {{"alerts.alert-edit.rules" | translate}}
          </strong></span>
        <ul>
          <li>{{ editedAlert?.rule }}</li>
        </ul>
      </div>

      <h3 class="d-flex text-start w-100 mt-2 mb-1">
        <strong> {{"alerts.alert-edit.change-status" | translate}} </strong>
      </h3>
      <div class="d-flex btn-div w-100 justify-content-evenly mt-2">
        <button class="btn btn-primary" (click)="changeAlertStatus('AWARE')">
          {{"alerts.alert-edit.button-aware" | translate}}
        </button>
        <button class="btn btn-warning" (click)="changeAlertStatus('IGNORED')">
          {{"alerts.alert-edit.button-ignored" | translate}}
        </button>
        <button class="btn btn-success" (click)="changeAlertStatus('RESOLVED')">
          {{"alerts.alert-edit.button-resolved" | translate}}
        </button>
      </div>
    </mat-expansion-panel>
    <!-- Eventos  -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> {{"alerts.alert-edit.events" | translate}} </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <li *ngFor="let events of generatedEvents?.generatedEvents" class="text-start">
          {{ events.eventName }} : {{ events.eventValue }}
        </li>
        <mat-divider *ngIf="generatedEvents && generatedEvents.generatedEvents.length > 1"></mat-divider>
      </ul>
    </mat-expansion-panel>

    <!-- Ordem de serviço  -->
    <mat-expansion-panel *ngIf="!alertHasServiceOrder" [formGroup]="serviceOrderForm">
      <mat-expansion-panel-header>
        <mat-panel-title> {{"alerts.alert-edit.create-OS" | translate}} </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field>
        <mat-label> {{"alerts.alert-edit.create-OS-priority-level" | translate}} </mat-label>
        <mat-select formControlName="priority">
          <mat-option value="LOW"> {{"alerts.alert-edit.create-OS-priority-level-low" | translate}}
          </mat-option>
          <mat-option value="MEDIUM"> {{"alerts.alert-edit.create-OS-priority-level-medium" | translate}}
          </mat-option>
          <mat-option value="HIGH"> {{"alerts.alert-edit.create-OS-priority-level-high" | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label> {{"alerts.alert-edit.create-OS-status" | translate}} </mat-label>
        <mat-select formControlName="status">
          <mat-option value="OPEN"> {{"alerts.alert-edit.create-OS-status-open" | translate}} </mat-option>
          <mat-option value="CLOSED"> {{"alerts.alert-edit.create-OS-status-closed" | translate}}
          </mat-option>
          <mat-option value="IN_PROGRESS"> {{"alerts.alert-edit.create-OS-status-in-progress" | translate}}
          </mat-option>
          <mat-option value="CLOSED_WITH_ISSUES">
            {{"alerts.alert-edit.create-OS-status-closed-width-issues" | translate}}
          </mat-option>
          <mat-option value="DELETED"> {{"alerts.alert-edit.create-OS-deleted" | translate}} </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label> {{"alerts.alert-edit.create-OS-problem-reference" | translate}} </mat-label>
        <input matInput formControlName="problem_reference" name="problem_reference" type="text" />
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label> {{"alerts.alert-edit.create-OS-problem-details" | translate}} </mat-label>
        <textarea matInput formControlName="problem_details" placeholder="Ex. Consumo muito alto..."></textarea>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label> {{"alerts.alert-edit.create-OS-problem-adress" |translate}}</mat-label>
        <input matInput formControlName="problem_address" name="problem_address" type="text" />
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label> {{"alerts.alert-edit.create-OS-name" | translate}} </mat-label>
        <input matInput formControlName="complainant_name" name="complainant_name" type="text" />
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label> {{"alerts.alert-edit.create-OS-landlineNumber" | translate}} </mat-label>
        <input matInput formControlName="complainant_landlineNumber" name="complainant_landlineNumber" type="text" />
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label> {{"alerts.alert-edit.create-OS-phoneNumber" | translate}} </mat-label>
        <input matInput formControlName="complainant_phoneNumber" name="complainant_phoneNumber" type="text" />
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label> {{"alerts.alert-edit.create-OS-cpf" | translate}} </mat-label>
        <input matInput formControlName="complainant_cpf" name="complainant_cpf" type="text" />
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label> {{"alerts.alert-edit.create-OS-solution-closure-date" | translate}} </mat-label>
        <input matInput formControlName="solution_closure_date" name="solution_closure_date" type="text" />
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label> {{"alerts.alert-edit.create-OS-solution" | translate}} </mat-label>
        <textarea matInput formControlName="solution_details" placeholder="Ex. Consumo muito alto..."></textarea>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label> {{"alerts.alert-edit.create-OS-solution-adress" | translate}} </mat-label>
        <input matInput formControlName="solution_address" name="solution_address" type="text" />
      </mat-form-field>

      <button class="btn btn-primary w-100" (click)="createServiceOrder()"> {{"alerts.alert-edit.button-create-OS" |
        translate}} </button>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title *ngIf="!alertHasServiceOrder">
          {{"alerts.alert-edit.link-OS" | translate}}
        </mat-panel-title>
        <mat-panel-title *ngIf="alertHasServiceOrder">
          {{"alerts.alert-edit.OS-linked" | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-progress-bar mode="indeterminate" *ngIf="serviceOrdersLoading$ | async"></mat-progress-bar>
      <span class="d-flex align-items-center" *ngIf="!alertHasServiceOrder">
        <mat-form-field label *ngIf="!(serviceOrdersLoading$ | async)">
          <mat-label> {{"alerts.alert-edit.select-OS" | translate}} </mat-label>
          <mat-select #matRef>
            <mat-option *ngFor="let serviceOrder of serviceOrders$ | async" [value]="serviceOrder.id"
              (click)="setSelectedOsInformation(serviceOrder)">
              ({{ serviceOrder.status }}) {{ serviceOrder.serviceIdentifier }}
              {{ serviceOrder.creationDate | date : "dd/MM/yyyy" }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-icon class="cancel-icon" *ngIf="selectedOsInformation" (click)="clearOsSelection()">cancel</mat-icon>
      </span>

      <mat-progress-bar mode="indeterminate" *ngIf="loadingSelectedOsInformation"></mat-progress-bar>
      <div class="os-info" *ngIf="!loadingSelectedOsInformation && selectedOsInformation">
        <p *ngIf="!alertHasServiceOrder"> {{"alerts.alert-edit.data-OS" | translate}}</p>
        <div class="row mt-2">
          <span class="col-xl-7 col-lg-6 col-md-4 col-sm-3"><strong> {{"alerts.alert-edit.code-OS" |
              translate}}
            </strong>
            {{ selectedOsInformation.serviceIdentifier }}</span>
          <mat-divider></mat-divider>
          <span class="col-xl-7 col-lg-6 col-md-4 col-sm-3"><strong> {{"alerts.alert-edit.OS-creation-date" |
              translate}}
            </strong>
            {{ selectedOsInformation.creationDate }}</span>
          <mat-divider></mat-divider>
          <span class="col-xl-7 col-lg-6 col-md-4 col-sm-3"><strong> {{"alerts.alert-edit.OS-status"|
              translate}}
            </strong> {{
            selectedOsInformation.status
            }}
          </span>
          <mat-divider></mat-divider>
          <span class="col-xl-7 col-lg-6 col-md-4 col-sm-3"><strong> {{"alerts.alert-edit.OS-point" |
              translate}}
            </strong>
            {{ selectedOsInformation.installation }}
          </span>
          <mat-divider></mat-divider>
          <span class="col-xl-7 col-lg-6 col-md-4 col-sm-3"><strong> {{"alerts.alert-edit.OS-operator" |
              translate}}
            </strong>
            {{ selectedOsInformation.operator }}
          </span>
          <mat-divider></mat-divider>
          <span class="col-12"><strong> {{"alerts.alert-edit.OS-problem" | translate}} </strong> {{
            selectedOsInformation.problem }}
          </span>
        </div>
      </div>
      <button class="btn btn-primary w-100" [disabled]="!selectedOsInformation" *ngIf="!alertHasServiceOrder"
        (click)="addServiceOrderToAlert()">
        {{"alerts.alert-edit.OS-button" | translate}}
      </button>
    </mat-expansion-panel>
  </mat-accordion>

  <button class="btn btn-primary mt-2" [disabled]="loadingAlertData$ | async" (click)="cancelEdit()">
    {{"alerts.alert-edit.OS-button-back" | translate}}
  </button>
</div>