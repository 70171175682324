import { Injectable, EventEmitter } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LOGIN_QUERY } from 'src/app/graphql/users.graphql';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  showSideBar = new EventEmitter<boolean>();
  showSideBarUpdateUser = new EventEmitter<boolean>();
  private geoServerUrl: string = environment.geoServerUrl;

  constructor(private apollo: Apollo, private http: HttpClient) { }

  /**
   * 
   * @param email 
   * @param password 
   * @returns 
   */
  logIn(email: string | null, password: string | null) {
    return this.apollo.mutate({
      mutation: LOGIN_QUERY,
      variables: {
        username: email,
        password: password,
      }
    });
  }

  // checa se o geoserver pode ser acessado com o username e senha no momento do login 
  checkGeoServerAuth(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(`${username}:${password}`)
    });

    return this.http.get(`${this.geoServerUrl}geoserver/rest/`, {
      headers: headers,
      observe: 'response',
      responseType: 'text'
    }).pipe(
      catchError(error => {
        console.error('Error during GeoServer auth check:', error);
        return throwError(() => error);
      })
    );
  }

}
