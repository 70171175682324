import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-helper-modal',
  templateUrl: './helper-modal.component.html',
  styleUrls: ['./helper-modal.component.less']
})
export class HelperModalComponent {
  constructor(private router: Router) {
    console.log(this.router.url);
    // Para obter a rota atual, você pode usar o evento 'NavigationEnd' do Router.
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPage = event.url;
      }
    });
  }

  currentPage: string = this.router.url;

}
