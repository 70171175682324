<div class="form-fields-box filter" [ngClass]="!filter ? 'closed' : ''">
  <h2 (click)="Filter()">Tipos de registros</h2>
  <hr [ngClass]="!filter ? 'hr-open' : ''" />

  <div class="form-step">
    <div class="circle-number circle-number-ready">1</div>
    <mat-form-field class="parameter" appearance="outline">
      <mat-label>O que deseja registrar?</mat-label>
      <mat-select [(ngModel)]="typesFilter" (selectionChange)="Router([value.value])" #value>
        <mat-option class="form-control" value="/register">Nenhum</mat-option>
        <mat-option class="form-control" value="/register/companies">Empresas</mat-option>
        <mat-option class="form-control" value="/register/unit-measurement">Unidade de Medida</mat-option>
        <mat-option class="form-control" value="/register/properties">Propriedades</mat-option>
        <mat-option class="form-control" value="/register/hardwares">Hardwares</mat-option>
        <mat-option class="form-control" value="/register/firmwares">Firmwares</mat-option>
        <mat-option class="form-control" value="/register/equipments">Equipamentos</mat-option>
        <mat-option class="form-control" value="/register/parameters">Parâmetros</mat-option>
        <mat-option class="form-control" value="/register/components">Componentes</mat-option>
        <mat-option class="form-control" value="/register/meters"> Medidores </mat-option>
        <mat-option class="form-control" value="/register/lampsType"> Tipos de Luminárias </mat-option>
        <mat-option class="form-control" value="/register/equipment-type">Tipo de Equipamento</mat-option>
        <mat-option class="form-control" value="/register/installations">Instalações</mat-option>
        <mat-option class="form-control" value="/register/installation-site">Local de Instalação</mat-option>
        <mat-option class="form-control" value="/register/division"> Divisão (Subprefeitura) </mat-option>
        <mat-option class="form-control" value="/register/gateways"> Concentradores </mat-option>
        <mat-option class="form-control" value="/register/circuit-box"> Caixas de Circuito </mat-option>
      </mat-select>
    </mat-form-field>
  </div>