<div class="component-main-container">
  <div class="header">
    <app-page-header parentTitle="{{ 'properties.properties-parent-label' | translate }}"
      pageTitle="{{ 'properties.properties-title-label' | translate }}">
    </app-page-header>
  </div>
  <div class="row">
    <div class="col-lg-4 col-xl-4 col-sm-12">
      <div class="component-forms-box">
        <app-register-form></app-register-form>

        <div class="form-fields-box" [ngClass]="!registerPropertiesBox ? 'closed' : ''"
          [formGroup]="createPropertiesForm">
          <h2 (click)="registerPropertiesOpen()">
            {{ "properties.form-register-label" | translate }}
          </h2>
          <hr [ngClass]="!registerPropertiesBox ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "properties.form-register-name" | translate
                }}</mat-label>
              <input matInput formControlName="name" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">2</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "properties.form-register-source" | translate
                }}</mat-label>
              <input matInput formControlName="sourcename" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">3</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "properties.form-register-shortname" | translate
                }}</mat-label>
              <input maxlength="4" matInput formControlName="shortname" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">4</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "properties.form-register-service" | translate
                }}</mat-label>
              <input matInput formControlName="service" />
            </mat-form-field>
          </div>

          <div class="form-step">
            <div class="circle-number">5</div>

            <mat-form-field class="parameter" appearance="outline">
              <mat-label>{{
                "properties.form-register-measurementUnit" | translate
                }}</mat-label>
              <mat-select formControlName="measurementUnitSelected" #value>
                <mat-option [value]="item.id" *ngFor="let item of measurementUnitList">
                  {{ item.name }} {{ item.symbol }}
                </mat-option>

                <mat-option *ngIf="createLoading">
                  <mat-spinner [diameter]="30"></mat-spinner>
                </mat-option>

                <mat-option *ngIf="measurementUnitList.length === 0">
                  Não há unidades de medidas cadastradas
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button *ngIf="!filterLoading" (click)="createProperties()" class="btn btn-success">
            {{ "properties.form-button-save" | translate }}
          </button>
          <mat-spinner [diameter]="30" *ngIf="filterLoading"></mat-spinner>
        </div>

        <div class="form-fields-box" [ngClass]="!filterPropertiesBox ? 'closed' : ''"
          [formGroup]="filterPropertiesForm">
          <h2 (click)="filterPropertiesOpen()">
            {{ "properties.form-filter-name" | translate }}
          </h2>
          <hr [ngClass]="!filterPropertiesBox ? 'hr-open' : ''" />

          <div class="form-step">
            <div class="circle-number">1</div>
            <mat-form-field appearance="outline">
              <mat-label>{{
                "properties.form-register-name" | translate
                }}</mat-label>
              <input matInput formControlName="nameContains" />
            </mat-form-field>
          </div>

          <button *ngIf="!filterLoading" (click)="filterProperties(null)" class="btn btn-success">
            {{ "properties.form-button-filter" | translate }}
          </button>
          <mat-spinner [diameter]="30" *ngIf="filterLoading"></mat-spinner>
        </div>
      </div>
    </div>

    <div class="col-lg-7 col-xl-8 col-sm-12" *ngIf="paginationProperties.total">
      <app-table-header [itensNumber]="paginator.pageSize" [totalNumber]="paginationProperties.total" [hasCSV]="true"
        [hasPDF]="true" (downloadCSV)="generateFileData(null, 'CSV')" (downloadPDF)="generateFileData(null, 'PDF')"
        [loadingData$]="fileLoading">
      </app-table-header>

      <!-- Sessão tabela -->
      <div class="table-div mt-2 mt-lg-3 mt-xl-3">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
              {{ "properties.column-name" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <!-- CNPJ Column -->
          <ng-container matColumnDef="sourceName">
            <th mat-header-cell *matHeaderCellDef>
              {{ "properties.column-source" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.sourceName }}</td>
          </ng-container>

          <ng-container matColumnDef="shortName">
            <th mat-header-cell *matHeaderCellDef>
              {{ "properties.column-shortname" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.shortName }}</td>
          </ng-container>

          <ng-container matColumnDef="service">
            <th mat-header-cell *matHeaderCellDef>
              {{ "properties.column-service" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.service }}</td>
          </ng-container>

          <ng-container matColumnDef="measurementUnit">
            <th mat-header-cell *matHeaderCellDef>
              {{ "properties.column-measurementUnit" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.measurementUnit.name }}
              {{ element.measurementUnit.symbol }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Ações</th>
            <td mat-cell *matCellDef="let element" on-mouseover="element.view = true"
              on-mouseleave="element.view = false">
              <button (click)="openSidebarUpdate(element, false)" [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success view">
                <mat-icon class="component-svg-icon" svgIcon="bx-edit"></mat-icon>
              </button>
              <button (click)="openSidebarUpdate(element, true)" [ngClass]="element.view === true ? 'hover' : ' '"
                class="btn btn-success view">
                <mat-icon class="component-svg-icon" svgIcon="bx-show-white"></mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex"
          (page)="pageChanged($event)" aria-label="Selecione as páginas de Propriedades" class="mat-paginator-sticky">
        </mat-paginator>
      </div>
    </div>
  </div>

  <!-- SideBar para edição/visualização -->
  <div class="sidebar-edit" *ngIf="editorController" [formGroup]="updatePropertiesForm">
    <mat-icon (click)="closeEditionSideBar()"> clear </mat-icon>
    <h2 *ngIf="!viewController"> {{"properties.form-edit-property" | translate}}</h2>
    <h2 *ngIf="viewController">Visualizar Propriedades</h2>
    <hr />

    <mat-form-field appearance="outline">
      <mat-label>{{ "properties.form-edit-name" | translate }}
      </mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ "properties.form-edit-source" | translate }}
      </mat-label>
      <input matInput formControlName="sourcename" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ "properties.form-edit-shortname" | translate }}
      </mat-label>
      <input maxlength="4" matInput formControlName="shortname" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ "properties.form-edit-service" | translate }}
      </mat-label>
      <input matInput formControlName="service" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        {{ "properties.form-edit-measurementUnit" | translate }}
      </mat-label>
      <mat-select formControlName="measurementUnitSelected" #value>
        <mat-option [value]="item.id" *ngFor="let item of measurementUnitList">
          {{ item.name }} {{ item.symbol }}
        </mat-option>

        <mat-option *ngIf="updateLoading">
          <mat-spinner [diameter]="30"></mat-spinner>
        </mat-option>

        <mat-option *ngIf="measurementUnitList.length === 0">
          {{"properties.no-measurementUnit" | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button *ngIf="!viewController" class="btn btn-success" (click)="updateProperties()">
      {{"properties.button-edit" | translate}}
    </button>
  </div>
</div>