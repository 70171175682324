import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FILTERS_UNIT_MEASUREMENT, CREATE_UNIT_MEASUREMENT, UPDATE_UNIT_MEASUREMENT } from 'src/app/graphql/measurement-unit.graphql';
import { ErrorLibService } from './error-lib.service';

@Injectable({
  providedIn: 'root'
})
export class UnitMeasurementService {

  constructor(
    private apollo: Apollo,
    private errorLibService: ErrorLibService
  ) { }

  //Função responsável por manipular o GRAPHQL referente a criação de unidades de medida
  createUnits(
    reference: string | null,
    symbol: string | null
  ) {
    return this.apollo.mutate({
      mutation: CREATE_UNIT_MEASUREMENT,
      fetchPolicy: 'network-only',
      variables: {
        name: reference,
        symbol
      }
    })
  }


  //Função responsável por manipular o GRAPHQL referente a filtragem de unidades de medida
  filterUnits(
    reference: string | null,
    cursor: string | null,
    pageSize: number | null
  ): any {
    return this.apollo.watchQuery({
      query: FILTERS_UNIT_MEASUREMENT,
      fetchPolicy: 'network-only',
      variables: {
        cursor,
        pageSize,
        nameContains: reference,
      }
    })
  }

  //Função responsável por manipular o GRAPHQL referente a atualização de unidades de medida
  updateUnits(
    id: string | null,
    reference: string | null,
    symbol: string | null
  ) {
    return this.apollo.mutate({
      mutation: UPDATE_UNIT_MEASUREMENT,
      fetchPolicy: 'no-cache',
      variables: {
        id,
        name: reference,
        symbol
      }
    })
  }
}
