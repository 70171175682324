/** Variaveis de Componentes **/
export class Meters {
    constructor(
      public id: string,
      public serialNumber: string | null,
      public materialTypes: string | null,
      public installation: string | null
    ){}
}

/** Variáveis de Componentes para a tabela (Filtro Inicial) **/
export class MetersTable{
  constructor(
    public id: string,
    public serialNumber: string | null,
    public materialTypes: string | null,
    public installation: string | null,
  ){}
}

/** Variáveis de tipos de Componentes **/
export class MetersType {
  constructor(
    public id: string, 
    public reference: string | null,
  ){}
}
/** Variáveis de Instalações **/
export class Installations {
  constructor(
    public id: string,
    public reference: string | null
  ){}
}