import { AuditData } from "./auditData.model";

export class AuditList {
    constructor(
        public id: string | null,
        public reference: string | null,
        public installationTotal: number | null,
        public data: AuditData,
        public accountable: string | null,
        public creationDate: string | null,
        public startDate: string | null,
        public endDate: string | null,
        public status: string | null
    ) { }
}

/** Utilizado para exportar as informações de paginação **/
export class PaginationInfo {
    constructor(
        public total: number,
        public count: number,
        public hasNextPage: boolean,
        public hasPreviousPage: boolean,
        public startCursor: string | null,
        public endCursor: string | null
    ) { }

}