/** Classe que contém as propriedades do gateway **/
export class Gateway {
    constructor(
        public id: string,
        public reference: string | null,
        public isActive: boolean | null,
        public site: InstallationSite,
        public device: Device
    ) { }
}
/** Classe que contém as propriedades do local de instalação **/
export class InstallationSite {
    constructor(
        public id: string | null,
        public reference: string | null,
        public number: Number,
        public district: string | null,
        public state: string | null,
        public city: string | null,
        public country: string | null,
        public lat: string | null,
        public long: string | null,
        public street: string | null
    ) { }
}

/** Classe que contém as propriedades do equipamento **/
export class Device {
    constructor(
        public id: string | null,
        public serialNumber: string | null,
        public reference: string | null,
        public major: string | null,
        public minor: string | null,
        public revision: string | null,
    ) { }
}

/** Classe que contém as propriedades da paginação **/
export class PaginationInfo {
    constructor(
        public count: number,
        public total: number,
        public hasNextPage: boolean,
        public hasPreviousPage: boolean,
        public startCursor: string | null,
        public endCursor: string | null
    ) { }
}