<div class="component-main-container">
    <app-page-header parentTitle="{{'imports-csv.parentTitle' | translate}}"
        pageTitle="{{'imports-csv.pageTitle-create-installations' | translate}}">
    </app-page-header>
    <div class="row">
        <div class="col-lg-12 col-xl-12 col-sm-12">
            <div class="component-forms-box">
                <div class="form-fields-box">
                    <mat-stepper class="example-stepper" #stepper>
                        <mat-step label="{{'imports-csv.step-load-file' | translate}}">
                            <form>
                                <h4> {{'imports-csv.create-installations-title' | translate}} </h4>
                                <div class="input-group">
                                    <input type="file" class="form-control" id="inputGroupFile04" [accept]="'.csv'"
                                        aria-describedby="inputGroupFileAddon04" aria-label="Upload" #fileUploadSimple
                                        (change)="getTextFromFile($event)">
                                    <br>
                                    <button class="btn btn-secondary" type="button" id="inputGroupFileAddon04"
                                        (click)="importDataFromCSV()" type="submit" [disabled]="disableImportButton">
                                        {{'imports-csv.button-import-csv' |
                                        translate}}
                                    </button>
                                    <mat-progress-bar mode="indeterminate" *ngIf="loadingUpdate"></mat-progress-bar>
                                    <p *ngIf="loadingUpdate"
                                        style="color: red;margin-right: auto; margin-left: auto; margin-top: 17px">
                                        {{'imports-csv.loading-update' | translate}}
                                    </p>
                                </div>
                                <p id="text-information" *ngIf="logReadyMessage">
                                    {{'imports-csv.text-available-logs' | translate}}
                                </p>
                                <br>
                                <div>
                                    <button mat-button (click)="goToStep(2)">
                                        {{'imports-csv.doubts-importing' | translate}}
                                        <mat-icon iconPositionEnd>info</mat-icon>
                                    </button>
                                </div>

                                <button [disabled]="!isExported" style="margin: 2px" mat-fab color="primary" extended
                                    matStepperNext>
                                    <mat-icon iconPositionEnd>keyboard_arrow_right</mat-icon>
                                    {{'imports-csv.next-page' | translate}}
                                </button>
                            </form>
                        </mat-step>
                        <mat-step label="{{'imports-csv.step-export-logs' | translate}}">
                            <form>
                                <mat-spinner *ngIf="loadingUpdate"></mat-spinner>
                                <div>
                                    <p *ngIf="!loadingUpdate && !isExported"
                                        style="color: red;margin-right: auto; margin-left: auto; margin-top: 17px">
                                        {{'imports-csv.no-logs-message' | translate}}
                                    </p>
                                </div>
                                <div>
                                    <h4 *ngIf="isExported"> {{'imports-csv.import-completed-message' | translate}} </h4>
                                    <img *ngIf="isExported"
                                        src='../../../../../../../assets/imports-csv-imgs/concluded.png'>
                                </div>
                                <p *ngIf="loadingUpdate"
                                    style="color: red;margin-right: auto; margin-left: auto; margin-top: 17px">
                                    {{'imports-csv.waiting-logs' | translate}}
                                </p>
                                <br>
                                <button mat-flat-button color="success" *ngIf="isExported" (click)="exportLogs()">
                                    {{'imports-csv.button-export-logs' | translate}}
                                </button>

                                <br>
                                <button style="margin: 2px; top: 7px;" mat-fab color="primary" extended
                                    matStepperPrevious>
                                    <mat-icon iconPositionStart>keyboard_arrow_left</mat-icon>
                                    {{'imports-csv.previous-page' | translate}}
                                </button>
                                <button style="margin: 2px" mat-fab color="primary" extended matStepperNext>
                                    <mat-icon iconPositionEnd>keyboard_arrow_right</mat-icon>
                                    {{'imports-csv.next-page' | translate}}
                                </button>
                            </form>
                        </mat-step>
                        <mat-step label="{{'imports-csv.step-doubts' | translate}}" aria-disabled="true">
                            <form>
                                <h4> {{'imports-csv.doubts-create-installation-title' | translate}} </h4>
                                <img src='../../../../../../../assets/imports-csv-imgs/download-files.jpg'>
                                <div>
                                    <h3> {{'imports-csv.doubt-import-info' | translate}} <br>
                                        {{'imports-csv.doubt-import-info-create-installations' | translate}}
                                    </h3>
                                </div>
                                <div>
                                    <button (click)="openDialog()" mat-flat-button color="primary">
                                        {{'imports-csv.how-to-send-file' | translate}}
                                    </button>
                                </div>
                                <a class="button-download-example" color="primary" mat-button
                                    (click)="downloadCsvFile()" target="_blank">
                                    {{'imports-csv.example-download' | translate}}
                                </a>
                                <div>
                                    <button style="margin: 2px; top: 7px;" mat-fab color="primary" extended
                                        matStepperPrevious>
                                        <mat-icon iconPositionStart>keyboard_arrow_left</mat-icon>
                                        {{'imports-csv.previous-page' | translate}}
                                    </button>
                                </div>
                            </form>
                        </mat-step>
                    </mat-stepper>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #dialogTemplate let-dialogRef="dialogRef">
    <div class="dialogRef">
        <h2 mat-dialog-title>{{'imports-csv.instructions-import-create-installation' | translate}} </h2>
        <mat-dialog-content class="mat-typography">
            <h3>{{'imports-csv.import-description' | translate}}</h3>
            <p>{{'imports-csv.import-create-installation-description' | translate}}</p>

            <h3>{{'imports-csv.preparation-file-title' | translate}}</h3>
            <p>{{'imports-csv.preparation-file-text' | translate}}</p>
            <ul>
                <li>{{'imports-csv.preparation-file-semicolon' | translate}} (<code>;</code>).</li>
                <li>{{'imports-csv.preparation-file-filled-fields' | translate}}</li>
                <li>{{'imports-csv.preparation-header' | translate}}</li>
            </ul>
            <pre>reference<code>;</code>equipment<code>;</code>gateway<code>;</code>lampType<code>;</code>magneticKey<code>;</code>applications<code>;</code>division<code>;</code>site<code>;</code>latitude<code>;</code>longitude<code>;</code>country<code>;</code>state<code>;</code>city<code>;</code>district<code>;</code>street<code>;</code>number<code>;</code>details<code>;</code>locationCode</pre>
            <h3>{{'imports-csv.field-details' | translate}}</h3>
            <table border="1" cellpadding="5" cellspacing="0">
                <thead>
                    <tr>
                        <th>{{'imports-csv.field' | translate}}</th>
                        <th>{{'imports-csv.expected-type' | translate}}</th>
                        <th>{{'imports-csv.content' | translate}}</th>
                        <th>{{'imports-csv.mandatory' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>reference</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-reference-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-yes' | translate}}</td>
                    </tr>
                    <tr>
                        <td>equipment</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-serial-number-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-no' | translate}}</td>
                    </tr>
                    <tr>
                        <td>gateway</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-gateway-reference-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-no' | translate}}</td>
                    </tr>
                    <tr>
                        <td>lampType</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-lamps-type-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-no' | translate}}</td>
                    </tr>
                    <tr>
                        <td>magneticKey</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-magnetic-key-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-no' | translate}}</td>
                    </tr>
                    <tr>
                        <td>applications</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-application-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-yes' | translate}}</td>
                    </tr>
                    <tr>
                        <td>division</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-division-reference-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-no' | translate}}</td>
                    </tr>
                    <tr>
                        <td>site</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-site-reference-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-yes' | translate}}</td>
                    </tr>
                    <tr>
                        <td>latitude</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-lat-site-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-yes' | translate}}</td>
                    </tr>
                    <tr>
                        <td>longitude</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-lng-site-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-yes' | translate}}</td>
                    </tr>
                    <tr>
                        <td>country</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-country-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-no' | translate}}</td>
                    </tr>
                    <tr>
                        <td>state</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-state-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-no' | translate}}</td>
                    </tr>
                    <tr>
                        <td>city</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-city-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-no' | translate}}</td>
                    </tr>

                    <tr>
                        <td>district</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-district-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-no' | translate}}</td>
                    </tr>
                    <tr>
                        <td>street</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-street-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-no' | translate}}</td>
                    </tr>
                    <tr>
                        <td>number</td>
                        <td>String</td>
                        <td>{{'imports-csv.field-number-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-no' | translate}}</td>
                    </tr>
                    <tr>
                        <td>details</td>
                        <td>String</td>
                        <td> {{'imports-csv.field-details-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-no' | translate}}</td>
                    </tr>
                    <tr>
                        <td>locationCode</td>
                        <td>String</td>
                        <td> {{'imports-csv.field-cep-content' | translate}}</td>
                        <td>{{'imports-csv.field-mandatory-no' | translate}}</td>
                    </tr>
                </tbody>
            </table>
            <ul>
                <li>
                    {{'imports-csv.non-mandatory-fields-text' | translate}}
                </li>
                <li>
                    {{'imports-csv.reference-installation-site-text' | translate}}
                </li>
            </ul>

            <h3>{{'imports-csv.application-field-text' | translate}}</h3>
            <table border="1" cellpadding="5" cellspacing="0">
                <thead>
                    <tr>
                        <th>Application</th>
                        <th>{{'imports-csv.field-type' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>light</td>
                        <td> {{'imports-csv.application-light' | translate}}</td>
                    </tr>
                    <tr>
                        <td>electricity</td>
                        <td> {{'imports-csv.application-electricity' | translate}}</td>
                    </tr>
                    <tr>
                        <td>gas</td>
                        <td> {{'imports-csv.application-gas' | translate}}</td>
                    </tr>
                    <tr>
                        <td>water</td>
                        <td> {{'imports-csv.application-water' | translate}}</td>
                    </tr>
                </tbody>
            </table>
            <br>
            <h3>{{'imports-csv.model-to-field-title' | translate}} <code>magneticKey</code> </h3>
            <p>{{'imports-csv.model-field-text' | translate}} <code>magneticKey</code>,
                {{'imports-csv.follow-model-text' | translate}}</p>
            <table border="1" cellpadding="5" cellspacing="0">
                <thead>
                    <tr>
                        <th>{{'imports-csv.field' | translate}}</th>
                        <th>{{'imports-csv.expected-value' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>magneticKey</td>
                        <td> {{'imports-csv.false-or-true' | translate}}</td>
                    </tr>
                </tbody>
            </table>
            <ul>
                <li> {{'imports-csv.no-magnetic-key-text' | translate}}
                </li>
            </ul>

            <h3> {{'imports-csv.file-structure-title' | translate}} </h3>
            <p>{{'imports-csv.file-structure-text' | translate}}</p>
            <pre>reference<code>;</code>equipment<code>;</code>gateway<code>;</code>lampType<code>;</code>magneticKey<code>;</code>applications<code>;</code>division<code>;</code>site<code>;</code>latitude<code>;</code>longitude<code>;</code>country<code>;</code>state<code>;</code>city<code>;</code>district<code>;</code>street<code>;</code>number<code>;</code>details<code>;</code>locationCode<br>REF01<code>;</code>EQUIPMENT01<code>;</code>GATE01<code>;</code>LAMPTYPE01<code>;</code>0<code>;</code>light<code>;</code>DIVISION01<code>;</code>SITE01<code>;</code>-0056464566<code>;</code>-546455164545<code>;</code>COUNTRYNAME<code>;</code>STATENAME<code>;</code>CITYNAME<code>;</code>DISTRICTNAME<code>;</code>STREETNAME<code>;</code>1<code>;</code>DETAILSSITE0<code>;</code>1234</pre>

        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-flat-button color="success" mat-dialog-close>OK</button>
        </mat-dialog-actions>
    </div>
</ng-template>