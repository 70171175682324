import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateformatterService {

  constructor() { }

  dateWithHyphen(date: any): string {
    let newDate = date.toISOString();
    newDate = newDate.substring(0, newDate.indexOf('T'));
    return newDate;
  }

  dateToJsonStringify(date: any): string {
    return JSON.stringify(date).replace(/"/g, '')
  }

  dateToIsoString(date: any): string {
    let newDate = new Date(date);
    return newDate.toISOString();
  }

}
