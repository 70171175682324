// Variáveis utilizadas na lista de filtro de todos os firmwares (filtro inicial)
export class FirmwaresTable {
    constructor(
        public id: string,
        public name: string | null,
        public version: string | null,
        public releaseDate: Date | null,
    ){ }
}
// Variáveis utilizadas para criação de hardwares
export class Hardwares {
    constructor(
        public id: string,
        public name: string,
        public reference: string | null,
        public version: string | null,
        public description: string | null,
        public releaseDate: Date | null
    ){ }
}

