<div class="main-container">
  <!-- Deverá ser componentizado -->
  <div class="header">
    <div class="info-icon">
      <app-helper></app-helper>
    </div>
    <div class="header-title">
      <svg xmlns="http://www.w3.org/2000/svg" (click)="goToHome()" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
        stroke="currentColor" class="menu-icon header-link">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
      </svg>
      <h3 class="header-link" (click)="goToHome()">{{'home-page-label' | translate}}</h3>

      <div *ngIf="parentTitle" class="d-flex align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="menu-icon">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
        <h3 class="header-link" (click)="openSidebar()">{{parentTitle}}</h3>
      </div>

      <div *ngIf="pageTitle" class="d-flex align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="menu-icon">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
        <h3 class="header-link" (click)="openSubMenu()">{{pageTitle}}</h3>
      </div>

      <div *ngIf="subRoute" class="d-flex align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="menu-icon">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
        <h3 class="header-link">{{subRoute}}</h3>
      </div>

    </div>
  </div>
</div>