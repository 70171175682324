import { Component, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Unsubscriber } from 'src/shared/components/unsubscriber/unsubscriber.component';
import { AuditorshipService } from 'src/shared/services/auditorship.service';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import Swal from 'sweetalert2';
import { AuditData } from '../../models/auditData.model';
import { AuditList, PaginationInfo } from '../../models/auditList.model';
import { AuditInstallations } from '../../models/auditInstallations.model';

@Component({
  selector: 'app-report-audit-manage',
  templateUrl: './report-audit-manage.component.html',
  styleUrls: ['./report-audit-manage.component.less']
})
export class ReportAuditManageComponent extends Unsubscriber {
  constructor(
    private auditorshipService: AuditorshipService,
    private router: Router,
    private translate: TranslateService,
    private errorLib: ErrorLibService) {

    super();
    localStorage.getItem('lastCompanySelected') ?
      this.company = localStorage.getItem('lastCompanySelected') :
      this.company = '';
  }

  ngOnInit(): void {
    this.getAuditList(null);
    this.anotherSubscription = this.translate.get(this.successSwalTitle).subscribe((text: string) => {
      this.successSwalTitleTranslated = text;
    });
    this.anotherSubscription = this.translate.get(this.successSwalText).subscribe((text: string) => {
      this.successSwalTextTranslated = text;
    });
    this.anotherSubscription = this.translate.get(this.inProgressStatusLabel).subscribe((text: string) => {
      this.inProgressStatusLabelTranslated = text;
    });
    this.anotherSubscription = this.translate.get(this.finishedStatusLabel).subscribe((text: string) => {
      this.finishedStatusLabelTranslated = text;
    });
  }
  /** Variável que contém a lista de usuários (Utilizado para popular a tabela) **/
  public ELEMENT_DATA: AuditData[] = [];

  /**  Renderiza os comandos da lista ELEMENT_DATA para a tabela **/
  public dataSource = new MatTableDataSource<AuditData>(this.ELEMENT_DATA);

  company: string | null = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private sort: MatSort;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;

    this.setDataSourceAttributes();

  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  /** Variáveis utilizadas para manipulação do Paginator **/
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 1;

  /** Variável utilizada para controle de paginação **/
  public paginationProperties: PaginationInfo = new PaginationInfo(
    0,
    0,
    true,
    false,
    null,
    null
  );

  /**
   * Função que configura o paginator.
   */
  setDataSourceAttributes() {
    if (this.paginator && this.sort) {

      this.dataSourceAudit.paginator = this.paginator;
      this.dataSourceAudit.sort = this.sort;

    }
  }
  /**
  * Função que configura o paginator.
  */
  public setDataSourceAttributes231() {
    this.dataSource.data = this.ELEMENT_DATA;
    this.dataSource.paginator = this.paginator;
  }

  /** Função que controla os botões de anterior e pŕoximo no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     */
    if (event.previousPageIndex > event.pageIndex) {
      this.handlePreviousPageAudit(this.paginationProperties.endCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.getAuditList(this.paginationProperties.startCursor)
    }
  }


  auditCount: number = 0;
  //Fim variáveis do paginator

  //Flags gerais
  tableIsLoading: boolean = false;
  editingAudit: boolean = false;
  seeingAuditReportDownloads: boolean = false;
  referenceContainSpecialCharacters: boolean = false;
  referenceAlreadyExists: boolean = false;

  //translate variable
  successSwalTitle: string = 'report-audit.report-audit-manage.ts-file-labels.swal-fire-message-title'
  successSwalText: string = 'report-audit.report-audit-manage.ts-file-labels.swal-fire-message-text'
  inProgressStatusLabel: string = 'report-audit.report-audit-manage.ts-file-labels.is-open-true'
  finishedStatusLabel: string = 'report-audit.report-audit-manage.ts-file-labels.is-open-false'
  successSwalTitleTranslated: string = '';
  successSwalTextTranslated: string = '';
  inProgressStatusLabelTranslated: string = '';
  finishedStatusLabelTranslated: string = '';

  //Vetor que guarda as auditorias existentes
  auditListData: AuditList[] = [];
  auditIdList: string[] = [];
  //Vetor que guarda as referências das auditorias existentes
  auditReferenceList: string[] = [];
  //Variáveis do FormControl
  auditReferenceForm = new FormControl('', Validators.required);

  // Variáveis do mat-table das auditorias existentes
  displayedColumnsAudit: string[] = ['reference', 'data', 'accountable', 'creationDate', 'startDate', 'endDate', 'status', 'actions'];
  dataSourceAudit = new MatTableDataSource<AuditList>(this.auditListData);

  /**
   * Função que busca as auditorias existentes com paginação.
   */
  getAuditList(cursor: string | null): void {
    this.auditListData = [];
    this.tableIsLoading = true;

    this.subscriptions = this.auditorshipService.getAudits(
      this.pageSize,
      cursor
    )
      .valueChanges
      .subscribe({
        next: (data: any) => {

          this.paginationProperties = new PaginationInfo(
            data.data?.auditorships?.total,
            data.data?.auditorships?.count,
            data.data?.auditorships?.pageInfo?.hasNextPage,
            data.data?.auditorships?.pageInfo?.hasPreviousPage,
            data.data?.auditorships?.pageInfo?.startCursor,
            data.data?.auditorships?.pageInfo?.endCursor
          )
          this.length = data.data?.auditorships?.total

          let auditorshipList = data.data.auditorships.edges
          auditorshipList.forEach((auditorship: any) => {

            if (!auditorship.node.isOpen) {
              this.auditIdList.push(auditorship.node.id ? auditorship.node.id : null);
            }
            this.auditReferenceList.push(auditorship.node.auditReference);
            this.auditListData.push(
              new AuditList(
                auditorship.node.id,
                auditorship.node.auditReference,
                auditorship.node.installationauditSet.total,
                new AuditData(
                  auditorship.node.totals.icti,
                  auditorship.node.totals.icvi,
                  auditorship.node.totals.idt,
                  auditorship.node.totals.opDiu,
                  auditorship.node.totals.opNot,
                  auditorship.node.totals.sdft,
                  auditorship.node.totals.sdot,
                  auditorship.node.totals.mprev

                ),
                auditorship.node.accountable,
                auditorship.node.creationDate,
                auditorship.node.startDate,
                (auditorship.node.endDate ? auditorship.node.endDate : "-"),
                (auditorship.node.isOpen ? this.inProgressStatusLabelTranslated : this.finishedStatusLabelTranslated)
              )
            )
          });
          this.auditCount = this.auditListData.length;
          this.dataSourceAudit.data = this.auditListData;
          this.tableIsLoading = false;
        },
        error: (error: any) => {
          this.errorLib.errorAlert(error);
          this.tableIsLoading = false;
        }
      })
  }

  /**
   * Função que busca as auditorias existentes com paginação . 
   * @param cursor 
   * @returns lista de auditoria anterior. 
   */
  handlePreviousPageAudit(cursor: string | null): void {
    this.auditListData = [];
    this.tableIsLoading = true;
    this.subscriptions = this.auditorshipService.handlePreviousPageAudits(
      this.pageSize,
      cursor
    )
      .valueChanges
      .subscribe({
        next: (data: any) => {
          this.paginationProperties = new PaginationInfo(
            data.data?.auditorships?.total,
            data.data?.auditorships?.count,
            data.data?.auditorships?.pageInfo?.hasNextPage,
            data.data?.auditorships?.pageInfo?.hasPreviousPage,
            data.data?.auditorships?.pageInfo?.startCursor,
            data.data?.auditorships?.pageInfo?.endCursor
          )
          this.length = data.data?.auditorships?.total;

          let auditorshipList = data.data.auditorships.edges
          auditorshipList.forEach((auditorship: any) => {

            if (!auditorship.node.isOpen) {
              this.auditIdList.push(auditorship.node.id ? auditorship.node.id : null);
            }
            this.auditReferenceList.push(auditorship.node.auditReference);
            this.auditListData.push(
              new AuditList(
                auditorship.node.id,
                auditorship.node.auditReference,
                auditorship.node.installationauditSet.total,
                new AuditData(
                  auditorship.node.totals.icti,
                  auditorship.node.totals.icvi,
                  auditorship.node.totals.idt,
                  auditorship.node.totals.opDiu,
                  auditorship.node.totals.opNot,
                  auditorship.node.totals.sdft,
                  auditorship.node.totals.sdot,
                  auditorship.node.totals.mprev

                ),
                auditorship.node.accountable,
                auditorship.node.creationDate,
                auditorship.node.startDate,
                (auditorship.node.endDate ? auditorship.node.endDate : "-"),
                (auditorship.node.isOpen ? this.inProgressStatusLabelTranslated : this.finishedStatusLabelTranslated)
              )
            )
          });
          this.auditCount = this.auditListData.length;
          this.dataSourceAudit.data = this.auditListData;
          this.tableIsLoading = false;
        },
        error: (error: any) => {
          this.errorLib.errorAlert(error);
          this.tableIsLoading = false;
        }
      })
  }


  /**
   * Checka se a referência da auditoria é válida, baseado no que for escrito, 
   * não permite caracteres especiais e checka se a referência já não existe.
   * @returns True or False baseado na validade da referência.
   */
  referenceValidation(reference: string | null): void {
    //No começo considera que não há caracteres especiais e que a referência não existe.
    this.referenceContainSpecialCharacters = false;
    this.referenceAlreadyExists = false;
    //Primeiro verifica se existe caracteres especiais.
    const specialChars = `\`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\?~`;
    this.referenceContainSpecialCharacters = specialChars.split('').some(specialChar => {
      if (reference !== null) {
        if (reference.includes(specialChar)) {
          return true;
        }
        return false;
      } else return false;
    });
    //Em seguida verifica se a referência já existe.
    this.referenceAlreadyExists = this.auditReferenceList.some(auditReference => {
      if (
        auditReference.toLowerCase() === reference?.toLocaleLowerCase()
        || (auditReference.replace(/\s/g, '_')).toLowerCase() === (reference?.replace(/\s/g, '_'))?.toLocaleLowerCase()
      ) {
        return true;
      }
      return false;
    });
  }

  createAudit(): void {
    this.referenceValidation(this.auditReferenceForm.value);
    if (this.referenceContainSpecialCharacters || this.referenceAlreadyExists) {
      return;
    }
    // Chamar a requisição de criar auditoria, passando a referência, se criado com sucesso
    // a flag de edição passa a ser true, chamando o component auditEdit, passando por EventEmitter a id
    // da auditoria recém criada.
    this.subscriptions = this.auditorshipService.createAuditorship(this.auditReferenceForm.value).subscribe({
      next: (data: any) => {
        Swal.fire({
          title: this.successSwalTitleTranslated,
          text: this.successSwalTextTranslated,
          icon: 'success',
        }).then(() => {
          this.getAuditList(null);
          this.router.navigate([`/audit/auditEdit/${data.data.auditorshipCreate.auditorship.id}`]);
        })
      },
      error: (error: any) => {
        this.errorLib.errorAlert(error);
      }
    })
  }


  editAudit(selectedAuditId: string): void {
    this.auditorshipService.editingAudit.emit(true);
    this.auditorshipService.selectedAuditId.emit(selectedAuditId);
  }

  toggleDownloadManage(): void {
    this.seeingAuditReportDownloads = !this.seeingAuditReportDownloads;
  }


  private auditInstallationSet: AuditInstallations[] = [];

  public createInstallationSet(installation: any): AuditInstallations {
    let newInstallationSet: AuditInstallations = new AuditInstallations(
      installation.installationId,
      installation.installationReference,
      installation.transmissionComplianceIndicator,
      installation.register,
      installation.status,
      installation.variables,
      installation.daytimeOperation,
      installation.nighttimeOperation,
      installation.actuation
    )
    return newInstallationSet
  }

  public getAuditorshipInstallations(id: any) {
    this.subscriptions = this.auditorshipService.getAuditorshipInstallations(id)
      .valueChanges
      .subscribe({
        next: (auditData: any) => {
          let auditPayload = auditData.data.auditorship.edges[0].node;
          let auditInstallationSet = auditPayload.installationauditSet.edges;
          auditInstallationSet.forEach((installation: any) => {
            this.auditInstallationSet.push(
              this.createInstallationSet(installation.node)
            );
          });

          this.auditorshipService.downloadCsv(this.auditInstallationSet);
          this.auditInstallationSet = [];
        }
      })
  }
}
