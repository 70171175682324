import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ErrorLibService } from "src/shared/services/error-lib.service";
import { UnitMeasurementService } from "src/shared/services/unit-measurement.service";
import { PaginationInfo, Unit } from "./assets/unit-measurement.model";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import Swal from "sweetalert2";
import { Subscribable, Subscription, tap } from "rxjs";
import { jsPDF } from 'jspdf';
import { Unsubscriber } from "src/shared/components/unsubscriber/unsubscriber.component";

@Component({
  selector: 'app-unit-measurement',
  templateUrl: './unit-measurement.component.html',
  styleUrls: ['./unit-measurement.component.less']
})

export class UnitMeasurementComponent extends Unsubscriber implements OnInit {

  constructor(
    private unitMeasurementService: UnitMeasurementService,
    private formBuilder: FormBuilder,
    private errorLibService: ErrorLibService
  ) {
    super();
  }

  ngOnInit(): void { }

  //Define as propriedades da paginação
  public paginationProperties: PaginationInfo = new PaginationInfo(0, 0, false, false, null, null)

  // Variaveis responáveis por abrir e fechar as boxes / sidebar
  public register: boolean = false;
  public filter: boolean = false;
  public openSideBar: boolean = false;

  /** Variável usada para armazenar o filtro feito pelo usuário **/
  public filteredReference: string;

  /** Variáveis para loading **/
  public fileLoading: boolean = false;
  public filterLoading: boolean = false;

  //Variável responsável por armazenar os dados da lista
  public ELEMENT_DATA: Unit[] = [];

  /** Variáveis utilizadas para geração e manipulação dos arquivos **/
  public isReady = false;
  public FILE_DATA: Unit[] = [];

  // Variavel responsável por configurar o nome das colunas da tabela
  public displayedColumns: string[] = ['name', 'symbol', 'action'];

  // Renderiza os comandos da lista ELEMENT_DATA para a tabela
  public dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);

  /** Métodos e variáveis responsáveis por configurar a paginação da tabela **/
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /** Variáveis utilizadas para manipulação do Paginator **/
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 0;

  //Formulário de criação da unidade de medidas
  public createForm: FormGroup = this.formBuilder.group({
    reference: [null],
    symbol: [null]
  })

  //Formulário de atualização da unidade de medidas
  public updateForm: FormGroup = this.formBuilder.group({
    id: [null],
    reference: [null, Validators.required],
    symbol: [null, Validators.required]
  })

  //Formulário da filtragem de unidade de medidas
  public filterForm: FormGroup = this.formBuilder.group({
    reference: [null]
  })

  /** Função que controla os botões de anterior e pŕoximo no paginator **/
  public pageChanged(event: any) {

    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /** 
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     * 
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     **/
    if (event.previousPageIndex > event.pageIndex) {
      this.filterUnits(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterUnits(this.paginationProperties.endCursor)
    }
  }

  //Função que configura o paginator.
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
  }

  //Função responsável por abrir a box do filter
  public Filter(): void {
    this.register = false;
    this.filter = !this.filter
  }

  //Função responsável por abrir a box do register (Criar nova unidade de medida)
  public Register(): void {
    this.filter = false;
    this.register = !this.register
  }

  //Função para criar uma nova unidade de medida
  public createUnits() {
    if (!this.createForm.get("reference")?.value || !this.createForm.get("symbol")?.value) {
      Swal.fire({
        text: 'Verifique os campos e tente novamente!',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }

    //Service responsável pela criação da unidade de medida
    this.subscriptions = this.unitMeasurementService.createUnits(
      this.createForm.get("reference")?.value,
      this.createForm.get("symbol")?.value
    ).pipe(
      //Recebe o response da requisição GraphQL
      tap((res: any) => {

        //Insere o novo dado criado na lista de elementos
        this.ELEMENT_DATA.push(
          new Unit(
            res.data.measurementUnitCreate.measurementUnit.id,
            res.data.measurementUnitCreate.measurementUnit.name,
            res.data.measurementUnitCreate.measurementUnit.symbol
          )
        )

        //Configura o paginator
        this.setDataSourceAttributes()

        //Exibe uma modal de sucesso
        Swal.fire({
          title: 'Unidade de medida',
          text: 'Unidade de medida criada com sucesso',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      })
    ).subscribe({
      error: (error: any) => {
        /** Exibe alerta do erro ao usuário **/
        this.errorLibService.errorAlert(error);
      }
    });
    //Reseta o formulário
    this.createForm.reset()
  }

  //Função que filtra as unidades de medida
  public filterUnits(cursor: string | null) {
    //Service que filtra as unidades de medida
    this.filteredReference = this.filterForm.get("reference")?.value;

    this.filterLoading = true;

    if (cursor == null) {
      this.pageIndex = 0;
      this.isReady = true;
    }

    this.subscriptions = this.unitMeasurementService.filterUnits(
      this.filteredReference,
      cursor,
      this.pageSize
    ).valueChanges.subscribe({
      //Função responsável por manipular a response
      next: ((response: any) => {

        //Variável responsável pelas propriedades da paginação
        this.paginationProperties = new PaginationInfo(
          response.data.measurementUnit.count,
          response.data.measurementUnit.total,
          response.data.measurementUnit.pageInfo.hasNextPage,
          response.data.measurementUnit.pageInfo.hasPreviousPage,
          response.data.measurementUnit.pageInfo.startCursor,
          response.data.measurementUnit.pageInfo.endCursor
        )

        this.ELEMENT_DATA = [];
        this.isReady = false;
        this.length = this.paginationProperties.total;

        //Responsável por inserir os dados na lista de unidades a partir das lista de dados recebido pelo service
        response.data.measurementUnit.edges.forEach((unit: any) => {
          this.ELEMENT_DATA.push(
            new Unit(
              unit.node.id,
              unit.node.name,
              unit.node.symbol
            )
          )
        });
        //Configura o paginator
        this.setDataSourceAttributes()

        this.filterLoading = false;

      }), error: ((error: any) => {
        // Emite uma mensagem de erro ao usuário
        this.errorLibService.errorAlert(error);
        console.log(error);
      })
    })
  }

  //Função responsável por abrir a sideBar e popular os inputs nela
  public OpenUpdateSideBar(element: any): void {
    this.openSideBar = !this.openSideBar

    this.updateForm.get("id")?.setValue(element?.id)
    this.updateForm.get("reference")?.setValue(element?.name)
    this.updateForm.get("symbol")?.setValue(element?.symbol)
  }

  //Função responsável por atualizar as unidades de medida
  public updateUnits() {
    //Verifica se o formulário é valido, caso não, exibe o modal de error
    if (!this.updateForm.valid) {
      Swal.fire({
        text: 'Verifique os campos e tente novamente!',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }

    //Service responsável por atualizar as unidades de medida
    this.subscriptions = this.unitMeasurementService.updateUnits(
      this.updateForm.get("id")?.value,
      this.updateForm.get("reference")?.value,
      this.updateForm.get("symbol")?.value
    ).subscribe({
      //Manipula o response, atualizando os elementos da DATA
      next: () => {
        //Atualiza o ELEMENT_DATA com a unidade que foi atualizada
        this.ELEMENT_DATA = [
          new Unit(
            this.updateForm.get("id")?.value,
            this.updateForm.get("reference")?.value,
            this.updateForm.get("symbol")?.value
          )
        ]
        //Configura o paginator
        this.setDataSourceAttributes()

        //Exibe o modal de sucesso
        Swal.fire({
          title: 'Unidade de medida',
          text: 'Unidade de medida editada com sucesso',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      },
      //Caso de algum error a função abaixo é responsável por manipular
      error: (error: any) => {
        //Exibe no console o error
        console.log("updateUnits", error)
        //Exibe um alerta
        this.errorLibService.errorAlert(error);
      }
    })
  }

  public generateFileData(cursor: string | null, fileType: string) {
    this.fileLoading = true;
    /** Verifica se a lista está pronta **/
    if (this.isReady == false) {
      this.subscriptions = this.unitMeasurementService.filterUnits(
        this.filteredReference,
        cursor,
        100
      ).valueChanges.subscribe({
        next: ((response: any) => {
          // Preenche a lista responsável por alimentar a tabela
          //Responsável por inserir os dados na lista de unidades a partir das lista de dados recebido pelo service
          response.data.measurementUnit.edges.forEach((unit: any) => {
            this.FILE_DATA.push(
              new Unit(
                unit.node.id,
                unit.node.name,
                unit.node.symbol
              )
            )
          });

          /** Caso seja a última página a lista está completa **/
          if (!response.data?.measurementUnit?.pageInfo?.hasNextPage) {
            this.isReady = true;
          }

          this.generateFileData(response.data?.measurementUnit?.pageInfo?.endCursor, fileType);
        }),
        error: ((error: any) => {
          this.errorLibService.errorAlert(error);
        })
      })
    }
    /** Caso a lista esteja pronta gera o arquivo escolhido pelo usuário **/
    else {
      this.fileLoading = false;
      if (fileType === "PDF") {
        this.downloadPDF();
      } else {
        this.downloadCSV();
      }
    }
  }

  // Converte o conteúdo em CSV. Privado porque só usa aqui nessa classe.
  private convertToCSV(arr: any) {
    // Transforma o objeto numa lista onde o primeiro elemento é o cabeçalho com o nome das colunas.
    const content = [Object.keys(arr[0])].concat(arr);

    // Retorna o conteúdo mapeado como string linha a linha adicionando quebra de linha em cada um.
    return content.map((item) => {
      return Object.values(item).toString().replace(',', ';');
    }).join('\n');
  }

  // Função que realiza o download do CSV.
  public downloadCSV() {
    let content: any = [];

    // Adicoina cada elemento como um objeto na lista de conteúdo.
    this.FILE_DATA.forEach((element) => {
      content.push({
        'Nome': element.name,
        'Symbol': element.symbol
      });
    });

    // Adiciona o conteúdo convertido dentro de uma nova variável.
    let data = this.convertToCSV(content);
    // Transforma o conteúdo para o formato blob para geração do arquivo.
    let file = new Blob([data], { type: 'text/csv' });
    // Cria um endereçamento para o arquivo.
    let url = window.URL.createObjectURL(file);
    // Cria um elemento para a linkagem do arquivo.
    let link = document.createElement('a');
    // Adiciona a url no elemento de linkagem.
    link.href = url;
    // Nomeia o arquivo que será feito o download.
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}-companies.csv`;
    // Adiciona o link no documento.
    document.body.appendChild(link);
    // Atica o evento de click para realizar o download.
    link.click();
    // Remove o link do documento.
    document.body.removeChild(link);
    // Remove o endereço do download.
    window.URL.revokeObjectURL(url);

    this.FILE_DATA = [];
    this.isReady = false;
  }

  // Função que realiza o download do PDF.
  public downloadPDF() {
    // Define o cabeçalho do documento.
    const header = [
      'Nome',
      'Symbol',
    ];
    // Lista que irá conter o conteúdo retornado pela API.
    let content: any = [];
    // Inserção do conteúdo na lista.
    this.FILE_DATA.forEach((element) => {
      content.push([
        element.name,
        element.symbol,
      ]);
    });
    // Define o formato do documento.
    let file: any = new jsPDF('l', 'mm', 'a4');
    // Cria o PDF.
    file.autoTable({
      theme: 'grid',
      margin: { top: 20 },
      head: [header],
      body: content
    });
    // Gera o download do documento.
    file.save(`${new Date().toLocaleDateString()}-${new Date().getHours()}:${new Date().getMinutes()}-companies.pdf`)

    this.FILE_DATA = [];
    this.isReady = false;
  }
}